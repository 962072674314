import React from 'react';
import BootstrapTable from 'react-bootstrap-table-next';
import {Button, Modal} from 'react-bootstrap';
import paginationFactory from 'react-bootstrap-table2-paginator';
import moment from "moment";
import Details from "./Details";
import NumberFormat from 'react-number-format';
import GenericModal from "../common/GenericModal";
import connect from "react-redux/es/connect/connect";
import store from "../../store";
import {getReceiptPdfFile, refund, sendReceiptPdfFileEmail} from "../../providers/authorizations";
import {formatAmount, maskCard} from "../../lib/utils";
import Loader from "../common/Loader";
import HotSpot from "../common/HotSpot";
import NumberFormatter from "../common/NumberFormatter";
import EmptyStateShape from "../common/EmptyStateShape";
import ReactToPrint from "react-to-print";

const Translate = require('react-redux-i18n').Translate;
const I18n = require('react-redux-i18n').I18n;
const fileDownload = require('js-file-download');

const sortProps = {
    headerFormatter: (column, index, {sortElement, filterElement}) => {
        const order = sortElement.props;
        return (
            <div className="transactions-table-header">
                <Translate value={column.text} dangerousHTML/>
                <span>{order.children}</span>
            </div>
        )
    }
};

const stateMap = (store) => {
    return {
        userReducer: store.userReducer,
        merchantReducer: store.merchantReducer
    };
};

class DataTable extends React.Component {
    constructor(props) {
        super(props)

        this.settlingFormatter = this.settlingFormatter.bind(this);
        this.dateTimeFormatter = this.dateTimeFormatter.bind(this);
        this.cardSchemeFormatter = this.cardSchemeFormatter.bind(this);
        this.bankFormatter = this.bankFormatter.bind(this);
        this.loyaltyProgramAliasesFormatter = this.loyaltyProgramAliasesFormatter.bind(this);
        this.transTypeFormatter = this.transTypeFormatter.bind(this);
        this.installmentsFormatter = this.installmentsFormatter.bind(this);
        this.amountsFormatter = this.amountsFormatter.bind(this);
        this.responseCodeFormatter = this.responseCodeFormatter.bind(this);
        this.status3DFormatter = this.status3DFormatter.bind(this);
        this.sortCaret = this.sortCaret.bind(this);
        this.handleCloseModal = this.handleCloseModal.bind(this);
        this.handleShowModal = this.handleShowModal.bind(this);
        this.getJumpToPageValue = this.getJumpToPageValue.bind(this);
        this.jumpToPage = this.jumpToPage.bind(this);
        this.adjustPaginatorSize = this.adjustPaginatorSize.bind(this);
        this.jumpToPageKeyEvent = this.jumpToPageKeyEvent.bind(this);
        this.handleTableChange = this.handleTableChange.bind(this);
        this.toggleRefundModal = this.toggleRefundModal.bind(this);
        this.refund = this.refund.bind(this);
        this.refundMsg = this.refundMsg.bind(this);
        this.hotSpotHeaderFormatter = this.hotSpotHeaderFormatter.bind(this);
        this.getHotSpotName = this.getHotSpotName.bind(this);
        this.getReceiptPdfFile = this.getReceiptPdfFile.bind(this);
        this.toggleEmailWindow = this.toggleEmailWindow.bind(this);
        this.sendReceiptPdfFileEmail = this.sendReceiptPdfFileEmail.bind(this);
        this.hideReceiptExportError = this.hideReceiptExportError.bind(this);
        this.changeReceiptEmail = this.changeReceiptEmail.bind(this);

        this.state = {
            nextPageTitle: I18n.t('pages.transactions.table.pagination.nextPageTitle'),
            prevPageTitle: I18n.t('pages.transactions.table.pagination.prevPageTitle'),
            firstPageTitle: I18n.t('pages.transactions.table.pagination.firstPageTitle'),
            lastPageTitle: I18n.t('pages.transactions.table.pagination.lastPageTitle'),
            showDetails: false,
            selectedRowId: null,
            pageInput: null,
            emptyTable: (this.props.data.tableData ? (this.props.data.tableData.length === 0) : true),
            rowsPerPage: this.props.data.rowsPerPage ? this.props.data.rowsPerPage : 20,
            showRefundModal: false,
            amountToRefund: 0,
            showJumpToPage: this.props.data.total > 20,
            leftPaperclipTooltip: I18n.t('tooltips.hover.trxLeftPaperclip'),
            leftZedTooltipInBatch: I18n.t('tooltips.hover.trxLeftInBatch'),
            leftClockTooltip: I18n.t('tooltips.hover.trxLeftClock'),
            leftCheckTooltip: I18n.t('tooltips.hover.trxLeftCheck'),
            leftFailTooltip: I18n.t('tooltips.hover.trxLeftFail'),
            iconCheckTooltip: I18n.t('tooltips.hover.trxStatusOk'),
            iconXTooltip: I18n.t('tooltips.hover.trxStatusRejected'),
            iconXCardlinkOneTooltip: I18n.t('tooltips.hover.trxStatusCardlinkOneRejected'),
            errorFromRefund: undefined,
            disableExport: false,
            showEmailWindow: false,
            receiptEmail: '',
            validEmail: true,
            loaderTitle: '',
            receiptExportError: false
        }
    }

    componentWillReceiveProps(nextProps) {
        if (nextProps.locale !== this.props.locale) {
            this.setState({
                nextPageTitle: I18n.t('pages.transactions.table.pagination.nextPageTitle'),
                prevPageTitle: I18n.t('pages.transactions.table.pagination.prevPageTitle'),
                firstPageTitle: I18n.t('pages.transactions.table.pagination.firstPageTitle'),
                lastPageTitle: I18n.t('pages.transactions.table.pagination.lastPageTitle'),
                leftPaperclipTooltip: I18n.t('tooltips.hover.trxLeftPaperclip'),
                leftClockTooltip: I18n.t('tooltips.hover.trxLeftClock'),
                leftZedTooltipInBatch: I18n.t('tooltips.hover.trxLeftInBatch'),
                iconCheckTooltip: I18n.t('tooltips.hover.trxStatusOk'),
                iconXTooltip: I18n.t('tooltips.hover.trxStatusRejected')
            })
        }

        if (nextProps.data.tableData !== this.props.data.tableData) {
            this.setState({
                emptyTable: (nextProps.data.tableData ? (nextProps.data.tableData.length === 0) : true),
                showJumpToPage: nextProps.data.total > 20
            })
        }

    }

    handleTableChange = (type, {page, sizePerPage, sortField, sortOrder}) => {
        if (sortField === 'dateAndTime') {
            sortField = ('authorizationDate,authorizationTime');
        }
        this.setState({
            currentPage: page,
            rowsPerPage: sizePerPage,
        }, () => {
            if (type === "sort") {
                this.props.handleTblChange(page, sizePerPage, sortField, (!(this.props.data.asc && sortField === this.props.data.by)));
            } else {
                this.props.handleTblChange(page, sizePerPage, this.props.data.by, this.props.data.asc)
            }
        })
    };

    handleCloseModal() {
        this.setState({showDetails: false});
    }

    handleShowModal() {
        this.setState({showDetails: true});
    }

    arrangeSizePerPageList(total) {
        switch (true) {
            case (total > 20 && total < 100):
                return [
                    {
                        text: '20', value: 20
                    },
                    {
                        text: '50', value: 50
                    }
                ];
            case (total >= 100):
                return [{
                    text: '20', value: 20
                },
                    {
                        text: '50', value: 50
                    },
                    {
                        text: '100', value: 100
                    }];
            default:
                return [{
                    text: '20', value: 20
                },
                    {
                        text: '50', value: 50
                    },
                    {
                        text: '100', value: 100
                    }];
        }
    }

    settlingFormatter(cell, row) {
        switch (true) {
            case (cell === 1):
                return <div className="settled-state-icon settled-state-done" title={this.state.leftPaperclipTooltip}>
                            <span className='icon-settling icon-paperclip'>
                            </span>
                </div>
            case (cell === 2):
                return <div className="settled-state-icon in-batch" title={this.state.leftZedTooltipInBatch}>
                    <span className='icon-settling icon-kleismeno-paketo'>
                    </span>
                </div>
            case (cell === 3 && row.acquirerBankId && row.acquirerBankId === '887'):
                return <div className="settled-state-icon pay-success" title={this.state.leftCheckTooltip}>
                    <span className='icon-settling icon-success'>
                    </span>
                </div>
            case (cell === 4 && row.acquirerBankId && row.acquirerBankId === '887'):
                return <div className="settled-state-icon pay-failed" title={this.state.leftFailTooltip}>
                    <span className='icon-settling icon-failed'>
                    </span>
                </div>
            default:
                return <div className="settled-state-icon settled-state-pending" title={this.state.leftClockTooltip}>
                    <span className='icon-settling icon-circle-clock'>
                    </span>
                </div>
        }
    }

    dateTimeFormatter(cell, row) {
        return (
            <div className="datetime-tbl">
                <span className="date-tbl">
                    {moment(cell[0]).format('DD/MM/YY')}
                </span>
                <span className="time-tbl">
                    {cell[1]}
                </span>
            </div>
        );
    }

    cardSchemeFormatter(cell, row) {
        let schemeCls = cell !== null && cell !== "" ? "bg-" + (cell.toLowerCase().replace(" ", "_")) : '';
        return (
            <div className="scheme-icon-wrapper">
                <span className={schemeCls}> {cell !== null && cell !== "" ? '' : '-'} </span>
            </div>
        )
    }

    cardNumberFormatter(cell, row) {
        return (
            maskCard(cell)
        )
    }

    loyaltyProgramAliasesFormatter(cell, row) {
        const loyaltyProgramAliases = this.props.data.allMaps.loyaltyProgramAliases;
        let alias = loyaltyProgramAliases.find(item => {
            return item.key === cell;
        });
        if (alias !== undefined) return alias.label
        else return '-'
    }

    bankFormatter(cell, row) {
        const bankNames = this.props.data.allMaps.acquirers;
        let rowBankName = row.transactionType !== 'L' ? bankNames.find(item => {
            return item.value === cell;
        }) : undefined;
        let bankCls = rowBankName !== undefined ? "bg-" + (rowBankName.label.toLowerCase().replace(" ", "_")) : '';
        return (
            row.transactionType === 'L' ? <div className="asfalistikos-goneas-wrapper"
                                               title={I18n.t("pages.transactions.table.transactionTypes.asfalistikosGoneas")}>
                    <Translate value={"pages.transactions.table.transactionTypes.asfalistikosGoneas"}/>
                </div> :
                rowBankName !== undefined ?
                    <div className="bank-icon-wrapper">
                        <span className={bankCls}> {''} </span>
                    </div>
                    :
                    <div className="transaction-type">
                        <span><Translate value={"pages.transactions.table.transactionTypes.unknown"}/>
                        </span>
                    </div>
        )
    }

    transTypeFormatter(cell, row) {
        let transTypes = this.props.data.allMaps.transactionTypes;
        const keyPrefix = "pages.transactions.table.transactionTypes."
        let rowTransType
        if (row.isVoid) {
            if (cell === "14") {
                rowTransType = {value: cell, label: "VOID OF REFUND", key: "voidOfRefund"}
            } else if (cell === 'L') {
                rowTransType = {value: cell, label: "VOID OF ASF GONEA", key: "voidOfAsfGonea"}
            } else {
                rowTransType = {value: cell, label: 'VOID', key: 'transVoid'}
            }
        } else if (row.tlglifecycle && row.tlglifecycle === 'REV') {
            rowTransType = {value: cell, label: 'REVERSAL', key: 'reversal'}
        } else {
            rowTransType = transTypes.find(item => {
                if (cell !== undefined && cell !== null && item.value === cell.toString()) {
                    return item;
                } else {
                    return item.value === "-"
                }
            });
        }
        return (
            rowTransType !== undefined ? <div className="transaction-type" title={I18n.t(keyPrefix + rowTransType.key)}>
                <Translate value={keyPrefix + rowTransType.key}/>
            </div> : <div className="transaction-type">
                <span>-</span>
            </div>
        )
    }

    installmentsFormatter(cell, row) {
        return (
            cell === 0 ? '-' : cell
        )
    }

    amountsFormatter(cell, row) {
        return (
            <NumberFormatter number={cell} digits={2} symbol={"€"}/>
        )
    }

    hotSpotHeaderFormatter(column, index, {sortElement, filterElement}) {
        const order = sortElement ? sortElement.props : undefined;
        let hotSpot = this.getHotSpotName(column.dataField);
        return (
            <div className={"hotSpot-element__wrapper" +
                (column.dataField === "status3D" ||
                column.dataField === "responseCode" ?
                    " hotSpot-element__wrapper--centered" : "")}>
                <div className="transactions-table-header">
                    <Translate value={column.text} dangerousHTML/>
                    {order ?
                        <span>{order.children}</span> : null}
                </div>
                {this.props.userReducer.showHotSpots ?
                    <HotSpot
                        hotProp={hotSpot}
                        placement="top"
                    />
                    : null}
            </div>
        )
    }

    getHotSpotName(field) {
        switch (true) {
            case field === "status3D":
                return "threeDSecureHeader";
            case field === "packageStatus":
                return "isSettledTransactionHeader";
            case field === "responseCode":
                return "responseCodeHeader";
        }
    }

    responseCodeFormatter(cell, row) {
        const approvalResponseCodes = this.props.data.allMaps.approvalResponseCodes;
        const cardlinkOneDeclinedResponseCodes = this.props.data.allMaps.cardlinkOneDeclinedResponseCodes;
        return (
            <div
                className={"trans-tbl-icon " + (approvalResponseCodes.includes(cell) ?
                    "icon-icon-check" : "icon-x-icon")
                }
                title={approvalResponseCodes.includes(cell) ? this.state.iconCheckTooltip :
                    row.acquirerBankId === '887' && cardlinkOneDeclinedResponseCodes.includes(cell) ? this.state.iconXCardlinkOneTooltip :
                        this.state.iconXTooltip}/>
        )
    }

    status3DFormatter(cell, row) {
        return (
            <div className={"trans-tbl-icon " + (cell ?
                "icon-icon-check" : "icon-x-icon")
            }
                 title={cell ? this.state.iconCheckTooltip :
                     this.state.iconXTooltip}></div>
        )
    }

    sortCaret(order, column) {
        let isByDateTime = this.props.data.by === 'authorizationDate,authorizationTime' && column.dataField === 'dateAndTime';
        return (
            <div className="order">
                {(!this.props.data.asc ||
                    this.props.data.asc === false) ||
                (this.props.data.asc && column.dataField !== this.props.data.by && !isByDateTime) ?
                    <span className="dropdown">
                <span className="caret">
                </span>
            </span> : null}
                {this.props.data.asc === true &&
                (column.dataField === this.props.data.by || isByDateTime) ?
                    <span className="dropup">
                    <span className="caret">
                    </span>
                </span> : null}
            </div>
        )
    }

    getJumpToPageValue(obj) {
        this.setState({
            pageInput: obj.floatValue
        });
    }

    jumpToPage() {
        let quotient = Math.floor(parseInt(this.props.data.total, 10) / parseInt(this.state.rowsPerPage, 10));
        let remain = parseInt(this.props.data.total, 10) % parseInt(this.state.rowsPerPage, 10);
        let toPage = (quotient + (remain > 0 ? 1 : 0));

        if (this.state.pageInput <= toPage) {
            this.props.handleTblChange(this.state.pageInput, this.state.rowsPerPage);
        }
    }

    jumpToPageKeyEvent(e) {
        if (e.key === 'Enter' && this.state.pageInput !== null) {
            this.jumpToPage();
        }
    }

    adjustPaginatorSize(pageNumDigits) {
        switch (true) {
            case (pageNumDigits >= 5):
                return 3;
            case (pageNumDigits === 4):
                return 4;
            case (pageNumDigits === 3):
                return 5;
            case (pageNumDigits === 2 || pageNumDigits === 1):
                return 6;
            default:
                return 3;
        }
    }

    toggleRefundModal(amountToRefund, authorizationId, toggleModalFlag = true) {
        const requestId = this.props.userReducer.profile.email + '-' + moment().valueOf()
        this.setState({
            showRefundModal: toggleModalFlag ? !this.state.showRefundModal : this.state.showRefundModal,
            showDetails: toggleModalFlag ? !this.state.showDetails : this.state.showDetails,
            authorizationId: authorizationId,
            amountToRefund: amountToRefund ? amountToRefund : this.state.amountToRefund,
            requestId: requestId
        })
    }

    refund() {
        store.dispatch(refund(this.state.authorizationId, this.state.amountToRefund, this.state.requestId, this.state.selectedRowDate)).then(
            () => {
                this.toggleRefundModal(undefined, undefined, false)
                this.setState({
                    errorFromRefund: false
                })
            },
            (error) => {
                const requestId = this.props.userReducer.profile.email + '-' + moment().valueOf()
                this.setState({
                    requestId: requestId,
                    errorFromRefund: true
                })
            })
    }

    refundMsg() {
        let msg
        if (this.state.errorFromRefund) {
            msg = <span className="refund-message-feedback refund-message-feedback--error">
                    <span className="icon-failed refund-message-feedback--icon"></span>
                    <Translate value="pages.transactions.details.modalWindow.refundNotSucceeded"/>
                  </span>
        } else if (this.state.errorFromRefund === false) {
            msg = <span className="refund-message-feedback refund-message-feedback--success">
                    <span className="icon-success refund-message-feedback--icon"></span>
                    <Translate value="pages.transactions.details.modalWindow.refundSucceeded"/>
                  </span>
        } else {
            msg = <Translate value="pages.transactions.details.modalWindow.text1"/>
        }
        return msg
    }

    getReceiptPdfFile() {
        this.setState({
            disableExport: true,
            loaderTitle: 'loader.receiptPdf',
        })
        store.dispatch(getReceiptPdfFile(this.state.selectedRowId, this.state.selectedRowDate)).then(
            (response) => {
                fileDownload(response.value.data, response.value.headers.filename)
                this.setState({
                    disableExport: false
                })
            },
            () => {
                this.setState({
                    receiptExportError: true,
                    disableExport: false
                })
            })
    }

    sendReceiptPdfFileEmail() {
        this.setState({
            disableExport: true,
            receiptEmail: '',
            loaderTitle: 'loader.trxEmail',
        }, () => {
            setTimeout(() => {
                this.setState({
                    disableExport: false
                });
            }, 5000)
        });
        store.dispatch(sendReceiptPdfFileEmail(this.state.selectedRowId, this.state.selectedRowDate, this.state.receiptEmail)).then(
            () => {
            },
            () => {
                this.setState({
                    receiptExportError: true,
                    disableExport: false
                })
            })
    }

    toggleEmailWindow() {
        this.setState({
            showEmailWindow: !this.state.showEmailWindow,
            receiptEmail: '',
            validEmail: true
        })
    }

    hideReceiptExportError() {
        this.setState({
            receiptExportError: false
        });
    }

    changeReceiptEmail(event) {
        this.setState({
            receiptEmail: event.target.value,
            validEmail: event.target.value.match(
                /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)$/
            )
        })
    }

    render() {
        const refundInProgress = this.props.merchantReducer && this.props.merchantReducer.refund &&
            this.props.merchantReducer.refund.fetching;
        let formattedRefundAmount = Object.assign({}, {value: this.state.amountToRefund});
        const rowEvents = {
            onClick: (e, row, rowIndex) => {
                this.setState({
                    selectedRowId: row.authorizationId,
                    selectedRowDate: row.authorizationDate
                }, () => {
                    this.handleShowModal();
                })

            }
        };

        const rowClasses = (row, rowIndex) => {
            let classes = row.color;
            return classes;
        };

        const customTotal = (from, to, size) => (
            <div className="react-bootstrap-transactions-table-pagination-total">
                <div className="results-total">
                    <span> {this.props.data.total} </span>
                    <Translate value="pages.transactions.table.pagination.results"/>
                </div>
                <div className="dates-summary">
                    <span>{this.props.data.dateFrom}</span>
                    <Translate value="pages.transactions.table.pagination.toLabel"/>
                    <span>{this.props.data.dateTo}</span>
                </div>
            </div>
        );


        let options = {
            hideSizePerPage: this.props.data.total <= 20,
            paginationSize: this.adjustPaginatorSize(this.props.data.currentPage.toString().length),
            pageStartIndex: 1,
            alwaysShowAllBtns: true,
            withFirstAndLast: true,
            hidePageListOnlyOnePage: true,
            prePageText: 'p',
            nextPageText: 'n',
            firstPageText: 'f',
            lastPageText: 'l',
            prePageTitle: this.state.prevPageTitle,
            nextPageTitle: this.state.nextPageTitle,
            firstPageTitle: this.state.firstPageTitle,
            lastPageTitle: this.state.lastPageTitle,
            showTotal: true,
            paginationTotalRenderer: customTotal,
            onTableChange: this.handleTableChange,
            page: this.props.data.currentPage,
            sizePerPage: this.props.data.rowsPerPage,
            totalSize: this.props.data.total,
            sizePerPageList: this.arrangeSizePerPageList(this.props.data.total)
        };

        const columns = [
            {
                dataField: 'packageStatus',
                text: " ",
                formatter: this.settlingFormatter,
                headerFormatter: this.hotSpotHeaderFormatter,
                headerClasses: "table-hdr-settle-cell",
                classes: "table-col-settle-cell"
            },
            {
                dataField: 'dateAndTime',
                text: 'pages.transactions.table.headings.dateAndTime',
                formatter: this.dateTimeFormatter,
                headerFormatter: sortProps.headerFormatter,
                sortCaret: this.sortCaret,
                sort: true,
            },
            {
                dataField: 'merchantId',
                text: "pages.transactions.table.headings.merchantId",
                headerFormatter: sortProps.headerFormatter,
                sortCaret: this.sortCaret,
                sort: true
            }, {
                dataField: 'terminalId',
                text: "pages.transactions.table.headings.terminalId",
                headerFormatter: sortProps.headerFormatter,
                sortCaret: this.sortCaret,
                sort: true
            },
            {
                dataField: 'cardTypeBrand',
                text: "pages.transactions.table.headings.scheme",
                formatter: this.cardSchemeFormatter,
                headerFormatter: sortProps.headerFormatter,
                sortCaret: this.sortCaret,
                align: "center",
                headerAlign: "center",
                headerClasses: "table-hdr-align-center",
                sort: true
            },
            {
                dataField: 'maskedCardNumber',
                text: "pages.transactions.table.headings.cardNumber",
                formatter: this.cardNumberFormatter,
                headerFormatter: sortProps.headerFormatter,
                sortCaret: this.sortCaret,
                sort: true,
            },
            {
                dataField: 'acquirerBankId',
                text: "pages.transactions.table.headings.bank",
                formatter: this.bankFormatter,
                headerFormatter: sortProps.headerFormatter,
                sortCaret: this.sortCaret,
                align: "center",
                headerAlign: "center",
                headerClasses: "table-hdr-align-center",
                sort: true
            },
            {
                dataField: 'transactionType',
                text: "pages.transactions.table.headings.transactionType",
                formatter: this.transTypeFormatter,
                headerFormatter: sortProps.headerFormatter,
                sortCaret: this.sortCaret,
                align: "left",
                headerAlign: "left",
                headerClasses: "table-hdr-align-left",
                sort: true
            },
            {
                dataField: 'numberOfTotalInstalments',
                text: "pages.transactions.table.headings.totalInstallments",
                formatter: this.installmentsFormatter,
                headerFormatter: sortProps.headerFormatter,
                sortCaret: this.sortCaret,
                headerClasses: "table-hdr-align-center",
                classes: "table-col-align-center",
                sort: true
            },
            {
                dataField: 'redemptionAmount',
                text: "pages.transactions.table.headings.redemptionAmount",
                formatter: this.amountsFormatter,
                headerFormatter: sortProps.headerFormatter,
                sortCaret: this.sortCaret,
                align: "right",
                headerAlign: "right",
                headerClasses: "table-hdr-align-right",
                classes: "table-col-align-right",
                sort: true
            },
            {
                dataField: 'authorizationAmount',
                text: "pages.transactions.table.headings.authorizationAmount",
                formatter: this.amountsFormatter,
                headerFormatter: sortProps.headerFormatter,
                sortCaret: this.sortCaret,
                headerAlign: "right",
                headerClasses: "table-hdr-align-right",
                classes: "table-col-align-right",
                align: "right",
                sort: true
            },
            {
                dataField: 'status3D',
                text: "pages.transactions.table.headings.status3d",
                formatter: this.status3DFormatter,
                headerFormatter: this.hotSpotHeaderFormatter,
                sortCaret: this.sortCaret,
                headerAlign: "center",
                headerClasses: "table-hdr-align-center",
                classes: "table-col-align-center",
                align: "center",
                sort: true,
                hidden: !this.props.isEcom
            },
            {
                dataField: 'responseCode',
                text: "pages.transactions.table.headings.status",
                formatter: this.responseCodeFormatter,
                headerFormatter: this.hotSpotHeaderFormatter,
                sortCaret: this.sortCaret,
                headerAlign: "center",
                headerClasses: "table-hdr-align-center",
                classes: "table-col-align-center",
                align: "center",
                sort: true
            }
        ];

        return (
            <React.Fragment>
                <Modal show={this.state.showDetails} onHide={this.handleCloseModal}
                       bsClass="transaction__details_modal modal">
                    <Modal.Header closeButton bsClass="transaction__details_modal-header modal-header">
                        <Modal.Title>
                            <Translate value="pages.transactions.details.title"/>
                            <div className="buttons-wrapper">
                                <button title={I18n.t("pages.transactions.details.shareReceipt")}
                                        disabled={this.state.disableExport || this.props.isEcom}
                                        className="icon-saita transaction__details__print--btn"
                                        onClick={this.toggleEmailWindow}
                                />
                                <button title={I18n.t("pages.transactions.details.printReceipt")}
                                        disabled={this.state.disableExport || this.props.isEcom}
                                        onClick={this.getReceiptPdfFile}
                                        className="icon-download-icon transaction__details__print--btn"
                                />
                                <ReactToPrint
                                    trigger={() =>
                                        <button title={I18n.t("pages.transactions.details.printDetails")}
                                                className="icon-printer transaction__details__print--btn"/>}
                                    bodyClass={"transaction__details--print"}
                                    content={() => this.componentRef}
                                />
                            </div>
                        </Modal.Title>
                    </Modal.Header>
                    <Modal.Body bsClass="transaction__details_modal-body modal-body"
                                ref={el => (this.componentRef = el)}
                    >
                        <Details allMaps={this.props.data.allMaps}
                                 aliasesArr={this.props.data.aliasesArr}
                                 authorizationId={this.state.selectedRowId}
                                 authorizationDate={this.state.selectedRowDate}
                                 isEcom={this.props.isEcom}
                                 disableExport={this.state.disableExport}
                                 showEmailWindow={this.state.showEmailWindow}
                                 receiptExportError={this.state.receiptExportError}
                                 receiptEmail={this.state.receiptEmail}
                                 validEmail={this.state.validEmail}
                                 loaderTitle={this.state.loaderTitle}
                                 transTypeFormatter={this.transTypeFormatter}
                                 loyaltyProgramAliasesFormatter={this.loyaltyProgramAliasesFormatter}
                                 toggleEmailWindow={this.toggleEmailWindow}
                                 sendReceiptPdfFileEmail={this.sendReceiptPdfFileEmail}
                                 changeReceiptEmail={this.changeReceiptEmail}
                                 hideReceiptExportError={this.hideReceiptExportError}
                                 toggleRefundModal={this.toggleRefundModal}/>
                    </Modal.Body>
                </Modal>
                <GenericModal
                    dialogClassName="transaction__details__refund-modal"
                    showModal={this.state.showRefundModal}
                    showCloseButton={true}
                    onHide={() => {
                        this.toggleRefundModal()
                        this.setState({
                            errorFromRefund: undefined
                        })
                    }}
                    modalHeaderClass="transaction__details__refund-modal__header"
                    modalTitle=""
                    modalBody={
                        <React.Fragment>
                            <div className={"transaction__details__refund-modal__text-wrapper" +
                                (this.state.errorFromRefund !== undefined ? ' refund-modal-min-height' : '')}>
                                {this.refundMsg()}
                            </div>
                            {this.state.errorFromRefund === undefined ?
                                <React.Fragment>
                                    <div className="transaction__details__refund-modal__bold-text-wrapper">
                                        <Translate value="pages.transactions.details.modalWindow.text2"
                                                   amountToRefund={formatAmount(I18n._locale, formattedRefundAmount.value, 2)}/>
                                    </div>
                                    <div className="transaction__details__refund-modal__text-wrapper">
                                        <Translate value="pages.transactions.details.modalWindow.text3"/>
                                    </div>
                                    <div className="transaction__details__refund-modal__button-wrapper">
                                        <Button
                                            className="transaction__details__refund-modal__button-wrapper__cancel-button"
                                            onClick={() => {
                                                this.toggleRefundModal()
                                            }
                                            }>
                                            <Translate value="pages.transactions.details.modalWindow.cancel"/>
                                        </Button>
                                        <Button
                                            className="transaction__details__refund-modal__button-wrapper__refund-button"
                                            onClick={() => {
                                                this.refund()
                                            }
                                            }>
                                            {refundInProgress ?
                                                <Loader bouncing={false} color={'white'} message=''
                                                        style={{marginTop: 0}} bounceClass='smaller-bounces'/> :
                                                <Translate value="pages.transactions.details.modalWindow.yes"/>
                                            }
                                        </Button>
                                    </div>
                                </React.Fragment> : null}
                        </React.Fragment>
                    }
                    bsSize="small"/>
                <BootstrapTable classes={"transactions-table"}
                                remote
                                keyField='authorizationId'
                                data={this.props.data.tableData}
                                columns={columns}
                                rowEvents={rowEvents}
                                pagination={paginationFactory(options)}
                                onTableChange={this.handleTableChange}
                                rowStyle={rowClasses}
                />
                {this.state.emptyTable ?
                    <div className="transactions-table__empty-state">
                        <EmptyStateShape
                            title={"pages.transactions.table." + (this.props.data.fromSearch ? "emptySearchMessageTitle" : "emptyTableMessageTitle")}
                            text={this.props.data.fromSearch ? "pages.transactions.table.emptySearchMessageText" : null}
                        />
                    </div> : null
                }
                {!this.state.emptyTable && this.state.showJumpToPage ?
                    <div className="jump-to-page">
                        <div className="jump-to-page__wrapper">
                            <div className="jump-to-page__label">
                                <Translate value="pages.transactions.table.pagination.jumpToPage"/>
                            </div>
                            <NumberFormat
                                allowNegative={false}
                                format="########"
                                onValueChange={this.getJumpToPageValue}
                                onKeyPress={this.jumpToPageKeyEvent}
                            />
                            <button type="button" onClick={this.jumpToPage}> {"OK"} </button>
                        </div>
                    </div> : null}
            </React.Fragment>
        )
    }
}

export default connect(stateMap)(DataTable);
