import React from "react";
import MidData from "./MidData";
import {filterByKeyValue, toUppercaseNoAccents} from "../../lib/utils";
import MidDetails from "./MidDetails";
import {Button, DropdownButton, MenuItem, Modal} from "react-bootstrap";
import BusinessSupportForm from "../supportForms/BusinessSupportForm";
import connect from "react-redux/es/connect/connect";
import DragScroll from 'react-dragscroll';

import Tooltip from "../common/Tooltip";
import HotSpot from "../common/HotSpot";

const Translate = require('react-redux-i18n').Translate

const stateMap = (store) => {
    return {
        userReducer: store.userReducer,
        i18n: store.i18n
    };
};

class Areas extends React.Component {
    _isMounted = false;
    constructor(props) {
        super(props)
        let groupedMids = this.groupMidsByArea(props.areasMap, props.data);
        this.state = {
            singleAfmMid: props.userReducer.getAssociationsResponse.merchantType === 'singleAfmMid',
            data: props.data,
            areasMap: props.areasMap,
            selectedGroupsOfMids: groupedMids,
            selectedArea: Object.keys(groupedMids)[0],
            isEcom: false,
            showEcom: false,
            ecomMids: null,
            showCompanyTkTooltip: false,
            selectedEcomMid: '',
            aliasesArr: props.aliasesArr ? props.aliasesArr: []
        };
        this.groupMidsByArea = this.groupMidsByArea.bind(this)
        this.setEcomIfExists = this.setEcomIfExists.bind(this)
        this.postCodeHandle = this.postCodeHandle.bind(this)
        this.loopAreas = this.loopAreas.bind(this)
        this.selectArea = this.selectArea.bind(this)
        this.arrangeAreaName = this.arrangeAreaName.bind(this)
        this.handleShowModal = this.handleShowModal.bind(this)
        this.handleCloseModal = this.handleCloseModal.bind(this)
        this.handleAliasChange = this.handleAliasChange.bind(this)
        this.handlePostCodeChange = this.handlePostCodeChange.bind(this)
        this.setTidForSupport = this.setTidForSupport.bind(this);
        this.openSupportForm = this.openSupportForm.bind(this);
        this.closeSupportForm = this.closeSupportForm.bind(this);
        this.formulateMidLabel = this.formulateMidLabel.bind(this);
    }

    componentDidMount() {
        this._isMounted = true;
        this.setEcomIfExists();
    }

    componentWillUnmount() {
        this._isMounted = false;
    }

    componentWillReceiveProps(nextProps) {
        if (nextProps.data !== this.props.data && this._isMounted) {
            let groupedMids = this.groupMidsByArea(this.state.areasMap, nextProps.data);
            this.setState({
                data: nextProps.data,
                selectedGroupsOfMids: groupedMids,
                selectedArea: Object.keys(groupedMids)[0]
            }, () => this.setEcomIfExists())
        }
        if (this.props.aliasesArr !== nextProps.aliasesArr && this._isMounted) {
            this.setState({
                aliasesArr: nextProps.aliasesArr
            })
        }
    }

    setEcomIfExists() {
        let data = this.state.data;
        let ecoms = [];
        data.forEach((mid, key) => {
            let ecomObject = mid.tidData.find((e) => e.ecom === true);
            if (ecomObject) {
                ecoms.push(mid)
                data[key].hideEcom = true;
            } else {
                data[key].hideEcom = false;
            }
        })
        // let tid = data[key].tidData.tid;
        if (this._isMounted) {
            this.setState({
                data: data,
                selectedGroupsOfMids: this.groupMidsByArea(this.state.areasMap, data),
                isEcom: ecoms.length > 0,
                ecomMids: ecoms
            })
        }
    }

    handlePostCodeChange(code) {
        const data = this.state.data;
        data.portalPostCode = code;
        let groupedMids = this.groupMidsByArea(this.state.areasMap, data);
        if (this._isMounted) {
            Object.keys(this.state.areasMap).forEach(key => {
                if (this.state.areasMap[key].postCode.includes(this.postCodeHandle(code))) {
                    this.setState({
                        data: data,
                        selectedGroupsOfMids: groupedMids,
                        selectedArea: key
                    })
                }
            })
        }
    }

    handleAliasChange(mid, alias, type) {
        if (type !== 'mid') {
            const ecomMid = this.state.selectedEcomMid;
            ecomMid.merchantAlias = alias;
            this.setState({
                selectedEcomMid: ecomMid
            })
        }
    }


    groupMidsByArea(areaMap, data) {
        let mids = [];
        let selectedAreaFlags = [];
        let restOfMids = data.slice(0);
        Object.keys(areaMap).forEach((key) => {
            data.forEach(mid => {
                if (areaMap[key].postCode.includes(this.postCodeHandle(mid.portalPostCode && mid.portalPostCode.match(/(\d{5})/g) ? mid.portalPostCode :
                    mid.locationPostCode && mid.locationPostCode.match(/(\d{5})/g) ? mid.locationPostCode : null))) {
                    if (!mids[key]) mids[key] = [];
                    mids[key].push(mid);
                    if (mid.hideEcom === false) {
                        mids[key]['notAllEcom'] = true;
                        selectedAreaFlags.push(key)
                    }
                    let indexOfMid = restOfMids.indexOf(mid);
                    restOfMids.splice(indexOfMid, 1)
                }
            })
        });
        if (!mids['restOfAreas']) mids['restOfAreas'] = [];
        else mids['restOfAreas'].concat(restOfMids);
        if (this._isMounted) {
            this.setState({
                selectedArea: selectedAreaFlags[0]
            });
        }
        return mids;
    }

    postCodeHandle(postCode) {
        return postCode !== null ? postCode.substring(0, 2) : '0';
    }

    arrangeAreaName(area, areaMap) {
        return this.props.locale === 'en' ? areaMap[area].latinName : areaMap[area].greekName;
    }

    loopAreas(areaMap) {
        if (!this.state.singleAfmMid) {
            return <DragScroll height={35} width={"auto"} className={"area__dragscroll"}>
                <div className="business-row area__tabs">
                    {Object.keys(areaMap).map((area) => (
                        areaMap[area].length > 0 && areaMap[area].notAllEcom ?
                            <div key={area}
                                 className={"area__option " + (this.state.selectedArea === area ? "business-area-selected" : "")}>
                                <a href=""
                                   onClick={(e) => {
                                       e.preventDefault()
                                       this.selectArea(area)
                                   }}>
                                    {toUppercaseNoAccents(this.arrangeAreaName(area, this.state.areasMap))}
                                </a>
                            </div> : null
                    ))}
                </div>
            </DragScroll>
        }
    }

    selectArea(area) {
        if (this._isMounted) {
            this.setState({
                selectedArea: area
            })
        }
    }

    handleShowModal(e) {
        // This runs only for single eshop
        if (this._isMounted) {
            this.setState({
                showEcom: true,
                selectedEcomMid: this.state.ecomMids[0]
            });
        }
    }

    handleCloseModal(e) {
        this.setState({
            showEcom: false
        });
    }

    setTidForSupport(tid) {
        this.setState({
            supportTid: tid,
        })
    }

    openSupportForm() {
        this.setState({
            showSupportModal: true,
            showEcom: false
        })
    }

    closeSupportForm() {
        this.setState({
            showSupportModal: false
        })
    }
    
    formulateMidLabel(midObj) {
        let alias = filterByKeyValue(this.state.aliasesArr, midObj.mid);
        return midObj.mid +
            (midObj.locationAddress1 ? " " + midObj.locationAddress1 : '') +
            ( alias ? " " + alias: '')
    }

    render() {
        let allAreasMidData = this.props.data;
        return (
            <div>
                {!this.state.singleAfmMid ?
                    <div className="business-column business-areas">
                        {this.state.isEcom && !this.state.singleAfmMid ? [
                            <Modal show={this.state.showEcom}
                                   onHide={this.handleCloseModal}
                                   bsSize="large"
                                   key=" business-areas-modal">
                                <Modal.Header closeButton bsClass="modal-header business-modal__header">
                                </Modal.Header>
                                <Modal.Body bsClass="business-modal">
                                    <MidDetails
                                        data={this.state.selectedEcomMid}
                                        iban={this.props.iban}
                                        setAliasesArr={this.props.setAliasesArr}
                                        updateTerminalAllias={this.props.updateTerminalAllias}
                                        selectedTid={this.state.ecomMids[0].tidData.tid}
                                        setTidForSupport={this.setTidForSupport}
                                        locale={this.props.locale}
                                        handleAliasChange={this.handleAliasChange}
                                        isEcom={this.state.isEcom}
                                    />
                                    <div className="support-business__wrapper">
                                        <h5>
                                            <Translate value="modals.support.business.message"/>
                                        </h5>
                                        <Button className="support-form__btn btn-default"
                                                onClick={() => {
                                                    this.openSupportForm();
                                                }}>
                                            <Translate value="modals.support.business.button"/>
                                        </Button>
                                    </div>
                                </Modal.Body>
                            </Modal>,
                            <div className="support-business__wrapper"
                                 key="support-busines-modal__wrapper">
                                <Modal bsClass="modal"
                                       onHide={this.closeSupportForm}
                                       dialogClassName="modal-dialog support-modal__dialog"
                                       show={this.state.showSupportModal}>
                                    <Modal.Header closeButton bsClass="modal-header support-modal_header">
                                    </Modal.Header>
                                    <Modal.Body bsClass="modal-body support-modal_body">
                                        <BusinessSupportForm
                                            selectedMid={this.state.selectedEcomMid.mid}
                                            selectedTid={this.state.supportTid ? this.state.supportTid : this.state.ecomMids[0].tidData.tid}
                                            closeModal={this.closeSupportForm}/>
                                    </Modal.Body>
                                </Modal>
                            </div>] : null
                        }
                        <div className="business-row">
                            <h4>
                                <Translate value={"pages.business.stores.title"}/>
                            </h4>
                            {this.state.isEcom && !this.state.singleAfmMid ?
                                <div className="hotSpot-element__wrapper hotSpot-element__wrapper--ecom-info">
                                    {this.props.userReducer.showHotSpots ?
                                        <HotSpot
                                            hotProp="eCommerceBusiness"
                                            placement="top"
                                        />:null}
                                    {this.state.ecomMids.length > 1 ?
                                        <div className="dropdown__ecoms--wrapper">
                                            <DropdownButton id="dropdown-ecom-terminals"
                                                            title={<Translate  value="pages.business.eshopDropdown" />}>
                                                {this.state.ecomMids.map((midObj) => {
                                                    return [
                                                        midObj ? <MenuItem eventKey={midObj.mid}
                                                            key={midObj.mid}
                                                            onSelect={() => {
                                                                this.setState({
                                                                    selectedEcomMid: midObj,
                                                                    showEcom: true,
                                                                })
                                                            }}
                                                            active={midObj.mid === this.state.selectedEcomMid}
                                                            >
                                                            {this.state.aliasesArr ? this.formulateMidLabel(midObj): null}
                                                        </MenuItem>:null
                                                    ]
                                                })}
                                            </DropdownButton>
                                        </div>
                                        :<Button id="show-ecom-btn" onClick={this.handleShowModal}>
                                            <Translate value="pages.business.eshopButton"/>
                                            <span className="icon-icon-ecommerce"></span>
                                        </Button>}
                                </div>: null}
                        </div>
                        <div className="business-row business-areas__company-tk-info">
                            <Translate value={"pages.business.stores.hint"}/>
                            <span>
                                <Tooltip placement="bottom"
                                         tooltipButtonId="companyTkInfo"
                                         tooltipLabelClass="company-tk-label"
                                         popoverId="company-tk-tltp"
                                         tooltipText="companyTKInfo"
                                />
                            </span>
                        </div>
                        {this.loopAreas(this.state.selectedGroupsOfMids)}
                        <div className="area__tab-content">
                            {this.state.data && this.state.selectedGroupsOfMids ?
                                <MidData
                                    data={this.state.selectedGroupsOfMids[this.state.selectedArea]}
                                    iban={this.props.iban}
                                    setAliasesArr={this.props.setAliasesArr}
                                    updateTerminalAllias={this.props.updateTerminalAllias}
                                    allAreasMidData={allAreasMidData}
                                    selectedArea={this.state.selectedArea}
                                    ecrMap={this.props.ecrMap}
                                    locale={this.props.locale}
                                    handlePostCodeChange={this.handlePostCodeChange}
                                /> : null
                            }
                        </div>
                    </div> :
                    this.state.data && this.state.selectedGroupsOfMids ?
                        <MidDetails
                            data={this.state.selectedGroupsOfMids[Object.keys(this.state.selectedGroupsOfMids)[0]][0]}
                            iban={this.props.iban}
                            setAliasesArr={this.props.setAliasesArr}
                            updateTerminalAllias={this.props.updateTerminalAllias}
                            selectedTid={this.state.selectedGroupsOfMids[Object.keys(this.state.selectedGroupsOfMids)[0]][0].tidData[0].tid}
                            setTidForSupport={this.setTidForSupport}
                            isSingleAfmMid={true}
                            locale={this.props.locale}
                            handleAliasChange={this.handleAliasChange}
                            handlePostCodeChange={this.handlePostCodeChange}
                        /> : null}
            </div>
        )
    }
}

export default connect(stateMap)(Areas);