import React from 'react';
import {DropdownButton, MenuItem} from 'react-bootstrap';
import store from "../../store";
import connect from "react-redux/es/connect/connect";
import {withRouter} from 'react-router-dom';
import {setOrderStatus, switchCompany, switchStore} from "../../actions/merchantAction";
import QuickFindSearch from "../topMainRenderer/QuickFindSearch";
import {arrangeCompanyName, getStatusMap} from "../../lib/utils";
import SearchBox from "../common/SearchBox";
import HotSpot from "../common/HotSpot";
import Tooltip from "../common/Tooltip";
import {toggleSearchTransactionsPopover} from "../../actions/userActions";
import {getMerchantOrderStatus, getMidsAndTidsByAfm} from "../../providers/merchants";
import Loader from "../common/Loader";

const Translate = require('react-redux-i18n').Translate;
const I18n = require('react-redux-i18n').I18n;

const stateMap = (store) => {
    return {
        i18n: store.i18n,
        userReducer: store.userReducer,
        merchantReducer: store.merchantReducer,
        rightColumnReducer: store.rightColumnReducer
    };
};


class TopMainRenderer extends React.Component {
    constructor(props) {
        super(props)
        this.mainMultipleMidWrapper = React.createRef();
        this.state = {
            selectedCompany: this.props.merchantReducer,
            allCompanies: I18n.t('dropdownCompanies'),
            uniqueMids: null,
            uniqueMidsWithLocation: null,
            loadingMids: false,
            midsWithLocationInDropdownFormat: [],
            showMidHelp: false,
            showQuickFindHelp: false,
            hasSelectedAfm: props.merchantReducer.afm !== undefined
        }

        this.resetAllMidsDropdown = this.resetAllMidsDropdown.bind(this);
        this.dispatchMerchantReducer = this.dispatchMerchantReducer.bind(this);
        this.checkIfUserIsInBusinessTab = this.checkIfUserIsInBusinessTab.bind(this);
        this.uniqueMidsToDropdownFormat = this.uniqueMidsToDropdownFormat.bind(this);
        this.makeUniqueValuesAndToDropdownFormat = this.makeUniqueValuesAndToDropdownFormat.bind(this);
        this.setMidsWithLocationToDropdownFormat = this.setMidsWithLocationToDropdownFormat.bind(this);
        this.setMidWithLocationToSearchBoxFormat = this.setMidWithLocationToSearchBoxFormat.bind(this);
        this.setAndReturnUniqueMidsWithLocation = this.setAndReturnUniqueMidsWithLocation.bind(this);
        this.getAllFromAssociationType = this.getAllFromAssociationType.bind(this);
        this.getFilteredFromAssociationType = this.getFilteredFromAssociationType.bind(this);
        this.checkTabIsOverviewOrReports = this.checkTabIsOverviewOrReports.bind(this);
        this.midsDropdownHasAtLeast2Entries = this.midsDropdownHasAtLeast2Entries.bind(this);
        this.userNotPickedAnyMid = this.userNotPickedAnyMid.bind(this);
        this.setOrderStatuses = this.setOrderStatuses.bind(this);
    }

    async componentWillMount() {
        store.dispatch(getMerchantOrderStatus(undefined, undefined)).then((resp) => {
            let payload = resp.value.data.payload;
            if (payload && payload.length > 0) {
                let cardlinkOneAfm = payload.filter(e => e.afmCardlinkOne);
                let hasCardlinkOneAfm = cardlinkOneAfm.length > 0;
                if (hasCardlinkOneAfm) {
                    let cardlinkOneMid = cardlinkOneAfm[0].midData.filter(e => e.midCardlinkOne);
                    let currentMid = cardlinkOneMid.length > 0 && cardlinkOneMid[0] && cardlinkOneMid[0].mid ? cardlinkOneMid[0].mid : '';
                    this.setOrderStatuses(currentMid);

                } else {
                    store.dispatch(setOrderStatus(undefined))
                }
            }
        });
        let companies = this.props.userReducer.getAssociationsResponse.uniqueAfmsWithTitles;
        if (companies.length > 0) {
            let type = this.props.userReducer.getAssociationsResponse.merchantType;
            if (type !== 'multiAfm') {
                store.dispatch(switchCompany(companies[0].afm, arrangeCompanyName(companies[0], this.props.i18n.locale), companies[0].isCardlinkOne));
                const midsWithLocationInDropdownFormat = await this.makeUniqueValuesAndToDropdownFormat(companies[0].afm)
                if (midsWithLocationInDropdownFormat.length === 1) {
                    store.dispatch(switchStore(midsWithLocationInDropdownFormat.value, midsWithLocationInDropdownFormat))
                }
            } else {
                store.dispatch(switchCompany('', '', ''));
                this.resetAllMidsDropdown()
            }
            if (window.location.pathname.includes("business")) {
                store.dispatch(switchCompany(companies[0].afm, arrangeCompanyName(companies[0], this.props.i18n.locale), companies[0].isCardlinkOne));
            }
            store.dispatch(toggleSearchTransactionsPopover(false));
        }
    }


    resetAllMidsDropdown() {
        store.dispatch(switchStore(null, null))
    }

    async makeUniqueValuesAndToDropdownFormat(afmPicked) {
        this.setState({
            loadingMids: true
        })
        let uniqueMidsWithLocation = await this.setAndReturnUniqueMidsWithLocation(afmPicked)
        return this.setMidsWithLocationToDropdownFormat(uniqueMidsWithLocation)
    }

    async setAndReturnUniqueMidsWithLocation(afmPicked) {
        let uniqueMids = []
        let uniqueMidsWithLocation = []
        await store.dispatch(getMidsAndTidsByAfm(1, -1, afmPicked)).then(
            (response) => {
                response.value.data.forEach(associations => {
                    associations.mids.forEach(midTidAndLocation => {
                        uniqueMids.push(uniqueMids)
                        uniqueMidsWithLocation.push({
                            mid: midTidAndLocation.mid,
                            location: midTidAndLocation.location
                        })
                    })
                })
            });

        this.setState({
            uniqueMids: uniqueMids,
            uniqueMidsWithLocation: uniqueMidsWithLocation
        })

        return uniqueMidsWithLocation
    }

    setMidsWithLocationToDropdownFormat(uniqueMids) {
        let midsWithLocationInDropdownFormat = this.setMidWithLocationToSearchBoxFormat(uniqueMids)

        this.setState({
            midsWithLocationInDropdownFormat: midsWithLocationInDropdownFormat,
            loadingMids: false
        })

        return midsWithLocationInDropdownFormat
    }

    setMidWithLocationToSearchBoxFormat(originalArray, baseValue) {
        let inDropdownFormat = originalArray.map(element => {
            const label = element.location ? element.mid.concat(" - ", element.location) : element.mid
            return {value: element.mid, label: label}
        })
        if (baseValue) {
            this.addBaseValueToDropdown(inDropdownFormat, baseValue)
        }

        return inDropdownFormat
    }

    checkIfUserIsInBusinessTab() {
        return this.props.location.pathname === '/business'
    }


    async componentWillReceiveProps(nextProps) {

        let companies = this.props.userReducer.getAssociationsResponse.uniqueAfmsWithTitles;
        if (nextProps.rightColumnReducer !== this.props.rightColumnReducer &&
            nextProps.rightColumnReducer.afmSearched !== null) {
            let company = companies.find(company => {
                return company.afm === nextProps.rightColumnReducer.afmSearched
            })
            store.dispatch(switchCompany(nextProps.rightColumnReducer.afmSearched,
                arrangeCompanyName(company), company.isCardlinkOne)
            );
            if (!this.checkIfUserIsInBusinessTab()) {
                this.props.history.push('/business')
            }
        }

        if (nextProps.merchantReducer.afm !== this.props.merchantReducer.afm) {
            this.setState({
                selectedCompany: nextProps.merchantReducer,
                hasSelectedAfm: nextProps.merchantReducer.afm !== undefined
            }, () => {
            });
            const midsWithLocationInDropdownFormat = await this.makeUniqueValuesAndToDropdownFormat(
                nextProps.merchantReducer.afm ? nextProps.merchantReducer.afm : undefined);
            if (midsWithLocationInDropdownFormat.length === 1) {
                store.dispatch(switchStore(midsWithLocationInDropdownFormat[0].value, midsWithLocationInDropdownFormat))
            } else {
                this.resetAllMidsDropdown()
            }
        }

        if (this.props.location.pathname !== nextProps.location.pathname) {
                if (window.location.pathname.includes("business") && !this.props.merchantReducer.afm &&
                    nextProps.userReducer.getAssociationsResponse.uniqueAfmsWithTitles.length > 0) {
                    this.dispatchMerchantReducer(companies[0])
                }
        }

        if (nextProps.i18n.locale !== this.props.i18n.locale) {
            this.setState({
                allCompanies: I18n.t('dropdownCompanies')
            })
        }
    }

    getAllFromAssociationType(associationsResponse, associationType) {
        return associationsResponse.map(company => {
            return company[associationType]
        })
    }

    getFilteredFromAssociationType(associationsResponse, associationType, filterValue, filterName) {
        const filteredAssociations = associationsResponse.filter(company => {
            return company[filterName] === filterValue
        })
        return this.getAllFromAssociationType(filteredAssociations, 'mid')
    }

    dispatchMerchantReducer(company) {
        store.dispatch(switchCompany(company.afm, arrangeCompanyName(company, this.props.i18n.locale), company.isCardlinkOne));
    }

    uniqueMidsToDropdownFormat() {
        return this.props.userReducer.getAssociationsResponse.uniqueMids.map(uniqueMid => {
            return {value: uniqueMid, label: uniqueMid}
        })
    }

    checkTabIsOverviewOrReports() {
        return window.location.pathname.includes("overview") || window.location.pathname.includes("reports")
    }

    midsDropdownHasAtLeast2Entries() {
        return this.state.midsWithLocationInDropdownFormat.length > 1
    }

    userNotPickedAnyMid() {
        return !this.props.merchantReducer.midsSearchBoxFormat
    }

    setOrderStatuses(currentMid) {
        let orderStatus;
        let orderStatuses = this.props.merchantReducer.merchantStatus.orderStatuses;
        if (orderStatuses && orderStatuses.length > 0) {
            let resStatus = orderStatuses.find(({mid}) => mid === currentMid);
            orderStatus = resStatus ? resStatus.status : null;
            if (orderStatus && orderStatus.value) {
                store.dispatch(setOrderStatus(getStatusMap(orderStatus.value)));
            } else {
                store.dispatch(setOrderStatus(null));
            }
        }
    }

    render() {
        let companies = this.props.userReducer.getAssociationsResponse.uniqueAfmsWithTitles;
        let type = this.props.userReducer.getAssociationsResponse.merchantType;
        let showMultiAfm = (this.midsDropdownHasAtLeast2Entries() || this.userNotPickedAnyMid()) &&
            this.state.hasSelectedAfm;
        return (
            <div className={'top-main-renderer__wrapper' + ('singleAfmMid' ? ' lesser-renderer-height__wrapper' : '')}>
                {companies.length > 0 ?
                    <React.Fragment>
                        {window.location.pathname.includes("transactions") ?
                        <div className="top-main-renderer__helper">
                        </div> : null}
                        <div className={'top-main-renderer'
                        + (window.location.pathname.includes("business") &&
                        type !== 'singleAfmMid' ? ' reduced-renderer-height' : '')
                        + (type === 'singleAfmMid' ? ' lesser-renderer-height' : '')
                        }>
                            <div className="top-main-renderer__left-part">
                                {type === 'multiAfm' ?
                                    <div className="hotSpot-element__wrapper hotSpot-element__wrapper--companies-dropdown">
                                        <DropdownButton id="dropdown-companies"
                                                        disabled={this.props.userReducer.searchTransactionsPopoverOpen}
                                                        title={this.props.merchantReducer.name &&
                                                        this.props.merchantReducer.afm ?
                                                        <span className='companies-in-dropdown'>
                                                            {this.props.merchantReducer.name}
                                                        </span>:
                                                        <Translate value="dropdownCompanies"/>}>
                                            {!window.location.pathname.includes("business")?
                                                <MenuItem eventKey={undefined}
                                                          key={'empty-afm'}
                                                          onSelect={() => {
                                                              store.dispatch(switchCompany(undefined, this.state.allCompanies, undefined));
                                                          }
                                                          }
                                                          active={!this.props.merchantReducer.afm}>
                                                    {this.state.allCompanies}
                                                </MenuItem> : null}
                                            {
                                                companies ? (
                                                    companies.map((company) => {
                                                        return [
                                                            <MenuItem eventKey={company.afm}
                                                                      key={company.afm}
                                                                      onSelect={() => {
                                                                          let name = arrangeCompanyName(company, this.props.i18n.locale);
                                                                          store.dispatch(switchCompany(company.afm, name, company.isCardlinkOne));
                                                                      }
                                                                      }
                                                                      active={company.afm === this.props.merchantReducer.afm}
                                                            >{arrangeCompanyName(company, this.props.i18n.locale)}
                                                            </MenuItem>
                                                        ]
                                                    })) : null
                                            }
                                        </DropdownButton>
                                        {this.props.userReducer.showHotSpots ?
                                            <HotSpot
                                                hotProp="companiesDropdown"
                                                placement="bottom"
                                            />
                                            : null}
                                    </div>
                                    :
                                    <h3>{arrangeCompanyName(companies[0], this.props.i18n.locale)}</h3>
                                }
                                {this.state.loadingMids && this.checkTabIsOverviewOrReports() ?
                                    <Loader bounceClass="smaller-bounces"
                                            loaderClass="top-main-renderer__left-part__loader-align"
                                            bouncing={false} color={'bahama-blue'} message=''
                                            spinnerClass="multiselect-stores__loader"/> : null}
                                {this.checkTabIsOverviewOrReports() && showMultiAfm && !this.state.loadingMids ?
                                    <div className="hotSpot-element__wrapper  hotSpot-element__wrapper--multiselect-stores">
                                        <SearchBox options={this.state.midsWithLocationInDropdownFormat}
                                                   clsName="multiselect-stores"
                                                   key={"mids"}
                                                   value={this.props.merchantReducer.midsSearchBoxFormat}
                                                   onChange={(midsSearchBoxFormat) => {
                                                       const allPickedMidsCommaSeparated = midsSearchBoxFormat.map(mid => {
                                                           return mid.value
                                                       }).toString()
                                                       const midsOnly = midsSearchBoxFormat.map(element => {
                                                           return {value: element.value, label: element.value}
                                                       })
                                                       store.dispatch(switchStore(allPickedMidsCommaSeparated, midsOnly))
                                                   }}
                                                   placeholder="dropdownStores"
                                                   isMulti={true}
                                                   isDisabled={!this.props.merchantReducer.afm}/>
                                        <div ref={this.mainMultipleMidWrapper}>
                                            <Tooltip placement="bottom"
                                                     tooltipButtonId="multipleMid"
                                                     popoverId="mid-tltp"
                                                     tooltipLabelClass="mid-label"
                                                     tooltipText="mainMultipleMID"
                                            />
                                        </div>
                                        {this.props.userReducer.showHotSpots ?
                                            <HotSpot
                                                hotProp="storesMultiselect"
                                                placement="bottom"
                                            />
                                            : null}
                                        <button className="top-main-renderer__left-part__reset-link"
                                                onClick={this.resetAllMidsDropdown}>
                                            <Translate value="pages.transactions.searchModal.labels.clear"/>
                                        </button>
                                    </div> : null
                                }
                            </div>
                            {(!window.location.pathname.includes("transactions")&&
                              !window.location.pathname.includes("bankTotals")) &&
                               type !== 'singleAfmMid' ?
                                <div className={"top-main-renderer__right-part search-fast-wrapper"
                                + (window.location.pathname.includes("business") ? " search-fast__business" : "")
                                + (type === "singleAfmMid" ? " search-fast__single-merchant" : "")
                                }>
                                    {this.props.userReducer.getAssociationsResponse.merchantType !== 'singleAfmMid' ?
                                        <React.Fragment>
                                            <React.Fragment>
                                                <div className="search-fast__header-tooltip">
                                                    <h6 className="search-fast-label">
                                                        <Translate value="common.fastSearch.label"/>
                                                    </h6>
                                                    <div>
                                                        <Tooltip placement="bottom"
                                                                 tooltipButtonId="quickFind"
                                                                 popoverId="quickfind-tltp"
                                                                 tooltipLabelClass="quickFind-label"
                                                                 tooltipText="quickFind"
                                                        />
                                                    </div>
                                                </div>
                                                <QuickFindSearch/>
                                            </React.Fragment>
                                        </React.Fragment> : null}
                                </div> : null}
                        </div>
                    </React.Fragment> : null}
            </div>
        )
    }
}


export default withRouter(connect(stateMap)(TopMainRenderer));

