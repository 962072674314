import React from "react";
import store from "../../store";
import {Button, DropdownButton, MenuItem, OverlayTrigger, Popover} from "react-bootstrap";
import SearchBox from "../common/SearchBox";
import connect from "react-redux/es/connect/connect";
import {arrangeCompanyName} from "../../lib/utils";
import {getBusinessExcel, getMidsAndTidsFilteredByAfmAndMidAndEcom} from "../../providers/merchants";
import Loader from "../common/Loader";
import {withRouter} from "react-router-dom";
import Tooltip from "../common/Tooltip";
import HotSpot from "../common/HotSpot";

const Translate = require('react-redux-i18n').Translate
const I18n = require('react-redux-i18n').I18n
const fileDownload = require('js-file-download')
const _ = require("underscore");

const stateMap = (store) => {
    return {
        userReducer: store.userReducer,
        i18n: store.i18n
    };
};

class ExportCompany extends React.Component {
    constructor(props) {
        super(props)
        this.reportDownloadMids = React.createRef();
        this.state = {
            companies: this.props.userReducer.getAssociationsResponse.uniqueAfmsWithTitles,
            company: {afm: undefined, name: null},
            mids: null,
            tids: null,
            loadingMidsOrTids: false,
            exportMids: null,
            exportTids: null,
            exportMidsSelected: null,
            exportTidsSelected: null,
            onlyActive: true,
            disableExport: false,
            showCompanyDlTooltip: false,
            showMultipleMidTooltip: false,
            showMultipleTidTooltip: false
        }
        this.setMids = this.setMids.bind(this)
        this.setTids = this.setTids.bind(this)
        this.setMidsTids = this.setMidsTids.bind(this)
        this.getBusinessExcel = this.getBusinessExcel.bind(this)
        this.onChangeSelect = this.onChangeSelect.bind(this)
        this.handleInputChange = this.handleInputChange.bind(this)
        this.returnDropdownToDefaultState = this.returnDropdownToDefaultState.bind(this)
        this.deselectAllDropdownValues = this.deselectAllDropdownValues.bind(this)
    }

    componentWillMount() {
        if (this.state.companies.length === 1) {
            this.setState({
                company: {afm: this.state.companies[0].afm, name: this.state.companies[0].name},
                onlyActive: false
            }, () => {
                this.setMidsTids()
            })
        } else {
            this.setMidsTids()
        }
    }

    setMids(map) {
        let mids = []
        map.forEach(element => {
            const label = element.location ? element.mid.concat(" - ", element.location) : element.mid
            mids.push({value: element.mid, label: label})
        })
        this.setState({
            mids: mids
        })
    }

    setTids(map) {
        let tids = []
        map.forEach(tid => {
            tids.push({value: tid, label: tid})
        })
        this.setState({
            tids: tids,
            loadingMidsOrTids: false
        })
    }

    deselectAllDropdownValues(pickedVariableName) {
        this.setState({
            [pickedVariableName]: undefined,
            [pickedVariableName + 'Selected']: null
        })
    }

    async setMidsTids(getOnlyTids) {
        let uniqueMidsWithLocation = []
        let tids = []

        this.setState({
            loadingMidsOrTids: true
        })
        if (!getOnlyTids) {
            store.dispatch(getMidsAndTidsFilteredByAfmAndMidAndEcom(1, -1,
                this.state.company.afm, undefined)).then(
                (response) => {
                    response.value.data.midsWithLocation.forEach(midWithLocation => {
                        uniqueMidsWithLocation.push({
                            mid: midWithLocation.mid,
                            location: midWithLocation.location,
                        })
                    })
                    this.setMids(uniqueMidsWithLocation)
                });
        }

        await store.dispatch(getMidsAndTidsFilteredByAfmAndMidAndEcom(1, -1, this.state.company.afm,
            this.state.exportMids ? this.state.exportMids : undefined)).then(
            (response) => {
                tids = _.uniq(response.value.data.tids)
                this.setTids(tids)
            });

    }

    getBusinessExcel(afm, mid, tid, onlyActive) {
        this.setState({
            disableExport: true
        })
        store.dispatch(getBusinessExcel(-1, afm, mid, tid, onlyActive, this.props.locale)).then((response) => {
            fileDownload(response.value.data, response.value.headers.filename)
            this.setState({
                disableExport: false
            })
        })
    }

    onChangeSelect(event, id) {
        let list = []
        event.forEach(e => {
            list.push(e.value)
        })
        const midsWithNoLocation = event.map(element => {
            return {value: element.value, label: element.value}
        })
        this.setState({
            [id]: list.join() === "" ? undefined : list.join(),
            [id + 'Selected']: midsWithNoLocation
        }, () => {
            if (id !== 'exportTids') {
                this.setMidsTids(true)
            }
        })
    }

    handleInputChange(event) {
        if (event.target.type === "checkbox") {
            this.setState({
                [event.target.id]: event.target.checked
            })
        } else {
            this.setState({
                [event.target.id]: event.target.value
            })
        }
    }

    returnDropdownToDefaultState(pickedVariableName, labelValue) {
        this.setState({
            [pickedVariableName]: {value: undefined, label: undefined}
        })
    }

    render() {
        const popoverBottom = (
            <Popover id={"business-popover-positioned-bottom"}
                     bsClass={'popover'}
            >
                <div className="business-popover-modal dropdown">
                    <a id='export-reset' onClick={() => {
                        this.setState({
                            company: {afm: null, name: null},
                            exportMidsSelected: null,
                            exportTidsSelected: null,
                            onlyActive: true
                        }, () => this.setMidsTids())
                    }}>
                        <Translate value="pages.business.modal.reset"/>
                    </a>
                    <form className='business-export-form' onSubmit={(e) => {
                        e.preventDefault();
                        this.getBusinessExcel(this.state.company.afm, this.state.exportMids, this.state.exportTids,
                            this.state.onlyActive)
                    }}>
                        {this.state.companies.length !== 1 ?
                            <DropdownButton id="dropdown-companies-export"
                                            title={this.state.company.name ? <span className="companies-export-in-dropdown">{this.state.company.name}</span> :
                                                <Translate className="companies-export-in-dropdown" value="dropdownCompanies"/>}>
                                <MenuItem eventKey={undefined}
                                          key={'empty-afm'}
                                          onSelect={() => {
                                              this.deselectAllDropdownValues('exportMids')
                                              this.deselectAllDropdownValues('exportTids')
                                              this.setState({
                                                  company: {afm: undefined, name: null}
                                              }, () => {
                                                  this.setMidsTids()
                                              })
                                          }
                                          }
                                          active={this.state.company.afm === null}>
                                    <Translate value="dropdownCompanies"/>
                                </MenuItem>
                                {this.state.companies ? (
                                    this.state.companies.map((company) => {
                                        return [
                                            <MenuItem eventKey={company.afm}
                                                      key={company.afm}
                                                      onSelect={() => {
                                                          let name = arrangeCompanyName(company, this.props.i18n.locale);
                                                          this.deselectAllDropdownValues('exportMids')
                                                          this.deselectAllDropdownValues('exportTids')
                                                          this.setState({
                                                              company: {afm: company.afm, name: name},
                                                          }, () => this.setMidsTids())
                                                      }
                                                      }
                                                      active={company.afm === this.state.company.afm}
                                            >{arrangeCompanyName(company, this.props.i18n.locale)}
                                            </MenuItem>
                                        ]
                                    })) : null
                                }
                            </DropdownButton> :
                            <p className="business-export-form__single-afm">
                                {arrangeCompanyName(this.state.companies[0], this.props.i18n.locale)}
                            </p>}
                        {this.state.loadingMidsOrTids && !this.state.exportMidsSelected ?
                            <Loader bounceClass="smaller-bounces"
                                    spinnerClass="smaller-bounces-align-export-company"
                                    bouncing={false} color={'bahama-blue'} message=''/> :
                            <React.Fragment>
                                <div className="business-export-form__multiple-mid-wrapper">
                                    <Translate value='pages.business.modal.selectMid'/>
                                    <div>
                                        <Tooltip placement="left"
                                                 tooltipButtonId="reportDownloadPanelMultipleMID"
                                                 popoverId="business-export-tltp"
                                                 tooltipLabelClass="export-form-label"
                                                 tooltipText="companyDlMultipleMid"
                                        />
                                    </div>
                                </div>
                                <SearchBox
                                    isMulti={true}
                                    options={this.state.mids}
                                    value={this.state.exportMidsSelected}
                                    clsName="business-allmids-select"
                                    onChange={(event) => {
                                        this.deselectAllDropdownValues('exportTids')
                                        this.onChangeSelect(event, 'exportMids')
                                    }}
                                    placeholder={I18n.t('pages.business.modal.midTitle')}
                                />
                                <div className="business-export-form__multiple-tid-wrapper">
                                    <Translate value='pages.business.modal.selectTid'/>
                                    <div>
                                        <Tooltip placement="left"
                                                 tooltipButtonId="reportDownloadPanelMultipleTID"
                                                 popoverId="business-export-tid-tltp"
                                                 tooltipLabelClass="export-form-label"
                                                 tooltipText="companyDlMultipleTid"
                                        />
                                    </div>

                                </div>
                                {(this.state.loadingMidsOrTids && this.state.exportMidsSelected) ?
                                    <Loader bounceClass="smaller-bounces"
                                            spinnerClass="smaller-bounces-align-export-company"
                                            bouncing={false} color={'bahama-blue'} message=''/> :
                                    <SearchBox
                                        isMulti={true}
                                        options={this.state.tids}
                                        value={this.state.exportTidsSelected}
                                        clsName="business-allmids-select"
                                        onChange={(event) => {
                                            this.onChangeSelect(event, 'exportTids')
                                        }}
                                        placeholder={I18n.t('pages.business.modal.tidTitle')}
                                    />}
                            </React.Fragment>}
                        <div>
                            <input id="onlyActive" type="checkbox" checked={this.state.onlyActive}
                                   onChange={this.handleInputChange}/>
                            <label htmlFor="onlyActive">
                                <Translate value='pages.business.modal.onlyActive'/>
                                <span className="checkmark"></span>
                            </label>
                        </div>
                        <br/>
                        {!this.state.loadingMidsOrTids ?
                            <button disabled={this.state.disableExport} id="businessExport" type="submit">
                                {this.state.disableExport ? <Loader bouncing={false} color={'white'} message=''
                                                                    style={{marginTop: 0}}
                                                                    bounceClass='smaller-bounces'/>
                                    : <Translate value='pages.business.modal.submit'/>}
                            </button> : null}
                    </form>
                </div>
            </Popover>
        );
        return (
            <div className="modal-right">
                {this.props.userReducer.getAssociationsResponse.merchantType === 'singleAfmMid' ?
                    <button type="button" className="__excel-button __excel-button-tiny"
                            onClick={() => {
                                this.getBusinessExcel(this.state.company.afm, null, null,
                                    this.state.onlyActive)
                            }
                            }
                    >
                    </button>
                    :
                    this.state.mids && this.state.tids ?
                        <div className="hotSpot-element__wrapper hotSpot-element__wrapper--download-business-excel">
                        {this.props.userReducer.showHotSpots ?
                            <HotSpot
                                hotProp="downloadBusinessExcel"
                                placement="bottom"
                            />:null}
                            <OverlayTrigger trigger="click"
                                            placement="bottom"
                                            overlay={popoverBottom}
                                            ref="overlayRef32"
                                            container={this}>
                                <Button className={"business-popover-toggle " +
                                (this.props.isToggleOn ? "triggered " : "not-triggered")}
                                        onClick={this.props.togglePopover}>
                                    <Translate value={"pages.business.modal.closed"}/>
                                    <Translate value={"pages.business.modal.opened"}/>
                                    <span className="icons">
                                              <i className={"icon-download-icon"}> </i>
                                              <i className={"icon-x-icon"}> </i>
                                              </span>
                                </Button>
                            </OverlayTrigger>
                        </div>
                        : <Loader loaderClass="business-popover-toggle__loader"
                                                    bouncing={false} color={'bahama-blue'} message=''/>
                }
            </div>
        )
    }
}

export default withRouter(connect(stateMap)(ExportCompany));
