import React from "react";
import {connect} from "react-redux";
import LocaleSwitch from "../../common/LocaleSwitch";
import Logo from "../../common/Logo";
import store from "../../../store";
import {setPhone} from "../../../providers/users";
import {setUserDetails} from "../../../actions/userActions";
import NumberFormat from "react-number-format";
import {phoneNumberFormat} from "../../../lib/utils";
import Loader from "../../common/Loader";
import Wave from "../../common/Wave";

const Translate = require('react-redux-i18n').Translate;

const stateMap = (store) => {
    return {
        i18n: store.i18n,
        userReducer: store.userReducer
    };
};

class NewPhoneForm extends React.Component {
    constructor(props) {
        super(props)

        this.state = {
            errorMessage: '',
            phone: ''
        }

        this.submitNewPhone = this.submitNewPhone.bind(this)
        this.handleFormattedChange = this.handleFormattedChange.bind(this)
        this.phoneNumberFormat = this.phoneNumberFormat.bind(this)
    }

    submitNewPhone() {
        store.dispatch(setPhone(this.props.confirmationCode, this.props.email,
            this.state.phone)).then(
            () => {
                store.dispatch(setUserDetails({
                    renderNewPhoneForm: false, renderOtpForm: true,
                    phone: this.state.phone
                }))
            },
            (error) => {
                if ((error.response.status === 503)) {
                    this.setState({
                        errorMessage: 'pages.userDetails.errors.anErrorOccured'
                    })
                }
            })
    }

    handleFormattedChange(obj, ev) {
        this.setState({
            [ev.target.id]: obj.value
        })
    }

    phoneNumberFormat(val) {
        let formatIsProper = false
        if (val[0] === '6') {
            if (val[1]) {
                if (val[1] === '9' && val.length <= 10) {
                    formatIsProper = true
                } else if (val.length > 10) {
                    return this.state.phone
                }
            } else {
                formatIsProper = true
            }
        }
        if (formatIsProper) return val
        return ''
    }

    render() {
        const settingPhoneInProgress = this.props.userReducer &&
            this.props.userReducer.setPhone &&
            this.props.userReducer.setPhone.fetching
        return (
            <div className="new-phone-form">
                <main>
                    <div className="new-phone-form__top">
                        <LocaleSwitch type={"login-area"}></LocaleSwitch>
                    </div>
                    <div className="new-phone-form__content">
                        <div className="new-phone-form__login-with-forgot">
                            <div className="new-phone-form__login-box">
                                <Logo isMain={false} isHeader={false} imgClassName="login"/>
                                <form onSubmit={(e) => {
                                    e.preventDefault();
                                    this.submitNewPhone();
                                }}>
                                    <Translate value="pages.userDetails.mobilePhone"/>
                                    <NumberFormat
                                        type="tel"
                                        id="phone"
                                        format={phoneNumberFormat}
                                        className="simple-input"
                                        value={this.state.phone}
                                        placeholder="69"
                                        decimalScale={0} required={true}
                                        onValueChange={this.handleFormattedChange}/>
                                    <div className="new-phone-form_wrong-creds">
                                        {this.state.errorMessage !== '' ?
                                            <div className="generic-error-msg">
                                                <span className="icon-icon-attention"/>
                                                <div>
                                                    <Translate value={this.state.errorMessage} dangerousHTML/>
                                                </div>
                                            </div> : null
                                        }
                                    </div>
                                    <div className="new-phone-form__login-button-area">
                                        <button type="submit" className="new-phone-form__login-button"
                                                disabled={settingPhoneInProgress}>
                                            {settingPhoneInProgress ?
                                                <Loader bouncing={false} color={'white'} message=''
                                                        style={{marginTop: 0}} bounceClass='smaller-bounces'/> :
                                                <Translate value="pages.userDetails.submitButtonText"/>
                                            }

                                        </button>
                                    </div>
                                </form>
                            </div>
                        </div>
                    </div>
                    <Wave />
                </main>
            </div>
        )
    }
}

export default connect(stateMap)(NewPhoneForm);