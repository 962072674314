import React from 'react';
import moment from "moment";
import {DropdownButton, MenuItem} from "react-bootstrap";
import {withRouter} from "react-router-dom";
import connect from "react-redux/es/connect/connect";

const Translate = require('react-redux-i18n').Translate;

const stateMap = (store) => {
    return {
        i18n: store.i18n
    };
};

class ChartDropdown extends React.Component {
    constructor(props) {
        super(props)
        this.state = {
            dateFrom: moment(new Date()).subtract(11, 'months').format('YYYY-MM-DD'),
            dateTo: moment(new Date()).subtract(1, 'days').format('YYYY-MM-DD'),
            pickedDropdownKey: '',
            pickedDropdownName: '',
            chartDropDown: this.pickChartDropdownBasedOnLocale(this.props.i18n.locale, this.props.isRedemption),
        }
        this.pickChartDropdownBasedOnLocale = this.pickChartDropdownBasedOnLocale.bind(this)
        this.handleDropdownChange = this.handleDropdownChange.bind(this)
    }

    componentWillMount() {
        this.setState({
            chartDropDown: this.pickChartDropdownBasedOnLocale(this.props.i18n.locale, this.props.isRedemption)
        })
    }

    componentWillReceiveProps(nextProps){

        if (this.props.i18n.locale !== nextProps.i18n.locale) {
            let chartDropdown = this.pickChartDropdownBasedOnLocale(nextProps.i18n.locale, nextProps.isRedemption);
            this.setState(prevState => ({
                chartDropDown: chartDropdown,
                pickedDropdownName: this.state.pickedDropdownKey !== "" ?
                                    chartDropdown.find(x => x.key === prevState.pickedDropdownKey).label
                                    : prevState.pickedDropdownName
            }));
        }
    }

    pickChartDropdownBasedOnLocale(locale) {
        if(this.props.isRedemption) {
            return locale === 'en' ? this.props.chartMaps.RedemptionChartsDropdowns :
                this.props.chartMaps.RedemptionChartsDropdownsGr
        } else {
            return locale === 'en' ? this.props.chartMaps.dropdowns :
                this.props.chartMaps.dropdownsGr
        }

    }

    handleDropdownChange(pickedDropdownEntry) {
        this.setState({
            pickedDropdownName: pickedDropdownEntry.label,
            pickedDropdownKey: pickedDropdownEntry.key
        });
        const newDatesAndTimespanMessage = this.props.setDates(pickedDropdownEntry, this.props.dateToIsCurrentDay)
        this.props.reRenderChart({
            ...this.props.data,
            dateFrom: newDatesAndTimespanMessage.dateFrom,
            dateTo: newDatesAndTimespanMessage.dateTo,
            previousDateFrom: newDatesAndTimespanMessage.previousDateFrom,
            previousDateTo: newDatesAndTimespanMessage.previousDateTo
        })
        if (this.props.setDatesInParent) {
            this.props.setDatesInParent(newDatesAndTimespanMessage.dateFrom, newDatesAndTimespanMessage.dateTo)
        }
        if (this.props.setPreviousTimespanMessage) {
            this.props.setPreviousTimespanMessage(newDatesAndTimespanMessage.previousTimespanMessage)
        }
    }


    render() {
        let chartDropdown = this.state.chartDropDown;
        return (
            <DropdownButton
                id={"chart-dropdown-" + this.state.pickedDropdownName}
                className="charts-timespan__dropdowns no-multi"
                title={this.state.pickedDropdownName ? <span>{this.state.pickedDropdownName}</span> :
                    <Translate value={this.props.dropdownBaseValue}/>}>
                {
                    chartDropdown ? (
                        chartDropdown.map((dropdownEntry) => {
                            return [
                                // !(this.props.hideWeek && dropdownEntry.key === "lastWeek") ?
                                    <MenuItem eventKey={dropdownEntry.key}
                                              key={dropdownEntry.key}
                                              onSelect={() => {
                                                  this.handleDropdownChange(dropdownEntry)
                                              }}
                                              active={dropdownEntry.key === this.state.pickedDropdownKey}
                                    >{dropdownEntry.label}
                                    </MenuItem>
                                    // :null
                            ]
                        })) : null
                }
            </DropdownButton>
        )
    }
}

export default withRouter(connect(stateMap)(ChartDropdown));