import React from 'react';
import moment from "moment";
import store from "../../store";
import {approvedCount, settledCount} from "../../providers/charts";
import ChartDropdown from "./ChartDropdown";
import {formatAmount, setChartDates} from "../../lib/utils";
import Loader from "../common/Loader";
import {withRouter} from "react-router-dom";
import connect from "react-redux/es/connect/connect";
import HotSpot from "../common/HotSpot";

const Translate = require('react-redux-i18n').Translate;
const I18n = require('react-redux-i18n').I18n

const stateMap = (store) => {
    return {
        chartReducer: store.chartReducer,
        userReducer: store.userReducer,
        i18n: store.i18n
    };
};

class SettledAndApprovedChart extends React.Component {
    constructor(props) {
        super(props)
        this.state = {
            afm: this.props.afm,
            mid: this.props.mid,
            approvedCountData: null,
            settledCountData: null,
            dateFrom: moment(moment().subtract(1, 'days')).subtract(1, 'week').format('YYYY-MM-DD'),
            dateTo: moment().subtract(1, 'days').format('YYYY-MM-DD'),
            settledAndApprovedTooltip: I18n.t('tooltips.hover.timeDropdown03')
        }
        this.runApprovedAndSettledCount = this.runApprovedAndSettledCount.bind(this)
        this.approvedCount = this.approvedCount.bind(this)
        this.settledCount = this.settledCount.bind(this)
        this.setNewDates = this.setNewDates.bind(this)
    }

    componentWillMount() {
        const data = {
            afm: this.props.afm,
            mid: this.props.mid,
            dateFrom: this.state.dateFrom,
            dateTo: this.state.dateTo,
        }

        this.runApprovedAndSettledCount(data)
    }

    componentWillReceiveProps(nextProps) {
        if (this.props.afm !== nextProps.afm || this.props.mid !== nextProps.mid) {
            this.setState({
                afm: nextProps.afm,
                mid: nextProps.mid
            }, () => {
                let data = {
                    afm: this.state.afm,
                    mid: this.state.mid,
                    dateFrom: this.state.dateFrom,
                    dateTo: this.state.dateTo
                }
                this.runApprovedAndSettledCount(data)
            })
        }

        if (nextProps.i18n !== this.props.i18n) {
            this.setState({
                settledAndApprovedTooltip: I18n.t('tooltips.hover.timeDropdown03')
            })
        }
    }

    runApprovedAndSettledCount(data) {
        this.approvedCount(data)
        this.settledCount(data)
    }

    approvedCount(data) {
        store.dispatch(approvedCount(data)).then((response) => {
            let approvedCount = response.value.data.payload[0].count ? response.value.data.payload[0].count : 0;
            this.setState({
                approvedCountData: approvedCount ? approvedCount  : 0
            })
        }, (error) => {
        })
    }

    settledCount(data) {
        store.dispatch(settledCount(data)).then((response) => {
            let settledCount = response.value.data.payload[0].count ? response.value.data.payload[0].count : 0;
            this.setState({
                settledCountData: settledCount ? settledCount : 0
            })
        }, (error) => {
        })
    }

    setNewDates(dateFrom, dateTo) {
        this.setState({
            dateFrom: dateFrom,
            dateTo: dateTo
        })
    }

    render() {
        const settledCountReady = this.props.chartReducer && this.props.chartReducer.settledCount
        const approvedCountReady = this.props.chartReducer && this.props.chartReducer.approvedCount
        const settledCountFetching = settledCountReady && this.props.chartReducer.settledCount.fetching
        const settledCountFetched = settledCountReady && this.props.chartReducer.settledCount.fetched
        const approvedCountFetching = approvedCountReady && this.props.chartReducer.approvedCount.fetching
        const approvedCountFetched = approvedCountReady && this.props.chartReducer.approvedCount.fetched
        const formattedSettledCount = this.state.settledCountData ? formatAmount(this.props.i18n.locale, this.state.settledCountData, 0) : 0;
        const formattedApprovedCount = this.state.approvedCountData ? formatAmount(this.props.i18n.locale, this.state.approvedCountData, 0) : 0;
        return (
            <div className="reports__counts reports__counts--transactions-count  reports__counts--chart">
                {settledCountFetched && approvedCountFetched ?
                    <React.Fragment>
                        <div className="reports--counts">
                        {this.props.userReducer.showHotSpots ?
                            <div className="hotSpot-element__wrapper">
                                <HotSpot
                                    hotProp="settledTransactions"
                                    placement="left"
                                />
                            </div>
                            : null}
                            <span>{formattedSettledCount}</span>
                            <div>
                                <span className="icon-circle-check charts-icon"></span>
                                <Translate value="pages.reports.approvedCount"/>
                            </div>
                        </div>
                        <div className="reports--counts">
                        {this.props.userReducer.showHotSpots ?
                            <div className="hotSpot-element__wrapper">
                                <HotSpot
                                    hotProp="pendingTransactions"
                                    placement="left"
                                />
                            </div>
                            : null}
                            <span>{formattedApprovedCount}</span>
                            <div>
                                <span className="icon-circle-clock charts-icon"></span>
                                <Translate value="pages.reports.settledCount"/>
                            </div>
                        </div>
                    </React.Fragment> : null}
                {settledCountFetching || approvedCountFetching ?
                    <Loader bouncing={false} color={'bahama-blue'} message=''/> : null
                }
                <div className="date-align-right__wrapper">
                    <div className="">
                        {this.props.renderDates(this.state.dateFrom, this.state.dateTo, "date-align-right__flex")}
                        <div className="date-align-right__flex">
                            <div title={this.state.settledAndApprovedTooltip}>
                                <ChartDropdown
                                    id="settled-and-approved-chart-timespan"
                                    chartMaps={this.props.chartMaps} data={{
                                    afm: this.props.afm,
                                    mid: this.state.mid
                                }} reRenderChart={this.runApprovedAndSettledCount} setDates={setChartDates}
                                    setDatesInParent={this.setNewDates}
                                    dropdownBaseValue="pages.reports.chart.dropdown.baseValueWeek"
                                />
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}

export default withRouter(connect(stateMap)(SettledAndApprovedChart));