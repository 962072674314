import React from "react";

import {withRouter} from "react-router-dom";
import connect from "react-redux/es/connect/connect";
import {DateTimePicker} from "react-widgets";
import moment from "moment";
import {
    blurInput,
    checkDateOlderThanToday,
    checkDateValidity,
    date1AfterOrEqualToDate2,
    date1OlderOrEqualToDate2
} from "../../lib/utils";
import momentLocalizer from "react-widgets-moment";
import store from "../../store";
import {
    getAvailableReports,
    getBranchReports,
    getCompanyReports,
    getCompanyReportsCount,
    getFusikoAerioReports
} from "../../providers/reports";
import SearchBox from "../common/SearchBox";
import {Panel} from 'react-bootstrap';
import {getMap} from "../../providers/rest";
import {
    approvedSettledExcel,
    bankTotalsExcel,
    cardModeTotalsExcel,
    cardSchemeTotalsExcel,
    countsTotalsExcel,
    installmentsExcel,
    redemptionExcel,
    transactionExcel
} from "../../providers/charts";
import HotSpot from "../common/HotSpot";
import Tooltip from "../common/Tooltip";
import {getMidsAndTidsByAfm} from "../../providers/merchants";
import Loader from "../common/Loader";
import {downloadBankTotalsReport} from "../../providers/bankTotals";

const Translate = require('react-redux-i18n').Translate;
const I18n = require('react-redux-i18n').I18n
const fileDownload = require('js-file-download');
const excelRowLimit = process.env.EXCEL_ROW_LIMIT ? parseInt(process.env.EXCEL_ROW_LIMIT, 10) : 1000000;


const stateMap = (store) => {
    return {
        i18n: store.i18n,
        merchantReducer: store.merchantReducer,
        userReducer: store.userReducer,
        rightColumnReducer: store.rightColumnReducer
    };
};

class DownloadReports extends React.Component {

    constructor(props) {
        super(props);

        const starterDate = moment().subtract(1, 'days');

        this.state = {
            dateFrom: starterDate.toDate(),
            dateTo: starterDate.toDate(),
            dateToDisabled: false,
            dateFromDisabled: false,
            reportsMap: null,
            availableReports: null,
            addedMidReportsToDropdown: false,
            uniqueAfms: null,
            uniqueMids: null,
            afmsInDropdownFormat: null,
            midsInDropdownFormat: null,
            loadingMids: false,
            reportsInDropdownFormat: [],
            pickedAfm: undefined,
            allMids: true,
            pickedMids: undefined,
            pickedMidsAfterDisabled: undefined,
            pickedReport: undefined,
            excelReport: true,
            baseAfm: I18n.t("pages.reports.search.allCompanies"),
            baseReport: I18n.t("pages.reports.search.allReports"),
            errorMessage: '',
            warningMessage: '',
            reportsDownloadTooltip: I18n.t("tooltips.hover.reportDownloadPanel"),
            showMultipleMidTooltip: false
        }

        this.changeDateFrom = this.changeDateFrom.bind(this)
        this.changeDateTo = this.changeDateTo.bind(this)
        this.makeUniqueValuesAndToDropdownFormat = this.makeUniqueValuesAndToDropdownFormat.bind(this)
        this.checkAllAssociationsForSingleValue = this.checkAllAssociationsForSingleValue.bind(this)
        this.getReportsIfAssociationHasSingleValue = this.getReportsIfAssociationHasSingleValue.bind(this)
        this.checkUniqueAssociationHasSingleValue = this.checkUniqueAssociationHasSingleValue.bind(this)
        this.setPickedAssociationToAvailableValue = this.setPickedAssociationToAvailableValue.bind(this)
        this.setAndReturnUniqueAfmsAndMids = this.setAndReturnUniqueAfmsAndMids.bind(this)
        this.getAllFromAssociationType = this.getAllFromAssociationType.bind(this)
        this.getFilteredFromAssociationType = this.getFilteredFromAssociationType.bind(this)
        this.removeDuplicatesAndRedundantValues = this.removeDuplicatesAndRedundantValues.bind(this)
        this.setAfmOrMidToSearchBoxFormat = this.setAfmOrMidToSearchBoxFormat.bind(this)
        this.handleDropdownChange = this.handleDropdownChange.bind(this)
        this.handleCheckBoxChange = this.handleCheckBoxChange.bind(this)
        this.handleReportDropdownChange = this.handleReportDropdownChange.bind(this)
        this.calculateDateFrom = this.calculateDateFrom.bind(this)
        this.calculateDateTo = this.calculateDateTo.bind(this)
        this.renderDateFromAndTo = this.renderDateFromAndTo.bind(this)
        this.calculateDateFromMax = this.calculateDateFromMax.bind(this)
        this.renderSearchBox = this.renderSearchBox.bind(this)
        this.getReports = this.getReports.bind(this)
        this.setReportsToDropdownFormat = this.setReportsToDropdownFormat.bind(this)
        this.getAvailableReports = this.getAvailableReports.bind(this)
        this.addChartAndRestMaps = this.addChartAndRestMaps.bind(this)
        this.handleWhenAfmNotHaveCentralCompany = this.handleWhenAfmNotHaveCentralCompany.bind(this)
        this.getReportsRegardlessCentralCompany = this.getReportsRegardlessCentralCompany.bind(this)
        this.addForMidOrForTaxIdFlag = this.addForMidOrForTaxIdFlag.bind(this)
        this.downloadReport = this.downloadReport.bind(this)
        this.formAllMidsValues = this.formAllMidsValues.bind(this)
        this.checkIfPickedReportHasDateTo = this.checkIfPickedReportHasDateTo.bind(this)
        this.checkIfPickedReportIsFusikoAerio = this.checkIfPickedReportIsFusikoAerio.bind(this)
        this.checkIfPickedReportIsBankTotals = this.checkIfPickedReportIsBankTotals.bind(this)
        this.changeTitleLocalizationOfReport = this.changeTitleLocalizationOfReport.bind(this)
        this.changeDateToDayMonthYearFormat = this.changeDateToDayMonthYearFormat.bind(this)
        this.returnDropdownToDefaultState = this.returnDropdownToDefaultState.bind(this)
        this.findMidInMidsDropdownArray = this.findMidInMidsDropdownArray.bind(this)
        this.removeBaseMidFromMidsDropdown = this.removeBaseMidFromMidsDropdown.bind(this)
        this.checkDateChangeRestrictions = this.checkDateChangeRestrictions.bind(this)
        this.checkDateChange = this.checkDateChange.bind(this)
        this.checkDateFromMaxIsToday = this.checkDateFromMaxIsToday.bind(this)
        this.checkDateChangeWhenBankTotalsReport = this.checkDateChangeWhenBankTotalsReport.bind(this)
        this.handleAfmChange = this.handleAfmChange.bind(this)
        this.deselectAllDropdownValues = this.deselectAllDropdownValues.bind(this)
        this.changeMidsCheckboxValue = this.changeMidsCheckboxValue.bind(this)
        this.checkIfPickedReportHasDefaultValue = this.checkIfPickedReportHasDefaultValue.bind(this)
        this.addMidReportsToDropdown = this.addMidReportsToDropdown.bind(this)
        this.resetSearchToDefault = this.resetSearchToDefault.bind(this)
        this.checkDownloadReportsPendingStatus = this.checkDownloadReportsPendingStatus.bind(this)
        this.disableDownloadButton = this.disableDownloadButton.bind(this)
        this.handleDateRanges = this.handleDateRanges.bind(this)
        this.getDatesDiff = this.getDatesDiff.bind(this)
        this.checkIfPickedReportIsLive = this.checkIfPickedReportIsLive.bind(this)
        this.checkIfPickedReportIs365ChartReport = this.checkIfPickedReportIs365ChartReport.bind(this)
        this.checkIfPickedReportIsDualFormat = this.checkIfPickedReportIsDualFormat.bind(this)
        this.downloadCompanyReport = this.downloadCompanyReport.bind(this)
        this.handleRadioChange = this.handleRadioChange.bind(this)
    }

    componentWillReceiveProps(nextProps) {
        if (nextProps.i18n.locale !== this.props.i18n.locale) {
            this.setState({
                    baseAfm: I18n.t("pages.reports.search.allCompanies"),
                    baseReport: I18n.t("pages.reports.search.allReports"),
                    reportsDownloadTooltip: I18n.t("tooltips.hover.reportDownloadPanel"),
                    ...(
                        this.state.warningMessage !== '' && this.state.warningMessage.props && this.state.warningMessage.props.value === 'pages.reports.download.warnings.excelRowLimitReached' && {
                            warningMessage: <Translate value="pages.reports.download.warnings.excelRowLimitReached"
                                                       report={this.state.pickedReport.label}
                                                       dangerousHTML/>
                        }
                    )
                }, () => {
                    if (this.state.pickedAfm !== undefined && this.state.availableReports !== null) {
                        this.setReportsToDropdownFormat(this.state.availableReports);
                        if (this.state.pickedReport !== undefined) {
                            const pickedReport = this.changeTitleLocalizationOfReport(nextProps);
                            this.setState({
                                pickedReport: pickedReport
                            })
                        }
                    }
                }
            )
        }
    }

    changeTitleLocalizationOfReport(props) {
        let pickedReport = this.state.pickedReport
        if (pickedReport.value) {
            pickedReport.label = props.i18n.locale === 'en' ? this.state.pickedReport.value.titleLatin :
                this.state.pickedReport.value.titleGreek
            return pickedReport;
        } else {
            pickedReport.label = I18n.t("pages.reports.search.allReports");
            return pickedReport;
        }
    }

    async componentWillMount() {
        this.getReports()
        const uniqueAfmsAndMids = await this.makeUniqueValuesAndToDropdownFormat();
        const singleAvailableValues = this.checkAllAssociationsForSingleValue(uniqueAfmsAndMids);
        this.getReportsIfAssociationHasSingleValue(singleAvailableValues.afmSingleAvailableValue, singleAvailableValues.midSingleAvailableValue.value)
    }

    getReports() {
        store.dispatch(getMap('reportsMap')).then((response) => {
                this.setState({
                    reportsMap: response.value.data
                })
            },
            (error) => {
            }
        )
    }

    async makeUniqueValuesAndToDropdownFormat(afmPicked) {
        let uniqueAfmsAndMids = await this.setAndReturnUniqueAfmsAndMids(afmPicked)
        this.setAfmsAndMidsToDropdownFormat(uniqueAfmsAndMids.uniqueAfms, uniqueAfmsAndMids.uniqueMids)

        return uniqueAfmsAndMids
    }

    checkAllAssociationsForSingleValue(uniqueAfmsAndMids) {
        let afmSingleAvailableValue = {value: undefined}
        let midSingleAvailableValue = {value: undefined}
        if (this.checkUniqueAssociationHasSingleValue(uniqueAfmsAndMids.uniqueAfms)) {
            afmSingleAvailableValue = this.setPickedAssociationToAvailableValue(
                'pickedAfm', uniqueAfmsAndMids.uniqueAfms)
        }
        if (this.checkUniqueAssociationHasSingleValue(uniqueAfmsAndMids.uniqueMids)) {
            midSingleAvailableValue = this.setPickedAssociationToAvailableValue(
                'pickedMids', uniqueAfmsAndMids.uniqueMids)
            this.changeMidsCheckboxValue(false)
        }

        return {afmSingleAvailableValue: afmSingleAvailableValue, midSingleAvailableValue: midSingleAvailableValue}
    }

    getReportsIfAssociationHasSingleValue(afm, midSingleAvailableValue) {
        if (afm.value) {
            this.getAvailableReports(afm.value, midSingleAvailableValue)
        }
    }

    setAfmsAndMidsToDropdownFormat(uniqueAfms, uniqueMids) {
        let afmsInDropdownFormat = this.setAfmOrMidToSearchBoxFormat(uniqueAfms, this.state.baseAfm)
        let midsInDropdownFormat = this.setAfmOrMidToSearchBoxFormat(uniqueMids, undefined, true)
        this.setState({
            afmsInDropdownFormat: afmsInDropdownFormat,
            midsInDropdownFormat: midsInDropdownFormat
        })
    }

    checkUniqueAssociationHasSingleValue(uniqueAssociationArray) {
        return uniqueAssociationArray.length === 1
    }

    setPickedAssociationToAvailableValue(associationType, uniqueAssociationArray) {
        this.setState({
            [associationType]: {value: uniqueAssociationArray[0]}
        })

        return {value: uniqueAssociationArray[0]}
    }

    async setAndReturnUniqueAfmsAndMids(afmPicked) {
        const uniqueAfms = this.props.userReducer.getAssociationsResponse.uniqueAfms;
        let uniqueMidsWithLocation = []
        await store.dispatch(getMidsAndTidsByAfm(1, -1, afmPicked)).then(
            (response) => {
                response.value.data.forEach(associations => {
                    associations.mids.forEach(midAndLocation => {
                        uniqueMidsWithLocation.push({
                            mid: midAndLocation.mid,
                            location: midAndLocation.location
                        })
                    })
                })
            });

        this.setState({
            uniqueAfms: uniqueAfms,
            uniqueMids: uniqueMidsWithLocation,
            loadingMids: false
        })

        return {uniqueAfms: uniqueAfms, uniqueMids: uniqueMidsWithLocation}
    }

    removeDuplicatesAndRedundantValues(associationWithDuplicates) {
        let uniqueAssociation = new Set(associationWithDuplicates)
        if (uniqueAssociation.has(null)) {
            uniqueAssociation.delete(null)

        } else if (uniqueAssociation.has(undefined)) {
            uniqueAssociation.delete(undefined)
        }
        return Array.from(uniqueAssociation)
    }

    returnDropdownToDefaultState(pickedVariableName, labelValue) {
        this.setState({
            [pickedVariableName]: this.state.afmsInDropdownFormat.length > 2 || pickedVariableName !== 'pickedAfm' ?
                {value: undefined, label: labelValue} :
                this.state.afmsInDropdownFormat[1]
        })
    }

    getFilteredFromAssociationType(associationsResponse, associationType, filterValue, filterName) {
        const filteredAssociations = associationsResponse.filter(company => {
            return company[filterName] === filterValue
        })
        return this.getAllFromAssociationType(filteredAssociations, 'mid')
    }

    getAllFromAssociationType(associationsResponse, associationType) {
        return associationsResponse.map(company => {
            return company[associationType]
        })
    }

    setAfmOrMidToSearchBoxFormat(originalArray, baseValue, midWithLocation) {
        let inDropdownFormat = originalArray.map(element => {
            if (midWithLocation) {
                const label = element.location ? element.mid.concat(" - ", element.location) : element.mid
                return {value: element.mid, label: label}
            }
            return {value: element, label: element}
        })
        if (baseValue) {
            this.addBaseValueToDropdown(inDropdownFormat, baseValue)
        }

        return inDropdownFormat
    }

    addBaseValueToDropdown(dropdown, baseValue) {
        dropdown.unshift({value: undefined, label: baseValue})

        return dropdown
    }

    changeDateFrom(dateFrom, dateFromMax) {
        if (this.checkDateChange(dateFrom) ||
            this.checkDateChangeWhenBankTotalsReport(dateFrom, dateFromMax)) {
            let dateTo = this.state.dateToDisabled ? dateFrom :
                (this.checkIfPickedReportIs365ChartReport(this.state.pickedReport) || this.checkIfPickedReportIsBankTotals(this.state.pickedReport)) && (this.state.dateTo.getTime() - dateFrom.getTime()) / (1000 * 3600 * 24) > 365 ?
                    moment(dateFrom).add(1, 'year').toDate() : this.state.dateTo
            this.setState({
                dateFrom: dateFrom,
                dateTo: dateTo,
                errorMessage: ''
            })
        }
    }

    checkDateChange(dateFrom) {
        return this.checkDateChangeRestrictions(dateFrom) &&
            (date1OlderOrEqualToDate2(dateFrom, this.state.dateTo) || this.state.dateToDisabled)
    }

    checkDateChangeWhenBankTotalsReport(dateFrom, dateFromMax) {
        return this.checkDateFromMaxIsToday(dateFromMax) &&
            date1OlderOrEqualToDate2(dateFrom, dateFromMax)
    }

    checkDateFromMaxIsToday(dateFromMax) {
        return moment(dateFromMax).isSame(moment(), 'day')
    }

    changeDateTo(dateTo) {
        if (this.checkDateChangeRestrictions(dateTo) &&
            date1AfterOrEqualToDate2(dateTo, this.state.dateFrom)) {
            let dateFrom = (this.checkIfPickedReportIs365ChartReport(this.state.pickedReport) || this.checkIfPickedReportIsBankTotals(this.state.pickedReport)) && (dateTo.getTime() - this.state.dateFrom.getTime()) / (1000 * 3600 * 24) > 365 ?
                moment(dateTo).subtract(1, 'year').toDate() : this.state.dateFrom
            this.setState({
                dateTo: dateTo,
                dateFrom: dateFrom
            })
        }
    }

    checkDateChangeRestrictions(date) {
        return checkDateValidity(date) && (checkDateOlderThanToday(date) || this.checkIfPickedReportIsBankTotals(this.state.pickedReport))
    }

    handleDropdownChange(variableName, value) {
        this.setState({
            [variableName]: value
        })
    }

    handleCheckBoxChange(event) {
        const checkBoxChecked = event.target.checked
        this.setState({
            [event.target.id]: checkBoxChecked,
            errorMessage: ''
        }, () => {
            if (checkBoxChecked) {
                this.setState({
                    pickedMidsAfterDisabled: this.state.pickedMids,
                    pickedMids: null
                })
                this.getAvailableReports(this.state.pickedAfm.value)
            } else if (!checkBoxChecked) {
                this.setState({
                    pickedMids: this.state.pickedMidsAfterDisabled,
                    pickedMidsAfterDisabled: null
                })
                if (this.state.pickedMidsAfterDisabled && this.state.pickedMidsAfterDisabled.length > 0) {
                    this.addMidReportsToDropdown(this.state.availableReports)
                }
            }
        })

        this.returnDropdownToDefaultState('pickedReport',
            this.state.baseReport)
    }

    handleReportDropdownChange(value) {
        const pickedReport = {
            label: this.props.i18n.locale === 'en' ? value.titleLatin :
                value.titleGreek, value: value
        }
        const dateToDisabled = !value.dateTo
        const dateFromDisabled = !value.dateFrom

        const dateFrom = this.calculateDateFrom(pickedReport)
        this.setState({
            pickedReport: pickedReport,
            dateToDisabled: dateToDisabled,
            dateFromDisabled: dateFromDisabled,
            dateTo: this.calculateDateTo(dateToDisabled, dateFrom, pickedReport),
            dateFrom: dateFrom,
        })
    }

    calculateDateFrom(pickedReport) {
        let dateFrom
        if (this.checkIfPickedReportIs365ChartReport(pickedReport)) {
            dateFrom = moment().subtract(1, 'month').subtract(1, 'days').toDate()
        } else if (this.checkIfPickedReportIsBankTotals(pickedReport)) {
            dateFrom = moment().subtract(1, 'month').toDate()
        } else if (this.checkIfPickedReportIsLive(pickedReport)) {
            dateFrom = moment().toDate()
        } else if (moment(this.state.dateFrom).isSame(moment(), 'day')) {
            dateFrom = moment().subtract(1, 'days').toDate()
        } else {
            dateFrom = this.state.dateFrom
        }

        return dateFrom
    }

    calculateDateTo(dateToDisabled, dateFrom, pickedReport) {
        let dateTo
        if (dateToDisabled) {
            dateTo = dateFrom
        } else if (this.checkIfPickedReportIsBankTotals(pickedReport) || this.checkIfPickedReportIsLive(pickedReport)) {
            dateTo = moment().toDate()
        } else if (moment(this.state.dateTo).isSame(moment(), 'day') || this.checkIfPickedReportIs365ChartReport(pickedReport)) {
            dateTo = moment().subtract(1, 'days').toDate()
        } else {
            dateTo = this.state.dateTo
        }

        return dateTo
    }

    renderDateFromAndTo(dateFromMax, dateFromMin) {
        return (
            <React.Fragment>
                <div className="datetime-picker__left">
                    <DateTimePicker value={this.state.dateFrom}
                                    time={false}
                                    onChange={dateFrom => this.changeDateFrom(dateFrom, dateFromMax)}
                                    disabled={this.state.dateFromDisabled}
                                    min={this.checkIfPickedReportIs365ChartReport(this.state.pickedReport) || this.checkIfPickedReportIsBankTotals(this.state.pickedReport) ?
                                        moment().subtract(1, 'year').toDate() : dateFromMin}
                                    max={dateFromMax}/>
                </div>
                <div className="datetime-picker__right">
                    <DateTimePicker value={this.state.dateTo}
                                    time={false}
                                    onChange={dateTo => this.changeDateTo(dateTo)}
                                    disabled={this.state.dateToDisabled}
                                    min={this.state.dateFrom}
                                    max={this.checkIfPickedReportIsBankTotals(this.state.pickedReport) ? moment().toDate() : moment().subtract(1, 'day').toDate()}/>
                </div>
            </React.Fragment>
        )
    }

    calculateDateFromMax() {
        let dateFromMax
        if (this.state.dateToDisabled) {
            dateFromMax = moment().subtract(1, 'day').toDate()
        } else {
            dateFromMax = this.state.dateTo
        }

        return dateFromMax
    }

    renderSearchBox(searchBoxObj) {
        return (<SearchBox options={searchBoxObj.options}
                           clsName={searchBoxObj.clsName}
                           value={searchBoxObj.value} onChange={searchBoxObj.onChange}
                           placeholder={searchBoxObj.placeholder}
                           isDisabled={searchBoxObj.isDisabled}
                           isMulti={searchBoxObj.isMulti}
                           blurBox = {this.state.allMids}
                           blurInput={blurInput}
            />
        )
    }

    getAvailableReports(taxId, midSingleAvailableValue) {
        store.dispatch(getAvailableReports(taxId)).then(({value: {data: {payload}}}) => {
                let midAndAvailableReports = {availableReports: [], pickedMids: undefined, midsInDropdownFormat: null}
                let availableHybridReportList = payload.reportsIds.filter(x => payload.hybridReportsIdList.includes(x))
                if (payload.hasCentralCompany) {
                    midAndAvailableReports.availableReports = this.getReportsRegardlessCentralCompany(payload)
                } else if (availableHybridReportList.length > 0) {
                    payload.reportsIds = availableHybridReportList
                    midAndAvailableReports.availableReports = this.getReportsRegardlessCentralCompany(payload)
                }

                this.addChartAndRestMaps(midAndAvailableReports.availableReports)
                this.setState({
                    availableReports: midAndAvailableReports.availableReports,
                    midsInDropdownFormat: midAndAvailableReports.midsInDropdownFormat !== null ?
                        midAndAvailableReports.midsInDropdownFormat : this.state.midsInDropdownFormat
                })
                if (midSingleAvailableValue && midSingleAvailableValue.mid) {
                    this.setState({
                        allMids: false
                    })
                    this.addMidReportsToDropdown(midAndAvailableReports.availableReports)
                } else {
                    this.setReportsToDropdownFormat(midAndAvailableReports.availableReports)
                }
            },
            (error) => {
            }
        )
    }

    addChartAndRestMaps(availableReports) {
        const chartMaps = this.state.reportsMap['forCharts']
        const restMaps = this.state.reportsMap['rest']
        chartMaps.forEach((report) => {
            let reportWithFlag = this.addForMidOrForTaxIdFlag(report, 'forCharts')
            availableReports.push(reportWithFlag)
        })
        restMaps.forEach((report) => {
            let reportWithFlag = this.addForMidOrForTaxIdFlag(report, 'rest')
            availableReports.push(reportWithFlag)
        })

        return availableReports
    }

    handleWhenAfmNotHaveCentralCompany(midAndAvailableReports, response) {
        midAndAvailableReports.pickedMid = this.findMidInMidsDropdownArray(this.state.midsInDropdownFormat,
            response.value.data.payload.mid)
        midAndAvailableReports.availableReports = this.getReportsRegardlessCentralCompany(response.value.data.payload,
            midAndAvailableReports.pickedMid)

        return midAndAvailableReports
    }

    findMidInMidsDropdownArray(mids, midToFind) {
        return mids.find(mid => {
            return mid.value === midToFind
        })
    }

    removeBaseMidFromMidsDropdown() {
        return this.state.midsInDropdownFormat.filter(element => {
            return element.value !== undefined
        })
    }

    setReportsToDropdownFormat(reports) {
        let reportsInDropdownFormat = this.setReportsToSearchBoxFormat(reports, this.state.baseReport)

        this.setState({
            reportsInDropdownFormat: reportsInDropdownFormat,
            loadingMids: false
        })
    }

    setReportsToSearchBoxFormat(originalArray) {
        let titleLocale = this.props.i18n.locale === 'en' ? 'titleLatin' : 'titleGreek'
        return originalArray.sort((a, b) => a.dropdownOrder - b.dropdownOrder).map(element => ({
            value: element,
            label: element[titleLocale]
        }))
    }

    getReportsRegardlessCentralCompany(response) {
        let availableReports = []
        response.reportsIds.forEach(reportId => {
            let report = this.state.reportsMap['forTaxId'].find(report => {
                return report.id === reportId
            })
            report = this.addForMidOrForTaxIdFlag(report, 'forTaxId')
            if (report !== undefined) {
                availableReports.push(report)
            }
        })

        return availableReports
    }

    addForMidOrForTaxIdFlag(report, flag) {
        if (report !== undefined) {
            report[flag] = true
        }

        return report
    }



    checkDownloadReportsPendingStatus() {
        return this.props.merchantReducer.bankTotalsReport.fetching ||
            this.props.rightColumnReducer.countsTotalsExcel.fetching ||
            this.props.rightColumnReducer.chartsTransactionExcel.fetching ||
            this.props.rightColumnReducer.chartsBankTotalsExcel.fetching ||
            this.props.rightColumnReducer.chartsCardSchemeTotalsExcel.fetching ||
            this.props.rightColumnReducer.chartsCardModeTotalsExcel.fetching ||
            this.props.rightColumnReducer.chartsApprovedSettledExcel.fetching ||
            this.props.rightColumnReducer.chartsInstallmentsExcel.fetching ||
            this.props.rightColumnReducer.chartsRedemptionExcel.fetching ||
            this.props.rightColumnReducer.branchReports.fetching ||
            this.props.rightColumnReducer.companyReports.fetching ||
            this.props.rightColumnReducer.fysikoAerioReports.fetching;
    }

    disableDownloadButton() {
        return this.state.pickedAfm === undefined ||
            this.state.pickedReport === undefined ||
            this.checkIfPickedReportHasDefaultValue() ||
            this.checkDownloadReportsPendingStatus();
    }

    downloadCompanyReport(dateFrom, dateTo, pickedMids) {
        store.dispatch(getCompanyReports(dateFrom, dateTo, this.state.pickedReport.value.code,
            this.state.pickedAfm.value, pickedMids,
            this.props.userReducer.impersonator !== null ? this.props.userReducer.impersonator.email : this.props.userReducer.profile.email,
            window.location.origin, this.props.i18n.locale, this.state.excelReport
        )).then((response) => {
                this.setState({
                    errorMessage: ''
                })
                if (response.value.status === 204) {
                    this.props.triggerLoader()
                } else {
                    fileDownload(response.value.data, response.value.headers.filename)
                }
            },
            () => {
                this.setState({
                    errorMessage: I18n.t("pages.reports.download.errors.downloadFailed"),
                })
            })

    }

    downloadReport() {
        let pickedMids = this.formAllMidsValues(this.state.pickedMids);
        let fixedDateRangeObj = this.handleDateRanges();
        this.setState({
            dateFrom: fixedDateRangeObj.dateFrom,
            warningMessage: fixedDateRangeObj.warningMessage
        }, () => {
            if (this.state.pickedReport.value.forTaxId === true) {
                let dateTo = undefined
                if (this.checkIfPickedReportHasDateTo()) {
                    dateTo = this.changeDateToDayMonthYearFormat(this.state.dateTo)
                }
                const dateFrom = this.changeDateToDayMonthYearFormat(this.state.dateFrom)
                if (this.checkIfPickedReportIsFusikoAerio()) {
                    store.dispatch(getFusikoAerioReports(this.changeDateToDayMonthYearFormat(this.state.dateFrom),
                        this.state.pickedAfm.value,
                        pickedMids
                    )).then((response) => {
                            fileDownload(response.value.data, response.value.headers.filename)
                        },
                        (error) => {
                        })
                } else {
                    if (this.checkIfPickedReportIsDualFormat(this.state.pickedReport) && this.state.excelReport) {
                        store.dispatch(getCompanyReportsCount(dateFrom, dateTo, this.state.pickedReport.value.code,
                            this.state.pickedAfm.value, pickedMids)).then((response) => {
                            let count = response.value.data ? response.value.data : 0;
                            let rowExceeded = count > excelRowLimit
                            this.setState({
                                warningMessage: rowExceeded ? <Translate value="pages.reports.download.warnings.excelRowLimitReached"
                                                                         report={this.state.pickedReport.label}
                                                                         dangerousHTML/> : '',
                                excelReport: !rowExceeded
                            }, () => {
                                this.downloadCompanyReport(dateFrom, dateTo, pickedMids)
                            })
                        }, () => {
                            this.setState({
                                errorMessage: I18n.t("pages.reports.download.errors.downloadFailed"),
                            })
                        })
                    } else {
                        this.downloadCompanyReport(dateFrom, dateTo, pickedMids)
                    }
                }
            } else if (this.state.pickedReport.value.forMid === true) {
                store.dispatch(getBranchReports(this.changeDateToDayMonthYearFormat(this.state.dateFrom),
                    this.state.pickedAfm.value, pickedMids, this.state.pickedReport.value.code, this.props.i18n.locale
                )).then((response) => {
                        fileDownload(response.value.data, response.value.headers.filename)
                        this.setState({
                            errorMessage: ''
                        })
                    },
                    (error) => {
                        if (error.response.headers.errors) {
                            const spittedError = error.response.headers.errors.split(',')
                            let midWithEqualsSign = spittedError[3].split('=')
                            this.setState({
                                errorMessage: <Translate value="pages.reports.download.errors.midCannotSeeReport"
                                                         mid={midWithEqualsSign[1]}
                                                         report={this.state.pickedReport.label}
                                                         dangerousHTML/>
                            })
                        }
                    })

            } else if (this.state.pickedReport.value.forCharts === true) {
                switch (this.state.pickedReport.value.code) {
                case "1":
                    store.dispatch(countsTotalsExcel({
                            dateFrom: this.changeDateToYearMonthDayFormat(this.state.dateFrom),
                            dateTo: this.changeDateToYearMonthDayFormat(this.state.dateTo),
                            afm: this.state.pickedAfm.value,
                            mid: pickedMids,
                            locale: this.props.i18n.locale
                        }
                    )).then((response) => {
                            fileDownload(response.value.data, response.value.headers.filename);
                        },
                        (error) => {
                        });
                    break;
                case "2":
                    store.dispatch(transactionExcel({
                            dateFrom: this.changeDateToYearMonthDayFormat(this.state.dateFrom),
                            dateTo: this.changeDateToYearMonthDayFormat(this.state.dateTo),
                            afm: this.state.pickedAfm.value,
                            mid: pickedMids,
                            locale: this.props.i18n.locale
                        }
                    )).then((response) => {
                            fileDownload(response.value.data, response.value.headers.filename);
                        },
                        (error) => {
                        });
                    break;
                case "3":
                    store.dispatch(bankTotalsExcel({
                            dateFrom: this.changeDateToYearMonthDayFormat(this.state.dateFrom),
                            dateTo: this.changeDateToYearMonthDayFormat(this.state.dateTo),
                            afm: this.state.pickedAfm.value, mid: pickedMids, locale: this.props.i18n.locale
                        }
                    )).then((response) => {
                            fileDownload(response.value.data, response.value.headers.filename);
                        },
                        (error) => {
                        });
                    break;
                case "4":
                    store.dispatch(cardSchemeTotalsExcel({
                            dateFrom: this.changeDateToYearMonthDayFormat(this.state.dateFrom),
                            dateTo: this.changeDateToYearMonthDayFormat(this.state.dateTo),
                            afm: this.state.pickedAfm.value, mid: pickedMids, locale: this.props.i18n.locale
                        }
                    )).then((response) => {
                            fileDownload(response.value.data, response.value.headers.filename);
                        },
                        (error) => {
                        });
                    break;
                case "5":
                    store.dispatch(cardModeTotalsExcel({
                            dateFrom: this.changeDateToYearMonthDayFormat(this.state.dateFrom),
                            dateTo: this.changeDateToYearMonthDayFormat(this.state.dateTo),
                            afm: this.state.pickedAfm.value, mid: pickedMids, locale: this.props.i18n.locale
                        }
                    )).then((response) => {
                            fileDownload(response.value.data, response.value.headers.filename);
                        },
                        (error) => {
                        });
                    break;
                case "6":
                    store.dispatch(approvedSettledExcel({
                            dateFrom: this.changeDateToYearMonthDayFormat(this.state.dateFrom),
                            dateTo: this.changeDateToYearMonthDayFormat(this.state.dateTo),
                            afm: this.state.pickedAfm.value, mid: pickedMids, locale: this.props.i18n.locale
                        }
                    )).then((response) => {
                            fileDownload(response.value.data, response.value.headers.filename);
                        },
                        (error) => {
                        });
                    break;
                case "7":
                    store.dispatch(installmentsExcel({
                            dateFrom: this.changeDateToYearMonthDayFormat(this.state.dateFrom),
                            dateTo: this.changeDateToYearMonthDayFormat(this.state.dateTo),
                            afm: this.state.pickedAfm.value, mid: pickedMids, locale: this.props.i18n.locale
                        }
                    )).then((response) => {
                            fileDownload(response.value.data, response.value.headers.filename);
                        },
                        (error) => {
                        });
                    break;
                case "8":
                    store.dispatch(redemptionExcel({
                            dateFrom: this.changeDateToYearMonthDayFormat(this.state.dateFrom),
                            dateTo: this.changeDateToYearMonthDayFormat(this.state.dateTo),
                            afm: this.state.pickedAfm.value, mid: pickedMids, locale: this.props.i18n.locale,
                            email: (this.props.userReducer.impersonator !== null ? this.props.userReducer.impersonator.email : this.props.userReducer.profile.email),
                            currentUrl: window.location.origin,
                        }
                    )).then((response) => {
                            this.setState({
                                errorMessage: ''
                            });
                            if (response.value.status === 204) {
                                this.props.triggerLoader();
                            } else {
                                fileDownload(response.value.data, response.value.headers.filename);
                            }
                        },
                        (error) => {
                            this.setState({
                                errorMessage: I18n.t("pages.reports.download.errors.downloadFailed"),
                            });
                        });
                    break;
                default:
                    break;
                }
            } else if (this.state.pickedReport.value.rest === true) {
                if (this.checkIfPickedReportIsBankTotals(this.state.pickedReport)) {
                    const bankTotalsMids = pickedMids ? pickedMids : this.state.uniqueMids.map(uniqueMid => {
                        return uniqueMid.mid
                    }).join()
                    store.dispatch(downloadBankTotalsReport(moment(this.state.dateFrom).format('DD/MM/YYYY'),
                        moment(this.state.dateTo).format('DD/MM/YYYY'),
                        bankTotalsMids, null,
                        (this.props.userReducer.impersonator !== null ? this.props.userReducer.impersonator.email : this.props.userReducer.profile.email),
                        window.location.origin,
                        I18n._locale)).then((response) => {
                            this.setState({
                                errorMessage: ''
                            })
                            if (response.value.status === 204) {
                                this.props.triggerLoader()
                            } else {
                                fileDownload(response.value.data, response.value.headers.filename)
                            }
                        },
                        (error) => {
                            this.setState({
                                errorMessage: I18n.t("pages.reports.download.errors.downloadFailed"),
                            })
                        })
                }
            }
        })
    }

    handleDateRanges() {
        let dateRangeType = this.state.pickedReport.value.dateRangeType;
        let dateRangeValue = this.state.pickedReport.value.dateRangeValue;

        let datesDiff = this.getDatesDiff(this.state.dateFrom, this.state.dateTo, dateRangeType) > dateRangeValue;
        let dateRange = datesDiff ? moment(this.state.dateTo).subtract(dateRangeValue, dateRangeType).toDate(): this.state.dateFrom;

        let dateRangeWarningMessage = '';
        let warningMessageKey = '';

        if (datesDiff) {
            if(dateRangeType === "days") {
                warningMessageKey = dateRangeValue > 0 ? "days" : (dateRangeValue === 0 ? "day": "");
            } else if (dateRangeType === "months") {
                warningMessageKey = dateRangeValue > 0 ? "months" : (dateRangeValue === 0 ? "month":"");
            }
            dateRangeWarningMessage = <Translate value={"pages.reports.download.warnings." + warningMessageKey}
                                                 dateRangeValue={dateRangeValue}
                                                 report={this.state.pickedReport.label}/>
        }

        return {dateFrom: dateRange, warningMessage: dateRangeWarningMessage};
    }


    getDatesDiff(dateFrom, dateTo, type) {
        return moment(dateTo).diff(moment(dateFrom), type, true)
    }

    formAllMidsValues(pickedMids) {
        if (this.state.allMids === false && pickedMids) {
            if (Array.isArray(pickedMids) && pickedMids.length > 0) {
                const allMidsValues = pickedMids.map(pickedMidObj => {
                    return pickedMidObj.value
                })
                return allMidsValues.join()
            } else {
                return pickedMids.value.mid
            }
        }

        return undefined
    }

    checkIfPickedReportHasDateTo() {
        return this.state.pickedReport.value.id === '9' || this.state.pickedReport.value.id === '10'
            || this.state.pickedReport.value.id === '11' || this.state.pickedReport.value.id === '12'
            || this.state.pickedReport.value.id === '15' || this.state.pickedReport.value.id === '19'
            || this.state.pickedReport.value.id === '20' || this.state.pickedReport.value.id === '21'
            || this.state.pickedReport.value.id === '22' || this.state.pickedReport.value.id === '23'
    }

    checkIfPickedReportIsFusikoAerio() {
        return this.state.pickedReport.value.id === '14'
    }

    checkIfPickedReportIsBankTotals(pickedReport) {
        return pickedReport && pickedReport.value && pickedReport.value.id === '24'
    }

    checkIfPickedReportIsLive(pickedReport) {
        return pickedReport && pickedReport.value && (pickedReport.value.id === '18' || pickedReport.value.id === '19')
    }

    checkIfPickedReportIs365ChartReport(pickedReport) {
        return pickedReport && pickedReport.value && ['9', '10', '11', '12', '15', '23', '20', '21', '22', '23'].includes(pickedReport.value.id)
    }

    checkIfPickedReportIsDualFormat(pickedReport) {
        return pickedReport && pickedReport.value && ['11', '12', '15', '20', '21'].includes(pickedReport.value.id)
    }

    changeDateToDayMonthYearFormat(date) {
        return moment(date).format('DD/MM/YYYY')
    }

    changeDateToYearMonthDayFormat(date) {
        return moment(date).format('YYYY-MM-DD')
    }

    async handleAfmChange(afm) {
        if (afm.value !== undefined) {
            this.deselectAllDropdownValues('pickedMids')
            this.changeMidsCheckboxValue(true)
            this.handleDropdownChange('pickedAfm', afm)
            const uniqueAfmsAndMids = await this.makeUniqueValuesAndToDropdownFormat(afm.value)
            const singleAvailableValues = this.checkAllAssociationsForSingleValue(uniqueAfmsAndMids)
            this.getReportsIfAssociationHasSingleValue(afm, singleAvailableValues.midSingleAvailableValue.value)
        } else {
            this.handleDropdownChange('pickedAfm', afm)
            this.deselectAllDropdownValues('pickedMids')
            this.changeMidsCheckboxValue(true)
            this.setState({
                loadingMids: false
            })
        }

        this.returnDropdownToDefaultState('pickedReport',
            this.state.baseReport)
        this.setState({
            errorMessage: ''
        })
    }

    deselectAllDropdownValues(pickedVariableName) {
        this.setState({
            [pickedVariableName]: null
        })
    }

    changeMidsCheckboxValue(value) {
        this.setState({
            allMids: value
        })
    }

    checkIfPickedReportHasDefaultValue() {
        return this.state.pickedReport && this.state.pickedReport.value === undefined
    }

    addMidReportsToDropdown(availableReports) {
        let midAvailableReports = []
        const midReportMap = this.state.reportsMap['forMid']
        midReportMap.forEach((report) => {
            let reportWithFlag = this.addForMidOrForTaxIdFlag(report, 'forMid')
            midAvailableReports.push(reportWithFlag)
        })
        this.setReportsToDropdownFormat(midAvailableReports.concat(availableReports))
    }

    resetSearchToDefault() {
        const starterDate = moment().subtract(1, 'days')
        this.setState({
            dateFrom: starterDate.toDate(),
            dateTo: starterDate.toDate(),
            pickedReport: this.state.baseReport
        })
        this.returnDropdownToDefaultState('pickedAfm', this.state.baseAfm)
        this.deselectAllDropdownValues('pickedMids')
        this.changeMidsCheckboxValue(true)
        this.setState({
            loadingMids: false
        })
    }

    handleRadioChange(event) {
        this.setState({
            excelReport: event.currentTarget.value === "true"
        })
    }

    render() {
        this.props.i18n.locale === 'el' ? moment.locale('el') : moment.locale('en');
        momentLocalizer();
        const isExcelReport = this.state.excelReport
        return (
            this.state.afmsInDropdownFormat && this.state.midsInDropdownFormat ?
                <div className="hotSpot-element__wrapper">
                    <Panel defaultExpanded
                           id="collapsible-panel-download-reports" onToggle={() => {
                    }}>
                        <Panel.Heading title={this.state.reportsDownloadTooltip}>
                            <Panel.Toggle componentClass="h6">
                                {"Reports"}
                                <i className="icon-plus-accordion"></i>
                                <i className="icon-minus-accordion"></i>
                            </Panel.Toggle>
                        </Panel.Heading>
                        <Panel.Collapse>
                            <Panel.Body>
                                <button className="panel-body__button-container__reset-link"
                                        onClick={this.resetSearchToDefault}>
                                    <Translate value="pages.transactions.searchModal.labels.clear"/>
                                </button>
                                <form onSubmit={(e) => {
                                    e.preventDefault();
                                    this.downloadReport();
                                }}>
                                    <h6>
                                        <Translate value="pages.reports.search.dateTimePick"/>
                                        <span>
                                            <Tooltip placement="left"
                                                     tooltipButtonId="reportsRightColumnTitle"
                                                     tooltipLabelClass="reports-right-column-title-label"
                                                     popoverId="reports-right-column-title-tltp"
                                                     tooltipText='reportsRightColumnTitleTltp'
                                            />
                                        </span>
                                    </h6>
                                    <div className="datetimepickers-wrapper">
                                        {this.renderDateFromAndTo(this.calculateDateFromMax())}
                                    </div>
                                    {this.state.afmsInDropdownFormat.length > 2 ?
                                        <React.Fragment>
                                            <h6>
                                                <Translate value="pages.reports.search.companyPick"/>
                                            </h6>
                                            {this.renderSearchBox({
                                                options: this.state.afmsInDropdownFormat,
                                                clsName: "reports__dropdowns", value: this.state.pickedAfm,
                                                onChange: (value) => {
                                                    this.setState({
                                                        loadingMids: true
                                                    })
                                                    this.handleAfmChange(value)


                                                },
                                                placeholder: "pages.reports.search.allCompanies"
                                            })}
                                        </React.Fragment>
                                        : null}
                                    {this.state.midsInDropdownFormat.length > 1 && !this.state.loadingMids ?
                                        <React.Fragment>
                                            <p className="reports-allmids-cbox">
                                                <span className="reports-allmids-cbox__wrapper">
                                                    <input type="checkbox"
                                                           name="select-allMids-checkbox"
                                                           className="reports-allmids-cbox"
                                                           id='allMids'
                                                           checked={this.state.allMids}
                                                           onChange={this.handleCheckBoxChange}
                                                           disabled={this.state.pickedAfm === undefined}
                                                    />
                                                    <label htmlFor='allMids'>
                                                        <Translate value="pages.reports.search.toAllStores"/>
                                                    </label>
                                                </span>
                                            </p>
                                            <div className="reports-allmids-select__wrapper">
                                                {this.renderSearchBox({
                                                    options: this.state.midsInDropdownFormat,
                                                    clsName: "reports__dropdowns reports-allmids-select",
                                                    value: this.state.pickedMids,
                                                    onChange: (value) => {
                                                        if (value.length === 0) {
                                                            this.getAvailableReports(this.state.pickedAfm.value)
                                                            this.setState({
                                                                allMids: true
                                                            })
                                                        } else if (value.length === 1) {
                                                            this.addMidReportsToDropdown(this.state.availableReports)
                                                        }
                                                        const midsWithNoLocation = value.map(element => {
                                                            return {value: element.value, label: element.value}
                                                        })
                                                        this.handleDropdownChange('pickedMids', midsWithNoLocation)
                                                        this.returnDropdownToDefaultState('pickedReport',
                                                            this.state.baseReport)
                                                        this.setState({
                                                            errorMessage: ''
                                                        })
                                                    },
                                                    placeholder: "pages.reports.search.toSpecificStores",
                                                    isDisabled: this.state.pickedAfm === undefined || this.state.allMids,
                                                    isMulti: true
                                                })}

                                                <div>
                                                    <Tooltip placement="left"
                                                             tooltipButtonId="reportDownloadPanelMultipleTid"
                                                             popoverId="report-download-multiple-mid-tltp"
                                                             tooltipLabelClass="multiple-tid-transaction-label"
                                                             tooltipText="reportDownloadPanelMultipleMID"
                                                    />
                                                </div>
                                            </div>
                                        </React.Fragment> : null}
                                    {!this.state.loadingMids && !this.props.rightColumnReducer.availableReports.fetching?
                                        <React.Fragment>
                                            <h6 className="reports__selection--title">
                                                <Translate value="pages.reports.search.reportPick"/>
                                            </h6>
                                            {this.renderSearchBox({
                                                options: this.state.reportsInDropdownFormat,
                                                clsName: "reports__dropdowns reports__selection " + (this.props.i18n.locale === "en" ? "en-width" : "el-width"),
                                                value: this.state.pickedReport,
                                                onChange: (value) => {
                                                    if (value.value !== undefined) {
                                                        this.handleReportDropdownChange(value.value)
                                                    } else {
                                                        this.handleDropdownChange('pickedReport', value.value)
                                                    }
                                                    this.setState({
                                                        errorMessage: '',
                                                        excelReport: true
                                                    })
                                                },
                                                placeholder: "pages.reports.search.allReports",
                                                isDisabled: this.state.pickedAfm === undefined || this.state.pickedAfm.value === undefined
                                            })}
                                        </React.Fragment> : <Loader bounceClass="smaller-bounces"
                                                                    bouncing={false} color={'bahama-blue'} message=''/>}
                                    {this.checkIfPickedReportIsDualFormat(this.state.pickedReport) ?
                                        <section className="reports-format-radius-section">
                                            <div className="format--radio">
                                                <input
                                                    id="radio1"
                                                    name="format"
                                                    type="radio"
                                                    className="format"
                                                    value="true"
                                                    onChange={this.handleRadioChange}
                                                    checked={isExcelReport}
                                                    required={true}
                                                />
                                                <label htmlFor="radio1">
                                                    <Translate value="pages.reports.search.radioButtons.xls"/>
                                                </label>
                                            </div>
                                            <div className="format--radio">
                                                <input
                                                    id="radio2"
                                                    name="format"
                                                    type="radio"
                                                    className="format"
                                                    value="false"
                                                    onChange={this.handleRadioChange}
                                                    checked={!isExcelReport}
                                                    required={true}
                                                />
                                                <label htmlFor="radio2">
                                                    <Translate value="pages.reports.search.radioButtons.csv"/>
                                                </label>
                                            </div>
                                        </section>
                                        : null

                                    }
                                    {/*Error messages*/}

                                    {this.state.errorMessage !== '' ?
                                        <div className="generic-error-msg">
                                            <span className="icon-icon-attention"/>
                                            <div>{this.state.errorMessage}</div>
                                        </div> : null
                                    }

                                    {/*Warning messages*/}

                                    {this.state.warningMessage !== '' ?
                                        <div className="generic-warning-msg">
                                            <span className="icon-icon-attention"/>
                                            <div>{this.state.warningMessage}</div>
                                        </div> : null
                                    }
                                    <div className="submit-reports-request__wrapper">
                                        <button type="submit" className={"submit-reports-request__btn " +
                                            (this.disableDownloadButton() ? "btn-disabled" : "")}
                                                disabled={this.disableDownloadButton()}>
                                            {this.checkDownloadReportsPendingStatus() ?
                                                <Loader bouncing={false} color={'affair'} message=''
                                                        style={{marginTop: 0}} bounceClass='smaller-bounces'/> :
                                                <Translate value="pages.reports.search.download"/>
                                            }
                                        </button>
                                    </div>
                                </form>
                            </Panel.Body>
                        </Panel.Collapse>
                    </Panel>
                    {this.props.userReducer.showHotSpots ?
                        <div className="hotSpot-element__wrapper">
                            <HotSpot
                                hotProp="downloadReports"
                                placement="right"
                            />
                        </div>
                        : null}
                </div>
                : null
        )
    }
}

export default withRouter(connect(stateMap)(DownloadReports));