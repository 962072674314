import React from 'react';
import LocaleSwitch from './LocaleSwitch';
import LogOut from '../userProcedures/LogOut';
import Logo from './Logo';
import store from "../../store";
import {changeDefaultPageOverview, changeInitialLogin} from "../../providers/users";
import {changeFirstTimeOverlay} from "../../actions/userActions";
import Wave from "./Wave";
import connect from "react-redux/es/connect/connect";
import {Modal} from "react-bootstrap";

const Translate = require('react-redux-i18n').Translate;

const stateMap = (store) => {
    return {
        userReducer: store.userReducer,
    };
};

class IntroScreen extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            checked: false,
            showModal: this.showModal()
        };

        this.handleCheck = this.handleCheck.bind(this);
        this.changeDefaultPageOverviewStatus = this.changeDefaultPageOverviewStatus.bind(this);
        this.setFirstTimeOverlayToFalse = this.setFirstTimeOverlayToFalse.bind(this);
        this.turnOffInitialModalForever = this.turnOffInitialModalForever.bind(this);
        this.showModal = this.showModal.bind(this);
    }


    changeDefaultPageOverviewStatus(status) {
        store.dispatch(changeDefaultPageOverview(status))
    }

    handleCheck(e) {
        this.setState({checked: !this.state.checked}, () => {
            this.changeDefaultPageOverviewStatus(this.state.checked)
        });
    }

    setFirstTimeOverlayToFalse() {
        store.dispatch(changeFirstTimeOverlay(false))
    }

    turnOffInitialModalForever() {
        store.dispatch(changeInitialLogin(true)).then(response => {
            this.setState({
                showModal: false
            })
        });
    }

    showModal() {
        let isInitialLogin = this.props.userReducer.profile.initialLogin;
        return !isInitialLogin;
    }

    render() {
        let typesArr = [
            // {name: 'interactiveTour', icon: "tour"},
            {name: "overview",
            icon: "dashboard"
        }, {name: "business", icon: "company"}, {name: "transactions", icon: "transactions"}, {
            name: "reports",
            icon: "reports"
        }];
        let currentYear = new Date().getFullYear();
        return (
            <React.Fragment>
                <div className="intro-screen">
                    <Modal
                        show={this.state.showModal}
                        onHide={this.turnOffInitialModalForever}
                        backdrop="static"
                        container={this}
                        dialogClassName="intro-dialog intro-dialog__intro-screen">
                        <Modal.Header closeButton>
                        </Modal.Header>
                        <Modal.Body>
                            <p>
                                <Translate value="startScreen.modal.caption" />
                            </p>
                           <article className="article__intro-screen">
                               <Translate value="startScreen.modal.text"/>
                           </article>

                        </Modal.Body>
                    </Modal>
                    <div className="intro-screen__top">
                        <div className="intro-screen__logout-locale">
                            <LocaleSwitch type={"intro-screen"}></LocaleSwitch>
                            <LogOut remainingTime={this.props.remainingTime} isImpersonator={this.props.userReducer.impersonator !== null}/>
                        </div>
                    </div>
                    <div className="intro-screen__content">
                        <div className="intro-screen__title">
                            <Translate value="startScreen.welcome"/>
                            <Logo isMain={false} isHeader={false} imgClassName="intro" isNotLink={true}/>
                        </div>
                        <nav className="intro-screen__menu">
                            {
                                typesArr.map((item) => {
                                    let title = 'startScreen.option.' + item.name + '.title';
                                    let text = 'startScreen.option.' + item.name + '.text';
                                    return [
                                        <a className={"intro-screen__menu__link" +
                                        (item.name === 'interactiveTour' ? ' intro-screen__menu__link_highlight' : '')}
                                           onClick={this.setFirstTimeOverlayToFalse}
                                           href={"/" + item.name} key={item}>
                                            {item.name === 'interactiveTour' ? [
                                                    <div className='intro-screen__menu__link_highlight__header'
                                                         key={'tile-highlighted'}>
                                                        <Translate value="startScreen.option.interactiveTour.newUser"/>
                                                    </div>]
                                                : null
                                            }
                                            <div className={"icon-icon-" + item.icon}>
                                            </div>
                                            <h5>
                                                <Translate value={title}/>
                                            </h5>
                                            {item.name !== 'interactiveTour' ?
                                                <p>
                                                    <Translate value={text}/>
                                                </p>
                                                : null}
                                        </a>
                                    ]
                                })
                            }
                            <div className="intro-screen__copyright">
                                {"©Cardlink " + currentYear}
                            </div>
                        </nav>
                        <div className="intro-screen__menu__option">
                            <input type="checkbox" id={'select-homepage-checkbox'} name="select-homepage-checkbox"
                                   value={this.state.checked}
                                   onChange={this.handleCheck}/>
                            <label htmlFor={'select-homepage-checkbox'}>
                                <Translate value="startScreen.selectHomepageLabel_a"/>
                                <b><Translate value="startScreen.selectHomepageLabel_b"/></b>
                                <Translate value="startScreen.selectHomepageLabel_c"/>
                                <Translate value="startScreen.selectHomepageLabel_d"/>
                                <span className="checkmark"></span>
                            </label>
                        </div>
                    </div>
                    <div className="intro-screen__wave-wrapper">
                        <Wave />
                    </div>
                </div>
            </React.Fragment>
        )
    }
}


export default connect(stateMap)(IntroScreen);