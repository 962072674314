import React from "react";
import one from "../../../assets/img/cardlink_one.png";
import {withRouter} from "react-router-dom";
import connect from "react-redux/es/connect/connect";
import GenericModal from "../common/GenericModal";
import Loader from "../common/Loader";

const Translate = require('react-redux-i18n').Translate;
const cardlinkOneUrl = process.env.CARDLINK_ONE_URL;

const stateMap = (store) => {
    return {
        merchantReducer: store.merchantReducer
    };
};

class OrderStatus extends React.Component {

    constructor(props) {
        super(props)
        this.state = {
            openOrderStatusModal: false
        }

        this.hideOrderStatusModal = this.hideOrderStatusModal.bind(this);
    }

    hideOrderStatusModal() {
        this.setState({
            openOrderStatusModal: false
        })
    }

    render() {
        let orderStatus = this.props.merchantReducer.orderStatus;
        return (
            <React.Fragment>
            <section className="order-status__container">
                {this.props.merchantReducer.merchantStatus.fetched ?
                <div className="order-status__wrapper">
                    <div className="order-status__logo">
                        <img src={one} alt="cardlink one logo" className="order-status__logo--icon"  />
                    </div>
                    <div className="order-status__btn">
                        <a href="javascript:void(0);"
                           onClick={() => {
                               this.setState({
                                   openOrderStatusModal: true
                               })
                           }} className={"order-status__btn--clickable order-status__btn--" + orderStatus}>
                            <Translate value={"orderStatus." + orderStatus + ".button"} />
                            <span className="order-status__btn--icon icon-icon-info" />
                        </a>
                    </div>
                </div>:null}
                {this.props.merchantReducer.merchantStatus.fetching ?
                    <Loader bouncing={false} color={'bahama-blue'}
                            message='orderStatus.loading'
                            style={{marginTop: 0}}
                            bounceClass='smaller-bounces'/>:null}
            </section>
            <GenericModal
                showModal={this.state.openOrderStatusModal}
                showCloseButton={true}
                onHide={this.hideOrderStatusModal}
                modalTitle={
                    <React.Fragment>
                    <img src={one} alt="Cardlink one logo" className="order-status__modal--title-icon" />
                    <Translate className="order-status__modal--title-text" value={"orderStatus." + orderStatus + ".modal.title"}/>
                    <br/>
                    </React.Fragment>
                }
                bsSize="small"
                modalClass='order-status__modal modal'
                modalBody={
                    <article className="order-status__modal--body">
                        <p>
                            <Translate value={"orderStatus." + orderStatus + ".modal.text"}
                                       mbsLink={cardlinkOneUrl} dangerousHTML/>
                        </p>
                        <div className="order-status__bottom-btn--container">
                            <a href={orderStatus !== "cancelled" ? "javascript:void;" : "/cardlink"}
                               target={orderStatus !== "cancelled" ? "_self" : "_blank"}
                               className="order-status__bottom-btn" onClick={() => {
                                this.setState(prevState => ({
                                    openOrderStatusModal: !prevState.openOrderStatusModal
                                }));
                            }}>
                                <Translate value={"orderStatus." + orderStatus + ".modal.bottomBtn"} />
                            </a>
                        </div>
                    </article>
                    }/>

            </React.Fragment>
        )
    }
}

export default withRouter(connect(stateMap)(OrderStatus));