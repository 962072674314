export default function emailReducer(state = {
    postage: {
        fetching: false,
        fetched: false
    }
    }, action) {
    switch (action.type) {
        case "POST_SUPPORT_EMAIL_PENDING": {
            return {
                ...state,
                postage: {
                    fetching: true,
                    fetched: false
                }
            }
        }
        case "POST_SUPPORT_EMAIL_REJECTED": {
            return {
                ...state,
                postage: {
                    fetching: false,
                    fetched: false
                }
            }
        }
        case "POST_SUPPORT_EMAIL_FULFILLED": {
            return {
                ...state,
                postage: {
                    fetching: false,
                    fetched: true
                }
            }
        }
        default : {
            return state
        }
    }
}