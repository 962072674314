import React from "react";
import {formatAmount} from "../../lib/utils";

const Translate = require('react-redux-i18n').Translate;
const I18n = require('react-redux-i18n').I18n

export default class BankTotalsTable extends React.Component {
    constructor(props) {
        super(props)
        this.numberFormat = this.numberFormat.bind(this)
    }

    numberFormat(val, prefix = '€ ') {
        if (Number(val) !== 0)
            return (prefix + formatAmount(I18n._locale, Number(val), 2))
        return '€ ' + formatAmount(I18n._locale, val, 2)
    }

    render() {
        return (
            <div>
                <div className="bank-totals-title-wrapper">
                    <h4 className="section_title">
                        {this.props.title}
                    </h4>
                    <h4 className=" label-to-right">
                        {this.props.locationTitle}
                    </h4>
                </div>
                <table className='bank-totals-table'>
                    <thead>
                    <tr>
                        <th></th>
                        <th colSpan={2}>
                            <Translate value={'pages.bankTotals.table.titles.purchase'}/>
                        </th>
                        <th colSpan={2}>
                            <Translate value={'pages.bankTotals.table.titles.refund'}/>
                        </th>
                        <th colSpan={2}>
                            <Translate value={'pages.bankTotals.table.titles.void'}/>
                        </th>
                        <th colSpan={2}>
                            <Translate value={'pages.bankTotals.table.titles.redemption'}/>
                        </th>
                        <th colSpan={2}>
                            <Translate value={'pages.bankTotals.table.titles.sum'}/>
                        </th>
                    </tr>
                    </thead>
                    <tbody>
                    {this.props.data.map(element => {
                        return (
                            <tr key={element.acquirerId[I18n._locale]}>
                                <td>{element.acquirerId[I18n._locale]}</td>
                                <td>{formatAmount(I18n._locale, element.purchaseCount, 0)}</td>
                                <td>{this.numberFormat(element.purchaseSum)}</td>
                                <td>{formatAmount(I18n._locale, element.refundCount, 0)}</td>
                                <td>{this.numberFormat(element.refundSum, '-')}</td>
                                <td>{formatAmount(I18n._locale, element.voidCount, 0)}</td>
                                <td>{this.numberFormat(element.voidSum, '-')}</td>
                                <td>{formatAmount(I18n._locale, element.redemptionCount, 0)}</td>
                                <td>{this.numberFormat(element.redemptionSum)}</td>
                                <td>{formatAmount(I18n._locale, element.sumCount, 0)}</td>
                                <td>{this.numberFormat(element.sumAmount)}</td>
                            </tr>
                        )
                    })}
                    </tbody>
                </table>
            </div>
        )
    }

}