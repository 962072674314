import React from "react";
import {DropdownButton, MenuItem, OverlayTrigger, Panel, Popover} from "react-bootstrap";
import TerminalBankDetails from "./TerminalBankDetails"
import BillingMethod from "./BillingMethod"
import store from "../../store";
import {getMap} from "../../providers/rest";
import PopoverTerminalAlias from "./PopoverTerminalAlias";
import Tooltip from "../common/Tooltip";
import {toggleInfoTooltips} from "../../actions/userActions";

const Translate = require('react-redux-i18n').Translate

export default class TerminalDetails extends React.Component {
    constructor(props) {
        super(props)
        this.state = {
            mid: props.mid,
            data: props.data,
			tid: props.selectedTid !== null && props.selectedTid !== undefined ? this.findData(props.selectedTid, props.data) : props.data[0],
            posMap: null,
            active: [],
            inactive: [],
            aliasFormShow: false
        };
        this.getMap = this.getMap.bind(this)
        this.fillActiveInactivePos = this.fillActiveInactivePos.bind(this)
        this.findData = this.findData.bind(this)
        this.handleChange = this.handleChange.bind(this)
        this.openAliasForm = this.openAliasForm.bind(this)
        this.handleTerminalAliasChange = this.handleTerminalAliasChange.bind(this)
        this.toggleTerminalAlias = this.toggleTerminalAlias.bind(this)
    }

    componentWillMount() {
        this.getMap('posMap')
        this.props.setTidForSupport(this.state.tid.tid)
    }

    getMap(mapName) {
        store.dispatch(getMap(mapName)).then((response) => {
                this.setState({
                    posMap: response.value.data
                }, () => {
                    this.fillActiveInactivePos()
                })
            },
            (error) => {
            }
        )
    }

    fillActiveInactivePos() {
        let active = []
        let inactive = []
        this.state.data.forEach(tid => {
            if (this.state.posMap.active.includes(tid.posStatusCode)) {
                active.push(tid.tid)
            } else {
                inactive.push(tid.tid)
            }
        })
        this.setState({
            active: active,
            inactive: inactive,
        })
    }

    findData(tid, data) {
        return data.find((e) => e.tid === tid)
    }

    handleChange(tid) {
        this.setState({
            tid: this.findData(tid, this.state.data)
        }, () => {
            this.props.setTidForSupport(tid)
        })
    }

    openAliasForm() {
        this.setState((prevState) => {
            return {
                aliasFormShow: !prevState.aliasFormShow
            }
        }, () => {
            if(this.state.aliasFormShow) {
                store.dispatch(toggleInfoTooltips("showInfo__business_tid_alias_tltp", false))
            }
        })
    }

    toggleTerminalAlias(e) {
        if (!e) {
            this.refs.overlayTerminalAliasRef.hide();
        } 
    }

    handleTerminalAliasChange(tid, alias) {
        const data = this.state.data
        data.find((e) => e.tid === tid).terminalAlias = alias
        this.setState({
            data: data
        })
    }

    render() {
        const tid = this.state.tid;
        const baseTranslate = "pages.business.tidDetails";
        let statusCodes = this.props.locale === "en" ? "statusCodes" : "statusCodesGr";
        const popoverTerminalAlias = (
            <Popover id={"business-terminalAlias-popover-positioned-bottom"}>
                <PopoverTerminalAlias
                    mid={this.state.mid}
                    tid={this.state.tid.tid}
                    terminalAlias={this.state.tid.terminalAlias}
                    overlayRef={this.refs.overlayTerminalAliasRef}
                    handleTerminalAliasChange={this.handleTerminalAliasChange}
                    updateTerminalAllias={this.props.updateTerminalAllias}
                />
            </Popover>
        )
        return ( [
            <Panel id="collapsible-panel-terminal-details"
                   onToggle={this.toggleTerminalAlias}
                   key="collapsible-panel-terminal-details" defaultExpanded>
                <Panel.Heading>
                    <Panel.Toggle componentClass="h4">
                        {tid.ecom ?
                            <Translate value="pages.business.tidDetails.ecomTitle"
                                       key="business-tidDetails-ecomTitle"/>
                            :
                            <Translate value="pages.business.tidDetails.title"
                                        key="business-tidDetails-title"/>
                        }
                        <i className="icon-plus-accordion"></i>
                        <i className="icon-minus-accordion"></i>
                    </Panel.Toggle>
                </Panel.Heading>
                <Panel.Collapse>
                    <Panel.Body>
                    {this.state.posMap !== null ?
                     <div className="business-column">
                        {!tid.ecom ?
                            <div className="business-row__pos">
                                <div className="active-state__column active-state__column--left">
                                    <div className="active-state__pos">
                                        <span className="active-state__circle active-state__green"></span>
                                        <Translate className="active-state__label" value="pages.business.tidDetails.activeLabel"/>
                                        <span className="active-state__value">({this.state.active.length})</span>
                                    </div>
                                    <div className="inactive-state__pos">
                                        <span className="active-state__circle active-state__red"></span>
                                        <Translate className="active-state__label" value="pages.business.tidDetails.inactiveLabel"/>
                                        <span className="active-state__value">({this.state.inactive.length})</span>
                                    </div>
                                </div>
                                <div className="active-state__row active-state__column--dropdowns">
                                    <div className="active-state__column">
                                        {!tid.ecom ?
                                            <div className="active-state__column--label"
                                                 key="business-tidDetails-column--label">
                                                <Translate value="pages.business.tidDetails.dropdownsActivePosLabel" />
                                            </div>:null
                                        }
                                        <DropdownButton
                                            disabled={this.state.active.length <= 0}
                                            title={<Translate
                                            value={this.state.active.length > 0 ? (baseTranslate + ".activeDropdown") : (baseTranslate + ".emptyActiveDropdown")}/>}
                                            id="dropdown-active-pos">
                                            {this.state.active ? (
                                                this.state.active.map((tid) => {
                                                        return [
                                                            <MenuItem key={tid}
                                                                      onSelect={() => {
                                                                          this.handleChange(tid)
                                                                      }}
                                                            >{tid}
                                                            </MenuItem>
                                                        ]
                                                    }
                                                )) : null
                                            }
                                        </DropdownButton>
                                    </div>
                                    {this.state.inactive.length > 0 ?
                                        <div className="active-state__column">
                                            {!tid.ecom ?
                                                <div className="active-state__column--label"
                                                     key="business-tidDetails-column--label">
                                                    <Translate value="pages.business.tidDetails.dropdownsInactivePosLabel" />
                                                </div>:null
                                            }
                                            <DropdownButton
                                                title={<Translate
                                                value={this.state.inactive.length > 0 ? (baseTranslate + ".inactiveDropdown") : (baseTranslate + ".emptyInactiveDropdown")}/>}
                                                id="dropdown-inactive-pos">
                                                {this.state.inactive ? (
                                                    this.state.inactive.map((tid) => {
                                                            return [
                                                                <MenuItem key={tid}
                                                                          onSelect={() => {
                                                                              this.handleChange(tid)
                                                                          }}
                                                                >{tid}
                                                                </MenuItem>
                                                            ]
                                                        }
                                                    )) : null
                                                }
                                            </DropdownButton>
                                        </div>
                                    : null}
                                </div>
                            </div>
                            : null}
                            <div className="business-row business-row__details business-row__tid-values__top">
                                <div className="business-details__column business-details__column--duo">
                                    <Translate value="pages.business.tidDetails.tidTitle"/>
                                    <div className="business-details__value">{tid.tid ? tid.tid : '-'}</div>
                                </div>
                                <div className="alias-tltps-wrapper business-details__column">
                                    <Translate value="pages.business.tidDetails.tidAlias"/>
                                    <Tooltip placement="right"
                                             tooltipButtonId="businessTidAlias"
                                             tooltipLabelClass="business-tid-alias-label"
                                             popoverId="business-tid-alias-tltp"
                                             tooltipText='tooltipTid'
                                    />
                                    <OverlayTrigger trigger="click"
                                                    placement="bottom"
                                                    overlay={popoverTerminalAlias}
                                                    ref="overlayTerminalAliasRef"
                                                    container={!this.props.singleAfmMid ? this.props.modalRef: undefined}>
                                        <div onClick={() => this.openAliasForm()}
                                                className="editable-field__btn business-details__column business-details__column--duo">
                                            <div className="editable-field__wrapper">
                                                { tid.terminalAlias ?
                                                    <span className="editable-field__value">{tid.terminalAlias}</span> :
                                                    <Translate className="editable-field__no-value" value="pages.business.midDetails.provideAlias" />
                                                }
                                                <span className={"editable-field__icon icon-edit-pencil " + (tid.terminalAlias ? 'icon-orange' : 'icon-gray')}></span>
                                            </div>
                                        </div>
                                    </OverlayTrigger>
                                </div>
                                {!tid.ecom ? [
                                        <div className="business-details__column business-details__column--serial-num"
                                            key="business-details__column--serial-num">
                                            <Translate value="pages.business.tidDetails.serialNumber"/>
                                            <div className="business-details__value">{tid.serialNumber ? tid.serialNumber : '-'}</div>
                                        </div>,
                                        <div className="business-details__column business-details__column--status-pos"
                                            key="business-details__column--status-pos">
                                            <Translate value="pages.business.tidDetails.status"/>
                                            <div className="business-details__value">{this.state.posMap[statusCodes][tid.posStatusCode]}</div>
                                        </div>
                                    ]: [
                                    <div className="business-details__column"
                                            key="business-tidDetails-interconnection">
                                        <Translate value="pages.business.tidDetails.interconnection"/>
                                        <div className="business-details__value">{tid.interconnection ? tid.interconnection : '-'}</div>
                                    </div>
                                    ]
                                }
                            </div>
                            {!tid.ecom ?
                                <div className="business-row business-row__details business-row__tid-values__bottom"
                                        key="business-row__details_tid-values__bottom">
                                    <div className="business-details__column business-details__column--duo"
                                        key="business-details__columns__column--duo-a">
                                        <Translate value="pages.business.tidDetails.model"/>
                                        <div className="business-details__value">{tid.modelName ? tid.modelName : '-'}
                                        </div>
                                    </div>
                                    <div className="business-details__column business-details__column--duo"
                                        key="business-details__columns__column--duo-b">
                                        <Translate value="pages.business.tidDetails.type"/>
                                        <div className="business-details__value">{tid.terminalTypeField ? tid.terminalTypeField : '-'}</div>
                                    </div>
                                    <div className="business-details__column business-details__column--duo"
                                         key="business-details__columns__column--duo-c">
                                        <Translate value="pages.business.tidDetails.technology"/>
                                        <div className="business-details__value">{tid.technologyTypeField ? tid.technologyTypeField : '-'}</div>
                                    </div>
                                    <div className="business-details__column business-details__column--duo"
                                         key="business-details__columns__column--duo-d">
                                        <Translate value="pages.business.tidDetails.contactless"/>
                                        <div className="business-details__value">
                                            {tid.contactlessFlag ?
                                                <Translate value="common.answers.yes"/> :
                                                <Translate value="common.answers.no"/>
                                            }
                                        </div>
                                    </div>
                                    <div className="business-details__column business-details__column--duo"
                                         key="business-details__columns__column--duo-e">
                                        <Translate value="pages.business.tidDetails.pinPad"/>
                                        <div className="business-details__value">
                                            {tid.general_features === 'POSwith Pin Reader' ?
                                                <Translate value="common.answers.yes"/> :
                                                <Translate value="common.answers.no"/>
                                            }
                                        </div>
                                    </div>
                                    <div className="business-details__column business-details__column--duo"
                                         key="business-details__columns__column--duo-f">
                                        <Translate value="pages.business.tidDetails.ecr"/>
                                        <div className="business-details__value">
                                            {tid.ecrFunctionality !== null && tid.ecrFunctionality !== '210000' ?
                                                <Translate value="common.answers.yes"/> :
                                                <Translate value="common.answers.no"/>
                                            }
                                        </div>
                                    </div>
                                </div>
                                : null}
                        <TerminalBankDetails
                            data={tid.tidBankData}
                            isEcom={tid.ecom}
                            locale={this.props.locale}
                        />
                    </div> : null}
                    </Panel.Body>
                </Panel.Collapse>
            </Panel>,
            <BillingMethod
                data={tid}
                locale={this.props.locale}/>]
        )
    }
}