import React from "react";
import {connect} from "react-redux";
import {withRouter} from "react-router-dom";
import {Panel} from "react-bootstrap";
import store from "../../../store";
import {
    downloadOneDebitNotesFile,
    downloadOneDebitNotesPdfFile,
    getDebitNotesList,
    getDebitNotesTotalData,
    getOneDebitNotificationList
} from "../../../providers/authorizations";
import DebitNotesCriteriaForm from "./DebitNotesCriteriaForm";
import DebitNotesOverview from "./DebitNotesOverview";
import moment from "moment";
import {formatAmount} from "../../../lib/utils";
import DebitNotesDetails from "./DebitNotesDetails";
import {setNewDebitNotesAction} from "../../../actions/debitNotesActions";

const fileDownload = require('js-file-download')
const Translate = require('react-redux-i18n').Translate;
const I18n = require('react-redux-i18n').I18n;


const stateMap = (store) => {
    return {
        i18n: store.i18n,
        userReducer: store.userReducer,
        debitNotesReducer: store.debitNotesReducer
    };
};

class DebitNotes extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            debitNotesPanelOpen: false,
            afm: props.afm,
            mid: props.mid,
            email: props.email,
            tids: props.tids,
            selectedMonth: {},
            debitNoteYear: null,
            debitNoteMonth: null,
            dateFrom: moment().clone().startOf('month'),
            dateTo: moment().clone().endOf('month'),
            minDateFrom: moment().clone().startOf('month'),
            maxDateTo: moment().clone().endOf('month'),
            currentPage: 1,
            asc: false,
            by: "payment_resp_date",
            rowsPerPage: 20,
            total: null,
            lastAvailableMonth: new Date(),
            selectedTids: [],
            cardSchemes: [],
            selectedCardSchemes: [],
            batches: [],
            selectedBatches: [],
            schemeData: [],
            feesSum: null,
            dateRangeLabel: null,
            extraDetails: false,
            newDebitNotes: props.debitNotesReducer.newDebitNotes,
            debitNotesTotalData: [],
            disableExport: false
        };

        this.changeSelectedMonth = this.changeSelectedMonth.bind(this);
        this.changeDateFrom = this.changeDateFrom.bind(this);
        this.changeDateTo = this.changeDateTo.bind(this);
        this.getFirstAndLastMonthDay = this.getFirstAndLastMonthDay.bind(this);
        this.changeSelectedTids = this.changeSelectedTids.bind(this);
        this.changeSelectedCardSchemes = this.changeSelectedCardSchemes.bind(this);
        this.changeSelectedBatches = this.changeSelectedBatches.bind(this);
        this.setFeesSum = this.setFeesSum.bind(this);
        this.dateRangeLabel = this.dateRangeLabel.bind(this);
        this.setDebitNotesTotalData = this.setDebitNotesTotalData.bind(this);
        this.setDebitNotesData = this.setDebitNotesData.bind(this);
        this.mapDebitNotes = this.mapDebitNotes.bind(this);
        this.adjustTable = this.adjustTable.bind(this);
        this.createDebitNoteObject = this.createDebitNoteObject.bind(this);
        this.generateDebitNoteFile = this.generateDebitNoteFile.bind(this);
        this.generateDebitNotesPdfFile = this.generateDebitNotesPdfFile.bind(this);
    }

    componentDidMount(){
        this.setState({
            afm: this.props.afm,
            mid: this.props.mid,
            email: this.props.email,
            tids: this.props.tids,
            lastAvailableMonth: new Date(),
            dateRangeLabel: this.dateRangeLabel(this.state.dateFrom, this.state.dateTo),
            newDebitNotes: this.props.debitNotesReducer.newDebitNotes,
        }, ()=> {
            this.setDebitNotesTotalData()
            store.dispatch(getOneDebitNotificationList(this.state.afm, this.state.mid, this.state.email, 0)).then((resp) => {

            })
        });

        this.fakeNotification = setInterval(
            () => {
                if (!this.state.debitNotesPanelOpen) {
                    store.dispatch(setNewDebitNotesAction(true))
                }
            },
            30000
        );
    }

    componentWillUnmount() {
        clearInterval(this.fakeNotification);
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        if (prevProps.i18n.locale !== this.props.i18n.locale) {
            this.setState({
                feesSum: this.setFeesSum()
            })
        }
        if (prevProps.debitNotesReducer.newDebitNotes !== this.props.debitNotesReducer.newDebitNotes) {
            this.setState({
                newDebitNotes: this.props.debitNotesReducer.newDebitNotes
            })
        }
    }

    changeSelectedMonth(currentMonth) {
        let dateRange = this.getFirstAndLastMonthDay(currentMonth.value);
        this.setState({
            selectedMonth: currentMonth,
            debitNoteYear: currentMonth.value ? currentMonth.value.getYear() + 1900 : null,
            debitNoteMonth: currentMonth.value ? currentMonth.value.getMonth() + 1 : null,
            dateFrom: dateRange.first,
            dateTo: dateRange.last,
            minDateFrom: dateRange.first,
            maxDateTo: dateRange.last,
            dateRangeLabel: this.dateRangeLabel(dateRange.first, dateRange.last),
        }, () => {
            this.setDebitNotesTotalData()
        })
    }


    changeDateFrom(dateFrom) {
        this.setState({
            dateFrom: dateFrom
        })
    }

    changeDateTo(dateTo) {
        this.setState({
            dateTo: dateTo
        })
    }

    getFirstAndLastMonthDay(month) {
        let first = moment(month, 'DD/MM/YYYY').startOf('month').toDate();
        let last= moment(month, 'DD/MM/YYYY').endOf('month').toDate();

        return {first: first, last: last}
    }

    changeSelectedTids(currentTids) {
        this.setState({
            selectedTids: currentTids
        })
    }

    changeSelectedCardSchemes(currentSchemes) {
        this.setState({
            selectedCardSchemes: currentSchemes
        })
    }

    changeSelectedBatches(currentBatches) {
        this.setState({
            selectedBatches: currentBatches
        })
    }

    setFeesSum() {
        return formatAmount(I18n._locale, this.state.schemeData.reduce((a, b) => +a + +parseFloat(b.amount), 0), 2) + " €"
    }

    dateRangeLabel(dateFrom, dateTo) {
        let dFrom = moment(dateFrom).format('DD/MM/YYYY');
        let dTo = moment(dateTo).format('DD/MM/YYYY');
        return (dFrom + " - " + dTo).toString();
    }

    setDebitNotesTotalData() {
        let data = this.createDebitNoteObject()
        store.dispatch(getDebitNotesTotalData(data)).then((cardTotalsResponse) => {
            let cardTotalsResponseData = cardTotalsResponse.value.data.cardTotals
            let batches = cardTotalsResponse.value.data.batches.map(batch => {
                return {label: batch, value: batch}
            })
            let totalAcquirerFees = cardTotalsResponseData.reduce((a, b) => a + (b.feesSum ? parseFloat(b.feesSum) : 0), 0)
            let feesSum = formatAmount(I18n._locale, totalAcquirerFees, 2) + " €"
            let cardSchemes = []
            let schemeData = cardTotalsResponseData.map((card) => {
                cardSchemes.push({label: card.cardScheme, value: card.cardScheme})
                let amount = card.feesSum ? parseFloat(card.feesSum) : 0
                return {
                    cardScheme: card.cardScheme,
                    amount: amount,
                    feesSum: formatAmount(I18n._locale, amount, 2),
                    percentage: totalAcquirerFees !== 0 ? amount / totalAcquirerFees * 100 : 0
                }
            })
            this.setState({
                cardSchemes: cardSchemes,
                schemeData: schemeData,
                feesSum: feesSum,
                batches: batches,
                dateRangeLabel: this.dateRangeLabel(this.state.dateFrom, this.state.dateTo)
            }, () => {
                this.setDebitNotesData(data.page, data.rowsPerPage, data.by, data.asc)
            })
        })
    }

    setDebitNotesData(page, rowsPerPage, sortField, sortOrder) {
        let data = this.createDebitNoteObject(page, rowsPerPage, sortField, sortOrder)
        store.dispatch(getDebitNotesList(data)).then((resp) => {
            let responseData = resp.value.data.payload.payload
            let debitNotesData = responseData ? this.mapDebitNotes(responseData) : []
            this.setState({
                debitNotesTotalData: debitNotesData,
                total: resp.value.data.payload.total,
                currentPage: data.page,
                rowsPerPage: data.rowsPerPage,
                by: data.by,
                asc: data.asc
            })
        })
    }

    createDebitNoteObject(page, rowsPerPage, sortField, sortOrder) {
        let currentPage = page ? page : this.state.currentPage
        let pageSize = rowsPerPage ? rowsPerPage : this.state.rowsPerPage
        let by = sortField ? sortField : this.state.by
        let asc = sortOrder ? sortOrder : this.state.asc
        return {
            mid: this.state.mid,
            dateFrom: this.state.dateFrom ? moment(this.state.dateFrom).format('DD/MM/YYYY') : null,
            dateTo: this.state.dateTo ? moment(this.state.dateTo).format('DD/MM/YYYY') : null,
            debitNoteScheme: this.state.selectedCardSchemes.length > 0 ? this.state.selectedCardSchemes.toString() : null,
            tid: this.state.selectedTids.length > 0 ? this.state.selectedTids.toString() : null,
            batchNumber: this.state.selectedBatches.length > 0 ? this.state.selectedBatches.toString() : null,
            page: currentPage,
            rowsPerPage: pageSize,
            by: by,
            asc: asc
        }
    }

    mapDebitNotes(debitNotesData) {
        let counter = 0
        debitNotesData.map((row) => {
            row.debitNoteId = counter
            row.authorizationDate = new Date(row.paymentRespDate)
            row.commissionAmountMerchant = row.commissionAmountMerchant ? parseFloat(row.commissionAmountMerchant) : 0;
            row.netAmount = row.netAmount ? parseFloat(row.netAmount) : 0;
            row.refundsAmount = row.refundsAmount ? parseFloat(row.refundsAmount) : 0;
            row.amount = row.amount ? parseFloat(row.amount) : 0;
            row.debitNoteScheme = row.debitNoteScheme ? row.debitNoteProduct ? row.debitNoteScheme.concat('_', row.debitNoteProduct) : row.debitNoteScheme : ''
            counter++
            return row;
        });
        return debitNotesData
    }

    adjustTable(page, sizePerPage, sortField, sortOrder) {
        this.setDebitNotesData(
            page,
            sizePerPage,
            sortField,
            sortOrder
        )
    }

    generateDebitNoteFile(fileType) {
        this.setState({
            disableExport: true
        }, () => {
            let data = this.createDebitNoteObject()
            data.fileType = fileType
            data.locale = I18n._locale
            store.dispatch(downloadOneDebitNotesFile(data)).then((response) => {
                fileDownload(response.value.data, response.value.headers.filename)
                this.setState({
                    disableExport: false
                })
            })
        })
    }

    generateDebitNotesPdfFile() {
        this.setState({
            disableExport: true
        }, () => {
            let data = {
                afm: this.state.afm,
                mid: this.state.mid,
                year: this.state.debitNoteYear.toString(),
                month: this.state.debitNoteMonth.toString()
            }
            store.dispatch(downloadOneDebitNotesPdfFile(data)).then((response) => {
                fileDownload(response.value.data, response.value.headers.filename)
                this.setState({
                    disableExport: false
                })
            })
        })
    }


    render() {
        return (
            <Panel id="collapsible-panel-payments-and-fees"
                   onToggle={()=>{
                this.setState(prevState => ({
                    debitNotesPanelOpen: !prevState.debitNotesPanelOpen
                }), ()=> {
                    if (this.state.debitNotesPanelOpen) {
                        store.dispatch(setNewDebitNotesAction(false));
                    }
                });
            }}>
                <Panel.Heading>
                    <Panel.Toggle componentClass="h4">
                        <div className="debit-notes__header">
                            <Translate value="pages.business.debitNotes.title"/>
                                <div className={"debit-notes__header--new" + (this.state.newDebitNotes ? " fade-in": " fade-out")}>
                                    <Translate value="pages.business.debitNotes.new"/>
                                </div>
                        </div>
                        <i className="icon-plus-accordion"></i>
                        <i className="icon-minus-accordion"></i>
                    </Panel.Toggle>
                </Panel.Heading>
                <Panel.Collapse>
                    <Panel.Body>
                        <div className='debit-notes'>

                                <React.Fragment>
                                    <DebitNotesCriteriaForm
                                        tids={this.state.tids}
                                        lastAvailableMonth={this.state.lastAvailableMonth}
                                        selectedMonth={this.state.selectedMonth}
                                        dateFrom={this.state.dateFrom}
                                        dateTo={this.state.dateTo}
                                        batches={this.state.batches}
                                        selectedTids={this.state.selectedTid}
                                        minDateFrom={this.state.minDateFrom}
                                        maxDateTo={this.state.maxDateTo}
                                        cardSchemes={this.state.cardSchemes}
                                        selectedCardSchemes={this.state.selectedCardSchemes}
                                        selectedBatches={this.state.selectedBatches}
                                        debitNotesTotalData={this.state.debitNotesTotalData}
                                        disableExport={this.state.disableExport}
                                        changeSelectedMonth={this.changeSelectedMonth}
                                        changeDateFrom={this.changeDateFrom}
                                        changeDateTo={this.changeDateTo}
                                        changeSelectedTids={this.changeSelectedTids}
                                        changeSelectedCardSchemes={this.changeSelectedCardSchemes}
                                        changeSelectedBatches={this.changeSelectedBatches}
                                        setDebitNotesTotalData={this.setDebitNotesTotalData}
                                        generateDebitNoteFile={this.generateDebitNoteFile}
                                        generateDebitNotesPdfFile={this.generateDebitNotesPdfFile}
                                    />
                                    {this.state.schemeData && this.state.schemeData.length > 0 ?
                                    <DebitNotesOverview
                                        dateFrom={this.state.dateFrom}
                                        dateTo={this.state.dateTo}
                                        data={this.state.schemeData}
                                        dateRangeLabel={this.state.dateRangeLabel}
                                        feesSum={this.state.feesSum}
                                    />:null}
                            </React.Fragment>
                            <DebitNotesDetails
                                dateRangeLabel={this.state.dateRangeLabel}
                                locale={this.props.i18n.locale}
                                debitNotesData = {this.state.debitNotesTotalData}
                                total = {this.state.total}
                                currentPage = {this.state.currentPage}
                                rowsPerPage = {this.state.rowsPerPage}
                                by ={this.state.by}
                                asc = {this.state.asc}
                                adjustTable={this.adjustTable}
                                debiNotesParameters={
                                    {
                                        dateFrom: this.state.dateFrom,
                                        dateTo: this.state.dateTo,
                                        tids: this.state.selectedTids,
                                        cardSchemes: this.state.selectedCardSchemes,
                                        batches: this.state.selectedBatches
                                    }
                                }
                            />
                        </div>
                    </Panel.Body>
                </Panel.Collapse>
            </Panel>
        )
    }
}

export default withRouter(connect(stateMap)(DebitNotes));