import {makeRequest} from "../api-client";

const middlewareUrl = process.env.MIDDLEWARE_URL

export const approvedCount = (data) => {
    let headers = {'x-afm': data.afm}
    return makeRequest(middlewareUrl + "/charts/approved/count", "GET", {
            mid: data.mid,
            dateFrom: data.dateFrom,
            dateTo: data.dateTo
        },
        headers, "GET_CHARTS_APPROVED_COUNT")
};
export const bankTotals = (data) => {
    let headers = {'x-afm': data.afm}
    return makeRequest(middlewareUrl + "/charts/bank/totals", "GET", {
        mid: data.mid,
        dateFrom: data.dateFrom,
        dateTo: data.dateTo
    }, headers, "GET_CHARTS_BANK_TOTALS")
};
export const cardModeTotals = (data) => {
    let headers = {'x-afm': data.afm}
    return makeRequest(middlewareUrl + "/charts/card/mode/totals", "GET", {
        mid: data.mid,
        dateFrom: data.dateFrom,
        dateTo: data.dateTo
    }, headers, "GET_CHARTS_CARD_MODE_TOTALS")
};
export const cardSchemeTotals = (data) => {
    let headers = {'x-afm': data.afm}
    return makeRequest(middlewareUrl + "/charts/card/scheme/totals", "GET", {
        mid: data.mid,
        dateFrom: data.dateFrom,
        dateTo: data.dateTo
    }, headers, "GET_CHARTS_CARD_SCHEME_TOTALS")
};
export const countsTotals = (data) => {
    let headers = {'x-afm': data.afm}
    return makeRequest(middlewareUrl + "/charts/counts/totals", "GET", {
        mid: data.mid,
        dateFrom: data.dateFrom,
        dateTo: data.dateTo
    }, headers, "GET_CHARTS_COUNTS_TOTALS")
};
export const installments = (data) => {
    let headers = {'x-afm': data.afm}
    return makeRequest(middlewareUrl + "/charts/installments", "GET", {
        mid: data.mid,
        dateFrom: data.dateFrom,
        dateTo: data.dateTo
    }, headers, "GET_CHARTS_INSTALLMENTS")
};
export const installmentsAverage = (data) => {
    let headers = {'x-afm': data.afm}
    return makeRequest(middlewareUrl + "/charts/installments/average", "GET", {
        mid: data.mid,
        dateFrom: data.dateFrom,
        dateTo: data.dateTo
    }, headers, "GET_CHARTS_INSTALLMENTS_AVERAGE")
};

export const installmentsPerPeriod = (data) => {
    let headers = {'x-afm': data.afm}
    return makeRequest(middlewareUrl + "/charts/installments/perPeriod", "GET", {
        mid: data.mid,
        dateFrom: data.dateFrom,
        dateTo: data.dateTo
    }, headers, "GET_CHARTS_INSTALLMENTS_PER_PERIOD")
};


export const redemption = (data) => {
    let headers = {'x-afm': data.afm}
    return makeRequest(middlewareUrl + "/charts/redemption", "GET", {
        mid: data.mid,
        dateFrom: data.dateFrom,
        dateTo: data.dateTo
    }, headers, "GET_CHARTS_REDEMPTION")
};
export const settledCount = (data) => {
    let headers = {'x-afm': data.afm}
    return makeRequest(middlewareUrl + "/charts/settled/count", "GET", {
        mid: data.mid,
        dateFrom: data.dateFrom,
        dateTo: data.dateTo
    }, headers, "GET_CHARTS_SETTLED_COUNT")
};
export const transactionType = (data) => {
    let headers = {'x-afm': data.afm}
    return makeRequest(middlewareUrl + "/charts/transaction/type", "GET", {
        mid: data.mid,
        dateFrom: data.dateFrom,
        dateTo: data.dateTo
    }, headers, "GET_CHARTS_TRANSACTION_TYPE")
};

export const countsAverage = (data) => {
    let headers = {'x-afm': data.afm}
    return makeRequest(middlewareUrl + "/charts/counts/average", "GET", {
        mid: data.mid,
        dateFrom: data.dateFrom,
        dateTo: data.dateTo
    }, headers, "GET_CHARTS_COUNTS_AVERAGE")
};

export const approvedSettledExcel = (data) => {
    let headers = {'x-afm': data.afm}
    return makeRequest(middlewareUrl + "/charts/approved/settled/excel", "GET", {
        mid: data.mid,
        dateFrom: data.dateFrom,
        dateTo: data.dateTo,
        locale: data.locale
    }, headers, "GET_CHARTS_APPROVED_SETTLED_EXCEL", "blob")
};
export const bankTotalsExcel = (data) => {
    let headers = {'x-afm': data.afm}
    return makeRequest(middlewareUrl + "/charts/bank/totals/excel", "GET", {
        mid: data.mid,
        dateFrom: data.dateFrom,
        dateTo: data.dateTo,
        locale: data.locale
    }, headers, "GET_CHARTS_BANK_TOTALS_EXCEL", "blob")
}
export const cardModeTotalsExcel = (data) => {
    let headers = {'x-afm': data.afm}
    return makeRequest(middlewareUrl + "/charts/card/mode/totals/excel", "GET", {
        mid: data.mid,
        dateFrom: data.dateFrom,
        dateTo: data.dateTo,
        locale: data.locale
    }, headers, "GET_CHARTS_CARD_MODE_TOTALS_EXCEL", "blob")
};
export const cardSchemeTotalsExcel = (data) => {
    let headers = {'x-afm': data.afm}
    return makeRequest(middlewareUrl + "/charts/card/scheme/totals/excel", "GET", {
        mid: data.mid,
        dateFrom: data.dateFrom,
        dateTo: data.dateTo,
        locale: data.locale
    }, headers, "GET_CHARTS_CARD_SCHEME_TOTALS_EXCEL", "blob")
};
export const countsTotalsExcel = (data) => {
    let headers = {'x-afm': data.afm}
    return makeRequest(middlewareUrl + "/charts/counts/totals/excel", "GET", {
        mid: data.mid,
        dateFrom: data.dateFrom,
        dateTo: data.dateTo,
        locale: data.locale
    }, headers, "GET_CHARTS_COUNTS_TOTALS_EXCEL", "blob")
};
export const installmentsExcel = (data) => {
    let headers = {'x-afm': data.afm}
    return makeRequest(middlewareUrl + "/charts/installments/excel", "GET", {
        mid: data.mid,
        dateFrom: data.dateFrom,
        dateTo: data.dateTo,
        locale: data.locale
    }, headers, "GET_CHARTS_INSTALLMENTS_EXCEL", "blob")
};
export const redemptionExcel = (data) => {
    let headers = {'x-afm': data.afm}
    return makeRequest(middlewareUrl + "/charts/redemption/excel", "GET", {
        mid: data.mid,
        dateFrom: data.dateFrom,
        dateTo: data.dateTo,
        locale: data.locale,
        email: data.email,
        currentUrl: data.currentUrl
    }, headers, "GET_CHARTS_REDEMPTION_EXCEL", "blob")
};
export const transactionExcel = (data) => {
    let headers = {'x-afm': data.afm}
    return makeRequest(middlewareUrl + "/charts/transaction/excel", "GET", {
        mid: data.mid,
        dateFrom: data.dateFrom,
        dateTo: data.dateTo,
        locale: data.locale
    }, headers, "GET_CHARTS_TRANSACTION_EXCEL", "blob")
};

export const redemptionForChartExcel = (data) => {
    let headers = {'x-afm': data.afm}
    return makeRequest(middlewareUrl + "/charts/redemption/for/chart/excel", "GET", {
        mid: data.mid,
        dateFrom: data.dateFrom,
        dateTo: data.dateTo,
        locale: data.locale
    }, headers, "GET_CHARTS_REDEMPTION_FOR_CHART_EXCEL", "blob")
};