import React from "react";
import BootstrapTable from 'react-bootstrap-table-next';
import paginationFactory from 'react-bootstrap-table2-paginator';
import moment from "moment";
import {formatAmount} from "../../../lib/utils";
import NumberFormatter from "../../common/NumberFormatter";

const I18n = require('react-redux-i18n').I18n;

const Translate = require('react-redux-i18n').Translate;

const sortProps = {
    headerFormatter: (column, index, {sortElement, filterElement}) => {
        return (
            <div className="debit-notes-details-table-header">
                <Translate value={column.text} dangerousHTML/>
            </div>
        )
    }
};


export default class DebitNotesDetails extends React.Component {
    constructor(props) {
        super(props)

        this.state = {
            nextPageTitle: I18n.t('pages.business.debitNotes.details.table.pagination.nextPageTitle'),
            prevPageTitle: I18n.t('pages.business.debitNotes.details.table.pagination.prevPageTitle'),
            firstPageTitle: I18n.t('pages.business.debitNotes.details.table.pagination.firstPageTitle'),
            lastPageTitle: I18n.t('pages.business.debitNotes.details.table.pagination.lastPageTitle'),
            extraDetails: false,
            dateRangeLabel: props.dateRangeLabel,
            rowsPerPage: 20
        }

        this.toggleExtraDetails = this.toggleExtraDetails.bind(this);
        this.handleTableChange = this.handleTableChange.bind(this);
        this.amountsFormatter = this.amountsFormatter.bind(this);
        this.dateTimeFormatter = this.dateTimeFormatter.bind(this);
        this.arrangeSizePerPageList = this.arrangeSizePerPageList.bind(this);
    }

    componentDidMount() {
        this.setState({
            dateRangeLabel: this.props.dateRangeLabel,
            debitNotesData: this.props.debitNotesData,
            debitNotesDataTotals: this.props.total
        })
    }

    componentDidUpdate(prevProps: Readonly<P>, prevState: Readonly<S>, snapshot: SS) {
        //TODO if any relative parameter is changed, fetch the data accordingly...
        if (prevProps.dateRangeLabel!==this.props.dateRangeLabel) {
            this.setState({
                dateRangeLabel: this.props.dateRangeLabel
            });
        }

        if (prevProps.locale !== this.props.locale) {
            this.setState({
                nextPageTitle: I18n.t('pages.business.debitNotes.details.table.pagination.nextPageTitle'),
                prevPageTitle: I18n.t('pages.business.debitNotes.details.table.pagination.prevPageTitle'),
                firstPageTitle: I18n.t('pages.business.debitNotes.details.table.pagination.firstPageTitle'),
                lastPageTitle: I18n.t('pages.business.debitNotes.details.table.pagination.lastPageTitle'),
                debitNotesData: this.props.debitNotesData,
                debitNotesDataTotals: this.props.total
            })
        }

    }

    toggleExtraDetails() {
        this.setState(prevState => ({
            extraDetails: !prevState.extraDetails
        }))
    }

    handleTableChange = (type, {page, sizePerPage, sortField, sortOrder}) => {
        if (sortField === 'date') {
            sortField = ('authorization_date');
        }
        this.setState({
            currentPage: page,
            rowsPerPage: sizePerPage,
        }, () => {
            if (type === "sort") {
                this.props.adjustTable(page, sizePerPage, sortField, (!(this.props.asc && sortField === this.props.by)));
            } else {
                this.props.adjustTable(page, sizePerPage, this.props.by, this.props.asc)
            }
        })
    }

    dateTimeFormatter(cell, row) {
        return (
            <div className="datetime-tbl">
                <span className="date-tbl">
                    {moment(cell).format('DD/MM/YY')}
                </span>
            </div>
        );
    }

    amountsFormatter(cell, row, rowIndex, formatExtraData) {
        if (cell === 0) {
            return (
                <span>-</span>
            )
        } else {
            return (
                <NumberFormatter number={cell} digits={2} symbol={"€"} />
            )
        }
    }

    arrangeSizePerPageList(total) {
        switch (true) {
            case (total > 20 && total < 100):
                return [
                    {
                        text: '20', value: 20
                    },
                    {
                        text: '50', value: 50
                    }
                ];
            case (total >= 100):
                return [{
                    text: '20', value: 20
                },
                    {
                        text: '50', value: 50
                    },
                    {
                        text: '100', value: 100
                    }];
            default:
                return [{
                    text: '20', value: 20
                },
                    {
                        text: '50', value: 50
                    },
                    {
                        text: '100', value: 100
                    }];
        }
    }


    render() {
        const customTotal = (from, to, size) => (
            <div className="react-bootstrap-debit-notes-table-pagination-total">
                <div className="results-total">
                    <span> {this.props.total} </span>
                    <Translate value="pages.business.debitNotes.details.table.pagination.results"/>
                </div>
            </div>
        );

        let options = {
            hideSizePerPage: true,
            paginationSize: 6,
            pageStartIndex: 1,
            alwaysShowAllBtns: true,
            withFirstAndLast: true,
            hidePageListOnlyOnePage: true,
            prePageText: 'p',
            nextPageText: 'n',
            firstPageText: 'f',
            lastPageText: 'l',
            prePageTitle: this.state.prevPageTitle,
            nextPageTitle: this.state.nextPageTitle,
            firstPageTitle: this.state.firstPageTitle,
            lastPageTitle: this.state.lastPageTitle,
            showTotal: true,
            paginationTotalRenderer: customTotal,
            onTableChange: this.handleTableChange,
            page: this.props.currentPage,
            sizePerPage: this.props.rowsPerPage,
            totalSize: this.props.total,
            sizePerPageList: this.arrangeSizePerPageList(this.props.total)
        };

        const columns = [
            {
                dataField: 'authorizationDate',
                text: 'pages.business.debitNotes.details.table.headings.date',
                formatter: this.dateTimeFormatter,
                headerFormatter: sortProps.headerFormatter
            },
            {
                dataField: 'tid',
                text: "pages.business.debitNotes.details.table.headings.tid",
                headerFormatter: sortProps.headerFormatter
            },
            {
                dataField: 'batchNumber',
                text: "pages.business.debitNotes.details.table.headings.batch",
                headerFormatter: sortProps.headerFormatter
            },
            {
                dataField: 'debitNoteScheme',
                text: "pages.business.debitNotes.details.table.headings.cardScheme",
                headerFormatter: sortProps.headerFormatter
            },
            {
                dataField: 'amount',
                text: 'pages.business.debitNotes.details.table.headings.salesAmount',
                formatter: this.amountsFormatter,
                formatExtraData: formatAmount,
                headerFormatter: sortProps.headerFormatter
            },
            {
                dataField: 'refundsAmount',
                text: 'pages.business.debitNotes.details.table.headings.refundsAmount',
                formatter: this.amountsFormatter,
                formatExtraData: formatAmount,
                headerFormatter: sortProps.headerFormatter
            },
            {
                dataField: 'commissionAmountMerchant',
                text: 'pages.business.debitNotes.details.table.headings.commissionAmount',
                formatter: this.amountsFormatter,
                formatExtraData: formatAmount,
                headerFormatter: sortProps.headerFormatter
            },
            {
                dataField: 'netAmount',
                text: 'pages.business.debitNotes.details.table.headings.netAmount',
                formatter: this.amountsFormatter,
                formatExtraData: formatAmount,
                headerFormatter: sortProps.headerFormatter
            }
        ];
        return (
            <div className="debit-notes__details">
                <div className="debit-notes__more-criteria">
                    <label htmlFor="debitNotesExtraDetails" className="mid-line">
                        <input className="mid-line__more-criteria"
                               type="checkbox" id="debitNotesExtraDetails"
                               onChange={() => {
                                   this.toggleExtraDetails()
                               }}
                        />
                        <span className={this.state.extraDetails ? "icon-minus-less" : "icon-right-open-1"}/>
                        <Translate className="debit-notes__more-criteria--label"
                                   value={"pages.business.debitNotes.details.title"}/>
                    </label>
                </div>
                {this.state.extraDetails ?
                    <div className="debit-notes__more-criteria--details__content">
                        <div className="debit-notes__daterange">
                            {this.state.dateRangeLabel}
                        </div>
                        <div className="debit-notes__details--table">
                            <BootstrapTable classes={"debit-notes-details-table"}
                                            keyField='debitNoteId'
                                            data={this.props.debitNotesData}
                                            columns={columns}
                                            pagination={paginationFactory(options)}
                                            onTableChange={this.handleTableChange}
                                            striped
                                            remote
                            />
                        </div>
                    </div>:null}
            </div>
        )
    }
}


