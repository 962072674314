import React from 'react';
import wave from "../../../assets/img/wave.png";

export default class Wave extends React.Component {

    render() {
        return (
            <div className={this.props.className ? this.props.className : 'wave'}>
                <img src={wave} alt="a wave img" />
            </div>
        )
    }
}