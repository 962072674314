import React from "react";
import store from "../../store";
import {updateAssociatedStatus} from "../../providers/users";
import moment from "moment";

const Translate = require('react-redux-i18n').Translate;
const I18n = require('react-redux-i18n').I18n

export default class ManagedUser extends React.Component {
    constructor(props) {
        super(props)

        this.state = {
            enabled: null,
            lockedToggleTooltip: I18n.t('tooltips.hover.lockedToggle'),
            enabledUserTooltip: I18n.t('tooltips.hover.userToggleOn'),
            disabledUserTooltip: I18n.t('tooltips.hover.userToggleOff')
        }

        this.handleCheck = this.handleCheck.bind(this)
    }


    componentWillReceiveProps(nextProps) {
        if (nextProps.i18n !== this.props.i18n) {
            this.setState({
                lockedToggleTooltip: I18n.t('tooltips.hover.lockedToggle'),
                enabledUserTooltip: I18n.t('tooltips.hover.userToggleOn'),
                disabledUserTooltip: I18n.t('tooltips.hover.userToggleOff')
            })
        }
    }

    handleCheck(event) {
        const status = this.props.associatedUser.enabled === true ? 'disabled' : 'enabled'
        store.dispatch(updateAssociatedStatus(this.props.associatedUser.userId, status)).then(
            () => {
                this.props.getAssociatedUsers()
            },
            (error) => {
                console.log('error is ', error)
            })
    }

    render() {
        let userTypes = [];
        let userTitleCls = '';
        let isCurrentUser = this.props.associatedUser.userId === this.props.currentUser;
        let isImpersonator = this.props.impersonator !== null
        if (this.props.associatedUser.superUser && this.props.associatedUser.legalRepresentative) {
            userTitleCls = 'double__title'
        } else {
            userTitleCls = 'single__title'
        }
        if (this.props.associatedUser.superUser) {
            userTypes.push(
                <div className={"user-management__user super-user " + userTitleCls} key="super-user-type">
                    <Translate value="modals.userManagement.superUser"/>
                </div>
            );
        }
        if (this.props.associatedUser.legalRepresentative) {
            userTypes.push(
                <div className={"user-management__user legal-representative " + userTitleCls}
                     key="legal-representative-type">
                    <Translate value="modals.userManagement.legalRepresentative"/>
                </div>
            );
        }
        if (!this.props.associatedUser.superUser && !this.props.associatedUser.legalRepresentative) {
            userTypes.push(
                <div className={"user-management__user simple-user " + userTitleCls} key="simple-user-type">
                    <Translate value="modals.userManagement.simpleUser"/>
                </div>
            );
        }

        return (
            <div
                className={'user-management__item' + (!this.props.associatedUser.confirmed || isImpersonator ? ' user-management__disabled' : ' ')}>
                <div className="user-management__types-status">
                    <div className="user-management__user-types">
                        {userTypes}
                        <div className="user-management__name-email">
                            <div className="user-management__name">
                                {this.props.associatedUser.firstName + ' ' + this.props.associatedUser.lastName}
                            </div>
                            <div className="user-management__email">
                                {this.props.associatedUser.email}
                            </div>
                        </div>
                    </div>
                    <div className="user-management__user-switch">
                        <div className="user-management__switch-label-value">
                            {this.props.associatedUser.confirmed ?
                                this.props.associatedUser.enabled === true ?
                                    <Translate value="modals.userManagement.enabled"/> :
                                    <Translate value="modals.userManagement.disabled"/> :
                                <Translate value="modals.userManagement.notConfirmed"/>
                            }
                        </div>
                        <input type="checkbox"
                               disabled={isCurrentUser}
                               name="select-homepage-checkbox"
                               className="cbx"
                               id={this.props.associatedUser.userId}
                               checked={this.props.associatedUser.enabled}
                               onChange={this.handleCheck}
                        />
                        <label htmlFor={this.props.associatedUser.userId}
                               className={"toggle " + (isCurrentUser ? 'disabled-cls' : '')}
                               title={isCurrentUser ? this.state.lockedToggleTooltip :
                                   this.props.associatedUser.enabled ? this.state.enabledUserTooltip :
                                       this.state.disabledUserTooltip}>
                            <span></span>
                            {isCurrentUser ? <i className="icon-lock"></i> : null}
                        </label>
                    </div>
                </div>
                <div className="user-management__dates">
                    <div className="added-on">
                        <Translate value="modals.userManagement.userAdded"/>
                        <span>
                            {this.props.associatedUser.addedOn !== null ?
                                moment(this.props.associatedUser.addedOn).format('DD/MM/YYYY') : '-'}
                        </span>
                    </div>
                    <div className="last-connect">
                        <Translate value="modals.userManagement.lastConnection"/>
                        <span>
                            {this.props.associatedUser.lastLoginOn !== null ?
                                moment(this.props.associatedUser.lastLoginOn).format('DD/MM/YYYY') : '-'}
                        </span>
                    </div>
                </div>
            </div>)
    }
}