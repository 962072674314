import React from "react";
import {connect} from "react-redux";
import {withRouter} from "react-router-dom";
import Wave from "../common/Wave";
import LocaleSwitch from "../common/LocaleSwitch";

const Translate = require('react-redux-i18n').Translate;



const stateMap = (store) => {
    return {
        i18n: store.i18n,
        userReducer: store.userReducer
    };
};

class InvalidInvitation extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            status: props.location.state ? this.props.location.state.status : 'default'
        }
    }

    componentWillMount() {
        if (!this.props.location.state) {
            this.props.history.push("/overview")
        } else {
            this.state = {
                status: this.props.location.state ? this.props.location.state.status : 'default'
            }
        }
    }

    componentDidMount() {
        if (this.props.userReducer.userLoggedIn) {
            this.props.history.push("/overview")
        }
    }


    render() {
        return (
            !this.props.userReducer.userLoggedIn ?
                <React.Fragment>
                    <main className="registration-information-page">
                        <div className="login-area__top">
                            <LocaleSwitch type={"login-area"}></LocaleSwitch>
                        </div>
                        <section className='msg__wrapper'>
                            <h1 className='msg__title'>
                                <Translate value={"pages.invalidInvitation.title." + this.state.status} />
                            </h1>
                            <article className='msg__text'>
                                <Translate value={"pages.invalidInvitation.message." + this.state.status} />
                            </article>
                        </section>
                        <Wave />
                    </main>
                </React.Fragment> : null
        )
    }
}

export default withRouter(connect(stateMap)(InvalidInvitation));