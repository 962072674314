import React, {Component} from 'react';
import connect from "react-redux/es/connect/connect";
import Logo from "../common/Logo";
import {termsAndConditionsActions} from "../../actions/termsAndConditionsActions";
import {acceptTermsAndConditions, getLatestTermsInPdf} from "../../providers/termsAndConditions";
import GenericModal from "./GenericModal";
import store from "../../store";
import {unEscapeHtml} from "../../lib/utils.js";

const fileDownload = require('js-file-download');

const stateMap = (store) => {
    return {
        termsAndConditionsReducer: store.termsAndConditionsReducer,
        i18n: store.i18n
    };
};

const Translate = require('react-redux-i18n').Translate;

class TermsAndConditions extends Component {
    constructor(props) {
        super(props)

        this.state = {}

        this.createTermsAndConditionsTitle = this.createTermsAndConditionsTitle.bind(this)
        this.returnAcceptButtonAndTerms = this.returnAcceptButtonAndTerms.bind(this)
        this.createTermsFooter = this.createTermsFooter.bind(this)
        this.showOrHideTermsAttentionPart = this.showOrHideTermsAttentionPart.bind(this)
        this.acceptTermsAndConditions = this.acceptTermsAndConditions.bind(this)
        this.checkTheTermsUpdatedSuccessfully = this.checkTheTermsUpdatedSuccessfully.bind(this)
        this.hideTermsAndConditions = this.hideTermsAndConditions.bind(this)
        this.printTermsAsPdf = this.printTermsAsPdf.bind(this)
    }

    createTermsFooter() {
        return (
            <button type="button" className="terms-and-conditions__download" onClick={this.printTermsAsPdf}>
                <span className="icon-download"/>
            </button>
        )
    }

    printTermsAsPdf() {
        store.dispatch(getLatestTermsInPdf(this.props.i18n.locale)).then((response) => {
                fileDownload(response.value.data, response.value.headers.filename)
            },
            (error) => {
            }
        )
    }

    createTermsAndConditionsTitle() {
        return (
            <React.Fragment>
                <Logo imgClassName="terms-and-conditions"/>
                <div className="terms-and-conditions__title">
                    <Translate value="modals.termsAndConditions.title"/>
                </div>
            </React.Fragment>
        )
    }

    returnAcceptButtonAndTerms() {
        return (
            <form onSubmit={(e) => {
                e.preventDefault();
                this.acceptTermsAndConditions();
            }}>
                <p className="terms-and-conditions__modal-body__pre-text">
                    {unEscapeHtml(this.props.termsAndConditionsReducer.latestTermsAndConditions.content)}
                </p>
                {this.showOrHideTermsAttentionPart()}
                {!this.props.termsAndConditionsReducer.showCloseButton ?
                    <div className="text-center">
                        <button type="submit" className="terms-and-conditions__modal-body__accept-button">
                            <Translate value="modals.termsAndConditions.acceptButton"/>
                        </button>
                    </div> : null}
            </form>
        )
    }

    showOrHideTermsAttentionPart() {
        return !this.props.termsAndConditionsReducer.showCloseButton ?
            <div className="terms-and-conditions__modal-body__attention-part">
                <Translate value="modals.termsAndConditions.attention" dangerousHTML/>
            </div> : null
    }

    acceptTermsAndConditions() {
        store.dispatch(acceptTermsAndConditions(1,
            this.props.termsAndConditionsReducer.latestTermsAndConditions.version,
            this.props.i18n.locale)).then(
            (response) => {
                if (this.checkTheTermsUpdatedSuccessfully(response)) {
                    this.hideTermsAndConditions()
                }
            },
            (error) => {
            })
    }

    checkTheTermsUpdatedSuccessfully(response) {
        const acceptedServerResponses = [1]
        return acceptedServerResponses.includes(response.value.data.payload)
    }

    hideTermsAndConditions() {
        store.dispatch(termsAndConditionsActions(true))
    }


    render() {
        const latestTermsAndConditions = this.props.termsAndConditionsReducer.latestTermsAndConditions
        return (
            latestTermsAndConditions && latestTermsAndConditions.content ?
                <GenericModal
                    showModal={this.props.termsAndConditionsReducer.termsAndConditionsAccepted === false}
                    showCloseButton={this.props.termsAndConditionsReducer.showCloseButton}
                    onHide={this.props.termsAndConditionsReducer.showCloseButton ?
                        this.hideTermsAndConditions :
                        () => {
                        }}
                    modalClass='terms-and-conditions__modal modal'
                    modalTitle={this.createTermsAndConditionsTitle()}
                    modalBody={this.returnAcceptButtonAndTerms()}
                    modalBodyClass='terms-and-conditions__modal-body modal-body'
                    modalFooter={this.createTermsFooter()}
                    modalFooterClass="terms-and-conditions__modal-footer modal-footer"
                    bsSize={this.props.bsSize}/> : null
        )
    }
}

export default connect(stateMap)(TermsAndConditions);
