import React from 'react';
import NumberFormat from 'react-number-format';
import OpenedDetail from './OpenedDetail';
import { Collapse, Fade } from 'react-bootstrap';

import {
    deleteAvatar,
    emailChange,
    getProfile,
    nameChange,
    passwordChange,
    phoneChange,
    updateAvatar,
} from '../../providers/users';

import store from '../../store';
import ClosedDetail from './ClosedDetail';
import ToggleDetail from './ToggleDetail';
import { loadUserAvatar, phoneNumberFormat } from '../../lib/utils';
import AvatarEditorWithButton from '../../lib/AvatarEditorWithButton';
import { phoneNumberRegex } from '../../constants';
import connect from 'react-redux/es/connect/connect';
import Loader from '../common/Loader';
import Button from 'react-bootstrap/es/Button';


const Translate = require('react-redux-i18n').Translate;
const I18n = require('react-redux-i18n').I18n;

const stateMap = (store) => {
    return {
        i18n: store.i18n,
        userReducer: store.userReducer
    };
};

class UserProfile extends React.Component {
    constructor(props) {
        super(props)
        this.nameChange = this.nameChange.bind(this)
        this.getProfile = this.getProfile.bind(this)
        this.checkFileTypeIsImage = this.checkFileTypeIsImage.bind(this)
        this.checkImageDimensionsAndSize = this.checkImageDimensionsAndSize.bind(this)
        this.uploadImageToServer = this.uploadImageToServer.bind(this)
        this.onImageUpload = this.onImageUpload.bind(this)
        this.handleChange = this.handleChange.bind(this)
        this.noChangesToName = this.noChangesToName.bind(this)
        this.phoneChange = this.phoneChange.bind(this)
        this.phoneSameAsConfirm = this.phoneSameAsConfirm.bind(this)
        this.handleFormattedChange = this.handleFormattedChange.bind(this)
        this.emailChange = this.emailChange.bind(this)
        this.resetEmailChangeFields = this.resetEmailChangeFields.bind(this)
        this.emailSameAsConfirm = this.emailSameAsConfirm.bind(this)
        this.passwordSameAsConfirm = this.passwordSameAsConfirm.bind(this)
        this.passwordChange = this.passwordChange.bind(this)
        this.resetPasswordChangeFields = this.resetPasswordChangeFields.bind(this)
        this.toggleItem = this.toggleItem.bind(this)
        this.resetNameToDefault = this.resetNameToDefault.bind(this)
        this.closeEmailMessage = this.closeEmailMessage.bind(this)
        this.doAnimations = this.doAnimations.bind(this)
        this.nullifyImgUrlValue = this.nullifyImgUrlValue.bind(this)
        this.deleteAvatar = this.deleteAvatar.bind(this)
        this.onToggleImageLoad = this.onToggleImageLoad.bind(this)
        this.toggleShowPassword = this.toggleShowPassword.bind(this)

        this.state = {
            imgUrl: null,
            imgName: null,
            firstName: '',
            lastName: '',
            initialFirstName: '',
            initialLastName: '',
            profile: null,
            newEmail: '',
            confirmNewEmail: '',
            password: '',
            oldPassword: '',
            newPassword: '',
            confirmNewPassword: '',
            newPhone: '',
            confirmNewPhone: '',
            validPhone: I18n.t("modals.profile.phone.input.validPhoneNumber"),
            passwordForPhone: '',
            imageError: '',
            imageSuccess: '',
            changeEmailErrorMessage: '',
            changeEmailError: '',
            changePasswordErrorMessage: '',
            changePasswordError: '',
            changePhoneErrorMessage: '',
            changePasswordMessage: '',
            changePhoneError: '',
            imageChangeMessage: '',
            nameChangeMessage: '',
            emailChangeMessage: '',
            phoneChangeMessage: '',
            passwordChangeMessage: '',
            nameChangeError: '',
            emailChangeSuccess: '',
            passwordChangeSuccess: '',
            phoneChangeSuccess: '',
            imageChanged: '',
            nameChanged: '',
            emailChanged: '',
            phoneChanged: '',
            passwordChanged: '',
            passwordPattern: '(?=^.{12,255}$)((?=.*\\d)(?=.*[A-Z])(?=.*[a-z])(?=.*[^A-Za-z0-9]))^.*',
            passwordRequirements: I18n.t('modals.profile.passwordRequirements'),
            usernameIsOpen: false,
            emailIsOpen: false,
            phoneIsOpen: false,
            passwordIsOpen: false,
            imageAnimate: false,
            usernameAnimate: false,
            emailAnimate: false,
            phoneAnimate: false,
            passwordAnimate: false,
            userImageTooltip: I18n.t('tooltips.hover.addAvatar'),
            usernameChangeTooltip: I18n.t('tooltips.hover.editName'),
            emailChangeTooltip: I18n.t('tooltips.hover.editEmail'),
            phoneChangeTooltip: I18n.t('tooltips.hover.editMobile'),
            passwordChangeTooltip: I18n.t('tooltips.hover.editPassword'),
            closeEditAvatarTooltip: I18n.t('modals.profile.closeEditAvatar'),
            saveAvatarTooltip: I18n.t('modals.profile.saveEditAvatar'),
            deleteAvatarTooltip: I18n.t('modals.profile.deleteAvatar'),
            avatarLoaderVisible: false,
            showOldPassword: false,
            showNewPassword: false,
            showConfirmNewPassword: false,
            showPasswordForPhone: false,
            showPassword: false
        }
    }

    componentDidMount() {
        this.getProfile()
    }

    componentWillReceiveProps(nextProps) {
        if (nextProps.i18n !== this.props.i18n) {
            this.setState({
                passwordRequirements: I18n.t('modals.profile.passwordRequirements'),
                validPhone: I18n.t("modals.profile.phone.input.validPhoneNumber"),
                userImageTooltip: I18n.t('tooltips.hover.addAvatar'),
                usernameChangeTooltip: I18n.t('tooltips.hover.editName'),
                emailChangeTooltip: I18n.t('tooltips.hover.editEmail'),
                phoneChangeTooltip: I18n.t('tooltips.hover.editMobile'),
                passwordChangeTooltip: I18n.t('tooltips.hover.editPassword'),
                saveAvatarTooltip: I18n.t('modals.profile.saveEditAvatar'),
                deleteAvatarTooltip: I18n.t('modals.profile.deleteAvatar')
            })
        }
    }

    doAnimations(type, isClickable) {
        this.setState({
            [type + 'Animate']: true
        }, () => {
            setTimeout(() => {
                this.setState({
                    [type + 'IsOpen']: false
                });
            }, 300)
        })
        if (type !== 'email' && !isClickable) {
            setTimeout(() => {
                this.setState({
                    [type + 'Animate']: false
                });
            }, 2500)
        }
    }

    closeEmailMessage() {
        this.setState({
            emailAnimate: false,
            emailIsOpen: true
        });

    }

    nullifyImgUrlValue() {
        this.setState({
            imgUrl: null
        })
    }


    nameChange() {
        store.dispatch(nameChange(this.state.firstName, this.state.lastName)).then(
            (response) => {
                this.setState({
                    nameChangeMessage: 'modals.profile.success.nameChangedSuccessfully',
                    nameChanged: true
                }, () => {
                    this.getProfile()
                })
            },
            (error) => {
                this.setState({
                    nameChangeMessage: 'modals.profile.error.nameNotChanged',
                    nameChanged: false
                })
            },
            this.doAnimations('username')
        )
    }

    getProfile() {
        store.dispatch(getProfile()).then(
            (response) => {
                const firstName = response.value.data.payload.firstName !== null ?
                    response.value.data.payload.firstName : '';
                const lastName = response.value.data.payload.lastName !== null ?
                    response.value.data.payload.lastName : '';
                const email = response.value.data.payload.email !== null ?
                    response.value.data.payload.email : '';
                const phone = response.value.data.payload.phone !== null ?
                    response.value.data.payload.phone : '';
                this.setState({
                    ...this.state,
                    profile: response.value.data.payload,
                    initialFirstName: firstName,
                    initialLastName: lastName,
                    firstName: firstName,
                    lastName: lastName,
                    phone: phone,
                    email: email
                })
            },
            (error) => {
            })
    }

    phoneChange() {
        if (this.phoneSameAsConfirm() === 1) {
            return;
        }
        store.dispatch(phoneChange(this.state.newPhone, this.state.passwordForPhone)).then(
            () => {
                this.clearChangePhoneFields()
                this.setState({
                    phoneChangeMessage: 'modals.profile.success.phoneChangedSuccessfully',
                    phoneChanged: true
                })

            },
            (error) => {
                if (error.response.data.errors[0] === 'UNAUTHORIZED') {
                    this.setState({
                        phoneChangeMessage: 'modals.profile.errors.passwordIsIncorrect',
                        phoneChanged: false
                    })
                }
            },

            this.doAnimations('phone')
        )
    }

    clearChangePhoneFields() {
        this.setState({
            newPhone: '',
            confirmNewPhone: '',
            passwordForPhone: ''
        })
    }

    passwordChange() {
        if (this.passwordSameAsConfirm() === 1) {
            return;
        }
        store.dispatch(passwordChange(this.state.oldPassword, this.state.newPassword)).then(
            () => {
                this.resetPasswordChangeFields();
                this.setState({
                    changePasswordMessage: 'modals.profile.success.passwordChangedSuccessfully',
                    passwordChanged: true,
                    changePasswordError: ''
                }, () => {
                    this.doAnimations('password');
                });
            },
            (error) => {
                if (error.response && error.response.data && error.response.data.errors && error.response.data.errors.length > 0) {
                    if (error.response.data.errors[0] === 'OLD_PASSWORD_ERROR') {
                        this.setState({
                            changePasswordError: I18n.t('modals.profile.errors.oldPasswordError'),
                        })
                    } else if (error.response.data.errors[0] === 'COMMON_PASSWORD_ERROR') {
                        this.setState({
                            changePasswordError: I18n.t('modals.profile.errors.commonPasswordError')
                        })
                    } else if (error.response.data.errors[0] === 'MID_PASSWORD_ERROR') {
                        this.setState({
                            changePasswordError: I18n.t('modals.profile.errors.midPasswordError')
                        })
                    } else if (error.response.data.errors[0] === 'SAME_PASSWORD_ERROR') {
                        this.setState({
                            changePasswordError: I18n.t('modals.profile.errors.samePasswordError')
                        })
                    } else if (error.response.data.errors[0] === 'PASSWORD_HISTORY_ERROR') {
                        this.setState({
                            changePasswordError: I18n.t('pages.expiredPassword.errors.passwordHistoryError')
                        })
                    } else if (error.response.data.errors[0] === 'TOO_MANY_CHANGE_PASSWORD_ATTEMPTS') {
                        this.setState({
                            changePasswordError: I18n.t('modals.profile.errors.tooManyChangePasswordAttempts')
                        })
                    } else {
                        this.setState({
                            changePasswordMessage: 'modals.profile.errors.passwordNotChanged',
                            passwordChanged: false
                        }, () => {
                            this.doAnimations('password')
                        })
                    }
                } else {
                    this.setState({
                        changePasswordMessage: 'modals.profile.errors.passwordNotChanged',
                        passwordChanged: false
                    }, () => {
                        this.doAnimations('password')
                    })
                }
            }
        )

    }


    passwordSameAsConfirm() {
        if (this.state.newPassword !== this.state.confirmNewPassword) {
            this.setState({
                changePasswordError: I18n.t('modals.profile.errors.passwordSameAsConfirm'),
                passwordChangeSuccess: ''
            })
            return 1
        }
        return 0
    }

    resetPasswordChangeFields() {
        this.setState({
            oldPassword: '',
            newPassword: '',
            confirmNewPassword: ''
        })
    }

    emailChange() {
        if (this.emailSameAsConfirm() === 1) {
            return;
        }
        const currentUrl = window.location.origin;
        store.dispatch(emailChange(this.state.newEmail, this.state.password, currentUrl)).then(
            () => {
                this.resetEmailChangeFields();
                this.setState({
                    emailChangeMessage: 'modals.profile.success.emailChangedSuccessfully',
                    emailChanged: true
                }, () => {
                    this.doAnimations('email', true)
                })
            },
            (error) => {
                if (error.response.data.errors[0] === 'EMAIL_ALREADY_EXISTS') {
                    this.setState({
                        emailChangeMessage: 'modals.profile.errors.emailAlreadyExists'
                    })
                } else if (error.response.data.errors[0] === 'PASSWORD_IS_INCORRECT') {
                    this.setState({
                        emailChangeMessage: 'modals.profile.errors.passwordIsIncorrect'
                    })
                }
                this.setState({
                    emailChanged: false
                }, () => {
                    this.doAnimations('email', false)
                })
            },
        )
    }

    emailSameAsConfirm() {
        if (this.state.newEmail !== this.state.confirmNewEmail) {
            this.setState({
                changeEmailError: I18n.t('modals.profile.errors.emailSameAsConfirm'),
                changeEmailErrorMessage: 'emailSameAsConfirm'
            })
            return 1
        }
        return 0
    }

    resetEmailChangeFields() {
        this.setState({
            newEmail: '',
            confirmNewEmail: '',
            password: ''
        })
    }

    uploadImageToServer(imgName, imgUrl) {
        store.dispatch(updateAvatar(imgName, imgUrl)).then(
            () => {
                this.nullifyImgUrlValue()
                this.setState({
                        imageChangeMessage: 'modals.profile.success.imageUploadSuccessfully',
                        imageChanged: true,
                    }, () => {
                        loadUserAvatar()
                        this.setState({
                            imageAnimate: true
                        })
                    },
                    setTimeout(() => {
                        this.onToggleImageLoad(false)
                        this.setState({
                            imageAnimate: false
                        });
                    }, 2500)
                )
            },
            (error) => {
                this.setState({
                    imageChangeMessage: 'modals.profile.error.imageNotChanged',
                    imageChanged: false
                }, () => {
                    this.setState({
                            imageAnimate: true
                        },
                        setTimeout(() => {
                            this.setState({
                                imageAnimate: false
                            });
                        }, 2500))
                })
            }
        )
    }

    checkImageDimensionsAndSize(image, fileSize) {
        // 4 MB
        const maxFileSizeInBytes = 4194304;
        if (fileSize > maxFileSizeInBytes) {
            this.setState({
                ...this.state,
                imageError: I18n.t("modals.profile.logoSpecifications"),
                imageSuccess: '',
                imgUrl: null,
                imgName: null
            });
            return false
        } else {
            this.setState({
                ...this.state,
                imageError: null
            });
            return true
        }
    }

    checkFileTypeIsImage(fileType) {
        const splittedFileType = fileType.split("/");
        if (splittedFileType === fileType || splittedFileType[0] !== "image") {
            this.setState({
                ...this.state,
                imageError: I18n.t("modals.profile.logoSpecifications"),
                imageSuccess: '',
                imgUrl: null,
                imgName: null
            })
            return false
        }
        return true
    }

    handleFormattedChange(obj, ev) {
        this.setState({
            [ev.target.id]: obj.value
        })
    }

    onImageUpload(image) {
        let file = image.target.files[0]
        if (!this.checkFileTypeIsImage(file.type)) return
        let reader = new FileReader()
        reader.readAsDataURL(file)
        reader.onloadend = function (e) {
            let imageSrc = reader.result
            let image = new Image()
            image.src = imageSrc
            image.onload = function () {
                if (this.checkImageDimensionsAndSize(image, file.size))
                    this.setState({
                        ...this.state,
                        imgUrl: reader.result,
                        imgName: file.name
                    }, () => {
                        this.onToggleImageLoad(true)
                    })
            }.bind(this)
        }.bind(this)
    }


    phoneSameAsConfirm() {
        if (this.state.newPhone !== this.state.confirmNewPhone) {
            this.setState({
                changePhoneError: I18n.t('modals.profile.errors.phoneSameAsConfirm'),
                changePhoneErrorMessage: 'phoneSameAsConfirm'
            })
            return 1
        }
        return 0
    }


    handleChange(event) {
        this.setState({
            [event.target.id]: event.target.value
        })
    }

    noChangesToName() {
        return this.state.firstName.trim() === this.state.initialFirstName &&
            this.state.lastName.trim() === this.state.initialLastName;

    }

    toggleItem(item) {
        this.resetNameToDefault()
        const items = ["usernameIsOpen", "emailIsOpen", "phoneIsOpen", "passwordIsOpen"];
        this.setState((prevState) => {
            return {[item]: !prevState[item]};
        }, () => {
            items.forEach(other => {
                if (other !== item) {
                    this.setState({
                        [other]: false
                    })
                }
            })
        });
    }

    resetNameToDefault() {
        this.setState({
            firstName: this.state.initialFirstName,
            lastName: this.state.initialLastName
        })
    }

    deleteAvatar() {
        store.dispatch(deleteAvatar()).then(
            () => {
                loadUserAvatar()
            },
            (error) => {
            })
    }

    onToggleImageLoad(visible) {
        this.setState({
            avatarLoaderVisible: visible
        })
    }


    toggleShowPassword(target) {
        this.setState(prevState => ({
            [target]: !prevState[target]
        }));
    }

    render() {
        this.setEditorRef = (editor) => this.editor = editor
        let imageChanged = (this.state.imageChanged);
        let nameChanged = (this.state.nameChanged);
        let emailChanged = (this.state.emailChanged);
        let phoneChanged = (this.state.phoneChanged);
        let passwordChanged = (this.state.passwordChanged);
        const userAvatar = this.props.userReducer.userAvatar;

        return (
            <div className="user-profile">
                {this.props.userReducer.showHotSpots ?
                    <div className="blocking-overlay__wrapper">
                        <div className="blocking-overlay blocking-overlay__modal" onClick={this.closeHotPopovers}></div>
                    </div> : null}
                <div className="user-profile__item user-profile__item_image"
                     title={this.state.userImageTooltip}
                     key="image-key">
                    {!this.state.avatarLoaderVisible && userAvatar ?
                        <div className="user-profile__item_image-existing-avatar">
                            <img src={'data:image/png;base64,' + userAvatar} alt="user avatar"/>
                        </div> : null}
                    <ClosedDetail type="image" key="closed-image"
                                  labelValue={
                                      <Translate value="modals.profile.image.value"/>}
                    />
                    <input
                        type="file"
                        id="userImage"
                        name="imageUpload"
                        className="upload-image-btn"
                        multiple={false}
                        accept={"image/*"}
                        onChange={this.onImageUpload}/>
                    {!this.state.avatarLoaderVisible ?
                        <React.Fragment>
                            <label htmlFor="userImage"
                                   title={this.state.userImageTooltip}
                                   className="icon-plus-accordion avatar-label-close">

                            </label>
                            {this.props.userReducer.userAvatar ?
                                <button className="user-profile__item_image__delete"
                                        title={this.state.deleteAvatarTooltip}
                                        onClick={this.deleteAvatar}>
                                    <Translate value="modals.profile.image.delete"/>
                                </button> : null}
                        </React.Fragment> : null}
                    {this.state.imageError ?
                        <div className="user-profile__avatar-error">
                            <span className="icon-icon-attention"/>
                            {this.state.imageError}
                        </div> : null}
                    {this.state.imageSuccess !== '' ?
                        <div>
                            <Translate value={this.state.imageSuccess}/>
                        </div> : null}
                    <Fade in={imageChanged !== '' && this.state.imageAnimate} timeout={300}>
                        <div className={"feedback-msg " + (!this.state.imageAnimate ? 'hide' : '')}>
                            <Translate value={this.state.imageChangeMessage}/>
                            {imageChanged !== '' && this.state.imageAnimate ?
                                <span className={imageChanged ? 'icon-success' : 'icon-failed'}></span> : null
                            }
                        </div>
                    </Fade>
                    {this.state.imgUrl !== null ?
                        <AvatarEditorWithButton imageUploadHandler={this.uploadImageToServer}
                                                imgUrl={this.state.imgUrl}
                                                width={135}
                                                imgName={this.state.imgName}
                                                imageWidth={135}
                                                cropColor={'#EFF5FF'}
                                                backgroundColor={'#007DC3'}
                                                cropRadius={20}
                                                minCropRadius={20}
                                                closeIconColor={'#EFF5FF'}
                                                shadingColor={'#007DC3'}
                                                shadingOpacity={0.50}
                                                class="avatarEditorWithButton"
                                                containerClass="user-avatar-submit-form"
                                                closeEditAvatarTooltip={this.state.closeEditAvatarTooltip}
                                                saveAvatarTooltip={this.state.saveAvatarTooltip}
                                                onToggleImageLoad={this.onToggleImageLoad}
                                                nullifyImgUrlValue={this.nullifyImgUrlValue}/>
                        : null}
                </div>
                <Collapse in={this.state.usernameIsOpen} timeout={300} title={this.state.usernameChangeTooltip}>
                    <div
                        className={'user-profile__item user-profile__item_username ' + (this.state.usernameIsOpen ? 'isOpen' : 'isClosed')}
                        key="username-key">
                        <ToggleDetail usernameIsOpen={this.state.usernameIsOpen} type={"username"}
                                      toggleItem={this.toggleItem}/>
                        {this.state.usernameIsOpen ?
                            [
                                <form
                                    onSubmit={(e) => {
                                        e.preventDefault();
                                        this.nameChange();
                                    }}>
                                    <OpenedDetail type={"username"}/>
                                    <div className="user-profile__label__value_opened">
                                        <Translate value="modals.profile.username.input.name"/>
                                    </div>
                                    <input type="text" id="firstName" name="firstName" required={true}
                                           onChange={this.handleChange}
                                           value={this.state.firstName} minLength="1"/>
                                    <div className="user-profile__label__value_opened">
                                        <Translate value="modals.profile.username.input.lastname"/>
                                    </div>
                                    <input type="text" id="lastName" name="lastName" required={true}
                                           onChange={this.handleChange}
                                           value={this.state.lastName} minLength="1"/>
                                    <button type="submit" className={"user-profile__btn user-profile__"
                                        + (this.state.firstName.trim() === '' ||
                                        this.state.lastName.trim() === '' || this.noChangesToName() ? 'btn_gray' : 'btn_orange')}
                                            disabled={this.state.firstName.trim() === '' ||
                                                this.state.lastName.trim() === '' || this.noChangesToName()}>
                                        <Translate value="modals.profile.saveButton"/>
                                    </button>
                                </form>
                            ] : [
                                <ClosedDetail type="username" key="closed-username"
                                              labelValue={this.state.firstName + " " + this.state.lastName}/>
                            ]
                        }

                        <Fade in={nameChanged !== '' && this.state.usernameAnimate} timeout={300}>
                            <div className={"feedback-msg " + (!this.state.usernameAnimate ? 'hide' : '')}>
                                <Translate value={this.state.nameChangeMessage}/>
                                {nameChanged !== '' && this.state.usernameAnimate ?
                                    <span className={nameChanged ? 'icon-success' : 'icon-failed'}></span> : null
                                }
                            </div>
                        </Fade>
                    </div>
                </Collapse>
                <Collapse in={this.state.emailIsOpen} timeout={300}>
                    <div
                        className={'user-profile__item user-profile__item_email ' + (this.state.emailIsOpen ? 'isOpen' : 'isClosed')}
                        title={this.state.emailChangeTooltip}
                        key="email-key">
                        <ToggleDetail emailIsOpen={this.state.emailIsOpen} type={"email"} toggleItem={this.toggleItem}/>
                        {this.state.emailIsOpen ?
                            [
                                <form
                                    onSubmit={(e) => {
                                        e.preventDefault();
                                        this.emailChange();
                                    }}>
                                    <OpenedDetail type={"email"}/>
                                    <div className="user-profile__label__value_opened">
                                        <Translate value="modals.profile.email.input.new"/>
                                    </div>
                                    <input type="email" id="newEmail" name="newEmail" required
                                           onChange={this.handleChange}
                                           value={this.state.newEmail}/>
                                    <div className="user-profile__label__value_opened">
                                        <Translate value="modals.profile.email.input.confirm"/>
                                    </div>
                                    <input type="email" id="confirmNewEmail" name="confirmNewEmail" required
                                           onChange={this.handleChange}
                                           value={this.state.confirmNewEmail}/>
                                    <div className="user-profile__label__value_opened">
                                        <Translate value="modals.profile.email.input.password"/>
                                    </div>
                                    <div className="user-profile__password-container">
                                        <input id="password" name="password" required
                                               type={this.state.showPassword ? "text" : "password"}
                                               onChange={this.handleChange}
                                               value={this.state.password}
                                               autoComplete="new-password"/>
                                        <Button onClick={() => {
                                            this.toggleShowPassword("showPassword")
                                        }}
                                                className={"user-profile__icon-password-eye" +
                                                    (this.state.showPassword ? " icon-show-password-eye" : " icon-hide-password-eye")}></Button>
                                    </div>
                                    <button type="submit" className={"user-profile__btn user-profile__"
                                        + (this.state.newEmail.trim() === '' ||
                                        this.state.confirmNewEmail.trim() === '' ||
                                        this.state.password === '' ? 'btn_gray' : 'btn_orange')}
                                            disabled={this.state.newEmail.trim() === '' ||
                                                this.state.confirmNewEmail.trim() === '' ||
                                                this.state.password === ''}>
                                        {this.props.userReducer.emailChange.fetching ?
                                            <Loader bouncing={false} color={'white'} message=''
                                                    style={{marginTop: 0}} bounceClass='smaller-bounces'/> :
                                            <Translate value="modals.profile.emailSaveButton"/>
                                        }
                                    </button>
                                    {this.state.changeEmailError ?
                                        <div className="user-profile__bottom-error">
                                            <span className="icon-icon-attention"/>
                                            {this.state.changeEmailError}
                                        </div> : null}
                                </form>] : [
                                <ClosedDetail type="email" key="closed-email" labelValue={this.state.email}/>]}
                        <Fade in={emailChanged !== '' && this.state.emailAnimate} timeout={300}
                              onClick={this.closeEmailMessage}>
                            <div className={"feedback-msg " + (!this.state.emailAnimate ? 'hide' : '')}>
                                <Translate value={this.state.emailChangeMessage}/>
                                {emailChanged !== '' && this.state.emailAnimate ?
                                    <span className={emailChanged ? 'icon-pending' : 'icon-failed'}></span> : null
                                }
                            </div>
                        </Fade>
                    </div>
                </Collapse>
                <Collapse in={this.state.phoneIsOpen} timeout={300}>
                    <div
                        className={'user-profile__item user-profile__item_phone ' + (this.state.phoneIsOpen ? 'isOpen' : 'isClosed')}
                        title={this.state.phoneChangeTooltip}
                        key="phone-key">
                        <ToggleDetail phoneIsOpen={this.state.phoneIsOpen} type={"phone"} toggleItem={this.toggleItem}/>
                        {this.state.phoneIsOpen ? [
                            <form
                                onSubmit={(e) => {
                                    e.preventDefault();
                                    this.phoneChange();
                                }}>
                                <OpenedDetail type={"phone"}/>
                                <div className="user-profile__label__value_opened">
                                    <Translate value="modals.profile.phone.input.new"/>
                                </div>
                                <NumberFormat
                                    type="tel"
                                    id="newPhone"
                                    format={phoneNumberFormat}
                                    className="simple-input"
                                    value={this.state.newPhone}
                                    placeholder="69"
                                    decimalScale={0} required={true}
                                    pattern={phoneNumberRegex}
                                    title={this.state.validPhone}
                                    onValueChange={this.handleFormattedChange}/>
                                <div className="user-profile__label__value_opened">
                                    <Translate value="modals.profile.phone.input.confirm"/>
                                </div>
                                <NumberFormat
                                    type="tel"
                                    id="confirmNewPhone"
                                    format={phoneNumberFormat}
                                    className="simple-input"
                                    value={this.state.confirmNewPhone}
                                    placeholder="69"
                                    decimalScale={0} required={true}
                                    onValueChange={this.handleFormattedChange}/>
                                <div className="user-profile__label__value_opened">
                                    <Translate value="modals.profile.phone.input.password"/>
                                </div>
                                <div className="user-profile__password-container">
                                    <input id="passwordForPhone" name="passwordForPhone" required={true}
                                           type={this.state.showPasswordForPhone ? "text" : "password"}
                                           title={this.state.passwordRequirements}
                                           onChange={this.handleChange}
                                           value={this.state.passwordForPhone}
                                        // You need to put "new-password" in order for autocomplete to become disabled
                                           autoComplete="new-password"/>
                                    <Button onClick={() => {
                                        this.toggleShowPassword("showPasswordForPhone")
                                    }}
                                            className={"user-profile__icon-password-eye" +
                                                (this.state.showPasswordForPhone ? " icon-show-password-eye" : " icon-hide-password-eye")}></Button>
                                </div>
                                <button type="submit" className={"user-profile__btn user-profile__"
                                    + (this.state.newPhone.trim() === '' ||
                                    this.state.confirmNewPhone.trim() === '' ||
                                    this.state.passwordForPhone === '' ? 'btn_gray' : 'btn_orange')}
                                        disabled={this.state.newPhone.trim() === '' ||
                                            this.state.confirmNewPhone.trim() === '' ||
                                            this.state.passwordForPhone === ''}>
                                    <Translate value="modals.profile.saveButton"/>
                                </button>
                                <div className="user-profile__footnote">
                                    <Translate value="modals.profile.phone.footnote" dangerousHTML/>
                                </div>
                                {this.state.changePhoneError ?
                                    <div className="user-profile__bottom-error">
                                        <span className="icon-icon-attention"/>
                                        {this.state.changePhoneError}
                                    </div> : null}
                                {this.state.phoneChangeSuccess !== '' ?
                                    <div>
                                        <Translate value={this.state.phoneChangeSuccess}/>
                                    </div> : null}
                            </form>] : [
                            <ClosedDetail type="phone" key="closed-phone"
                                          labelValue={this.state.phone}/>]}
                        <Fade in={phoneChanged !== '' && this.state.phoneAnimate} timeout={300}>
                            <div className={"feedback-msg " + (!this.state.phoneAnimate ? 'hide' : '')}>
                                <Translate value={this.state.phoneChangeMessage}/>
                                {phoneChanged !== '' && this.state.phoneAnimate ?
                                    <span className={phoneChanged ? 'icon-success' : 'icon-failed'}></span> : null
                                }
                            </div>
                        </Fade>
                    </div>
                </Collapse>
                <Collapse in={this.state.passwordIsOpen} timeout={300}>
                    <div
                        className={'user-profile__item user-profile__item_password ' + (this.state.passwordIsOpen ? 'isOpen' : 'isClosed')}
                        title={this.state.passwordChangeTooltip}
                        key="password-key">
                        <ToggleDetail passwordIsOpen={this.state.passwordIsOpen} type={"password"}
                                      toggleItem={this.toggleItem}/>
                        {this.state.passwordIsOpen ?
                            [
                                <form
                                    onSubmit={(e) => {
                                        e.preventDefault();
                                        this.passwordChange();
                                    }}>
                                    <OpenedDetail type={"password"}/>
                                    <div className="user-profile__label__value_opened">
                                        <Translate value="modals.profile.password.input.old"/>
                                    </div>
                                    <div className="user-profile__password-container">
                                        <input id="oldPassword" name="oldPassword" required
                                               type={this.state.showOldPassword ? "text" : "password"}
                                               onChange={this.handleChange}
                                               value={this.state.oldPassword}/>
                                        <Button onClick={() => {
                                            this.toggleShowPassword("showOldPassword")
                                        }}
                                                className={"user-profile__icon-password-eye" +
                                                    (this.state.showOldPassword ? " icon-show-password-eye" : " icon-hide-password-eye")}></Button>
                                    </div>
                                    <div className="user-profile__label__value_opened">
                                        <Translate value="modals.profile.password.input.new"/>
                                    </div>
                                    <div className="user-profile__password-container">
                                        <input id="newPassword" name="newPassword" required
                                               type={this.state.showNewPassword ? "text" : "password"}
                                               title={this.state.passwordRequirements}
                                               onChange={this.handleChange}
                                               value={this.state.newPassword}/>
                                        <Button onClick={() => {
                                            this.toggleShowPassword("showNewPassword")
                                        }}
                                                className={"user-profile__icon-password-eye" +
                                                    (this.state.showNewPassword ? " icon-show-password-eye" : " icon-hide-password-eye")}></Button>
                                    </div>
                                    <div className="user-profile__label__value_opened">
                                        <Translate value="modals.profile.password.input.confirm"/>
                                    </div>
                                    <div className="user-profile__password-container">
                                        <input id="confirmNewPassword" name="confirmNewPassword" required
                                               type={this.state.showConfirmNewPassword ? "text" : "password"}
                                               title={this.state.passwordRequirements}
                                               onChange={this.handleChange}
                                               value={this.state.confirmNewPassword}/>
                                        <Button onClick={() => {
                                            this.toggleShowPassword("showConfirmNewPassword")
                                        }}
                                                className={"user-profile__icon-password-eye" +
                                                    (this.state.showConfirmNewPassword ? " icon-show-password-eye" : " icon-hide-password-eye")}></Button>
                                    </div>
                                    <button type="submit" className={"user-profile__btn user-profile__"
                                        + (this.state.oldPassword === '' ||
                                        this.state.newPassword === '' || this.state.confirmNewPassword === ''
                                            ? 'btn_gray' : 'btn_orange')}
                                            disabled={this.state.oldPassword === '' ||
                                                this.state.newPassword === '' || this.state.confirmNewPassword === ''}>
                                        {this.props.userReducer.passwordChange.fetching ?
                                            <Loader bouncing={false} color={'white'} message=''
                                                    style={{marginTop: 0}} bounceClass='smaller-bounces'/> :
                                            <Translate value="modals.profile.saveButton"/>}
                                    </button>
                                    {this.state.changePasswordError ?
                                        <div className="user-profile__bottom-error">
                                            <span className="icon-icon-attention"/>
                                            {this.state.changePasswordError}
                                        </div> : null}
                                    {this.state.passwordChangeSuccess !== '' ?
                                        <div>
                                            <Translate value={this.state.passwordChangeSuccess}/>
                                        </div> : null}
                                </form>
                            ] :
                            [
                                <ClosedDetail type="password"
                                              key="closed-phone"
                                              labelValue="********"/>
                            ]
                        }
                        <Fade in={passwordChanged !== '' && this.state.passwordAnimate} timeout={300}>
                            <div className={"feedback-msg " + (!this.state.passwordAnimate ? 'hide' : '')}>
                                <Translate value={this.state.changePasswordMessage}/>
                                {passwordChanged !== '' && this.state.passwordAnimate ?
                                    <span className={passwordChanged ? 'icon-success' : 'icon-failed'}></span> : null
                                }
                            </div>
                        </Fade>
                    </div>
                </Collapse>
            </div>
        )
    }
}

export default connect(stateMap)(UserProfile);
