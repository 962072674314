import React from "react";
import {connect} from "react-redux";
import {withRouter} from "react-router-dom";
import store from "../../store";
import {startImpersonation} from "../../providers/users";

const qs = require('qs')
const _ = require("underscore")

const stateMap = (store) => {
    return {
        i18n: store.i18n,
        userReducer: store.userReducer
    };
};

class StartImpersonation extends React.Component {
    constructor(props) {
        super(props)

        this.state = {}
    }

    componentDidMount() {
        let parameters = qs.parse(window.location.search, {ignoreQueryPrefix: true})
        parameters = _.isEmpty(parameters) ? qs.parse(window.location.search, {ignoreQueryPrefix: true}) : parameters
        if (parameters.email !== undefined && parameters.tempToken !== undefined && parameters.impersonatorEmail !== undefined && parameters.groups !== undefined && !this.props.userReducer.impersonator) {
            store.dispatch(startImpersonation(parameters.email, parameters.tempToken, parameters.impersonatorEmail, parameters.groups)).then(() => {
                    this.props.checkIfUserLoggedIn(true, this.props.history)
                },
                (error) => {
                    this.props.history.push("/login")
                })
        } else {
            this.props.history.push("/login")
        }
    }

    render() {
        return (
            <React.Fragment/>
        )
    }
}

export default withRouter(connect(stateMap)(StartImpersonation));