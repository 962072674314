import {makeRequest} from "../api-client";

const middlewareUrl = process.env.MIDDLEWARE_URL

export const getTopFaqs = (top) => {
    let data = {top: top}
    return makeRequest(middlewareUrl + "/faqs", "GET", data, {}, "GET_TOP_FAQS")
};

export const getMap = (mapName) => {
    let data = {}
    data['mapName'] = mapName

    return makeRequest(middlewareUrl + "/map", "GET", data, {},
        "GET_MAP")
};

export const merchantRegistrationValidate = (data) => {
    data.saveMerchantRegistration = undefined
    data.validateMerchantRegistration = undefined
    return makeRequest(middlewareUrl + "/merchant/registration/validate", "POST", data, {},
        "VALIDATE_MERCHANT_REGISTRATION")
};
export const merchantRegistrationSave = (data, locale) => {
    data.saveMerchantRegistration = undefined
    data.validateMerchantRegistration = undefined
    data.locale = locale
    return makeRequest(middlewareUrl + "/merchant/registration/save", "POST", data, {},
        "SAVE_MERCHANT_REGISTRATION")
};