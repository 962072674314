import React from 'react';
import connect from "react-redux/es/connect/connect";
import {switchLocale} from "../../lib/utils";

const stateMap = (store) => {
    return {
        i18n: store.i18n
    };
};

class LocaleSwitch extends React.Component {

    constructor(props) {
        super(props)
        this.state = {
            locale: this.props.i18n.locale ? this.props.i18n.locale : 'el'
        };
        this.handleLocaleClick = this.handleLocaleClick.bind(this)
    }

    componentWillReceiveProps(nextProps) {
        if (this.props.i18n.locale !== nextProps.i18n.locale) {
            this.setState({
                locale: nextProps.i18n.locale ? nextProps.i18n.locale : 'el'
            })
        }
    }

    handleLocaleClick(e) {
        let id = e.target.id;
        this.setState({
            locale: e.target.id
        }, () => {
            switchLocale(id)
        })

    }

    render() {
        return (
            <div>
                <div className={this.props.type + "__switch-locale switch-locale"}>
                <input type="radio" className="switch-input" name="locale" value="el" id="el"
                       onChange={this.handleLocaleClick}/>
                <label htmlFor="el" className={this.state.locale === 'el' ? 'locale-selected' : ''}>Ελ</label>
                <span>/</span>
                <input type="radio" className="switch-input" name="locale" value="en" id="en"
                       onChange={this.handleLocaleClick}/>
                <label htmlFor="en" className={this.state.locale === 'en' ? 'locale-selected' : ''}>En</label>
            </div>
            </div>
        )
    }
}

export default connect(stateMap)(LocaleSwitch);
