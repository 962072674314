import React from "react";
import {formatAmount} from "../../../lib/utils.js";

const Translate = require('react-redux-i18n').Translate;
const I18n = require('react-redux-i18n').I18n;

export default class Gauge extends React.Component {

    constructor(props) {
        super(props)
        this.state = {
            gaugeTooltip: I18n.t('tooltips.hover.tooltip')
        }
    }

    render() {
        let currency = this.props.currency;
        return (
            <div className="gauge__outer__wrapper" title={this.state.gaugeTooltip}>
                <div className="gauge__label gauge__label--left">
                    <GaugeLabel label={"pages.overview.dashboard.gauge.valueLabels.min"}
                                value={this.props.min}
                                currency={currency}/>
                </div>
                <div className="gauge__wrapper">
                    <div className="gauge__label gauge__label--top">
                        <GaugeLabel label={"pages.overview.dashboard.gauge.valueLabels.average"}
                                    value={this.props.average}
                                    isAverage={true}
                                    currency={currency}/>
                    </div>
                    <div className="gauge">
                        <div className="gauge__container">
                            <div className="gauge__background"></div>
                            <div className="gauge__center"></div>
                            <div className="gauge__data"></div>
                            <div className="gauge__needle"></div>
                        </div>
                        <div className="gauge__needle__base"></div>
                        <div className="gauge__left-round"></div>
                        <div className="gauge__right-round"></div>
                    </div>
                    <div className="gauge__label gauge__label--bottom">
                        <Translate value={"pages.overview.dashboard.gauge." + this.props.label}/>
                    </div>
                </div>
                <div className="gauge__label gauge__label--right">
                    <GaugeLabel label={"pages.overview.dashboard.gauge.valueLabels.max"}
                                value={this.props.max}
                                currency={currency}/>
                </div>
            </div>
        )
    }
}

class GaugeLabel extends React.Component {
    checkNumber(num) {
        return num % 1 !== 0 && num > 0
    }

    render() {
        let num = this.props.value;
        let isAverage = this.props.isAverage;
        let currency = this.props.currency;
        const separator = I18n._locale === 'en' ? '.' : ','
        let decimal = num ? formatAmount(I18n._locale, num, 2) : '0' + separator + '00';
        return (
            <div className="gauge__label__text">
                <div className={"gauge__label__value" + (isAverage ? " gauge__label__value--average" : "")}>
                    <span>{currency}</span>
                    <span>{decimal ? decimal : '0'}</span>
                </div>
                <Translate value={this.props.label}/>
            </div>
        );


    }
}
