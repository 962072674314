import React from 'react';
import {Tab, Tabs} from 'react-bootstrap';
import store from '../../store';
import {
    downloadInstallmentsReport,
    downloadTransactionReport,
    getInstallmentsList,
    getTransactionList,
} from '../../providers/authorizations';
import {getMap} from '../../providers/rest';
import connect from 'react-redux/es/connect/connect';
import moment from 'moment';
import TransactionPopoverAndTable from '../transactions/TransactionPopoverAndTable';
import Loader from '../common/Loader';
import {
    getEcomAvailabilityByAfm,
    getMidsAndTidsFilteredByAfmAndMidAndEcom,
    getMidsTidsWithAliases,
    getTransactionTypesByAfm,
} from '../../providers/merchants';
import update from 'react-addons-update';
import {toggleSearchTransactionsPopover} from '../../actions/userActions';
import EmptyStateShape from '../common/EmptyStateShape';
import {filterByKeyValue, getSignedAmount} from '../../lib/utils';
import InstallmentPopoverAndTable from '../transactions/InstallmentPopoverAndTable';

const Translate = require('react-redux-i18n').Translate
const I18n = require('react-redux-i18n').I18n

const stateMap = (store) => {
    return {
        i18n: store.i18n,
        userReducer: store.userReducer,
        merchantReducer: store.merchantReducer
    };
};


class Transactions extends React.Component {
    constructor(props) {
        super(props)
        this.state = {
            isNoteToggleOn: false,
            isEcomToggleOn: false,
            isInstallmentsToggleOn: false,
            userAfm: props.merchantReducer.afm ? props.merchantReducer.afm : undefined,
            isCardlinkOne: props.merchantReducer.isCardlinkOne ? props.merchantReducer.isCardlinkOne : false,
            selectedMid: undefined,
            dateFrom: this.props.location.state ? moment(this.props.location.state.dateFrom).format('DD/MM/YYYY') :
                moment().format('DD/MM/YYYY'),
            dateTo: this.props.location.state ? moment(this.props.location.state.dateTo).format('DD/MM/YYYY') :
                moment().format('DD/MM/YYYY'),
            startTime: '00:00',
            endTime: '23:59',
            paymentTypeValue: 'notecom',
            authorizationCode: null,
            merchantId: null,
            allStoresBaseMid: I18n.t('pages.transactions.searchModal.midColumn.allStores'),
            allPosBaseTid: I18n.t('pages.transactions.searchModal.midColumn.allPos'),
            allPosBaseVmid: I18n.t('pages.transactions.searchModal.lowerMidColumn.allVmids'),
            allPosBaseVtid: I18n.t('pages.transactions.searchModal.lowerMidColumn.allVtids'),
            allAcquirersBase: I18n.t('pages.transactions.searchModal.lowerMidColumn.pickBank'),
            terminalId: null,
            amountFrom: null,
            amountTo: null,
            acquirerId: null,
            vMerchantId: null,
            vTerminalId: null,
            transactionType: null,
            transactionTypeLabel: null,
            successStatus: false,
            rejectStatus: false,
            packageStatus: null,
            hasClosedBatch: false,
            tableData: null,
            installmentsTableData: null,
            allMaps: null,
            currentPage: 1,
            asc: false,
            by: "authorizationDate,authorizationTime",
            showDuplicateTransactions: false,
            rowsPerPage: 20,
            total: null,
            // Required when recalling after locale to change the allResultsDropdowns function
            getAssociationsResponse: null,
            // Required when recalling after locale to change the mapDataFormat function
            transactionMaps: null,
            transactionReportError: null,
            moreCriteria: false,
            filteredMids: undefined,
            filteredTids: undefined,
            filteredVMids: undefined,
            filteredVTids: undefined,
            searchStartedFromPopoverSearch: false,
            hasEcomTransactions: false,
            loaderSquircleIsActive: false,
            hasNotEcomTransactions: false,
            transactionTypesByAfm: null,
            aliasesArr: [],
            ecomData: null
        };

        this.checkEcomNotEcomAvailability = this.checkEcomNotEcomAvailability.bind(this);
        this.handleChange = this.handleChange.bind(this);
        this.togglePopover = this.togglePopover.bind(this);
        this.adjustTable = this.adjustTable.bind(this);
        this.adjustInstallmentsTable = this.adjustInstallmentsTable.bind(this);
        this.getRequiredMaps = this.getRequiredMaps.bind(this);
        this.formatData = this.formatData.bind(this);
        this.getTransactions = this.getTransactions.bind(this);
        this.getInstallments = this.getInstallments.bind(this);
        this.downloadTransactionsReport = this.downloadTransactionsReport.bind(this);
        this.allResultsDropdowns = this.allResultsDropdowns.bind(this);
        this.resetPopover = this.resetPopover.bind(this);
        this.hideTransactionReportError = this.hideTransactionReportError.bind(this);
        this.toggleMoreCriteria = this.toggleMoreCriteria.bind(this);
        this.getListsByAfmOrMid = this.getListsByAfmOrMid.bind(this);
        this.setSelectedMid = this.setSelectedMid.bind(this);
        this.toDropdownFormat = this.toDropdownFormat.bind(this);
        this.defineInitialPaymentTypeValue = this.defineInitialPaymentTypeValue.bind(this);
        this.setSelectedTab = this.setSelectedTab.bind(this);
        this.setAliasesArr = this.setAliasesArr.bind(this);
    }

    componentWillMount() {
        store.dispatch(getEcomAvailabilityByAfm()).then((resp) => {
            let afmParam = this.props.merchantReducer.afm ? this.props.merchantReducer.afm: null;
            this.checkEcomNotEcomAvailability(afmParam, resp.value.data);
        });
        this.setAliasesArr(this.state.userAfm)
        if (this.state.paymentTypeValue !== 'installments') {
            this.getTransactions(
              this.state.dateFrom,
              this.state.dateTo,
              this.state.startTime,
              this.state.endTime,
              this.state.currentPage,
              this.state.rowsPerPage,
              false, this.state.by, undefined, this.state.paymentTypeValue);
        } else {
            this.getInstallments(
              this.state.trxId,
              this.state.dateFrom,
              this.state.dateTo,
              this.state.amountFrom,
              this.state.amountTo,
              this.state.currentPage,
              this.state.rowsPerPage,
              false, this.state.by, undefined);
        }

        this.getRequiredMaps();
        this.allResultsDropdowns(this.props.userReducer);
        this.setState({
            getAssociationsResponse: this.props.userReducer,
            allPosBaseVmid: I18n.t('pages.transactions.searchModal.lowerMidColumn.allVmids'),
            allPosBaseVtid: I18n.t('pages.transactions.searchModal.lowerMidColumn.allVtids')
        }, () => {
            this.getListsByAfmOrMid(this.state.userAfm);
        });
    }

    checkEcomNotEcomAvailability(afm, data) {
        let hasEcomTransactions = false;
        let hasNotEcomTransactions = false;
        data.forEach(element => {
            if (!afm || afm === element.afm) {
                hasEcomTransactions = element.ecom ? element.ecom : hasEcomTransactions
                hasNotEcomTransactions = element.notecom ? element.notecom : hasNotEcomTransactions
            }
        })

        const paymentTypeValue = this.defineInitialPaymentTypeValue(hasEcomTransactions, hasNotEcomTransactions, true)
        this.setState({
            hasEcomTransactions: hasEcomTransactions,
            hasNotEcomTransactions: hasNotEcomTransactions,
            paymentTypeValue: paymentTypeValue
        })
    }

    setSelectedMid(mid) {
        this.setState({
            selectedMid: mid,
            searchStartedFromPopoverSearch: true
        }, () => {
            this.getListsByAfmOrMid(this.state.userAfm ? this.state.userAfm : undefined, mid ? mid.toString() : undefined)
        })
    }

    async getListsByAfmOrMid(afm, mid) {
        const ecom = this.state.paymentTypeValue === 'ecom';
        if (!mid) {
            store.dispatch(getMidsAndTidsFilteredByAfmAndMidAndEcom(1, -1, afm, undefined, ecom)).then(
                (response) => {
                    this.setState({
                        filteredMids: response.value.data.midsWithLocation,
                        initialTids: response.value.data.tids
                    }, () => {
                        let midsInDropdownFormat = this.toDropdownFormat(this.state.filteredMids, 'pickedMid');
                        let initialTidsInDropdownFormat = this.toDropdownFormat(this.state.initialTids, 'pickedTid', 'allPosBaseTid');
                        this.setState({
                            nonMapData: update(this.state.nonMapData, {
                                midsInDropdownFormat: {$set: midsInDropdownFormat},
                                tidsInDropdownFormat: {$set: initialTidsInDropdownFormat}
                            }),

                        })
                    })
                });
        } else {
            store.dispatch(getMidsAndTidsFilteredByAfmAndMidAndEcom(1, -1, afm, mid, ecom)).then(
                (response) => {
                    this.setState({
                        filteredTids: response.value.data.tids,
                        filteredVMids: response.value.data.vMids,
                        filteredVTids: response.value.data.vTids,
                    }, () => {
                        let tidsInDropdownFormat = this.toDropdownFormat(this.state.filteredTids, 'pickedTid', 'allPosBaseTid');
                        let vMidsInDropdownFormat = this.toDropdownFormat(this.state.filteredVMids, 'pickedVMid', 'allPosBaseVmid');
                        let vTidsInDropdownFormat = this.toDropdownFormat(this.state.filteredVTids, 'pickedVTid', 'allPosBaseVtid');
                        this.setState({
                            nonMapData: update(this.state.nonMapData, {
                                tidsInDropdownFormat: {$set: tidsInDropdownFormat},
                                vMidsInDropdownFormat: {$set: vMidsInDropdownFormat},
                                vTidsInDropdownFormat: {$set: vTidsInDropdownFormat},
                            }),
                            searchStartedFromPopoverSearch: false
                        })
                    })
                });
        }

        store.dispatch(getTransactionTypesByAfm(afm)).then(
            (response) => {
                this.setState({
                    transactionTypesByAfm: response.value.data
                })
            });

    }


    componentWillReceiveProps(nextProps) {
        if (nextProps.i18n.locale !== this.props.i18n.locale) {
            this.setState({
                allStoresBaseMid: I18n.t('pages.transactions.searchModal.midColumn.allStores'),
                allPosBaseTid: I18n.t('pages.transactions.searchModal.midColumn.allPos'),
                allPosBaseVmid: I18n.t('pages.transactions.searchModal.lowerMidColumn.allVmids'),
                allPosBaseVtid: I18n.t('pages.transactions.searchModal.lowerMidColumn.allVtids'),
                allAcquirersBase: I18n.t('pages.transactions.searchModal.lowerMidColumn.pickBank'),
            }, () => {
                this.allResultsDropdowns(this.state.getAssociationsResponse)
            })
        }

        if (nextProps.merchantReducer.afm !== this.props.merchantReducer.afm) {
            this.checkEcomNotEcomAvailability(nextProps.merchantReducer.afm, nextProps.merchantReducer.ecomAvailability.payload)
            this.setState({
                userAfm: nextProps.merchantReducer.afm ? nextProps.merchantReducer.afm : undefined,
                isCardlinkOne: nextProps.merchantReducer.isCardlinkOne ? nextProps.merchantReducer.isCardlinkOne : false
            }, () => {
                this.adjustTable(1, 20, "authorizationDate,authorizationTime");
                if (this.state.userAfm) {
                    this.getListsByAfmOrMid(this.state.userAfm ? this.state.userAfm :
                        undefined, undefined);
                } else {
                    this.setState({
                        filteredMids: undefined,
                        filteredTids: undefined
                    }, () => {
                        this.allResultsDropdowns(this.props.userReducer)
                    })
                }
            })
        }
    }

    handleChange(event) {
        this.setState({
            [event.target.id]: event.target.value
        })
    }

    adjustTable(page, sizePerPage, sortField, sortOrder) {
        this.getTransactions(
            this.state.dateFrom,
            this.state.dateTo,
            this.state.startTime,
            this.state.endTime,
            page,
            sizePerPage,
            false,
            sortField,
            sortOrder,
            this.state.paymentTypeValue,
            this.state.cardNumber,
            this.state.rrn,
            this.state.authorizationCode,
            this.state.merchantId,
            this.state.terminalId,
            this.state.amountFrom,
            this.state.amountTo,
            this.state.acquirerId,
            this.state.vMerchantId,
            this.state.vTerminalId,
            this.state.transactionType,
            this.state.successStatus,
            this.state.rejectStatus,
            this.state.packageStatus,
            this.state.transactionTypeLabel,
            this.state.paymentTypeLabel,
            this.state.acquirerTypeLabel,
            this.state.showDuplicateTransactions
        )
    }

    adjustInstallmentsTable(page, sizePerPage, sortField, sortOrder) {
      this.getInstallments(
        this.state.trxId,
        this.state.dateFrom,
        this.state.dateTo,
        this.state.amountFrom,
        this.state.amountTo,
        page,
        sizePerPage,
        false,
        sortField,
        sortOrder
      )
    }

    resetPopover() {
        this.setState({
            isNoteToggleOn: false,
            isEcomToggleOn: false,
            isInstallmentsToggleOn: false,
        });
        this.toggleMoreCriteria(false);
        store.dispatch(toggleSearchTransactionsPopover(false));
        this.getListsByAfmOrMid(this.state.userAfm ? this.state.userAfm : undefined, undefined);
    }

    togglePopover() {
        this.setState(function (prevState) {
            return {
                isNoteToggleOn: this.state.paymentTypeValue === "notecom" ? !prevState.isNoteToggleOn : prevState.isNoteToggleOn,
                isEcomToggleOn: this.state.paymentTypeValue === "ecom" ? !prevState.isEcomToggleOn : prevState.isEcomToggleOn,
                isInstallmentsToggleOn: this.state.paymentTypeValue === "installments" ? !prevState.isInstallmentsToggleOn : prevState.isInstallmentsToggleOn
            };
        }, () => {
            this.toggleMoreCriteria(false)
            store.dispatch(toggleSearchTransactionsPopover(this.state.isNoteToggleOn || this.state.isEcomToggleOn || this.state.isInstallmentsToggleOn));
        });
    }

    downloadTransactionsReport() {
        const currentUrl = window.location.origin
        this.setState({
            loaderSquircleIsActive: true
        }, () => {
            setTimeout(() => {
                this.setState({
                    loaderSquircleIsActive: false
                });
            }, 5000)
        });
        if (this.state.paymentTypeValue !== 'installments') {
            store.dispatch(downloadTransactionReport(this.state.userAfm, this.state.dateFrom, this.state.dateTo, this.state.startTime,
              this.state.endTime, 1, -1, this.props.i18n.locale,
              this.state.cardNumber, this.state.rrn, this.state.authorizationCode,
              this.state.merchantId, this.state.terminalId, this.state.amountFrom, this.state.amountTo,
              this.state.acquirerId, this.state.vMerchantId, this.state.vTerminalId, this.state.transactionType,
              this.state.responseCode, this.state.packageStatus, this.state.paymentTypeValue, this.state.by,
              this.state.asc, this.state.showDuplicateTransactions,
              this.props.userReducer.impersonator !== null ? this.props.userReducer.impersonator.email : this.props.userReducer.profile.email,
              currentUrl, this.props.userReducer.impersonator !== null)).then((response) => {
              },
              (error) => {
                  if (error.response.status === 413) {
                      this.setState({
                          transactionReportError: 'pages.transactions.excelReport.largeAmountOfDataError'
                      });

                  }
              })
        } else {
            store.dispatch(downloadInstallmentsReport(this.state.userAfm, this.state.trxId, this.state.dateFrom, this.state.dateTo, this.state.amountFrom, this.state.amountTo, 1, -1, this.state.by, this.state.asc, this.props.userReducer.impersonator !== null ? this.props.userReducer.impersonator.email : this.props.userReducer.profile.email,
              currentUrl)).then(() => {
            },
          (error) => {
              if (error.response.status === 413) {
                  this.setState({
                      transactionReportError: 'pages.transactions.excelReport.largeAmountOfDataError'
                  });

              }
          })
        }

    }

    hideTransactionReportError() {
        this.setState({
            transactionReportError: ''
        });
    }


    getTransactions(dateFrom, dateTo, startTime, endTime, page, rowsPerPage, fromSearch, by, asc, paymentTypeValue,
                    cardNumber, rrn, authorizationCode, merchantId, terminalId, amountFrom, amountTo, acquirerId,
                    vMerchantId, vTerminalId, transactionType, successStatus, rejectStatus, packageStatus,
                    transactionTypeLabel, acquirerTypeLabel, paymentTypeLabel, showDuplicateTransactions) {

        let responseCode = (successStatus && !rejectStatus) ? 'approved' : (rejectStatus && !successStatus) ? 'declined' : undefined;

        if (fromSearch === true) {
            // It is the correspoding api field for 'transaction status'
            dateFrom = moment(dateFrom).format('DD/MM/YYYY')
            dateTo = moment(dateTo).format('DD/MM/YYYY')
            startTime = moment(startTime).format('HH:mm')
            endTime = moment(endTime).format('HH:mm')
            merchantId = merchantId ? merchantId : null
            terminalId = terminalId ? terminalId.value : null
            vMerchantId = vMerchantId ? vMerchantId.value : null
            vTerminalId = vTerminalId ? vTerminalId.value : null
            by = "authorizationDate,authorizationTime"
            this.setState({
                // close search popOver
                isToggleOn: !this.state.isToggleOn,
                dateFrom: dateFrom,
                dateTo: dateTo,
                startTime: startTime,
                endTime: endTime,
                page: page,
                rowsPerPage: rowsPerPage,
                cardNumber: cardNumber,
                by: by,
                rrn: rrn,
                authorizationCode: authorizationCode,
                responseCode: responseCode,
                merchantId: merchantId,
                terminalId: terminalId,
                amountFrom: amountFrom,
                amountTo: amountTo,
                acquirerId: acquirerId,
                vMerchantId: vMerchantId,
                vTerminalId: vTerminalId,
                transactionType: transactionType,
                transactionTypeLabel: transactionTypeLabel,
                successStatus: successStatus,
                rejectStatus: rejectStatus,
                packageStatus: packageStatus,
                paymentTypeValue: paymentTypeValue,
                acquirerTypeLabel: acquirerTypeLabel,
                paymentTypeLabel: paymentTypeLabel,
                showDuplicateTransactions: showDuplicateTransactions
            })
        }

        by = (showDuplicateTransactions && fromSearch ? "duplicate_id," : "") + by

        store.dispatch(getTransactionList(this.state.userAfm, dateFrom, dateTo, startTime, endTime,
            page, rowsPerPage, cardNumber, rrn, authorizationCode, merchantId, terminalId, amountFrom, amountTo,
            acquirerId, vMerchantId, vTerminalId, transactionType, responseCode, packageStatus, paymentTypeValue, by,
            asc, showDuplicateTransactions)).then(
            (response) => {
                this.setState({
                    tableData: this.formatData(response.value.data.payload.payload),
                    total: response.value.data.payload.total,
                    currentPage: page,
                    rowsPerPage: rowsPerPage,
                    by: by,
                    asc: asc,
                    fromSearch: fromSearch
                })
            },
            (error) => {
            })
    }

    getInstallments(trxId, dateFrom, dateTo, amountFrom, amountTo, page, rowsPerPage, fromSearch, by, asc) {
        if (fromSearch === true) {
            dateFrom = moment(dateFrom).format('DD/MM/YYYY')
            dateTo = moment(dateTo).format('DD/MM/YYYY')
            by = "payment_date"
            this.setState({
                // close search popOver
                isToggleOn: !this.state.isToggleOn,
                dateFrom: dateFrom,
                dateTo: dateTo,
                amountFrom: amountFrom,
                amountTo: amountTo,
                page: page,
                rowsPerPage: rowsPerPage,
                by: by,
                trxId: trxId
            })
        }

        store.dispatch(getInstallmentsList(this.state.userAfm, trxId, dateFrom, dateTo, amountFrom, amountTo, page, rowsPerPage, by, asc)).then(
          (response) => {
              const data = response.value.data.payload.payload.map(obj => ({
                  ...obj,
                  amount: getSignedAmount(obj.amount, obj.amountSign),
                  statusId: I18n.t('pages.installments.details.statusDescription.' + obj.statusId)
              }));
              this.setState({
                  installmentsTableData: data,
                  total: response.value.data.payload.total,
                  currentPage: page,
                  rowsPerPage: rowsPerPage,
                  by: by,
                  asc: asc,
                  fromSearch: fromSearch
              })
          },
          (error) => {
          })


    }

    allResultsDropdowns(response) {

        let midsInDropdownFormat = this.state.filteredMids ?
            this.toDropdownFormat(this.state.filteredMids, 'pickedMid')
            : this.toDropdownFormat(response.getAssociationsResponse.uniqueMidsWithLocation, 'pickedMid');
        let tidsInDropdownFormat = this.state.filteredTids ?
            this.toDropdownFormat(this.state.filteredTids, 'pickedTid', 'allPosBaseTid')
            : this.toDropdownFormat(response.getAssociationsResponse.uniqueTids, 'pickedTid', 'allPosBaseTid');
        let vMidsInDropdownFormat = this.state.filteredVMids ?
            this.toDropdownFormat(this.state.filteredVMids, 'pickedVMid', 'allPosBaseVmid')
            : this.toDropdownFormat(response.getAssociationsResponse.uniqueVMids, 'pickedVMid', 'allPosBaseVmid');
        let vTidsInDropdownFormat = this.state.filteredVTids ?
            this.toDropdownFormat(this.state.filteredVTids, 'pickedVTid', 'allPosBaseVtid')
            : this.toDropdownFormat(response.getAssociationsResponse.uniqueVTids, 'pickedVTid', 'allPosBaseVtid');

        this.setState({
            nonMapData: {
                midsInDropdownFormat: midsInDropdownFormat,
                tidsInDropdownFormat: tidsInDropdownFormat,
                vMidsInDropdownFormat: vMidsInDropdownFormat,
                vTidsInDropdownFormat: vTidsInDropdownFormat
            }
        })
    }

    toDropdownFormat(arr, id, state) {
        let result = arr.map(element => {
            if (id === 'pickedMid' && element.mid) {
                let label = element.location ? element.mid.concat(" - ", element.location) : element.mid;
                label = filterByKeyValue(this.state.aliasesArr, element.mid) ? label + " - " + filterByKeyValue(this.state.aliasesArr, element.mid) : label;
                return {value: element.mid, label: label}
            }

            return {value: element, label: element, id: id}
        });

        if (id !== 'pickedMid') {
            result.unshift({value: undefined, label: this.state[state], id: id});
        }
        result.forEach((obj, idx) => {
            if (id === 'pickedTid') {
                obj.label = filterByKeyValue(this.state.aliasesArr, obj.value) ? obj.label + " - " + filterByKeyValue(this.state.aliasesArr, obj.value) : obj.label;
            }
            if ((obj.value === null || obj.value === "") && (obj.label === null || obj.label === "")) {
                result.splice(idx, 1);
            }
        });
        return result;
    }

   setAliasesArr(afm) {
       store.dispatch(getMidsTidsWithAliases(afm, undefined)).then((response) => {
               this.setState({
                   aliasesArr: response.value.data
               })
           })
    }

    formatData(data) {
        let colors = ["#ffffff", "#CECDCC"];
        let colorInx = 0;
        let currentDuplicateId = null;
        data.forEach((obj, idx) => {
            obj.dateAndTime = [obj.authorizationDate, obj.authorizationTime];
            obj.cardTypeAndNumber = [obj.cardTypeBrand, obj.maskedCardNumber];
            obj.authorizationAmount = getSignedAmount(obj.authorizationAmount, obj.amountSign)
            if (this.state.showDuplicateTransactions && obj.duplicateId !== null) {
                if (currentDuplicateId !== obj.duplicateId) {
                    colorInx = (colorInx + 1) % 2
                    currentDuplicateId = obj.duplicateId
                }
                obj.color = {"backgroundColor": colors[colorInx]}
            } else {
                obj.color = null
            }
        });
        return data;
    };

    getRequiredMaps() {
        store.dispatch(getMap('transactionMaps')).then(
            (response) => {
                this.setState({
                    allMaps: response.value.data
                })
            },
            (error) => {
            })
    }

    toggleMoreCriteria(moreCriteria) {
        this.setState({
            moreCriteria: moreCriteria
        })
    }

    defineInitialPaymentTypeValue(hasEcomTransactions, hasNotEcomTransactions, resetToPhysical) {
        let typeVal
        if (!hasNotEcomTransactions && hasEcomTransactions) {
            typeVal = 'ecom'
        } else if (!hasEcomTransactions && hasNotEcomTransactions) {
            typeVal = 'notecom'
        } else {
            if (resetToPhysical) {
                typeVal = 'notecom'
            } else {
                typeVal = this.state.paymentTypeValue
            }
        }

        return typeVal
    }

    setSelectedTab(){
        if((this.state.paymentTypeValue !== 'ecom' && this.state.paymentTypeValue !== 'notecom' && this.state.paymentTypeValue !== 'installments') || !this.state.paymentTypeValue) {
            return 'notecom'
        } else {
            return this.state.paymentTypeValue
        }
    }


    render() {
        // Required to know which of 2 tabs to render
        // The possible values of 'paymentTypeValue' are more than 'ecom' or 'notecom' so must be mapped accordingly
        // to these two
        this.defineInitialPaymentTypeValue(this.state.hasEcomTransactions, this.state.hasNotEcomTransactions);
        return (
            this.props.userReducer.getAssociationsResponse.uniqueAfmsWithTitles.length > 0 ?
                <main className={"main-page transactions-page"
                + (this.state.moreCriteria ? " more-criteria" : "")
                + (this.state.isEcomToggleOn ? " ecom-open" : " note-open")

                }>
                    <div className="transactions">
                        {this.props.merchantReducer.ecomAvailability.fetched ?
                        <Tabs activeKey={this.setSelectedTab()}
                              onSelect={(paymentTypeValue) => {
                                  const isInstallments = paymentTypeValue === 'installments'
                                  this.setState({
                                      paymentTypeValue: paymentTypeValue,
                                      paymentTypeLabel: null,
                                      currentPage: 1,
                                      rowsPerPage: 20,
                                      dateFrom: moment().format('DD/MM/YYYY'),
                                      dateTo: moment().format('DD/MM/YYYY'),
                                      startTime: "00:00",
                                      endTime: "23:59",
                                      cardNumber: '',
                                      rrn: '',
                                      authorizationCode: '',
                                      merchantId: undefined,
                                      terminalId: '',
                                      amountFrom: '',
                                      amountTo: '',
                                      acquirerId: undefined,
                                      acquirerTypeLabel: null,
                                      vMerchantId: undefined,
                                      vTerminalId: undefined,
                                      transactionType: undefined,
                                      transactionTypeLabel: '',
                                      successStatus: false,
                                      rejectStatus: false,
                                      packageStatus: null,
                                      asc: false,
                                      by: isInstallments ? "payment_date" : "authorizationDate",
                                      showDuplicateTransactions: false
                                  }, () => {
                                      if (isInstallments) {
                                        this.getInstallments(
                                          this.state.trxId,
                                          this.state.dateFrom,
                                          this.state.dateTo,
                                          this.state.amountFrom,
                                          this.state.amountTo,
                                          this.state.currentPage,
                                          this.state.rowsPerPage,
                                          false,
                                          this.state.by,
                                          this.state.asc
                                        )
                                      } else {
                                          this.getTransactions(
                                            this.state.dateFrom,
                                            this.state.dateTo,
                                            this.state.startTime,
                                            this.state.endTime,
                                            this.state.currentPage,
                                            this.state.rowsPerPage,
                                            false,
                                            this.state.by,
                                            this.state.asc,
                                            this.state.paymentTypeValue,
                                            this.state.cardNumber,
                                            this.state.rrn,
                                            this.state.authorizationCode,
                                            this.state.merchantId,
                                            this.state.terminalId,
                                            this.state.amountFrom,
                                            this.state.amountTo,
                                            this.state.acquirerId,
                                            this.state.vMerchantId,
                                            this.state.vTerminalId,
                                            this.state.transactionType,
                                            this.state.successStatus,
                                            this.state.rejectStatus,
                                            this.state.packageStatus,
                                            this.state.transactionTypeLabel,
                                            this.state.pickedAcquirerLabel,
                                            this.state.paymentTypeLabel,
                                            this.state.showDuplicateTransactions
                                          )
                                      };
                                      this.resetPopover();
                                  })
                              }}
                              id="controlled-tabs-transactions">
                            {this.state.hasNotEcomTransactions ?
                                <Tab eventKey='notecom'
                                     title={<Translate value="pages.transactions.tabs.store.title" dangerousHTML/>}>
                                    <TransactionPopoverAndTable data={this.state}
                                                                tableData={this.state.tableData}
                                                                allMaps={this.state.allMaps}
                                                                adjustTable={this.adjustTable}
                                                                locale={this.props.i18n}
                                                                isToggleOn={this.state.isNoteToggleOn}
                                                                togglePopover={this.togglePopover}
                                                                getTransactions={this.getTransactions}
                                                                resetPopover={this.resetPopover}
                                                                downloadTransactionsReport={this.downloadTransactionsReport}
                                                                transactionReportError={this.state.transactionReportError}
                                                                hideTransactionReportError={this.hideTransactionReportError}
                                                                authorizationReport={this.props.merchantReducer.authorizationReport}
                                                                merchantReducer={this.props.merchantReducer}
                                                                toggleMoreCriteria={this.toggleMoreCriteria}
                                                                setSelectedMid={this.setSelectedMid}
                                                                searchStartedFromPopoverSearch={this.state.searchStartedFromPopoverSearch}
                                                                transactionTypesByAfm={this.state.transactionTypesByAfm}
                                                                showDuplicateTransactions={this.state.showDuplicateTransactions}
                                    />
                                </Tab> : null}
                            {this.state.hasEcomTransactions ?
                                <Tab eventKey='ecom'
                                     title={<Translate value="pages.transactions.tabs.ecommerce.title" dangerousHTML/>}>
                                    <TransactionPopoverAndTable data={this.state}
                                                                tableData={this.state.tableData}
                                                                allMaps={this.state.allMaps}
                                                                adjustTable={this.adjustTable}
                                                                locale={this.props.i18n}
                                                                isToggleOn={this.state.isEcomToggleOn}
                                                                togglePopover={this.togglePopover}
                                                                getTransactions={this.getTransactions}
                                                                resetPopover={this.resetPopover}
                                                                downloadTransactionsReport={this.downloadTransactionsReport}
                                                                transactionReportError={this.state.transactionReportError}
                                                                hideTransactionReportError={this.hideTransactionReportError}
                                                                authorizationReport={this.props.merchantReducer.authorizationReport}
                                                                merchantReducer={this.props.merchantReducer}
                                                                toggleMoreCriteria={this.toggleMoreCriteria}
                                                                setSelectedMid={this.setSelectedMid}
                                                                isEcom={true}
                                                                searchStartedFromPopoverSearch={this.state.searchStartedFromPopoverSearch}
                                                                hasNotEcomTransactions={this.state.hasNotEcomTransactions}
                                                                transactionTypesByAfm={this.state.transactionTypesByAfm}
                                                                showDuplicateTransactions={this.state.showDuplicateTransactions}
                                    />
                                </Tab> : null}
                            {this.state.isCardlinkOne ?
                                <Tab eventKey='installments'
                                     title={<Translate value="pages.transactions.tabs.installments.title" dangerousHTML/>}>
                                    <InstallmentPopoverAndTable data={this.state}
                                                                tableData={this.state.installmentsTableData}
                                                                allMaps={this.state.allMaps}
                                                                adjustTable={this.adjustInstallmentsTable}
                                                                locale={this.props.i18n}
                                                                isToggleOn={this.state.isInstallmentsToggleOn}
                                                                togglePopover={this.togglePopover}
                                                                getTransactions={this.getInstallments}
                                                                resetPopover={this.resetPopover}
                                                                downloadTransactionsReport={this.downloadTransactionsReport}
                                                                transactionReportError={this.state.transactionReportError}
                                                                hideTransactionReportError={this.hideTransactionReportError}
                                                                authorizationReport={this.props.merchantReducer.authorizationReport}
                                                                merchantReducer={this.props.merchantReducer}
                                                                toggleMoreCriteria={this.toggleMoreCriteria}
                                                                setSelectedMid={this.setSelectedMid}
                                                                isEcom={true}
                                                                searchStartedFromPopoverSearch={this.state.searchStartedFromPopoverSearch}
                                                                hasNotEcomTransactions={this.state.hasNotEcomTransactions}
                                                                transactionTypesByAfm={this.state.transactionTypesByAfm}
                                                                showDuplicateTransactions={this.state.showDuplicateTransactions}
                                    />
                                </Tab> : null}
                        </Tabs>:
                            this.props.merchantReducer.ecomAvailability.fetching ?
                            <Loader bouncing={true}
                                                  color={'cerulean'}
                                                  message=''
                                                  subMessage=''
                                                  loaderStyles={{minHeight: "120px", top: "80px", position: "absolute"}}/>:null}
                        {this.state.loaderSquircleIsActive ?
                            <Loader bouncing={true}
                                    color={'cerulean'}
                                    loaderStyles={{minHeight: "100vh", margin: "0"}}
                                    message='loader.trxEmail'
                                    subMessage='loader.trxSubMessage'
                                    style={{top: "115px"}}
                                    overlayClass="is-overlay"/> : null
                        }
                    </div>
                </main> :
                <div className="no-pos__wrapper">
                    <EmptyStateShape title="common.noPosInAccount" />
                </div>
        )
    }
}

export default connect(stateMap)(Transactions);
