import React from "react";
import IdleTimer from 'react-idle-timer'
import {millisToMinutesAndSeconds} from "../../lib/utils";
import store from "../../store";
import {setUserLeaveTimestamp} from "../../actions/userActions";
import {withRouter} from "react-router-dom";
import connect from "react-redux/lib/connect/connect";


const stateMap = (store) => {
    return {
        userReducer: store.userReducer
    };
};


class SessionManagement extends React.Component {
    constructor(props) {
        super(props)

        this.state = {
            remainingTime: null,
            lastActive: null
        }

        this.idleTimer = null
        this.onIdle = this._onIdle.bind(this)
        this.handleBeforeUnload = this.handleBeforeUnload.bind(this)
        this.handleLoad = this.handleLoad.bind(this)

    }

    checkIfTimeoutHasPassed(isIdleTimer) {
        let timeToCompare = isIdleTimer ? (this.idleTimer != null ? this.idleTimer.getLastActiveTime() : null) : (this.props.userReducer ? this.props.userReducer.userLeaveTimestamp : null);
        if (timeToCompare != null) {
            const beforeTimeout = new Date();
            beforeTimeout.setMilliseconds(beforeTimeout.getMilliseconds() - this.props.timeout)
            if (beforeTimeout.getTime() >= timeToCompare) {
                this.props.handleLogout();
            }
        }
    }

    componentDidMount() {
        this.checkIfTimeoutHasPassed(false)
        this.checkIfTimeoutHasPassed(true)
        this.setState({
            remainingTime: this.idleTimer.getRemainingTime()
        })
        this.myInterval = setInterval(() => {
            this.setState({
                remainingTime: this.idleTimer.getRemainingTime()
            })
        }, 1000)
        window.addEventListener('beforeunload', this.handleBeforeUnload);

        window.addEventListener('load', this.handleLoad);
    }

    componentWillUnmount() {
        clearInterval(this.myInterval);
        this.checkIfTimeoutHasPassed(true)
        window.removeEventListener('beforeunload', this.handleBeforeUnload);
        window.removeEventListener('load', this.handleLoad);
    }

    _onIdle(e) {
        this.props.handleLogout();
    }

    handleBeforeUnload = (ev) => {
        store.dispatch(setUserLeaveTimestamp(new Date().getTime()));
    };

    handleLoad = () => {
        this.checkIfTimeoutHasPassed(false);
    };

    render() {
        this.checkIfTimeoutHasPassed(true)
        const timeOut = this.props.timeout;
        return (
            <IdleTimer
                ref={ref => {
                    this.idleTimer = ref
                }}
                crossTab={{
                    type: 'localStorage',
                    emitOnAllTabs: true
                }}
                onIdle={this.onIdle}
                timeout={timeOut}>
                <span>
                    {
                        millisToMinutesAndSeconds(this.state.remainingTime)
                    }
                </span>
            </IdleTimer>
        )
    }
}
export default withRouter(connect(stateMap)(SessionManagement));
