import React, {Component} from 'react';
import GenericModal from "./GenericModal";
import Iframe from 'react-iframe'
import Loader from "./Loader";


export default class PoliciesModal extends Component {
    constructor(props) {
        super(props)
        this.state=({
            privacyPolicy: false,
            cookiesPolicy: false,
            loading: true
        })

        this.hideCurrentModal = this.hideCurrentModal.bind(this);
    }

    componentWillMount() {
        this.setState({
            privacyPolicy: this.props.privacyPolicy,
            cookiesPolicy: this.props.cookiesPolicy,
            loading: true
        })
    }

    componentWillReceiveProps(nextProps) {
        if (this.props.privacyPolicy !== nextProps.privacyPolicy) {
            this.setState({
                privacyPolicy: nextProps.privacyPolicy,
            })
        }
        if (this.props.cookiesPolicy !== nextProps.cookiesPolicy) {
            this.setState({
                cookiesPolicy: nextProps.cookiesPolicy,
            })
        }
        this.setState({
            loading: true
        }, () => {
            setTimeout(() => {
                this.setState({
                    loading: false
                })
            }, 2500)
        });
    }
    
    hideCurrentModal() {
        this.props.togglePoliciesModal(this.state.privacyPolicy ? "privacyPolicy": (this.state.cookiesPolicy ? "cookiesPolicy": false), false);
    }

    render() {
        let isPrivacy = this.state.privacyPolicy;
        let isCookies = this.state.cookiesPolicy;
        let privacyUrl = this.props.locale === 'el' ? 'https://cardlink.gr/gdpr/' : 'https://cardlink.gr/en/gdpr-information/';
        let cookiesUrl = this.props.locale === 'el' ? 'https://cardlink.gr/politiki-cookies/' : 'https://cardlink.gr/en/cookies-policy/';
        let url = isPrivacy ? privacyUrl : cookiesUrl;

        return (
            <GenericModal
                showModal={isPrivacy || isCookies}
                showCloseButton={true}
                onHide={this.hideCurrentModal}
                modalTitle={''}
                bsSize="large"
                modalClass='policies__modal modal'
                modalBody={
                    <React.Fragment>
                        {this.state.loading ?
                        <Loader bouncing={true}
                                color={'cerulean'}
                                message=''
                                subMessage=''
                                loaderStyles={{minHeight: "180px", top: "80px", position: "absolute"}}/> : null}
                        <Iframe url= {url}
                                width="100%"
                                height="100%"
                                display={"initial"}
                                style={{visibility: this.state.loading ? 'hidden' : 'visible'}}
                                position="relative"/>
                    </React.Fragment>
                }/>

        )
    }
}