import React from "react";

const Translate = require('react-redux-i18n').Translate;

export default function LoginErrorMessages(props) {

    return <div>
        {props.info.wrongCreds ?
            <div className="generic-error-msg">
                <span className="icon-icon-attention"/>
                <div className="double-msg">
                    <Translate value="pages.login.wrongCreds" dangerousHTML/>
                    <Translate value="pages.login.wrongCreds2"/>
                </div>
            </div> : null
        }
        {props.info.nonConfirmedAccount ?
            <div className="generic-error-msg">
                <span className="icon-icon-attention"/>
                <div>
                    <Translate value="pages.login.nonConfirmedAccount"/>
                </div>
            </div> : null
        }
        {props.info.disabledAccount ?
            <div className="generic-error-msg">
                <span className="icon-icon-attention"/>
                <div>
                    <Translate value="pages.login.disabledAccount"/>
                </div>
            </div> : null
        }
        {props.info.roleNotMerchant ?
            <div className="generic-error-msg">
                <span className="icon-icon-attention"/>
                <div>
                    <Translate value="pages.login.roleNotMerchant"/>
                </div>
            </div> : null
        }
        {props.info.notAllowedIp ?
            <div className="generic-error-msg">
                <span className="icon-icon-attention"/>
                <div>
                    <Translate value="pages.login.notAllowedIp"/>
                </div>
            </div> : null
        }

    </div>
}