import React from "react";
import {Button, Modal, Tab, Tabs} from 'react-bootstrap';
import AllFaq from "./AllFaq";
import AsidePanelSupportForm from "../supportForms/AsidePanelSupportForm";
import store from "../../store";
import {getFreshDeskUrl} from "../../providers/users";
import connect from "react-redux/es/connect/connect";

const Translate = require('react-redux-i18n').Translate;
const I18n = require('react-redux-i18n').I18n;

const stateMap = (store) => {
    return {
        i18n: store.i18n
    };
};

class SupportBox extends React.Component {
    constructor(props) {
        super(props)

        this.state = {
            supportButton: true,
            faqButton: false,
            showSupportForm: false,
            freshDeskUrl: '',
            sendRequestButtonTooltip: I18n.t("tooltips.hover.supportFormTooltip"),
            moreQuestionsTooltip: I18n.t("tooltips.hover.ssoLink"),
        }

        this.openSupportForm = this.openSupportForm.bind(this);
        this.closeSupportForm = this.closeSupportForm.bind(this);
        this.calculateFreshDeskUrl = this.calculateFreshDeskUrl.bind(this);
        this.redirectToFreshDesk = this.redirectToFreshDesk.bind(this)

    }

    calculateFreshDeskUrl() {
        store.dispatch(getFreshDeskUrl()).then((response) => {
            this.setState({
                freshDeskUrl: response.value.data.redirectUrl
            })
        }, (error) => {
        })
    }

    redirectToFreshDesk() {
        window.open(this.state.freshDeskUrl, '_blank')
    }

    componentWillReceiveProps(nextProps) {
        if (nextProps.i18n !== this.props.i18n) {
            this.setState({
                sendRequestButtonTooltip: I18n.t("tooltips.hover.supportFormTooltip"),
                moreQuestionsTooltip: I18n.t("tooltips.hover.ssoLink")
            })
        }
    }

    openSupportForm() {
        this.setState({
            showSupportForm: true
        })
    }

    closeSupportForm() {
        this.setState({
            showSupportForm: false
        })
    }

    render() {
        return (
            <div className="support-form">
                <Modal bsClass="modal"
                       dialogClassName="modal-dialog support-modal__dialog"
                       show={this.state.showSupportForm}
                       onHide={this.closeSupportForm}>
                    <Modal.Header closeButton bsClass="modal-header support-modal_header">
                    </Modal.Header>
                    <Modal.Body bsClass="modal-body support-modal_body">
                        <AsidePanelSupportForm closeModal={this.closeSupportForm}/>
                    </Modal.Body>
                </Modal>
                <Tabs defaultActiveKey={1} id="support-tabs">
                    <Tab eventKey={1} title={<Translate value="pages.overview.supportForm.supportButton"/>}>
                        <p>
                            <span className="icon-call support-icon"></span>
                        </p>
                        <p>
                            <Translate value="pages.overview.supportForm.needHelp"/>
                        </p>
                        <p>
                            <Translate value="pages.overview.supportForm.phoneNumber"/>
                        </p>
                        <p>
                            <Button className="support-form__btn"
                                    title={this.state.sendRequestButtonTooltip}
                                    onClick={this.openSupportForm}>
                                <Translate value="pages.overview.supportForm.otherWay"/>
                            </Button>
                        </p>
                    </Tab>
                    <Tab eventKey={2} title={<Translate value="pages.overview.supportForm.faq"/>}>
                        <AllFaq/>
                        <div className="more-questions hidden" title={this.state.moreQuestionsTooltip}>
                            <div className="more-questions__icon">
                                <span className="icon-more-questions"></span>
                            </div>
                            <div className="more-questions__text">
                                <Translate className="more-questions__first"
                                           value={"pages.overview.faqForm.anyMoreQuestions1"}/>
                                <div className="more-questions__second">
                                    <label htmlFor="anyMoreQuestions" className="mid-line"
                                           onMouseEnter={() => {
                                               this.calculateFreshDeskUrl()
                                           }}>
                                        <input className="mid-line__more-criteria"

                                               type="button" id="anyMoreQuestions" onClick={() => {
                                            this.redirectToFreshDesk()
                                        }}/>
                                        <Translate value={"pages.overview.faqForm.anyMoreQuestions2"}/>
                                    </label>
                                </div>
                            </div>
                        </div>
                    </Tab>
                </Tabs>
            </div>
        )
    }
}

export default connect(stateMap)(SupportBox);