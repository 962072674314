import React from "react";
import {Overlay, Popover} from "react-bootstrap";
import Button from "react-bootstrap/es/Button";
import store from "../../store";
import {toggleInfoTooltips} from "../../actions/userActions";
import {withRouter} from "react-router-dom";
import connect from "react-redux/es/connect/connect";

const Translate = require('react-redux-i18n').Translate;

const allTooltips = [
    'company-tk-tltp',
    'mid-tltp',
    'quickfind-tltp',
    'refund-tltp',
    'multiple-mid-popover-tltp',
    'business-export-tltp',
    'business-export-tid-tltp',
    'report-download-multiple-mid-tltp',
    'reports-right-column-title-tltp',
    'download-trans-tltp',
    'business-mid-alias-tltp',
    'download-trans-tltp',
    'business-tid-alias-tltp',
    'reports-transactions-chart-tltp',
    'overview-kpi-title-tltp',
    'duplicate-transactions-tltp'
];


const stateMap = (store) => {
    return {
        userReducer: store.userReducer,
    };
};


class Tooltip extends React.Component {

    constructor(props) {
        super(props)
        this.state = {
            showTooltip: false
        };
        this.toggleTooltip = this.toggleTooltip.bind(this)
    }


    toggleTooltip(tltp) {
        store.dispatch(toggleInfoTooltips("showInfo__" + tltp.replace(/-/g, "_"), !this.props.userReducer["showInfo__" + tltp.replace(/-/g, "_")]));
        allTooltips.forEach(elProp => {
            if (elProp !== tltp && this.props.userReducer["showInfo__" + elProp.replace(/-/g, "_")]) {
                store.dispatch(toggleInfoTooltips("showInfo__" + elProp.replace(/-/g, "_"), false))
            }
        })
    }


    render() {
        let show = this.props.userReducer["showInfo__" + this.props.popoverId.replace(/-/g, "_")];
        let prefix = "tooltips.click.";
        return (
            <label htmlFor={this.props.tooltipButtonId}
                   className={this.props.tooltipLabelClass}>
                <Button id={this.props.tooltipButtonId}
                        className="tooltip-button"
                        onClick={e => {
                            this.toggleTooltip(this.props.popoverId)
                            this.setState({
                                target: e.target,
                            });
                        }}>
                </Button>
                <span className="icon-icon-info"/>
                <Overlay
                    show={show}
                    target={this.state.target}
                    placement={this.props.placement}
                    container={this}
                    containerPadding={20}>
                    <Popover id={this.props.popoverId}
                             title=''>
                        {this.props.dangerousHtml ?
                            <Translate value={prefix + this.props.tooltipText} dangerousHTML/> :
                            <Translate value={prefix + this.props.tooltipText}/>}
                    </Popover>
                </Overlay>
            </label>
        )
    }
}

export default withRouter(connect(stateMap)(Tooltip));

