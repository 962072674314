import React from "react";

export default class ToggleDetail extends React.Component {

    constructor(props) {
        super(props)
        this.toggleParentItem = this.toggleParentItem.bind(this)
    }

    toggleParentItem(item){
        this.props.toggleItem(item)
    }

    render() {
        let isOpenedState = this.props.type + "IsOpen";
        return (
            <button className={"toggle-item-btn " + (this.props[isOpenedState] ? 'isOpened' : 'isClosed')}
                    onClick={() => this.toggleParentItem(isOpenedState)} >
                <i className='icon-x-icon'></i>
                <i className='icon-edit-pencil'></i>
            </button>
        )
    }
}