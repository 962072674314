import React from "react";
import {connect} from "react-redux";
import {withRouter} from "react-router-dom";
import LocaleSwitch from "../../common/LocaleSwitch";
import Logo from "../../common/Logo";
import store from "../../../store";
import {resendCode, validateOtpAndCompleteProfile} from "../../../providers/users";
import NumberFormat from "react-number-format";
import {setUserDetails} from "../../../actions/userActions";
import GenericModal from "../../common/GenericModal";
import Loader from "../../common/Loader";
import Wave from "../../common/Wave";

const Translate = require('react-redux-i18n').Translate;

const stateMap = (store) => {
    return {
        i18n: store.i18n,
        userReducer: store.userReducer
    };
};

class OtpForm extends React.Component {
    constructor(props) {
        super(props)

        this.state = {
            errorMessage: '',
            fourDigitCode: '',
        }

        this.handleChange = this.handleChange.bind(this)
        this.handleFormattedChange = this.handleFormattedChange.bind(this)
        this.validateOtpAndCompleteProfile = this.validateOtpAndCompleteProfile.bind(this)
        this.checkFourDigitCodeIsFilled = this.checkFourDigitCodeIsFilled.bind(this)
    }

    validateOtpAndCompleteProfile() {
        store.dispatch(validateOtpAndCompleteProfile(this.props.confirmationCode,
            this.props.email, this.state.fourDigitCode)).then(
            () => {
                this.setState({
                    errorMessage: ''
                })
                store.dispatch(setUserDetails({
                    renderOtpForm: false
                }))
                this.props.history.push('/login')
            },
            (error) => {
                if (error.response.data) {
                    if (error.response.data.errors[0] === 'INVALID_OTP') {
                        this.setState({
                            errorMessage: 'pages.userDetails.errors.invalidOtp'
                        })
                    } else if (typeof error.response.data === 'string' &&
                        (error.response.data.includes('id is null.') ||
                         error.response.data.includes('id is already used'))) {
                        this.setState({
                            errorMessage: 'pages.userDetails.errors.anErrorOccured',
                        })
                    }
                }
            })
    }

    resendCode() {
        store.dispatch(resendCode(this.props.email, this.props.phone)).then(
            () => {
                this.setState({
                    errorMessage: ''
                })
            },
            (error) => {
                if ((error.response.status === 503)) {
                    this.setState({
                        errorMessage: 'pages.userDetails.errors.anErrorOccured'
                    })
                }
            })
    }

    handleChange(event) {
        this.setState({
            [event.target.id]: event.target.value
        })
    }

    handleFormattedChange(obj, ev) {
        this.setState({
            [ev.target.id]: obj.value
        })
    }

    checkFourDigitCodeIsFilled() {
        return this.state.fourDigitCode.length < 4
    }

    render() {
        const validatingOtp = this.props.userReducer &&
            this.props.userReducer.validateOtpAndCompleteProfile &&
            this.props.userReducer.validateOtpAndCompleteProfile.fetching
        const resendingOtpCode = this.props.userReducer &&
            this.props.userReducer.resendCode &&
            this.props.userReducer.resendCode.fetching
        return (
            <div className="otp-form">
                <GenericModal
                    showModal={this.state.errorMessage === 'pages.userDetails.errors.anErrorOccured'}
                    showCloseButton={true}
                    onHide={() => {
                        this.setState({
                            errorMessage: ''
                        })
                    }}
                    modalTitle={<Translate value='pages.userDetails.errors.errorOccurredTitle'/>}
                    modalBody={<Translate value={this.state.errorMessage}/>}
                    dialogClassName="reset-area-step1__custom-modal"
                    bsSize="small"/>
                <main>
                    <div className="otp-form__top">
                        <LocaleSwitch type={"login-area"}></LocaleSwitch>
                    </div>
                    <div className="otp-form__content">
                        <div className="otp-form__left">
                            <div className="otp-form__resend-area">
                                <form onSubmit={(e) => {
                                    e.preventDefault();
                                    this.resendCode();
                                }}>
                                    <button type="submit" className="otp-form__resend-button"
                                            disabled={resendingOtpCode}>
                                        {resendingOtpCode ?
                                            <Loader bouncing={false} color={'bahama-blue'} message=''
                                                    style={{marginTop: 0}} bounceClass='smaller-bounces'/> :
                                            <Translate value="pages.userDetails.resendButton"/>
                                        }
                                    </button>
                                    <p className="otp-form__few">
                                        <Translate value="pages.userDetails.allowFewSeconds"/>
                                    </p>
                                </form>
                            </div>
                        </div>
                        <div className="otp-form__login-with-forgot">
                            <div className="otp-form__login-box">
                                <Logo isMain={false} isHeader={false} imgClassName="login"/>
                                <form onSubmit={(e) => {
                                    e.preventDefault();
                                    this.validateOtpAndCompleteProfile();
                                }}>
                                    <div className='otp-form__digit'>
                                        <div className='otp-form__digit-title'>
                                            <Translate value="pages.userDetails.mobilePhone"/>
                                            <span className={"icon-icon-mob"}>
                                            </span>
                                        </div>
                                        <div className="otp-form__digit-column">
                                            <Translate value="pages.userDetails.fourDigitCode"/>
                                            <NumberFormat
                                                type="text"
                                                id="fourDigitCode"
                                                format="####"
                                                className="otp-form__code"
                                                value={this.state.fourDigitCode}
                                                placeholder=""
                                                decimalScale={0}
                                                onValueChange={this.handleFormattedChange}/>
                                    </div>
                                    </div>
                                    <div className="otp-form__wrong-creds">
                                        {this.state.errorMessage !== '' ?
                                            <div className="generic-error-msg">
                                                <span className="icon-icon-attention"/>
                                                <div>
                                                    <Translate value={this.state.errorMessage} dangerousHTML/>
                                                </div>
                                            </div> : null
                                        }
                                    </div>
                                    <div className="otp-form__button-area">
                                        <button type="submit" className={"otp-form__login-button" +
                                        (this.checkFourDigitCodeIsFilled() ? '__gray btn-disabled' : '')}
                                                disabled={this.checkFourDigitCodeIsFilled() ||
                                                validatingOtp}>
                                            {validatingOtp ?
                                                <Loader bouncing={false} color={'white'} message=''
                                                        style={{marginTop: 0}} bounceClass='smaller-bounces'/> :
                                                <Translate value="pages.userDetails.submitButtonText"/>
                                            }
                                        </button>
                                    </div>
                                    <div className="reset-area-step1__forgot-password">
                                        <a href=""
                                           onClick={(e) => {
                                               e.preventDefault()
                                               store.dispatch(setUserDetails({
                                                   renderNewPhoneForm: true, renderOtpForm: false
                                               }))
                                           }}>
                                            <Translate value="pages.userDetails.returnToPhoneForm"/>
                                        </a>
                                    </div>
                                </form>
                            </div>
                        </div>
                    </div>
                    <Wave />
                </main>
            </div>
        )
    }
}

export default withRouter(connect(stateMap)(OtpForm));