import React from 'react';
import connect from "react-redux/es/connect/connect";
import SlidingPane from 'react-sliding-pane';
import 'react-sliding-pane/dist/react-sliding-pane.css';
import Modal from 'react-modal';
import UserProfile from "../sidebar/UserProfile"
import UserManagement from "../sidebar/UserManagement"
import {toggleSideModal} from "../../lib/utils";

const stateMap = (store) => {
    return {
        sideModal: store.sideModalReducer,
        i18n: store.i18n,
        userReducer: store.userReducer
    };
};

class SlidingPanel extends React.Component {

    constructor(props) {
        super(props)

        this.state = {
            isProfile: this.props.sideModal.isProfile,
            isNotification: this.props.sideModal.isNotification,
            isUserManagement: this.props.sideModal.isUserManagement,
            isSmartSettings: this.props.sideModal.isSmartSettings
        }
    }

    componentDidMount() {
        Modal.setAppElement(this.el);
    }

    componentWillReceiveProps(nextProps) {
        if (nextProps.sideModal !== this.props.sideModal) {
            this.setState({
                isProfile: nextProps.sideModal.isProfile,
                isNotification: nextProps.sideModal.isNotification,
                isUserManagement: nextProps.sideModal.isUserManagement,
                isSmartSettings: nextProps.sideModal.isSmartSettings
            })
        }
    }

    render() {
        const slidingPanels = []
        slidingPanels.push({
            panelOpened: this.state.isProfile,
            id: 'isProfile',
            content: "profile"
        })
        slidingPanels.push({
            panelOpened: this.state.isNotification,
            id: 'isNotification',
            content: 'notification Opened'
        })
        slidingPanels.push({
            panelOpened: this.state.isSmartSettings,
            id: 'isSmartSettings',
            content: 'smart settings Opened'
        })
        slidingPanels.push({
            panelOpened: this.state.isUserManagement,
            id: 'isUserManagement',
            content: "user-management"
        })
        return (
            <div ref={ref => this.el = ref}>
                {slidingPanels.map(slidingPanel => {
                    return [
                        <SlidingPane
                            isOpen={slidingPanel.panelOpened === true}
                            key={"slidingPane"}
                            from='left'
                            width='415px'
                            className={"left-sliding " +
                            (slidingPanel.content === 'profile' ? 'slide-pane__profile' :
                            (slidingPanel.content === 'user-management' ? 'slide-pane__user-management' : null))}
                            onRequestClose={() => {
                                toggleSideModal(false, slidingPanel.id)
                             }
                            }>
                            <div>{
                                slidingPanel.content === 'profile' ?
                                    <UserProfile /> : null}
                                {slidingPanel.content === 'user-management' ?
                                   <UserManagement currentUser={this.props.userReducer.profile.userId} locale={this.props.i18n.locale} /> : null
                                }</div>
                        </SlidingPane>
                    ]
                })}
            </div>
        )
    }

}

export default connect(stateMap)(SlidingPanel);
