import React from 'react';
import Panel from 'react-bootstrap/es/Panel';
import {withRouter} from "react-router-dom";
import connect from "react-redux/es/connect/connect";

const stateMap = (store) => {
    return {
        sideModalReducer: store.sideModalReducer,
        userReducer: store.userReducer
    };
};

const Translate = require('react-redux-i18n').Translate

class AfmData extends React.Component {
    constructor(props) {
        super(props)
        this.state = {
            title: this.getLocaleValue(props.locale, "Title", props.data),
            name: this.getLocaleValue(props.locale, "Name", props.data),
            imageError: null,
            imageSuccess: null,
            imgUrl: null,
            imgName: null,
        }
        this.onImageUpload = this.onImageUpload.bind(this)
        this.checkFileTypeIsImage = this.checkFileTypeIsImage.bind(this)
    }

    componentWillReceiveProps(nextProps) {
        this.setState({
            title: this.getLocaleValue(nextProps.locale, "Title", nextProps.data),
            name: this.getLocaleValue(nextProps.locale, "Name", nextProps.data)
        })

    }

    checkFileTypeIsImage(fileType) {
        const splittedFileType = fileType.split("/")
        if (splittedFileType === fileType || splittedFileType[0] !== "image") {
            this.setState({
                ...this.state,
                imageError: <Translate value="modals.profile.logoSpecifications"/>,
                imageSuccess: '',
                imgUrl: null,
                imgName: null
            })
            return false
        }
        return true
    }

    checkImageDimensionsAndSize(image, fileSize) {
        // 4 MB
        const maxFileSizeInBytes = 4194304;
        if (fileSize > maxFileSizeInBytes) {
            this.setState({
                ...this.state,
                imageError: <Translate value="modals.profile.logoSpecifications"/>,
                imageSuccess: '',
                imgUrl: null,
                imgName: null
            })
            return false
        } else {
            this.setState({
                ...this.state,
                imageError: null
            })
            return true
        }
    }

    onImageUpload(image) {
        let file = image.target.files[0]
        if (!this.checkFileTypeIsImage(file.type)) return
        let reader = new FileReader()
        reader.readAsDataURL(file)
        reader.onloadend = function (e) {
            let imageSrc = reader.result
            let image = new Image()
            image.src = imageSrc
            image.onload = function () {
                if (this.checkImageDimensionsAndSize(image, file.size))
                    this.setState({
                        ...this.state,
                        imgUrl: reader.result,
                        imgName: file.name
                    })
            }.bind(this)
        }.bind(this)
    }

    getLocaleValue(locale, field, data){
        let greekField = "merchant"+field+"Greek";
        let latinField = "merchant"+field+"Latin";
        return locale === "en"
            ? data[latinField] ? data[latinField] : data[greekField]
            : data[greekField] ? data[greekField] : data[latinField];
    }
    render() {
        let item = this.props.data;
        let currRow = [{key: 'afm', value: item.afm}, {key: 'doy', value: item.doy},
            {key: 'name', value: this.state.name}]
        return (
            <div>
                <Panel id="collapsible-panel-company-elements"
                       defaultExpanded={this.props.userReducer.getAssociationsResponse.merchantType === 'singleAfmMid'}>
                    <Panel.Heading>
                        <Panel.Toggle componentClass="h4">
                            <div className="company-elements__title__wrapper">
                                <Translate value="pages.business.details"/>
                            </div>
                            <i className="icon-plus-accordion"></i>
                            <i className="icon-minus-accordion"></i>
                        </Panel.Toggle>
                    </Panel.Heading>
                    <Panel.Collapse>
                        <Panel.Body>
                            <div className="business-row business-row__elements">
                                {this.props.data !== null ?
                                    currRow.map((el) => {
                                        return [
                                            <div className="business-column business-row__elements_item" key={el.key}>
                                                <Translate value={"pages.business." + el.key}/>
                                                <div
                                                    className="business-element__value"> {el.value ? el.value : '-'}</div>
                                            </div>
                                        ]
                                    })
                                    : null}
                            </div>
                        </Panel.Body>
                    </Panel.Collapse>
                </Panel>
            </div>
        )
    }
}

export default withRouter(connect(stateMap)(AfmData));