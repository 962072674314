import React from "react";
import {withRouter} from "react-router-dom";
import store from "../../store";
import {confirmEmailChange} from "../../providers/users";
import connect from "react-redux/es/connect/connect";

import queryString from 'query-string';

const stateMap = (store) => {
    return {
        i18n: store.i18n
    };
};

// TODO If not used for anything other than confirm email for change pass,
//  probably change class name
class GlobalActions extends React.Component {
    componentDidMount() {
        const parsedUrl = queryString.parse(this.props.location.search);
        // Came here after email change in Profile tab
        if (parsedUrl && parsedUrl.confirmationCode) {
            store.dispatch(confirmEmailChange(parsedUrl.confirmationCode)).then(
                (response) => {
                },
                (error) => {
                    console.log('error is ', error)
                })
        }
    }

    render() {
        return null
    }
}

export default withRouter(connect(stateMap)(GlobalActions));