export default function merchantReducer(state = {
    afm: null,
    name: null,
    isCardlinkOne: null,
    midsCommaSeparated: null,
    midsSearchBoxFormat: null,
    merchantAlias: null,
    orderStatus: null,
    merchantStatus: {
        fetching: false,
        fetched: false,
        orderStatuses: []
    },
    merchantTree: {
        fetching: false,
        fetched: false
    },
    merchantTreeSearch: {
        fetching: false,
        fetched: false
    },
    authorizationReport: {
        fetching: false,
        fetched: false
    },
    authorizationList: {
        fetching: false,
        fetched: false
    },
    installmentsList: {
        fetching: false,
        fetched: false
    },
    tree: {
        fetching: false,
        fetched: false
    },
    bankTotalsTable: {
        fetching: false,
        fetched: false
    },
    bankTotalsReport: {
        fetching: false,
        fetched: false
    },
    getTidsGroupedByMid: {
        fetching: false,
        fetched: false
    },
    ecomAvailability: {
        payload: null,
        fetching: false,
        fetched: false
    },
    refund: {
        payload: null,
        fetching: false,
        fetched: false
    }
}, action) {
    switch (action.type) {
        case "SET_CURRENT_COMPANY": {
            return {
                ...state,
                afm: action.afm,
                name: action.name,
                isCardlinkOne: action.isCardlinkOne
            }
        }
        case "SET_CURRENT_STORE": {
            return {
                ...state,
                midsCommaSeparated: action.midsCommaSeparated,
                midsSearchBoxFormat: action.midsSearchBoxFormat
            }
        }
        case "SET_ORDER_STATUS": {
            return {
                ...state,
                orderStatus: action.orderStatus
            }
        }
        case "GET_MERCHANT_TREE_PENDING": {
            return {
                ...state,
                merchantTree: {
                    fetching: true,
                    fetched: false
                }
            }
        }
        case "GET_MERCHANT_TREE_REJECTED": {
            return {
                ...state,
                merchantTree: {
                    fetching: false,
                    fetched: false
                }
            }
        }
        case "GET_MERCHANT_TREE_FULFILLED": {
            return {
                ...state,
                merchantTree: {
                    fetching: false,
                    fetched: true
                }
            }
        }
        case "GET_MERCHANT_TREE_SEARCH_PENDING": {
            return {
                ...state,
                merchantTreeSearch: {
                    fetching: true,
                    fetched: false
                }
            }
        }
        case "GET_MERCHANT_TREE_SEARCH_REJECTED": {
            return {
                ...state,
                merchantTreeSearch: {
                    fetching: false,
                    fetched: false
                }
            }
        }
        case "GET_MERCHANT_TREE_SEARCH_FULFILLED": {
            return {
                ...state,
                merchantTreeSearch: {
                    fetching: false,
                    fetched: true
                }
            }
        }

        case "GET_MERCHANT_ORDER_STATUS_PENDING": {
            return {
                ...state,
                merchantStatus: {
                    fetching: true,
                    fetched: false,
                    orderStatuses: []
                }
            }
        }
        case "GET_MERCHANT_ORDER_STATUS_REJECTED": {
            return {
                ...state,
                merchantStatus: {
                    fetching: false,
                    fetched: false,
                    orderStatuses: []
                }
            }
        }
        case "GET_MERCHANT_ORDER_STATUS_FULFILLED": {
            return {
                ...state,
                merchantStatus: {
                    fetching: false,
                    fetched: true,
                    orderStatuses: action.payload.data.orderStatuses
                }
            }
        }
        case "GET_MIDS_AND_TIDS_BY_AFM_PENDING": {
            return {
                ...state,
                tree: {
                    fetching: true,
                    fetched: false
                }
            }
        }
        case "GET_MIDS_AND_TIDS_BY_AFM_REJECTED": {
            return {
                ...state,
                tree: {
                    fetching: false,
                    fetched: false
                }
            }
        }
        case "GET_MIDS_AND_TIDS_BY_AFM_FULFILLED": {
            return {
                ...state,
                tree: {
                    fetching: false,
                    fetched: true
                }
            }
        }
        case "DOWNLOAD_AUTHORIZATION_REPORT_PENDING": {
            return {
                ...state,
                authorizationReport: {
                    fetching: true,
                    fetched: false
                }
            }
        }
        case "DOWNLOAD_AUTHORIZATION_REPORT_REJECTED": {
            return {
                ...state,
                authorizationReport: {
                    fetching: false,
                    fetched: false
                }
            }
        }
        case "DOWNLOAD_AUTHORIZATION_REPORT_FULFILLED": {
            return {
                ...state,
                authorizationReport: {
                    fetching: false,
                    fetched: true
                }
            }
        }
        case "INIT_AUTHORIZATION_REPORT": {
            return {
                ...state,
                authorizationReport: {
                    fetching: false,
                    fetched: false
                }
            }
        }
        case "AUTHORIZATIONS_LIST_PENDING": {
            return {
                ...state,
                authorizationList: {
                    fetching: true,
                    fetched: false
                }
            }
        }
        case "AUTHORIZATIONS_LIST_REJECTED": {
            return {
                ...state,
                authorizationList: {
                    fetching: false,
                    fetched: false
                }
            }
        }
        case "AUTHORIZATIONS_LIST_FULFILLED": {
            return {
                ...state,
                authorizationList: {
                    fetching: false,
                    fetched: true
                }
            }
        }
        case "GET_BANK_TOTALS_LIST_PENDING": {
            return {
                ...state,
                bankTotalsTable: {
                    fetching: true,
                    fetched: false
                }
            }
        }
        case "GET_BANK_TOTALS_LIST_REJECTED": {
            return {
                ...state,
                bankTotalsTable: {
                    fetching: false,
                    fetched: false
                }
            }
        }
        case "GET_BANK_TOTALS_LIST_FULFILLED": {
            return {
                ...state,
                bankTotalsTable: {
                    fetching: false,
                    fetched: true
                }
            }
        }
        case "DOWNLOAD_BANK_TOTALS_REPORT_PENDING": {
            return {
                ...state,
                bankTotalsReport: {
                    fetching: true,
                    fetched: false
                }
            }
        }
        case "DOWNLOAD_BANK_TOTALS_REPORT_REJECTED": {
            return {
                ...state,
                bankTotalsReport: {
                    fetching: false,
                    fetched: false
                }
            }
        }
        case "DOWNLOAD_BANK_TOTALS_REPORT_FULFILLED": {
            return {
                ...state,
                bankTotalsReport: {
                    fetching: false,
                    fetched: true
                }
            }
        }
        case "GET_TIDS_GROUPED_BY_MID_PENDING": {
            return {
                ...state,
                getTidsGroupedByMid: {
                    fetching: true,
                    fetched: false
                }
            }
        }
        case "GET_TIDS_GROUPED_BY_MID_REJECTED": {
            return {
                ...state,
                getTidsGroupedByMid: {
                    fetching: false,
                    fetched: false
                }
            }
        }
        case "GET_TIDS_GROUPED_BY_MID_FULFILLED": {
            return {
                ...state,
                getTidsGroupedByMid: {
                    fetching: false,
                    fetched: true
                }
            }
        }
        case "GET_ECOM_AVAILABILITY_BY_AFM_PENDING": {
            return {
                ...state,
                ecomAvailability: {
                    payload: null,
                    fetching: true,
                    fetched: false
                }
            }
        }
        case "GET_ECOM_AVAILABILITY_BY_AFM_REJECTED": {
            return {
                ...state,
                ecomAvailability: {
                    payload: null,
                    fetching: false,
                    fetched: false
                }
            }
        }
        case "GET_ECOM_AVAILABILITY_BY_AFM_FULFILLED": {
            return {
                ...state,
                ecomAvailability: {
                    payload: action.payload.data,
                    fetching: false,
                    fetched: true
                }
            }
        }
        case "REFUND_PENDING": {
            return {
                ...state,
                refund: {
                    payload: null,
                    fetching: true,
                    fetched: false
                }
            }
        }
        case "REFUND_REJECTED": {
            return {
                ...state,
                refund: {
                    payload: null,
                    fetching: false,
                    fetched: false
                }
            }
        }
        case "REFUND_FULFILLED": {
            return {
                ...state,
                refund: {
                    payload: action.payload.data,
                    fetching: false,
                    fetched: true
                }
            }
        }
        case "CHECK_MERCHANT_STATUS_PENDING": {
            return {
                ...state,
                merchantStatus: {
                    fetching: true,
                    fetched: false
                }
            }
        }
        case "CHECK_MERCHANT_STATUS_REJECTED": {
            return {
                ...state,
                merchantStatus: {
                    fetching: false,
                    fetched: false
                }
            }
        }
        case "CHECK_MERCHANT_STATUS_FULFILLED": {
            return {
                ...state,
                merchantStatus: {
                    fetching: false,
                    fetched: true
                }
            }
        }

        case "INSTALLMENTS_LIST_PENDING": {
            return {
                ...state,
                installmentsList: {
                    fetching: true,
                    fetched: false
                }
            }
        }
        case "INSTALLMENTS_LIST_REJECTED": {
            return {
                ...state,
                installmentsList: {
                    fetching: false,
                    fetched: false
                }
            }
        }
        case "INSTALLMENTS_LIST_FULFILLED": {
            return {
                ...state,
                installmentsList: {
                    fetching: false,
                    fetched: true
                }
            }
        }
        default : {
            return state
        }
    }
}
