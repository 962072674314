import {makeRequest} from "../api-client";

const middlewareUrl = process.env.MIDDLEWARE_URL
const blobResponseType = 'blob'

export const getAvailableReports = (taxId, mid) => {
    let headers = {'x-afm': taxId};
    return makeRequest(middlewareUrl + "/reports/list", "GET", {
        mid: mid,
    }, headers, "GET_AVAILABLE_REPORTS")
};

export const getBranchReports = (dateFrom, afm, mid, reportId, locale) => {
    let headers = {'x-afm': afm};
    return makeRequest(middlewareUrl + "/reports/branch", "GET", {
        date: dateFrom,
        mid: mid,
        reportId: reportId,
        locale: locale
    }, headers, "GET_BRANCH_REPORTS", blobResponseType)
};

export const getCompanyReportsCount = (dateFrom, dateTo, reportId, taxId, mid) => {
    let headers = {'x-afm': taxId};
    return makeRequest(middlewareUrl + "/reports/company/count", "GET", {
        date: dateFrom,
        dateEnd: dateTo,
        reportId: reportId,
        mid: mid
    }, headers, "GET_COMPANY_REPORTS_COUNT")
};

export const getCompanyReports = (dateFrom, dateTo, reportId, afm, mid, email, currentUrl, locale, excelReport) => {
    let headers = {'x-afm': afm};
    return makeRequest(middlewareUrl + "/reports/company", "GET", {
        date: dateFrom,
        dateEnd: dateTo,
        reportId: reportId,
        mid: mid,
        email: email,
        currentUrl: currentUrl,
        locale: locale,
        excelReport: excelReport
    }, headers, "GET_COMPANY_REPORTS", blobResponseType)
};

export const getFusikoAerioReports = (dateFrom, afm, mid) => {
    let headers = {'x-afm': afm};
    return makeRequest(middlewareUrl + "/reports/fusiko/aerio", "GET", {
        date: dateFrom,
        mid: mid
    }, headers, "GET_FYSIKO_AERIO_REPORTS", blobResponseType)
};

export const downloadReportFile = (filename, email, originalFilename, isImpersonator, source) => {
    return makeRequest(middlewareUrl + "/reports/download/file", "GET", {
            filename: filename,
            email: email,
            originalFilename: originalFilename,
            isImpersonator: isImpersonator,
            source: source,
        }, {},
        "DOWNLOAD_REPORT_FILE", blobResponseType)
}
