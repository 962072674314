import React from 'react';
import { connect } from 'react-redux';
import { NavLink, withRouter } from 'react-router-dom';
import Logo from '../common/Logo';
import Button from 'react-bootstrap/es/Button';
import Loader from '../common/Loader';
import Wave from '../common/Wave';
import { passwordChange } from '../../providers/users';
import store from '../../store';
import LocaleSwitch from '../common/LocaleSwitch';

const Translate = require('react-redux-i18n').Translate;
const I18n = require('react-redux-i18n').I18n;



const stateMap = (store) => {
    return {
        i18n: store.i18n,
        userReducer: store.userReducer
    };
};

class ExpiredPassword extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            password: '',
            oldPassword: '',
            newPassword: '',
            confirmNewPassword: '',
            changePasswordErrorMessage: '',
            changePasswordError: '',
            changePhoneErrorMessage: '',
            changePasswordMessage: '',
            passwordChangeMessage: '',
            passwordChanged: '',
            passwordPattern: '(?=^.{12,255}$)((?=.*\\d)(?=.*[A-Z])(?=.*[a-z])(?=.*[^A-Za-z0-9]))^.*',
            passwordRequirements: I18n.t('pages.expiredPassword.passwordRequirements'),
            showOldPassword: false,
            showNewPassword: false,
            showConfirmNewPassword: false
        }

        this.toggleShowPassword = this.toggleShowPassword.bind(this)
        this.passwordChange = this.passwordChange.bind(this)
        this.resetPasswordChangeFields = this.resetPasswordChangeFields.bind(this)
        this.handleChange = this.handleChange.bind(this)
    }

    componentDidMount() {
        if (this.props.userReducer.userLoggedIn) {
            this.props.history.push("/overview")
        }
    }


    toggleShowPassword(target){
        this.setState(prevState => ({
            [target]: !prevState[target]
        }));
    }


    handleChange(event) {
        this.setState({
            [event.target.id]: event.target.value,
            changePasswordError: '',
            changePasswordMessage: ''
        })
    }


    passwordSameAsConfirm() {
        if (this.state.newPassword !== this.state.confirmNewPassword) {
            this.setState({
                changePasswordError: I18n.t('pages.expiredPassword.errors.passwordSameAsConfirm'),
            })
            return 1
        }
        return 0
    }

    resetPasswordChangeFields() {
        this.setState({
            oldPassword: '',
            newPassword: '',
            confirmNewPassword: ''
        })
    }

    passwordChange() {
        if (this.passwordSameAsConfirm() === 1) {
            return;
        }
        store.dispatch(passwordChange(this.state.oldPassword, this.state.newPassword)).then(
            () => {
                this.resetPasswordChangeFields();
                this.setState({
                    changePasswordMessage: 'pages.expiredPassword.success.passwordChangedSuccessfully',
                    passwordChanged: true,
                    changePasswordError: ''
                }, ()=> {
                    this.props.history.push("/login")
                });
            },
            (error) => {
                if (error.response && error.response.data && error.response.data.errors && error.response.data.errors.length > 0) {
                    if (error.response.data.errors[0] === 'OLD_PASSWORD_ERROR') {
                        this.setState({
                            changePasswordError: I18n.t('pages.expiredPassword.errors.oldPasswordError'),
                        })
                    } else if (error.response.data.errors[0] === 'COMMON_PASSWORD_ERROR') {
                        this.setState({
                            changePasswordError: I18n.t('pages.expiredPassword.errors.commonPasswordError')
                        })
                    } else if (error.response.data.errors[0] === 'MID_PASSWORD_ERROR') {
                        this.setState({
                            changePasswordError: I18n.t('pages.expiredPassword.errors.midPasswordError')
                        })
                    } else if (error.response.data.errors[0] === 'STRONG_PASSWORD_ERROR') {
                        this.setState({
                            changePasswordError: I18n.t('pages.expiredPassword.errors.strongPasswordError')
                        })
                    } else if (error.response.data.errors[0] === 'SAME_PASSWORD_ERROR') {
                        this.setState({
                            changePasswordError: I18n.t('pages.expiredPassword.errors.samePasswordError')
                        })
                    } else if (error.response.data.errors[0] === 'PASSWORD_HISTORY_ERROR') {
                        this.setState({
                            changePasswordError: I18n.t('pages.expiredPassword.errors.passwordHistoryError')
                        })
                    } else {
                        this.setState({
                            changePasswordMessage: 'pages.expiredPassword.errors.passwordNotChanged',
                            passwordChanged: false
                        })
                    }
                } else {
                    this.setState({
                        changePasswordMessage: 'pages.expiredPassword.errors.passwordNotChanged',
                        passwordChanged: false
                    })
                }
            }
        )
    }


    render() {
        let currentYear = new Date().getFullYear();
        let passwordChanged = (this.state.passwordChanged);
        return (
            !this.props.userReducer.userLoggedIn ?
                <React.Fragment>
                    <main className="login-area expired-pw-area">
                        <div className="login-area__top">
                            <LocaleSwitch type={"login-area"}></LocaleSwitch>
                        </div>
                        <div className="login-area__content">
                            <div className="expired-pw-area__text--container">
                                <h2>
                                    <Translate value="pages.expiredPassword.title" />
                                </h2>
                                <article>
                                    <Translate value="pages.expiredPassword.caption" />
                                </article>
                                <b>
                                    <Translate value="pages.expiredPassword.changePassword" />
                                </b>
                            </div>
                            <div className="login-area__login-with-forgot">
                                <div className="login-area__login-box">
                                    <Logo isMain={false} isHeader={false} imgClassName="login"/>
                                    <form onSubmit={(e) => {
                                        e.preventDefault();
                                        this.passwordChange();
                                    }}>
                                        <div className="user-profile__label__value_opened">
                                            <Translate value="pages.expiredPassword.old"/>
                                        </div>
                                        <div className="user-profile__password-container">
                                            <input id="oldPassword" name="oldPassword" required
                                                   type={this.state.showOldPassword ? "text" : "password"}
                                                   onChange={this.handleChange}
                                                   value={this.state.oldPassword}/>
                                            <Button onClick={()=> {this.toggleShowPassword("showOldPassword")}}
                                                    className={"user-profile__icon-password-eye" +
                                                    (this.state.showOldPassword ? " icon-show-password-eye" : " icon-hide-password-eye")}></Button>
                                        </div>
                                        <div className="user-profile__label__value_opened">
                                            <Translate value="pages.expiredPassword.new"/>
                                        </div>
                                        <div className="user-profile__password-container">
                                            <input id="newPassword" name="newPassword" required
                                                   type={this.state.showNewPassword ? "text" : "password"}
                                                   title={this.state.passwordRequirements}
                                                   onChange={this.handleChange}
                                                   value={this.state.newPassword}/>
                                            <Button onClick={()=> {this.toggleShowPassword("showNewPassword")}}
                                                    className={"user-profile__icon-password-eye" +
                                                    (this.state.showNewPassword ? " icon-show-password-eye" : " icon-hide-password-eye")}></Button>
                                        </div>
                                        <div className="user-profile__label__value_opened">
                                            <Translate value="pages.expiredPassword.confirm"/>
                                        </div>
                                        <div className="user-profile__password-container">
                                            <input id="confirmNewPassword" name="confirmNewPassword" required
                                                   type={this.state.showConfirmNewPassword ? "text" : "password"}
                                                   title={this.state.passwordRequirements}
                                                   onChange={this.handleChange}
                                                   value={this.state.confirmNewPassword}/>
                                            <Button  onClick={()=> {this.toggleShowPassword("showConfirmNewPassword")}}
                                                     className={"user-profile__icon-password-eye" +
                                                     (this.state.showConfirmNewPassword ? " icon-show-password-eye" : " icon-hide-password-eye")}></Button>
                                        </div>
                                        {this.state.changePasswordError ?
                                            <div className="user-profile__bottom-error">
                                                <span className="icon-icon-attention"/>
                                                {this.state.changePasswordError}
                                            </div> : null}
                                        {this.state.changePasswordMessage !== '' ?
                                        <React.Fragment>
                                                <div className={"feedback-msg " + (passwordChanged ? 'msg-success': 'msg-fail')}>
                                                    <Translate value={this.state.changePasswordMessage}/>
                                                        <span className={passwordChanged ? 'icon-success' : 'icon-failed'}></span>
                                                </div>
                                                <NavLink to={"/login"}>
                                                    <Translate value="pages.reset.returnToLogin"/>
                                                </NavLink>
                                        </React.Fragment>:null}
                                        <button type="submit" className={"user-profile__btn user-profile__"
                                        + (this.state.oldPassword === '' ||
                                        this.state.newPassword === '' || this.state.confirmNewPassword === ''
                                            ? 'btn_gray' : 'btn_orange')}
                                                disabled={this.state.oldPassword === '' ||
                                                this.state.newPassword === '' || this.state.confirmNewPassword === ''}>
                                            {this.props.userReducer.passwordChange.fetching ?
                                                <Loader bouncing={false} color={'white'} message=''
                                                        style={{marginTop: 0}} bounceClass='smaller-bounces'/> :
                                                    <Translate value="pages.expiredPassword.saveButton"/>}
                                        </button>
                                        <div className="login-area__forgot-password">
                                            <NavLink to={"/reset"}>
                                                <Translate value="pages.login.forgotPassword"/>
                                            </NavLink>
                                        </div>
                                    </form>
                                </div>
                            </div>
                            <div className="login-area__copyright">{'©Cardlink ' + currentYear}</div>
                        </div>
                        <Wave />
                    </main>
                </React.Fragment> : null
        )
    }
}

export default withRouter(connect(stateMap)(ExpiredPassword));
