import React from "react";

const Translate = require('react-redux-i18n').Translate;

export default class NoChartData extends React.Component {

    render() {
        return (
            <div className="empty-chart">
                <div className="empty-chart__icon icon-no-data"></div>
                {this.props.hasAfms ?
                    <Translate className="empty-chart__text" value="pages.reports.installments.noAfmChecked"/>
                    : <Translate className="empty-chart__text" value="pages.reports.installments.noPeriodData"/>}
            </div>
        )
    }
}