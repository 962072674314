import React from "react";
const Translate = require('react-redux-i18n').Translate;

export default class ResponseMessage extends React.Component {

    render() {
        return (
            <div className="support-email-response">
                {this.props.response === "sent" ?
                    <h4>
                        <span className="icon-success"></span>
                        <div className="support-email-response__msg">
                            <Translate value="modals.support.response.positive" />
                        </div>
                    </h4>:
                    <h4>
                        <span className="icon-failed"></span>
                        <div className="support-email-response__msg">
                            <Translate value="modals.support.response.negative" />
                        </div>
                    </h4>
                 }
            </div>
        )
    }
}