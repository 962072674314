import React from "react";

const Translate = require('react-redux-i18n').Translate;

export default function EmptyStateShape(props) {
    return (
        <div className="empty-msg__shape">
            <div className="empty-msg__wrapper">
                {props.title ?
                <div className="empty-msg__title">
                    <Translate value={props.title}/>
                </div>:null}
                {props.text ?
                    <div className="empty-msg__text">
                        <Translate value={props.text}/>
                    </div>:null}
            </div>
        </div>
        // <div className={props.shapeWrapper}>
        //     <div className={props.shape}>
        //         <div className={props.msgWrapper}>
        //             <div className={props.msgTitle}>
        //                 <Translate
        //                     value={props.message}/>
        //             </div>
        //         </div>
        //     </div>
        // </div>
    )
}