import React from "react";
import store from "../../store";
import {getMap} from "../../providers/rest";

const Translate = require('react-redux-i18n').Translate

export default class TerminalBankDetails extends React.Component {
    constructor(props) {
        super(props)
        this.state = {
            data: props.data,
            acquirersMap: null,
            isEcom: props.isEcom
        }
        this.getAcquirersMap = this.getAcquirersMap.bind(this)
        this.showAcquirers = this.showAcquirers.bind(this)
        this.checkLoyalty = this.checkLoyalty.bind(this)
        this.checkDcc = this.checkDcc.bind(this)
        this.checkBank = this.checkBank.bind(this)
    }

    componentWillMount() {
        this.getAcquirersMap('acquirers')
    }

    componentWillReceiveProps(nextProps) {
        if (this.props.data !== nextProps.data) {
            this.setState({
                data: nextProps.data
            })
        }
        if (this.props.isEcom !== nextProps.isEcom) {
            this.setState({
                isEcom: nextProps.isEcom
            })
        }
    }

    getAcquirersMap(mapName) {
        store.dispatch(getMap(mapName)).then((response) => {
                this.setState({
                    acquirersMap: this.props.locale === 'en' ? response.value.data.latin : response.value.data.greek
                })
            }, (error) => {
            }
        )
    }

    showAcquirers(map, data) {
        const nonEcomAcquirers = ['017', '011', '016'];
        return (map.map((acquirer) => (
            [
                (acquirer.value !== undefined && acquirer.value !== '021') && !(nonEcomAcquirers.includes(acquirer.value) && this.state.isEcom) ?
                    <div key={acquirer.value} className="tid-bank__details_acquirers_column">
                        <div className="business-row tid-bank__details_acquirer-label">{acquirer.label}</div>
                        <div className="tid-bank__details_acquirer-value">
                            {this.checkBank(data, acquirer.value)}
                        </div>
                        <div className="tid-bank__details_acquirer-value">{this.checkLoyalty(data, acquirer.value)}</div>
                        {!this.state.isEcom ?
                            <div className="tid-bank__details_acquirer-value">{this.checkDcc(data, acquirer.value)}</div> : null}
                    </div>
                    : null
            ]
        )))
    }

    checkBank(data, acquirerId) {
        const bankData = data.find((e) => e.acquirerId === acquirerId)
        return (bankData && !bankData.deleted ?
            <span className='icon-icon-check icon-green'></span> :
           <span className='icon-x-icon icon-red'></span>)
    }

    checkLoyalty(data, acquirerId) {
        const bankData = data.find((e) => e.acquirerId === acquirerId)
        if (acquirerId === '016')
            return '-'
        return (bankData && bankData.t_loyaltyFlag ? <span className='icon-icon-check icon-green'></span>:
            <span className='icon-x-icon icon-red'></span>)
    }

    checkDcc(data, acquirerId) {
        const bankData = data.find((e) => e.acquirerId === acquirerId)
        if (acquirerId === '016' || acquirerId === '017' || acquirerId === '011') {
            return '-';
        }
        return (bankData && bankData.dccFlag === true ? <span className='icon-icon-check icon-green'></span>:
            <span className='icon-x-icon icon-red'></span> )
    }

    render() {
        return (
            <div className="tid-bank__details_wrapper">
                {this.state.acquirersMap !== null ?
                    <div className="business-row">
                        <div className="business-column tid-bank__details_column">
                            <div className="tid-bank__details_row">
                                <Translate value="pages.business.tidDetails.collaboration"/>
                            </div>
                            <div className="tid-bank__details_row">
                                <Translate className="tid-bank__details_row" value="pages.business.tidDetails.conformityProgram"/>
                            </div>
                            {!this.state.isEcom ?
                                <div className="tid-bank__details_row">
                                    <Translate value="pages.business.tidDetails.dynamicConversion"/>
                                </div>: null}
                        </div>
                        <div className={"tid-bank__details_acquirers_row" + (this.state.isEcom ? '_ecom' : '')}>
                            {this.showAcquirers(this.state.acquirersMap, this.state.data)}
                        </div>
                    </div>
                    : null}
            </div>
        )
    }
}