import React from "react";
import {withRouter} from "react-router-dom";
import connect from "react-redux/es/connect/connect";
import store from "../../../store";
import {setRegistrationStep, setRegistrationStep3} from "../../../actions/registerAction";
import {merchantRegistrationValidate} from "../../../providers/rest";
import Loader from "../../common/Loader";
import {ShowHelp} from "../../common/ShowHelp";
import Logo from "../../common/Logo";

const stateMap = (store) => {
    return {
        i18n: store.i18n,
        registrationReducer: store.registrationReducer
    };
};

const Translate = require('react-redux-i18n').Translate;
const I18n = require('react-redux-i18n').I18n

class MerchantRegistrationStep3 extends React.Component {
    constructor(props) {
        super(props)
        this.state = {
            numberOfUsers: 2,
            errorMessage: '',
            emailPlaceholder: I18n.t('pages.register.emailPlaceholder'),
            lastNamePlaceholder: I18n.t('pages.register.lastNamePlaceholder'),
            firstNamePlaceholder: I18n.t('pages.register.firstNamePlaceholder')
        }
        this.handleChange = this.handleChange.bind(this)
        this.renderUser = this.renderUser.bind(this)
        this.loopMoreUsers = this.loopMoreUsers.bind(this)
        this.validate = this.validate.bind(this)
        this.validatedNonNullUsers = this.validatedNonNullUsers.bind(this)
    }

    handleChange(event) {
        this.props.handleChange(event)
    }

    renderUser(userNumber) {
        const isDisabled = userNumber === 1
        return (
            <div className="registration-disabled" key={'users' + userNumber}>
                <Translate className="register__added-user" value={"pages.register.user" + userNumber}/>
                <br/>
                <input type="text"
                       disabled={isDisabled}
                       onChange={this.handleChange}
                       placeholder={this.state.lastNamePlaceholder} size="40"
                       id={"lastName" + userNumber} name={"lastName" + userNumber}
                       value={this.props['lastName' + userNumber]} required={this.props.isRequired}/>
                <input type="text"
                       disabled={isDisabled}
                       onChange={this.handleChange}
                       placeholder={this.state.firstNamePlaceholder} size="40"
                       id={"firstName" + userNumber} name={"firstName" + userNumber}
                       value={this.props['firstName' + userNumber]} required={this.props.isRequired}/>
                <input type="email"
                       disabled={isDisabled}
                       onChange={this.handleChange}
                       placeholder={this.state.emailPlaceholder} size="40"
                       id={"email" + userNumber} name={"email" + userNumber}
                       value={this.props['email' + userNumber]} required={this.props.isRequired}/>
            </div>
        )
    }

    loopMoreUsers() {
        let users = []
        for (let i = 3; i <= this.state.numberOfUsers; i++) {
            users.push(this.renderUser(i))
        }
        return users;
    }

    validate(data) {
        store.dispatch(merchantRegistrationValidate(data)).then((response) => {
                data.step++
                store.dispatch(setRegistrationStep3(data))
            }, () => {
                this.setState({
                    errorMessage: I18n.t('pages.register.inputError')
                })
            }
        )
    }

    validatedNonNullUsers(data) {
        for (let i = 2; i < 6; i++) {
            if ((this.props['lastName' + i] !== null && this.props['firstName' + i] !== null
                && this.props['email' + i] !== null) && (this.props['lastName' + i] !== undefined
                && this.props['firstName' + i] !== undefined && this.props['email' + i] !== undefined)) {
                data['lastName' + i] = this.props['lastName' + i]
                data['firstName' + i] = this.props['firstName' + i]
                data['email' + i] = this.props['email' + i]
            } else {
                break;
            }
        }
    }

    render() {
        const validationInProgress = this.props.registrationReducer &&
            this.props.registrationReducer.validateMerchantRegistration &&
            this.props.registrationReducer.validateMerchantRegistration.fetching
        return (
            <div className="register-merchant-step1__content">
                <div className="register-merchant-step1__left">
                    <p className="register-merchant-step1__sub-header">
                        <Translate value="pages.register.registerMerchant"/>
                    </p>
                    <p className="register-merchant-step1__sub-header">
                        <b> <Translate value="pages.register.step3"/> </b>
                    </p>
                    <p className="register-merchant-step1__text register-merchant-step1__text_intro"><Translate
                        value="pages.register.subTitle3"/>
                    </p>
                </div>
                <div className="register-merchant-step1__login-with-forgot">
                    <div className="register-merchant-step1__login-box">
                        <Logo isMain={false} isHeader={false} imgClassName="login"/>
                        <form onSubmit={(e) => {
                            e.preventDefault();
                            let data = {
                                ...this.props.registrationReducer,
                                mid: this.props.registrationReducer.mid.filter(Boolean).join()
                            }
                            this.validatedNonNullUsers(data);
                            this.validate(data)
                        }}>
                            <h2 className="register-merchant-title">
                                <Translate value="pages.register.title3"/>
                            </h2>

                            {this.renderUser(1)}
                            {this.renderUser(2)}
                            {this.state.numberOfUsers > 2 ? this.loopMoreUsers()
                                : null}
                            {this.state.errorMessage !== '' ?
                                <div className="login-area__login-box__wrong-creds">
                                    <div className="generic-error-msg">
                                        <span className="icon-icon-attention"/>
                                        <div>{this.state.errorMessage}</div>
                                    </div>
                                </div> : null
                            }
                            <div className="register__add-remove-element__container">
                                {this.state.numberOfUsers < 5 ?
                                    <button className="register__add-remove-element register__add-remove-element__add"
                                            onClick={(e) => {
                                                e.preventDefault()
                                                this.setState({
                                                    numberOfUsers: this.state.numberOfUsers + 1
                                                })
                                            }}>
                                        <span className="icon-circled-plus"></span>
                                        <Translate value="pages.register.addUser"/>
                                    </button>
                                    : null}
                                {this.state.numberOfUsers > 2 ?
                                    <button className="register__add-remove-element register__add-remove-element__remove"
                                            onClick={(e) => {
                                                e.preventDefault()
                                                this.props.removeUser(this.state.numberOfUsers)
                                                this.setState({
                                                    numberOfUsers: this.state.numberOfUsers - 1
                                                })
                                            }}>
                                        <Translate value="pages.register.removeUser"/>
                                        <span className="icon-circled-minus"></span>
                                    </button>
                                    : null}
                            </div>
                            <div className="register-merchant-step1__login-button-area__wrapper">
                                <button className="register-merchant-step1__login-button register__button" onClick={(e) => {
                                    store.dispatch(setRegistrationStep({
                                        step: this.props.step - 1
                                    }))
                                }}>
                                    <Translate value="pages.register.backButton"/>
                                </button>
                                <button type="submit" className="register-merchant-step1__login-button register__button"
                                        disabled={validationInProgress}>
                                    {validationInProgress ?
                                        <Loader bouncing={false} color={'white'} message=''
                                                style={{marginTop: 0}} bounceClass='smaller-bounces'/> :
                                        <Translate value="pages.register.submitButton"/>
                                    }
                                </button>
                            </div>
                            <ShowHelp/>
                        </form>
                    </div>
                </div>
            </div>
        )
    }
}

export default withRouter(connect(stateMap)(MerchantRegistrationStep3));