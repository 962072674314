import React from "react";
import {Button, OverlayTrigger, Popover} from "react-bootstrap";
import GenericModal from "../common/GenericModal";
import Loader from "../common/Loader";
import Tooltip from "../common/Tooltip";
import connect from "react-redux/es/connect/connect";
import HotSpot from "../common/HotSpot";
import EmptyStateShape from "../common/EmptyStateShape";
import store from "../../store";
import {setAuthorizationsReportStatus} from "../../actions/merchantAction";
import InstallmentPopoverSearch from "./InstallmentPopoverSearch";
import InstallmentsDataTable from "./InstallmentsDataTable";

const Translate = require('react-redux-i18n').Translate
const I18n = require('react-redux-i18n').I18n

const stateMap = (store) => {
    return {
        merchantReducer: store.merchantReducer,
        userReducer: store.userReducer
    };
};

class InstallmentPopoverAndTable extends React.Component {
    constructor(props) {
        super(props)
        this.state = {
            excelButtonTooltip: I18n.t("tooltips.hover.trxDownloadBTN"),
        }
    }

    componentDidMount() {
        store.dispatch(setAuthorizationsReportStatus())
    }

    componentWillReceiveProps(nextProps) {
        if (nextProps.i18n !== this.props.i18n) {
            this.setState({
                excelButtonTooltip: I18n.t("tooltips.hover.trxDownloadBTN")
            })
        }
    }


    render() {
        const popoverBottom = (
            <Popover id="installments-popover-positioned-bottom">
                {this.props.data.nonMapData !== null && this.props.data.allMaps !== null ?
                    <InstallmentPopoverSearch
                        data={this.props.data}
                        overlayRef={this.refs.overlayRef}
                        isToggleOn={this.props.isToggleOn}
                        getTransactions={this.props.getTransactions}
                        currentPage={this.props.data.currentPage}
                        rowsPerPage={this.props.data.rowsPerPage}
                        resetPopover={this.props.resetPopover}
                    /> : null}
            </Popover>
        );
        const disabledButton = (this.props.authorizationReport.fetching === true &&
            this.props.authorizationReport.fetched === false) ||
			this.props.data.total > 100000 ||
            this.props.data.total === 0 ||
            this.props.merchantReducer.installmentsList.fetching
        return (
            <div>
                <GenericModal
                    showModal={this.props.transactionReportError}
                    showCloseButton={true}
                    onHide={this.props.hideTransactionReportError}
                    modalTitle={<Translate value={"pages.transactions.excelReport.AnErrorOccurred"}/>}
                    modalBody={this.props.transactionReportError ?
                        <Translate value={this.props.transactionReportError}/> : ''}
                    bsSize="small"/>
                <div className="hotSpot-element__wrapper hotSpot-element__wrapper--trans-settling-info">
                </div>
                <div className="transactions-popover-wrapper">
                    {this.props.userReducer.showHotSpots ?

                            <HotSpot
                                hotProp="transactionExcelButton"
                                placement="bottom"
                            />

                        : null}
                    <div className="transaction-excel-wrapper">
                        <div className="transactions-popover-wrapper__excel-button-tooltip-wrapper">
                            <Translate value={"pages.transactions.tooltips.excelButtonTooltip"}/>
                        </div>
                        <button type="button" className={"transactions-popover-wrapper__excel-button"}
                                title={this.state.excelButtonTooltip}
                                onClick={this.props.downloadTransactionsReport}
                                disabled={disabledButton}>
                            <i className={"icon-saita "}></i>
                        </button>
                        {disabledButton ?
                            <Tooltip placement="left"
                                     tooltipButtonId="download-excel-tltp"
                                     popoverId="download-trans-tltp"
                                     tooltipLabelClass="download-trans-label"
                                     tooltipText="downloadExcelTooltip"
                            /> : null}
                    </div>

                    <OverlayTrigger trigger="click"
                                    placement="bottom"
                                    overlay={popoverBottom}
                                    ref="overlayRef"
                                    container={this}>
                        <Button className={"transactions-popover-toggle " +
                        (this.props.isToggleOn ? "triggered " : "not-triggered") +
                        " move-to-right"}
                                onClick={this.props.togglePopover}>
                            <Translate value={"pages.transactions.searchModal.labels.opened"}/>
                            <Translate value={"pages.transactions.searchModal.labels.closed"}/>
                            <span className="icons">
                            <i className={"icon-ico-magnifier"}> </i>
                            <i className={"icon-x-icon"}> </i>
                        </span>
                        </Button>
                    </OverlayTrigger>
                </div>
                {this.props.tableData !== null && this.props.allMaps !== null &&
                this.props.merchantReducer && this.props.merchantReducer.installmentsList &&
                this.props.merchantReducer.installmentsList.fetched ?
                    <div className="transactions-table-wrapper">
                        {this.props.userReducer.showHotSpots ?
                            <HotSpot
                                hotProp="transactionDetailsInfo"
                                placement="top"
                            />
                            : null}
                        {this.props.data.total > 20 ?
                            <div className="transactions-table-wrapper__trx-tooltip-wrapper">
                                <Translate value={"pages.transactions.tooltips.trxItemDropdown"}/>
                            </div> : null}
                        <InstallmentsDataTable
                            data={this.props.data}
                            handleTblChange={this.props.adjustTable}
                            locale={this.props.locale}
                        />
                    </div> :
                    !this.props.merchantReducer.installmentsList.fetching ?
                        <div className="transactions-table__empty-state">
                            <EmptyStateShape title="pages.transactions.table.errorTableMessageTitle" />
                        </div> : null
                }
                {this.props.merchantReducer && this.props.merchantReducer.installmentsList &&
                this.props.merchantReducer.installmentsList.fetching ?
                    <Loader
                        bouncing={true}
                        color={'cerulean'}
                        loaderStyles={{
                            minHeight: "150px",
                            width: "calc(100% - 145px)",
                            margin: "35px auto"
                        }}
                        style={{top: "70px"}}
                        message="loader.generic"/>
                    : null}
            </div>
        )
    }
}

export default connect(stateMap)(InstallmentPopoverAndTable);
