export default function chartReducer(state = {
    dashboard: {
        fetching: false,
        fetched: false
    },
    totalTransactions: {
        fetching: false,
        fetched: false
    },
    bankTotals: {
        fetching: false,
        fetched: false
    },
    cardTotals: {
        fetching: false,
        fetched: false
    },
    settledCount: {
        fetching: false,
        fetched: false
    },
    approvedCount: {
        fetching: false,
        fetched: false
    },
    transactionTypes: {
        fetching: false,
        fetched: false
    },
    installments: {
        fetching: false,
        fetched: false
    },
    installmentsAverage: {
        fetching: false,
        fetched: false
    },
    installmentsPerPeriod: {
        fetching: false,
        fetched: false
    },
    redemptions: {
        fetching: false,
        fetched: false
    },
    posStatusCodes: {
        fetching: false,
        fetched: false
    }
}, action) {
    switch (action.type) {
        case "GET_CHARTS_COUNTS_AVERAGE_PENDING": {
            return {
                ...state,
                dashboard: {
                    fetching: true,
                    fetched: false
                }
            }
        }
        case "GET_CHARTS_COUNTS_AVERAGE_REJECTED": {
            return {
                ...state,
                dashboard: {
                    fetching: false,
                    fetched: false
                }
            }
        }
        case "GET_CHARTS_COUNTS_AVERAGE_FULFILLED": {
            return {
                ...state,
                dashboard: {
                    fetching: false,
                    fetched: true
                }
            }
        }
        case "GET_CHARTS_COUNTS_TOTALS_PENDING": {
            return {
                ...state,
                totalTransactions: {
                    fetching: true,
                    fetched: false
                }
            }
        }
        case "GET_CHARTS_COUNTS_TOTALS_REJECTED": {
            return {
                ...state,
                totalTransactions: {
                    fetching: false,
                    fetched: false
                }
            }
        }
        case "GET_CHARTS_COUNTS_TOTALS_FULFILLED": {
            return {
                ...state,
                totalTransactions: {
                    fetching: false,
                    fetched: true
                }
            }
        }
        case "GET_POS_STATUS_CODES_PENDING": {
            return {
                ...state,
                posStatusCodes: {
                    fetching: true,
                    fetched: false
                }
            }
        }
        case "GET_POS_STATUS_CODES_REJECTED": {
            return {
                ...state,
                posStatusCodes: {
                    fetching: false,
                    fetched: false
                }
            }
        }
        case "GET_POS_STATUS_CODES_FULFILLED": {
            return {
                ...state,
                posStatusCodes: {
                    fetching: false,
                    fetched: true
                }
            }
        }
        case "GET_CHARTS_BANK_TOTALS_PENDING": {
            return {
                ...state,
                bankTotals: {
                    fetching: true,
                    fetched: false
                }
            }
        }
        case "GET_CHARTS_BANK_TOTALS_REJECTED": {
            return {
                ...state,
                bankTotals: {
                    fetching: false,
                    fetched: false
                }
            }
        }
        case "GET_CHARTS_BANK_TOTALS_FULFILLED": {
            return {
                ...state,
                bankTotals: {
                    fetching: false,
                    fetched: true
                }
            }
        }
        case "GET_CHARTS_CARD_MODE_TOTALS_PENDING": {
            return {
                ...state,
                cardTotals: {
                    fetching: true,
                    fetched: false
                }
            }
        }
        case "GET_CHARTS_CARD_MODE_TOTALS_REJECTED": {
            return {
                ...state,
                cardTotals: {
                    fetching: false,
                    fetched: false
                }
            }
        }
        case "GET_CHARTS_CARD_MODE_TOTALS_FULFILLED": {
            return {
                ...state,
                cardTotals: {
                    fetching: false,
                    fetched: true
                }
            }
        }
        case "GET_CHARTS_CARD_SCHEME_TOTALS_PENDING": {
            return {
                ...state,
                settledCount: {
                    fetching: true,
                    fetched: false
                }
            }
        }
        case "GET_CHARTS_CARD_SCHEME_TOTALS_REJECTED": {
            return {
                ...state,
                settledCount: {
                    fetching: false,
                    fetched: false
                }
            }
        }
        case "GET_CHARTS_CARD_SCHEME_TOTALS_FULFILLED": {
            return {
                ...state,
                settledCount: {
                    fetching: false,
                    fetched: true
                }
            }
        }
        case "GET_CHARTS_APPROVED_COUNT_PENDING": {
            return {
                ...state,
                approvedCount: {
                    fetching: true,
                    fetched: false
                }
            }
        }
        case "GET_CHARTS_APPROVED_COUNT_REJECTED": {
            return {
                ...state,
                approvedCount: {
                    fetching: false,
                    fetched: false
                }
            }
        }
        case "GET_CHARTS_APPROVED_COUNT_FULFILLED": {
            return {
                ...state,
                approvedCount: {
                    fetching: false,
                    fetched: true
                }
            }
        }
        case "GET_CHARTS_TRANSACTION_TYPE_PENDING": {
            return {
                ...state,
                transactionTypes: {
                    fetching: true,
                    fetched: false
                }
            }
        }
        case "GET_CHARTS_TRANSACTION_TYPE_REJECTED": {
            return {
                ...state,
                transactionTypes: {
                    fetching: false,
                    fetched: false
                }
            }
        }
        case "GET_CHARTS_TRANSACTION_TYPE_FULFILLED": {
            return {
                ...state,
                transactionTypes: {
                    fetching: false,
                    fetched: true
                }
            }
        }
        case "GET_CHARTS_INSTALLMENTS_PENDING": {
            return {
                ...state,
                installments: {
                    fetching: true,
                    fetched: false
                }
            }
        }
        case "GET_CHARTS_INSTALLMENTS_REJECTED": {
            return {
                ...state,
                installments: {
                    fetching: false,
                    fetched: false
                }
            }
        }
        case "GET_CHARTS_INSTALLMENTS_FULFILLED": {
            return {
                ...state,
                installments: {
                    fetching: false,
                    fetched: true
                }
            }
        }
        case "GET_CHARTS_INSTALLMENTS_AVERAGE_PENDING": {
            return {
                ...state,
                installmentsAverage: {
                    fetching: true,
                    fetched: false
                }
            }
        }
        case "GET_CHARTS_INSTALLMENTS_AVERAGE_REJECTED": {
            return {
                ...state,
                installmentsAverage: {
                    fetching: false,
                    fetched: false
                }
            }
        }
        case "GET_CHARTS_INSTALLMENTS_AVERAGE_FULFILLED": {
            return {
                ...state,
                installmentsAverage: {
                    fetching: false,
                    fetched: true
                }
            }
        }
        case "GET_CHARTS_INSTALLMENTS_PER_PERIOD_PENDING": {
            return {
                ...state,
                installmentsPerPeriod: {
                    fetching: true,
                    fetched: false
                }
            }
        }
        case "GET_CHARTS_INSTALLMENTS_PER_PERIOD_REJECTED": {
            return {
                ...state,
                installmentsPerPeriod: {
                    fetching: false,
                    fetched: false
                }
            }
        }
        case "GET_CHARTS_INSTALLMENTS_PER_PERIOD_FULFILLED": {
            return {
                ...state,
                installmentsPerPeriod: {
                    fetching: false,
                    fetched: true
                }
            }
        }
        case "GET_CHARTS_REDEMPTION_PENDING": {
            return {
                ...state,
                redemptions: {
                    fetching: true,
                    fetched: false
                }
            }
        }
        case "GET_CHARTS_REDEMPTION_REJECTED": {
            return {
                ...state,
                redemptions: {
                    fetching: false,
                    fetched: false
                }
            }
        }
        case "GET_CHARTS_REDEMPTION_FULFILLED": {
            return {
                ...state,
                redemptions: {
                    fetching: false,
                    fetched: true
                }
            }
        }
        default : {
            return state
        }
    }
}