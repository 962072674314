// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
import ___CSS_LOADER_GET_URL_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/getUrl.js";
var ___CSS_LOADER_URL_IMPORT_0___ = new URL("../fonts/calibri/calibri.eot", import.meta.url);
var ___CSS_LOADER_URL_IMPORT_1___ = new URL("../fonts/calibri/calibri.woff", import.meta.url);
var ___CSS_LOADER_URL_IMPORT_2___ = new URL("../fonts/calibri/calibri.woff2", import.meta.url);
var ___CSS_LOADER_URL_IMPORT_3___ = new URL("../fonts/calibri/calibri.ttf", import.meta.url);
var ___CSS_LOADER_URL_IMPORT_4___ = new URL("../fonts/calibri/calibri.otf", import.meta.url);
var ___CSS_LOADER_URL_IMPORT_5___ = new URL("../fonts/calibri/calibri-bold.woff", import.meta.url);
var ___CSS_LOADER_URL_IMPORT_6___ = new URL("../fonts/calibri/calibri-bold.woff2", import.meta.url);
var ___CSS_LOADER_URL_IMPORT_7___ = new URL("../fonts/calibri/calibri-bold.ttf", import.meta.url);
var ___CSS_LOADER_URL_IMPORT_8___ = new URL("../fonts/calibri/calibri-bold.otf", import.meta.url);
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
var ___CSS_LOADER_URL_REPLACEMENT_0___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_0___);
var ___CSS_LOADER_URL_REPLACEMENT_1___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_1___);
var ___CSS_LOADER_URL_REPLACEMENT_2___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_2___);
var ___CSS_LOADER_URL_REPLACEMENT_3___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_3___);
var ___CSS_LOADER_URL_REPLACEMENT_4___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_4___);
var ___CSS_LOADER_URL_REPLACEMENT_5___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_5___);
var ___CSS_LOADER_URL_REPLACEMENT_6___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_6___);
var ___CSS_LOADER_URL_REPLACEMENT_7___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_7___);
var ___CSS_LOADER_URL_REPLACEMENT_8___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_8___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `@font-face {
    font-family: "Calibri";
    src: url(${___CSS_LOADER_URL_REPLACEMENT_0___});
    src:
            url(${___CSS_LOADER_URL_REPLACEMENT_1___}) format("woff"),
            url(${___CSS_LOADER_URL_REPLACEMENT_2___}) format("woff2"),
            url(${___CSS_LOADER_URL_REPLACEMENT_3___}) format("truetype"),
            url(${___CSS_LOADER_URL_REPLACEMENT_4___}) format("opentype");
}

@font-face {
    font-family: "Calibri";
    src:
            url(${___CSS_LOADER_URL_REPLACEMENT_5___}) format("woff"),
            url(${___CSS_LOADER_URL_REPLACEMENT_6___}) format("woff2"),
            url(${___CSS_LOADER_URL_REPLACEMENT_7___}) format("truetype"),
            url(${___CSS_LOADER_URL_REPLACEMENT_8___}) format("opentype");
    font-weight: bold;
}

`, "",{"version":3,"sources":["webpack://./src/assets/css/cookiebot.css"],"names":[],"mappings":"AAAA;IACI,sBAAsB;IACtB,4CAAwC;IACxC;;;;sEAI8D;AAClE;;AAEA;IACI,sBAAsB;IACtB;;;;sEAImE;IACnE,iBAAiB;AACrB","sourcesContent":["@font-face {\n    font-family: \"Calibri\";\n    src: url(\"../fonts/calibri/calibri.eot\");\n    src:\n            url(\"../fonts/calibri/calibri.woff\") format(\"woff\"),\n            url(\"../fonts/calibri/calibri.woff2\") format(\"woff2\"),\n            url(\"../fonts/calibri/calibri.ttf\") format(\"truetype\"),\n            url(\"../fonts/calibri/calibri.otf\") format(\"opentype\");\n}\n\n@font-face {\n    font-family: \"Calibri\";\n    src:\n            url(\"../fonts/calibri/calibri-bold.woff\") format(\"woff\"),\n            url(\"../fonts/calibri/calibri-bold.woff2\") format(\"woff2\"),\n            url(\"../fonts/calibri/calibri-bold.ttf\") format(\"truetype\"),\n            url(\"../fonts/calibri/calibri-bold.otf\") format(\"opentype\");\n    font-weight: bold;\n}\n\n"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
