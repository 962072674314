import React from 'react';
import DonutChart from "./donutChart/DonutChart";
import BootstrapTable from "react-bootstrap-table-next";
import NoChartData from "../common/NoChartData";
import {withRouter} from "react-router-dom";
import connect from "react-redux/es/connect/connect";
import NumberFormatter from "../common/NumberFormatter";

const I18n = require('react-redux-i18n').I18n

const stateMap = (store) => {
    return {
        chartReducer: store.chartReducer,
        userReducer: store.userReducer,
        i18n: store.i18n,
    };
};

const donutChart = {
    headerFormatter: (column, index, {sortElement, filterElement}) => {
        let str = column.text;
        return (
            <div className="donut-chart-tbl-hdr">
                <div className="donut-chart-tbl-hdr--item">{str}</div>
            </div>
        )
    }
};


class DebitNotesChart extends React.Component {
    constructor(props) {
        super(props)
        this.state = {
            cardSchemeTotalsData: null,
            locale: this.props.locale,
            data: props.data
        }
        this.reRenderChart = this.reRenderChart.bind(this)
        this.cardSchemeTotals = this.cardSchemeTotals.bind(this)
    }

    componentWillMount() {
        this.cardSchemeTotals(this.props.data)
    }

    componentDidUpdate(prevProps: Readonly<P>, prevState: Readonly<S>, snapshot: SS) {
        if (prevProps.i18n.locale !== this.props.i18n.locale) {
            this.reRenderChart()
        }

        if (prevProps.data !== this.props.data) {
            this.cardSchemeTotals(this.props.data)
        }

        if (prevProps.chartTitle !== this.props.chartTitle) {
            this.setState({
                chartTitle: this.props.chartTitle
            })
        }
    }


    reRenderChart() {
        this.cardSchemeTotals(this.state.data)
    }

    cardSchemeTotals(data) {
            let cardTotals = []
            data.forEach((card, index) => {
                let colors = ["bahama-blue", "sangria", "deep-sea", "carrot-orange", "malibu", "thunderbird", "jacksons-purple", "forest-green", "midnight-blue", "grenadier"];
                let color = colors[index % colors.length];
                cardTotals.push({
                    title: card.cardScheme,
                    value: card.percentage,
                    dotColor: color,
                    uniqueKey: index
                })
            })
            this.setState({
                cardSchemeTotalsData: cardTotals
            }, ()=> {
            })
    }

    percentageFormatter(cell, row, index, digits) {
        return (
            <NumberFormatter number={cell} digits={2} />
        )
    }

    render() {
        const cardTotalsData = this.state.cardSchemeTotalsData;
        const columns = [{
            dataField: 'title',
            text: " "
        }, {
            dataField: 'value',
            text: I18n.t('pages.business.debitNotes.overview.chart.tableTitle'),
            headerFormatter: donutChart.headerFormatter,
            formatter: this.percentageFormatter,
            formatExtraData: 1
        }];

        const rowClasses = (row, rowIndex) => {
            let classes = row.dotColor;
            return classes;
        };

        return (
            <React.Fragment>
                {cardTotalsData !== null && cardTotalsData.length > 0 ?
                    <div className="donut-chart--cards">
                        <DonutChart
                            type="card"
                            data={cardTotalsData}
                            keyField='uniqueKey'
                            isDebitNotes={true}
                            chartTitle={this.props.chartTitle}/>
                        <BootstrapTable classes="donut-chart-table"
                                        data={cardTotalsData}
                                        keyField='uniqueKey'
                                        columns={columns}
                                        rowClasses={rowClasses}/>
                    </div>
                    :
                    <NoChartData/>}
            </React.Fragment>

        )
    }
}

export default withRouter(connect(stateMap)(DebitNotesChart));