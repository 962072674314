import React from "react";
import {connect} from "react-redux";
import {withRouter} from "react-router-dom";
import LocaleSwitch from "../../common/LocaleSwitch";
import Logo from "../../common/Logo";
import store from "../../../store";
import {passwordResetInit} from "../../../providers/users";
import {emailPhoneUrlBase} from "../../../actions/userActions";
import {Modal} from "react-bootstrap";
import Wave from "../../common/Wave";
import Loader from "../../common/Loader";
import localforage from "localforage";
import {Helmet} from 'react-helmet';

const Translate = require('react-redux-i18n').Translate;
const I18n = require('react-redux-i18n').I18n

const stateMap = (store) => {
    return {
        i18n: store.i18n,
        userReducer: store.userReducer
    };
};

class ResetPasswordStep1 extends React.Component {
    constructor(props) {
        super(props)

        this.state = {
            emailPlaceholder: I18n.t('pages.reset.emailPlaceholder'),
            passwordPlaceholder: I18n.t('pages.reset.passwordPlaceholder'),
            phoneRequirements: I18n.t('pages.reset.passwordPlaceholder'),
            email: '',
            phone: '',
            errorMessage: '',
            successfulReset: false,
            buttonDisabled: true,
            showHelp: false
        }

        this.handleChange = this.handleChange.bind(this)
        this.reset = this.reset.bind(this)
        this.handleFormattedChange = this.handleFormattedChange.bind(this)
        this.handleCloseModal = this.handleCloseModal.bind(this)
        this.phoneNumberFormat = this.phoneNumberFormat.bind(this)
    }

    componentWillReceiveProps(nextProps) {
        if (nextProps.i18n !== this.props.i18n) {
            this.setState({
                emailPlaceholder: I18n.t('pages.reset.emailPlaceholder'),
                passwordPlaceholder: I18n.t('pages.reset.passwordPlaceholder'),
            })
        }
    }


    reset() {
        const currentUrl = window.location.href
        store.dispatch(passwordResetInit(this.state.email, currentUrl)).then(
            (response) => {
               if (response.value.data.payload &&  response.value.data.payload.phone) {
                   localforage.setItem('userResetPwPhone', response.value.data.payload.phone)
               }
                store.dispatch(emailPhoneUrlBase(this.state.email, currentUrl))
                this.setState({
                    errorMessage: '',
                    successfulReset: true
                })
            },
            (error) => {
                if (error.response.data.errors && (error.response.data.errors[0] === "EMAIL_NOT_FOUND" || error.response.data.errors[0] === "EMAIL_NOT_CONFIRMED")) {
                    this.setState({
                        errorMessage: 'pages.reset.errors.emailNotification'
                    })
                } else if (error.response.status === 503) {
                    this.setState({
                        errorMessage: 'pages.reset.errors.anErrorOccured'
                    })
                }
            })
    }

    handleFormattedChange(obj, ev) {
        if (obj.value.length === 10) {
            this.setState({
                buttonDisabled: false
            })
        } else {
            this.setState({
                buttonDisabled: true
            })
        }
        this.setState({
            [ev.target.id]: obj.value
        })
    }

    handleChange(event) {
        this.setState({
            [event.target.id]: event.target.value,
            buttonDisabled: false
        })
    }

    handleCloseModal() {
        this.setState({successfulReset: false})
    }

    phoneNumberFormat(val) {
        let formatIsProper = false
        if (val[0] === '6') {
            if (val[1]) {
                if (val[1] === '9' && val.length <= 10) {
                    formatIsProper = true
                } else if (val.length > 10) {
                    return this.state.phone
                }
            } else {
                formatIsProper = true
            }
        }
        if (formatIsProper) return val
        return ''
    }

    render() {
        const passwordResetInitInProgress = this.props.userReducer &&
            this.props.userReducer.passwordResetInit &&
            this.props.userReducer.passwordResetInit.fetching
        return (
            <div className="login-area__wrapper">
                <Helmet>
                    <meta name="referrer" content="no-referrer"/>
                </Helmet>
                <Modal show={this.state.successfulReset} onHide={this.handleCloseModal}
                       dialogClassName="reset-area-step1__custom-modal">
                    <Modal.Header closeButton>
                        <Modal.Title>
                            <Translate className="generic-modal-title" value="pages.reset.errors.successfulReset"/>
                        </Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        <div className="generic-success-modal-msg">
                            <span className="icon-success"></span>
                            <Translate className="text-success" value="pages.reset.errors.successfulReset2"/>
                        </div>
                    </Modal.Body>
                </Modal>
                <main className="reset-area-step1">
                    <div className="reset-area-step1__top">
                        <LocaleSwitch type={"login-area"}></LocaleSwitch>
                    </div>
                    <div className="reset-area-step1__content">
                        <div className="reset-area-step1__left">
                            <div>
                                <p className="reset-area-step1__sub-header">
                                    <Translate value="pages.reset.resetYourPass"/>
                                </p>
                                <p className="reset-area-step1__sub-header">
                                    <b> <Translate value="pages.reset.step1"/> </b>
                                </p>
                                <p className="reset-area-step1__text reset-area-step1__text_intro">
                                    <Translate value="pages.reset.fillForm"/>
                                </p>
                            </div>
                        </div>
                        <div className="reset-area-step1__login-with-forgot">
                            <div className="reset-area-step1__login-box">
                                <Logo isMain={false} isHeader={false} imgClassName="login"/>
                                <form onSubmit={(e) => {
                                    e.preventDefault();
                                    this.reset();
                                }}>
                                    <Translate value="pages.reset.email"/>
                                    <input type="email" required={true}
                                           onChange={this.handleChange}
                                           placeholder={this.state.emailPlaceholder} size="40"
                                           id="email" name="email" value={this.state.email}/>
                                    {/*<Translate value="pages.reset.mobilePhone"/>*/}
                                    {/*<NumberFormat*/}
                                    {/*    type="tel"*/}
                                    {/*    id="phone"*/}
                                    {/*    format={phoneNumberFormat}*/}
                                    {/*    className="simple-input"*/}
                                    {/*    value={this.state.phone}*/}
                                    {/*    placeholder="69"*/}
                                    {/*    decimalScale={0} required={true}*/}
                                    {/*    onValueChange={this.handleFormattedChange}/>*/}
                                    {this.state.errorMessage !== '' ?
                                        <div className="login-area__login-box__wrong-creds">
                                            <div className="generic-error-msg">
                                                <span className="icon-icon-attention"/>
                                                <div>
                                                    <Translate value={this.state.errorMessage} dangerousHTML/>
                                                </div>
                                            </div>
                                        </div> : null
                                    }
                                    <div className="reset-area-step1__login-button-area">
                                        <button type="submit" className="reset-area-step1__login-button"
                                                disabled={passwordResetInitInProgress || this.state.buttonDisabled}>
                                            {passwordResetInitInProgress ?
                                                <Loader bouncing={false} color={'white'} message=''
                                                        style={{marginTop: 0}} bounceClass='smaller-bounces'/> :
                                                <Translate value="pages.reset.buttonText"/>
                                            }

                                        </button>
                                    </div>
                                    <div className="reset-area-step1__forgot-password">
                                        <a href=""
                                           onClick={(e) => {
                                               e.preventDefault()
                                               this.setState({
                                                   showHelp: !this.state.showHelp
                                               })
                                           }} className="_active">
                                            <Translate value={"pages.reset.needHelp"}/>
                                        </a>
                                    </div>
                                    {this.state.showHelp ?
                                        <div className="reset-area-step1__forgot-password">
                                            <Translate value={"pages.reset.help"}/>
                                        </div>
                                        : null}
                                </form>
                            </div>
                        </div>
                    </div>
                    <Wave />
                </main>
            </div>
        )
    }
}

export default withRouter(connect(stateMap)(ResetPasswordStep1));