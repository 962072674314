import React from "react";
import el from "../translations/el";
import en from "../translations/en";
import {loadTranslations, setLocale, syncTranslationWithStore} from "react-redux-i18n";
import store from "../store";
import {getAvatar} from "../providers/users";
import {nullifyModalAction, setModalAction} from "../actions/SideModalActions";
import moment from "moment";
import Cookiebot from "./cookieService";

export const formatAmount = function (locale, amount, digits) {
    return amount.toLocaleString(locale, {minimumFractionDigits: digits, maximumFractionDigits: digits})

};

export function formatDecimal(num, digits) {
    return parseFloat(Math.round(num * 100) / 100).toFixed(digits);
}

export function millisToMinutesAndSeconds(millis) {
    const minutes = Math.floor(millis / 60000);
    let seconds = (millis % 60000)
    seconds = (seconds / 1000)
    seconds = Math.floor(seconds);
    return minutes + ":" + (seconds < 10 ? '0' : '') + seconds;
}

export const splitDecimal = function (num, char) {
    if (!num.toString().includes("-")) {
        return num.toString().split(char);
    } else {
        return ["0", "0"];
    }
};

export function dangerousHtmlDiv(content) {
    return <div dangerouslySetInnerHTML={{__html: content}}/>
}

export function phoneNumberFormat(val) {
    let formatIsProper = false
    if (val[0] === '6') {
        if (val[1]) {
            if (val[1] === '9' && val.length <= 10) {
                formatIsProper = true
            } else if (val.length > 10) {
                return val.substring(0, 10)
            }
        } else {
            formatIsProper = true
        }
    }
    if (formatIsProper) return val;
    return ''
}

export function switchLocale(locale) {
    const translationsObject = locale === 'en' ? {en: en} : {el: el}
    syncTranslationWithStore(store);
    store.dispatch(loadTranslations(translationsObject));
    store.dispatch(setLocale(locale))
}

export function loadUserAvatar() {
    store.dispatch(getAvatar()).then(
        () => {
        },
        (error) => {
        })
}


export function defineCardsColor(title) {
    switch (title) {
        case 'AMERICAN EXPRESS':
            return 'bahama-blue';
        case 'AMEX':
            return 'bahama-blue';
        case 'DINERS':
            return 'midnight-blue';
        case 'MAESTRO':
            return 'jacksons-purple';
        case 'MASTERCARD':
            return 'grenadier';
        case 'UNIONPAY':
            return 'deep-sea';
        case 'VISA':
            return 'carrot-orange';
        case 'ASFGONEAS':
            return 'sangria';
        default:
            return ''
    }
}

export function toggleSideModal(isModalOpen, typeOfModal) {
    store.dispatch(nullifyModalAction());
    store.dispatch(setModalAction(isModalOpen, typeOfModal))
}

export function toUppercaseNoAccents(str) {
    return str.toUpperCase().normalize('NFD').replace(/[\u0300-\u036f]/g, "");
}

export function checkDateValidity(dateToCheck) {
    return moment(dateToCheck).isValid()
}

export function checkDateOlderThanToday(dateToCheck) {
    return moment(dateToCheck).isSameOrBefore(moment().subtract(1, 'days'), 'day')
}

export function checkDateIsTillToday(dateToCheck) {
    return moment(dateToCheck).isSameOrBefore(moment().subtract(0, 'days'), 'day')
}

export function date1NotOlderForMoreThanXDays(date1, date2, days) {
    return moment(date2).subtract(days, 'days').isSameOrBefore(moment(date1))
}

export function date1OlderOrEqualToDate2(date1, date2) {
    return moment(date1).isSameOrBefore(moment(date2), 'day')
}

export function date1AfterOrEqualToDate2(date1, date2) {
    return moment(date1).isSameOrAfter(moment(date2), 'day')
}

export function pickedDateIs1DayAgo(dateFrom) {
    const yesterday = moment().subtract(1, 'days').startOf('day').format('YYYY-MM-DD')
    return moment(dateFrom).isSame(moment(yesterday), 'day')
}

export function date1OlderXAmountsOfDaysThanDate2(date1, date2, maxDaysOlder) {
    return moment(date1).isSameOrBefore(moment(date2).subtract(maxDaysOlder, 'days'), 'day')
}

export function arrangeCompanyName(comp, locale) {
    if (comp.titleGreek !== null) {
        return comp.titleGreek;
    } else {
        return comp.titleLatin;
    }
}

export function bytesToSize(bytes, dec) {
    if (bytes === 0 || bytes === (0).toString) {
        return "0 Bytes";
    }
    let c = 1024, d = dec || 2,
        e = ["Bytes", "KB", "MB", "GB", "TB", "PB", "EB", "ZB", "YB"],
        f = Math.floor(Math.log(bytes) / Math.log(c));
    return parseFloat((bytes / Math.pow(c, f)).toFixed(d)) + " " + e[f]
}

export function setChartDates(pickedDropdownEntry, dateToIsCurrentDay) {
    let dateFrom = null
    let previousDateFrom = null
    let previousDateTo = null
    let previousTimespanMessage = null
    let dateTo = setDateTo(dateToIsCurrentDay)
    if (pickedDropdownEntry.key === 'lastDay') {
        dateFrom = moment(dateTo).format('YYYY-MM-DD')
        previousDateFrom = moment(dateTo).subtract(1, 'day').format('YYYY-MM-DD')
        previousTimespanMessage = 'prePrevDay'
    } else if (pickedDropdownEntry.key === 'lastWeek') {
        dateFrom = moment(dateTo).subtract(6, 'day').format('YYYY-MM-DD')
        previousDateFrom = moment(dateTo).subtract(13, 'day').format('YYYY-MM-DD')
        previousTimespanMessage = 'prePrev7Days'
    } else if (pickedDropdownEntry.key === 'lastMonth') {
        dateFrom = moment(dateTo).subtract(30, 'day').format('YYYY-MM-DD')
        previousDateFrom = moment(dateTo).subtract(60, 'day').format('YYYY-MM-DD')
        previousTimespanMessage = 'prePrev30Days'
    } else if (pickedDropdownEntry.key === 'last3Months') {
        dateFrom = moment().subtract(90, 'day').format('YYYY-MM-DD')
        previousDateFrom = moment(dateTo).subtract(180, 'day').format('YYYY-MM-DD')
        previousTimespanMessage = 'prePrev90Days'
    } else if (pickedDropdownEntry.key === 'last6Months') {
        dateFrom = moment().subtract(180, 'day').format('YYYY-MM-DD')
        previousDateFrom = moment(dateTo).subtract(360, 'day').format('YYYY-MM-DD')
        previousTimespanMessage = 'prePrev180Days'
    } else if (pickedDropdownEntry.key === 'lastYear') {
        dateFrom = moment().subtract(365, 'day').format('YYYY-MM-DD')
        previousDateFrom = moment(dateTo).subtract(730, 'day').format('YYYY-MM-DD')
        previousTimespanMessage = 'prePrev365Days'
    }

    previousDateTo = moment(dateFrom).subtract(1, 'day').format('YYYY-MM-DD')
    return {
        dateFrom: dateFrom, dateTo: dateTo, previousDateFrom: previousDateFrom,
        previousDateTo: previousDateTo, previousTimespanMessage: previousTimespanMessage
    }
}

export function screenLimit() {
    return window.screen.availWidth > 991;
}

function setDateTo(dateToIsCurrentDay) {
    let dateTo
    if (dateToIsCurrentDay) {
        dateTo = moment().format('YYYY-MM-DD');
    } else {
        dateTo = moment().subtract(1, 'day').format('YYYY-MM-DD');
    }

    return dateTo
}

export function maskCard(cardNumber) {
    let result = '';
    if (cardNumber) {
        let maskA = cardNumber.substring(0, 6);
        let maskB = cardNumber.substr(cardNumber.length - 4);
        result = maskA + "***" + maskB;
    } else {
        result = "-";
    }

    return result;
}

export function getSignedAmount(amount, amountSign) {
    let amountSignMultiplier = amountSign === -1 ? -1 : 1;
    return (amount !== null && amount !== 0) ? amountSignMultiplier * amount : 0
}

export const loadPromoScript = () => {
    const existingScript = document.getElementById('appo-script');
    if (existingScript) {
        existingScript.remove();
    }
    const existingBanner = document.getElementsByClassName('appo-banner-6571');
    if (existingBanner.length > 0 && existingBanner[0]) {
        existingBanner[0].innerHTML = '';
    }
    document.appoInited = false;
    var appoInit=document.createElement('script');
    var appoS=document.getElementsByTagName('script')[0];
    appoInit.type='text/javascript';
    appoInit.src='https://www.appocalypsis.com/loader/init/laFFFP.js';
    appoInit.setAttribute("data-cookieconsent", "necessary");
    appoInit.async=true;
    appoInit.id = 'appo-script';
    appoS.parentNode.insertBefore(appoInit, appoS);
};

export function filterByKeyValue(array, value) {
    let res = array.find(function(element) {
        return element[value];
    });
    return res ? res[value] : undefined;
}

export function blurInput(elRef) {
    if (elRef && elRef.select) {
        elRef.select.blur()
    }
}

export function unEscapeHtml(unsafe) {
    return unsafe
        .replace(/&amp;/g, '&')
        .replace(/&lt;/g, '<')
        .replace(/&gt;/g, '>')
        .replace(/&quot;/g, '"')
        .replace("/&#039/g;", "'");
}

export function reInitCookieBanner() {
        let timeOutFunc = setTimeout(findElems, 300);
        function findElems() {
            let BodyLevelButtonAccept1= document.getElementById('CybotCookiebotDialogBodyLevelButtonAccept1');
            let BodyLevelButtonAccept = document.getElementById('CybotCookiebotDialogBodyLevelButtonAccept');
            let BodyButtonAccept = document.getElementById('CybotCookiebotDialogBodyButtonAccept');
            let BodyButtonDecline = document.getElementById('CybotCookiebotDialogBodyButtonDecline');
            let BodyButtonDetails = document.getElementById('CybotCookiebotDialogBodyButtonDetails');

            if (BodyLevelButtonAccept1 && BodyLevelButtonAccept && BodyButtonAccept && BodyButtonDecline && BodyButtonDetails ) {
                abortTimer();
            } else {
                timeOutFunc = setTimeout(findElems, 300);
            }

            if (BodyLevelButtonAccept1) {
                BodyLevelButtonAccept1.addEventListener("click", function () {
                    ShowMore();
                });

            }
            if (BodyLevelButtonAccept) {
                BodyLevelButtonAccept.addEventListener("click", function () {
                    Cookiebot.dialog.submitConsent();
                });
            }
            if (BodyButtonAccept) {
                BodyButtonAccept.addEventListener("click", function () {
                    AcceptAllCookies();
                });
            }
            if (BodyButtonDecline) {
                BodyButtonDecline.addEventListener("click", function () {
                    Cookiebot.dialog.submitDecline();
                });
            }
            if (BodyButtonDetails) {
                BodyButtonDetails.addEventListener("click", function () {
                    ShowMore();
                });
            }

        }

        function abortTimer() {
            clearTimeout(timeOutFunc);
        }

        function ShowMore(){
            var cookieMore = document.getElementById("CybotCookiebotDialogDetail");
            var cookieLevel = document.getElementById("CybotCookiebotDialogBodyLevelWrapper");
            var cookieAcceptBtn = document.getElementById("CybotCookiebotDialogBodyButtonAccept")
            var cookieDeclineBtn = document.getElementById("CybotCookiebotDialogBodyButtonDecline");
            var cookieMoreDisplay = cookieMore.style.display;
            if (cookieMoreDisplay === "block") {
                cookieMore.style.display = "none";
                cookieLevel.style.display = "none";
                cookieDeclineBtn.style.display = cookieAcceptBtn.style.display = "inline-block";
            }else{
                cookieMore.style.display = "block";
                cookieLevel.style.display = "block";
                cookieDeclineBtn.style.display = cookieAcceptBtn.style.display = "none";
            }
        }
        function AcceptAllCookies(){
            var cookiePref = document.getElementById("CybotCookiebotDialogBodyLevelButtonPreferences");
            var cookieStat = document.getElementById("CybotCookiebotDialogBodyLevelButtonStatistics");
            var cookieMark = document.getElementById("CybotCookiebotDialogBodyLevelButtonMarketing");
            cookiePref.checked = true;
            cookieStat.checked = true;
            cookieMark.checked = true;
            Cookiebot.dialog.submitConsent();
            Cookiebot.hide();
        }
}
export function getStatusMap(status) {

    // Σε μερική λειτουργία / on-hold
    //
    // Ενεργοποίηση αίτησης / completed
    //
    // Αίτηση καταχωρήθηκε / custom-status
    //
    // Υπέρβαση ορίου / cancelled
    //
    // Αίτηση υπό επεξεργασία / processing
    //
    // Ακύρωση αίτησης / failed
    //
    // Απόρριψη αίτησης / refunded

    // Σε μερική λειτουργία    Partially Activated
    // Ενεργοποίηση αίτησης    Activated
    // Αίτηση καταχωρήθηκε     None
    // Υπέρβαση ορίου          Cancelled
    // Αίτηση υπό επεξεργασία  Pending
    // Ακύρωση αίτησης         Order Cancelled
    // Απόρριψη αίτησης    	    Declined

    switch (status) {
        case "PAR":
            return "partiallyActivated";
        case "ACT":
            return "activated";
        case "NON":
            return "none";
        case "BLO":
            return "blocked";
        case "PEN":
            return "pending";
        case "CAN":
            return "cancelled";
        case "DEC":
            return "declined";
        default:
            return "none";
    }

    // let statuses = {
    //     onhold: "partiallyActivated",
    //     completed: "activated",
    //     customstatus: "none",
    //     cancelled: "blocked",
    //     processing: "pending",
    //     failed: "cancelled",
    //     refunded: "declined"
    // };
    // return statuses[prop];
}

export function calculateCountAmountDiff(amount, locale) {
    if (amount === '-') {
        return {diff: amount, outOfRangeSign: null}
    }
    let outOfRangePositive = amount && amount > 100.00;
    let outOfRangeNegative = amount && amount < -100.00;
    let diffAmount = !amount ? formatAmount(locale, 0, 2) :
        outOfRangePositive ? formatAmount(locale, 100, 2) :
        outOfRangeNegative ? formatAmount(locale, -100, 2) :
        formatAmount(locale, amount, 2);
    return {diff: diffAmount, outOfRangeSign: outOfRangePositive ? '>' : outOfRangeNegative ? '<' : null}
}

export function toCamelCase(str) {
    return str.substring(0, 1).toUpperCase() + str.substring(1);
}

export function scrollMainToTop() {
    const MainEl = document.getElementsByTagName("main")[0];
    if (MainEl) {
        MainEl.scrollIntoView();
    }
}