export function setModalAction(isModalOpen, typeOfModal) {
    return {
        type: "SET_MODAL_ACTION",
        isModalOpen: isModalOpen,
        typeOfModal: typeOfModal
    }
}

export function nullifyModalAction() {
    return {
        type: "NULLIFY_MODAL_ACTION"
    }
}