import React from 'react';
import {Table} from "react-bootstrap";
import DebitNotesChart from "../../charts/DebitNotesChart";
import NumberFormatter from "../../common/NumberFormatter";

const Translate = require('react-redux-i18n').Translate;


export default class DebitNotesOverview extends React.Component {
    constructor(props) {
        super(props)
        this.state = {
            dateRangeLabel: props.dateRangeLabel,
            schemeData: props.data,
            feesSum: props.feesSum
        }


    }

    componentDidUpdate(prevProps: Readonly<P>, prevState: Readonly<S>, snapshot: SS) {
        if (prevProps.dateRangeLabel !== this.props.dateRangeLabel) {
            this.setState({
                dateRangeLabel: this.props.dateRangeLabel
            });
        }

        if (prevProps.data !== this.props.data) {
            this.setState({
                schemeData: this.props.data
            });
        }

        if (prevProps.feesSum !== this.props.feesSum) {
            this.setState({
                feesSum: this.props.feesSum
            });
        }
    }

    componentDidMount() {
        this.setState({
            schemeData: this.props.data,
            feesSum: this.props.feesSum,
            dateRangeLabel: this.props.dateRangeLabel
        })
    }




    render() {
        return (
            <div className="debit-notes__overview">
                {this.state.schemeData && this.state.schemeData.length > 0  ?
                    <React.Fragment>
                        <div className="debit-notes__overview--left">
                            <div className="debit-notes__title">
                                <Translate value="pages.business.debitNotes.overview.table.title" />
                            </div>
                            <div className="debit-notes__daterange">
                                {this.state.dateRangeLabel}
                            </div>
                            <Table striped borderless="true" className="debit-notes__overview--table">
                                <tbody>
                                {this.state.schemeData.map((card)=> {
                                    return <tr key={'debit-note-' + card.cardScheme.replace(" ", "_")}>
                                                <td className="td__overview td__overview--scheme">
                                                    {card.cardScheme}
                                                </td>
                                                <td className="td__overview td__overview--amount">
                                                    <NumberFormatter number={card.feesSum} digits={2} symbol={"€"} />
                                                </td>
                                            </tr>
                                })}
                                </tbody>
                                <tfoot className="tfoot__overview">
                                    <tr>
                                        <td className="tfoot__overview--scheme">
                                            <Translate value="pages.business.debitNotes.overview.table.total" />
                                        </td>
                                        <td className="tfoot__overview--amount">
                                        <span>
                                            {this.state.feesSum}
                                        </span>
                                        </td>
                                    </tr>
                                </tfoot>
                            </Table>
                        </div>
                        <div className="debit-notes__overview--right">
                            <div className="debit-notes__title debit-notes__title--chart">
                                <Translate value="pages.business.debitNotes.overview.chart.title" />
                            </div>
                            <div className="debit-notes__overview--chart">
                                {this.state.schemeData && this.state.feesSum ?
                                 <DebitNotesChart
                                     data={this.state.schemeData}
                                     chartTitle={this.state.feesSum}
                                 />:null}
                            </div>
                        </div>
                    </React.Fragment>
                :null}
            </div>
        )
    }
}