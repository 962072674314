import React from "react";
import store from "../../store";
import {updateMerchantAlias} from "../../providers/merchants";

const Translate = require('react-redux-i18n').Translate

export default class PopoverMerchantAlias extends React.Component {
    constructor(props) {
        super(props)
        this.state = {
            mid: props.mid,
            merchantAlias: props.merchantAlias,
        }
        this.updateMerchantAlias = this.updateMerchantAlias.bind(this)
    }
    updateMerchantAlias(mid, merchantAlias) {
        store.dispatch(updateMerchantAlias(mid, merchantAlias)).then((response) => {
                this.props.handleAliasChange(mid, merchantAlias, 'mid')
                this.props.setAliasesArr(this.props.afm, true)
            },
            (error) => {
            }
        )
    }

    render() {
        return (
            <div className="popover__business-modal">
                <form onSubmit={(e) => {
                    e.preventDefault();
                    this.updateMerchantAlias(this.state.mid, this.state.merchantAlias)
                    this.props.overlayAliasRef.hide();
                }}>
                    <div className="business-column">
                        <Translate value="pages.business.popover.merchantAlias" className="editable-popover-label"/>
                        <div className="business-row">
                            <input type="text" id="midAlias" name="midAlias"
                                   className="simple-input"
                                   onChange={(event) => {
                                       this.setState({
                                           merchantAlias: event.target.value
                                       })
                                   }}
                                   value={this.state.merchantAlias}
                                   placeholder={this.state.merchantAlias}
                                   title={this.state.merchantAlias}/>
                            <button className="icon-icon-check" type="submit"></button>
                            <button className="icon-x-icon" type="button" onClick={() => this.props.overlayAliasRef.hide()}></button>
                        </div>
                    </div>
                </form>
            </div>
        )
    }
}