import React from "react";
import TerminalDetails from "./TerminalDetails";
import {OverlayTrigger, Panel, Popover} from "react-bootstrap";
import PopoverMerchantAlias from "./PopoverMerchantAlias";
import PopoverPostCode from "./PopoverPostCode";
import store from "../../store";
import {getMap} from "../../providers/rest";
import {getBusinessExcel} from "../../providers/merchants";
import connect from "react-redux/es/connect/connect";
import Tooltip from "../common/Tooltip";
import {toggleInfoTooltips} from "../../actions/userActions";
import PaymentsAndFees from "./PaymentsAndFees";
import DebitNotes from "./DebitNotes/DebitNotes";

const Translate = require('react-redux-i18n').Translate;
const fileDownload = require('js-file-download')

const stateMap = (store) => {
    return {
        merchantReducer: store.merchantReducer,
        userReducer: store.userReducer
    };
};

class MidDetails extends React.Component {
    constructor(props) {
        super(props)
        this.businessModal = React.createRef();
        this.state = {
            data: props.data,
            acquirersMap: null,
            aliasFormShow: false,
            disableExcel: false,
            merchantAlias: props.data.merchantAlias,
            isEcom: props.isEcom !== undefined ? props.isEcom : false
        }
        this.getAcquirersMap = this.getAcquirersMap.bind(this)
        this.openAliasForm = this.openAliasForm.bind(this)
        this.handleAliasChange = this.handleAliasChange.bind(this)
        this.handlePostCodeChange = this.handlePostCodeChange.bind(this)
        this.midBanks = this.midBanks.bind(this)
        this.toggleMerchantAlias= this.toggleMerchantAlias.bind(this)
        this.togglePostCode= this.togglePostCode.bind(this)
        this.getMerchantExcel = this.getMerchantExcel.bind(this)
        this.tidsSearchBoxData = this.tidsSearchBoxData.bind(this);
    }

    componentWillMount() {
        this.getAcquirersMap('acquirers')
    }


    openAliasForm() {
        this.setState((prevState) => {
            return {
                aliasFormShow: !prevState.aliasFormShow
            }
        }, () => {
            if(this.state.aliasFormShow) {
                store.dispatch(toggleInfoTooltips("showInfo__business_mid_alias_tltp", false))
            }
        })
    }

    toggleMerchantAlias(e) {
        if (!e) {
            this.refs.overlayAliasRef.hide();
        }
    }

    togglePostCode(e) {
        if (!e) {
            this.refs.overlayPostCodeRef.hide()
        }
    }

    handlePostCodeChange(mid, postCode) {
        const data = this.state.data;
        data.portalPostCode = postCode;
        this.setState({
            data: data
        }, () => {
            this.props.handlePostCodeChange(postCode ? postCode : data.locationPostCode)
        })
    }


    handleAliasChange(mid, merchantAlias) {
        const data = this.state.data;
        data.merchantAlias = merchantAlias;
        this.setState({
            data: data
        }, () => {
            this.props.handleAliasChange(mid, merchantAlias, 'mid')
        })

    }

    getAcquirersMap(mapName) {
        store.dispatch(getMap(mapName)).then((response) => {
                this.setState({
                    acquirersMap: this.props.locale === 'en' ? response.value.data.latin : response.value.data.greek
                })
            }, (error) => {
            }
        )
    }

    tidsSearchBoxData(tidData) {
        return tidData.map((tid)=> {
           return {label: tid.tid + (tid.terminalAlias ? '-' + tid.terminalAlias : ''),
                        value: tid.tid,
                        id: "debit-note-" + tid.tid
            };
        })
    }

    midBanks(data) {
        let banks = []
        data.tidData.forEach(tid => {
            tid.tidBankData.forEach(bank => {
                if (bank.acquirerId !== null) {
                    banks.push(this.state.acquirersMap.find((e) => e.value === bank.acquirerId).label)
                }
            })
        })

        return (
            <Panel id="collapsible-panel-bank-collaborators">
                <Panel.Heading>
                    <Panel.Toggle componentClass="h4">
                        <Translate value="pages.business.midDetails.banks"/>
                        <i className="icon-plus-accordion"></i>
                        <i className="icon-minus-accordion"></i>
                    </Panel.Toggle>
                </Panel.Heading>
                <Panel.Collapse>
                    <Panel.Body>
                        <div className="bank-collaborators">
                            {[...new Set(banks)].map((value) => (
                                <div className="bank-collaborators__item" key={value}>{value}</div>
                            ))}
                        </div>
                    </Panel.Body>
                </Panel.Collapse>
            </Panel>
        )
    }

    getMerchantExcel(afm, mid, tid, onlyActive) {
        this.setState({
            disableExcel: true
        })
        store.dispatch(getBusinessExcel(-1, afm, mid, tid, onlyActive, this.props.locale)).then((response) => {
            fileDownload(response.value.data, response.value.headers.filename)
            this.setState({
                disableExcel: false
            })
        })
    }

    render() {
        const data = this.state.data !== null ? this.state.data : null;
        const postCode = data.portalPostCode ? data.portalPostCode : data.locationPostCode
        const isNotSingleAfmMid = this.props.userReducer.getAssociationsResponse.merchantType !== 'singleAfmMid';
        let popoverMerchantAliasProps = {
            afm: this.props.merchantReducer.afm,
            mid: this.state.data.mid,
            merchantAlias: this.state.data.merchantAlias,
            overlayAliasRef: this.refs.overlayAliasRef,
            handleAliasChange: this.handleAliasChange,
            isEcom: this.state.isEcom,
            setAliasesArr: this.props.setAliasesArr,
            updateTerminalAllias: this.props.updateTerminalAllias
        };
        const popoverMerchantAlias = (
            <Popover id={"business-merchantAlias-popover-positioned-bottom"}>
                <PopoverMerchantAlias
                    {...popoverMerchantAliasProps}
                />
            </Popover>)
        const popoverPostCode = (
            <Popover id={"business-postCode-popover-positioned-bottom"}>
                <PopoverPostCode
                    mid={this.state.data.mid}
                    postCode={postCode}
                    overlayPostCodeRef={this.refs.overlayPostCodeRef}
                    handlePostCodeChange={this.handlePostCodeChange}
                />
            </Popover>
        )
        return (
            <div id="business-modal__content" ref={this.businessModal}
                 className={isNotSingleAfmMid ? 'multiple-stats__content':''}
                 key={"business-modal-content-" + data.mid}>
                {isNotSingleAfmMid ?
                <button className={"__mid-excel-button " + (this.state.disableExcel === true ? "disabled" : "")}
                        onClick={() => this.getMerchantExcel(this.props.merchantReducer.afm, data.mid, null, false)}
                        disabled={this.state.disableExcel === true}>
                </button>:null}
                <Panel id="collapsible-panel-company-details" onToggle={this.toggleMerchantAlias} defaultExpanded>
                    <Panel.Heading>
                        <Panel.Toggle componentClass="h4">
                            {this.state.isEcom ?
                                <Translate value="pages.business.midDetails.ecomTitle"/>
                                :
                                <Translate value={"pages.business.midDetails" + (!this.props.isSingleAfmMid ? ".title" : ".singleTitle")}/>
                            }
                            <i className="icon-plus-accordion"></i>
                            <i className="icon-minus-accordion"></i>
                        </Panel.Toggle>
                    </Panel.Heading>
                    <Panel.Collapse>
                        <Panel.Body>
                            <div className="business-row business-row__details">
                                <div className="business-details__column">
                                    <Translate value="pages.business.midDetails.midTitle"/>
                                    <div className="business-details__value">{data.mid}</div>
                                </div>
                                <div className="editable-field__btn business-details__column">
                                <span>
                                    <Translate value="pages.business.midDetails.midAlias"/>
                                    <span className="alias-tltps-wrapper">
                                        <Tooltip placement="right"
                                                 tooltipButtonId="businessMidAlias"
                                                 tooltipLabelClass="business-mid-alias-label"
                                                 popoverId="business-mid-alias-tltp"
                                                 tooltipText="tooltipMid"
                                        />
                                    </span>
                                </span>
                                    <div className="editable-field__wrapper">
                                        <OverlayTrigger trigger="click"
                                                        placement="bottom"
                                                        overlay={popoverMerchantAlias}
                                                        ref="overlayAliasRef"
                                                        container={!this.props.isSingleAfmMid ? this: undefined}>
                                            <span>
                                                { data.merchantAlias ?
                                                <span className="editable-field__value">{data.merchantAlias}</span> :
                                                <Translate className="editable-field__no-value" value="pages.business.midDetails.provideAlias" />
                                                }
                                                <span className={"editable-field__icon icon-edit-pencil " + (data.merchantAlias ? 'icon-orange' : 'icon-gray')}></span>
                                            </span>
                                        </OverlayTrigger>
                                    </div>
                                </div>
                                <div className="business-details__column">
                                    <Translate value="pages.business.midDetails.midActivity"/>
                                    <div className="business-details__value">{data.mccDescription}</div>
                                </div>
                            </div>
                        </Panel.Body>
                    </Panel.Collapse>
                </Panel>
                {/*{this.state.acquirersMap !== null ? this.midBanks(data) : null}*/}
                <Panel id="collapsible-panel-contact-details" onToggle={!this.state.isEcom ? this.togglePostCode : null}>
                        <Panel.Heading>
                            <Panel.Toggle componentClass="h4">
                                <Translate value="pages.business.midDetails.contactDetails"/>
                                <i className="icon-plus-accordion"></i>
                                <i className="icon-minus-accordion"></i>
                            </Panel.Toggle>
                        </Panel.Heading>
                        <Panel.Collapse>
                            <Panel.Body>
                                {!this.state.isEcom ?
                                <div className="business-row business-row__details">
                                    <div className={"business-details__column business-details__column--address " +
                                    (this.props.isSingleAfmMid ? "single-stats" : '')}>
                                        <Translate value="pages.business.midDetails.address"/>
                                        <div className="business-details__value">
                                            <span>{data.locationAddress1}
                                                {data.locationAddress2 ? ' & ' : null}
                                            </span>
                                            <span>{data.locationAddress2}</span>
                                        </div>
                                    </div>
                                    <OverlayTrigger trigger="click"
                                                    placement="bottom"
                                                    overlay={popoverPostCode}
                                                    ref="overlayPostCodeRef"
                                                    container={!this.props.isSingleAfmMid ? this : undefined}>
                                        <div onClick={() => this.openAliasForm()}
                                            className="change-postal-code__btn business-details__column">
                                            <Translate value="pages.business.midDetails.postCode"/>
                                            <div className="change-postal-code__wrapper">
                                                {postCode ?
                                                    <span className="change-postal-code__value">{postCode}</span> :
                                                    <Translate className="change-postal-code__no-value" value="pages.business.midDetails.providePostalCode" />
                                                }
                                                <span className={"editable-field__icon icon-edit-pencil " + (postCode ? 'icon-orange' : 'icon-gray')}></span>
                                            </div>
                                        </div>
                                    </OverlayTrigger>
                                    <div className="business-details__column business-details__column--city">
                                        <Translate value="pages.business.midDetails.city"/>
                                        <div className="business-details__value">{data.locationCity ? data.locationCity : '-'}</div>
                                    </div>
                                    <div className="business-details__column business-details__column--area">
                                        <Translate value="pages.business.midDetails.area"/>
                                        <div className="business-details__value">{data.locationCountryArea ? data.locationCountryArea : '-'}</div>
                                    </div>
                                    <div className="business-details__column business-details__column--empty-helper">
                                    </div>
                                </div>: null}
                                <div className="business-row business-row__details">
                                    <div className="business-details__column business-details__column--sub">
                                        <Translate value="pages.business.midDetails.phone1"/>
                                        <div className="business-details__value">{data.phone1 ? data.phone1 : '-'}</div>
                                    </div>
                                    <div className="business-details__column business-details__column--sub">
                                        <Translate value="pages.business.midDetails.phone2"/>
                                        <div className="business-details__value">{data.phone2 ? data.phone2 : '-'}</div>
                                    </div>
                                    <div className="business-details__column business-details__column--sub">
                                        <Translate value="pages.business.midDetails.phone3"/>
                                        <div className="business-details__value">{data.phone3 ? data.phone3 : '-'}</div>
                                    </div>
                                    <div className="business-details__column business-details__column--sub">
                                        <Translate value="pages.business.midDetails.mobilePhone"/>
                                        <div className="business-details__value">{data.mobilePhone ? data.mobilePhone : '-'}</div>
                                    </div>
                                </div>
                            </Panel.Body>
                        </Panel.Collapse>
                    </Panel>
                    <TerminalDetails
                        key={"terminal-details-" + data.mid}
                        mid = {data.mid}
                        modalRef = {this.businessModal.current}
                        data = {data.tidData}
                        locale = {this.props.locale}
                        setTidForSupport = {this.props.setTidForSupport}
                        selectedTid={this.props.selectedTid}
                        singleAfmMid = {this.props.isSingleAfmMid}
                        updateTerminalAllias = {this.props.updateTerminalAllias}
                    />
                {this.props.data.midCardlinkOne ?
                    <React.Fragment>
                        <PaymentsAndFees  mid = {data.mid} iban={this.props.iban ? this.props.iban : "-"} />
                        <DebitNotes  mid = {data.mid}
                                     email={this.props.userReducer.profile.email ? this.props.userReducer.profile.email : null}
                                     afm={this.props.merchantReducer.afm ? this.props.merchantReducer.afm : null}
                                     // tids={data.tidData.length > 0 ? data.tidData.map(t => t.tid) : []}
                                     tids={this.tidsSearchBoxData(data.tidData)}
                        />
                    </React.Fragment>
                :null}
            </div>
        )
    }
}

export default connect(stateMap)(MidDetails);