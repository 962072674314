
export default function userReducer(state = {
    email: '',
    phone: '',
    urlBase: '',
    getAssociationsResponse: '',
    emailConfirmationCompleted: false,
    userLoggedIn: false,
    profile: null,
    csrf: null,
    createdOn: null,
    firstTimeOverlay: null,
    userAvatar: null,
    freshDeskUrl: null,
    groups: [],
    showHelp__bubble: false,
    searchTransactionsPopoverOpen: false,
    showHotSpots: false,
    showHelp__companiesDropdown: false,
    showHelp__storesMultiselect: false,
    showHelp__reportsTransactions: false,
    showHelp__quickSearch: false,
    showHelp__reportsBanks: false,
    showHelp__downloadReports: false,
    showHelp__cardsDates: false,
    showHelp__cardScheme: false,
    showHelp__transactionTypes: false,
    showHelp__exportChartHints: false,
    showHelp__downloadBusinessExcel: false,
    showHelp__eCommerceBusiness: false,
    showHelp__transactionExcelButton: false,
    showHelp__transactionDetailsInfo: false,
    showHelp__transactionSettlingInfo: false,
    showHelp__settledTransactions: false,
    showHelp__pendingTransactions: false,
    showHelp__isSettledTransactionHeader: false,
    showHelp__threeDSecureHeader: false,
    showHelp__responseCodeHeader: false,
    showInfo__company_tk_tltp: false,
    showInfo__mid_tltp: false,
    showInfo__quickfind_tltp: false,
    showInfo__refund_tltp: false,
    showInfo__multiple_mid_popover_tltp: false,
    showInfo__business_export_tltp: false,
    showInfo__business_export_tid_tltp: false,
    showInfo__report_download_multiple_mid_tltp: false,
    showInfo__reports_right_column_title_tltp: false,
    showInfo__business_mid_alias_tltp: false,
    showInfo__download_trans_tltp: false,
    showInfo__business_tid_alias_tltp: false,
    showInfo__reports_transactions_chart_tltp: false,
    showInfo__overview_kpi_title_tltp: false,
    showInfo__duplicate_transactions_tltp: false,
    hide__companiesDropdown: false,
    hide__reportsBanks: false,
    hide__transactionTypes: false,
    hide__exportChartHints: false,
    hide__transactionDetailsInfo: false,
    hide__isSettledTransactionHeader: false,
    impersonator: null,
    redirectToCardlink: false,
    userLeaveTimestamp: null,
    userDetails: {
        renderNewPasswordForm: true,
        renderNewPhoneForm: false,
        renderOtpForm: false,
        phone: ''
    },
    loginProcedure: {
        fetching: false,
        fetched: false
    },
    logoutProcedure: {
        fetching: false,
        fetched: false
    },
    setPassword: {
        fetching: false,
        fetched: false
    },
    setPhone: {
        fetching: false,
        fetched: false
    },
    validateOtpAndCompleteProfile: {
        fetching: false,
        fetched: false
    },
    resendCode: {
        fetching: false,
        fetched: false
    },
    passwordResetInit: {
        fetching: false,
        fetched: false
    },
    passwordReset: {
        fetching: false,
        fetched: false
    },
    passwordChange: {
        fetched: false,
        fetching: false
    },
    emailChange: {
        fetching: false,
        fetched: true
    }
}, action) {
    switch (action.type) {
        case "SET_EMAIL_URL_BASE": {
            return {
                ...state,
                email: action.email,
                urlBase: action.urlBase
            }
        }
        case "GET_ASSOCIATIONS_FULFILLED": {
            return {
                ...state,
                getAssociationsResponse: action.payload.data
            }
        }
        case "GET_PROFILE_FULFILLED": {
            return {
                ...state,
                profile: action.payload.data.payload
            }
        }
        case "GET_AVATAR_FULFILLED": {
            return {
                ...state,
                userAvatar: action.payload.data.payload
            }
        }
        case "CHANGE_FIRST_TIME_OVERLAY": {
            return {
                ...state,
                firstTimeOverlay: action.firstTimeOverlay
            }
        }
        case "SET_USER_LOGGED_IN_STATUS": {
            return {
                ...state,
                userLoggedIn: action.loggedIn
            }
        }
        case "LOGIN_PENDING": {
            return {
                ...state,
                loginProcedure: {
                    fetching: true,
                    fetched: false
                }
            }
        }
        case "LOGIN_REJECTED": {
            return {
                ...state,
                loginProcedure: {
                    fetching: false,
                    fetched: false
                }
            }
        }
        case "LOGIN_FULFILLED": {
            return {
                ...state,
                loginProcedure: {
                    fetching: false,
                    fetched: true
                },
                csrf: action.payload.data.payload.csrf,
                createdOn: action.payload.data.payload.createdOn
            }
        }
        case "LOGOUT_PENDING": {
            return {
                ...state,
                logoutProcedure: {
                    fetching: true,
                    fetched: false
                }
            }
        }
        case "LOGOUT_REJECTED": {
            return {
                ...state,
                logoutProcedure: {
                    fetching: false,
                    fetched: false
                }
            }
        }
        case "LOGOUT_FULFILLED": {
            return {
                ...state,
                logoutProcedure: {
                    fetching: false,
                    fetched: true
                }
            }
        }
        case "SET_USER_DETAILS": {
            return {
                ...state,
                userDetails: {
                    renderNewPasswordForm: action.userDetails.renderNewPasswordForm !== undefined ?
                        action.userDetails.renderNewPasswordForm : state.userDetails.renderNewPasswordForm,
                    renderNewPhoneForm: action.userDetails.renderNewPhoneForm !== undefined ?
                        action.userDetails.renderNewPhoneForm : state.userDetails.renderNewPhoneForm,
                    renderOtpForm: action.userDetails.renderOtpForm !== undefined ?
                        action.userDetails.renderOtpForm : state.userDetails.renderOtpForm,
                    phone: action.userDetails.phone !== undefined ?
                        action.userDetails.phone : state.userDetails.phone
                }
            }
        }
        case "SET_PASSWORD_PENDING": {
            return {
                ...state,
                setPassword: {
                    fetching: true,
                    fetched: false
                }
            }
        }
        case "SET_PASSWORD_REJECTED": {
            return {
                ...state,
                setPassword: {
                    fetching: false,
                    fetched: false
                }
            }
        }
        case "SET_PASSWORD_FULFILLED": {
            return {
                ...state,
                setPassword: {
                    fetching: false,
                    fetched: true
                }
            }
        }
        case "SET_PHONE_PENDING": {
            return {
                ...state,
                setPhone: {
                    fetching: true,
                    fetched: false
                }
            }
        }
        case "SET_PHONE_REJECTED": {
            return {
                ...state,
                setPhone: {
                    fetching: false,
                    fetched: false
                }
            }
        }
        case "SET_PHONE_FULFILLED": {
            return {
                ...state,
                setPhone: {
                    fetching: false,
                    fetched: true
                }
            }
        }
        case "VALIDATE_OTP_AND_COMPLETE_PROFILE_PENDING": {
            return {
                ...state,
                validateOtpAndCompleteProfile: {
                    fetching: true,
                    fetched: false
                }
            }
        }
        case "VALIDATE_OTP_AND_COMPLETE_PROFILE_REJECTED": {
            return {
                ...state,
                validateOtpAndCompleteProfile: {
                    fetching: false,
                    fetched: false
                }
            }
        }
        case "VALIDATE_OTP_AND_COMPLETE_PROFILE_FULFILLED": {
            return {
                ...state,
                validateOtpAndCompleteProfile: {
                    fetching: false,
                    fetched: true
                }
            }
        }
        case "RESEND_CODE_PENDING": {
            return {
                ...state,
                resendCode: {
                    fetching: true,
                    fetched: false
                }
            }
        }
        case "RESEND_CODE_REJECTED": {
            return {
                ...state,
                resendCode: {
                    fetching: false,
                    fetched: false
                }
            }
        }
        case "RESEND_CODE_FULFILLED": {
            return {
                ...state,
                resendCode: {
                    fetching: false,
                    fetched: true
                }
            }
        }
        case "PASSWORD_RESET_INIT_PENDING": {
            return {
                ...state,
                passwordResetInit: {
                    fetching: true,
                    fetched: false
                }
            }
        }
        case "PASSWORD_RESET_INIT_REJECTED": {
            return {
                ...state,
                passwordResetInit: {
                    fetching: false,
                    fetched: false
                }
            }
        }
        case "PASSWORD_RESET_INIT_FULFILLED": {
            return {
                ...state,
                passwordResetInit: {
                    fetching: false,
                    fetched: true
                }
            }
        }
        case "PASSWORD_RESET_PENDING": {
            return {
                ...state,
                passwordReset: {
                    fetching: true,
                    fetched: false
                }
            }
        }
        case "PASSWORD_RESET_REJECTED": {
            return {
                ...state,
                passwordReset: {
                    fetching: false,
                    fetched: false
                }
            }
        }
        case "PASSWORD_RESET_FULFILLED": {
            return {
                ...state,
                passwordReset: {
                    fetching: false,
                    fetched: true
                }
            }
        }
        case "GET_USER_GROUPS_PENDING": {
            return {
                ...state,
                groups: []
            }
        }
        case "GET_USER_GROUPS_REJECTED": {
            return {
                ...state,
                groups: []
            }
        }
        case "GET_USER_GROUPS_FULFILLED": {
            return {
                ...state,
                groups: action.payload.data.payload
            }
        }
        case "EMAIL_CHANGE_PENDING": {
            return {
                ...state,
                emailChange: {
                    fetching: true,
                    fetched: false
                }
            }
        }
        case "EMAIL_CHANGE_REJECTED": {
            return {
                ...state,
                emailChange: {
                    fetching: false,
                    fetched: false
                }
            }
        }
        case "EMAIL_CHANGE_FULFILLED": {
            return {
                ...state,
                emailChange: {
                    fetching: false,
                    fetched: true
                }
            }
        }
        case "PASSWORD_CHANGE_PENDING": {
            return {
                ...state,
                passwordChange: {
                    fetching: true,
                    fetched: false
                }
            }
        }
        case "PASSWORD_CHANGE_REJECTED": {
            return {
                ...state,
                passwordChange: {
                    fetching: false,
                    fetched: false
                }
            }
        }
        case "PASSWORD_CHANGE_FULFILLED": {
            return {
                ...state,
                passwordChange: {
                    fetching: false,
                    fetched: true
                }
            }
        }
        case "GET_IMPERSONATOR_FULFILLED": {
            return {
                ...state,
                impersonator: action.payload.data.payload
            }
        }
        case "SHOW_HOT_SPOTS": {
            return {
                ...state,
                showHotSpots: action.showHotSpots
            }
        }
        case "SHOWHELP__BUBBLE": {
            return {
                ...state,
                showHelp__bubble: action.showHelp__bubble
            }
        }
        case "SHOWHELP__COMPANIESDROPDOWN": {
            return {
                ...state,
                showHelp__companiesDropdown: action.showHelp__companiesDropdown
            }
        }
        case "SHOWHELP__STORESMULTISELECT": {
            return {
                ...state,
                showHelp__storesMultiselect: action.showHelp__storesMultiselect
            }
        }
        case "SHOWHELP__REPORTSTRANSACTIONS": {
            return {
                ...state,
                showHelp__reportsTransactions: action.showHelp__reportsTransactions
            }
        }
        case "SHOWHELP__QUICKSEARCH": {
            return {
                ...state,
                showHelp__quickSearch: action.showHelp__quickSearch
            }
        }
        case "SHOWHELP__REPORTSBANKS": {
            return {
                ...state,
                showHelp__reportsBanks: action.showHelp__reportsBanks
            }
        }
        case "SHOWHELP__DOWNLOADREPORTS": {
            return {
                ...state,
                showHelp__downloadReports: action.showHelp__downloadReports
            }
        }
        case "SHOWHELP__CARDSCHEME": {
            return {
                ...state,
                showHelp__cardScheme: action.showHelp__cardScheme
            }
        }
        case "SHOWHELP__CARDSDATES": {
            return {
                ...state,
                showHelp__cardsDates: action.showHelp__cardsDates
            }
        }
        case "SHOWHELP__TRANSACTIONTYPES": {
            return {
                ...state,
                showHelp__transactionTypes: action.showHelp__transactionTypes
            }
        }
        case "SHOWHELP__EXPORTCHARTHINTS": {
            return {
                ...state,
                showHelp__exportChartHints: action.showHelp__exportChartHints
            }
        }
        case "SHOWHELP__DOWNLOADBUSINESSEXCEL": {
            return {
                ...state,
                showHelp__downloadBusinessExcel: action.showHelp__downloadBusinessExcel
            }
        }
        case "SHOWHELP__ECOMMERCEBUSINESS": {
            return {
                ...state,
                showHelp__eCommerceBusiness: action.showHelp__eCommerceBusiness
            }
        }
        case "SHOWHELP__TRANSACTIONEXCELBUTTON": {
            return {
                ...state,
                showHelp__transactionExcelButton: action.showHelp__transactionExcelButton
            }
        }
        case "SHOWHELP__TRANSACTIONDETAILSINFO": {
            return {
                ...state,
                showHelp__transactionDetailsInfo: action.showHelp__transactionDetailsInfo
            }
        }
        case "SHOWHELP__TRANSACTIONSETTLINGINFO": {
            return {
                ...state,
                showHelp__transactionSettlingInfo: action.showHelp__transactionSettlingInfo
            }
        }
        case "SHOWHELP__SETTLEDTRANSACTIONS": {
            return {
                ...state,
                showHelp__settledTransactions: action.showHelp__settledTransactions
            }
        }
        case "SHOWHELP__PENDINGTRANSACTIONS": {
            return {
                ...state,
                showHelp__pendingTransactions: action.showHelp__pendingTransactions
            }
        }
        case "SHOWHELP__ISSETTLEDTRANSACTIONHEADER": {
            return {
                ...state,
                showHelp__isSettledTransactionHeader: action.showHelp__isSettledTransactionHeader
            }
        }
        case "SHOWHELP__THREEDSECUREHEADER": {
            return {
                ...state,
                showHelp__threeDSecureHeader: action.showHelp__threeDSecureHeader
            }
        }
        case "SHOWHELP__RESPONSECODEHEADER": {
            return {
                ...state,
                showHelp__responseCodeHeader: action.showHelp__responseCodeHeader
            }
        }
        case "HIDE__COMPANIESDROPDOWN": {
            return {
                ...state,
                hide__companiesDropdown: action.hide__companiesDropdown
            }
        }
        case "HIDE__REPORTSBANKS": {
            return {
                ...state,
                hide__reportsBanks: action.hide__reportsBanks
            }
        }
        case "HIDE__TRANSACTIONTYPES": {
            return {
                ...state,
                hide__transactionTypes: action.hide__transactionTypes
            }
        }

        case "HIDE__EXPORTCHARTHINTS": {
            return {
                ...state,
                hide__exportChartHints: action.hide__exportChartHints
            }
        }

        case "HIDE__TRANSACTIONDETAILSINFO": {
            return {
                ...state,
                hide__transactionDetailsInfo: action.hide__transactionDetailsInfo
            }
        }

        case "HIDE__ISSETTLEDTRANSACTIONHEADER": {
            return {
                ...state,
                hide__isSettledTransactionHeader: action.hide__isSettledTransactionHeader
            }
        }

        case "SEARCH_TRANSACTIONS_POPOVER_OPEN": {
            return {
                ...state,
                searchTransactionsPopoverOpen: action.searchTransactionsPopoverOpen
            }
        }
        case "SHOWINFO__COMPANY_TK_TLTP": {
            return {
                ...state,
                showInfo__company_tk_tltp: action.showInfo__company_tk_tltp
            }
        }
        case "SHOWINFO__MID_TLTP": {
            return {
                ...state,
                showInfo__mid_tltp: action.showInfo__mid_tltp
            }
        }
        case "SHOWINFO__QUICKFIND_TLTP": {
            return {
                ...state,
                showInfo__quickfind_tltp: action.showInfo__quickfind_tltp
            }
        }
        case "SHOWINFO__REFUND_TLTP": {
            return {
                ...state,
                showInfo__refund_tltp: action.showInfo__refund_tltp
            }
        }

        case "SHOWINFO__MULTIPLE_MID_POPOVER_TLTP": {
            return {
                ...state,
                showInfo__multiple_mid_popover_tltp: action.showInfo__multiple_mid_popover_tltp
            }
        }

        case "SHOWINFO__BUSINESS_EXPORT_TLTP": {
            return {
                ...state,
                showInfo__business_export_tltp: action.showInfo__business_export_tltp
            }
        }

        case "SHOWINFO__BUSINESS_EXPORT_TID_TLTP": {
            return {
                ...state,
                showInfo__business_export_tid_tltp: action.showInfo__business_export_tid_tltp
            }
        }
        case "SHOWINFO__REPORT_DOWNLOAD_MULTIPLE_MID_TLTP": {
            return {
                ...state,
                showInfo__report_download_multiple_mid_tltp: action.showInfo__report_download_multiple_mid_tltp
            }
        }
        case "SHOWINFO__REPORTS_RIGHT_COLUMN_TITLE_TLTP": {
            return {
                ...state,
                showInfo__reports_right_column_title_tltp: action.showInfo__reports_right_column_title_tltp
            }
        }
        case "SHOWINFO__BUSINESS_MID_ALIAS_TLTP": {
            return {
                ...state,
                showInfo__business_mid_alias_tltp: action.showInfo__business_mid_alias_tltp
            }
        }
        case "SHOWINFO__BUSINESS_TID_ALIAS_TLTP": {
            return {
                ...state,
                showInfo__business_tid_alias_tltp: action.showInfo__business_tid_alias_tltp
            }
        }
        case "SHOWINFO__DOWNLOAD_TRANS_TLTP": {
            return {
                ...state,
                showInfo__download_trans_tltp: action.showInfo__download_trans_tltp
            }
        }

        case "SHOWINFO__REPORTS_TRANSACTIONS_CHART_TLTP": {
            return {
                ...state,
                showInfo__reports_transactions_chart_tltp: action.showInfo__reports_transactions_chart_tltp
            }
        }

        case "SHOWINFO__OVERVIEW_KPI_TITLE_TLTP": {
            return {
                ...state,
                showInfo__overview_kpi_title_tltp: action.showInfo__overview_kpi_title_tltp
            }
        }

        case "SHOWINFO__DUPLICATE_TRANSACTIONS_TLTP": {
            return {
                ...state,
                showInfo__duplicate_transactions_tltp: action.showInfo__duplicate_transactions_tltp
            }
        }

        case "REDIRECT_TO_CARDLINK": {
            return {
                ...state,
                redirectToCardlink: action.redirectToCardlink
            }
        }

        case "START_IMPERSONATION_FULFILLED":
            return {
                ...state,
                csrf: action.payload.data.payload.csrf,
                createdOn: action.payload.data.payload.createdOn
            }
        case "SET_USER_LEAVE_TIMESTAMP": {
            return {
                ...state,
                userLeaveTimestamp: action.userLeaveTimestamp
            }
        }
        default : {
            return state
        }
    }
}