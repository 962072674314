import React from "react";
import {connect} from "react-redux";
import {withRouter} from "react-router-dom";
import LocaleSwitch from "../common/LocaleSwitch";
import MerchantRegistrationStep1 from "./registerMerchantSteps/MerchantRegistrationStep1";
import MerchantRegistrationStep2 from "./registerMerchantSteps/MerchantRegistrationStep2";
import store from "../../store";
import {setRegistrationData} from "../../actions/registerAction";
import MerchantRegistrationStep3 from "./registerMerchantSteps/MerchantRegistrationStep3";
import MerchantRegistrationStep4 from "./registerMerchantSteps/MerchantRegistrationStep4";
import MerchantRegistrationStep5 from "./registerMerchantSteps/MerchantRegistrationStep5";
import {scrollMainToTop} from "../../lib/utils";
import Wave from "../common/Wave";

const I18n = require('react-redux-i18n').I18n

const stateMap = (store) => {
    return {
        i18n: store.i18n,
        registrationReducer: store.registrationReducer,
        userReducer: store.userReducer
    };
};

class RegisterMerchant extends React.Component {
    constructor(props) {
        super(props)
        this.state = {
            legal: null,
            lastName: props.registrationReducer.lastName ? props.registrationReducer.lastName : null,
            firstName: props.registrationReducer.firstName ? props.registrationReducer.firstName : null,
            email: props.registrationReducer.email ? props.registrationReducer.email : null,
            lastName2: props.registrationReducer.lastName2 ? props.registrationReducer.lastName2 : null,
            firstName2: props.registrationReducer.firstName2 ? props.registrationReducer.firstName2 : null,
            email2: props.registrationReducer.email2 ? props.registrationReducer.email2 : null,
            lastName3: props.registrationReducer.lastName3 ? props.registrationReducer.lastName3 : null,
            firstName3: props.registrationReducer.firstName3 ? props.registrationReducer.firstName3 : null,
            email3: props.registrationReducer.email3 ? props.registrationReducer.email3 : null,
            lastName4: props.registrationReducer.lastName4 ? props.registrationReducer.lastName4 : null,
            firstName4: props.registrationReducer.firstName4 ? props.registrationReducer.firstName4 : null,
            email4: props.registrationReducer.email4 ? props.registrationReducer.email4 : null,
            lastName5: props.registrationReducer.lastName5 ? props.registrationReducer.lastName5 : null,
            firstName5: props.registrationReducer.firstName5 ? props.registrationReducer.firstName5 : null,
            email5: props.registrationReducer.email5 ? props.registrationReducer.email5 : null,
            isRequired: false,
            phone1: props.registrationReducer.phone1 ? props.registrationReducer.phone1 : null,
            corpType: props.corpType ? props.corpType: null,
            afm: props.registrationReducer.afm ? props.registrationReducer.afm : null,
            merchantNameLatin: props.registrationReducer.merchantNameLatin ? props.registrationReducer.merchantNameLatin : null,
            merchantTitleLatin: props.registrationReducer.merchantTitleLatin ? props.registrationReducer.merchantTitleLatin : null,
            logoImageBase64: props.registrationReducer.logoImageBase64 ? props.registrationReducer.logoImageBase64 : null,
            logoFileName: props.registrationReducer.logoFileName ? props.registrationReducer.logoFileName : null,
            superuserLastName: props.registrationReducer.superuserLastName ? props.registrationReducer.superuserLastName : null,
            superuserFirstName: props.registrationReducer.superuserFirstName ? props.registrationReducer.superuserFirstName : null,
            superuserEmail: props.registrationReducer.superuserEmail ? props.registrationReducer.superuserEmail : null,
            superuserPhone: props.registrationReducer.superuserPhone ? props.registrationReducer.superuserPhone : null,
            acceptCheckbox1: props.registrationReducer.acceptCheckbox1 ? props.registrationReducer.acceptCheckbox1 : false,
            acceptCheckbox2: props.registrationReducer.acceptCheckbox2 ? props.registrationReducer.acceptCheckbox2 : false,
            errorMessage: '',
            emailPlaceholder: I18n.t('pages.register.emailPlaceholder'),
            lastNamePlaceholder: I18n.t('pages.register.lastNamePlaceholder'),
            firstNamePlaceholder: I18n.t('pages.register.firstNamePlaceholder'),
            afmPlaceholder: I18n.t('pages.register.afmPlaceholder'),
            companyNamePlaceholder: I18n.t('pages.register.companyNamePlaceholder'),
            companyTitlePlaceholder: I18n.t('pages.register.companyTitlePlaceholder'),
            merchantIdPlaceholder: I18n.t('pages.register.merchantIdPlaceholder'),
            step: props.registrationReducer !== undefined && props.registrationReducer !== null ? props.registrationReducer.step : 1,
            mid: props.registrationReducer.mid ? props.registrationReducer.mid: [],
            errorMid: [],
            responsibleUser: props.responsibleUser ? props.responsibleUser : null,
        }
        this.handleFormattedChange = this.handleFormattedChange.bind(this)
        this.handleChange = this.handleChange.bind(this)
        this.removeUser = this.removeUser.bind(this)
        this.handleRadioChange = this.handleRadioChange.bind(this)
        this.renderRegistrationStep = this.renderRegistrationStep.bind(this)
        this.checkImageSize = this.checkImageSize.bind(this)
        this.onImageUpload = this.onImageUpload.bind(this)
        this.toggleRequired = this.toggleRequired.bind(this)
        this.removeMid = this.removeMid.bind(this)
        this.handleWrongMids = this.handleWrongMids.bind(this)
    }

    componentDidMount() {
        if (this.props.userReducer.userLoggedIn) {
            this.props.history.push("/overview")
        }
    }

    componentWillReceiveProps(nextProps) {
        if (nextProps.registrationReducer.step !== this.props.registrationReducer.step) {
            this.setState({
                step: nextProps.registrationReducer.step
            }, ()=> {
                scrollMainToTop();
            })
        }
    }

    componentWillUnmount() {
        store.dispatch(setRegistrationData({step: 1}))
    }

    checkImageSize(fileSize) {
        // 4 MB
        const maxFileSizeInBytes = 4194304;
        if (fileSize > maxFileSizeInBytes) {
            this.setState({
                ...this.state,
                imageError: I18n.t("pages.register.sealSizeSpecifications"),
                imageSuccess: '',
                logoImageBase64: null,
                logoFileName: null
            })
            return false
        } else {
            this.setState({
                ...this.state,
                imageError: null
            })
            return true
        }
    }

    onImageUpload(image) {
        let file = image.target.files[0]
        let reader = new FileReader();
        if (file && file.type.match('image.*')) {
            reader.readAsDataURL(file);
            reader.onloadend = function (e) {
                let imageSrc = reader.result
                let image = new Image()
                image.src = imageSrc
                image.onload = function () {
                    this.setState({
                        ...this.state,
                        logoImageBase64: reader.result,
                        logoFileName: file.name
                    })
                }.bind(this)
            }.bind(this)
        }
    }

    handleFormattedChange(obj, ev) {
        this.setState({
            [ev.target.id]: obj.value
        })
    }

    toggleRequired() {
        if (this.state.firstName2 || this.state.lastName2 || this.state.email2) {
            this.setState({
                isRequired: true
            })
        } else {
            this.setState({
                isRequired: false
            })
        }
    }

        handleChange(event, checkboxType) {
        if (checkboxType !== undefined) {
            this.setState({
                [checkboxType]: event.target.checked
            })
        } else if (event.target.id.startsWith("mid")) {
            let el = event.target.id;
            let idx = parseInt(el.replace("mid", "")) - 1
            let mid = [...this.state.mid];
            mid[idx] = event.target.value;
            this.setState({
                mid: mid,
                ['mid' +(idx + 1)]: mid[idx]
            })
        } else {
            this.setState({
                [event.target.id]: event.target.value ? event.target.value : undefined
            }, () => this.toggleRequired())
        }
    }

    handleWrongMids(errorMidArr) {
        this.setState({
            errorMid: errorMidArr
        })
    }

    handleRadioChange(event) {
        this.setState({
            [event.target.name]: event.target.value
        })
    }

    removeUser(userNumber) {
        this.setState({
            ['firstName' + userNumber]: null,
            ['lastName' + userNumber]: null,
            ['email' + userNumber]: null,
        })
    }

    removeMid(midNumber) {
        let mid = [...this.state.mid];
        mid.splice(midNumber - 1, 1);
        this.setState({
            mid: mid
        })
    }

    renderRegistrationStep(step) {

        switch (step) {
            case 1:
                return (
                    <MerchantRegistrationStep1
                        legal={this.state.legal}
                        lastName={this.state.lastName}
                        firstName={this.state.firstName}
                        email={this.state.email}
                        phone1={this.state.phone1}
                        step={this.state.step}
                        handleChange={this.handleChange}
                        handleFormattedChange={this.handleFormattedChange}
                    />);
            case 2:
                return (
                    <MerchantRegistrationStep2
                        corpType={this.state.corpType}
                        afm={this.state.afm}
                        merchantNameLatin={this.state.merchantNameLatin}
                        merchantTitleLatin={this.state.merchantTitleLatin}
                        mid={this.state.mid}
                        logoImageBase64={this.state.logoImageBase64}
                        logoFileName={this.state.logoFileName}
                        step={this.state.step}
                        handleChange={this.handleChange}
                        handleRadioChange={this.handleRadioChange}
                        onImageUpload={this.onImageUpload}
                        checkImageSize={this.checkImageSize}
                        imageError={this.state.imageError}
                        removeMid={this.removeMid}
                        handleWrongMids = {this.handleWrongMids}
                        errorMid = {this.state.errorMid}
                    />);
            case 3:
                return (
                    <MerchantRegistrationStep3
                        step={this.state.step}
                        lastName1={this.state.lastName}
                        firstName1={this.state.firstName}
                        email1={this.state.email}
                        lastName2={this.state.lastName2}
                        firstName2={this.state.firstName2}
                        email2={this.state.email2}
                        lastName3={this.state.lastName3}
                        firstName3={this.state.firstName3}
                        email3={this.state.email3}
                        lastName4={this.state.lastName4}
                        firstName4={this.state.firstName4}
                        email4={this.state.email4}
                        lastName5={this.state.lastName5}
                        firstName5={this.state.firstName5}
                        email5={this.state.email5}
                        isRequired={this.state.isRequired}
                        handleChange={this.handleChange}
                        removeUser={this.removeUser}
                    />
                )
            case 4:
                return <MerchantRegistrationStep4
                    step={this.state.step}
                    superuserLastName={this.state.superuserLastName}
                    superuserFirstName={this.state.superuserFirstName}
                    superuserEmail={this.state.superuserEmail}
                    superuserPhone={this.state.superuserPhone}
                    responsibleUser={this.state.responsibleUser}
                    lastName={this.state.lastName}
                    firstName={this.state.firstName}
                    email={this.state.email}
                    phone1={this.state.phone1}
                    handleChange={this.handleChange}
                    handleRadioChange={this.handleRadioChange}
                    handleFormattedChange={this.handleFormattedChange}
                />
            case 5:
                return <MerchantRegistrationStep5
                    showTermsAndConditions={this.props.showTermsAndConditions}
                    step={this.state.step}
                    acceptCheckbox1={this.state.acceptCheckbox1}
                    acceptCheckbox2={this.state.acceptCheckbox2}
                    handleChange={this.handleChange}
                />
            default:
                break;
        }
    }

    render() {
        return (
            !this.props.userReducer.userLoggedIn ?
                <div className="login-area__wrapper">
                    <main className={"register-merchant-step" + (this.state.step === 2 ? "2" : "1")}>
                        <div className={"register-merchant-step"  + (this.state.step === 2 ? "2" : "1") + "__top"}>
                            <LocaleSwitch type={"login-area"}></LocaleSwitch>
                        </div>
                        {this.renderRegistrationStep(this.state.step)}
                        <Wave className={this.state.step === 2 ? "wave-registration" : ""}/>
                    </main>
                </div> : null
        )
    }
}

export default withRouter(connect(stateMap)(RegisterMerchant));