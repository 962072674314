export function emailPhoneUrlBase(email, phone, urlBase) {
    return {
        type: "SET_EMAIL_URL_BASE",
        email: email,
        urlBase: urlBase
    }
}

export function setUserLoggedInStatus(loggedIn) {
    return {
        type: "SET_USER_LOGGED_IN_STATUS",
        loggedIn: loggedIn
    }
}

export function setUserDetails(userDetails) {
    return {
        type: "SET_USER_DETAILS",
        userDetails: userDetails
    }
}

export function changeFirstTimeOverlay(firstTimeOverlay) {
    return {
        type: "CHANGE_FIRST_TIME_OVERLAY",
        firstTimeOverlay: firstTimeOverlay
    }
}

export function switchHelpOverlay(show) {
    return {
        type: "SHOW_HOT_SPOTS",
        showHotSpots: show,
    }
}

export function toggleHelpPopovers(popover, show) {
    return {
        type: popover.toUpperCase(),
        [popover]: show
    }
}

export function toggleInfoTooltips(tltp, show) {
    return {
        type: tltp.toUpperCase(),
        [tltp]: show
    }
}

export function toggleLeftHotSpots(hotSpot, hide) {
    return {
        type: hotSpot.toUpperCase(),
        [hotSpot]: hide
    }
}

export function toggleSearchTransactionsPopover(show) {
    return {
        type: 'SEARCH_TRANSACTIONS_POPOVER_OPEN',
        searchTransactionsPopoverOpen: show
    }
}


export function toggleHelpBubble(show) {
    return {
        type: 'SHOWHELP__BUBBLE',
        showHelp__bubble: show
    }
}

export function redirectToCardlink(redirectToCardlink) {
    return {
        type: 'REDIRECT_TO_CARDLINK',
        redirectToCardlink: redirectToCardlink
    }
}

export function setUserLeaveTimestamp(timestamp) {
    return {
        type: "SET_USER_LEAVE_TIMESTAMP",
        userLeaveTimestamp: timestamp
    }
}
