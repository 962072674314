import React from 'react';

const Translate = require('react-redux-i18n').Translate;


export default class Loader extends React.Component {

    render() {
        return (
            <div className={"loader__wrapper" + (this.props.color ? " " + this.props.color : "") +
            (this.props.overlayClass ? " " + this.props.overlayClass : "") +
            (this.props.loaderClass ? " " + this.props.loaderClass : "")}
                 style={this.props.loaderStyles}>
                <div className={this.props.overlayClass ? "white-loader-bg" : ""}>
                    <h6><Translate value={this.props.message}/></h6>
                    {this.props.subMessage ?
                    <div className="loader__sub-msg">
                        <Translate value={this.props.subMessage}/>
                    </div>:null}
                    {this.props.bouncing ?
                        [
                            <div style={this.props.style} key="loader-elements">
                                <div id="loader">
                                    <div id="shadow"></div>
                                    <div id="box"></div>
                                </div>
                                <div id="loader2">
                                    <div id="box2"></div>
                                </div>
                            </div>,
                            <h4 key="waiting-indicator-title"></h4>]
                        : <div className={"spinner" + (this.props.spinnerClass ? " " + this.props.spinnerClass : "")}
                               style={this.props.style}
                               key="waiting-indicator-dots">
                            <div
                                className={"bounce1 " + (this.props.bounceClass ? " " + this.props.bounceClass : "")}></div>
                            <div
                                className={"bounce2 " + (this.props.bounceClass ? " " + this.props.bounceClass : "")}></div>
                            <div
                                className={"bounce3 " + (this.props.bounceClass ? " " + this.props.bounceClass : "")}></div>
                        </div>
                    }
                </div>
            </div>
        )
    }
}