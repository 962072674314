import React from "react";
import connect from "react-redux/es/connect/connect";
import Logo from "../../common/Logo";
import LocaleSwitch from "../../common/LocaleSwitch";
import Button from "react-bootstrap/es/Button";
import Overlay from "react-bootstrap/es/Overlay";
import Popover from "react-bootstrap/es/Popover";
import store from "../../../store";
import {setPassword} from "../../../providers/users";
import {passwordRequirementsPattern} from "../../../constants";
import {setUserDetails} from "../../../actions/userActions";
import Loader from "../../common/Loader";
import Wave from "../../common/Wave";

const Translate = require('react-redux-i18n').Translate;

const I18n = require('react-redux-i18n').I18n

const stateMap = (store) => {
    return {
        i18n: store.i18n,
        userReducer: store.userReducer
    };
};

class NewPasswordForm extends React.Component {

    constructor(props) {
        super(props)

        this.state = {
            passwordRequirements: I18n.t('pages.userDetails.passwordRequirements'),
            password: "",
            repeatPassword: "",
            errorMessage: "",
            showHelp: false,
            showPassword: false,
            showRepeatPassword: false
        }

        this.handleChange = this.handleChange.bind(this)
        this.toggleShowPassword = this.toggleShowPassword.bind(this)
    }

    componentWillReceiveProps(nextProps) {
        if (nextProps.i18n !== this.props.i18n) {
            this.setState({
                passwordRequirements: I18n.t('pages.userDetails.passwordRequirements'),
            })
        }
    }


    handleChange(event) {
        this.setState({
            [event.target.id]: event.target.value
        })
    }

    toggleShowPassword(target){
        this.setState(prevState => ({
            [target]: !prevState[target]
        }));
    }


    submitNewPassword() {
        if (!this.checkPasswordsMatch(this.state.password, this.state.repeatPassword)) {
            this.setState({
                errorMessage: 'pages.userDetails.passwordsNotMatch'
            })
        } else {
            this.setState({
                errorMessage: ''
            })
            store.dispatch(setPassword(this.props.confirmationCode, this.props.email,
                this.state.password)).then(
                () => {
                    store.dispatch(setUserDetails({renderNewPasswordForm: false, renderNewPhoneForm: true}))
                },
                (error) => {
                    if (error.response.data.errors &&
                        error.response.data.errors[0] === "INVALID_EMAIL_OR_CONFIRMATION_TOKEN") {
                        this.setState({
                            errorMessage: 'pages.userDetails.errors.invalidEmailOrConfirmationToken'
                        })
                    }
                })
        }
    }

    checkPasswordsMatch(password, repeat) {
        return password === repeat
    }

    render() {
        const settingPasswordInProgress = this.props.userReducer &&
            this.props.userReducer.setPassword &&
            this.props.userReducer.setPassword.fetching
        return (
            <div>
                <main className="new-password-form">
                    <div className="login-area__top">
                        <LocaleSwitch type={"login-area"}></LocaleSwitch>
                    </div>
                    <div className="new-password-form__content">
                        <div className="new-password-form__right">
                            <div className="new-password-form__login-with-forgot">
                            </div>
                            <div className="new-password-form__login-with-forgot">
                                <div className="new-password-form__login-box">
                                    <Logo isMain={false} isHeader={false} imgClassName="login"/>
                                    <form onSubmit={(e) => {
                                        e.preventDefault();
                                        this.submitNewPassword();
                                    }}>
                                        <Translate value="pages.userDetails.newPassword"/>
                                        <label htmlFor="moreCriteria"
                                               className="new-password-form__info-button-label">
                                            <Button id="moreCriteria"
                                                    className="new-password-form__info-button"
                                                    onClick={e => {
                                                        this.setState({
                                                            target: e.target,
                                                            showHelp: !this.state.showHelp
                                                        });
                                                    }}>
                                            </Button>
                                            <span className="icon-icon-info"/>
                                            <Overlay
                                                show={this.state.showHelp}
                                                target={this.state.target}
                                                placement="right"
                                                container={this}
                                                containerPadding={20}>
                                                <Popover id="popover-positioned-right"
                                                         title=''>
                                                    {this.state.passwordRequirements}
                                                </Popover>
                                            </Overlay>
                                        </label>
                                        <div className="password-container">
                                            <input type={this.state.showPassword ? "text" : "password"}
                                                   onChange={this.handleChange}
                                                   size="40"
                                                   required={true}
                                                   pattern={passwordRequirementsPattern}
                                                   title={this.state.passwordRequirements}
                                                   id="password" name="password" value={this.state.password}/>
                                            <Button onClick={()=> {this.toggleShowPassword("showPassword")}}
                                                    className={"new-password-form__icon-password-eye" +
                                                    (this.state.showPassword ? " icon-show-password-eye" : " icon-hide-password-eye")}></Button>
                                        </div>
                                        <Translate value="pages.userDetails.retypeNewPassword"/>
                                        <div className="password-container">
                                            <input type={this.state.showRepeatPassword ? "text" : "password"}
                                                   onChange={this.handleChange}
                                                   size="40"
                                                   required={true}
                                                   pattern={passwordRequirementsPattern}
                                                   title={this.state.passwordRequirements}
                                                   id="repeatPassword" name="password"
                                                   value={this.state.repeatPassword}/>
                                            <Button onClick={()=> {this.toggleShowPassword("showRepeatPassword")}}
                                                    className={"new-password-form__icon-password-eye" +
                                                    (this.state.showRepeatPassword ? " icon-show-password-eye" : " icon-hide-password-eye")}></Button>
                                        </div>
                                        {/*Error messages*/}
                                        <div className="new-password-form__wrong-creds">
                                            {this.state.errorMessage !== '' ?
                                                <div className="generic-error-msg">
                                                    <span className="icon-icon-attention"/>
                                                    <div>
                                                        <Translate value={this.state.errorMessage} dangerousHTML/>
                                                    </div>
                                                </div> : null
                                            }
                                        </div>
                                        <div className="new-password-form__button-area">
                                            <button type="submit" className="new-password-form__button"
                                                    disabled={settingPasswordInProgress}>
                                                {settingPasswordInProgress ?
                                                    <Loader bouncing={false} color={'white'} message=''
                                                            style={{marginTop: 0}} bounceClass='smaller-bounces'/> :
                                                    <Translate value="pages.userDetails.submitButtonText"/>
                                                }
                                            </button>
                                        </div>
                                    </form>
                                </div>
                            </div>
                        </div>
                    </div>
                    <Wave />
                </main>
            </div>
        )
    }
}

export default connect(stateMap)(NewPasswordForm);