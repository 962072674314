import React from 'react';
import Options from "./StackedBarsChartOptions";
import AmCharts from "@amcharts/amcharts3-react";
import {withRouter} from "react-router-dom";
import connect from "react-redux/es/connect/connect";



const stateMap = (store) => {
    return {
        i18n: store.i18n
    };
};

class StackedBarsChart extends React.Component {
    constructor(props) {
        super(props)
        this.state = {
            locale: this.props.i18n.locale
        }
    }

    componentWillMount() {
        this.setState({
            locale: this.props.i18n.locale
        })

    }

    componentWillReceiveProps(nextProps) {
        if (this.props.i18n.locale !== nextProps.i18n.locale) {
            this.setState({
                locale: nextProps.i18n.locale
            })
        }
    }

    render() {
        let styles = {
            width: "95%",
            height: "280px",
            marginTop: "30px",
            backgroundRepeat: 'no-repeat',
            // backgroundSize: "38px 31px",
            // backgroundPosition: 'center ',
            position: 'relative'
        };

        return (
            <React.Fragment>
                <div id={"chart-export-btn__" + this.props.exportBtnId}>
                </div>
                <AmCharts.React
                    key={this.props.chartKey}
                    style={styles}
                    options={Options(this.props.data,
                        this.props.numberOfGraphs,
                        this.props.graphTitles,
                        this.props.graphValues,
                        this.props.types,
                        this.props.exportBtnId,
                        this.props.filename,
                        this.state.locale)}
                />
            </React.Fragment>
        )
    }
}


export default withRouter(connect(stateMap)(StackedBarsChart));