import React from "react";
import {DropdownButton, MenuItem} from "react-bootstrap";
import store from "../../store";
import connect from "react-redux/es/connect/connect";
import {arrangeCompanyName, bytesToSize} from "../../lib/utils";
import {withRouter} from "react-router-dom";
import SearchBox from "../common/SearchBox";
import Dropzone from 'react-dropzone'
import {getMidsAndTidsByAfm} from "../../providers/merchants";
import moment from "moment";
import {emailSupport} from "../../providers/users";
import Loader from "../common/Loader";
import ResponseMessage from "./ResponseMessage";

const Translate = require('react-redux-i18n').Translate;

const I18n = require('react-redux-i18n').I18n;

const _ = require("underscore");

const stateMap = (store) => {
    return {
        userReducer: store.userReducer,
        merchantReducer: store.merchantReducer,
        emailReducer: store.emailReducer,
        i18n: store.i18n
    };
};


export class AsidePanelSupportForm extends React.Component {
    constructor(props) {
        super(props)
        this.state = {
            category: undefined,
            afm: this.props.merchantReducer.afm,
            companies: this.props.userReducer.getAssociationsResponse.uniqueAfmsWithTitles,
            companyTitles: this.getAllCompanyTitles(this.props.userReducer.getAssociationsResponse.uniqueAfmsWithTitles),
            companyTitle: undefined,
            companyName: undefined,
            company: undefined,
            mid: undefined,
            fileSizeArr: [],
            singleMid: false,
            singleTid: false,
            midsDropdown: undefined,
            tidsDropdown: undefined,
            merchantTitles: [],
            pending: false,
            files: [],
            companyObj: undefined,
            emailSent: undefined,
            showFileError: false,
            emailBodyError: false

    };

        this.populateMidsSelection = this.populateMidsSelection.bind(this);
        this.populateTidsSelection = this.populateTidsSelection.bind(this);
        this.onDrop = this.onDrop.bind(this)
        this.onCancel = this.onCancel.bind(this)
        this.checkTextArea = this.checkTextArea.bind(this);
        this.getSingleArr = this.getSingleArr.bind(this);
        this.formulateEmailMessage = this.formulateEmailMessage.bind(this);
        this.getAllCompanyTitles = this.getAllCompanyTitles.bind(this);
        this.onlyUnique = this.onlyUnique.bind(this);
        this.checkFilesSize = this.checkFilesSize.bind(this);
        this.removeUpload = this.removeUpload.bind(this);
    }

    componentWillMount() {
        if (this.state.afm) {
            this.findCurrentCompany(this.state.companies, this.state.afm);
        } else {
            let afms = this.props.userReducer.getAssociationsResponse.uniqueAfms;
            this.handleChange("company", afms.toString(), I18n.t("modals.support.company.dropdown.allCompanies"), true);
        }
    }

    componentWillReceiveProps(nextProps) {
        if(nextProps.merchantReducer.afm !== this.props.merchantReducer.afm) {
            this.setState({
                afm: nextProps.merchantReducer.afm
            }, () => {
                this.findCurrentCompany(this.state.companies, nextProps.merchantReducer.afm)
            })
        }
    }

    getSingleArr(arr) {
        let result = [];
        arr.forEach(val => {
            result.push(val.mid);
        })
        return result;
    }

    populateMidsSelection(midsArr) {
        let mids = [{label: I18n.t("modals.support.mid.dropdown.allMids"), value: midsArr.toString(), allSelected: true}];
        midsArr.map(mid => {
            mids.push({label: mid, value: mid, allSelected: false});
        });
        return mids;
    }

    populateTidsSelection(tidsArr) {
        let tids = [{label: I18n.t("modals.support.tid.dropdown.allTids"), value: tidsArr.toString(), allSelected: true}];
        tidsArr.map(tid => {
            tids.push({label: tid, value: tid, allSelected: false});
        });
        return tids;
    }

    onlyUnique(value, index, self) {
        return self.indexOf(value) === index;
    }

    findCurrentCompany(arr, afm) {
        let currComp = arr.find(x => x.afm === afm);
        return this.handleChange("company", currComp.afm, arrangeCompanyName(currComp), false)
    }

    getAllCompanyTitles(arr) {
        let res = [];
        arr.map(comp => {
            res.push(arrangeCompanyName(comp, this.props.i18n.locale));
        });
        return res;
    }


    handleChange(type, value, label, allSelected) {
        this.setState({
            [type]: {label: label, value: value, allSelected: allSelected}
        }, () => {
            if (type === "company") {
                this.setState({
                    mid: undefined,
                    singleMid: undefined,
                    tid: undefined,
                    singleTid: undefined
                });
                if(allSelected) {
                    this.setState({
                        companyTitle: (this.state.companyTitles).toString(),
                    })
                } else {
                    store.dispatch(getMidsAndTidsByAfm(1, -1, value, undefined)).then(
                    (response) => {
                        let newMids = this.populateMidsSelection(this.getSingleArr(response.value.data[0].mids));
                        let newTids = response.value.data[0].mids.length === 1 ? this.populateTidsSelection(response.value.data[0].mids[0].tids.filter(this.onlyUnique)) : undefined;
                        this.setState({
                            midsDropdown: newMids,
                            singleMid: newMids.length <= 2,
                            mid: newMids.length <= 2 ? newMids[1] : undefined,
                            companyTitle: this.state.company.label,
                            companyObj: response.value.data[0],
                            tidsDropdown: newTids ? newTids : undefined,
                            singleTid: newTids ? (newTids.length <= 2): undefined,
                            tid: newTids ? (newTids.length <= 2 ? newTids[1] : undefined): undefined,
                        })
                    });
                }

            }
            if (type === "mid") {
                this.setState({
                    tid: undefined,
                    singleTid: undefined
                });
                if(!allSelected) {
                    let selectedMidObj = _.find(this.state.companyObj.mids, function (obj) {
                        return obj.mid === value; });
                    let newTids = this.populateTidsSelection(selectedMidObj.tids.filter(this.onlyUnique));
                    this.setState({
                        tidsDropdown: newTids,
                        singleTid: newTids.length <= 2,
                        tid: newTids.length <= 2 ? newTids[1] : undefined
                    })
                }
            }
            if (type === "message") {
                this.setState({
                    emailBodyError: /((javascript:)|(<)|(>))/.test(value)
                });
            }
        })
    }

	onDrop(files) {
        files.forEach((file, idx) => {
            const reader = new FileReader();
            reader.onload = (event) => {
                let resFiles = [];
                let resFileSizeArr = [];
                resFiles.push({
                    name: file.name,
                    attachment: Array.from(new Uint8Array(event.target.result)),
                    contentType: null
                });
                resFileSizeArr.push(file.size);
                this.setState(prevState => ({
                    files: prevState.files && prevState.fileSizeArr ? this.checkFilesSize(prevState.files.concat(resFiles), prevState.fileSizeArr.concat(resFileSizeArr))[0] : this.checkFilesSize(resFiles, resFileSizeArr)[0],
                    fileSizeArr: prevState.fileSizeArr && prevState.files ? this.checkFilesSize(prevState.files.concat(resFiles), prevState.fileSizeArr.concat(resFileSizeArr))[1] : this.checkFilesSize(resFiles, resFileSizeArr)[1],
                }));
            };
            let fileForReader = file;
            if (fileForReader) {
                reader.readAsArrayBuffer(fileForReader);
                this.setState({
                    showFileError: false
                })
            } else {
                this.setState({
                    showFileError: true
                })
            }
        });
	}

    onCancel() {
        this.setState({
            files: null
        });
    }

    checkFilesSize(fileArr, sizeArr) {
        let limit = 4194304;
        let sum = 0;
        for (let i = 0; i < sizeArr.length; i++) {
            sum += sizeArr[i];
            if (sum > limit) {
                sizeArr.length = i;
                fileArr.length = i;
            }
        }
        return [fileArr, sizeArr];
    }

    removeUpload(idx) {
        let fileArr = [...this.state.files];
        let sizeArr = [...this.state.fileSizeArr];
        fileArr.splice(idx, 1);
        sizeArr.splice(idx, 1);
        this.setState({
            files: fileArr,
            fileSizeArr: sizeArr
        });
    }


    checkTextArea(text) {
       return !text || !text.value.replace(/\s/g, '').length || !text.value || /((javascript:)|(<)|(>))/.test(text.value);
    }

    formulateEmailMessage() {
        let profile = this.props.userReducer.profile;
        this.props.i18n.locale === 'el' ? moment.locale('el') : moment.locale('en');
        let subject = I18n.t("modals.support.email.aside.subject") + " " + this.state.companyTitle;
        let emailContent = {
            portalLabel: I18n.t("modals.support.email.aside.portalLabel"),
            timeStamp: moment().format('dddd DD MMMM HH:mm:ss'),
            afm: this.state.company.value,
            mid: this.state.mid ? this.state.mid.value : "-",
            tid: this.state.tid ? this.state.tid.value : "-",
            userEmail: profile.email,
            userRealName: profile.firstName + " " + profile.lastName,
            mobile: profile.phone,
            title: this.state.companyTitle,
            cause: this.state.category.label,
            message: this.state.message.value,
            attachments: this.state.files,
            section: "commonSupport",
            labels: {
                timeStamp: I18n.t("modals.support.email.labels.timeStamp"),
                cause: I18n.t("modals.support.email.labels.cause"),
                taxId: I18n.t("modals.support.email.labels.taxId"),
                mid: I18n.t("modals.support.email.labels.mid"),
                tid: I18n.t("modals.support.email.labels.tid"),
                email: I18n.t("modals.support.email.labels.email"),
                fullName: I18n.t("modals.support.email.labels.fullName"),
                mobile: I18n.t("modals.support.email.labels.mobile"),
                corpTitle: I18n.t("modals.support.email.labels.corpTitle"),
                message: I18n.t("modals.support.email.labels.message")
            }
        };

        store.dispatch(emailSupport(subject, emailContent)).then(
            (response) => {
                this.setState({
                    emailSent: "sent"
                })

            },
            (error) => {
                this.setState({
                    emailSent: "notSent"
                })
            },

        )
    }


    render() {
        let categoryPrefix = "modals.support.category.dropdown.";
        let companyPrefix = "modals.support.company.dropdown.";
        let categories = [{key: "selectionRequest", value: "request", label: I18n.t(categoryPrefix + "request")}, {key: "selectionFailure", value: "failure", label: I18n.t(categoryPrefix + "failure")}, {key: "selectionComplain", value: "complain", label: I18n.t(categoryPrefix + "complain")}];
        let companies = this.state.companies;
        let afms = this.props.userReducer.getAssociationsResponse.uniqueAfms;
        let type = this.props.userReducer.getAssociationsResponse.merchantType;
        let categoryVal = this.state.category ? (categoryPrefix + this.state.category.label) : categoryPrefix + "selection";
        let companyVal = this.state.company ? (this.state.company.label ? this.state.company.label: companyPrefix + "selection"): companyPrefix + "selection";
        return (
            <div className="support-form__content">
                {!this.props.emailReducer.postage.fetching && !this.state.emailSent ?
                <form onSubmit={(e) => {
                    e.preventDefault();
                    this.formulateEmailMessage();
                }}>
                    <div className="support-form__block">
                        <Translate value="modals.support.category.title" />
                            <DropdownButton
                                title={
                                <Translate value={categoryVal}/>}
                                id="supportDropdownCategory">
                                {categories.map((category) => {
                                        return [
                                            <MenuItem key={category.key}
                                                      onSelect={() => {
                                                          this.handleChange("category", category.value, category.label, false)
                                                      }}
                                                      active={this.state.category ?  (this.state.category.value === category.value): false}
                                            >
                                                <Translate value={categoryPrefix + category.value}/>
                                            </MenuItem>
                                        ]
                                    }
                                )
                                }
                            </DropdownButton>
                    </div>
                    <div className="support-form__block__row">
                        <div className="support-form__block">
                            <Translate value="modals.support.company.title" />
                            {type === 'multiAfm' ?
                            <DropdownButton
                                title={<Translate value={companyVal}/>}
                                id="supportDropdownCompany">
                                <MenuItem eventKey={undefined}
                                              key={'empty-afm'}
                                              onSelect={() => {
                                                  this.handleChange("company", afms.toString(), I18n.t(companyPrefix + "allCompanies"), true);
                                              }}
                                              active={this.state.company ? (this.state.company.allSelected) : false}
                                >
                                        {<Translate value={companyPrefix + "allCompanies"} />}
                                </MenuItem>
                                {companies ? (
                                    companies.map((company) => {
                                            return [
                                                <MenuItem eventKey={company.afm}
                                                          key={company.afm}
                                                          onSelect={() => {
                                                              let title = arrangeCompanyName(company, this.props.i18n.locale);
                                                              this.handleChange("company", company.afm, title, false)
                                                          }
                                                          }
                                                          active={this.state.company ? (company.afm === this.state.company.value) : false}>
                                                          {arrangeCompanyName(company, this.props.i18n.locale)}
                                                </MenuItem>
                                            ]
                                    })) : null
                                }
                            </DropdownButton>:
                                <h6 className="single-element-value">{arrangeCompanyName(companies[0], this.props.i18n.locale)}</h6>}
                        </div>
                    </div>
                    <div className="support-form__block__row">
                        <div className="support-form__block">
                            <Translate value="modals.support.mid.title" />
                            {(type === 'multiAfm' || type === 'multiMid') && !this.state.singleMid ?
                            <SearchBox options={this.state.midsDropdown}
                                       clsName="support-form__mid-dropdown"
                                       value={this.state.mid ? this.state.mid: null} onChange={(event) => {
                                            this.handleChange('mid', event.value, event.label, event.allSelected)
                                        }}
                                       isDisabled={!this.state.company || (this.state.company ? (this.state.company.allSelected): false)}
                                       placeholder="modals.support.mid.dropdown.selection" />:
                                this.state.singleMid  ?
                                <h6 className="single-element-value">{this.state.midsDropdown[1].label ? this.state.midsDropdown[1].label : "-"}</h6>
                            :   <h6 className="single-element-value">{"-"}</h6>}
                        </div>
                        <div className="support-form__block support-form__block--tid">
                            <Translate value="modals.support.tid.title" />
                            {type !== 'singleAfmMids' && !this.state.singleTid ?
                            <SearchBox options={this.state.tidsDropdown}
                                       clsName="support-form__tid-dropdown"
                                       value={this.state.tid ? this.state.tid: null} onChange={(event) => {
                                            this.handleChange('tid', event.value, event.label, event.allSelected)
                                        }}
                                       isDisabled={!this.state.mid|| (this.state.mid ? (this.state.mid.allSelected): false)}

                                      placeholder="modals.support.tid.dropdown.selection" />:
                                this.state.singleTid  ?
                                    <h6 className="single-element-value">{this.state.tidsDropdown[1].label ? this.state.tidsDropdown[1].label : "-"}</h6>:
                                    <h6 className="single-element-value">{"-"}</h6>}
                        </div>
                    </div>
                    <div className="support-form__block__row">
                        <div className="support-form__textarea">
                            <Translate value="modals.support.textArea.title"/>
                            <textarea value={this.state.value}
                                      disabled={!this.state.category}
                                      maxLength="10000"
                                      onChange={(event) => {
                                          this.handleChange('message', event.target.value, "message", false)
                                      }}
                                      placeholder={this.state.category ? I18n.t("modals.support.textArea.placeholder") : I18n.t("modals.support.textArea.placeholderB")}/>
                            {this.state.emailBodyError ?
                                <div className="support-error-file">
                                    <span className="icon-icon-attention support-error-file__icon"></span>
                                    <Translate className="support-error-file__text"
                                               value="modals.support.textArea.contentError"/>
                                </div> : null}
                        </div>
                    </div>
                    <div className="support-form__block__row support-form__file-attachment">
                        <section>
                            <div className="dropzone">
                                <Dropzone
                                    accept="image/*, application/pdf"
                                    onDrop={this.onDrop}
                                    maxSize={4194304}
                                    multiple={true}
                                    onFileDialogCancel={this.onCancel}
                                    className="dropzone support-form__dropzone">
                                    <Translate value="modals.support.fileUpload.dragAndDrop" dangerousHTML  />
                                </Dropzone>
                            </div>
                            {this.state.files ?
                                <div>
                                    {this.state.files.length > 0 ?
                                    <h2>
                                        <Translate value="modals.support.fileUpload.attachedFiles"/>
                                    </h2>: null}
                                    <ul>
                                        {this.state.files.map((f, idx) => <li key={f.name}>{f.name} - {bytesToSize(this.state.fileSizeArr[idx], 2)}
                                        <i className="icon-failed support-form__remove-attachment" onClick={() => this.removeUpload(idx)}></i>
                                        </li>)}
                                    </ul>
                                </div>:null}
                                {this.state.showFileError ?
                                    <div className="support-error-file">
                                        <span className="icon-icon-attention support-error-file__icon"></span>
                                        <Translate className="support-error-file__text" value="modals.support.fileUpload.error" />
                                    </div>: null}
                        </section>
                    </div>
                    <div className="support-form__button-wrapper">
                        <button type="submit"
                                disabled={this.checkTextArea(this.state.message)
                                    || !this.state.category
                                    || this.props.merchantReducer.tree.fetching
                                }>
                            <Translate value="modals.support.buttonSubmit"/>
                        </button>
                    </div>
                </form>:
                    <div>
                        {this.state.emailSent?
                            <ResponseMessage response={this.state.emailSent} closeModal={this.props.closeModal} />:
                            <Loader bouncing={true}
                                    color={'bahama-blue'}
                                    message="modals.support.emailFetching"
                                    loaderStyles={{minHeight: "120px"}}
                            />
                        }
                    </div>
               }
            </div>
        )
    }
}

export default withRouter(connect(stateMap)(AsidePanelSupportForm ));