import React from "react";
import {connect} from "react-redux";
import {withRouter} from "react-router-dom";
import {Button, DropdownButton, MenuItem} from "react-bootstrap";
import moment from "moment";
import {blurInput, toUppercaseNoAccents} from "../../../lib/utils";
import {DateTimePicker} from "react-widgets";
import momentLocalizer from "react-widgets-moment";
import SearchBox from "../../common/SearchBox";
import Loader from "../../common/Loader";

const Translate = require('react-redux-i18n').Translate;
const I18n = require('react-redux-i18n').I18n;

const stateMap = (store) => {
    return {
        i18n: store.i18n,
        userReducer: store.userReducer
    };
};

class DebitNotesCriteriaForm extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            tids: props.tids,
            cardSchemes: props.cardSchemes,
            batches: props.batches,
            lastAvailableMonth: props.lastAvailableMonth,
            selectedMonth: {},
            minDateFrom: props.minDateFrom,
            maxDateTo: props.maxDateTo,
            dateFrom: props.dateFrom,
            dateTo: props.dateTo,
            debitNotesTotalData: [],
            lastSixMonths: [],
            selectedTids: [],
            selectedCardSchemes: [],
            selectedBatches: [],
            pickedTids: null,
            pickedSchemes: null,
            pickedBatches: null,
            extraCriteria: false,
        };

        this.getLastSixMonths = this.getLastSixMonths.bind(this);
        this.toggleExtraCriteria = this.toggleExtraCriteria.bind(this);
    }

    componentWillMount(){
        this.setState({
            tids: this.props.tids,
            lastAvailableMonth: this.props.lastAvailableMonth,
            lastSixMonths: this.getLastSixMonths(this.props.lastAvailableMonth, I18n._locale)
        }, ()=> {
        })
    }

    componentDidMount() {
        this.props.changeSelectedMonth(this.getLastSixMonths(this.state.selectedMonth, this.props.i18n.locale)[0]);
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        if (prevProps.i18n.locale !== this.props.i18n.locale) {
            this.setState({
                lastSixMonths:  this.getLastSixMonths(this.state.lastAvailableMonth, this.props.i18n.locale)
            }, ()=> {
                this.props.changeSelectedMonth(this.getLastSixMonths(this.state.selectedMonth, this.props.i18n.locale)[0])
            });
        }
        if (prevProps.selectedMonth !== this.props.selectedMonth) {
            this.setState({
                selectedMonth:  this.props.selectedMonth,
                minDateFrom: this.props.minDateFrom,
                maxDateTo: this.props.maxDateTo,
            });
        }

        if (prevProps.dateFrom !== this.props.dateFrom) {
            this.setState({
                dateFrom:  this.props.dateFrom,
            });
        }

        if (prevProps.dateTo !== this.props.dateTo) {
            this.setState({
                dateTo:  this.props.dateTo,
            });
        }

        if (prevProps.selectedCardSchemes !== this.props.selectedCardSchemes) {
            this.setState({
                selectedCardSchemes:  this.props.selectedCardSchemes
            });
        }
        if (prevProps.selectedTids !== this.props.selectedTids) {
            this.setState({
                selectedTids:  this.props.selectedTids
            });
        }
        if (prevProps.selectedBatches !== this.props.selectedBatches) {
            this.setState({
                selectedBatches:  this.props.selectedBatches
            });
        }

        if (prevProps.debitNotesTotalData !== this.props.debitNotesTotalData) {
            this.setState({
                debitNotesTotalData: this.props.debitNotesTotalData
            });
        }

        if (prevProps.cardSchemes !== this.props.cardSchemes) {
            this.setState({
                cardSchemes: this.props.cardSchemes
            });
        }

        if (prevProps.batches !== this.props.batches) {
            this.setState({
                batches: this.props.batches
            });
        }
    }

    getLastSixMonths(currentMonth, locale) {
        let months = [1, 2, 3, 4, 5, 6];
        return months.map((i, idx)=> {
            let label = moment(currentMonth).locale(locale).subtract(i, 'months').format('MMMM YYYY');
            let value = moment(currentMonth).subtract(i, 'months').toDate();
            return {label: toUppercaseNoAccents(label), value: value}
        });
    }

    toggleExtraCriteria() {
        this.setState(prevState => ({
            extraCriteria: !prevState.extraCriteria
        }))
    }

    handleDownloadExcelFile(fileType) {

    }

    render() {
        moment.locale('en-GB')
        momentLocalizer();
        return (
            <div className="debit-notes__form">
                <div className="debit-notes__form--item__top">
                    <div className="debit-notes__form--item debit-notes__form--item__month">
                        <div className="debit-notes__title">
                            <Translate value="pages.business.debitNotes.form.monthSelection"/>
                        </div>
                        <DropdownButton
                            title={this.state.selectedMonth.label ? this.state.selectedMonth.label : ''}
                            id="debitNotesMonthDropdown">
                            {this.state.lastSixMonths.map((month) => {
                                    return [
                                        <MenuItem key={month.label.replace(" ", "-key")}
                                                  onSelect={() => {
                                                      this.props.changeSelectedMonth(month);
                                                  }}
                                                  active={this.state.selectedMonth ? (this.state.selectedMonth.value === month.value) : false}
                                        >
                                            {month.label}
                                        </MenuItem>
                                    ];
                                }
                            )}
                        </DropdownButton>
                    </div>
                    <button disabled={this.props.disableExport} className="debit-notes__download--btn"
                            onClick={() => this.props.generateDebitNotesPdfFile()}>
                        {this.props.disableExport ? <Loader bouncing={false} color={'white'} message=""
                                                            style={{marginTop: 0}}
                                                            bounceClass="smaller-bounces"/>
                            : <React.Fragment>
                                <span>PDF</span>
                                <i className="icon-download-icon"/>
                            </React.Fragment>
                        }
                    </button>
                </div>
                <div className="debit-notes__more-criteria">
                    <label htmlFor="debitNotesExtraCriteria" className="mid-line">
                        <input className="mid-line__more-criteria"
                               type="checkbox" id="debitNotesExtraCriteria"
                               onChange={() => {
                                   this.toggleExtraCriteria();
                               }}
                        />
                        <span className={this.state.extraCriteria ? "icon-minus-less" : "icon-right-open-1"}/>
                        <Translate className="debit-notes__more-criteria--label"
                                   value={this.state.extraCriteria ?
                                       "pages.business.debitNotes.form.lessCriteria" :
                                       "pages.business.debitNotes.form.moreCriteria"}/>
                    </label>
                </div>
                {this.state.extraCriteria ?
                    <div className="debit-notes__more-criteria--form__content">
                        <div className="debit-notes__form--item debit-notes__form--item__left">
                            <div className="debit-notes__title">
                                <Translate value="pages.business.debitNotes.form.timePeriod"/>
                            </div>
                            <div className="debit-notes__datepicker">
                                <div className="debit-notes__datepicker--top debit-notes__datepicker--item">
                                    <DateTimePicker value={this.state.dateFrom}
                                                    time={false}
                                                    onChange={dateFrom => this.props.changeDateFrom(dateFrom)}
                                                    min={this.state.minDateFrom}
                                                    max={new Date(Math.min(this.state.maxDateTo, this.state.dateTo, moment().toDate()))}/>
                                </div>
                                <div className="debit-notes__datepicker--bottom debit-notes__datepicker--item">
                                    <DateTimePicker value={this.state.dateTo}
                                                    time={false}
                                                    onChange={dateTo => this.props.changeDateTo(dateTo)}
                                                    min={new Date(Math.max(this.state.dateFrom, this.state.minDateFrom))}
                                                    max={new Date(Math.min(moment().toDate(), this.state.maxDateTo))}/>
                                </div>
                            </div>
                        </div>
                        <div className="debit-notes__form--item debit-notes__form--item__middle">
                            <div className="debit-notes__title">
                                <Translate value="pages.business.debitNotes.form.posSelection"/>
                            </div>
                            <SearchBox options={this.state.tids}
                                       clsName="debit-notes__search-box"
                                       value={this.state.pickedTids}
                                       isMulti={true}
                                       blurInput={blurInput}
                                       onChange={(event) => {
                                           if (event.length > 0) {
                                               const tidsOnly = event.map(element => {
                                                   return {value: element.value, label: element.value};
                                               });
                                               this.setState({
                                                   pickedTids: tidsOnly,
                                               }, () => {
                                                   this.props.changeSelectedTids(this.state.pickedTids.map(a => a.value));
                                               });
                                           } else {
                                               this.setState({
                                                   pickedTids: null,
                                               });
                                               this.props.changeSelectedTids([]);
                                           }

                                       }}
                                       placeholder={"pages.business.debitNotes.form.allPos"}/>
                            <div className="debit-notes__form--item__middle--bottom">

                                <div className="debit-notes__title">
                                    <Translate value="pages.business.debitNotes.form.batchSelection"/>
                                </div>
                                <SearchBox options={this.state.batches}
                                           clsName="debit-notes__search-box"
                                           value={this.state.pickedBatches}
                                           isMulti={true}
                                           blurInput={blurInput}
                                           onChange={(event) => {
                                               if (event.length > 0) {
                                                   const batchesOnly = event.map(element => {
                                                       return {value: element.value, label: element.value};
                                                   });
                                                   this.setState({
                                                       pickedBatches: batchesOnly,
                                                   }, () => {
                                                       this.props.changeSelectedBatches(this.state.pickedBatches.map(a => a.value));
                                                   });
                                               } else {
                                                   this.setState({
                                                       pickedBatches: null,
                                                   });
                                                   this.props.changeSelectedBatches([]);
                                               }

                                           }}
                                           placeholder={"pages.business.debitNotes.form.allBatches"}/>

                            </div>

                        </div>
                        <div className="debit-notes__form--item debit-notes__form--item__right">
                            <div className="debit-notes__title">
                                <Translate value="pages.business.debitNotes.form.cardSchemeSelection"/>
                            </div>
                            <div className="debit-notes__form--item__right--top">
                                <SearchBox options={this.state.cardSchemes}
                                           clsName="debit-notes__search-box"
                                           value={this.state.pickedSchemes}
                                           isMulti={true}
                                           blurInput={blurInput}
                                           onChange={(event) => {
                                               if (event.length > 0) {
                                                   const schemesOnly = event.map(element => {
                                                       return {value: element.value, label: element.value};
                                                   });
                                                   this.setState({
                                                       pickedSchemes: schemesOnly,
                                                   }, () => {
                                                       this.props.changeSelectedCardSchemes(this.state.pickedSchemes.map(a => a.value));
                                                   });
                                               } else {
                                                   this.setState({
                                                       pickedSchemes: null,
                                                   });
                                                   this.props.changeSelectedCardSchemes([]);
                                               }
                                           }}
                                           placeholder={"pages.business.debitNotes.form.allCardSchemes"}/>
                                <Button className="debit-notes__form--submit-btn"
                                        onClick={() => this.props.setDebitNotesTotalData()}>
                                    <Translate value="pages.business.debitNotes.form.submit"/>
                                </Button>
                            </div>
                            <div className="debit-notes__form--item__right--bottom">
                                <button disabled={this.props.disableExport} className="debit-notes__download--btn"
                                        onClick={() => this.props.generateDebitNoteFile('xlsx')}>
                                    {this.props.disableExport ? <Loader bouncing={false} color={'white'} message=""
                                                                        style={{marginTop: 0}}
                                                                        bounceClass="smaller-bounces"/>
                                        : <React.Fragment>
                                            <span>XLS</span>
                                            <i className="icon-download-icon"/>
                                        </React.Fragment>
                                    }
                                </button>
                                <button disabled={this.props.disableExport} className="debit-notes__download--btn"
                                        onClick={() => this.props.generateDebitNoteFile('csv')}>
                                    {this.props.disableExport ? <Loader bouncing={false} color={'white'} message=""
                                                                        style={{marginTop: 0}}
                                                                        bounceClass="smaller-bounces"/>
                                        : <React.Fragment>
                                            <span>CSV</span>
                                            <i className="icon-download-icon"/>
                                        </React.Fragment>
                                    }
                                </button>
                            </div>
                        </div>
                    </div>
                    : null}
            </div>
        )
    }
}

export default withRouter(connect(stateMap)(DebitNotesCriteriaForm));