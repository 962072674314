import React from "react";
import {connect} from "react-redux";
import {withRouter} from "react-router-dom";
import {Panel, Table} from "react-bootstrap";
import store from "../../store";
import {getCardlinkOneCardsCommissionsByMid} from "../../providers/merchants";
import {formatAmount} from "../../lib/utils";

const Translate = require('react-redux-i18n').Translate;

const stateMap = (store) => {
    return {
        i18n: store.i18n,
        userReducer: store.userReducer
    };
};

class PaymentsAndFees extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            paymentsFeesPanelOpen: false,
            mid: props.mid,
            iban: props.iban
        };

    }

    componentWillMount(){
        this.setState({
            mid: this.props.mid,
            iban: this.props.iban
        }, ()=> {
            store.dispatch(getCardlinkOneCardsCommissionsByMid(this.state.mid)).then(
                (response) => {
                   this.setState({
                       fees: response.value.data
                   })
                });
        })
    }


    render() {
        return (
            <Panel id="collapsible-panel-payments-and-fees"  onToggle={()=>{
                this.setState(prevState => ({
                    paymentsFeesPanelOpen: !prevState.paymentsFeesPanelOpen
                }));
            }}>
                <Panel.Heading>
                    <Panel.Toggle componentClass="h4">
                        <Translate value="pages.business.paymentsAndFees.title"/>
                        <i className="icon-plus-accordion"></i>
                        <i className="icon-minus-accordion"></i>
                    </Panel.Toggle>
                </Panel.Heading>
                <Panel.Collapse>
                    <Panel.Body>
                        <div className="fees-table__container">
                            {this.state.fees ?
                                <React.Fragment>
                                    <div className="fees-table__caption">
                                        <Translate className="fees-table__iban--title" value="pages.business.paymentsAndFees.caption"/>
                                        <span className="fees-table__iban--value">{this.state.iban}</span>
                                    </div>
                                    <Table className="fees-table" responsive>
                                        <thead>
                                            <tr>
                                                <th>
                                                    <Translate value="pages.business.paymentsAndFees.table.card" />
                                                </th>
                                                <th>
                                                    <Translate value="pages.business.paymentsAndFees.table.type" />
                                                </th>
                                                <th>
                                                    <Translate value="pages.business.paymentsAndFees.table.fixRate" />
                                                </th>
                                                <th>
                                                    <Translate value="pages.business.paymentsAndFees.table.percentageRate" />
                                                </th>
                                            </tr>
                                        </thead>
                                        {this.state.fees.length > 0 ?
                                            <tbody>
                                            {this.state.fees.map((fee) => {
                                                return <tr>
                                                    <td>
                                                        {fee.card ? fee.card : "   -   "}
                                                    </td>
                                                    <td>
                                                        {fee.type ? fee.type : "   -   "}
                                                    </td>
                                                    <td>
                                                        {fee.fixRate && parseFloat(fee.fixRate) > 0 ? formatAmount(this.props.i18n.locale, fee.fixRate, 2) : "   -   "}
                                                    </td>
                                                    <td>
                                                        {fee.percentageRate && parseFloat(fee.percentageRate) > 0 ? formatAmount(this.props.i18n.locale, fee.percentageRate, 2) : "   -   "}
                                                    </td>
                                                </tr>
                                            })
                                            }
                                            </tbody>:
                                            <tbody>
                                                <tr>
                                                    <td colSpan="4" className="no-data">
                                                        <Translate value="pages.business.paymentsAndFees.table.noData" />
                                                    </td>
                                                </tr>
                                            </tbody>
                                        }
                                    </Table>
                                </React.Fragment>
                            :null}
                         </div>
                    </Panel.Body>
                </Panel.Collapse>
            </Panel>
        )
    }
}

export default withRouter(connect(stateMap)(PaymentsAndFees));