import React from "react";

const Translate = require('react-redux-i18n').Translate;

export default class OpenedDetail extends React.Component {
    render() {
        return (
            <div className="user-profile__label__title_opened">
                <Translate value={"modals.profile." + this.props.type +".change"} />
            </div>
        )
    }
}