import React from 'react';
import BootstrapTable from "react-bootstrap-table-next";
import StackedBarsChart from "./stackedBarsChart/StackedBarsChart";
import moment from "moment";
import store from "../../store";
import {redemption, redemptionForChartExcel} from "../../providers/charts";
import ChartDropdown from "./ChartDropdown";
import {formatAmount, setChartDates} from "../../lib/utils";
import NoChartData from "../common/NoChartData";
import Loader from "../common/Loader";
import {withRouter} from "react-router-dom";
import connect from "react-redux/es/connect/connect";

const fileDownload = require('js-file-download')
const I18n = require('react-redux-i18n').I18n
const Translate = require('react-redux-i18n').Translate;

const stateMap = (store) => {
    return {
        chartReducer: store.chartReducer,
        i18n: store.i18n
    };
};

class RedemptionCharts extends React.Component {
    constructor(props) {
        super(props)
        this.state = {
            afm: this.props.afm,
            mid: this.props.mid,
            redemptionData: null,
            dateFrom: moment(moment().subtract(1, 'days')).subtract(1, 'month').format('YYYY-MM-DD'),
            dateTo: moment().subtract(1, 'days').format('YYYY-MM-DD'),
            redemptionsDropdownTooltip: I18n.t('tooltips.hover.timeDropdown05')
        }
        this.amountsFormatter = this.amountsFormatter.bind(this)
        this.valueFormatter = this.valueFormatter.bind(this)
        this.redemption = this.redemption.bind(this)
        this.returnNumber = this.returnNumber.bind(this)
        this.getOrderedListByKey = this.getOrderedListByKey.bind(this)
        this.setNewDates = this.setNewDates.bind(this)
        this.isEmptyObject = this.isEmptyObject.bind(this)
        this.getObjectSize = this.getObjectSize.bind(this)
        this.allMonthsFormatter = this.allMonthsFormatter.bind(this)
    }

    componentWillMount() {
        this.redemption({
            afm: this.props.afm,
            mid: this.props.mid,
            dateFrom: this.state.dateFrom,
            dateTo: this.state.dateTo
        },  this.props.i18n.locale);
    }

    componentWillReceiveProps(nextProps) {
        if (this.props.afm !== nextProps.afm ||
            this.props.mid !== nextProps.mid) {
            this.setState({
                afm: nextProps.afm,
                mid: nextProps.mid
            }, () => this.redemption({
                afm: this.state.afm,
                mid: this.state.mid,
                dateFrom: this.state.dateFrom,
                dateTo: this.state.dateTo
            }, nextProps.i18n.locale))
        }

        if (nextProps.i18n.locale !== this.props.i18n.locale) {
            this.setState({
                redemptionsDropdownTooltip: I18n.t('tooltips.hover.timeDropdown05'),
                locale: nextProps.i18n.locale
            }, ()=> {
                this.redemption({
                    afm: this.state.afm,
                    mid: this.state.mid,
                    dateFrom: this.state.dateFrom,
                    dateTo: this.state.dateTo,
                },  nextProps.i18n.locale)
            })
        }
    }

    redemption(data, locale) {
        store.dispatch(redemption(data)).then((response) => {
            let data = [];
            let totalCounts = 0;
            let totalAmount = 0;
            let totalRedemptionAmount = 0;

            let sumTotalCounts = 0;
            let sumTotalAmount = 0;
            let sumTotalRedemptionAmount = 0;
            let uniqueKeysForUndefined = 1
            let uniqueKeys = 1
            response.value.data.forEach((bank, index) => {
                if (data[bank.bank] === undefined) {
                    data[bank.bank] = []
                }
                sumTotalCounts += totalCounts;
                sumTotalAmount += totalAmount;
                sumTotalRedemptionAmount += totalRedemptionAmount;
                let monthLabel = moment(bank.month);
                monthLabel.locale(locale);
                data[bank.bank].push({
                    count: bank.count ? bank.count : 0,
                    redemptionAmount: bank.redemptionAmount ? bank.redemptionAmount : 0,
                    amount: bank.amount ? bank.amount : 0,
                    redemptionAmountPercentage: this.returnNumber(bank.redemptionAmount / (bank.redemptionAmount + bank.amount) * 100),
                    amountPercentage: this.returnNumber(bank.amount / (bank.redemptionAmount + bank.amount) * 100),
                    month: monthLabel.format('MMM \'YY'),
                    uniqueKey: bank.count && bank.redemptionAmount ?
                        bank.count.toString().concat(bank.redemptionAmount, uniqueKeys++) : uniqueKeysForUndefined++
                });
            });
            let result = this.getOrderedListByKey(data);
            Object.keys(result).forEach((bank, index) => {
                data[bank].forEach((data) => {
                    totalCounts += data.count;
                    totalAmount += data.amount;
                    totalRedemptionAmount += data.redemptionAmount;
                });
                sumTotalCounts += totalCounts;
                sumTotalAmount += totalAmount;
                sumTotalRedemptionAmount += totalRedemptionAmount;
                data[bank].push({
                    month: 'allMonths',
                    count: totalCounts,
                    amount: totalAmount,
                    redemptionAmount: totalRedemptionAmount,
                    uniqueKey: bank.concat('1')
                });
                totalCounts = 0;
                totalAmount = 0;
                totalRedemptionAmount = 0;
            });
            this.setState({
                redemptionData: this.getOrderedListByKey(data),
                sumTotalCounts: sumTotalCounts,
                sumTotalAmount: sumTotalAmount,
                sumTotalRedemptionAmount: sumTotalRedemptionAmount
            })
        }, (error) => {
        })
    }

    returnNumber(number) {
        return isNaN(number) ? 0 : number
    }

    getOrderedListByKey(unordered) {
        const ordered = {};
        Object.keys(unordered).sort().forEach(function (key) {
            ordered[key] = unordered[key];
        });
        return ordered
    }

    amountsFormatter(cell, row) {
        return (
            (cell && cell !== null) ? "€" + formatAmount(I18n._locale, cell, 2) : '-'
        )
    }

    valueFormatter(cell) {
        return (
            (cell) ? formatAmount(I18n._locale, cell, 0) : '-'
        )
    }

    setNewDates(dateFrom, dateTo) {
        this.setState({
            dateFrom: dateFrom,
            dateTo: dateTo
        })
    }

    isEmptyObject(obj) {
        for (let key in obj) {
            if (obj.hasOwnProperty(key))
                return false;
        }
        return true;
    }

    getObjectSize(obj) {
        let size = 0, key;
        for (key in obj) {
            if (obj.hasOwnProperty(key)) size++;
        }
        return size;
    };

    allMonthsFormatter(cell, row) {
        return (
            cell !== "allMonths" ? cell : ''
        )
    }

    render() {

        const columns = [
            {
                dataField: 'month',
                formatter: this.allMonthsFormatter
            },
            {
                dataField: 'count',
                text: <Translate value='pages.reports.countTitleTable' dangerousHTML/>,
                formatter: this.valueFormatter
            }, {
                dataField: 'amount',
                text: <Translate value='pages.reports.amountWithCardTitleTable' dangerousHTML/>,
                formatter: this.amountsFormatter,
            }, {
                dataField: 'redemptionAmount',
                text: <Translate value='pages.reports.redemptionTitleTable' dangerousHTML/>,
                formatter: this.amountsFormatter,
            }
        ];
        return (
            <div>
                <div className="date-align-right__wrapper">
                    <div className="dates__chart-dropdown__wrapper"
                         title={this.state.redemptionsDropdownTooltip}>
                        {this.props.renderDates(this.state.dateFrom, this.state.dateTo)}
                        <ChartDropdown
                            chartMaps={this.props.chartMaps}
                            isRedemption={true}
                            id="redemption-chart-timespan"
                            data={{
                                afm: this.props.afm,
                                mid: this.state.mid
                            }} reRenderChart={this.redemption} setDates={setChartDates}
                            setDatesInParent={this.setNewDates}
                            dropdownBaseValue="pages.reports.chart.dropdown.baseValueMonth"
                        />
                    </div>
                </div>
                {this.props.chartReducer && this.props.chartReducer.redemptions &&
                this.props.chartReducer.redemptions.fetched ?
                    <React.Fragment>
                        {this.state.redemptionData !== null && !this.isEmptyObject(this.state.redemptionData) ?
                            <div className="redemption-chart__outter-wrapper">
                                <div className="redemption-chart-export-menu">
                                    <div className="export-main">
                                        <a href="javascript:void(0);" onClick={() => {
                                            store.dispatch(redemptionForChartExcel({
                                                afm: this.state.afm,
                                                mid: this.state.mid,
                                                dateFrom: this.state.dateFrom,
                                                dateTo: this.state.dateTo,
                                                locale: this.props.i18n.locale
                                            })).then(response => {
                                                fileDownload(response.value.data, (I18n.t("pages.reports.titles.redemption") + "_" + this.state.dateFrom + "_" +
                                                    this.state.dateTo + ".xlsx"))
                                            })
                                        }}></a>
                                    </div>
                                </div>
                                {Object.keys(this.state.redemptionData).map((bank, idx) => {
                                    let isLastItem = idx === (this.getObjectSize(this.state.redemptionData) - 1);
                                    let isFirstItem = idx === 0;
                                    return [
                                        <div
                                            className={"bank-stacked-chart-container " + (isLastItem ? "last-item" : "") + (isFirstItem ? "first-item" : "")}
                                            key={"row-" + bank}>
                                            <div className=" bank-symbol-container">
                                                <span className="redemption-bank-label">{bank}</span>
                                                <i className={"bg-" + bank.replace(" ", "_")}></i>
                                            </div>
                                            {}
                                            <div className="bank-stacked-chart-wrapper">
                                                <StackedBarsChart
                                                    chartKey={bank.replace(" ", "_") + '_chartKey'}
                                                    data={this.state.redemptionData[bank]}
                                                    numberOfGraphs={2}
                                                    exportBtnId="redemption"
                                                    graphTitles={{
                                                        title1: I18n.t("pages.reports.amountWithCardTitle"),
                                                        title2: I18n.t("pages.reports.redemptionTitle")
                                                    }}
                                                    graphValues={{
                                                        value1: 'amountPercentage',
                                                        value2: 'redemptionAmountPercentage'
                                                    }}
                                                    types={{stackType: "100%"}}
                                                />
                                            </div>
                                            <div className="reports__redemption__table__wrapper">
                                                <BootstrapTable classes="stacked-chart-table"
                                                                keyField='uniqueKey'
                                                                data={this.state.redemptionData[bank]}
                                                                columns={columns}/>
                                                {isLastItem ?
                                                    <div className="reports__redemption__total-sums">
                                                        <h6 className="reports__redemption__total-sums__title">
                                                            <Translate value="pages.reports.totalSum"/>
                                                        </h6>
                                                        <div className="reports__redemption__total-sums__container">
                                                            <div className="reports__redemption__total-sums__item">
                                                                {this.state.sumTotalCounts}
                                                            </div>
                                                            <div
                                                                className="reports__redemption__total-sums__item reports__redemption__total-sums__item--card">
                                                                {"€" + formatAmount(this.props.i18n.locale, this.state.sumTotalAmount, 2)}
                                                            </div>
                                                            <div
                                                                className="reports__redemption__total-sums__item reports__redemption__total-sums__item--amount">
                                                                {"€" + formatAmount(this.props.i18n.locale, this.state.sumTotalRedemptionAmount, 2)}
                                                            </div>
                                                        </div>
                                                    </div> : null}
                                            </div>
                                        </div>
                                    ]
                                })}
                            </div>
                            :
                            <NoChartData/>
                        }
                    </React.Fragment> : null}
                {this.props.chartReducer && this.props.chartReducer.redemptions &&
                this.props.chartReducer.redemptions.fetching ?
                    <Loader bouncing={false} color={'bahama-blue'} message=''/> : null
                }
            </div>
        )
    }
}

export default withRouter(connect(stateMap)(RedemptionCharts));
