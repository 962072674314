import React from 'react';
import {Overlay, Popover} from "react-bootstrap";
import store from "../../store";
import {toggleHelpPopovers} from "../../actions/userActions";
import {withRouter} from "react-router-dom";
import connect from "react-redux/es/connect/connect";

const Translate = require('react-redux-i18n').Translate;


const stateMap = (store) => {
    return {
        userReducer: store.userReducer,
    };
};

const hotProps =
    ['companiesDropdown',
    'storesMultiselect',
    'reportsTransactions',
    'quickSearch',
    'reportsBanks',
    'downloadReports',
    'cardScheme',
    'cardsDates',
    'transactionTypes',
    'exportChartHints',
    'downloadBusinessExcel',
    'eCommerceBusiness',
    'transactionExcelButton',
    'transactionDetailsInfo',
    'transactionSettlingInfo',
    'settledTransactions',
    'pendingTransactions',
    'threeDSecureHeader',
    'isSettledTransactionHeader',
    'responseCodeHeader'
    ];

 class  HotSpot extends React.Component {
    constructor(props) {
        super(props)
        this.toggleHotSpot = this.toggleHotSpot.bind(this)
        this.closeHotPopovers = this.closeHotPopovers.bind(this)
    }

    componentWillMount() {
        this.closeHotPopovers();
    }

    closeHotPopovers() {
        hotProps.forEach(elProp => {
            store.dispatch(toggleHelpPopovers("showHelp__" + elProp, false))
        })
    }

    toggleHotSpot(hotProp) {
            store.dispatch(toggleHelpPopovers("showHelp__" + hotProp, !this.props.userReducer["showHelp__" + hotProp]));
            hotProps.forEach(elProp => {
                if (elProp !== hotProp && this.props.userReducer["showHelp__" + elProp]) {
                        store.dispatch(toggleHelpPopovers("showHelp__" + elProp, false))
                }
            })
    }

    render() {
        let show = this.props.userReducer["showHelp__" + this.props.hotProp];
        const currImage = this.props.image ? require('../../../assets/img/help/' + this.props.image + '.gif') : undefined;
        return (
            <React.Fragment>
                {!this.props.userReducer['hide__' + this.props.hotProp] ?
                    <div className={"hotspot__wrapper hotspot__" + this.props.hotProp}>
                        <div className = "hotspot "
                             onClick={(e) => {
                                 e.stopPropagation()
                                 this.toggleHotSpot(this.props.hotProp)
                             }}>
                            {show ?
                                <i className="icon-x-icon hotspot__close"
                                   onClick={() => {this.toggleHotSpot(this.props.hotProp)}}
                                /> : null}
                        </div>
                    </div>:null}
                <Overlay
                    rootClose={false}
                    show={show}
                    placement={this.props.placement}
                    container={this}
                    onHide={() => {}
                    }
                    containerPadding={20}>
                    <Popover id={"hotSpot-popover__" + (this.props.hotProp)}
                             onClick={(e) => {
                                 e.stopPropagation()
                             }}
                             bsClass={"hot-popover__" + this.props.hotProp + " popover"}
                             title=''>
                        {currImage ?
                            <img className={"hot-popover-image"}
                                 src={currImage}
                                 alt={this.props.image + " help tooltip"} />:null
                        }
                        <Translate value={"hotSpots."+(this.props.hotProp)} dangerousHTML/>
                    </Popover>
                </Overlay>
            </React.Fragment>
        )
    }
}

export default withRouter(connect(stateMap)(HotSpot));