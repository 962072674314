import {makeRequest} from "../api-client";

const middlewareUrl = process.env.MIDDLEWARE_URL

export const getLatestTermsInPdf = (locale) => {

    return makeRequest(middlewareUrl + "/terms/conditions/download", "GET", {
            isMerchant: true,
            locale: locale
        }, {},
        "GET_LATEST_TERMS_IN_PDF", 'blob')
};

export const getLatestTermsAndConditions = (locale) => {

    return makeRequest(middlewareUrl + "/terms/conditions", "GET", {
            isMerchant: true,
            locale: locale
        }, {},
        "GET_LATEST_TERMS_AND_CONDITIONS")
};

export const acceptTermsAndConditions = (accepted, version, locale) => {
    const data = {accepted: accepted, version: version, isMerchant: true, locale: locale}

    return makeRequest(middlewareUrl + "/terms/conditions/accept", "POST", data, {},
        "ACCEPT_TERMS_AND_CONDITIONS")
};