import React from 'react';
import StackedBarsChart from "./stackedBarsChart/StackedBarsChart";
import moment from "moment";
import store from "../../store";
import {transactionType} from "../../providers/charts";
import Loader from "../common/Loader";
import {withRouter} from "react-router-dom";
import connect from "react-redux/es/connect/connect";
import HotSpot from "../common/HotSpot";

const I18n = require('react-redux-i18n').I18n
const Translate = require('react-redux-i18n').Translate;

const stateMap = (store) => {
    return {
        chartReducer: store.chartReducer,
        userReducer: store.userReducer,
        i18n: store.i18n
    };
};

class TransactionTypesChart extends React.Component {
    constructor(props) {
        super(props)
        this.state = {
            afm: this.props.afm,
            mid: this.props.mid,
            transactionTypeMode: false,
            transactionTypesData: null,
            dateFrom: moment(moment().subtract(1, 'days')).subtract(11, 'months').format('YYYY-MM-DD'),
            dateTo: moment().subtract(1, 'days').format('YYYY-MM-DD'),
        }
        this.transactionTypeTotals = this.transactionTypeTotals.bind(this)
        this.transactionTypeModeCheck = this.transactionTypeModeCheck.bind(this)
        this.getValues = this.getValues.bind(this)
        this.getTitles = this.getTitles.bind(this)
    }

    componentWillMount() {
        this.transactionTypeTotals({
            afm: this.props.afm,
            mid: this.props.mid,
            dateFrom: this.state.dateFrom,
            dateTo: this.state.dateTo,
        }, this.props.i18n.locale)
    }

    componentWillReceiveProps(nextProps) {
        if (this.props.afm !== nextProps.afm || this.props.mid !== nextProps.mid) {
            this.setState({
                afm: nextProps.afm,
                mid: nextProps.mid
            }, () => this.transactionTypeTotals({
                afm: this.state.afm,
                mid: this.state.mid,
                dateFrom: this.state.dateFrom,
                dateTo: this.state.dateTo
            }, nextProps.i18n.locale))
        }

        if (nextProps.i18n.locale !== this.props.i18n.locale) {
            this.transactionTypeTotals({
                afm: this.state.afm,
                mid: this.state.mid,
                dateFrom: this.state.dateFrom,
                dateTo: this.state.dateTo
            }, nextProps.i18n.locale)}
    }

    transactionTypeTotals(data, locale) {
        store.dispatch(transactionType(data)).then((response) => {
            const data = []
            response.value.data.forEach((element) => {
                if (data[element.month] === undefined) {
                    let monthLabel = moment(element.month);
                    monthLabel.locale(locale);
                    data[element.month] = {month: monthLabel.format('MMM \'YY')}
                }
                data[element.month][element.transactionType.replace(" ", "_") + '_amount'] = element.amount ? element.amount : 0
                data[element.month][element.transactionType.replace(" ", "_") + '_count'] = element.count ? element.count : 0
            })
            this.setState({
                transactionTypesData: Object.values(data)
            })
        }, (error) => {
        })
    }

    transactionTypeModeCheck(event) {
        this.setState(prevState => ({
            transactionTypeMode: !prevState.transactionTypeMode
        }));
    }

    getValues(map, mode) {
        const values = {}
        let counter = 1
        map.forEach(type => {
            values['value' + counter] = type.label + '_' + mode
            counter++
        })
        return values
    }

    getTitles(map) {
        const titles = {}
        let counter = 1
        map.forEach(type => {
            titles['title' + counter] = I18n.t('pages.reports.transactionTypes.' + type.key)
            counter++
        })
        return titles
    }

    render() {
        const mode = this.state.transactionTypeMode ? 'count' : 'amount'
        return (
            <div>
                <div className="date-align-right__wrapper">
                    <h5 className="reports__transaction-types--section_pre-title">
                        <Translate value="pages.reports.titles.components"/>
                    </h5>
                </div>
                <h4 className="reports__transaction-types--section_title">
                    <Translate value="pages.reports.titles.transactionTypes"/>
                </h4>
                {this.props.chartReducer && this.props.chartReducer.transactionTypes &&
                this.props.chartReducer.transactionTypes.fetched ?
                    <React.Fragment>
                        {this.state.transactionTypesData !== null ?
                            <StackedBarsChart
                                chartKey={'_chartKey'}
                                data={this.state.transactionTypesData}
                                numberOfGraphs={4}
                                graphTitles={this.getTitles(this.props.transactionTypesMap)}
                                graphValues={this.getValues(this.props.transactionTypesMap, mode)}
                                exportBtnId="transaction-types"
                                types={{
                                    stackType: "regular",
                                    hasLegend: true,
                                    hasLabelsEnabled: true,
                                    restOfTransactions: I18n.t('pages.reports.transactionTypes.restOfTransaction'),
                                    integersOnly: this.state.transactionTypeMode
                                }}
                                filename= {I18n.t("pages.reports.titles.components") + " " +
                                            I18n.t("pages.reports.titles.transactionTypes") + "_" +
                                            this.state.dateFrom + "_" + this.state.dateTo}
                            /> : null}
                        {this.props.userReducer.showHotSpots ?
                            <div className="hotSpot-element__wrapper">
                                <HotSpot
                                    hotProp="transactionTypes"
                                    placement="bottom"
                                />
                            </div>
                            :null}
                    </React.Fragment> : null}
                {this.props.chartReducer && this.props.chartReducer.transactionTypes &&
                this.props.chartReducer.transactionTypes.fetching ?
                    <Loader bouncing={false} color={'bahama-blue'} message=''/> : null
                }
                <div className="reports__chart-switch chart-right">
                    <div
                        className={"blue__switch report-translate" + (!this.state.transactionTypeMode ? "_active" : "")}>
                        <Translate value="pages.reports.amountTitle"/>
                    </div>
                    <input type="checkbox"
                           name="select-transaction-type-mode-checkbox"
                           className="cbx"
                           id='transactionType'
                           checked={this.state.transactionTypeMode}
                           onChange={this.transactionTypeModeCheck}
                    />
                    <label htmlFor="transactionType" className="toggle">
                        <span></span>
                    </label>
                    <div
                        className={"orange__switch report-translate" + (this.state.transactionTypeMode ? "_active" : "")}>
                        <Translate value="pages.reports.countTitle"/>
                    </div>
                </div>
            </div>
        )
    }
}

export default withRouter(connect(stateMap)(TransactionTypesChart));