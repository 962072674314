import React from "react";
import {Modal} from "react-bootstrap";
import FooterSupportForm from "../supportForms/FooterSupportForm";
const Translate = require('react-redux-i18n').Translate;

export default class Footer extends React.Component {
    constructor(props) {
        super(props)

        this.state = {
            showSupportModal: false,
            showModalHeader: true
        };

        this.openSupportForm = this.openSupportForm.bind(this);
        this.closeSupportForm = this.closeSupportForm.bind(this);
        this.hideModalHeader= this.hideModalHeader.bind(this);

    }

    hideModalHeader() {
        this.setState({
            showModalHeader: false
        })
    }

    openSupportForm() {
        this.setState({
            showSupportModal: true
        })
    }

    closeSupportForm() {
        this.setState({
            showSupportModal: false
        })
    }

    render() {
        let currentYear = new Date().getFullYear();
        return (
            <div className="footer__wrapper">
                <Modal  bsClass="modal"
                        dialogClassName="modal-dialog support-modal__dialog"
                        show={this.state.showSupportModal}
                        onHide={this.closeSupportForm}>
                    <Modal.Header closeButton bsClass="modal-header support-modal_header">
                        <Modal.Title>
                            {this.state.showModalHeader ? <Translate value="footer.modal.header"/>:null}
                        </Modal.Title>
                    </Modal.Header>
                    <Modal.Body bsClass="modal-body support-modal_body">
                       <FooterSupportForm closeModal = {this.closeSupportForm} hideModalHeader = {this.hideModalHeader}/>
                    </Modal.Body>
                </Modal>
                <footer>
                    <div className="footer__copyright">
                        {"©Cardlink " + currentYear}
                    </div>
                    <div className="footer__block"
                         onClick={this.openSupportForm}>
                        <span className="icon-ico-chat-bubble"></span>
                        <Translate value="footer.support" />
                    </div>
                </footer>
            </div>
        )
    }
}

