export function Options(data, type, chartTitle, filename, locale) {
    data.forEach((obj, idx) => {
        switch (obj.title) {
            case 'NEXI':
                data[idx].color = '#322199';
                break;
            case 'WORLDLINE':
                data[idx].color = '#DE2402';
                break;
            case 'PIRAEUS BANK':
                data[idx].color = '#FFC130';
                break;
            case 'NBG':
                data[idx].color = '#37A9B2';
                break;
            case 'ATTICA BANK':
                data[idx].color = '#1E478D';
                break;
            case 'SKLAVENITIS':
                data[idx].color = '#F28E1D';
                break;
            case 'AMERICAN EXPRESS':
                data[idx].color = '#005596';
                break;
            case 'DINERS':
                data[idx].color = '#00245D';
                break;
            case 'MAESTRO':
                data[idx].color = '#322199';
                break;
            case 'MASTERCARD':
                data[idx].color = '#DE2402';
                break;
            case 'UNIONPAY':
                data[idx].color = '#007E6B';
                break;
            case 'VISA':
                data[idx].color = '#F28E1D';
                break;
            case 'ASFGONEAS':
                data[idx].color = '#9F0000';
                break;
            case 'Ανέπαφες':
                data[idx].color = '#00245D';
                break;
            case 'Μη Ανέπαφες':
                data[idx].color = '#DE2402';
                break;
            default:
                data[idx].color = '#76777A';
        }
    });

    const decimalSeparator = locale === 'el' ? "," : ".";
    const thousandSeparator = locale === 'el' ? "." : ",";

    return {
        "type": "pie",
        "hideCredits": true,
        "startDuration": 0,
        "theme": "light",
        "dataProvider": data,
        "titleField": "title",
        "valueField": "value",
        "labelRadius": 3,
        "labelText": "[[title]]:\n[[value]]",
        "radius": "42%",
        "innerRadius": "90%",
        "labelsEnabled": false,
        "balloonText": "<span style='font-size:9px'><b>[[title]]:</b> [[value]] <i>([[percents]]%)</i></span>",
        "colorField": "color",
        "numberFormatter": {
            "precision": 2,
            "decimalSeparator": decimalSeparator,
            "thousandsSeparator": thousandSeparator
        },
        "export": exportOptions(chartTitle, filename, type)
    }
}

export function DebitNotesOptions(data, type, chartTitle, filename, locale) {
    const decimalSeparator = locale === 'el' ? "," : ".";
    const thousandSeparator = locale === 'el' ? "." : ",";

    const title = [{"text": chartTitle, "size": 11, "color": "#000000"}];
    const colors = ["#005596", "#9F0000", "#007E6B", "#F28E1D", "#5AC6FF", "#CC1F1F", "#322199", "#1E8839", "#00245D", "#DE2402"];

    return {
        "type": "pie",
        "hideCredits": true,
        "startDuration": 0,
        "theme": "light",
        "dataProvider": data,
        "titles": title,
        "titleField": "title",
        "valueField": "value",
        "labelRadius": 3,
        "labelText": "[[title]]:\n[[value]]",
        "radius": "42%",
        "innerRadius": "90%",
        "labelsEnabled": false,
        "balloonText": "<span style='font-size:9px'><b>[[title]]:</b> [[value]]%</span>",
        "colorField": null,
        "colors": colors,
        "numberFormatter": {
            "precision": 2,
            "decimalSeparator": decimalSeparator,
            "thousandsSeparator": thousandSeparator
        },
        "export": exportOptions(chartTitle, filename, type)
    }
}

function exportOptions(chartTitle, filename, type) {

    const onClickHandler = (format, chart, chartTitle, filename) => {
        chart.div.style.width = "600px";
        chart.div.style.height = "600px";
        chart.validateSize();
        chart.labelsEnabled = true;
        chart.radius = "25%";
        chart.innerRadius = "70%";
        chart.addLabel("50%","45%",chartTitle,"middle",11,"#595A5C");
        chart.validateNow();
        chart.export.capture({},function() {
            this["to"+format.toUpperCase()]( {}, function( data ) {
                format !== "print" && this.download( data, format.toLowerCase(), filename+"."+format.toLowerCase());
            });
        });
        chart.div.style.width = "220px";
        chart.div.style.height = "160px";
        chart.labelsEnabled = false;
        chart.radius = "42%";
        chart.innerRadius = "90%";
        chart.clearLabels();
        chart.validateNow();
    }

    return {
        "enabled": true,
        "exportFields": [
            "title",
            "value",
            "count"
        ],
        "menu": [
            {
                "class": "export-main",
                "format": "UNDEFINED",
                "label": "menu.label.undefined",
                "menu": [
                    {
                        "format": "UNDEFINED",
                        "label": "Download as ...",
                        "menu": [
                            {
                                "label": "PNG",
                                "click": function (a, b, chart = this.setup.chart) {
                                    onClickHandler("png", chart, chartTitle, filename)
                                }
                            }, {
                                "label": "JPG",
                                "click": function (a, b, chart = this.setup.chart) {
                                    onClickHandler("jpg", chart, chartTitle, filename)
                                }
                            }, {
                                "label": "SVG",
                                "click": function (a, b, chart = this.setup.chart) {
                                    onClickHandler("svg", chart, chartTitle, filename)
                                }
                            }, {
                                "label": "PDF",
                                "click": function (a, b, chart = this.setup.chart) {
                                    onClickHandler("pdf", chart, chartTitle, filename)
                                }
                            }
                        ]
                    }, {
                        "format": "UNDEFINED",
                        "label": "Save as ...",
                        "menu": [
                            {
                                "label": "CSV",
                                "click": function (a, b, chart = this.setup.chart) {
                                    onClickHandler("csv", chart, chartTitle, filename)
                                }
                            }, {
                                "label": "XLSX",
                                "click": function (a, b, chart = this.setup.chart) {
                                    onClickHandler("xlsx", chart, chartTitle, filename)
                                }
                            }, {
                                "label": "JSON",
                                "click": function (a, b, chart = this.setup.chart) {
                                    onClickHandler("json", chart, chartTitle, filename)
                                }
                            }
                        ]
                    }, {
                        "format": "PRINT",
                        "label": "Print",
                        "click": function (a, b, chart = this.setup.chart) {
                            onClickHandler("print", chart, chartTitle, filename)
                        }
                    }]
            }
        ],
        "columnNames": {
            "title": "Τράπεζες/Banks",
            "value": "Σύνολο Συναλλαγών (€)/Transactions' total amount (€)",
            "count": "Αριθμός Συναλλαγών/Transactions' Count"
        },
        "position": "top-left",
        "divId": "chart-export-btn__" + type,
        "fileName": filename,
        "creditsPosition": "top-left",


    }
}