import React from "react";
import {withRouter} from "react-router-dom";
import connect from "react-redux/es/connect/connect";
import store from "../../../store";
import {setRegistrationStep, setRegistrationStep2} from "../../../actions/registerAction";
import {merchantRegistrationValidate} from "../../../providers/rest";
import Loader from "../../common/Loader";
import {ShowHelp} from "../../common/ShowHelp";
import SealCropper from "../SealCrop/SealCropper";
import Logo from "../../common/Logo";

const stateMap = (store) => {
    return {
        i18n: store.i18n,
        registrationReducer: store.registrationReducer
    };
};

const Translate = require('react-redux-i18n').Translate;
const I18n = require('react-redux-i18n').I18n

class MerchantRegistrationStep2 extends React.Component {
    constructor(props) {
        super(props)
        this.state = {
            corpType: null,
            errorMessage: '',
            afmPlaceholder: I18n.t('pages.register.afmPlaceholder'),
            companyNamePlaceholder: I18n.t('pages.register.companyNamePlaceholder'),
            companyTitlePlaceholder: I18n.t('pages.register.companyTitlePlaceholder'),
            merchantIdPlaceholder: I18n.t('pages.register.merchantIdPlaceholder'),
            numberOfMids: 1,
            mid: props.mid ? props.mid: [],
            errorMid: props.errorMid ? props.errorMid : [],
            duplicatesMessage: '',
            disableAddBtn: false,
            logoImageBase64: null,
            logoImageBase64Cropped: null
        }
        this.handleChange = this.handleChange.bind(this)
        this.onImageUpload = this.onImageUpload.bind(this)
        this.setCroppedAndScaledImage = this.setCroppedAndScaledImage.bind(this)
        this.loopMoreMids = this.loopMoreMids.bind(this)
        this.renderMid = this.renderMid.bind(this)
        this.removeMid = this.removeMid.bind(this)
        this.validate = this.validate.bind(this)
        this.arrangeMid = this.arrangeMid.bind(this)
        this.compareArrays = this.compareArrays.bind(this)
        this.checkLastField = this.checkLastField.bind(this)
        this.handleRadioChange = this.handleRadioChange.bind(this)
    }

    handleChange(event) {
        let evt = event;

        this.setState({
            [event.target.id]: event.target.value,
            errorMessage: '',
            duplicatesMessage: ''
        }, ()=> {
            this.props.handleChange(evt)
        })
    }

    setCroppedAndScaledImage(imgSrc) {
        let base64str = imgSrc.substr(22);
        let decoded = atob(base64str);
        let isSize=this.props.checkImageSize(decoded.length);
        if (isSize) {
            this.setState({
                logoImageBase64Cropped: imgSrc
            })
        }
    }

    renderMid(midNumber) {
        return (
            <div className="registration-mid" key={'mid' + midNumber}>
                <span>
                    <Translate className="register__added-mid" value={"pages.register.mid"}/>
                    {" #" + midNumber}
                </span>
                <br/>
                <input type="text"
                       className={this.state.errorMid.indexOf(this.state['mid' + midNumber]) >= 0 ? 'register__error-mid': ''}
                       onChange={(event) => {
                           event.persist();
                           this.handleChange(event)
                       }}
                       placeholder={this.state.merchantIdPlaceholder}
                       maxLength="10"
                       id={"mid" + midNumber}
                       name={"mid" + midNumber}
                       value={this.state['mid' + midNumber]}
                       required={midNumber === 1}/>
            </div>
        )
    }

    removeMid() {
        this.props.removeMid(this.state.numberOfMids)
        this.setState({
            numberOfMids: this.state.numberOfMids - 1,
            ['mid' + this.state.numberOfMids ]: undefined,
            errorMessage: '',
            duplicatesMessage: ''
        }, ()=> {
            this.checkLastField()
        })
    }

    onImageUpload(image) {
        this.props.onImageUpload(image)
    }

    componentWillMount() {
        this.arrangeMid(this.props.mid)
        this.setState({
            errorMid: this.props.errorMid
        })
    }

    handleRadioChange(event) {
        this.props.handleRadioChange(event)
    }

    componentWillReceiveProps(nextProps) {
        if (this.props.logoFileName !== nextProps.logoFileName) {
            this.setState({
                logoFileName: nextProps.logoFileName
            })
        }
        if (this.props.logoImageBase64 !== nextProps.logoImageBase64) {
            this.setState({
                logoImageBase64: nextProps.logoImageBase64
            })
        }

        if (this.compareArrays(this.props.mid, nextProps.mid)) {
            this.arrangeMid(nextProps.mid)
        }

        if (this.compareArrays(this.props.errorMid, nextProps.errorMid)) {
            this.setState({
                errorMid: nextProps.errorMid
            })
        }
    }

    arrangeMid(mid) {
        this.setState({
            mid: mid,
            numberOfMids: mid.length
        }, ()=> {
            if (this.state.mid.length > 0) {
                for (let i = 0; i <= this.state.mid.length; i++) {
                    this.setState({
                        ['mid' +  (i + 1)]: this.state.mid[i],
                    }, () => {
                        this.checkLastField()
                    })
                }
            } else {
                this.checkLastField()
            }
            let findDuplicates = (arr) => arr.filter((item, index) => arr.indexOf(item) !== index);
            if (findDuplicates(this.state.mid).length > 0) {
                this.setState({
                    duplicatesMessage: I18n.t('pages.register.duplicateMid')
                })
            }

        })
    }

    compareArrays(arr1, arr2) {
        return arr1.length !== arr2.length || JSON.stringify(arr1) !== JSON.stringify(arr2)
    }

    checkLastField() {
        this.setState({
            disableAddBtn: !this.state['mid' + (this.state.numberOfMids).toString()]
        })
    }

    loopMoreMids() {
        let mid = [];
        for (let i = 2; i <= this.state.numberOfMids; i++) {
            mid.push(this.renderMid(i))
        }
        return mid;
    }

    validate(data) {
        store.dispatch(merchantRegistrationValidate(data)).then((response) => {
                data.step++;
                store.dispatch(setRegistrationStep2(data));
                this.props.handleWrongMids([]);
            }, () => {
                this.setState({
                    errorMessage: I18n.t('pages.register.inputError')
                })
            }
        )
    }

    render() {
        const disableNextButton = !this.props.corpType || !this.props.afm || !this.props.merchantNameLatin || !this.props.merchantTitleLatin ||
            !this.props.mid || this.props.mid.length === 0 || this.props.mid.every(el => el === '') ||
            !this.state.logoImageBase64Cropped || !this.props.logoFileName || this.state.duplicatesMessage !== '';
        const validationInProgress = this.props.registrationReducer &&
            this.props.registrationReducer.validateMerchantRegistration &&
            this.props.registrationReducer.validateMerchantRegistration.fetching
        return (
            <div className="register-merchant-step2__content">
                <div className="register-merchant-step2__left">
                    <p className="register-merchant-step2__sub-header">
                        <Translate value="pages.register.registerMerchant"/>
                    </p>
                    <p className="register-merchant-step2__sub-header">
                        <b> <Translate value="pages.register.step2"/> </b>
                    </p>
                    <p className="register-merchant-step2__text register-merchant-step2__text_intro">
                        <Translate value="pages.register.subTitle2"/>
                        <br/>
                        <br/>
                        <Translate value="pages.register.subTitle2b"/>
                    </p>
                </div>
                <div className="register-merchant-step2__login-with-forgot">
                    <div className="register-merchant-step2__login-box">
                        <Logo isMain={false} isHeader={false} imgClassName="login"/>
                        <form onSubmit={(e) => {
                            e.preventDefault();
                            let data = {
                                ...this.props.registrationReducer,
                                corpType: this.props.corpType,
                                afm: this.props.afm,
                                merchantNameLatin: this.props.merchantNameLatin,
                                merchantTitleLatin: this.props.merchantTitleLatin,
                                mid: this.state.mid.filter(Boolean).join(),
                                logoImageBase64: this.state.logoImageBase64Cropped,
                                logoFileName: this.props.logoFileName,
                            }
                            if (this.props.corpType !== null) {
                                this.validate(data)
                            } else {
                                this.setState({
                                    errorMessage: I18n.t('pages.register.corpTypeError')
                                })
                            }
                        }}>
                            <h2 className="register-merchant-title">
                                <Translate value="pages.register.title2"/>
                            </h2>
                            <Translate value="pages.register.corpType"/>
                            <section className="register-merchant-radius-section">
                                <div className="register-merchant-radius--corp">
                                    <input
                                        id="radio1"
                                        name="corpType"
                                        type="radio"
                                        onChange={this.handleRadioChange}
                                        className="register-radio-btn"
                                        value="fa"
                                        checked={this.props.corpType === 'fa'} required={true} onClick={(e) => {
                                        this.setState({
                                            corpType: 'fa'
                                        })
                                    }}/>
                                    <label htmlFor="radio1">
                                        <Translate value="pages.register.fa"/>
                                    </label>
                                </div>
                                <div className="register-merchant-radius--corp">
                                    <input
                                        id="radio2"
                                        name="corpType"
                                        type="radio"
                                        onChange={this.handleRadioChange}
                                        className="register-radio-btn"
                                        value="co"
                                        checked={this.props.corpType === 'co'} required={true} onClick={(e) => {
                                        this.setState({
                                            corpType: 'co'
                                        })
                                    }}/>
                                    <label htmlFor="radio2">
                                        <Translate value="pages.register.co"/>
                                    </label>
                                </div>
                            </section>
                            <input type="text" required={true}
                                   maxLength="9"
                                   onChange={this.props.handleChange}
                                   placeholder={this.state.afmPlaceholder} size="40"
                                   id="afm" name="afm" value={this.props.afm}/>
                            <input type="text" required={true}
                                   onChange={this.props.handleChange}
                                   placeholder={this.state.companyNamePlaceholder} size="40"
                                   id="merchantNameLatin" name="merchantNameLatin"
                                   value={this.props.merchantNameLatin}/>
                            <input type="text" required={true}
                                   onChange={this.props.handleChange}
                                   placeholder={this.state.companyTitlePlaceholder} size="40"
                                   id="merchantTitleLatin" name="merchantTitleLatin"
                                   value={this.props.merchantTitleLatin}/>
                            <div className="register__add-remove-element__mids">
                                {this.renderMid(1)}
                                {this.state.numberOfMids > 1 ? this.loopMoreMids()
                                    : null}
                            </div>
                            <div className="register__add-remove-element__container">
                                {this.state.numberOfMids < 5 ?
                                    <button className={"register__add-remove-element register__add-remove-element__add" +
                                    (this.state.disableAddBtn ? " add--disabled": "")}
                                            disabled={this.state.disableAddBtn}
                                            onClick={(e) => {
                                                e.preventDefault()
                                                this.setState({
                                                    numberOfMids: this.state.numberOfMids + 1,
                                                    disableAddBtn: true
                                                })
                                            }}>
                                        <span className="icon-circled-plus"></span>
                                        <Translate value="pages.register.addMerchantId"/>
                                    </button>
                                    : null}
                                {this.state.numberOfMids >= 2 ?
                                    <button className="register__add-remove-element register__add-remove-element__remove"
                                            onClick={(e) => {
                                                e.preventDefault()
                                                this.removeMid()
                                            }}>
                                        <Translate value="pages.register.removeMerchantId"/>
                                        <span className="icon-circled-minus"></span>
                                    </button>
                                    : null}
                            </div>
                            <div className="upload-register-seal">
                                <input type="file" name="logoImageBase64" id="logoImageBase64" multiple="false"
                                       accept={"image/*"}
                                       onChange={this.onImageUpload}/>
                                <label htmlFor="logoImageBase64">
                                    <span className="icon-paperclip"></span>
                                    <Translate value="pages.register.addSeal"/>
                                </label>
                                <div className="seal-file">
                                    <span className="seal-file__attachment"
                                          style={{display: this.props.logoFileName ? "inline-block" : "none"}}
                                    >
                                        <Translate className="seal-file__label" value="pages.register.fileName" /> <br/>
                                        <span className="seal-file__name">
                                            {this.props.logoFileName}
                                        </span>
                                   </span>
                                    <SealCropper imageUploaded={this.props.logoImageBase64}
                                                 setCroppedAndScaledImage={this.setCroppedAndScaledImage}
                                    />
                                </div>
                                {this.props.imageError && this.props.imageError !== '' ?
                                    <div className="generic-error-msg seal-error">
                                        <span className="icon-icon-attention"/>
                                        <div>{this.props.imageError}</div>
                                    </div> : null
                                }
                            </div>
                            {this.state.errorMessage !== '' ?
                                <div className="login-area__login-box__wrong-creds">
                                    <div className="generic-error-msg">
                                        <span className="icon-icon-attention"/>
                                        <div>{this.state.errorMessage}</div>
                                    </div>
                                </div>:null
                            }
                            {this.state.duplicatesMessage !== '' ?
                                <div className="login-area__login-box__wrong-creds">
                                    <div className="generic-error-msg">
                                        <span className="icon-icon-attention"/>
                                        <div>{this.state.duplicatesMessage}</div>
                                    </div>
                                </div>:null
                            }
                            <div className="register-merchant-step2__login-button-area__wrapper">
                                <div className="register-merchant-step2__login-button-area">
                                    <button className="register-merchant-step2__login-button register__button" onClick={(e) => {
                                        store.dispatch(setRegistrationStep({
                                            step: this.props.step - 1
                                        }))
                                    }}>
                                        <Translate value="pages.register.backButton"/>
                                    </button>
                                </div>
                                <div className="register-merchant-step2__login-button-area">
                                    <button type="submit" className="register-merchant-step2__login-button register__button"
                                            disabled={validationInProgress || disableNextButton}>
                                        {validationInProgress ?
                                            <Loader bouncing={false} color={'white'} message=''
                                                    style={{marginTop: 0}} bounceClass='smaller-bounces'/> :
                                            <Translate value="pages.register.submitButton"/>
                                        }
                                    </button>
                                </div>
                            </div>
                            <ShowHelp/>
                        </form>
                    </div>
                </div>
            </div>
        )
    }
}

export default withRouter(connect(stateMap)(MerchantRegistrationStep2));