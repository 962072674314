import React from "react";
import {DateTimePicker} from 'react-widgets';
import 'react-widgets/dist/css/react-widgets.css';
import {connect} from "react-redux";
import moment from "moment";
import momentLocalizer from 'react-widgets-moment';
import {checkDateIsTillToday, checkDateValidity} from "../../lib/utils";
import store from "../../store";
import {toggleSearchTransactionsPopover} from "../../actions/userActions";
import AmountRange from "./AmountRange";

const Translate = require('react-redux-i18n').Translate;

const I18n = require('react-redux-i18n').I18n

const _ = require('underscore')

const stateMap = (store) => {
    return {
        i18n: store.i18n,
        userReducer: store.userReducer,
        merchantReducer: store.merchantReducer
    };
};

class InstallmentPopoverSearch extends React.Component {
    constructor(props) {
        super(props)

        this.state = {
            fromPlaceholder: I18n.t("pages.transactions.searchModal.midColumn.from"),
            toPlaceholder: I18n.t("pages.transactions.searchModal.midColumn.to"),
            transactionDateFrom: this.props.data.dateFrom ? moment(this.props.data.dateFrom, 'DD/MM/YYYY').toDate() : moment().toDate(),
            transactionDateTo: this.props.data.dateTo ? moment(this.props.data.dateTo, 'DD/MM/YYYY').toDate() : moment().toDate(),
            amountFrom: this.props.data.amountFrom || this.props.data.amountFrom === 0 ? this.props.data.amountFrom : '',
            amountTo: this.props.data.amountTo || this.props.data.amountTo === 0 ? this.props.data.amountTo : '',
            moreCriteria: false,
            singleAfmMid: this.props.userReducer.getAssociationsResponse.merchantType === 'singleAfmMid',
            maxDateRange: parseInt(process.env.SEARCH_LIMIT_DAYS_SMALL),
        };

        this.handleChange = this.handleChange.bind(this)
        this.handleFormattedChange = this.handleFormattedChange.bind(this)
        this.resetSearchToDefault = this.resetSearchToDefault.bind(this)
        this.changeDateFrom = this.changeDateFrom.bind(this)
        this.changeDateTo = this.changeDateTo.bind(this)
        this.checkDateChangeRestrictions = this.checkDateChangeRestrictions.bind(this)
        this.nullifyAmountToIfAmountFromGreater = this.nullifyAmountToIfAmountFromGreater.bind(this)
        this.nullifyAmountToIfItIs0 = this.nullifyAmountToIfItIs0.bind(this)
    }

    resetSearchToDefault() {
        this.setState({
            transactionDateFrom: moment().toDate(),
            transactionDateTo: moment().toDate(),
            amountFrom: '',
            amountTo: ''
        })
    }


    componentWillReceiveProps(nextProps) {
        if (nextProps.i18n.locale !== this.props.i18n.locale) {
            this.setState({
                fromPlaceholder: I18n.t("pages.transactions.searchModal.midColumn.from"),
                toPlaceholder: I18n.t("pages.transactions.searchModal.midColumn.to")
            })
        }

        if (nextProps.isToggleOn !== this.props.isToggleOn && !nextProps.isToggleOn) {
            this.props.overlayRef.hide();
            store.dispatch(toggleSearchTransactionsPopover(false));
        }
    }

    handleChange(event) {
        // Required to handle the React-Select dropdown where id is the name of the state variable used
        // Also have been made compatible with dropdown picks
        if (event.object) {
            if (event.id.startsWith("transactionProgress")) {
                this.setState({
                    packageStatus: event.object.value
                });
            } else {
                this.setState({
                    [event.id]: event.object.value,
                    [event.label]: event.object.label
                })
            }
        } else if (event.id) {
            this.setState({
                [event.id]: event.value
            })
        } else {
            this.setState({
                [event.target.id]: event.target.value
            })
        }
    }

    handleFormattedChange(obj, ev) {
        this.setState({
            [ev.target.id]: obj.floatValue
        })
    }

    changeDateFrom(transactionDateFrom) {
        let days = this.state.maxDateRange;
        if (this.checkDateChangeRestrictions(transactionDateFrom)) {
            const dateFromPlusXDays = moment(transactionDateFrom).add(days, 'days').toDate()
            let dateTo = moment(this.state.transactionDateTo).toDate()
            if (moment(dateFromPlusXDays).isBefore(moment(dateTo))) {
                dateTo = moment(transactionDateFrom).add(days, 'days').toDate()
            } else if (moment(transactionDateFrom).isAfter(moment(this.state.transactionDateTo))) {
                dateTo = moment(transactionDateFrom).toDate()
            }
            this.setState({
                transactionDateFrom: transactionDateFrom,
                transactionDateTo: dateTo
            })
        } else {
            this.setState({
                transactionDateFrom: moment().subtract(days, 'days').toDate()
            })
        }
    }

    changeDateTo(transactionDateTo) {
        let days = this.state.maxDateRange;
        if (this.checkDateChangeRestrictions(transactionDateTo)) {
            let dateFrom = moment(this.state.transactionDateFrom).toDate()
            const dateFromPlusXDays = moment(this.state.transactionDateFrom).add(days, 'days')
            if (moment(transactionDateTo).isAfter(dateFromPlusXDays)) {
                dateFrom = moment(transactionDateTo).subtract(days, 'days').toDate()
            } else if (moment(transactionDateTo).isBefore(moment(this.state.transactionDateFrom))) {
                dateFrom = moment(transactionDateTo).toDate()
            }
            this.setState({
                transactionDateFrom: dateFrom,
                transactionDateTo: transactionDateTo
            })
        } else {
            this.setState({
                transactionDateTo: moment().toDate()
            })
        }
    }

    checkDateChangeRestrictions(date) {
        return checkDateValidity(date) && checkDateIsTillToday(date)
    }

    nullifyAmountToIfAmountFromGreater(amountFrom, amountTo) {
        if (amountFrom > amountTo) {
            amountTo = undefined
        }

        return {amountFrom: amountFrom, amountTo: amountTo}
    }

    nullifyAmountToIfItIs0(amountTo) {
        return amountTo === 0 ? undefined : amountTo
    }


    render() {
        const baseTranslate = "pages.transactions.searchModal";
        const currency = "€";

        moment.locale('en-GB')
        momentLocalizer();
        return (
            <div className="overall-search-modal">
                <button className="overall-search-modal__button-container__reset-link"
                        onClick={this.resetSearchToDefault}>
                    <Translate value="pages.transactions.searchModal.labels.clear"/>
                </button>
                <form onSubmit={(e) => {
                    e.preventDefault();
                    let {amountFrom, amountTo} = this.nullifyAmountToIfAmountFromGreater(this.state.amountFrom,
                        this.state.amountTo)
                    amountTo = this.nullifyAmountToIfItIs0(amountTo)
                    this.props.getTransactions(null, this.state.transactionDateFrom, this.state.transactionDateTo,
                      amountFrom, amountTo, 1, this.props.rowsPerPage, true, undefined, undefined);
                    this.props.overlayRef.hide();
                    this.props.resetPopover();
                }}>
                    <div className="search-modal">
                        <div className="search-modal__left-column-upper search-modal__column">
                            <Translate value={baseTranslate + ".leftColumn.timePeriod"}/>
                            {/*Transaction Date From*/}
                            <DateTimePicker value={this.state.transactionDateFrom}
                                            time={false}
                                            onChange={transactionDateFrom => {
                                                this.changeDateFrom(transactionDateFrom)
                                            }}
                                            max={moment().toDate()}/>
                            {/*Transaction Date To*/}
                            <DateTimePicker value={this.state.transactionDateTo}
                                            time={false}
                                            onChange={transactionDateTo => {
                                                this.changeDateTo(transactionDateTo)
                                            }}
                                            max={moment().toDate()}
                            />
                            <AmountRange
                                baseTranslate={baseTranslate}
                                handleFormattedChange={this.handleFormattedChange}
                                fromPlaceholder={this.state.fromPlaceholder}
                                toPlaceholder={this.state.toPlaceholder}
                                amountFrom={this.state.amountFrom}
                                amountTo={this.state.amountTo}
                                currency={currency}
                                clsType={'left'}
                            />
                        </div>
                    </div>
                    <div className="overall-search-modal__button-container">
                        <button type="submit" className="overall-search-modal__search-button">
                            <Translate value="pages.transactions.searchModal.labels.opened"/>
                        </button>
                    </div>
                </form>
            </div>
        )
    }
}

export default connect(stateMap)(InstallmentPopoverSearch);
