import React from 'react';
import RightColumn from "../mainFrame/RightColumn";
import store from "../../store";
import connect from "react-redux/es/connect/connect";
import moment from "moment";
import RedemptionCharts from "../charts/RedemptionCharts";
import TransactionTypesChart from "../charts/TransactionTypesChart";
import {getMap} from "../../providers/rest";
import TotalTransactionsChart from "../charts/TotalTransactionsChart";
import BankTotalsChart from "../charts/BankTotalsChart";
import CardTotalsChart from "../charts/CardTotalsChart";
import SettledAndApprovedChart from "../charts/SettledAndApprovedChart";
import TransactionsWithInstallmentsChart from "../charts/TransactionsWithInstallmentsChart";
import {toggleSearchTransactionsPopover} from "../../actions/userActions";
import Panel from "react-bootstrap/es/Panel";
import EmptyStateShape from "../common/EmptyStateShape";
import BankTotals from "./BankTotals";
import Loader from "../common/Loader";

const Translate = require('react-redux-i18n').Translate;

const stateMap = (store) => {
    return {
        i18n: store.i18n,
        userReducer: store.userReducer,
        merchantReducer: store.merchantReducer,
    };
};

class Reports extends React.Component {

    constructor(props) {
        super(props)
        let afms = []
        props.userReducer.getAssociationsResponse.uniqueAfmsWithTitles.forEach((afmData) => {
            afms.push(afmData.afm)
        })
        this.state = {
            chartMaps: null,
            afm: props.merchantReducer.afm ? props.merchantReducer.afm : afms.join(),
            afms: props.userReducer.getAssociationsResponse.uniqueAfmsWithTitles,
            mid: props.merchantReducer.midsCommaSeparated ? props.merchantReducer.midsCommaSeparated : undefined,
            showRedemption: false,
            showInstallments: false,
            bankTotalsOpen: false,
            loaderSquircleIsActive: false
        }
        this.getChartMaps = this.getChartMaps.bind(this)
        this.renderDates = this.renderDates.bind(this)
        this.triggerLoader = this.triggerLoader.bind(this)
        // this.checkRedemptionAndInstallments = this.checkRedemptionAndInstallments.bind(this)
    }

    componentWillMount() {
        this.getChartMaps();
        // this.checkRedemptionAndInstallments();
        store.dispatch(toggleSearchTransactionsPopover(false));
    }

    componentWillReceiveProps(nextProps) {
        if (this.props.merchantReducer !== nextProps.merchantReducer) {
            this.setState({
                afm: nextProps.merchantReducer.afm,
                mid: nextProps.merchantReducer.midsCommaSeparated ? nextProps.merchantReducer.midsCommaSeparated : undefined,
            })
        }
        if (nextProps.merchantReducer.afm === undefined || nextProps.merchantReducer.afm === '') {
            let afms = [];
            nextProps.userReducer.getAssociationsResponse.uniqueAfmsWithTitles.forEach((afmData) => {
                afms.push(afmData.afm)
            });
            this.setState({
                afm: afms.join()
            })
        }
    }

    getChartMaps() {
        store.dispatch(getMap('chartMaps')).then((response) => {
            this.setState({
                chartMaps: response.value.data
            })
        })
    }


    renderDates(dateFrom, dateTo, className) {
        return (
            <div className={className ? className : ''}>
                <span>{moment(dateFrom).format('DD/MM/YYYY')}</span>
                <span> - </span>
                <span>{moment(dateTo).format('DD/MM/YYYY')}</span>
            </div>
        )
    }

    triggerLoader() {
        this.setState({
            loaderSquircleIsActive: true
        }, () => {
            setTimeout(() => {
                this.setState({
                    loaderSquircleIsActive: false
                });
            }, 5000)
        });
    }

    //Not usable for now
    /*checkRedemptionAndInstallments() {
        const data = this.props.merchantReducer.merchantTree.data ? this.props.merchantReducer.merchantTree.data.slice(0) : null
        if (data === null)
            return;
        for (let afm in data) {
            let afms = data[afm].midData
            for (let mid in afms) {
                let mids = afms[mid].tidData
                for (let tid in mids) {
                    let tids = mids[tid].tidBankData
                    for (let bank in tids) {
                        if (tids[bank].t_loyaltyFlag) {
                            this.setState({
                                showRedemption: true
                            })
                        }
                        if (tids[bank].t_installmentsFlag) {
                            this.setState({
                                showInstallments: true
                            })
                        }
                    }
                }
            }
        }
    }*/

    render() {
        return (
            this.props.userReducer.getAssociationsResponse.uniqueAfmsWithTitles.length > 0 ?
                <main className="main-page reports-page">
                    <div className="main-content">
                        <section className='reports__transactions row'>
                            <TotalTransactionsChart
                                key="TotalTransactionsChart"
                                afm={this.state.afm}
                                mid={this.state.mid}
                                afms={this.state.afms}
                                renderDates={this.renderDates}
                            />
                        </section>
                        <section className='reports__bank-cards row'>
                            <div className="reports__bank--chart_wrapper col-md-5 col-sm-12 col- col-xs-12">
                                {this.state.chartMaps !== null ?
                                    <BankTotalsChart
                                        key="BankTotalsChart"
                                        afm={this.state.afm}
                                        mid={this.state.mid}
                                        renderDates={this.renderDates}
                                        chartMaps={this.state.chartMaps}
                                    /> : null}
                            </div>
                            <div className="reports__card--chart_wrapper col-md-7 col-sm-12 col-xs-12">
                                {this.state.chartMaps !== null ? [
                                    <CardTotalsChart
                                        key="CardTotalsChart"
                                        afm={this.state.afm}
                                        mid={this.state.mid}
                                        renderDates={this.renderDates}
                                        chartMaps={this.state.chartMaps}/>,
                                    <SettledAndApprovedChart
                                        key="SettledAndApprovedChart"
                                        afm={this.state.afm}
                                        mid={this.state.mid}
                                        renderDates={this.renderDates}
                                        chartMaps={this.state.chartMaps}/>] : null}
                            </div>
                        </section>
                        <section className='reports__transaction-types row'>
                            <div className="reports__transaction-types--chart_wrapper col-md-12 col-sm-12 col-xs-12">
                                <div className="reports__transaction-types--chart">
                                    {this.state.chartMaps !== null ?
                                        <TransactionTypesChart
                                            key="TransactionTypesChart"
                                            transactionTypesMap={this.state.chartMaps.transactionTypes}
                                            afm={this.state.afm}
                                            mid={this.state.mid}
                                            renderDates={this.renderDates}
                                        />
                                        : null}
                                </div>
                            </div>
                        </section>
                        <Panel id="collapsible-panel-bank-totals"
                               defaultExpanded={false}
                               expanded={this.state.bankTotalsOpen}
                               onToggle={()=>{
                                   this.setState({ bankTotalsOpen: !this.state.bankTotalsOpen})
                               }}

                        >
                            <Panel.Heading>
                                <Panel.Toggle componentClass="h4">
                                    <div className="company-elements__title__wrapper">
                                        <Translate value="pages.reports.titles.bankTotals"/>
                                    </div>
                                    <i className="icon-plus-accordion"></i>
                                    <i className="icon-minus-accordion"></i>
                                </Panel.Toggle>
                            </Panel.Heading>
                            <Panel.Collapse>
                                <Panel.Body>
                                    <BankTotals isPanelOpen={this.state.bankTotalsOpen} />
                                </Panel.Body>
                            </Panel.Collapse>
                        </Panel>
                        <Panel id="collapsible-panel-installments-chart" defaultExpanded={false}>
                            <Panel.Heading>
                                <Panel.Toggle componentClass="h4">
                                    <div className="company-elements__title__wrapper">
                                        <Translate value="pages.reports.titles.installmentsTotals"/>
                                    </div>
                                    <i className="icon-plus-accordion"></i>
                                    <i className="icon-minus-accordion"></i>
                                </Panel.Toggle>
                            </Panel.Heading>
                            <Panel.Collapse>
                                <Panel.Body>
                                    {this.state.chartMaps !== null ?
                                        <TransactionsWithInstallmentsChart
                                            key="TransactionsWithInstallmentsChart"
                                            afm={this.state.afm}
                                            mid={this.state.mid}
                                            chartMaps={this.state.chartMaps}
                                            renderDates={this.renderDates}
                                        /> : null}
                                </Panel.Body>
                            </Panel.Collapse>
                        </Panel>
                        <Panel id="collapsible-panel-redemption-chart" defaultExpanded={false}>
                            <Panel.Heading>
                                <Panel.Toggle componentClass="h4">
                                    <div className="company-elements__title__wrapper">
                                        <Translate value="pages.reports.titles.redemption"/>
                                    </div>
                                    <i className="icon-plus-accordion"></i>
                                    <i className="icon-minus-accordion"></i>
                                </Panel.Toggle>
                            </Panel.Heading>
                            <Panel.Collapse>
                                <Panel.Body>
                                    {this.state.chartMaps !== null ?
                                        <RedemptionCharts
                                            key="RedemptionCharts"
                                            afm={this.state.afm}
                                            mid={this.state.mid}
                                            renderDates={this.renderDates}
                                            chartMaps={this.state.chartMaps}
                                        />
                                        : null}
                                </Panel.Body>
                            </Panel.Collapse>
                        </Panel>
                    </div>
                    <RightColumn type="downloadReports" triggerLoader = {this.triggerLoader}/>
                    {this.state.loaderSquircleIsActive ?
                        <Loader bouncing={true}
                                color={'cerulean'}
                                loaderStyles={{minHeight: "100vh", margin: "0"}}
                                message='loader.trxEmail'
                                subMessage='loader.trxSubMessage'
                                style={{top: "115px"}}
                                overlayClass="is-overlay"/> : null
                    }
                </main> :
                <div className="no-pos__wrapper">
                    <EmptyStateShape title="common.noPosInAccount"/>
                </div>
        )
    }
}

export default connect(stateMap)(Reports);
