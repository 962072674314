import {screenLimit} from "../../../lib/utils";

export default function Options(data, numberOfGraphs, graphTitles, graphValues, types, exportBtnId, filename, locale) {
    const graphs = [];
    let listeners = [];
    let isRegular = types.stackType === "regular";
    const percentage = types.stackType === '100%' ? '%' : '';
    const euroSign = (!types.integersOnly && types.stackType !== '100%') ? " € " : '';
    let colors = isRegular ? [
        "#007DC3",
        "#F47B20",
        "#7D4199",
        "#00245D",
        "#5AC6FF",
        "#FF5500",
        "#FFBA00",
        "#0189A2",
        "#66BF25",
        "#CE3D8B",
        "#7B5858",
        "#25A097",
        "#acacac"
    ] : ["#7D4199", "#F47B20"];

    const decimalSeparator = locale === 'el' ? "," : ".";
    const thousandSeparator = locale === 'el' ? "." : ",";

    for (let i = 1; i <= numberOfGraphs; i++) {
        let graph = {
            "id": 'id_' + i,
            "balloonText": "[[title]]:" + (euroSign ? euroSign: "") + "[[value]]" + (percentage ? percentage: ""),
            "labelText": "[[title]]",
            "labelPosition": "inside",
            "labelFunction": function(data, val) {
                if (data.values.value > 0) {
                    return val
                }
            },
            "fillColors": colors[i - 1],
            "color": "#fff",
            "fillAlphas": 1,
            "lineAlpha": 0.2,
            "title": graphTitles['title' + i],
            "type": "column",
            "valueField": graphValues['value' + i],
            "visibleInLegend": i === 1,
            "legendColor": '#007DC3',
            "fixedColumnWidth": 16,
            "showAllValueLabels": true
        };
        graphs.push(graph)
    }

    if (types.restOfTransactions !== undefined) {
        graphs.push({
            "title": types.restOfTransactions,
            "id": "restOfTransactions",
            "legendValueText": " ",
            "legendPeriodValueText": " ",
            "legendColor": '#F47B20',
            "fixedColumnWidth": 17
        })
        listeners = [
            {"event": "hideItem", "method": legendHandler},
            {"event": "showItem", "method": legendHandler}
        ]
    }


    function legendHandler(evt) {
        if (evt.dataItem.id === "restOfTransactions") {
            for (let i1 in evt.chart.graphs) {
                if (evt.chart.graphs[i1].id !== "restOfTransactions" && evt.chart.graphs[i1].id !== 'id_1') {
                    evt.chart[evt.dataItem.hidden ? "hideGraph" : "showGraph"](evt.chart.graphs[i1]);
                }
            }
        }
    }

    data = data.filter(function( obj ) {
        return obj.month !== 'allMonths';
    });

    return {
        "type": "serial",
        "language": locale,
        "hideCredits": true,
        "theme": "light",
        "categoryField": "month",
        "rotate": true,
        "startDuration": 1,
        "mouseWheelZoomEnabled": true,
        "usePrefixes": true,
        "chartScrollbar": {
            "enabled": isRegular,
            "updateOnReleaseOnly": false,
            "oppositeAxis": true,
            "offset": screenLimit ? 65 : 0,
            "position": "left",
            "dragIcon": "dragIconRectSmall",
            "dragIconWidth" : 20,
            "dragIconHeight" : 20,
            "scrollbarHeight": 10,
            "backgroundColor": "#D9D9D9",
            "selectedBackgroundColor": "#76777A",
        },
        "numberFormatter": {
            "precision": types.integersOnly ? -1 : 2,
            "decimalSeparator": decimalSeparator,
            "thousandsSeparator": thousandSeparator

        },
        "categoryAxis": {
            "gridThickness": 0,
            "axisAlpha": 0,
            "fillAlpha": 0,
            "axisThickness": 0,
            "gridAlpha": 0,
            "centerLabels": true
        },
        "columnSpacing": 0,
        "graphs": [
            ...graphs],
        "valueAxes": [{
            "stackType": types.stackType,
            "position": "bottom",
            "gridThickness": 0,
            "axisAlpha": 0,
            "fillAlpha": 0,
            "axisThickness": 0,
            "gridAlpha": 0,
            "labelsEnabled": types.hasLabelsEnabled
        },
        {
            "stackType": "regular",
            "position": "bottom",
            "labelsEnabled": true
        }],
        "legend": {
            "enabled": types.hasLegend,
            "switchable": types.hasLegend,
            "listeners": listeners,
            "switchType": "v",
            "equalWidths": false,
            "autoMargins": !screenLimit,
            "forceWidth": true,
            "marginLeft": screenLimit ? 75 : 0
        },
        "balloon": {
            "fixedPosition": false,
        },
        "export": {
            "enabled": isRegular,
            "columnNames": {
                "month": "Ημερομηνία/Date",
                "PREAUTH_amount": "Ποσό Προέγκρισης/Amount of Pre-approval",
                "PREAUTH_count": "Αριθμός Συναλλαγών Προέγκρισης/Number of Pre-approval Transactions",
                "REFUND_amount": "Ποσό Επιστροφής/Amount of return",
                "REFUND_count": "Επιστροφές/Returns",
                "PURCHASE_amount": "Ποσό Αγοράς/Market Amount",
                "PURCHASE_count": "Αριθμός Συναλλαγών Αγοράς/Number of Market Transactions",
                "PREAUTH_COMPLETION_amount": "Ποσό Ολοκλήρωσης Προέγκρισης/Amount of Pre-approval Completion",
                "PREAUTH_COMPLETION_count": "Αριθμός Συναλλαγών Προέγκρισης/Number of Pre-approval Transactions"
            },
            "position": "top-left",
            "divId": "chart-export-btn__" + exportBtnId,
            "fileName": filename
        },
        "integersOnly": types.integersOnly,
        "dataProvider": data,
        "creditsPosition": "top-left",
    }
}