export default function debitNotesReducer(state = {
    newDebitNotes: false,
}, action) {
    switch (action.type) {
        case "NEW_DEBIT_NOTES": {
            return {
                ...state,
                newDebitNotes: action.newDebitNotes
            }
        }
        default : {
            return state
        }
    }
}