import React from "react";
import {DateTimePicker} from "react-widgets";
import moment from "moment";
import momentLocalizer from 'react-widgets-moment';
import SearchBox from "../common/SearchBox";
import {withRouter} from "react-router-dom";
import connect from "react-redux/es/connect/connect";
import Loader from "../common/Loader";
import {checkDateValidity, date1OlderOrEqualToDate2} from "../../lib/utils";

const Translate = require('react-redux-i18n').Translate;
const I18n = require('react-redux-i18n').I18n

const stateMap = (store) => {
    return {
        merchantReducer: store.merchantReducer,
        userReducer: store.userReducer
    };
};

class SearchBankTotals extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            dateFrom: moment().subtract(1, 'month').toDate(),
            dateTo: moment().toDate(),
        }
        this.handleDateChange = this.handleDateChange.bind(this)
    }

    handleDateChange(dateFrom, dateTo) {
        if (checkDateValidity(dateFrom) && date1OlderOrEqualToDate2(dateFrom, dateTo)) {
            this.setState({
                dateFrom: dateFrom,
                dateTo: dateTo,
            }, () => {
                this.props.changeDate(dateFrom, dateTo)
            })
        }
    }

    render() {
        moment.locale('en-GB')
        momentLocalizer();
        return (
            <div>
                <div className="bank-totals-search">
                    <div className="bank-totals-search__row">
                        <div className="bank-totals-search__column">
                            <Translate value={"pages.bankTotals.searchModal.labels.timePeriod"}/>
                            <div className="datetimepickers-wrapper">
                                <DateTimePicker defaultValue={this.state.dateFrom}
                                                time={false}
                                                onSelect={dateFrom => this.handleDateChange(dateFrom, this.state.dateTo)}
                                                min={moment().subtract(1, 'year').toDate()}
                                                max={this.state.dateTo}/>
                                <DateTimePicker defaultValue={this.state.dateTo}
                                                time={false}
                                                onSelect={dateTo => this.handleDateChange(this.state.dateFrom, dateTo)}
                                                min={this.state.dateFrom}
                                                max={moment().toDate()}/>
                            </div>
                        </div>
                        <div className="bank-totals-search__column">
                            <Translate value={"pages.bankTotals.searchModal.labels.merchantLabel"}/>
                            <input id="allMidsBankTotals" type="checkbox" checked={this.props.isAllMids}
                                   onChange={this.props.handleInputChange}/>
                            <label htmlFor="allMidsBankTotals">
                                <Translate value='pages.bankTotals.searchModal.labels.midsCheckbox'/>
                                <span className="checkmark"></span>
                            </label>
                        </div>
                        <div className="bank-totals-search__column">
                            {this.props.merchantReducer.getTidsGroupedByMid.fetching === true && !this.props.isAllMids ?
                                <Loader bouncing={false} color={'bahama-blue'} message=''/> :
                                <SearchBox
                                    isMulti={true}
                                    options={this.props.midsOption}
                                    value={this.props.selectedMids}
                                    clsName="bank-totals-multi-mids"
                                    onChange={(event) => {
                                        this.props.changeMerchantId(event)
                                    }}
                                    placeholder={I18n.t('pages.business.modal.midTitle')}
                                    isDisabled={this.props.isAllMids}
                                />
                            }
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}

export default withRouter(connect(stateMap)(SearchBankTotals));