import React from "react";

const Translate = require('react-redux-i18n').Translate
const I18n = require('react-redux-i18n').I18n

export default class ErrorBoundary extends React.Component {
    constructor(props) {
        super(props);
        this.state = {hasError: false};
    }

    componentDidCatch(error, info) {
        this.setState({hasError: true});
    }

    render() {
        if (this.state.hasError) {
            return (
                <div>
                    <div className="error-page">
                        <div>
                            <h1 data-h1={I18n.t('errorBoundary.title1')}><Translate value='errorBoundary.title1'/></h1>
                            <p data-p={I18n.t('errorBoundary.title2')}>
                                <Translate value='errorBoundary.title2'/>
                            </p>
                        </div>
                    </div>
                    <div id="particles-js"></div>
                </div>
            )
        }
        return this.props.children;
    }
}