import React, { Component } from "react";
import Route from "react-router-dom/es/Route";
import Redirect from "react-router-dom/es/Redirect";

class PrivateRoute extends Component {

    componentDidMount() {
        const { checkIfUserLoggedIn } = this.props;
        checkIfUserLoggedIn();
    }

    componentDidUpdate(prevProps) {
        const { checkIfUserLoggedIn, location } = this.props;

        if (location.pathname !== prevProps.location.pathname) {
            checkIfUserLoggedIn();
        }
    }

    render() {
        const { component: RouteComponent, authed, ...rest } = this.props;

        return (
            <Route
                {...rest}
                render={(props) =>
                    authed === true
                        ? <RouteComponent {...props} />
                        : <Redirect to={{ pathname: '/login', state: { from: props.location } }} />
                }
            />
        );
    }
}

export default PrivateRoute;
