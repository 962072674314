import React from "react";
import store from "../../store";
import {updatePostCode} from "../../providers/merchants";

const Translate = require('react-redux-i18n').Translate

export default class PopoverPostCode extends React.Component {
    constructor(props) {
        super(props)
        this.state = {
            mid: props.mid,
            postCode: props.postCode,
        }
        this.updatePostCode = this.updatePostCode.bind(this)
    }

    updatePostCode(mid, postCode) {
        store.dispatch(updatePostCode(mid, postCode)).then((response) => {
                this.props.handlePostCodeChange(mid, response.value.data.portalPostCode !== "" ? response.value.data.portalPostCode:null )
            },
            (error) => {
            }
        )
    }

    render() {
        return (
            <div className="popover__business-modal">
                <form onSubmit={(e) => {
                    e.preventDefault();
                    this.updatePostCode(this.state.mid, this.state.postCode)
                    this.props.overlayPostCodeRef.hide();
                }}>
                    <div className="business-column">
                        <Translate value="pages.business.popover.postCode" className="editable-popover-label"/>
                        <div className="business-row">
                            <input type="text" id="postCode" name="postCode"
                                   className="simple-input"
                                   onChange={(event) => {
                                       this.setState({
                                           postCode: event.target.value
                                       })
                                   }}
                                   value={this.state.postCode}
                                   placeholder={this.state.postCode}
                                   title={this.state.postCode}/>
                            <button className="icon-icon-check" type="submit"></button>
                            <button className="icon-x-icon" type="button" onClick={() => this.props.overlayPostCodeRef.hide()}></button>
                        </div>
                    </div>
                </form>
            </div>
        )
    }
}