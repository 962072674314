import React from 'react';
import SessionManagement from "../../components/mainFrame/SessionManagement";
import store from '../../store';
import {logout, stopImpersonation} from "../../providers/users";
import {changeFirstTimeOverlay, setUserLoggedInStatus} from "../../actions/userActions";

const I18n = require('react-redux-i18n').I18n;

export default class LogOut extends React.Component {

    constructor(props) {
        super(props)

        this.state = {
            logoutTooltip: I18n.t('tooltips.hover.logOut'),
            timeoutTooltip: I18n.t('tooltips.hover.timeoutSession')
        }
        this.handleLogout = this.handleLogout.bind(this)
        LogOut.logoutActions = LogOut.logoutActions.bind(this)
    }

    handleLogout() {
        if (!this.state.logoutPending) {
            if (this.props.isImpersonator) {
                store.dispatch(stopImpersonation()).then((response) => {
                        LogOut.logoutActions()
                    },
                    (error) => {
                    })
            } else {
                store.dispatch(logout()).then(
                    (response) => {
                        // TODO It must be put again after the part of startOverlay click is handled by the backend
                        LogOut.logoutActions()
                    },
                    (error) => {
                    })
            }
            setTimeout(() => {
                window.location.reload();
            }, 3000)
        }
    }

    static logoutActions() {
        store.dispatch(setUserLoggedInStatus(false));
        store.dispatch(changeFirstTimeOverlay(true));
        // switchLocale(Locales.el)
        // localStorage.clear()
    }

    componentWillReceiveProps(nextProps) {
        if (nextProps.i18n !== this.props.i18n) {
            this.setState({
                logoutTooltip: I18n.t('tooltips.hover.logOut'),
                timeoutTooltip: I18n.t('tooltips.hover.timeoutSession')
            })
        }

        if (nextProps.pending !== this.props.pending) {
            this.setState({
                logoutPending: nextProps.pending
            })
        }
    }

    render() {
        return (
            <div className="timer-logout" title={this.state.logoutTooltip}>
                <div className="timer" title={this.state.timeoutTooltip}>
                     <span className="icon-ico-countdown-clock timer-icon">
                    </span>
                    <SessionManagement timeout={900000} handleLogout={this.handleLogout}/>
                </div>
                <a className={"logout" + (this.state.logoutPending ? " logout__pending" : "")}
                   onClick={this.handleLogout}
                   href="javascript:void(0)">
                    <i className="icon-logoff"/>
                </a>
            </div>
        )
    }
}
