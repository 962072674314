import {Locales} from "../constants";

export default function localeReducer(state = {
    currentLocale: Locales.el,
                                               en: 'en',
                                               el: 'el'
                                           }, action) {
    switch (action.type) {
        case "SET_LOCALE": {
            return {
                ...state,
                currentLocale: action.locale,
                isEn: action.locale === Locales.en,
                isEl: action.locale === Locales.el
            }
        }
        default : {
            return state
        }
    }
}