import React from "react";

const Translate = require('react-redux-i18n').Translate

export class ShowHelp extends React.Component {

    constructor(props) {
        super(props)
        this.state = {
            showHelp: false
        }
    }

    render() {
        return (
            <React.Fragment>
                <div className="forms__show-help">
                    <a href=""
                       onClick={(e) => {
                           e.preventDefault()
                           this.setState({
                               showHelp: !this.state.showHelp
                           })
                       }} className="_active">
                        <Translate value={"pages.reset.needHelp"}/>
                    </a>
                </div>
                {this.state.showHelp ?
                    <div className="forms__show-help">
                        <Translate value={"common.forms.help"}/>
                    </div>
                    : null}
            </React.Fragment>
        )
    }
}