import React from "react";
import {ButtonToolbar, DropdownButton, MenuItem} from "react-bootstrap";
import {DateTimePicker} from 'react-widgets';
import 'react-widgets/dist/css/react-widgets.css';
import {connect} from "react-redux";
import SearchBox from "../common/SearchBox";
import moment from "moment";
import momentLocalizer from 'react-widgets-moment';
import NumberFormat from 'react-number-format';
import Button from "react-bootstrap/es/Button";
import Overlay from "react-bootstrap/es/Overlay";
import Popover from "react-bootstrap/es/Popover";
import {blurInput, checkDateIsTillToday, checkDateValidity} from "../../lib/utils";
import Tooltip from "../common/Tooltip";
import store from "../../store";
import {toggleSearchTransactionsPopover} from "../../actions/userActions";
import AmountRange from "./AmountRange";
import Loader from "../common/Loader";

const Translate = require('react-redux-i18n').Translate;

const I18n = require('react-redux-i18n').I18n;

const _ = require('underscore');

const stateMap = (store) => {
    return {
        i18n: store.i18n,
        userReducer: store.userReducer,
        merchantReducer: store.merchantReducer
    };
};

const transProgressTypes = [
    {
        name: 'transPending',
        idx: 0,
    },
    {
        name: 'transClosedBatch',
        idx: 2,
    },
    {
        name: 'transCleared',
        idx: 1
    },
    {
        name: 'transSuccessPay',
        idx: 3,
    },
    {
        name: 'transUnsuccessPay',
        idx: 4

    }];

class PopoverSearch extends React.Component {
    constructor(props) {
        super(props);

        let midsWithNoLocation = this.getMidsFormats(props, false);

        let midsInDropdownFormat = this.getMidsFormats(props, true);

        let tidsInDropdownFormat = this.props.nonMapData.tidsInDropdownFormat;

        this.state = {
            fromPlaceholder: I18n.t("pages.transactions.searchModal.midColumn.from"),
            toPlaceholder: I18n.t("pages.transactions.searchModal.midColumn.to"),
            transactionDateFrom: this.props.data.dateFrom ? moment(this.props.data.dateFrom, 'DD/MM/YYYY').toDate() : moment().toDate(),
            transactionTimeFrom: this.props.data.startTime ? moment(this.props.data.startTime, "HH:mm A").toDate() : moment("00:00", "HH:mm A").toDate(),
            transactionDateTo: this.props.data.dateTo ? moment(this.props.data.dateTo, 'DD/MM/YYYY').toDate() : moment().toDate(),
            transactionTimeTo: this.props.data.endTime ? moment(this.props.data.endTime, "HH:mm A").toDate() : moment("23:59", "HH:mm A").toDate(),
            amountFrom: this.props.data.amountFrom || this.props.data.amountFrom === 0 ? this.props.data.amountFrom : '',
            amountTo: this.props.data.amountTo || this.props.data.amountTo === 0 ? this.props.data.amountTo : '',
            cardNumber: this.props.data.cardNumber ? this.props.data.cardNumber : '',
            rrn: this.props.data.rrn ? this.props.data.rrn : '',
            authorizationCode: this.props.data.authorizationCode ? this.props.data.authorizationCode : '',
            transactionTypeValue: this.props.data.transactionType ? this.props.data.transactionType : null,
            transactionTypeLabel: this.props.data.transactionTypeLabel ? this.props.data.transactionTypeLabel : '',
            pickedAcquirerValue: this.props.data.acquirerId ? this.props.data.acquirerId : null,
            pickedAcquirerLabel: this.props.data.acquirerTypeLabel ? this.props.data.acquirerTypeLabel : '',
            bothLocalesPickedAcquirerLabel: '',
            bothLocalesTransactionTypeLabel: '',
            bothLocalesPaymentTypeLabel: '',
            paymentTypeValue: this.props.data.paymentTypeValue ? this.props.data.paymentTypeValue : null,
            paymentTypeLabel: this.props.data.paymentTypeLabel ? this.props.data.paymentTypeLabel : '',
            allMids: midsInDropdownFormat ? false : true,
            tidsInDropdownFormat: tidsInDropdownFormat ? tidsInDropdownFormat : null,
            pickedMids: midsWithNoLocation ? midsWithNoLocation : null,
            pickedMidsAfterDisabled: undefined,
            pickedTid: this.props.data.terminalId ? this.props.nonMapData.tidsInDropdownFormat.find(obj => obj.value === this.props.data.terminalId) : this.props.nonMapData.tidsInDropdownFormat[0],
            pickedVMid: this.props.data.vMerchantId ? this.props.nonMapData.vMidsInDropdownFormat.find(obj => obj.value === this.props.data.vMerchantId) : this.props.nonMapData.vMidsInDropdownFormat[0],
            pickedVTid: this.props.data.vTerminalId ? this.props.nonMapData.vTidsInDropdownFormat.find(obj => obj.value === this.props.data.vTerminalId) : this.props.nonMapData.vTidsInDropdownFormat[0],
            latestPickedTid: this.props.nonMapData.tidsInDropdownFormat[0],
            latestPickedVMid: this.props.nonMapData.vMidsInDropdownFormat[0],
            latestPickedVTid: this.props.nonMapData.vTidsInDropdownFormat[0],
            successStatus: this.props.data.successStatus ? this.props.data.successStatus : false,
            rejectStatus: this.props.data.rejectStatus ? this.props.data.rejectStatus : false,
            packageStatus: this.props.data.packageStatus ? this.props.data.packageStatus : null,
            moreCriteria: false,
            showDuplicateTransactions: this.props.showDuplicateTransactions ? this.props.showDuplicateTransactions : false,
            cardNumberPlaceholder: I18n.t("pages.transactions.searchModal.lowerLeftColumn.cardNumberPlaceholder"),
            retrievalNumberPlaceholder: I18n.t("pages.transactions.searchModal.lowerLeftColumn.retrievalNumberPlaceholder"),
            approvalCodePlaceholder: I18n.t("pages.transactions.searchModal.lowerLeftColumn.approvalCodePlaceholder"),
            cardNumberPattern: '([0-9]{16,19}|([0-9]+\\*+|\\*+[0-9]+)+([0-9]*\\**))',
            cardNumberTooltip: I18n.t("pages.transactions.searchModal.lowerLeftColumn.cardNumberTooltip"),
            cardNumberErrorTitle: I18n.t("pages.transactions.searchModal.lowerLeftColumn.cardNumberErrorTitle"),
            showMultipleMidTooltip: false,
            singleAfmMid: this.props.userReducer.getAssociationsResponse.merchantType === 'singleAfmMid',
            maxDateRange: process.env.SEARCH_LIMIT_DAYS_SMALL,
            singleTid: this.props.nonMapData.tidsInDropdownFormat.length <= 2
        };

        this.getMidsFormats = this.getMidsFormats.bind(this);
        this.handleChange = this.handleChange.bind(this);
        this.handleFormattedChange = this.handleFormattedChange.bind(this);
        this.resetSearchToDefault = this.resetSearchToDefault.bind(this);
        this.changeDateFrom = this.changeDateFrom.bind(this);
        this.changeDateTo = this.changeDateTo.bind(this);
        this.checkDateChangeRestrictions = this.checkDateChangeRestrictions.bind(this);
        this.formAllMidsValues = this.formAllMidsValues.bind(this);
        this.resetTidVmidVtid = this.resetTidVmidVtid.bind(this);
        this.resetLatestAssociationsToDefaultValues = this.resetLatestAssociationsToDefaultValues.bind(this);
        this.nullifyAmountToIfAmountFromGreater = this.nullifyAmountToIfAmountFromGreater.bind(this);
        this.nullifyAmountToIfItIs0 = this.nullifyAmountToIfItIs0.bind(this);
        this.setInitialTids = this.setInitialTids.bind(this);
        this.setBothLocalesLabels = this.setBothLocalesLabels.bind(this);
        this.getSelectedTransProgress = this.getSelectedTransProgress.bind(this);
    }

    resetSearchToDefault() {
        this.setState({
            transactionDateFrom: moment().toDate(),
            transactionTimeFrom: moment("00:00", "HH:mm").toDate(),
            transactionDateTo: moment().toDate(),
            transactionTimeTo: moment("23:59", "HH:mm").toDate(),
            cardNumber: '',
            rrn: '',
            authorizationCode: '',
            allMids: true,
            pickedMids: null,
            pickedTid: this.state.tidsInDropdownFormat[0],
            pickedVMid: this.props.nonMapData.vMidsInDropdownFormat[0],
            pickedVTid: this.props.nonMapData.vTidsInDropdownFormat[0],
            amountFrom: '',
            amountTo: '',
            pickedAcquirerValue: null,
            pickedAcquirerLabel: null,
            transactionTypeValue: null,
            transactionTypeLabel: null,
            successStatus: false,
            showDuplicateTransactions: false,
            rejectStatus: false,
            packageStatus: null,
            paymentTypeLabel: null,
            singleTid: null
        }, () => {
            this.setInitialTids();
        });
    }

    setInitialTids() {
        let initialTids = this.props.nonMapData.tidsInDropdownFormat;
        this.setState({
            tidsInDropdownFormat: initialTids,
            singleTid: initialTids.length <= 2
        });
    }

    setBothLocalesLabels(type, value) {
        const allMaps = this.props.allMaps;

        switch (type) {
        case 'pickedAcquirerLabel':
            const acquirersEn = allMaps.acquirers;
            const acquirersEl = allMaps.acquirersGr;
            let bothLocalesAcquirerLabel = {
                en: acquirersEn.find(x => x.value === value).label,
                el: acquirersEl.find(x => x.value === value).label
            };
            this.setState({
                bothLocalesPickedAcquirerLabel: bothLocalesAcquirerLabel
            });
            break;
        case 'transactionTypeLabel':
            const transactionTypesEn = allMaps.transactionTypes;
            const transactionTypesEl = allMaps.transactionTypesGr;
            let bothLocalesTransactionTypesLabel = {
                en: transactionTypesEn.find(x => x.value === value).label,
                el: transactionTypesEl.find(x => x.value === value).label
            };
            this.setState({
                bothLocalesTransactionTypeLabel: bothLocalesTransactionTypesLabel
            });
            break;
        case 'paymentTypeLabel':
            const paymentTypesEn = allMaps.paymentTypes;
            const paymentTypesEl = allMaps.paymentTypesGr;
            let bothLocalesPaymentTypeLabel = {
                en: paymentTypesEn.find(x => x.value === value).label,
                el: paymentTypesEl.find(x => x.value === value).label
            };
            this.setState({
                bothLocalesPaymentTypeLabel: bothLocalesPaymentTypeLabel
            });
            break;
        }
    }

    componentWillMount() {
        let midsNum = this.props.nonMapData.midsInDropdownFormat.length;
        if (this.props.data.merchantId) {
            this.props.setSelectedMid(this.props.data.merchantId);
        } else {
            this.setInitialTids();
        }

        this.setState({
            maxDateRange: midsNum > parseInt(process.env.SEARCH_LIMIT_MIDS) ? parseInt(process.env.SEARCH_LIMIT_DAYS_SMALL) : parseInt(process.env.SEARCH_LIMIT_DAYS_BIG)
        });
    }


    componentWillReceiveProps(nextProps) {
        if (nextProps.i18n.locale !== this.props.i18n.locale) {
            if (this.state.pickedTid.value === undefined) {
                this.setState({
                    pickedTid: this.state.tidsInDropdownFormat[0]
                });
            }
            if (this.state.pickedVMid.value === undefined) {
                nextProps.nonMapData.vMidsInDropdownFormat[0].label = I18n.t('pages.transactions.searchModal.lowerMidColumn.allVmids');
                this.setState({
                    pickedVMid: nextProps.nonMapData.vMidsInDropdownFormat[0]
                });
            }
            if (this.state.pickedVTid.value === undefined) {
                nextProps.nonMapData.vTidsInDropdownFormat[0].label = I18n.t('pages.transactions.searchModal.lowerMidColumn.allVtids');
                this.setState({
                    pickedVTid: nextProps.nonMapData.vTidsInDropdownFormat[0]
                });
            }
            this.setState({
                cardNumberPlaceholder: I18n.t("pages.transactions.searchModal.lowerLeftColumn.cardNumberPlaceholder"),
                retrievalNumberPlaceholder: I18n.t("pages.transactions.searchModal.lowerLeftColumn.retrievalNumberPlaceholder"),
                approvalCodePlaceholder: I18n.t("pages.transactions.searchModal.lowerLeftColumn.approvalCodePlaceholder"),
                cardNumberTooltip: I18n.t("pages.transactions.searchModal.lowerLeftColumn.cardNumberTooltip"),
                cardNumberErrorTitle: I18n.t("pages.transactions.searchModal.lowerLeftColumn.ErrorTitle"),
                fromPlaceholder: I18n.t("pages.transactions.searchModal.midColumn.from"),
                toPlaceholder: I18n.t("pages.transactions.searchModal.midColumn.to"),
                pickedAcquirerLabel: this.state.bothLocalesPickedAcquirerLabel[nextProps.i18n.locale],
                transactionTypeLabel: this.state.bothLocalesTransactionTypeLabel[nextProps.i18n.locale],
                paymentTypeLabel: this.state.bothLocalesPaymentTypeLabel[nextProps.i18n.locale]
            });
        }

        if (nextProps.paymentTypeValue !== this.props.paymentTypeValue) {
            this.setState({
                paymentTypeValue: nextProps.paymentTypeValue
            });
        }

        if (nextProps.isToggleOn !== this.props.isToggleOn && !nextProps.isToggleOn) {
            this.props.overlayRef.hide();
            store.dispatch(toggleSearchTransactionsPopover(false));
        }

        if (nextProps.nonMapData.tidsInDropdownFormat !== this.props.nonMapData.tidsInDropdownFormat) {
            let currTid = (this.state.pickedTid && this.state.pickedTid.value) ?
                this.state.pickedTid :
                (nextProps.nonMapData.tidsInDropdownFormat.length <= 2 ?
                    nextProps.nonMapData.tidsInDropdownFormat[1] :
                    nextProps.nonMapData.tidsInDropdownFormat[0]);
            this.setState({
                tidsInDropdownFormat: nextProps.nonMapData.tidsInDropdownFormat,
                singleTid: nextProps.nonMapData.tidsInDropdownFormat.length <= 2,
                pickedTid: currTid
            });
        }
    }

    handleChange(event) {
        // Required to handle the React-Select dropdown where id is the name of the state variable used
        // Also have been made compatible with dropdown picks
        if (event.object) {
            if (event.id.startsWith("transactionProgress")) {
                this.setState({
                    packageStatus: event.object.value
                });
            } else {
                this.setState({
                    [event.id]: event.object.value,
                    [event.label]: event.object.label
                }, () => {
                    if (event.object.value &&
                        (event.label === 'pickedAcquirerLabel' ||
                        event.label === 'transactionTypeLabel' ||
                            event.label === 'paymentTypeLabel')
                    ) {
                        this.setBothLocalesLabels(event.label, event.object.value);
                    }
                });
            }

        } else if (event.id) {
            this.setState({
                [event.id]: event.value
            });
        } else if (event.target.type === "checkbox") {
            this.setState({
                [event.target.id]: event.target.checked,
            }, () => {
                if (this.state.allMids) {
                    this.props.setSelectedMid(undefined);
                    this.setState({
                        pickedMidsAfterDisabled: this.state.pickedMids,
                        pickedMids: null
                    });
                } else if (this.state.pickedMidsAfterDisabled) {
                    this.props.setSelectedMid(this.state.pickedMidsAfterDisabled.map(a => a.value));
                    this.setState({
                        pickedMids: this.state.pickedMidsAfterDisabled,
                        pickedMidsAfterDisabled: null
                    });
                }
            });
        } else {
            this.setState({
                [event.target.id]: event.target.value
            });
        }
    }

    handleFormattedChange(obj, ev) {
        this.setState({
            [ev.target.id]: obj.floatValue
        });
    }

    changeDateFrom(transactionDateFrom) {
        let days = this.state.maxDateRange;
        if (this.checkDateChangeRestrictions(transactionDateFrom)) {
            const dateFromPlusXDays = moment(transactionDateFrom).add(days, 'days').toDate();
            let dateTo = moment(this.state.transactionDateTo).toDate();
            if (moment(dateFromPlusXDays).isBefore(moment(dateTo))) {
                dateTo = moment(transactionDateFrom).add(days, 'days').toDate();
            } else if (moment(transactionDateFrom).isAfter(moment(this.state.transactionDateTo))) {
                dateTo = moment(transactionDateFrom).toDate();
            }
            this.setState({
                transactionDateFrom: transactionDateFrom,
                transactionDateTo: dateTo
            });
        } else {
            this.setState({
                transactionDateFrom: moment().subtract(days, 'days').toDate()
            });
        }
    }

    changeDateTo(transactionDateTo) {
        let days = this.state.maxDateRange;
        if (this.checkDateChangeRestrictions(transactionDateTo)) {
            let dateFrom = moment(this.state.transactionDateFrom).toDate();
            const dateFromPlusXDays = moment(this.state.transactionDateFrom).add(days, 'days');
            if (moment(transactionDateTo).isAfter(dateFromPlusXDays)) {
                dateFrom = moment(transactionDateTo).subtract(days, 'days').toDate();
            } else if (moment(transactionDateTo).isBefore(moment(this.state.transactionDateFrom))) {
                dateFrom = moment(transactionDateTo).toDate();
            }
            this.setState({
                transactionDateFrom: dateFrom,
                transactionDateTo: transactionDateTo
            });
        } else {
            this.setState({
                transactionDateTo: moment().toDate()
            });
        }
    }

    checkDateChangeRestrictions(date) {
        return checkDateValidity(date) && checkDateIsTillToday(date);
    }

    checkDateFromSmallerThanDateTo(date) {
        return checkDateValidity(date) && checkDateIsTillToday(date);
    }

    formAllMidsValues() {
        let pickedMids = undefined;
        if (!this.state.allMids && this.state.pickedMids) {
            const allMidsValues = this.state.pickedMids.map(pickedMid => {
                return pickedMid.value;
            });
            pickedMids = allMidsValues.join();
        } else {
            pickedMids = undefined;
        }

        return pickedMids;
    }

    resetTidVmidVtid(nonMapData) {
        this.setState({
            pickedTid: nonMapData.tidsInDropdownFormat[0],
            pickedVMid: nonMapData.vMidsInDropdownFormat[0],
            pickedVTid: nonMapData.vTidsInDropdownFormat[0]
        });
    }

    resetLatestAssociationsToDefaultValues() {
        this.setState({
            latestPickedTid: this.props.nonMapData.tidsInDropdownFormat[0],
            latestPickedVMid: this.props.nonMapData.vMidsInDropdownFormat[0],
            latestPickedVTid: this.props.nonMapData.vTidsInDropdownFormat[0],
        });
    }

    nullifyAmountToIfAmountFromGreater(amountFrom, amountTo) {
        if (amountFrom > amountTo) {
            amountTo = undefined;
        }

        return {amountFrom: amountFrom, amountTo: amountTo};
    }

    nullifyAmountToIfItIs0(amountTo) {
        return amountTo === 0 ? undefined : amountTo;
    }

    getMidsFormats(props, dropdown) {
        if (props.data.merchantId) {
            const splittedMids = this.props.data.merchantId.split(',');
            let midsInDropdownFormat = splittedMids.map(mid => {
                return this.props.nonMapData.midsInDropdownFormat.find(obj => obj.value === mid);
            });
            if (dropdown) {
                return midsInDropdownFormat;
            } else {
                return midsInDropdownFormat.map(element => {
                    return {value: element.value, label: element.value};
                });
            }
        }
    }

    getSelectedTransProgress(packageStatus, baseTranslate) {
        switch (packageStatus) {
        case 0:
            return <Translate value={baseTranslate + ".lowerRightColumn.transPending"}/>;
        case 1:
            return <Translate value={baseTranslate + ".lowerRightColumn.transCleared"}/>;
        case 2:
            return <Translate value={baseTranslate + ".lowerRightColumn.transClosedBatch"}/>;
        case 3:
            return <Translate value={baseTranslate + ".lowerRightColumn.transSuccessPay"}/>;
        case 4:
            return <Translate value={baseTranslate + ".lowerRightColumn.transUnsuccessPay"}/>;
        default:
            return <Translate value={baseTranslate + ".lowerRightColumn.allTransProgress"}/>;
        }
    }


    render() {
        const baseTranslate = "pages.transactions.searchModal";
        const currency = "€";
        const nonMapData = this.props.nonMapData;
        const allMaps = this.props.allMaps;
        const locale = I18n._locale;
        const acquirers = locale === 'en' ? allMaps.acquirers : allMaps.acquirersGr;
        const transactionTypes = locale === 'en' ? allMaps.transactionTypes : allMaps.transactionTypesGr;
        const paymentTypes = locale === 'en' ? allMaps.paymentTypes : allMaps.paymentTypesGr;
        const transactionTypesByAfm = this.props.transactionTypesByAfm;
        const filteredTransactionTypes = _.filter(transactionTypes, (b) => transactionTypesByAfm.map(c => c).indexOf(b.value) > -1);
        filteredTransactionTypes.unshift({
            value: undefined, label: I18n.t(baseTranslate + ".rightColumn.allTransTypes"),
            id: 'allTransactionTypesBase'
        });
        moment.locale('en-GB');
        momentLocalizer();
        return (
            <div className="overall-search-modal">
                <button className="overall-search-modal__button-container__reset-link"
                        onClick={this.resetSearchToDefault}>
                    <Translate value="pages.transactions.searchModal.labels.clear"/>
                </button>
                <form onSubmit={(e) => {
                    e.preventDefault();
                    const pickedMids = this.formAllMidsValues();
                    let {amountFrom, amountTo} = this.nullifyAmountToIfAmountFromGreater(this.state.amountFrom,
                        this.state.amountTo);
                    amountTo = this.nullifyAmountToIfItIs0(amountTo);
                    this.props.getTransactions(this.state.transactionDateFrom, this.state.transactionDateTo,
                        this.state.transactionTimeFrom, this.state.transactionTimeTo, 1, this.props.rowsPerPage, true, undefined,
                        undefined, this.state.paymentTypeValue, this.state.cardNumber, this.state.rrn, this.state.authorizationCode,
                        pickedMids, this.state.pickedTid, amountFrom, amountTo,
                        this.state.pickedAcquirerValue, this.state.pickedVMid, this.state.pickedVTid,
                        this.state.transactionTypeValue, this.state.successStatus, this.state.rejectStatus,
                        this.state.packageStatus, this.state.transactionTypeLabel,
                        this.state.pickedAcquirerLabel, this.state.paymentTypeLabel, this.state.showDuplicateTransactions);
                    this.props.overlayRef.hide();
                    this.props.resetPopover();
                }}>
                    <div className="search-modal">
                        <div className="search-modal__left-column-upper search-modal__column">
                            <Translate value={baseTranslate + ".leftColumn.timePeriod"}/>
                            {/*Transaction Date From*/}
                            <DateTimePicker value={this.state.transactionDateFrom}
                                            time={false}
                                            onChange={transactionDateFrom => {
                                                this.changeDateFrom(transactionDateFrom);
                                            }}
                                            max={moment().toDate()}/>
                            <DateTimePicker value={this.state.transactionTimeFrom}
                                            time={true}
                                            date={false}
                                            onChange={transactionTimeFrom => {
                                                if (moment(this.state.transactionDateFrom).format('DD/MM/YYYY') === moment(this.state.transactionDateTo).format('DD/MM/YYYY')) {
                                                    this.setState({
                                                        transactionTimeFrom: moment(transactionTimeFrom).isBefore(this.state.transactionTimeTo, 'minute') && moment(transactionTimeFrom).isValid() ?
                                                            transactionTimeFrom : moment("00:00", "HH:mm A").toDate()
                                                    });
                                                } else {
                                                    this.setState({
                                                        transactionTimeFrom: moment(transactionTimeFrom).isValid() ?
                                                            transactionTimeFrom : moment("00:00", "HH:mm A").toDate()
                                                    });
                                                }
                                            }
                                            }/>
                            {/*Transaction Date To*/}
                            <DateTimePicker value={this.state.transactionDateTo}
                                            time={false}
                                            onChange={transactionDateTo => {
                                                this.changeDateTo(transactionDateTo);
                                            }}
                                            max={moment().toDate()}
                            />
                            <DateTimePicker value={this.state.transactionTimeTo}
                                            time={true}
                                            date={false}
                                            onChange={transactionTimeTo => {
                                                if (moment(this.state.transactionDateFrom).format('DD/MM/YYYY') === moment(this.state.transactionDateTo).format('DD/MM/YYYY')) {
                                                    this.setState({
                                                        transactionTimeTo: moment(transactionTimeTo).isAfter(this.state.transactionTimeFrom, 'minute') && moment(transactionTimeTo).isValid() ?
                                                            transactionTimeTo : moment("23:59", "HH:mm A").toDate()
                                                    });
                                                } else {
                                                    this.setState({
                                                        transactionTimeTo: moment(transactionTimeTo).isValid() ?
                                                            transactionTimeTo : moment("23:59", "HH:mm A").toDate()
                                                    });
                                                }
                                            }
                                            }
                            />
                            {!this.state.singleAfmMid ?
                                <AmountRange
                                    baseTranslate={baseTranslate}
                                    handleFormattedChange={this.handleFormattedChange}
                                    fromPlaceholder={this.state.fromPlaceholder}
                                    toPlaceholder={this.state.toPlaceholder}
                                    amountFrom={this.state.amountFrom}
                                    amountTo={this.state.amountTo}
                                    currency={currency}
                                    clsType={'left'}
                                /> : null}
                            <div className="checkboxes-label-wrapper">
                                <div className="checkboxes-container">
                                        <span className="cbox-search-modal">
                                            <input type="checkbox" id="showDuplicateTransactions"
                                                   checked={this.state.showDuplicateTransactions}
                                                   onChange={this.handleChange}/>
                                            <label htmlFor="showDuplicateTransactions">
                                                <Translate
                                                    value={baseTranslate + ".lowerRightColumn.showDuplicateTransactions"}/>
                                            </label>
                                        </span>
                                    <div className="transactions__tooltip-wrapper">
                                        <Tooltip placement="right"
                                                 tooltipButtonId="duplicateTransactionsTitleTltp"
                                                 popoverId="duplicate-transactions-tltp"
                                                 tooltipLabelClass="duplicate-transactions-label"
                                                 tooltipText="duplicateTransactionsTitleTltp"
                                        />
                                    </div>
                                </div>

                            </div>
                        </div>
                        <div className="search-modal__mid-column-upper search-modal__column">
                            {!this.state.singleAfmMid ?
                                <React.Fragment>
                                    <Translate value={baseTranslate + ".midColumn.searchRange"}/>
                                    <p className="transactions-allmids-cbox">
                                    <span className="transactions-allmids-cbox__wrapper">
                                       <input type="checkbox"
                                              name="select-allMids-checkbox"
                                              id="allMids"
                                              checked={this.state.allMids}
                                              onChange={(event) => {
                                                  if (event.target.checked === true) {
                                                      this.setState({
                                                          latestPickedTid: this.state.pickedTid,
                                                          latestPickedVMid: this.state.pickedVMid,
                                                          latestPickedVTid: this.state.pickedVTid
                                                      }, () => {
                                                          this.resetTidVmidVtid(nonMapData);
                                                      });
                                                  } else {
                                                      this.setState({
                                                          pickedTid: this.state.latestPickedTid,
                                                          pickedVMid: this.state.latestPickedVMid,
                                                          pickedVTid: this.state.latestPickedVTid,
                                                      }, () => {
                                                          this.resetLatestAssociationsToDefaultValues();
                                                      });
                                                  }

                                                  this.handleChange(event);
                                              }}
                                       />
                                        <label htmlFor={'allMids'}>
                                            <Translate value={baseTranslate + ".midColumn.toAllStores"}/>
                                        </label>
                                    </span>
                                    </p>
                                    <div className="search-modal__mid-column-upper__mids-search-wrapper">
                                        <SearchBox options={nonMapData.midsInDropdownFormat}
                                                   blurBox={this.state.allMids}
                                                   blurInput={blurInput}
                                                   clsName="transactions-allmids-select"
                                                   value={this.state.pickedMids} onChange={(event) => {
                                            this.resetTidVmidVtid(nonMapData);

                                            if (event.length > 0) {
                                                const midsOnly = event.map(element => {
                                                    return {value: element.value, label: element.value};
                                                });
                                                this.setState({
                                                    pickedMids: midsOnly,
                                                }, () => {
                                                    this.props.setSelectedMid(this.state.pickedMids.map(a => a.value));
                                                });
                                            } else {
                                                this.setState({
                                                    pickedMids: null,
                                                    allMids: true
                                                });
                                                this.props.setSelectedMid(null);
                                            }
                                        }}
                                                   placeholder={baseTranslate + ".midColumn.toSpecificStores"}
                                                   isMulti={true}
                                                   isDisabled={this.state.allMids}/>
                                        <div className="transactions__tooltip-wrapper">
                                            <Tooltip placement="right"
                                                     tooltipButtonId="trxSearchMultipleMID"
                                                     popoverId="multiple-mid-popover-tltp"
                                                     tooltipLabelClass="multiple-mid-transaction-label"
                                                     tooltipText="trxSearchMultipleMID"
                                            />
                                        </div>
                                    </div>
                                </React.Fragment> :
                                <AmountRange
                                    baseTranslate={baseTranslate}
                                    handleFormattedChange={this.handleFormattedChange}
                                    fromPlaceholder={this.state.fromPlaceholder}
                                    toPlaceholder={this.state.toPlaceholder}
                                    amountFrom={this.state.amountFrom}
                                    amountTo={this.state.amountTo}
                                    currency={currency}
                                    clsType={'mid'}
                                />}
                            <div className="search-modal__pick-bank-label--wrapper">
                                <Translate value={baseTranslate + ".lowerMidColumn.pickBank"}/>
                                <ButtonToolbar>
                                    <DropdownButton className="search-modal__right-column__dropdowns no-multi"
                                                    title={this.state.pickedAcquirerLabel ?
                                                        this.state.pickedAcquirerLabel :
                                                        <Translate value={baseTranslate + ".lowerMidColumn.allBanks"}/>}
                                                    id="dropdown-acquirers">
                                        {
                                            acquirers ? (
                                                acquirers.map((acquirer) => {
                                                    if (acquirer.value === '021') return [];
                                                    return [
                                                        <MenuItem eventKey={acquirer.value}
                                                                  key={acquirer.value}
                                                                  onSelect={() => {
                                                                      this.handleChange(
                                                                          {
                                                                              id: 'pickedAcquirerValue',
                                                                              object: acquirer,
                                                                              label: 'pickedAcquirerLabel'
                                                                          });
                                                                  }}
                                                                  active={acquirer.value === this.state.pickedAcquirerValue}
                                                        >{acquirer.label}
                                                        </MenuItem>
                                                    ];
                                                })) : null
                                        }
                                    </DropdownButton>
                                </ButtonToolbar>
                            </div>
                        </div>
                        <div className="search-modal__right-column-upper search-modal__column">
                            <Translate
                                value={baseTranslate + (this.state.singleTid ? ".midColumn.singlePos" : ".midColumn.pickPos")}/>
                            {this.props.merchantReducer.getTidsGroupedByMid.fetched ?
                                <React.Fragment>
                                    {!this.state.singleTid ?
                                        <SearchBox options={this.state.tidsInDropdownFormat}
                                                   clsName="search-modal__mid-column__dropdowns"
                                                   value={!this.state.allMids && this.state.latestPickedTid &&
                                                   this.state.latestPickedTid.value ?
                                                       this.state.latestPickedTid :
                                                       this.state.pickedTid} onChange={(event) => {
                                            this.setState({
                                                pickedTid: event
                                            });
                                        }}
                                                   placeholder={baseTranslate + ".midColumn.allPos"}/> :
                                        <div className="search-modal__right-column-upper__single-tid">
                                            {
                                                // nonMapData.tidsInDropdownFormat[1] ? nonMapData.tidsInDropdownFormat[1].label : ''
                                                this.state.pickedTid.label
                                            }
                                        </div>
                                    }
                                </React.Fragment> : null}
                            {this.props.merchantReducer.getTidsGroupedByMid.fetching ?
                                <Loader key="tid-loader"
                                        bounceClass="smaller-bounces"
                                        spinnerClass="smaller-bounces-align-popover"
                                        loaderClass="search-modal__right-column-upper__loader"
                                        bouncing={false} color={'bahama-blue'} message=""/> : null}
                            <Translate value={baseTranslate + ".rightColumn.pickTransType"}
                                       className="search-modal__dropdown-label"/>
                            <ButtonToolbar>
                                <DropdownButton
                                    className="search-modal__right-column__dropdowns no-multi"
                                    title={this.state.transactionTypeLabel ?
                                        <span
                                            className="search-modal__transaction-type-label">{this.state.transactionTypeLabel}</span> :
                                        <Translate value={baseTranslate + ".rightColumn.allTransTypes"}/>}
                                    id="dropdown-transaction-types">
                                    {
                                        filteredTransactionTypes ? (
                                            filteredTransactionTypes.filter(transactionType => transactionType.value !== null &&
                                                transactionType.value !== '').map((transactionType) => {
                                                return [
                                                    <MenuItem eventKey={transactionType.value}
                                                              key={transactionType.value}
                                                              onSelect={() => {
                                                                  this.handleChange(
                                                                      {
                                                                          id: 'transactionTypeValue',
                                                                          object: transactionType,
                                                                          label: 'transactionTypeLabel'
                                                                      });
                                                              }}
                                                              active={transactionType.value === this.state.transactionTypeValue}
                                                    >{transactionType.label}
                                                    </MenuItem>
                                                ];
                                            })) : null
                                    }
                                </DropdownButton>
                            </ButtonToolbar>
                            <div className="checkboxes-label-wrapper">
                                <Translate value={baseTranslate + ".rightColumn.transStatus"}/>
                                <div className="checkboxes-container">
                                <span className="cbox-search-modal">
                                    <input type="checkbox" id="successStatus" checked={this.state.successStatus}
                                           onChange={this.handleChange}/>
                                    <label htmlFor="successStatus">
                                        <Translate value={baseTranslate + ".rightColumn.transSuccess"}/>
                                    </label>
                                 </span>
                                    <span className="cbox-search-modal">
                                    <input type="checkbox" id="rejectStatus" checked={this.state.rejectStatus}
                                           onChange={this.handleChange}/>
                                    <label htmlFor="rejectStatus">
                                        <Translate value={baseTranslate + ".rightColumn.transReject"}/>
                                    </label>
                                </span>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div>
                        <p className="mid-line__more-criteria__paragraph">
                            <label htmlFor="moreCriteria" className="mid-line">
                                <input className="mid-line__more-criteria"
                                       type="checkbox" id="moreCriteria" onChange={() => {
                                    this.setState({moreCriteria: !this.state.moreCriteria}, () => {
                                        this.props.toggleMoreCriteria(this.state.moreCriteria);
                                    });
                                }}/>
                                <span
                                    className={this.state.moreCriteria === false ? "icon-right-open-1" : "icon-minus-less"}/>
                                <Translate value={this.state.moreCriteria === false ?
                                    baseTranslate + ".lowerLeftColumn.moreCriteria" :
                                    baseTranslate + ".lowerLeftColumn.lessCriteria"}/>
                            </label>
                        </p>
                    </div>
                    {this.state.moreCriteria === true ?
                        <div className="search-modal">
                            <div className="search-modal__left-column-bottom search-modal__column">
                                <Translate value={baseTranslate + ".lowerLeftColumn.cardNumber"}/>
                                <label htmlFor="tooltip"
                                       className="search-modal__column__info-button-label">
                                    <Button id="tooltip"
                                            className="search-modal__left-column-bottom search-modal__column__info-button"
                                            onClick={e => {
                                                this.setState({target: e.target, showHelp: !this.state.showHelp});
                                            }}>
                                    </Button>
                                    <span className="icon-icon-info"/>
                                    <Overlay
                                        show={this.state.showHelp}
                                        target={this.state.target}
                                        placement="right"
                                        container={this}
                                        containerPadding={20}>
                                        <Popover id="card-number-tooltip"
                                                 className={"card-number-tltp position-" + I18n._locale}
                                                 title="">
                                            {this.state.cardNumberTooltip}
                                        </Popover>
                                    </Overlay>
                                </label>
                                <input type="text" id="cardNumber" name="cardNumber"
                                       className="simple-input"
                                       onChange={this.handleChange}
                                       value={this.state.cardNumber}
                                       placeholder={this.state.cardNumberPlaceholder}
                                       minLength={2}
                                       size={19}
                                       pattern={this.state.cardNumberPattern}/>
                                <Translate value={baseTranslate + ".lowerLeftColumn.retrievalNumber"}/>
                                <NumberFormat
                                    id="rrn"
                                    format="#############"
                                    className="simple-input"
                                    value={this.state.rrn}
                                    placeholder={this.state.retrievalNumberPlaceholder}
                                    onValueChange={this.handleFormattedChange}/>
                                <Translate value={baseTranslate + ".lowerLeftColumn.approvalCode"}/>
                                <input type="text" id="authorizationCode"
                                       className="simple-input"
                                       onChange={(event) => {
                                           if (event.target.value.length <= 6) {
                                               this.setState({
                                                   authorizationCode: event.target.value
                                               });
                                           }
                                       }}
                                       value={this.state.authorizationCode}
                                       placeholder={this.state.approvalCodePlaceholder}/>
                            </div>
                            <div className="search-modal__mid-column-bottom search-modal__column">
                                {this.props.paymentTypeValue === 'notecom' ? [
                                    <Translate value={baseTranslate + ".lowerMidColumn.pickVmid"}
                                               key="lower-mid-column-pick-vmid"/>,
                                    (this.props.merchantReducer.getTidsGroupedByMid.fetched ?
                                        <SearchBox options={nonMapData.vMidsInDropdownFormat}
                                                   clsName="search-modal__mid-column__dropdowns"
                                                   key={"VMids"}
                                                   value={!this.state.allMids && this.state.latestPickedVMid &&
                                                   this.state.latestPickedVMid.value ?
                                                       this.state.latestPickedVMid :
                                                       this.state.pickedVMid} onChange={(event) => {
                                            this.setState({
                                                pickedVMid: event
                                            });
                                        }}
                                                   placeholder={baseTranslate + ".lowerMidColumn.allVmids"}/> : null),
                                    (this.props.merchantReducer.getTidsGroupedByMid.fetching ?
                                        <Loader key="vmid-loader"
                                                bounceClass="smaller-bounces"
                                                spinnerClass="smaller-bounces-align-popover"
                                                loaderClass="search-modal__right-column-upper__loader"
                                                bouncing={false} color={'bahama-blue'} message=""/> : null),
                                    <Translate value={baseTranslate + ".lowerMidColumn.pickVtid"}
                                               key="lower-mid-column-pick-vtid"/>,
                                    (this.props.merchantReducer.getTidsGroupedByMid.fetched ?
                                        <SearchBox options={nonMapData.vTidsInDropdownFormat}
                                                   clsName="search-modal__mid-column__dropdowns"
                                                   key={"VTids"}
                                                   value={!this.state.allMids && this.state.latestPickedVTid &&
                                                   this.state.latestPickedVTid.value ?
                                                       this.state.latestPickedVTid :
                                                       this.state.pickedVTid} onChange={(event) => {
                                            this.setState({
                                                pickedVTid: event
                                            });
                                        }}
                                                   placeholder={baseTranslate + ".lowerMidColumn.allVtids"}/> : null),
                                    (this.props.merchantReducer.getTidsGroupedByMid.fetching ?
                                        <Loader key="vtid-loader"
                                                bounceClass="smaller-bounces"
                                                spinnerClass="smaller-bounces-align-popover"
                                                loaderClass="search-modal__right-column-upper__loader"
                                                bouncing={false} color={'bahama-blue'} message=""/> : null)
                                ] : null}
                            </div>
                            <div className="search-modal__right-column-bottom search-modal__column">
                                <Translate value={baseTranslate + ".lowerRightColumn.transProgress"}/>
                                <ButtonToolbar>
                                    <DropdownButton
                                        className="search-modal__right-column__dropdowns no-multi"
                                        title={this.getSelectedTransProgress(this.state.packageStatus, baseTranslate)}
                                        id="dropdown-transaction-progress">
                                        <MenuItem eventKey={"allTransactions"}
                                                  key={"allTransactions"}
                                                  onSelect={() => {
                                                      this.handleChange(
                                                          {
                                                              id: "transactionProgress-allTransactions",
                                                              object: {
                                                                  label: I18n.t(baseTranslate + ".lowerRightColumn.allTransProgress"),
                                                                  value: null
                                                              },
                                                          });
                                                  }}
                                                  active={this.state.packageStatus === undefined || this.state.packageStatus === null}>
                                            <Translate value={baseTranslate + ".lowerRightColumn.allTransProgress"}/>
                                        </MenuItem>
                                        {
                                            transProgressTypes.map((trans, idx) => {
                                                return <MenuItem eventKey={trans.name}
                                                                 key={trans.name}
                                                                 onSelect={() => {
                                                                     this.handleChange(
                                                                         {
                                                                             id: "transactionProgress-" + trans.name,
                                                                             object: {
                                                                                 label: I18n.t(baseTranslate + ".lowerRightColumn." + trans.name),
                                                                                 value: trans.idx
                                                                             },
                                                                         });
                                                                 }}
                                                                 active={this.state.packageStatus === trans.idx}>
                                                    <Translate
                                                        value={baseTranslate + ".lowerRightColumn." + trans.name}/>
                                                </MenuItem>;
                                            })
                                        }
                                    </DropdownButton>
                                </ButtonToolbar>
                                {this.state.paymentTypeValue !== 'ecom' ? [
                                    <div className="payment-type-wrapper"
                                         key="popover-search-payment-type-wrapper">
                                        <Translate value={baseTranslate + ".lowerRightColumn.pickPayType"}/>
                                        <ButtonToolbar>
                                            <DropdownButton
                                                className="search-modal__right-column__dropdowns no-multi"
                                                title={this.state.paymentTypeLabel ? this.state.paymentTypeLabel :
                                                    <Translate
                                                        value={baseTranslate + ".lowerRightColumn.allPayTypes"}/>}
                                                id="dropdown-payment-types">
                                                {
                                                    paymentTypes ? (
                                                        paymentTypes.map((paymentType) => {
                                                            return [
                                                                <MenuItem eventKey={paymentType.value}
                                                                          key={paymentType.value}
                                                                          onSelect={() => {
                                                                              this.handleChange(
                                                                                  {
                                                                                      id: 'paymentTypeValue',
                                                                                      object: paymentType,
                                                                                      label: 'paymentTypeLabel'
                                                                                  });
                                                                          }}
                                                                          active={paymentType.value === this.state.paymentTypeValue}
                                                                >{paymentType.label}
                                                                </MenuItem>
                                                            ];
                                                        })) : null
                                                }
                                            </DropdownButton>
                                        </ButtonToolbar>
                                    </div>] : null}
                            </div>
                        </div> : null}
                    <div className="overall-search-modal__button-container">
                        <button type="submit" className="overall-search-modal__search-button">
                            <Translate value="pages.transactions.searchModal.labels.opened"/>
                        </button>
                    </div>
                </form>
            </div>
        );
    }
}

export default connect(stateMap)(PopoverSearch);