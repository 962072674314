import axios from "axios";

const store = require("./store");
const _ = require("underscore");

axios.defaults.headers.post['Content-Type'] = 'application/json;charset=UTF-8';
axios.interceptors.request.use(function (config) {
    if (process.env.NODE_ENV !== 'production') {
        console.debug("AXIOS Request " + JSON.stringify(config));
    }
    config.withCredentials = true
    config.timeout = 0
    return config
}, function (error) {
    console.error("AXIOS Request Error " + JSON.stringify(error));
    return Promise.reject(error);
});

export const makeRequest = (url, method, params, headers, reducer, responseType) => {
    if (_.isEmpty(headers)) {
        headers = {
            'csrf-token': store.default.getState().userReducer.csrf,
            'created-on': store.default.getState().userReducer.createdOn
        }
    } else {
        headers['csrf-token'] = store.default.getState().userReducer.csrf
        headers['created-on'] = store.default.getState().userReducer.createdOn
    }
    switch (method) {
        case "POST" : {
            return {
                type: reducer,
                payload: axios.post(url, params,
                    {
                        headers: headers
                    })
            }
        }
        case "PUT" : {
            return {
                type: reducer,
                payload: axios.put(url, params,
                    {
                        headers: headers,
                    })
            }
        }
        case "GET" : {
            return {
                type: reducer,
                payload: axios.get(url, {
                    headers: headers,
                    params: params,
                    responseType: responseType ? responseType : 'json'
                })
            }
        }
        case "DELETE" : {
            return {
                type: reducer,
                payload: axios.delete(url, {
                    headers: headers,
                    data: params
                })
            }
        }
        default:
            break;
    }
};