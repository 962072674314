import 'bootstrap3/dist/css/bootstrap.css';
import 'bootstrap3/dist/css/bootstrap-theme.css';
import React from 'react';
import ReactDOM from 'react-dom';
import App from "./js/components/mainFrame/App";
import {Provider} from 'react-redux';
import store from './js/store';
import registerServiceWorker from './registerServiceWorker';
import './assets/css/Main.css';
import './assets/css/pfm.css';
import './assets/css/cookiebot.css';
import 'amcharts3/amcharts/plugins/export/export.css';
import '@fortawesome/fontawesome-free/css/all.css'

ReactDOM.render(
	<Provider store={store}>
		<App/>
	</Provider>
    ,document.getElementById("main-app"));
    
    
registerServiceWorker();
