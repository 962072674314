import React from 'react';
import moment from "moment";
import DonutChart from "./donutChart/DonutChart";
import BootstrapTable from "react-bootstrap-table-next";
import store from "../../store";
import {cardModeTotals, cardSchemeTotals} from "../../providers/charts";
import ChartDropdown from "./ChartDropdown";
import {defineCardsColor, setChartDates} from "../../lib/utils";
import NoChartData from "../common/NoChartData";
import {withRouter} from "react-router-dom";
import connect from "react-redux/es/connect/connect";
import Loader from "../common/Loader";
import HotSpot from "../common/HotSpot";
import NumberFormatter from "../common/NumberFormatter";

const I18n = require('react-redux-i18n').I18n
const Translate = require('react-redux-i18n').Translate;

const stateMap = (store) => {
    return {
        chartReducer: store.chartReducer,
        userReducer: store.userReducer,
        i18n: store.i18n,
    };
};

const donutChart = {
    headerFormatter: (column, index, {sortElement, filterElement}) => {
        let str = column.text;
        let firstStr = str.substr(0, str.indexOf(' '));
        let scndStr = str.substr(str.indexOf(' ') + 1);
        return (
            <div className="donut-chart-tbl-hdr">
                <div className="donut-chart-tbl-hdr--item">{firstStr + " "}</div>
                <div className="donut-chart-tbl-hdr--item">{scndStr}</div>
            </div>

        )
    }
};


class CardTotalsChart extends React.Component {
    constructor(props) {
        super(props)
        this.state = {
            afm: this.props.afm,
            mid: this.props.mid,
            cardModeTotalsData: null,
            cardSchemeTotalsData: null,
            cardMode: false,
            dateFrom: moment(moment().subtract(1, 'days')).subtract(1, 'week').format('YYYY-MM-DD'),
            dateTo: moment().subtract(1, 'days').format('YYYY-MM-DD'),
            cardDropdownTooltip: I18n.t('tooltips.hover.timeDropdown02'),
            locale: this.props.locale
        }
        this.runCardModeAndSchemeTotals = this.runCardModeAndSchemeTotals.bind(this)
        this.reRenderChart = this.reRenderChart.bind(this)
        this.cardModeTotals = this.cardModeTotals.bind(this)
        this.cardSchemeTotals = this.cardSchemeTotals.bind(this)
        this.handleCardModeCheck = this.handleCardModeCheck.bind(this)
        this.setNewDates = this.setNewDates.bind(this)
        this.valueFormatter = this.valueFormatter.bind(this)
    }

    componentWillMount() {
        const data = {
            afm: this.props.afm,
            mid: this.props.mid,
            dateFrom: this.state.dateFrom,
            dateTo: this.state.dateTo,
        }
        this.runCardModeAndSchemeTotals(data)
    }

    componentWillReceiveProps(nextProps) {
        if (this.props.afm !== nextProps.afm || this.props.mid !== nextProps.mid) {
            this.setState({
                afm: nextProps.afm,
                mid: nextProps.mid
            }, () => {
                this.reRenderChart()
            })
        }
        if (this.props.i18n.locale !== nextProps.i18n.locale) {
            this.reRenderChart()
        }
    }

    runCardModeAndSchemeTotals(data) {
        this.cardModeTotals(data)
        this.cardSchemeTotals(data)
    }

    reRenderChart() {
        let data = {
            afm: this.state.afm,
            mid: this.state.mid,
            dateFrom: this.state.dateFrom,
            dateTo: this.state.dateTo
        }
        this.runCardModeAndSchemeTotals(data)
    }

    cardModeTotals(data) {
        store.dispatch(cardModeTotals(data)).then((response) => {
            let cardTotals = []
            response.value.data.payload.forEach((card, index) => {
                cardTotals.push({
                    title: card.contactless ? I18n.t('pages.reports.contactless') : I18n.t('pages.reports.notContactless'),
                    value: card.amount,
                    count: card.count,
                    uniqueKey: index
                })
            })
            this.setState({
                cardModeTotalsData: cardTotals.reverse()
            })
        }, (error) => {
        })
    }

    cardSchemeTotals(data) {
        store.dispatch(cardSchemeTotals(data)).then((response) => {
            let cardTotals = []
            response.value.data.forEach((card, index) => {
                let color = defineCardsColor(card.scheme);
                cardTotals.push({
                    title: card.scheme === "AMERICAN EXPRESS" ? "AMEX" : card.scheme,
                    value: card.amount,
                    count: card.count,
                    dotColor: color,
                    uniqueKey: index
                })
            })
            this.setState({
                cardSchemeTotalsData: cardTotals
            })
        }, (error) => {
        })
    }

    handleCardModeCheck(event) {
        this.setState({
            cardMode: !this.state.cardMode
        })
    }

    setNewDates(dateFrom, dateTo) {
        this.setState({
            dateFrom: dateFrom,
            dateTo: dateTo
        })
    }

    valueFormatter(cell, row, index, digits) {
        return (
            <NumberFormatter number={cell} digits={2} symbol={"€"}/>
        )
    }

    countFormatter(cell, row, index, digits) {
        return (
            <NumberFormatter number={cell} digits={0}/>
        )
    }

    render() {
        const cardTotalsData = this.state.cardMode ? this.state.cardModeTotalsData : this.state.cardSchemeTotalsData
        const columns = [{
            dataField: 'title',
            text: " "
        }, {
            dataField: 'count',
            text: I18n.t('pages.reports.tableTitles.number'),
            headerFormatter: donutChart.headerFormatter,
            formatter: this.countFormatter
        }, {
            dataField: 'value',
            text: I18n.t('pages.reports.tableTitles.count'),
            headerFormatter: donutChart.headerFormatter,
            formatter: this.valueFormatter,
            formatExtraData: 1
        }];

        const rowClasses = (row, rowIndex) => {
            let classes = row.dotColor;
            return classes;
        };

        return (
            <div>
                <div className="reports__card--chart">
                    <div className="date-align-right__wrapper">
                        <h5 className="reports__card--section_pre-title">
                            <Translate value="pages.reports.titles.components"/>
                        </h5>
                        {this.props.renderDates(this.state.dateFrom, this.state.dateTo)}
                    </div>
                    {this.props.userReducer.showHotSpots ?
                        <div className="hotSpot-element__wrapper">
                            <HotSpot
                                hotProp="cardsDates"
                                placement="right"
                            />
                        </div>
                        : null}
                    <div className="date-align-right__wrapper">
                        <h4 className="reports__bank--section_title">
                            <Translate value="pages.reports.titles.ofCards"/>
                        </h4>
                        <div title={this.state.cardDropdownTooltip}>
                            <ChartDropdown
                                id="card-total-chart-timespan"
                                chartMaps={this.props.chartMaps} data={{
                                afm: this.props.afm,
                                mid: this.state.mid
                            }} reRenderChart={this.runCardModeAndSchemeTotals} setDates={setChartDates}
                                setDatesInParent={this.setNewDates}
                                dropdownBaseValue="pages.reports.chart.dropdown.baseValueWeek"
                            />
                        </div>
                    </div>
                    {this.props.chartReducer && this.props.chartReducer.cardTotals &&
                    this.props.chartReducer.cardTotals.fetched ?
                        <React.Fragment>
                            {cardTotalsData !== null && cardTotalsData.length > 0 ?
                                <div className="donut-chart--cards">
                                    <DonutChart
                                        type="card"
                                        data={cardTotalsData}
                                        keyField='uniqueKey'
                                        chartTitle={I18n.t("pages.reports.pieChart.title")}
                                        filename={I18n.t("pages.reports.titles.components") + " " +
                                        I18n.t("pages.reports.titles.ofCards") +
                                        "_" + this.state.dateFrom + "_" + this.state.dateTo} />
                                    <BootstrapTable classes="donut-chart-table"
                                                    data={cardTotalsData}
                                                    keyField='uniqueKey'
                                                    columns={columns}
                                                    rowClasses={rowClasses}/>
                                </div>
                                :
                                <NoChartData/>}
                        </React.Fragment> : null}
                    {this.props.chartReducer && this.props.chartReducer.cardTotals &&
                    this.props.chartReducer.cardTotals.fetching ?
                        <Loader bouncing={false} color={'bahama-blue'} message=''/> : null
                    }
                    {!this.props.chartReducer.cardTotals.fetching ?
                    <div className="cards-switch__wrapper">
                        <div className="reports__chart-switch cards-switch">
                            {this.props.userReducer.showHotSpots ?
                                <HotSpot
                                    hotProp="cardScheme"
                                    placement="right"
                                />
                                : null}
                            <div className={"blue__switch report-translate" + (!this.state.cardMode ? "_active" : "")}>
                                <Translate value="pages.reports.scheme"/>
                            </div>
                            <input type="checkbox"
                                   name="select-card-mode-checkbox"
                                   className="cbx"
                                   id='cardMode'
                                   checked={this.state.cardMode}
                                   onChange={this.handleCardModeCheck}
                            />
                            <label htmlFor="cardMode" className="toggle">
                                <span></span>
                            </label>
                            <div className={"orange__switch report-translate" + (this.state.cardMode ? "_active" : "")}>
                                <Translate value="pages.reports.mode"/>
                            </div>
                        </div>
                        <div className="empty_flex_div">
                        </div>
                    </div>:null}
                </div>

            </div>
        )
    }
}

export default withRouter(connect(stateMap)(CardTotalsChart));