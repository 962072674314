import React from "react";
import SupportBox from "../overview/SupportBox";
import {withRouter} from "react-router-dom";
import connect from "react-redux/es/connect/connect";
import DownloadReports from "../rightColumn/DownloadReports";
import SocialButtons from "../rightColumn/SocialButtons";


const stateMap = (store) => {
    return {
        merchantReducer: store.merchantReducer,
        userReducer: store.userReducer
    };
};


class RightColumn extends React.Component {

    constructor(props) {
        super(props)
        this.promoAreaWithSupportBox = this.promoAreaWithSupportBox.bind(this)
    }


    promoAreaWithSupportBox() {
        return (
            <div className={"right-column " + (this.props.userReducer.getAssociationsResponse.merchantType === 'singleAfmMid' ? 'no-quick-find' : '')}>
                <div className="banner__wrapper">
                    <div className="appo-banner-6571" />
                </div>
                <SupportBox/>
                <SocialButtons/>
            </div>
        )
    }

    render() {
        switch (this.props.type) {
            case 'promo':
                return (this.promoAreaWithSupportBox());
            case 'downloadReports':
                return (
                    <div className={"right-column " + (this.props.userReducer.getAssociationsResponse.merchantType === 'singleAfmMid' ? 'no-quick-find' : '')}>
                        <DownloadReports triggerLoader={this.props.triggerLoader}/>
                        <SocialButtons/>
                    </div>
                )
            default:
                return (this.promoAreaWithSupportBox());
        }
    }
}

export default withRouter(connect(stateMap)(RightColumn));