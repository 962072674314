import React from "react";
import store from "../../store";
import connect from "react-redux/es/connect/connect";
import {arrangeCompanyName, bytesToSize} from "../../lib/utils";
import {withRouter} from "react-router-dom";
import Dropzone from 'react-dropzone'
import {getMidsAndTidsByAfm} from "../../providers/merchants";
import moment from "moment";
import {emailSupport} from "../../providers/users";
import Loader from "../common/Loader";
import ResponseMessage from "./ResponseMessage";

const Translate = require('react-redux-i18n').Translate;

const I18n = require('react-redux-i18n').I18n;

const stateMap = (store) => {
    return {
        userReducer: store.userReducer,
        merchantReducer: store.merchantReducer,
        emailReducer: store.emailReducer,
        i18n: store.i18n
    };
};


class FooterSupportForm extends React.Component {
    constructor(props) {
        super(props)
        this.state = {
            afm: props.merchantReducer.afm,
            companies: props.userReducer.getAssociationsResponse.uniqueAfmsWithTitles,
            companyTitles: this.getAllCompanyTitles(this.props.userReducer.getAssociationsResponse.uniqueAfmsWithTitles),
            companyTitle: undefined,
            companyName: undefined,
            company: undefined,
            mid: props.merchantReducer.midsCommaSeparated ? props.merchantReducer.midsCommaSeparated : undefined,
            fileSizeArr: [],
            selectedMid: props.selectedMid,
            selectedTid: props.selectedTid,
            singleMid: this.populateMidsSelection(props.userReducer.getAssociationsResponse.uniqueMids) <= 2,
            singleTid: false,
            merchantTitles: [],
            pending: false,
            files: [],
            emailSent: undefined,
            showFileError: false,
            emailBodyError: false

    };

        this.populateMidsSelection = this.populateMidsSelection.bind(this);
        this.populateTidsSelection = this.populateTidsSelection.bind(this);
        this.onDrop = this.onDrop.bind(this)
        this.onCancel = this.onCancel.bind(this)
        this.checkTextArea = this.checkTextArea.bind(this);
        this.formulateEmailMessage = this.formulateEmailMessage.bind(this);
        this.getAllCompanyTitles= this.getAllCompanyTitles.bind(this);
        this.checkFilesSize = this.checkFilesSize.bind(this);
        this.removeUpload = this.removeUpload.bind(this);
    }

    componentWillMount() {
        if (this.state.afm) {
            this.findCurrent(this.state.companies, this.state.afm, "company")
        } else {
            let afms = this.props.userReducer.getAssociationsResponse.uniqueAfms;
            this.handleChange("company", afms.toString(), I18n.t("modals.support.company.dropdown.allCompanies"), true);
        }
        if (this.props.selectedMid) {
            this.findCurrent(this.state.midsDropdown, this.props.selectedMid, "mid");
        }
        if (this.props.selectedTid) {
            this.findCurrent(this.state.tidsDropdown, this.props.selectedTid, "tid");
        }
    }

    componentWillReceiveProps(nextProps) {
        if(nextProps.merchantReducer.afm !== this.props.merchantReducer.afm) {
            this.setState({
                afm: nextProps.merchantReducer.afm
            }, () => {
                this.findCurrent(this.state.companies, nextProps.merchantReducer.afm, "company");
            })
        }
        if(nextProps.selectedMid!== this.props.selectedMid) {
            this.setState({
                selectedMid: this.props.selectedMid
            }, () => {
                this.findCurrent(this.state.midsDropdown, nextProps.selectedMid, "mid")
            })
        }
        if(nextProps.selectedTid!== this.props.selectedTid) {
            this.setState({
                selectedTid: this.props.selectedTid
            }, () => {
                this.findCurrent(this.state.tidsDropdown, nextProps.selectedTid, "tid")
            })
        }
    }

    populateMidsSelection(midsArr) {
        let mids = [{label: I18n.t("modals.support.mid.dropdown.allMids"), value: midsArr.toString(), allSelected: true}];
        midsArr.forEach(mid => {
            mids.push({label: mid, value: mid, allSelected: false});
        });
        return mids;
    }

    populateTidsSelection(tidsArr) {
        let tids = [{label: I18n.t("modals.support.tid.dropdown.allTids"), value: tidsArr.toString(), allSelected: true}];
        tidsArr.forEach(tid => {
            tids.push({label: tid, value: tid, allSelected: false});
        });
        return tids;
    }

    findCurrent(arr, val, type) {
        if (type === "company") {
            let curr = arr.find(x => x.afm === val);
            this.handleChange(type, curr.afm, arrangeCompanyName(curr), false)
        } else {
            let curr = arr.find(x => x.value === val);
            this.handleChange(type, curr.value, curr.value, false, null)
        }
    }

    getAllCompanyTitles(arr) {
        let res = [];
        arr.forEach(comp => {
            res.push(arrangeCompanyName(comp, this.props.i18n.locale));
        });
        return res;
    }

    handleChange(type, value, label, allSelected) {
        this.setState({
            [type]: {label: label, value: value, allSelected: allSelected}
        }, () => {
            if (type === "company") {
                if(allSelected) {
                    this.setState({
                        companyTitle: (this.state.companyTitles).toString(),
                    })
                } else {
                    store.dispatch(getMidsAndTidsByAfm(1, -1, value, undefined)).then(
                        (response) => {
                            this.setState({
                                companyTitle: this.state.company.label,
                            })
                        });
                }

            }
            if (type === "message") {
                this.setState({
                    emailBodyError: /((javascript:)|(<)|(>))/.test(value)
                });
            }
        })
    }

    onDrop(files) {
        files.forEach((file, idx) => {
            const reader = new FileReader();
            reader.onload = (event) => {
                let resFiles = [];
                let resFileSizeArr = [];
                resFiles.push({
                    name: file.name,
                    attachment: Array.from(new Uint8Array(event.target.result)),
                    contentType: null
                });
                resFileSizeArr.push(file.size);
                this.setState(prevState => ({
                    files: prevState.files && prevState.fileSizeArr ? this.checkFilesSize(prevState.files.concat(resFiles), prevState.fileSizeArr.concat(resFileSizeArr))[0] : this.checkFilesSize(resFiles, resFileSizeArr)[0],
                    fileSizeArr: prevState.fileSizeArr && prevState.files ? this.checkFilesSize(prevState.files.concat(resFiles), prevState.fileSizeArr.concat(resFileSizeArr))[1] : this.checkFilesSize(resFiles, resFileSizeArr)[1],
                }));
            };
            let fileForReader = file;
            if (fileForReader) {
                reader.readAsArrayBuffer(fileForReader);
                this.setState({
                    showFileError: false
                })
            } else {
                this.setState({
                    showFileError: true
                })
            }
        });
    }

    onCancel() {
        this.setState({
            files: null
        });
    }

    checkFilesSize(fileArr, sizeArr) {
        let limit = 4194304;
        let sum = 0;
        for (let i = 0; i < sizeArr.length; i++) {
            sum += sizeArr[i];
            if (sum > limit) {
                sizeArr.length = i;
                fileArr.length = i;
            }
        }
     return [fileArr, sizeArr];
    }

    removeUpload(idx) {
        let fileArr = [...this.state.files];
        let sizeArr = [...this.state.fileSizeArr];
            fileArr.splice(idx, 1);
            sizeArr.splice(idx, 1);
            this.setState({
                files: fileArr,
                fileSizeArr: sizeArr
            });
        }

    checkTextArea(text) {
        return !text || !text.value.replace(/\s/g, '').length || !text.value || /((javascript:)|(<)|(>))/.test(text.value);
    }

    formulateEmailMessage() {
        let profile = this.props.userReducer.profile;
        this.props.i18n.locale === 'el' ? moment.locale('el') : moment.locale('en');
        let subject = I18n.t("modals.support.email.footer.subject") + " " + this.state.companyTitle;
        let emailContent = {
            portalLabel: I18n.t("modals.support.email.footer.portalLabel"),
            timeStamp: moment().format('dddd DD MMMM HH:mm:ss'),
            afm: this.state.company.value,
            mid: this.state.mid ? this.state.mid: "-",
            tid: this.state.tid ? this.state.tid.value : "-",
            userEmail: profile.email,
            userRealName: profile.firstName + " " + profile.lastName,
            mobile: profile.phone,
            title: this.state.companyTitle,
            name: this.state.companyName,
            message: this.state.message.value,
            attachments: this.state.files,
            section: "footerSupport",
            labels: {
                timeStamp: I18n.t("modals.support.email.labels.timeStamp"),
                taxId: I18n.t("modals.support.email.labels.taxId"),
                mid: I18n.t("modals.support.email.labels.mid"),
                tid: I18n.t("modals.support.email.labels.tid"),
                email: I18n.t("modals.support.email.labels.email"),
                fullName: I18n.t("modals.support.email.labels.fullName"),
                mobile: I18n.t("modals.support.email.labels.mobile"),
                corpTitle: I18n.t("modals.support.email.labels.corpTitle"),
                message: I18n.t("modals.support.email.labels.message")
            }
        };

        store.dispatch(emailSupport(subject, emailContent)).then(
            (response) => {
                this.setState({
                    emailSent: "sent"
                })

            },
            (error) => {
                this.setState({
                    emailSent: "notSent"
                })
            }
        )
    }


    render() {
        return (
            <div className="support-form__content">
                {!this.props.emailReducer.postage.fetching && !this.state.emailSent ?
                <form onSubmit={(e) => {
                    e.preventDefault();
                    this.formulateEmailMessage();
                }}>
                    <div className="support-form__block__row">
                        <div className="support-form__textarea">
                            <Translate value="modals.support.textArea.title"/>
                            <textarea value={this.state.value}
                                      maxLength="10000"
                                      onChange={(event) => {
                                          this.handleChange('message', event.target.value, "message", false)
                                      }}
                                      placeholder={I18n.t("modals.support.textArea.placeholder")}/>
                            {this.state.emailBodyError ?
                                <div className="support-error-file">
                                    <span className="icon-icon-attention support-error-file__icon"></span>
                                    <Translate className="support-error-file__text"
                                               value="modals.support.textArea.contentError"/>
                                </div> : null}
                        </div>
                    </div>
                    <div className="support-form__block__row support-form__file-attachment">
                        <section>
                            <div className="dropzone">
                                <Dropzone
                                    accept="image/*, application/pdf"
                                    onDrop={this.onDrop}
                                    maxSize={4194304}
                                    multiple={true}
                                    onFileDialogCancel={this.onCancel}
                                    className="dropzone support-form__dropzone"
                                >
                                    <Translate value="modals.support.fileUpload.dragAndDrop" dangerousHTML  />
                                </Dropzone>
                            </div>
                            {this.state.files ?
                                <div>
                                    {this.state.files.length > 0 ?
                                    <h2>
                                        <Translate value="modals.support.fileUpload.attachedFiles"/>
                                    </h2>: null}
                                    <ul>
                                        {this.state.files.map((f, idx) => <li key={idx + "_" + f.name + "-"}>{f.name} - {bytesToSize(this.state.fileSizeArr[idx], 2)}
                                        <i className="icon-failed support-form__remove-attachment" onClick={() => this.removeUpload(idx)}></i>
                                        </li>)}
                                    </ul>
                                </div>:null}
                                {this.state.showFileError ?
                                    <div className="support-error-file">
                                        <span className="icon-icon-attention support-error-file__icon"></span>
                                        <Translate className="support-error-file__text" value="modals.support.fileUpload.error" />
                                    </div>: null}
                        </section>
                    </div>
                    <div className="support-form__button-wrapper">
                        <button type="submit" onClick={this.props.hideModalHeader}
                                disabled={this.checkTextArea(this.state.message)}>
                            <Translate value="modals.support.buttonSubmit"/>
                        </button>
                    </div>
                </form>:
                    <div>
                        {this.state.emailSent ?
                            <ResponseMessage response={this.state.emailSent} closeModal={this.props.closeModal} />:
                            <Loader bouncing={true}
                                    color={'bahama-blue'}
                                    message="modals.support.emailFetching"
                                    loaderStyles={{minHeight: "120px"}}/>
                        }
                    </div>
                }
            </div>
        )
    }
}

export default withRouter(connect(stateMap)(FooterSupportForm));