export default function rightColumnReducer(state = {
    midSearched: null,
    afmSearched: null,
    countsTotalsExcel: {
        fetching: false,
        fetched: false
    },
    chartsTransactionExcel: {
        fetching: false,
        fetched: false
    },
    chartsBankTotalsExcel: {
        fetching: false,
        fetched: false
    },
    chartsCardSchemeTotalsExcel: {
        fetching: false,
        fetched: false
    },
    chartsCardModeTotalsExcel: {
        fetching: false,
        fetched: false
    },
    chartsApprovedSettledExcel: {
        fetching: false,
        fetched: false
    },
    chartsInstallmentsExcel: {
        fetching: false,
        fetched: false
    },
    chartsRedemptionExcel: {
        fetching: false,
        fetched: false
    },
    availableReports: {
        fetching: false,
        fetched: false
    },
    branchReports: {
        fetching: false,
        fetched: false
    },
    companyReports: {
        fetching: false,
        fetched: false
    },
    fysikoAerioReports: {
        fetching: false,
        fetched: false
    }


}, action) {
    switch (action.type) {
        case "RIGHT_COLUMN_MID_SEARCH": {
            return {
                ...state,
                afmSearched: action.afmSearched,
                midSearched: action.midSearched,
                tidSearched: action.tidSearched
            }
        }
        case "GET_CHARTS_COUNTS_TOTALS_EXCEL_PENDING": {
            return {
                ...state,
                countsTotalsExcel: {
                    fetching: true,
                    fetched: false
                }
            }
        }
        case "GET_CHARTS_COUNTS_TOTALS_EXCEL_REJECTED": {
            return {
                ...state,
                countsTotalsExcel: {
                    fetching: false,
                    fetched: false
                }
            }
        }
        case "GET_CHARTS_COUNTS_TOTALS_EXCEL_FULFILLED": {
            return {
                ...state,
                countsTotalsExcel: {
                    fetching: false,
                    fetched: true
                }
            }
        }
        case "GET_CHARTS_TRANSACTION_EXCEL_PENDING": {
            return {
                ...state,
                chartsTransactionExcel: {
                    fetching: true,
                    fetched: false
                }
            }
        }
        case "GET_CHARTS_TRANSACTION_EXCEL_REJECTED": {
            return {
                ...state,
                chartsTransactionExcel: {
                    fetching: false,
                    fetched: false
                }
            }
        }
        case "GET_CHARTS_TRANSACTION_EXCEL_FULFILLED": {
            return {
                ...state,
                chartsTransactionExcel: {
                    fetching: false,
                    fetched: true
                }
            }
        }
        case "GET_CHARTS_BANK_TOTALS_EXCEL_PENDING": {
            return {
                ...state,
                chartsBankTotalsExcel: {
                    fetching: true,
                    fetched: false
                }
            }
        }
        case "GET_CHARTS_BANK_TOTALS_EXCEL_REJECTED": {
            return {
                ...state,
                chartsBankTotalsExcel: {
                    fetching: false,
                    fetched: false
                }
            }
        }
        case "GET_CHARTS_BANK_TOTALS_EXCEL_FULFILLED": {
            return {
                ...state,
                chartsBankTotalsExcel: {
                    fetching: false,
                    fetched: true
                }
            }
        }
        case "GET_CHARTS_CARD_SCHEME_TOTALS_EXCEL_PENDING": {
            return {
                ...state,
                chartsCardSchemeTotalsExcel: {
                    fetching: true,
                    fetched: false
                }
            }
        }
        case "GET_CHARTS_CARD_SCHEME_TOTALS_EXCEL_REJECTED": {
            return {
                ...state,
                chartsCardSchemeTotalsExcel: {
                    fetching: false,
                    fetched: false
                }
            }
        }
        case "GET_CHARTS_CARD_SCHEME_TOTALS_EXCEL_FULFILLED": {
            return {
                ...state,
                chartsCardSchemeTotalsExcel: {
                    fetching: false,
                    fetched: true
                }
            }
        }
        case "GET_CHARTS_CARD_MODE_TOTALS_EXCEL_PENDING": {
            return {
                ...state,
                chartsCardModeTotalsExcel: {
                    fetching: true,
                    fetched: false
                }
            }
        }
        case "GET_CHARTS_CARD_MODE_TOTALS_EXCEL_REJECTED": {
            return {
                ...state,
                chartsCardModeTotalsExcel: {
                    fetching: false,
                    fetched: false
                }
            }
        }
        case "GET_CHARTS_CARD_MODE_TOTALS_EXCEL_FULFILLED": {
            return {
                ...state,
                chartsCardModeTotalsExcel: {
                    fetching: false,
                    fetched: true
                }
            }
        }
        case "GET_CHARTS_APPROVED_SETTLED_EXCEL_PENDING": {
            return {
                ...state,
                chartsApprovedSettledExcel: {
                    fetching: true,
                    fetched: false
                }
            }
        }
        case "GET_CHARTS_APPROVED_SETTLED_EXCEL_REJECTED": {
            return {
                ...state,
                chartsApprovedSettledExcel: {
                    fetching: false,
                    fetched: false
                }
            }
        }
        case "GET_CHARTS_APPROVED_SETTLED_EXCEL_FULFILLED": {
            return {
                ...state,
                chartsApprovedSettledExcel: {
                    fetching: false,
                    fetched: true
                }
            }
        }
        case "GET_CHARTS_INSTALLMENTS_EXCEL_PENDING": {
            return {
                ...state,
                chartsInstallmentsExcel: {
                    fetching: true,
                    fetched: false
                }
            }
        }
        case "GET_CHARTS_INSTALLMENTS_EXCEL_REJECTED": {
            return {
                ...state,
                chartsInstallmentsExcel: {
                    fetching: false,
                    fetched: false
                }
            }
        }
        case "GET_CHARTS_INSTALLMENTS_EXCEL_FULFILLED": {
            return {
                ...state,
                chartsInstallmentsExcel: {
                    fetching: false,
                    fetched: true
                }
            }
        }
        case "GET_CHARTS_REDEMPTION_EXCEL_PENDING": {
            return {
                ...state,
                chartsRedemptionExcel: {
                    fetching: true,
                    fetched: false
                }
            }
        }
        case "GET_CHARTS_REDEMPTION_EXCEL_REJECTED": {
            return {
                ...state,
                chartsRedemptionExcel: {
                    fetching: false,
                    fetched: false
                }
            }
        }
        case "GET_CHARTS_REDEMPTION_EXCEL_FULFILLED": {
            return {
                ...state,
                chartsRedemptionExcel: {
                    fetching: false,
                    fetched: true
                }
            }
        }
        case "GET_BRANCH_REPORTS_PENDING": {
            return {
                ...state,
                branchReports: {
                    fetching: true,
                    fetched: false
                }
            }
        }
        case "GET_BRANCH_REPORTS_REJECTED": {
            return {
                ...state,
                branchReports: {
                    fetching: false,
                    fetched: false
                }
            }
        }
        case "GET_BRANCH_REPORTS_FULFILLED": {
            return {
                ...state,
                branchReports: {
                    fetching: false,
                    fetched: true
                }
            }
        }
        case "GET_COMPANY_REPORTS_PENDING":
        case "GET_COMPANY_REPORTS_COUNT_PENDING": {
            return {
                ...state,
                companyReports: {
                    fetching: true,
                    fetched: false
                }
            }
        }
        case "GET_COMPANY_REPORTS_REJECTED":
        case "GET_COMPANY_REPORTS_COUNT_REJECTED": {
            return {
                ...state,
                companyReports: {
                    fetching: false,
                    fetched: false
                }
            }
        }
        case "GET_COMPANY_REPORTS_FULFILLED":
        case "GET_COMPANY_REPORTS_COUNT_FULFILLED": {
            return {
                ...state,
                companyReports: {
                    fetching: false,
                    fetched: true
                }
            }
        }
        case "GET_FYSIKO_AERIO_REPORTS_PENDING": {
            return {
                ...state,
                fysikoAerioReports: {
                    fetching: true,
                    fetched: false
                }
            }
        }
        case "GET_FYSIKO_AERIO_REPORTS_REJECTED": {
            return {
                ...state,
                fysikoAerioReports: {
                    fetching: false,
                    fetched: false
                }
            }
        }
        case "GET_FYSIKO_AERIO_REPORTS_FULFILLED": {
            return {
                ...state,
                fysikoAerioReports: {
                    fetching: false,
                    fetched: true
                }
            }
        }
        case "GET_AVAILABLE_REPORTS_PENDING": {
            return {
                ...state,
                availableReports: {
                    fetching: true,
                    fetched: false
                }
            }
        }
        case "GET_AVAILABLE_REPORTS_REJECTED": {
            return {
                ...state,
                availableReports: {
                    fetching: false,
                    fetched: false
                }
            }
        }
        case "GET_AVAILABLE_REPORTS_FULFILLED": {
            return {
                ...state,
                availableReports: {
                    fetching: false,
                    fetched: true
                }
            }
        }
        default : {
            return state
        }
    }
}