import React from "react";
import {connect} from "react-redux";
import ResetPasswordStep1 from "./resetPasswordSteps/ResetPasswordStep1";
import ResetPasswordStep2 from "./resetPasswordSteps/ResetPasswordStep2";
import {withRouter} from "react-router-dom";

import queryString from 'query-string';

const stateMap = (store) => {
    return {
        i18n: store.i18n,
        userReducer: store.userReducer
    };
};

class ResetPassword extends React.Component {
    constructor(props) {
        super(props)

        this.state = {}

    }

    componentDidMount() {
        if (this.props.userReducer.userLoggedIn) {
            this.props.history.push("/overview")
        }
    }

    render() {
        const parsed = queryString.parse(this.props.location.search);
        return (
            !this.props.userReducer.userLoggedIn ?
                <React.Fragment>
                    {parsed && parsed.resetPasswordCode && parsed.email !== '' ?
                        <ResetPasswordStep2 email={parsed.email}
                                            phone={this.props.userReducer.phone}
                                            urlBase={this.props.userReducer.urlBase}
                                            resetPasswordCode={parsed.resetPasswordCode}/> :
                        <ResetPasswordStep1/>}
                </React.Fragment> : null
        )
    }
}

export default withRouter(connect(stateMap)(ResetPassword));