import React from "react";
import {NavLink, withRouter} from "react-router-dom";
import {nullifyModalAction} from "../../actions/SideModalActions";
import store from "../../store";
import SlidingPanel from "./SlidingPanel";
import connect from "react-redux/es/connect/connect";
import {reInitCookieBanner, toggleSideModal} from "../../lib/utils";
import Cookiebot from "../../lib/cookieService";
import {
    switchHelpOverlay,
    toggleHelpBubble,
    toggleHelpPopovers,
    toggleInfoTooltips,
    toggleLeftHotSpots
} from "../../actions/userActions";
import Overlay from "react-bootstrap/es/Overlay";
import Popover from "react-bootstrap/es/Popover";
import OrderStatus from "../sidebar/OrderStatus";

const Translate = require('react-redux-i18n').Translate;

const stateMap = (store) => {
    return {
        sideModalReducer: store.sideModalReducer,
        userReducer: store.userReducer,
        merchantReducer: store.merchantReducer
    };
};

const hotProps = [
    'companiesDropdown',
    'storesMultiselect',
    'reportsTransactions',
    'quickSearch',
    'reportsBanks',
    'downloadReports',
    'cardScheme',
    'cardsDates',
    'transactionTypes',
    'exportChartHints',
    'downloadBusinessExcel',
    'eCommerceBusiness',
    'transactionExcelButton',
    'transactionDetailsInfo',
    'transactionSettlingInfo',
    'settledTransactions',
    'pendingTransactions',
    'threeDSecureHeader',
    'isSettledTransactionHeader',
    'responseCodeHeader'
];

const allTooltips = [
    'company-tk-tltp',
    'mid-tltp',
    'quickfind-tltp',
    'refund-tltp',
    'multiple-mid-popover-tltp',
    'business-export-tltp',
    'business-export-tid-tltp',
    'report-download-multiple-mid-tltp',
    'reports-right-column-title-tltp',
    'download-trans-tltp',
    'business-mid-alias-tltp',
    'download-trans-tltp',
    'business-tid-alias-tltp',
    'reports-transactions-chart-tltp',
    'overview-kpi-title-tltp',
    'duplicate-transactions-tltp'
];

class SideBar extends React.Component {
    constructor(props) {
        super(props)

        this.switchElement = React.createRef();

        this.state = {
            isOpen: false,
            isProfileOpen: false,
            isUserManagementOpen: false,
            hasOrderStatus: false,
            expandCookie: false,
            showHotSpots: false
        };

        this.toggleMenu = this.toggleMenu.bind(this)
        this.closeOverlay = this.closeOverlay.bind(this)
        this.deselectAll = this.deselectAll.bind(this)
        this.toggleHotSpots = this.toggleHotSpots.bind(this)
        this.toggleSpecificHotSpot = this.toggleSpecificHotSpot.bind(this)
        this.closeHotSpots = this.closeHotSpots.bind(this)
        this.closeHotPopovers = this.closeHotPopovers.bind(this)
        this.toggleLeftHotSpots = this.toggleLeftHotSpots.bind(this)
        this.closeTooltips = this.closeTooltips.bind(this)
        this.closeBubble = this.closeBubble.bind(this)
        this.toggleCookies = this.toggleCookies.bind(this)
    }

    componentWillMount() {
        store.dispatch(switchHelpOverlay(false));
        this.toggleLeftHotSpots(false);
        this.closeTooltips();
        this.setState({
            hasOrderStatus: this.props.merchantReducer.orderStatus
        })
    }

    componentWillReceiveProps(nextProps) {
        if (nextProps.sideModalReducer !== this.props.sideModalReducer) {
            this.setState({
                isOpen: nextProps.sideModalReducer.isProfile || nextProps.sideModalReducer.isUserManagement,
                isProfileOpen: nextProps.sideModalReducer.isProfile,
                isUserManagementOpen: nextProps.sideModalReducer.isUserManagement
            }, () => {
                this.toggleLeftHotSpots(this.state.isOpen);
            })
        }
        if (nextProps.merchantReducer.orderStatus !== this.props.merchantReducer.orderStatus ) {
            this.setState({
                hasOrderStatus: nextProps.merchantReducer.orderStatus
            })
        }
    }

    componentDidMount() {
        setTimeout(() => {
            this.closeBubble()
        }, 8500)
    }

    deselectAll() {
        this.setState({
            isProfileOpen: false,
            isUserManagementOpen: false
        })
    }


    getInitialState() {
        return {isOpen: false};
    }

    toggleMenu() {
        this.setState(prevState => ({
            isOpen: !prevState.isOpen
        }), () => {
            if (!this.state.isOpen) {
                store.dispatch(nullifyModalAction())
            } else {
                this.closeTooltips()
                this.closeHotPopovers()
            }
        });

    }

    closeOverlay() {
        this.setState({
            isOpen: false
        });
    }

    toggleHotSpots() {
        this.setState(prevState => ({
            showHotSpots: !prevState.showHotSpots
        }), () => {
            store.dispatch(switchHelpOverlay(this.state.showHotSpots));
            if (this.state.showHotSpots) {
                this.closeTooltips()
            }
        });
    }

    toggleSpecificHotSpot(hotProp) {
        store.dispatch(toggleHelpPopovers("showHelp__" + hotProp, false));
    }

    closeHotPopovers() {
        hotProps.forEach(elProp => {
            store.dispatch(toggleHelpPopovers("showHelp__" + elProp, false))
        });
    }

    closeHotSpots() {
        store.dispatch(switchHelpOverlay(false));
        this.setState({
            showHotSpots: false
        })
    }

    toggleLeftHotSpots(hide) {
        let leftHotSpots = ['companiesDropdown', 'reportsBanks', 'transactionTypes', 'exportChartHints', 'transactionDetailsInfo', 'isSettledTransactionHeader'];
        leftHotSpots.forEach((hotSpot => {
            store.dispatch(toggleLeftHotSpots('hide__' + hotSpot, hide));
            this.toggleSpecificHotSpot(hotSpot)

        }))
    }

    closeTooltips() {
        allTooltips.forEach(elProp => {
            store.dispatch(toggleInfoTooltips("showInfo__" + elProp.replace(/-/g, "_"), false))
        })
    }

    closeBubble() {
        store.dispatch(toggleHelpBubble(false));
    }

    toggleCookies() {
        this.setState(prevState => ({
            expandCookie: !prevState.expandCookie
        }));
    }

    render() {
        let cookiebot = Cookiebot;
        return (
            <div>
                {this.props.userReducer.showHotSpots ?
                    <div className="blocking-overlay__wrapper">
                        <div className="blocking-overlay" onClick={this.closeHotPopovers}></div>
                    </div>
                    : null}
                <SlidingPanel data={this.state} deselectAll={this.deselectAll}/>
                <div className={"menu__overlay" + (this.state.isOpen ? " overlay_open" : " overlay_close")}
                     onClick={this.closeOverlay}></div>
                <div id="burger-menu-toggler" className={"burger-btn" + (this.state.isOpen ? " open" : "")}
                     onClick={this.toggleMenu}>
                    <span></span>
                    <span></span>
                    <span></span>
                    <span></span>
                </div>
                <aside id="left-panel" className={this.state.isOpen ? "menu_open" : " menu_close"}>
                    <nav>
                        <NavLink to={"/overview"} activeClassName="_active" onClick={() => {
                            store.dispatch(nullifyModalAction())
                            this.deselectAll()
                        }}>
                            <Translate value="navMenu.links.overview"/>
                        </NavLink>

                        <NavLink to={"/business"} activeClassName="_active" onClick={() => {
                            store.dispatch(nullifyModalAction())
                            this.deselectAll()
                        }}>
                            <Translate value="navMenu.links.business"/>
                        </NavLink>

                        <NavLink to={"/transactions"} activeClassName="_active" onClick={() => {
                            store.dispatch(nullifyModalAction())
                            this.deselectAll()
                        }}>
                            <Translate value="navMenu.links.transactions"/>
                        </NavLink>
                        <NavLink to={"/reports"} activeClassName="_active" onClick={() => {
                            store.dispatch(nullifyModalAction())
                            this.deselectAll()
                        }}>
                            <Translate value="navMenu.links.reports"/>
                        </NavLink>

                    </nav>
                    <ul className="modal-list">
                        <li className={'modal-list__item ' + (this.state.isProfileOpen ? '_active__modal' : '')}
                            onClick={() => {
                                toggleSideModal(true, "isProfile")
                                this.toggleLeftHotSpots(true)
                                this.closeTooltips()
                            }}
                        >
                            <Translate value="modalList.options.profile"/>
                        </li>
                        {/* TODO hidden-elements-return*/}
                        {/*<li className="modal-list__item"*/}
                        {/* //     onClick={() => {
                        //     this.toggleSideModal(true, "isNotification")
                        // }}
                        // >
                        //     <Translate value="modalList.options.notifications"/>
                        // </li>
                        {/* TODO hidden-elements-return*/}
                        {this.props.userReducer.profile.super_user ?
                            <li className={'modal-list__item ' + (this.state.isUserManagementOpen ? '_active__modal' : '')}
                                onClick={() => {
                                    toggleSideModal(true, "isUserManagement")
                                    this.toggleLeftHotSpots(true)
                                    this.closeTooltips()
                                }}
                            >
                                <Translate value="modalList.options.userManagement"/>
                            </li>
                            : null}
                        {/*<li className="modal-list__item" onClick={() => {*/}
                        {/*this.toggleSideModal(true, "isSmartSettings")*/}
                        {/*}}>*/}
                        {/*<Translate value="modalList.options.smartSettings"/>*/}
                        {/*</li>*/}
                    </ul>
                    <div className="external-list">
                        {/*<a className="external-list__link">*/}
                        {/*<Translate value="externalList.links.support"/>*/}
                        {/*</a>*/}
                        <Overlay
                            show={this.props.userReducer.showHelp__bubble}
                            target={this.state.target}
                            placement="right"
                            container={this}
                            containerPadding={20}>
                            <Popover id="popover-overlay-help" className='overlay-help__bubble'>
                                <div className='overlay-help__bubble--content_wrapper'>
                                    <div className="icon-x-icon overlay-help__bubble--close"
                                         onClick={this.closeBubble}/>
                                    <div className="icon-kpi-arrow overlay-help__bubble--arrow"/>
                                    <div className='overlay-help__bubble--content'>
                                        <Translate className='overlay-help__bubble--title'
                                                   value='navMenu.bubble.title'/>
                                        <Translate className='overlay-help__bubble--text' value='navMenu.bubble.text'/>
                                    </div>
                                </div>
                            </Popover>
                        </Overlay>
                        <div className={"overlay-help" + (this.props.userReducer.showHotSpots ? " _active" : '')}
                             onClick={this.toggleHotSpots}>
                            <Translate value="externalList.links.help" className="switch-item"
                            />
                            <input type="checkbox"
                                   ref={this.switchElement}
                                   name="overlay-help-switch"
                                   className="cbx"
                                   id="overlay-help-switch"
                                   defaultChecked={this.state.showHotSpots}
                            />
                            <label htmlFor="overlay-help-switch"
                                   onClick={this.toggleHotSpots}
                                   className={"toggle "}>
                                <span></span>
                            </label>
                        </div>
                        <a className="external-list__link"
                           onClick={() => {
                               this.props.showTermsAndConditions(true);
                               this.closeHotSpots();
                           }}
                        >
                            <Translate value="externalList.links.terms"/>
                        </a>
                        <a className="external-list__link"
                           onClick={() => {
                               cookiebot.hide();
                               this.props.togglePoliciesModal('privacyPolicy', true);
                               this.closeHotSpots()
                           }}
                        >
                            <Translate value="externalList.links.privacyPolicy"/>
                        </a>
                        <div className={"external-list__link external-list__link--expandable" +
                             (this.state.expandCookie ?
                                 " external-list__link--expandable_expanded": "")}
                             onClick={this.toggleCookies}>
                            <Translate value="externalList.links.cookies"/>
                            <span className="caret" />
                            <div className="external-list__link--content">
                                <a className="external-list__link--item"
                                   onClick={() => {
                                       cookiebot.renew();
                                       reInitCookieBanner();
                                       this.closeHotSpots();
                                   }}
                                >
                                    <Translate value="externalList.links.cookiePreferences"/>
                                </a>
                                <a className="external-list__link--item"
                                   onClick={() => {
                                       cookiebot.hide();
                                       this.props.togglePoliciesModal('cookiesPolicy', true);
                                       this.closeHotSpots()
                                   }}
                                >
                                    <Translate value="externalList.links.cookiesPolicy"/>
                                </a>
                            </div>
                        </div>
                    </div>
                    {this.state.hasOrderStatus ? <OrderStatus/>:null}
                </aside>
            </div>
        )
    }
}

export default withRouter(connect(stateMap)(SideBar));