import React from "react";
import {connect} from "react-redux";
import {withRouter} from "react-router-dom";
import Wave from "../common/Wave";
import Logo from "../common/Logo";
import LocaleSwitch from "../common/LocaleSwitch";

const Translate = require('react-redux-i18n').Translate;



const stateMap = (store) => {
    return {
        i18n: store.i18n,
        userReducer: store.userReducer
    };
};

class SuccessfulRegistration extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            successfulMerchantRegistration: props.location.state ? this.props.location.state.successfulMerchantRegistration : 'default'
        }
    }

    componentWillMount() {
        if (!this.props.location.state) {
            this.props.history.push("/overview")
        } else {
            this.state = {
                successfulMerchantRegistration: this.props.location.state ? this.props.location.state.successfulMerchantRegistration : 'default'
            }
        }
    }

    componentDidMount() {
        if (this.props.userReducer.userLoggedIn) {
            this.props.history.push("/overview")
        }
    }


    render() {
        return (
            !this.props.userReducer.userLoggedIn ?
                <React.Fragment>
                    <main className="registration-information-page">
                        <div className="login-area__top">
                            <LocaleSwitch type={"login-area"}></LocaleSwitch>
                        </div>
                        <section className='msg__wrapper'>
                            <Logo isMain={false} isHeader={false} imgClassName="login"/>
                            <h1 className='msg__title'>
                                <Translate value="pages.register.successfulMerchantRegistrationTitle"/>
                            </h1>
                            <article className='msg__text'>
                                <span className="icon-pending icon-pending__registration"></span>
                                <div className="successful-merchant-registration-msg">
                                    <Translate value="pages.register.successfulMerchantRegistration"/>
                                </div>
                            </article>
                        </section>
                        <Wave />
                    </main>
                </React.Fragment> : null
        )
    }
}

export default withRouter(connect(stateMap)(SuccessfulRegistration));