import React from "react";
import Cropper from 'react-easy-crop';
import {Button} from "react-bootstrap";
import getCroppedImg from './CropImage';
import getScaledImg from "./ScaleImage";

const Translate = require('react-redux-i18n').Translate;
const I18n = require('react-redux-i18n').I18n;

const cropStyles = {
                        cropAreaStyle: {
                            outline: "5px dashed rgba(255, 255, 255, 1)",
                            color: "rgba(0, 0, 0, 0.85)"
                        },
                        containerStyle: {
                            backgroundColor: "rgba(255, 255, 255, 1))"
                        }
                    }

export default class SealCropper extends React.Component {
    constructor(props) {
        super(props)
        this.state = {
            imageSrc: null,
            crop: {x: 0, y: 0},
            zoom: 1,
            // aspect: screenLimit() ? 16 / 9 : 4 / 3,
            aspect: 16 / 9,
            cropSize: {width: 330, height: 210},
            showGrid: false,
            croppedImage: null

        }
        this.onCropChange = this.onCropChange.bind(this)
        this.onCropComplete = this.onCropComplete.bind(this)
        this.onZoomChange = this.onZoomChange.bind(this)
        this.getCroppedImage = this.getCroppedImage.bind(this)
        this.getScaledImage = this.getScaledImage.bind(this)
        this.calculateAspectRatio  = this.calculateAspectRatio.bind(this)
        this.setAspectRatio  = this.setAspectRatio.bind(this)
    }

    componentDidUpdate(prevProps: Readonly<P>, prevState: Readonly<S>, snapshot: SS) {
        if (prevProps.imageUploaded !== this.props.imageUploaded) {
            this.setState({
                imageSrc: this.props.imageUploaded
            }, ()=> {
                // this.setAspectRatio(this.props.imageUploaded)
            });
        }
    }

    componentDidMount() {
        if (this.props.imageUploaded) {
            this.setState({
                imageSrc: this.props.imageUploaded
            }, ()=> {
                // this.setAspectRatio(this.props.imageUploaded);
            });
        }
    }

    calculateAspectRatio = (width, height) => {
        return (height === 0) ? width : this.calculateAspectRatio(height, width % height);
    }

    setAspectRatio(imgUploaded) {
        let that = this;
        let img = new Image();
        img.onload = function(){
            let ratio = that.calculateAspectRatio(img.width, img.height)
            that.setState({
                aspect: (img.width / ratio) / (img.height / ratio),
                zoom: 1
            });
        };
        img.src = imgUploaded;
    }

    onCropChange = crop => {
        this.setState({ crop })
    }

    onCropComplete = (croppedArea, croppedAreaPixels) => {
        this.setState({
            croppedAreaPixels: croppedAreaPixels
        }, ()=> {
            this.getCroppedImage()
        })
    }

    onZoomChange = zoom => {
        this.setState({ zoom })
    }

   getCroppedImage = async () => {
        try {
            const croppedImage = await getCroppedImg(
                this.state.imageSrc,
                this.state.croppedAreaPixels
            );
            this.getScaledImage(croppedImage);
        } catch(err) {
            console.error(err)
        }
    }

    getScaledImage = async (croppedImg) => {
        try {
            let scaledImg = await getScaledImg(croppedImg, this.state.croppedAreaPixels);
            this.props.setCroppedAndScaledImage(scaledImg);
        } catch(err) {
            console.error(err)
        }
    }

    render() {
        return (
            <React.Fragment>
                {this.state.imageSrc ?
                <div className="signature-cropper">
                    <Translate className="signature-cropper__label" value="pages.register.imageIcon" />
                    <Translate className="signature-cropper__hint" value="pages.register.imageHint" dangerousHTML />
                    <div className="signature-cropper__controls">
                        <div className="signature-cropper__controls--content">
                            <Button className="signature-cropper__controls--btn"
                                    variant="primary"
                                    type="button"
                                    title={I18n.t("pages.register.zoomIn")}
                                    onClick={() => {
                                        let zoom = JSON.parse(JSON.stringify(this.state.zoom));
                                        zoom+= 0.1;
                                        this.onZoomChange(zoom)
                                    }}>
                                <i className="icon-ico-magnifier">
                                </i>
                                <span>
                                  {"+"}
                                </span>
                            </Button>
                            <Button className="signature-cropper__controls--btn signature-cropper__controls--zoom-out"
                                    variant="primary"
                                    type="button"
                                    title={I18n.t("pages.register.zoomOut")}
                                    onClick={() => {
                                        let zoom = JSON.parse(JSON.stringify(this.state.zoom));
                                        zoom-= 0.1;
                                        this.onZoomChange(zoom)
                                    }}>
                                <i className="icon-ico-magnifier">
                                </i>
                                <span>
                                  {"-"}
                                </span>
                            </Button>
                        </div>
                    </div>
                    <div className="signature-cropper__content">
                        <div className="signature-cropper__container">
                            <Cropper
                                image={this.state.imageSrc}
                                crop={this.state.crop}
                                zoom={this.state.zoom}
                                aspect={this.state.aspect}
                                cropSize={this.state.cropSize}
                                showGrid={this.state.showGrid}
                                restrictPosition={false}
                                onCropChange={this.onCropChange}
                                onCropComplete={this.onCropComplete}
                                onZoomChange={this.onZoomChange}
                                style={cropStyles}
                            />
                        </div>
                    </div>

                </div>:null}
            </React.Fragment>
        )
    }
}
