export default {
    dropdownCompanies: 'Όλες οι επιχειρήσεις',
    dropdownStores: 'Επίλεξε κατάστημα (MID)',
    mainMultipleMID: 'Πρόσθεσε κωδικούς MID για να εμφανιστούν αποτελέσματα που αφορούν αποκλειστικά αυτά τα καταστήματα.',
    socialMedia: 'Ακολούθησέ μας στα social media',
    startScreen: {
        welcome: 'Καλώς όρισες στο ',
        cardlinkPortal: 'Cardlink apollo',
        selectHomepageLabel_a: 'Θέλω η ',
        selectHomepageLabel_b: 'Επισκόπηση',
        selectHomepageLabel_c: ' να γίνει',
        selectHomepageLabel_d: ' η αρχική μου οθόνη στο Cardlink apollo',
        modal: {
            caption: "Καλώς όρισες στο ανανεωμένο μας Portal, Cardlink apollo, τον πολύτιμο συνεργάτη της επιχείρησής σου!",
            text: "Στο Cardlink apollo θα βρεις πληροφορίες για τις συναλλαγές κα τα POS της επιχείρησής σου, στατιστικά στοιχεία και χρήσιμα reports."
        },
        option: {
            interactiveTour: {
                newUser: 'ΝΕΟΣ ΧΡΗΣΤΗΣ;',
                title: 'Ξεκίνα την περιήγησή σου εδώ...'
            },
            overview: {
                title: 'Επισκόπηση',
                text: 'Πάτα εδώ και δες στο Dashboard τις πληροφορίες που σε ενδιαφέρουν με μια ματιά!',
            },
            business: {
                title: 'Eπιχείρηση',
                text: 'Δες πληροφορίες που αφορούν τη συνεργασία της επιχείρησής σου με την Cardlink.',
            },
            transactions: {
                title: 'Συναλλαγές',
                text: 'Εδώ θα βρεις όλες τις πληροφορίες για τις συναλλαγές σου.'
            },
            reports: {
                title: 'Reports',
                text: 'Βρες στατιστικά στοιχεία και reports.',
            },
            smartSettings: {
                title: 'Έξυπνες ρυθμίσεις',
                text: 'Κάνε το Portal να δουλέψει για εσένα επιλέγοντας αυτοματισμούς και ρυθμίσεις.'
            }
        }
    },
    pages: {
        login: {
            portal: 'Cardlink apollo',
            tool: 'Το πολυτιμότερο εργαλείο της επιχείρησής σου!',
            everyDay: 'Κάθε μέρα δίπλα σου με:',
            listItem1: 'Αναλυτικές πληροφορίες για τις  συναλλαγές και τα προϊόντα σου',
            listItem2: 'Χρήσιμα reports',
            listItem3: 'Αποτελεσματική και άμεση βοήθεια και υποστήριξη για ό,τι χρειαστείς',
            email: 'Email',
            emailPlaceholder: '  το email σου',
            capsLockEnabled: '[Κεφαλαία]',
            wrongCreds: ' Λανθασμένα στοιχεία πρόσβασης email/password. <br/>',
            wrongCreds2: ' Δοκίμασε πάλι ή κάνε κλικ στο "Ξέχασα τον κωδικό πρόσβασης" για να τον επαναφέρεις.',
            notAllowedIp: 'Ο λογαριασμός σας δεν είναι προσβάσιμος μέσω της συγκεκριμένης IP.',
            password: 'Κωδικός Πρόσβασης',
            passwordPlaceholder: '  ο μυστικός κωδικός σου ',
            buttonText: 'Σύνδεση',
            forgotPassword: 'Ξέχασα τον κωδικό πρόσβασης',
            newUser: 'Είσαι νέος χρήστης;',
            nonConfirmedAccount: ' Ο λογαριασμός σας δεν είναι ενεργοποιημένος.',
            disabledAccount: ' Ο λογαριασμός σας έχει απενεργοποιηθεί.',
            roleNotMerchant: ' Μόνο οι εγγεγραμμένοι χρήστες έχουν δικαίωμα εισόδου',
            registerIn5Steps: 'Εγγραφή σε 3 βήματα',
            nonMerchantModalMsgA: 'Για την είσοδό σου στο Cardlink Portal, παρακαλούμε στο εξής να χρησιμοποιείς το url: ',
            nonMerchantModalMsgB: ' και τους υφιστάμενους κωδικούς πρόσβασής σου.',
            impersonator: {
                termsNotAccepted: "Ο εν λόγω χρήστης δεν έχει αποδεχτεί τους πιο πρόσφατους όρους χρήσης, πραγματοποιείται αποσύνδεση...",
                expiredPassword: "Ο κωδικός πρόσβασης του εν λόγω χρήστη έχει λήξει, πραγματοποιείται αποσύνδεση...",
                genericError: "Προέκυψε πρόβλημα, πραγματοποιείται αποσύνδεση..."
            }
        },
        reset: {
            help: 'Επικοινώνησε μαζί μας στο support@cardlink.gr ή στο 2106303000',
            needHelp: 'Χρειάζεσαι Βοήθεια;',
            codeNotReceived: 'Πρόβλημα με τον 4ψήφιο κωδικό;',
            email: "Email",
            portal: 'Cardlink apollo',
            resetYourPass: 'Επαναφορά Κωδικού',
            step1: 'ΒΗΜΑ 1',
            step2: 'ΒΗΜΑ 2',
            fillForm: 'Συμπλήρωσε το email σου για να λάβεις μέσω email τον σύνδεσμο για την επαναφορά του κωδικού και μέσω sms έναν κωδικό ασφαλείας.',
            emailPlaceholder: 'το email σου',
            mobilePhone: 'Κινητό Τηλέφωνο',
            buttonText: 'ΥΠΟΒΟΛΗ',
            resendButton: 'Επανάληψη αποστολής',
            returnToLogin: 'Επιστροφή στη login σελίδα',
            mobilePhonePlaceholder: '69',
            enter4DigitCode: 'Προσθέστε τον 4-ψήφιο κωδικό που λάβατε στο κινητό σας τηλέφωνο μέσω μηνύματος.' +
                ' Πληκτρολογήστε το νέο σας κωδικό και πραγματοποιήστε είσοδο στο Cardlink apollo.',
            passwordPlaceholder: 'ο μυστικός κωδικός σου',
            newPassword: 'Νέος κωδικός πρόσβασης',
            retypeNewPassword: 'Επιβεβαίωση νέου κωδικού',
            fourDigitCode: '4-ψήφιος κωδικός',
            sendFourDigitCode: 'Αποστολή Κωδικού',
            allowFewSeconds: 'Παρακαλούμε περίμενε μερικά δευτερόλεπτα για να λάβεις τον κωδικό. Σε περίπτωση που ο ' +
                'κωδικός έχει λήξει, μπορείς να ζητήσεις άλλον. Αν δεν έχεις λάβει τον κωδικό, παρακαλούμε γύρισε στο ' +
                'βήμα 1 και επιβεβαίωσε ότι έχεις πληκτρολογήσει σωστά το τηλέφωνό σου.',
            allowFewSeconds5: '&#9679; Ο 4ψήφιος κωδικός μπορεί να χρειαστεί μερικά δευτερόλεπτα μέχρι να φτάσει στο κινητό σου.<br/>' +
                '&#9679; Εάν δεν έλαβες ποτέ τον κωδικό ή έχει λήξει μπορείς να ζητήσεις να σου σταλεί νέος κωδικός.<br/>' +
                '&#9679; Εάν παρ\' όλ\' αυτά δεν λαμβάνεις τον κωδικό ίσως έχεις βάλει λάθος αριθμό κινητού. Μπορείς να πας στο ' +
                '<a href="/reset"><u>Βήμα 1</u></a> για να ξαναβάλεις τα στοιχεία σου. Αν συνεχίζεις να έχεις πρόβλημα  επικοινώνησε μαζί μας στο 2106303000',
            allowFewSeconds1: 'Ο 4ψήφιος κωδικός μπορεί να χρειαστεί μερικά δευτερόλεπτα μέχρι να φτάσει στο κινητό σου.',
            allowFewSeconds2: 'Εάν δεν έλαβες ποτέ τον κωδικό ή έχει λήξει μπορείς να ζητήσεις να σου σταλεί νέος κωδικός.',
            allowFewSeconds3: 'Εάν παρ\' όλ\' αυτά δεν λαμβάνεις τον κωδικό ίσως έχεις βάλει λάθος αριθμό κινητού. Μπορείς να πας στο ' +
                '                <a href="/reset"><u>Βήμα 1</u></a> για να ξαναβάλεις τα στοιχεία σου. Αν συνεχίζεις να έχεις πρόβλημα  επικοινώνησε μαζί μας στο <i>2106303000</i>',
            matchRequestedFormat: 'Please match the requested format',
            passwordRequirements: 'Ο κωδικός πρέπει να είναι σε λατινικούς χαρακτήρες (τουλάχιστον 12) και να' +
                ' συμπεριλαμβάνει' +
                ' αριθμό, ειδικό χαρακτήρα, πεζά & κεφαλαία',
            phoneRequirements: '',
            passwordsNotMatch: 'Υπάρχει ασυμφωνία μεταξύ των πεδίων',
            successfulPassChange: 'Επιτυχής αλλαγή κωδικού',
            youcanNowLogin: 'Μπορείτε να μπείτε με το νέο σας password στο Cardlink apollo',
            errors: {
                emailNotification: 'Αν είσαι εγγεγραμένος χρήστης, θα λάβεις σύντομα μήνυμα στην ηλεκτρονική διεύθυνση που δήλωσες. Ακολούθησε το σύνδεσμο για να επαναφέρεις τον κωδικό πρόσβασης.',
                invalidNotification: 'Προέκυψε σφάλμα, προσπάθησε αργότερα.',
                anErrorOccured: ' Προέκυψε ένα σφάλμα \n' +
                    'Παρακαλώ προσπαθήστε αργότερα ή επικοινωνήστε μαζί μας στο: support@cardlink.gr ή στο 210 630' +
                    ' 3000',
                codeNotFoundOrExpired: ' Αυτός ο σύνδεσμος δεν είναι πλέον σε ισχύ',
                otpInvalid: 'Ο 4-ψήφιος κωδικός δεν είναι σωστός.',
                otpLimitReached: 'Ξεπέρασες τον μέγιστο αριθμό προσπαθειών. Ο 4-ψήφιος κωδικός έληξε. Παρακαλούμε περίμενε μερικά δευτερόλεπτα για να λάβεις νέο κωδικό.',
                unsuccessfulReset: "Ανεπιτυχής ταυτοποίηση",
                successfulReset: 'Επιτυχής αποστολή',
                successfulReset2: 'Παρακαλούμε έλεγξε το email και το κινητό σου'
            }
        },
        register: {
            sealSizeSpecifications: 'Η εικόνα δεν πρέπει να ξεπερνάει τα 4MB',
            sealTypeSpecifications: 'Πρέπει να επιλέξετε ένα αρχείο εικόνας (jpg, png ή gif)',
            emailExists: 'Το email: %{email} έχει καταχωρηθεί ήδη.',
            afmMidNotFound: 'Έλεγξε τα στοιχεία που έβαλες. Δεν υπάρχει αντιστοιχία ΑΦΜ-MID.',
            duplicateMid: 'Δεν μπορείς να καταχωρήσεις το ίδιο MID παραπάνω από μια φορά.',
            imageMissing: ' Δεν έχει προστεθεί εικόνα',
            nameError: 'Το ονοματεπώνυμο του Υπεύθυνου Χρήστη και του Νόμιμου Εκπροσώπου διαφέρει ενώ το email είναι κοινό',
            phoneError: 'Το κινητό τηλέφωνο του Υπεύθυνου Χρήστη και του Νόμιμου Εκπροσώπου διαφέρει ενώ το email είναι κοινό',
            invalidTaxId: 'Invalid Tax ID',
            internalError: 'Παρουσιάστηκε κάποιο πρόβλημα',
            inputError: 'Παρουσιάστηκε κάποιο πρόβλημα. Έλεγξε τα στοιχεία που έβαλες.',
            acceptTerms: 'Δεν έχετε αποδεχτεί τους Όρους και προϋποθέσεις.',
            portal: 'Cardlink apollo',
            registerMerchant: 'Εγγραφή στην υπηρεσία',
            legalRepCaption: 'Απαραίτητη προϋπόθεση για την εγγραφή της επιχείρησης στο Cardlink apollo είναι η συμπλήρωση και υποβολή της φόρμας εγγραφής από τον Νόμιμο Εκπρόσωπό της.',
            legalRepPositive: 'Είμαι ο Νόμιμος Εκπρόσωπος',
            legalRepStatement: 'Δήλωσες ότι είσαι ο Νόμιμος Εκπρόσωπος',
            returnToLogin: 'Επιστροφή στη login σελίδα',
            submitButton: 'Συνέχεια',
            registerButton: 'Εγγραφή',
            backButton: 'Πίσω',
            addUser: 'Προσθήκη χρήστη',
            removeUser: 'Αφαίρεση χρήστη',
            addMerchantId: 'Προσθήκη MID',
            removeMerchantId: 'Αφαίρεση MID',
            title1: 'Στοιχεία Νόμιμου Εκπροσώπου',
            subTitle1: 'Για την εγγραφή της επιχείρησής σου θα χρειαστείς απλώς τα εξής:',
            listItem1: 'ΑΦΜ',
            listItem2: 'Κωδικό/ούς MID',
            listItem3: 'Αντίγραφο της σφραγίδας της επιχείρησης σε αρχείο τύπου jpg, png ή gif',
            title2: 'Στοιχεία Επιχείρησης',
            corpType: 'Νομική Μορφή',
            fa: "Ατομική ή Ελεύθερος επαγγελματίας",
            co: "Εταιρική (ΕΠΕ, ΑΕ, ΟΕ, ΙΚΕ, κ.ά.)",
            corpTypeError: "Παρακαλούμε επίλεξε είδος επιχείρησης",
            subTitle2: 'Για την εγγραφή, απαιτείται αντίγραφο σφραγίδας της επιχείρησης (jpg, png ή gif, μεγέθους < 4MB).',
            subTitle2b: 'Για εγγραφή επιχείρησης με περισσότερα από 5 MID, παρακαλούμε επικοινώνησε μαζί μας στο support@cardlink.gr ή στο 2106303000.',
            title3: 'Εξουσιοδοτημένοι χρήστες',
            subTitle3: 'Εδώ θα ορίσεις ποιοι χρήστες θα έχουν πρόσβαση μέσω του Cardlink apollo σε όλα τα MID που δήλωσες στο προηγούμενο βήμα.',
            title4: 'Υπεύθυνος Χρήστης',
            subTitle4: 'Εδώ θα δηλώσεις τα στοιχεία του χρήστη που θα έχει τη δυνατότητα απενεργοποίησης πρόσβασης των χρηστών της επιχείρησης, σε περίπτωση διαρροής των κωδικών.',
            title5: 'Ολοκλήρωση εγγραφής',
            approveTermsPart1: 'Αποδέχομαι ανεπιφύλακτα τους παρόντες ',
            approveTermsPart2: '<b>Όρους και Προϋποθέσεις Χρήσης του Cardlink apollo</b>,',
            approveTermsPart3: ' τους οποίους διάβασα και βρίσκονται επίσης στην ηλεκτρονική διεύθυνση cardlink.gr καθώς και την επεξεργασία των προσωπικών μου δεδομένων για τη χρήση της Πλατφόρμας.',
            approveSecondCheck: 'Αναλαμβάνω την αποκλειστική ευθύνη για την ακρίβεια των στοιχείων που δήλωσα στη φόρμα εγγραφής.',
            step1: 'Βήμα 1',
            step2: 'Βήμα 2',
            step3: 'Βήμα 3.1',
            step4: 'Βήμα 3.2',
            emailPlaceholder: 'email',
            lastNamePlaceholder: 'Επώνυμο',
            firstNamePlaceholder: 'Όνομα',
            afmPlaceholder: 'ΑΦΜ',
            companyNamePlaceholder: 'Επωνυμία',
            companyTitlePlaceholder: 'Διακριτικός τίτλος',
            merchantIdPlaceholder: 'Κωδικός Επιχείρησης (MID)',
            afm: 'TaxID',
            merchantNameLatin: 'Company Name',
            merchantTitleLatin: 'Discreet Title',
            mid: 'Merchant ID',
            user1: 'Χρήστης #1',
            user2: 'Χρήστης #2',
            user3: 'Χρήστης #3',
            user4: 'Χρήστης #4',
            user5: 'Χρήστης #5',
            legalAlert: 'Για την εγγραφή απαιτείται ο Νόμιμος Εκπρόσωπος της Επιχείρησης.',
            thankYou: 'Ευχαριστούμε.',
            ok: 'ΟΚ',
            addSeal: 'Επισύναψη σφραγίδας',
            successfulMerchantRegistration: 'Λάβαμε το αίτημά σου για εγγραφή στο Cardlink apollo και το επεξεργαζόμαστε.\n' +
                'Μέσα στις επόμενες δύο εργάσιμες ημέρες θα σε ενημερώσουμε για την εξέλιξή του.\n' +
                'Ευχαριστούμε!',
            successfulMerchantRegistrationTitle: 'Επιτυχημένη υποβολή!',
            fileName: "Όνομα αρχείου: ",
            imageIcon: "Eικόνα: ",
            imageHint: "Τοποθέτησε τη σφραγίδα μέσα στο λευκό πλαίσιο. Αν χρειάζεται, χρησιμοποίησε τα κουμπιά <i class='icon-plus-accordion'></i> <i class='icon-minus-accordion'></i> για να προσαρμόσεις το μέγεθός της.",
            zoomIn: "Μεγέθυνση",
            zoomOut: "Σμίκρυνση",
            responsibleUserLegalRep: "Ως νόμιμος εκπρόσωπος της επιχείρησης επιθυμώ να είμαι και ο υπεύθυνος χρήστης.",
            responsibleUserOther: "Ως νόμιμος εκπρόσωπος επιθυμώ να ορίσω άλλον χρήστη ως τον υπεύθυνο χρήστη."
        },
        expiredPassword: {
            title: "Ο κωδικός πρόσβασής σου έληξε!",
            caption: "Για λόγους ασφαλείας είναι καλό να αλλάζεις συχνά τον κωδικό πρόσβασής σου στο Cardlink apollo.",
            changePassword: "Άλλαξε τον κωδικό σου για να συνεχίσεις.",
            old: "Παλιός κωδικός πρόσβασης",
            new: "Νέος κωδικός πρόσβασης",
            confirm: "Επιβεβαίωση νέου κωδικού πρόσβασης",
            passwordRequirements: 'Ο κωδικός πρέπει να είναι σε λατινικούς χαρακτήρες (τουλάχιστον 12) και να' +
                ' συμπεριλαμβάνει' +
                ' αριθμό, ειδικό χαρακτήρα, πεζά & κεφαλαία',
            saveButton: "Υποβολή",
            success: {
                passwordChangedSuccessfully: 'Επιτυχής αλλαγή!',
            },
            errors: {
                passwordIsIncorrect: 'Ο κωδικός πρόσβασης είναι λανθασμένος',
                passwordSameAsConfirm: 'Το περιεχόμενο των δύο πεδίων πρέπει να είναι ίδιο',
                passwordNotChanged: 'Παρουσιάστηκε πρόβλημα στην αποθήκευση του νέου κωδικού',
                oldPasswordError: 'Ο παλιός κωδικός δεν είναι σωστός',
                commonPasswordError: 'Ο κωδικός πρόσβασης είναι στη λίστα με τους πιο συχνά ' +
                    'χρησιμοποιούμενους κωδικούς πρόσβασης.',
                midPasswordError: 'Ο κωδικός πρέπει να είναι σε λατινικούς χαρακτήρες (τουλάχιστον 12) και να' +
                    ' συμπεριλαμβάνει' +
                    ' αριθμό, ειδικό χαρακτήρα, πεζά & κεφαλαία.',
                samePasswordError: 'Ο νέος κωδικός δεν πρέπει να είναι ίδιος με τον παλαιό',
                passwordHistoryError: 'Παρακαλούμε επιλέξτε έναν νέο κωδικό που δεν έχετε χρησιμοποιήσει στο παρελθόν.'
            }
        },
        invalidInvitation: {
            title: {
                expired: "Η πρόσκληση έχει λήξει",
                wrongParam: "Μη έγκυρη πρόσκληση"
            },
            message: {
                expired: "Η πρόσκληση για την έγγραφή σου στο Cardlink apollo έχει λήξει.\n" +
                    "Για να λάβεις νέα πρόσκληση, επικοινώνησε μαζί μας στο support@cardlink.gr ή στο 2106303000.",
                wrongParam: "Η πρόσκληση δεν ισχύει.\n" +
                    "Eπικοινώνησε μαζί μας στο support@cardlink.gr ή στο 2106303000 για οποιαδήποτε διευκρίνιση."
            }
        },
        userDetails: {
            newPassword: 'Δημιουργία κωδικού πρόσβασης',
            retypeNewPassword: 'Επιβεβαίωση κωδικού πρόσβασης',
            passwordRequirements: 'Ο κωδικός πρέπει να είναι σε λατινικούς χαρακτήρες (τουλάχιστον 12) και να' +
                ' συμπεριλαμβάνει' +
                ' αριθμό, ειδικό χαρακτήρα, πεζά & κεφαλαία',
            passwordsNotMatch: 'Υπάρχει ασυμφωνία μεταξύ των πεδίων',
            capsLockEnabled: '[Κεφαλαία]',
            submitButtonText: 'ΥΠΟΒΟΛΗ',
            mobilePhone: 'Κινητό Τηλέφωνο',
            resendButton: 'Επανάληψη αποστολής',
            allowFewSeconds: 'Παρακαλούμε περίμενε μερικά δευτερόλεπτα για να λάβεις τον κωδικό. Σε περίπτωση που ο ' +
                'κωδικός έχει λήξει, μπορείς να ζητήσεις άλλον. Αν δεν έχεις λάβει τον κωδικό, παρακαλούμε γύρισε στο ' +
                'βήμα 1 και επιβεβαίωσε ότι έχεις πληκτρολογήσει σωστά το τηλέφωνό σου.',
            returnToPhoneForm: 'Επιστροφή στο προηγούμενο βήμα',
            fourDigitCode: '4-ψήφιος κωδικός',
            errors: {
                invalidEmailOrConfirmationToken: 'Invalid Email Or ConfirmationToken',
                invalidOtp: 'Ο 4-ψήφιος κωδικός δεν είναι σωστός.',
                errorOccurredTitle: 'Προέκυψε ένα σφάλμα',
                anErrorOccured: ' Προέκυψε ένα σφάλμα \n' +
                    'Παρακαλώ προσπαθήστε αργότερα ή επικοινωνήστε μαζί μας στο: support@cardlink.gr ή στο 210 630' +
                    ' 3000',
            },
        },
        overview: {
            selectStore: "Δες Reports ανά κατάστημα",
            dashboard: {
                title: "Βασικοί δείκτες επιδόσεων",
                transactionsInfo: {
                    amount: "Ποσό συναλλαγών",
                    count: "Αριθμός συναλλαγών",
                    timespanLabels: {
                        prePrevDay1: "Σε σύγκριση με",
                        prePrevDay2: "μία ημέρα πριν",
                        prePrev7Days1: "Σε σύγκριση με τις",
                        prePrev7Days2: "προηγούμενες 7 ημέρες",
                        prePrev30Days1: "Σε σύγκριση με τις",
                        prePrev30Days2: "προηγούμενες 30 ημέρες",
                        prePrev90Days1: "Σε σύγκριση με τις",
                        prePrev90Days2: "προηγούμενες 90 ημέρες",
                        prePrev180Days1: "Σε σύγκριση με τις",
                        prePrev180Days2: "προηγούμενες 180 ημέρες",
                        prePrev365Days1: "Σε σύγκριση με",
                        prePrev365Days2: "1 χρόνο πριν",
                    },
                    dropdownBaseValue: 'Προηγούμενη ημέρα'
                },
                gauge: {
                    kpiLabel: "ΑΞΙΑ ΣΥΝΑΛΛΑΓΗΣ",
                    installmentsLabel: "ΑΞΙΑ ΔΟΣΗΣ",
                    valueLabels: {
                        min: "MIN",
                        average: "Μέσος όρος",
                        max: "MAX"
                    },
                }
            },
            barChart: {
                diagramTitle: 'ΑΡΙΘΜΟΣ ΣΥΝΑΛΛΑΓΩΝ & ΤΖΙΡΟΣ ',
                column1Title: 'Ποσό Συναλλαγής',
                column2Title: 'Αριθμός Συναλλαγών'
            },
            moreReports: "Περισσότερα Reports",
            gotoTransactions: "Δες τις συναλλαγές",
            pos: {
                title: 'Τα Cardlink POS μου',
                functions: {
                    operative: 'Σε λειτουργία',
                    broken: 'Με βλάβη',
                    returns: 'Προς επιστροφή',
                    activation: 'Προς ενεργοποίηση',
                    lost: 'Μη εντοπισμένο'
                }
            },
            supportForm: {
                supportButton: 'ΥΠΟΣΤΗΡΙΞΗ',
                faq: 'ΣΥΧΝΕΣ ΕΡΩΤΗΣΕΙΣ',
                needHelp: 'Χρειάζεσαι βοήθεια;',
                phoneNumber: '210 630 3000',
                contactEmail: 'support@cardlink.gr',
                otherWay: 'Στείλε το αίτημά σου',
                orderPaperRolls: "Παράγγειλε <br/> χαρτοταινίες",
                here: ' εδώ ',
            },
            faqForm: {
                noPrint: 'Το POS μου δεν εκτυπώνει',
                noButtonWorks: 'Δε δουλεύει κανένα ή κάποιο πλήκτρο στο POS μου',
                telApproval: 'Πώς μπορώ να πάρω τηλεφωνική έγκρισή;',
                procurementIssues: 'Που μπορώ να απευθυνθώ για θέματα προμηθειών',
                whitePaper: 'Στην εκτύπωση βγαίνει λευκό χαρτί',
                charge: 'Κάθε πότε πρέπει να φορτίζω το POS;',
                anyMoreQuestions1: 'Έχεις άλλες ερωτήσεις;',
                anyMoreQuestions2: 'Βρες τις απαντήσεις εδώ',
            },
        },
        business: {
            details: 'Στοιχεία Επιχείρησης ',
            addLogoTitle: 'ΠΡΟΣΘΗΚΗ ΛΟΓΟΤΥΠΟΥ ',
            addLogoDescr: '(jpeg, png ή gif < 4Mb)',
            logoTypesSupported: 'σε μορφή jpeg, png ή gif έως 4MB',
            afm: 'ΑΦΜ',
            doy: 'Δ.Ο.Υ.',
            iban: 'IBAN',
            title: 'ΔΙΑΚΡΙΤΙΚΟΣ ΤΙΤΛΟΣ',
            name: 'ΕΠΩΝΥΜΙΑ',
            legalRep1: 'ΣΤΟΙΧΕΙΑ Α\' ΕΚΠΡΟΣΩΠΟΥ',
            legalRep2: 'ΣΤΟΙΧΕΙΑ Β\' ΕΚΠΡΟΣΩΠΟΥ',
            legalRep: 'Νόμιμοι Εκπρόσωποι Επιχείρησης ',
            legalRepName: 'ΟΝΟΜΑΤΕΠΩΝΥΜΟ',
            legalRepAdt: 'ΑΡ. ΔΕΛΤ. ΤΑΥΤΟΤΗΤΑΣ',
            legalRepAfm: 'ΑΦΜ',
            legalRepFatherName: 'ΠΑ ΤΡΩΝΥΜΟ',
            legalRepTelephone: 'ΤΗΛΕΦΩΝΟ',
            eshopButton: 'Ηλεκτρονικό Κατάστημα',
            eshopDropdown: 'Ηλεκτρονικά Καταστήματα',
            popover: {
                merchantAlias: 'Αλλαγή εναλλακτικής ονομασίας',
                postCode: 'Αλλαγή ταχυδρομικού κώδικα',
                terminalAlias: 'Αλλαγή εναλλακτικής ονομασίας',
            },
            stores: {
                title: 'Καταστήματα',
                hint: 'Επίλεξε κατάστημα για να δεις όλες τις πληροφορίες του.'
            },
            midDetails: {
                title: 'Στοιχεία Επιχείρησης',
                singleTitle: 'Στοιχεία Καταστήματος',
                ecomTitle: 'Στοιχεία Ηλεκτρονικού Καταστήματος',
                midTitle: 'ΚΩΔΙΚΟΣ ΕΜΠΟΡΙΚΗΣ ΕΠΙΧΕΙΡΗΣΗΣ (MID)',
                midAlias: 'ΕΝΑΛΛΑΚΤΙΚΗ ΟΝΟΜΑΣΙΑ (ALIAS)',
                midActivity: 'ΔΡΑΣΤΗΡΙΟΤΗΤΑ',
                address: 'ΔΙΕΥΘΥΝΣΗ',
                postCode: 'Τ.Κ.',
                city: 'ΠΟΛΗ',
                area: 'ΠΕΡΙΦΕΡΕΙΑ',
                phone1: 'ΤΗΛΕΦΩΝΟ',
                phone2: 'ΤΗΛΕΦΩΝΟ #2',
                phone3: 'ΤΗΛΕΦΩΝΟ #3',
                mobilePhone: 'ΚΙΝΗΤΟ ΤΗΛΕΦΩΝΟ',
                banks: 'Συνεργασίες',
                provideAlias: 'Δώσε ονομασία',
                contactDetails: 'Διεύθυνση & Στοιχεία επικοινωνίας',
                providePostalCode: 'Δώσε Ταχυδρομικό Κώδικα',
                emptyCity: 'Άγνωστη τοποθεσία'
            },
            tidDetails: {
                title: 'POS',
                ecomTitle: 'E-POS',
                dropdownsActivePosLabel: 'Αναζήτηση ενεργών POS',
                dropdownsInactivePosLabel: 'Αναζήτηση ανενεργών POS',
                emptyActiveDropdown: 'Χωρίς επιλογές',
                emptyInactiveDropdown: 'Χωρίς επιλογές',
                activeDropdown: 'Επίλεξε POS',
                inactiveDropdown: 'Επίλεξε POS',
                tidTitle: 'ΚΩΔΙΚΟΣ POS',
                tidAlias: 'ΕΝΑΛΛΑΚΤΙΚΗ ΟΝΟΜΑΣΙΑ',
                serialNumber: 'SERIAL NUMBER',
                status: 'STATUS POS',
                model: 'ΜΟΝΤΕΛΟ POS',
                type: 'ΤΥΠΟΣ',
                technology: 'ΤΕΧΝΟΛΟΓΙΑ',
                contactless: 'ΑΝΕΠΑΦΕΣ ΣΥΝΑΛΛΑΓΕΣ',
                pinPad: 'PIN PAD',
                ecr: 'ΣΥΝΔΕΣΗ ΜΕ ΤΑΜΕΙΑΚΟ ΣΥΣΤΗΜΑ',
                interconnection: 'ΤΡΟΠΟΣ ΔΙΑΣΥΝΔΕΣΗΣ',
                collaboration: 'ΣΥΝΕΡΓΑΣΙΑ',
                conformityProgram: 'ΠΡΟΓΡΑΜΜΑ ΠΙΣΤΟΤΗΤΑΣ',
                dynamicConversion: 'ΔΥΝΑΜΙΚΗ ΜΕΤΑΤΡΟΠΗ ΝΟΜΙΣΜΑΤΟΣ',
                activeLabel: 'ΕΝΕΡΓΟ',
                inactiveLabel: 'ΑΝΕΝΕΡΓΟ'

            },
            paymentsAndFees: {
                title: "Cardlink one Εκκαθάριση & Προμήθειες",
                caption: "ΛΟΓΑΡΙΑΣΜΟΣ ΕΚΚΑΘΑΡΙΣΗΣ",
                table: {
                    card: "ΚΑΡΤΑ",
                    type: "ΤΥΠΟΣ",
                    fixRate: "ΠΡΟΜΗΘΕΙΑ (€)",
                    percentageRate: "ΠΡΟΜΗΘΕΙΑ (%)",
                    noData: "Δεν υπάρχουν δεδομένα προς απεικόνιση."
                }
            },
            debitNotes: {
                title: "Cardlink one Χρεωστικό Σημείωμα",
                new: 'ΝΕΟ',
                form: {
                    monthSelection: "Χρεωστικό Σημείωμα",
                    moreCriteria: "ΑΝΑΛΥΣΗ ΜΕ ΕΠΙΛΟΓΗ ΕΞΤΡΑ ΚΡΙΤΗΡΙΩΝ",
                    lessCriteria: "ΧΩΡΙΣ ΕΞΤΡΑ ΚΡΙΤΗΡΙΑ",
                    timePeriod: "Χρονική περίοδος",
                    posSelection: "Επίλεξε POS (TID)",
                    allPos: "Όλα τα POS",
                    cardSchemeSelection: "Επίλεξε είδος κάρτας (card scheme)",
                    allCardSchemes: "Όλα τα είδη κάρτας",
                    batchSelection: "Επίλεξε πακέτο",
                    allBatches: "Όλα τα πακέτα",
                    submit: "Υποβολή"
                },
                overview: {
                    table: {
                        title: 'Συγκεντρωτικός πίνακας προμηθειών ανά είδος κάρτας',
                        total: 'ΣΥΝΟΛΟ'
                    },
                    chart: {
                        title: 'Ποσοστιαία αναλογία επί του συνολικού ποσού των προμηθειών',
                        tableTitle: 'Ποσοστό %'
                    }
                },
                details: {
                    title: "ΛΕΠΤΟΜΕΡΗΣ ΑΝΑΛΥΣΗ ΣΥΝΑΛΛΑΓΩΝ",
                    table: {
                        headings: {
                            date: "Ημερομηνία<br/>Συναλλαγής",
                            tid: "Τερματικό",
                            batch: "Πακέτο",
                            cardScheme: "Είδος κάρτας",
                            salesAmount: "Ονομαστικό<br/>ποσό πωλήσεων",
                            refundsAmount: "Ποσό<br/>επιστροφών",
                            commissionAmount: "Προμήθειες",
                            netAmount: "Καθαρό ποσό"
                        },
                        pagination: {
                            nextPageTitle: "Επόμενη σελίδα",
                            prevPageTitle: "Προηγούμενη σελίδα",
                            firstPageTitle: "Πρώτη σελίδα",
                            lastPageTitle: "Τελευταία σελίδα",
                            results: "Αποτελέσματα",
                            toLabel: "έως",
                            jumpToPage: "Μετάβαση στη σελίδα"
                        },
                    }
                }
            },
            billing: {
                billingTitle: 'Τρόπος Τιμολόγησης',
                posAcquisition: 'ΤΡΟΠΟΣ ΑΠΟΚΤΗΣΗΣ',
                monthlyServiceFee: 'ΤΙΜΟΛΟΓΗΣΗ ΥΠΗΡΕΣΙΑΣ ΥΠΟΣΤΗΡΙΞΗΣ',
                rentalFee: 'ΤΙΜΟΛΟΓΗΣΗ ΜΙΣΘΩΣΗΣ',
                billingPeriod: 'ΧΡΟΝΟΣ ΤΙΜΟΛΟΓΗΣΗΣ',
                initialBillingPeriod: 'ΑΡΧΙΚΗ ΠΕΡΙΟΔΟΣ ΤΙΜΟΛΟΓΗΣΗΣ',
                futureBilling: 'ΕΠΟΜΕΝΗ ΤΙΜΟΛΟΓΗΣΗ',
            },
            logoRestrictions: 'Το λογότυπο πρέπει να είναι μια εικόνα μεγέθους εώς 4MB, ύψους εώς 80px και μήκους εώς 130px',
            modal: {
                closed: 'Εξαγωγή excel Καταστημάτων',
                opened: 'Κλείσιμο',
                reset: 'Καθαρισμός',
                selectMid: 'Σε επιλεγμένα καταστήματα (MID)',
                midTitle: 'Κωδικός MID + enter',
                selectTid: 'Επίλεξε POS (TID)',
                tidTitle: 'Κωδικός TID + enter',
                onlyActive: 'Μόνο ενεργά τερματικά',
                submit: 'Εξαγωγή στοιχείων',
                companyDlMultipleMid: 'Πρόσθεσε τους κωδικούς MID για να κατεβάσεις στοιχεία που αφορούν μόνο αυτά.',
                companyDlMultipleTid: 'Πρόσθεσε τους κωδικούς TID για να κατεβάσεις στοιχεία που αφορούν μόνο αυτά.',

            }
        },
        transactions: {
            tabs: {
                store: {
                    title: "<i class='icon-icon-pos'></i>Συναλλαγές Καταστημάτων",
                },
                ecommerce: {
                    title: "<i class='icon-icon-ecommerce'></i>Συναλλαγές E-Commerce"
                },
                installments: {
                    title: "<i class=\"icon-logo-cardlink\"></i>Δόσεις Cardlink One"
                }
            },
            tooltips: {
                trxItemDropdown: 'Συναλλαγές ανά σελίδα',
                excelButtonTooltip: 'Αποστολή συναλλαγών μέσω email'
            },
            searchModal: {
                labels: {
                    opened: "Αναζήτηση",
                    closed: "Κλείσιμο Αναζήτησης",
                    clear: 'Καθαρισμός'
                },
                leftColumn: {
                    timePeriod: 'Χρονική περίοδος'
                },
                midColumn: {
                    pickStore: 'Επίλεξε κατάστημα (MID)',
                    searchRange: 'Αναζήτηση βάσει MID',
                    toAllStores: 'Όλα τα καταστήματα (MID)',
                    toSpecificStores: ' Σε επιλεγμένα MID',
                    allPos: 'Όλα τα POS',
                    pickPos: 'Επίλεξε POS (TID)',
                    singlePos: 'POS',
                    range: 'Εύρος ποσού συναλλαγής',
                    from: 'Από',
                    to: 'Έως'
                },
                rightColumn: {
                    pickTransType: 'Επίλεξε είδος συναλλαγής',
                    allTransTypes: 'Όλα τα είδη',
                    transStatus: 'Status Συναλλαγής',
                    transSuccess: 'Επιτυχής',
                    transReject: 'Απορρίφθηκε'
                },
                lowerMidColumn: {
                    pickBank: 'Επίλεξε Πάροχο Πληρωμών',
                    allBanks: 'Όλοι οι Πάροχοι Πληρωμών',
                    pickVmid: 'Επίλεξε VMID',
                    allVmids: 'Όλα τα Virtual Merchant IDs',
                    pickVtid: 'Επίλεξε VTID',
                    allVtids: 'Όλα τα Virtual Terminal IDs',
                },
                lowerRightColumn: {
                    pickPayType: 'Τρόπος πληρωμής',
                    allPayTypes: 'Όλοι οι τρόποι',
                    transProgress: 'Εξέλιξη συναλλαγής',
                    allTransProgress: "Όλες οι συναλλαγές",
                    transPending: 'Εγκρίσεις/Απορρίψεις',
                    transCleared: 'Προς εκκαθάριση',
                    transClosedBatch: 'Σε πακέτο',
                    transSuccessPay: 'Επιτυχείς πιστώσεις',
                    transUnsuccessPay: 'Ανεπιτυχείς πιστώσεις',
                    duplicateTransactions: 'Διπλοχρεώσεις',
                    showDuplicateTransactions: 'Πιθανές πολλαπλές χρεώσεις'
                },
                lowerLeftColumn: {
                    moreCriteria: 'ΠEΡΙΣΣΟΤΕΡΑ ΚΡΙΤΗΡΙΑ',
                    lessCriteria: 'ΛΙΓΟΤΕΡΑ ΚΡΙΤΗΡΙΑ',
                    cardNumber: 'Αριθμός κάρτας',
                    cardNumberPlaceholder: "XXXX XXXX XXXX XXXX",
                    cardNumberTooltip: "Αν δεν έχεις όλο τον αριθμό της κάρτας για να κάνεις ακριβή αναζήτηση, " +
                        "χρησιμοποίησε το * σαν μπαλαντέρ. Ο αστερίσκος αντικαθιστά ένα ή περισσότερα νούμερα και μπορεί" +
                        " να μπει σε οποιαδήποτε θέση.",
                    cardNumberErrorTitle: 'Λάθος στοιχεία',
                    retrievalNumber: 'Retrieval Ref. Number',
                    retrievalNumberPlaceholder: '13 ψηφία',
                    approvalCode: 'Κωδικός Έγκρισης',
                    approvalCodePlaceholder: '6 ψηφία',
                },
                installments: {
                    searchTrxId: 'Αναζήτηση βάσει Transaction ID',
                    trxId: 'Transaction ID',
                    trxIdTooltip: 'Πρόσθεσε το Transaction ID της αρχικής συναλλαγής.',
                    trxIdErrorTitle: 'Λάθος στοιχεία',
                },
                button: {
                    search: "Αναζήτηση",
                }
            },
            table: {
                headings: {
                    dateAndTime: "Ημερομηνία <br/>Ώρα",
                    merchantId: "Merchant ID <br/>(MID)",
                    terminalId: "Terminal ID <br/>(TID)",
                    scheme: "Κάρτα",
                    cardNumber: "Αριθμός<br/>Κάρτας",
                    bank: "Πάροχος<br/>Πληρωμών",
                    transactionType: "Είδος<br/>Συναλλαγής",
                    totalInstallments: "Άτοκες<br/>Δόσεις",
                    redemptionAmount: "Ποσό<br/>Εξαργύρωσης",
                    authorizationAmount: "Ποσό<br/>Συναλλαγής",
                    status3d: "3D Secure",
                    status: "Απάντηση",
                },
                pagination: {
                    nextPageTitle: "Επόμενη σελίδα",
                    prevPageTitle: "Προηγούμενη σελίδα",
                    firstPageTitle: "Πρώτη σελίδα",
                    lastPageTitle: "Τελευταία σελίδα",
                    results: "Αποτελέσματα",
                    toLabel: "έως",
                    jumpToPage: "Μετάβαση στη σελίδα"
                },
                transactionTypes: {
                    purchase: "Αγορά",
                    preAuth: "Προέγκριση",
                    refund: "Επιστροφή",
                    voidOfRefund: "Ακύρωση Επιστροφής",
                    voidOfAsfGonea: "Ακύρωση Ασφαλιστικού Γονέα",
                    preAuthCom: "Ολοκλήρωση Προέγκρισης",
                    mailPhone: "Mail/Τηλεφωνική Παραγγελία",
                    transVoid: "Aκύρωση",
                    reversal: "Αντιλογισμός",
                    revAuto: "Αντιλογισμός Αυτόματος",
                    asfalistikosGoneas: "Ασφαλιστικός Γονέας",
                    utilityPayment: "Πληρωμή Λογαριασμών",
                    utilityPaymentVf: "Πληρωμή Λογαριασμών - VF",
                    utilityPaymentFa: "Πληρωμή Λογαριασμών - ΦΑ",
                    prepaidBalance: "Υπόλοιπο Προπληρωμένης",
                    prepaidCredit: "Φόρτιση Προπληρωμένης",
                    prepaidRedemption: "Εξαργύρωση Προπληρωμένης",
                    balanceInquiry: "Ερώτηση Υπολοίπου",
                    unknown: "Unknown acquirer"

                },
                emptySearchMessageTitle: 'Η αναζήτηση δεν έφερε αποτελέσματα. ',
                emptySearchMessageText: 'Δοκίμασε με διαφορετικά κριτήρια.',
                emptyTableMessageTitle: 'Δεν υπάρχουν καταχωρημένες συναλλαγές στη συγκεκριμένη περίοδο.',
                errorTableMessageTitle: 'Προέκυψε σφάλμα κατά την κλήση των δεδομένων.'
            },
            details: {
                title: "Στοιχεία Συναλλαγής",
                transactionDate: 'ΗΜ/ΝΙΑ ΣΥΝΑΛΛΑΓΗΣ',
                cardNumber: 'ΑΡΙΘΜΟΣ ΚΑΡΤΑΣ',
                cardType: 'ΤΥΠΟΣ ΚΑΡΤΑΣ',
                amount: 'ΠΟΣΟ ΣΥΝΑΛΛΑΓΗΣ',
                approved: 'Εγκρίθηκε',
                declined: 'Απορρίφθηκε',
                declinedCardlinkOne: 'Απορρίφθηκε - Όριο συναλλαγών επιχείρησης',
                response: 'ΑΠΑΝΤΗΣΗ',
                merchantId: 'ΚΩΔΙΚΟΣ ΕΠΙΧΕΙΡΗΣΗΣ (MID)',
                terminalId: 'ΚΩΔΙΚΟΣ POS (TID)',
                merchantAlias: 'ΕΝΑΛΛΑΚΤΙΚΗ ΟΝΟΜΑΣΙΑ ΚΩΔΙΚΟΥ ΕΠΙΧΕΙΡΗΣΗΣ (MID)',
                terminalAlias: 'ΕΝΑΛΛΑΚΤΙΚΗ ΟΝΟΜΑΣΙΑ ΚΩΔΙΚΟΥ POS (TID)',
                vMerchantId: 'VMERCHANT ID (VMID)',
                vTerminalId: 'VTERMINAL ID (VTID)',
                bank: 'ΣΥΝΕΡΓΑΣΙΑ',
                totalInstallments: '# ΑΡΙΘΜΟΣ </br>ΣΥΝΟΛΙΚΩΝ ΔΟΣΕΩΝ',
                redemptionAmount: 'ΠΟΣΟ ΕΞΑΡΓΥΡΩΣΗΣ',
                tillNumber: '# ΤΑΜΕΙΟΥ',
                cashierNumber: '# ΤΑΜΙΑ',
                paymentInfo: 'ΚΩΔΙΚΟΣ ΣΥΝΔΡΟΜΗΤΗ',
                transactionType: 'ΕΙΔΟΣ ΣΥΝΑΛΛΑΓΗΣ',
                transactionAttribute: 'ΧΑΡΑΚΤΗΡΙΣΤΙΚΟ ΣΥΝΑΛΛΑΓΗΣ',
                cardholderVerMode: 'CARDHOLDER VERIFICATION MODE',
                cardEntryMode: 'CARD </br>ENTRY MODE',
                contactlessFlag: 'CONTACTLESS FLAG',
                authorizationCode: 'ΚΩΔΙΚΟΣ </br>ΕΓΚΡΙΣΗΣ',
                retrievalReferenceNumber: 'ΚΩΔΙΚΟΣ ΑΝΑΦΟΡΑΣ (RRN)',
                closeBatch: 'ΚΛΕΙΣΙΜΟ ΠΑΚΕΤΟΥ',
                settlementDate: 'ΑΠΟΣΤΟΛΗ ΓΙΑ ΕΚΚΑΘΑΡΙΣΗ',
                status3D: '3D SECURE',
                infoField1: 'INFO FIELD #1',
                infoField2: 'INFO FIELD #2',
                infoField3: 'INFO FIELD #3',
                dccAmount: 'DCC AMOUNT',
                dccCurrency: 'DCC CURRENCY',
                dccRate: 'DCC RATE',
                cardlessType: 'CARDLESS',
                originalTerminalId: 'ORIGINAL TID',
                paymentCode: 'CODE',
                walletId: 'WALLET ID',
                tipsAmount: 'ΠΟΣΟ ΦΙΛΟΔΩΡΗΜΑΤΟΣ',
                ecommerceOrderId: 'E-COMMERCE ORDER ID',
                xlsId: 'LOYALTY ID',
                loyaltyProgram: 'LOYALTY PROGRAM',
                refundedAmount: 'ΕΠΙΣΤΡΕΦΟΜΕΝΟ ΠΟΣΟ',
                remainingAmount: 'ΥΠΟΛΟΙΠΟΜΕΝΟ ΠΟΣΟ',
                refundedOn: 'ΕΠΕΣΤΡΑΦΗ ΣΤΙΣ',
                refundedBy: 'ΕΠΕΣΤΡΑΦΗ ΑΠΟ',
                refundButton: 'Επιστροφή χρημάτων',
                netCreditAmount: `ΠΟΣΟ ΠΙΣΤΩΣΗΣ\n(ΕΠΙΧΕΙΡΗΣΗ)`,
                totalCommissionAmount: `ΣΥΝΟΛΙΚΗ\nΠΡΟΜΗΘΕΙΑ`,
                cardlinkOneCommission: `ΠΡΟΜΗΘΕΙΑ\nCARDLINK ONE`,
                processionCommission: `ΠΡΟΜΗΘΕΙΑ\nPROCESSOR`,
                paymentRequestDate: `ΗΜ/ΝΙΑ ΕΝΤΟΛΗΣ ΠΙΣΤΩΣΗΣ\nΠΡΟΣ ΤΡΑΠΕΖΑ ΕΜΠΟΡΟΥ`,
                paymentResponseDate: `ΗΜ/ΝΙΑ ΠΙΣΤΩΣΗΣ\nΑΠΟ ΤΡΑΠΕΖΑ ΕΜΠΟΡΟΥ`,
                paymentResponse: `ΑΠΑΝΤΗΣΗ ΤΡΑΠΕΖΑΣ\nΕΜΠΟΡΟΥ`,
                transactionNotSettled: 'Η συναλλαγή δεν έχει εκκαθαριστεί ακόμη',
                transactionOlderThan1Year: 'Μετά την πάροδο 1 χρόνου δεν μπορεί να γίνει επιστροφή χρημάτων',
                printDetails: 'Εκτύπωση στοιχείων συναλλαγής',
                printReceipt: "Εμφάνιση ηλεκτρονικής απόδειξης",
                shareReceipt: "Κοινοποίηση ηλεκτρονικής απόδειξης",
                cardlinkOneSuccessfulPayment: "ΕΠΙΤΥΧΗΣ ΠΛΗΡΩΜΗ",
                cardlinkOneUnsuccessfulPayment: "ΑΝΕΠΙΤΥΧΗΣ ΠΛΗΡΩΜΗ",
                refundWindow: {
                    currencyLabel: "EUR",
                    text1: 'Ποσό που θα',
                    text2: 'επιστραφεί στον πελάτη',
                    cancel: 'Ακύρωση',
                    send: 'Αποστολή'
                },
                modalWindow: {
                    text1: "Θέλετε να επιστραφούν",
                    text2: "%{amountToRefund} EUR",
                    text3: "στον πελάτη;",
                    yes: 'Ναι',
                    cancel: 'Ακύρωση',
                    refundNotSucceeded: 'Η επιστροφή δεν πραγματοποιήθηκε',
                    refundSucceeded: 'Η επιστροφή πραγματοποιήθηκε'
                },
                emailPopover: {
                    title: "Συμπλήρωσε το e-mail",
                    errorMessage: "Το e-mail που συμπλήρωσες δεν είναι έγκυρο."
                },
                receiptFileError: {
                    title: 'Προέκυψε ένα σφάλμα',
                    message: 'Παρακαλούμε δοκίμασε ξανά.'
                }
            },
            excelReport: {
                AnErrorOccurred: 'Προέκυψε ένα σφάλμα',
                largeAmountOfDataError: 'Παρακαλούμε περιόρισε τον αριθμό των εγγραφών μέσω των κριτηρίων αναζήτησης.',
            }
        },
        installments: {
            details: {
                title: 'Στοιχεία Δόσης',
                merchantId: "Merchant ID",
                terminalId: "Terminal ID",
                id: 'Installment ID',
                trxId: 'Transaction ID',
                amount: 'Ποσό',
                merchantFee: 'Ποσό Πίστωσης (Cardlink one)',
                commissionAmountMerchant: 'Συνολική Προμήθεια',
                number: 'Αριθμός Δόσης',
                paymentDate: 'Ημ/νία Πληρωμής',
                status: 'Κατάσταση',
                paymentReqDate: 'Ημ/νία Εντολής Πίστωσης προς Πάροχο Πληρωμών',
                paymentRespDate: 'Ημ/νία Πίστωσης από Πάροχο Πληρωμών',
                paymentRespMessage: 'Απάντηση Παρόχου Πληρωμών Εμπόρου',
                debitNoteNumber: 'Αριθμός Debit Note',
                debitNoteGenerationDate: 'Ημ/νία Έκδοσης Debit Note',
                debitNoteScheme: 'Σχήμα Debit Note',
                debitNoteProduct: 'Προϊόν Debit Note',
                debitNoteMonth: 'Μήνας Debit Note',
                debitNoteYear: 'Έτος Debit Note',
                createDatetime: 'Ημ/νία Δημιουργίας Δόσης',
                updateDatetime: 'Ημ/νία Ενημέρωσης Δόσης',
                statusDescription: {
                    1: "Δόση καταχωρήθηκε",
                    2: "Δόση εκκαθαρίστηκε",
                    3: "Στάλθηκε για πίστωση",
                    4: "Δόση πιστώθηκε"
                }
            },
            table: {
                headings: {
                    merchantId: "Merchant ID",
                    terminalId: "Terminal ID",
                    paymentDate: "Ημ/νία Πίστωσης από Πάροχο Πληρωμών",
                    number: 'Αριθμός Δόσης',
                    total: 'Αριθμός Δόσεων',
                    trxId: "Transaction ID",
                    status: "Κατάσταση",
                    amount: "Ποσό"
                }
            }
        },
        reports: {
            titles: {
                transactions: 'Αριθμός και ποσό συναλλαγών',
                components: 'ΣΤΟΙΧΕΙΑ',
                perAcquirer: 'ανά Πάροχο Πληρωμών',
                ofCards: 'Καρτών',
                transactionTypes: 'ανά Τύπο Συναλλαγής',
                installmentsTotals: 'Συναλλαγές με δόσεις',
                redemption: 'Συναλλαγές με εξαργύρωση',
                bankTotals: 'Σύνολα συναλλαγών (live)'
            },
            search: {
                dateTimePick: 'Επίλεξε χρονική περίοδο',
                companyPick: 'Επίλεξε εταιρεία (ΑΦΜ)',
                searchRange: 'Εύρος Αναζήτησης',
                reportPick: 'Επίλεξε report',
                allCompanies: 'Όλες οι εταιρείες',
                toAllStores: 'Όλα τα καταστήματα (MID)',
                toSpecificStores: ' Σε επιλεγμένα καταστήματα (MID)',
                allReports: 'Επίλεξε Report',
                download: 'Εξαγωγή Report',
                reportDownloadPanel: 'Επίλεξε και κατέβασε τα reports που θέλεις.',
                radioButtons: {
                    xls: "xls",
                    csv: "csv"
                }
            },
            tableTitles: {
                number: 'Αριθμός Συναλλαγών',
                count: 'Σύνολο Συναλλαγών',
            },
            contactless: 'Ανέπαφες',
            notContactless: 'Μη Ανέπαφες',
            installments: {
                perMonth: ' / μήνα',
                perDay: ' / ημέρα',
                perPeriodCaption: "Συναλλαγές με δόσεις",
                noPeriodData: "Δεν υπάρχουν δεδομένα για τη συγκεκριμένη περίοδο.",
                noAfmChecked: "Χρειάζεται να έχεις τουλάχιστον μια επιχείρηση επιλεγμένη",
            },
            pieChart: {
                title: "Ποσό (€)",
                titleBanksColumnName: "Συνεργασίες",
                titleCardsColumnName: "Κάρτες"
            },
            chart: {
                dropdown: {
                    baseValueWeek: 'Τρέχουσα εβδομάδα',
                    baseValueMonth: 'Προηγούμενος μήνας',
                }
            },
            settledCount: 'ΣΕ ΕΓΚΡΙΣΗ',
            approvedCount: 'ΠΡΟΣ ΕΚΚΑΘΑΡΙΣΗ',
            redemptionTitle: 'Εξαργύρωση',
            redemptionTitleTable: 'Ποσό</br>εξαργύρωσης',
            monthTitle: 'Μήνας & έτος',
            amountWithCardTitleTable: 'Ποσό</br>με κάρτα',
            amountWithCardTitle: 'Ποσό',
            amountTitle: 'Ποσό (€)',
            countTitle: 'Αριθμός Συναλλαγών',
            countTitleTable: 'Αριθμός</br>Συναλλαγών',
            totalSum: 'ΣΥΝΟΛΙΚΑ ΠΟΣΑ',
            scheme: 'Κάρτα',
            mode: "Τύπος συναλλαγής",
            checkboxesHeader: "Επιχειρήσεις που συμπεριλαμβάνονται στη γραφική απεικόνιση",
            transactionTypes: {
                purchase: "Αγορά",
                preAuth: "Προέγκριση",
                refund: "Επιστροφή",
                preAuthCom: "Ολοκλήρωση Προέγκρισης",
                mailPhone: "Mail/Τηλεφωνική Παραγγελία",
                transVoid: "Aκύρωση",
                revAuto: "Αντιλογισμός Αυτόματος",
                asfalistikosGoneas: "Ασφαλιστικός Γονέας",
                utilityPayment: "Πληρωμή Λογαριασμών",
                utilityPaymentVf: "Πληρωμή Λογαριασμών - VF",
                utilityPaymentFa: "Πληρωμή Λογαριασμών - ΦΑ",
                prepaidBalance: "Υπόλοιπο Προπληρωμένης",
                prepaidCredit: "Φόρτιση Προπληρωμένης",
                prepaidRedemption: "Εξαργύρωση Προπληρωμένης",
                balanceInquiry: "Ερώτηση Υπολοίπου",
                restOfTransaction: "Ανάλυση λοιπών συναλλαγών"
            },
            download: {
                errors: {
                    midCannotSeeReport: ' Στο Mid %{mid} δεν αντιστοιχεί το Report %{report}',
                    downloadFailed: 'Παρουσιάστηκε πρόβλημα, παρακαλούμε προσπάθησε ξανά.'
                },
                warnings: {
                    day: 'To επιτρεπόμενο εύρος ημερομηνιών του report "%{report}" είναι μία ημέρα.',
                    days: 'To επιτρεπόμενο εύρος ημερομηνιών του report "%{report}" είναι %{dateRangeValue} ημέρες.',
                    month: 'To επιτρεπόμενο εύρος ημερομηνιών του report "%{report}" είναι ένας μήνας.',
                    months: 'To επιτρεπόμενο εύρος ημερομηνιών του report "%{report}" είναι %{dateRangeValue} μήνες.',
                    excelRowLimitReached: 'To μέγεθος του report "%{report}" ξεπέρασε το ανώτατο όριο εγγραφών του excel. Το report θα κατέβει με τη μορφή csv αρχείου.'
                }
            }
        },
        bankTotals: {
            table: {
                titles: {
                    mid: 'MID ',
                    location: 'ΤΟΠΟΘΕΣΙΑ: ',
                    allMids: 'Όλα τα MIDs',
                    bank: 'Συνεργασίες',
                    purchase: 'ΑΓΟΡΕΣ',
                    refund: 'ΕΠΙΣΤΡΟΦΕΣ',
                    void: 'ΑΚΥΡΩΣΕΙΣ',
                    redemption: 'ΕΞΑΡΓΥΡΩΣΕΙΣ',
                    sum: 'ΣΥΝΟΛΑ',
                },
                emptyTableMessageTitle: 'Δεν υπάρχουν καταχωρημένες συναλλαγές στη συγκεκριμένη περίοδο.',

            },
            searchModal: {
                labels: {
                    timePeriod: 'Επίλεξε χρονική περίοδο',
                    midsCheckbox: 'Όλα τα καταστήματα (MID)',
                    merchantLabel: 'Αναζήτηση βάσει MID',
                    report: 'Εξαγωγή Report'
                }
            }
        }
    },
    modals: {
        profile: {
            logoSpecifications: 'Η εικόνα δεν πρέπει να ξεπερνάει τα 4MB',
            firstName: 'Όνομα',
            lastName: 'Επώνυμο',
            saveButton: 'Αποθήκευση',
            emailSaveButton: 'Υποβολή Αλλαγής',
            passwordPlaceholder: 'Συνθηματικό',
            newEmail: 'Νέο Email',
            confirmNewEmail: 'Επιβεβαίωση Νέου Email',
            oldPassword: 'Παλιό Συνθηματικό',
            newPassword: 'Νέο Συνθηματικό',
            confirmNewPhone: 'Συνθηματικό',
            confirmNewPassword: 'Επιβεβαίωση Νέου Συνθηματικού',
            newPhone: 'Νέο τηλέφωνο',
            confirmPhone: 'Επιβεβαίωση τηλέφωνου',
            usernameRequirements: 'Το όνομα χρήστη πρέπει να είναι δύο λέξεις (όνομα και επώνυμο) με κενό μεταξύ τους',
            passwordRequirements: 'Ο κωδικός πρέπει να είναι σε λατινικούς χαρακτήρες (τουλάχιστον 12) και να' +
                ' συμπεριλαμβάνει' +
                ' αριθμό, ειδικό χαρακτήρα, πεζά & κεφαλαία',
            submitImageButton: 'Αποθήκευση',
            success: {
                imageUploadSuccessfully: 'Eπιτυχής μεταφόρτωση!',
                nameChangedSuccessfully: 'Επιτυχής αλλαγή!',
                emailChangedSuccessfully: 'Επιβεβαίωσε το νέο email σου στο link που σου στείλαμε.',
                passwordChangedSuccessfully: 'Επιτυχής αλλαγή!',
                phoneChangedSuccessfully: 'Επιτυχής αλλαγή!'
            },
            errors: {
                imageNotChanged: 'Παρουσιάστηκε πρόβλημα στην αποθήκευση της νέας εικόνας προφίλ',
                nameNotChanged: 'Παρουσιάστηκε πρόβλημα στην αποθήκευση του ονοματεπώνυμου',
                emailSameAsConfirm: 'Το περιεχόμενο των δύο πεδίων πρέπει να είναι ίδιο',
                phoneSameAsConfirm: 'Το περιεχόμενο των δύο πεδίων πρέπει να είναι ίδιο',
                emailAlreadyExists: 'Αυτό το email υπάρχει ήδη',
                passwordIsIncorrect: 'Ο κωδικός πρόσβασης είναι λανθασμένος',
                passwordSameAsConfirm: 'Το περιεχόμενο των δύο πεδίων πρέπει να είναι ίδιο',
                passwordNotChanged: 'Παρουσιάστηκε πρόβλημα στην αποθήκευση του νέου κωδικού',
                oldPasswordError: 'Ο παλιός κωδικός δεν είναι σωστός',
                commonPasswordError: 'Ο κωδικός πρόσβασης είναι στη λίστα με τους πιο συχνά ' +
                    'χρησιμοποιούμενους κωδικούς πρόσβασης.',
                midPasswordError: 'Ο κωδικός πρέπει να είναι σε λατινικούς χαρακτήρες (τουλάχιστον 12) και να' +
                    ' συμπεριλαμβάνει' +
                    ' αριθμό, ειδικό χαρακτήρα, πεζά & κεφαλαία.',
                samePasswordError: 'Ο νέος κωδικός δεν πρέπει να είναι ίδιος με τον παλαιό',
                passwordHistoryError: 'Παρακαλούμε επιλέξτε έναν νέο κωδικό που δεν έχετε χρησιμοποιήσει στο παρελθόν.',
                tooManyChangePasswordAttempts: 'Έχουν γίνει πολλές προσπάθειες αλλαγής κωδικού. Παρακαλώ δοκιμάστε ξανά σε λίγο.'
            },
            image: {
                title: 'ΦΩΤΟΓΡΑΦΙΑ ΠΡΟΦΙΛ ΧΡΗΣΤΗ',
                value: 'jpeg, png ή gif < 4MB',
                delete: 'Διαγραφή',
            },
            username: {
                title: 'ΟΝΟΜΑ ΧΡΗΣΤΗ',
                change: 'ΑΛΛΑΓΗ ΟΝΟΜΑΤΟΣ ΧΡΗΣΤΗ',
                input: {
                    name: 'Όνομα',
                    lastname: 'Επώνυμο'
                }
            },
            email: {
                title: 'EMAIL',
                change: 'ΑΛΛΑΓΗ EMAIL',
                input: {
                    new: 'Νέο email',
                    confirm: 'Επιβεβαίωση email',
                    password: 'Κωδικός πρόσβασης'
                }
            },
            phone: {
                title: 'KINHTO ΤΗΛΕΦΩΝΟ',
                change: 'ΑΛΛΑΓΗ ΚΙΝΗΤΟΥ ΤΗΛΕΦΩΝΟΥ',
                input: {
                    new: 'Νέο τηλέφωνο',
                    confirm: 'Επιβεβαίωση τηλεφώνου',
                    password: 'Κωδικός πρόσβασης',
                    validPhoneNumber: 'Παρακαλώ εισάγετε έναν έγκυρο αριθμό τηλεφώνου'
                },
                footnote: "* Ο αριθμός κινητού τηλεφώνου είναι απαραίτητος για τη χρήση του Cardlink apollo. <b>Με την αλλαγή του δεν ενημερώνονται τα λοιπά συστήματα της Cardlink και των συνεργασιών.</b> Για οποιαδήποτε αλλαγή, επικοινωνήστε μαζί μας στο <u>support@cardlink.gr</u>"
            },
            password: {
                title: 'ΚΩΔΙΚΟΣ ΠΡΟΣΒΑΣΗΣ',
                change: 'ΑΛΛΑΓΗ ΚΩΔΙΚΟΥ ΠΡΟΣΒΑΣΗΣ',
                input: {
                    old: 'Παλιός κωδικός πρόσβασης',
                    new: 'Νέος κωδικός πρόσβασης',
                    confirm: 'Επιβεβαίωση νέου κωδικού πρόσβασης'
                }
            },
            closeEditAvatar: 'Καθαρισμός και κλείσιμο της φόρμας',
            saveEditAvatar: 'Αποθήκευση φωτογραφίας προφίλ',
            deleteAvatar: 'Διαγραφή της φωτογραφίας προφίλ'
        },
        userManagement: {
            title: "Διαχείριση χρηστών",
            caption: "Εδώ μπορείς να δεις τους χρήστες της επιχείρησης και να απενεργοποιήσεις την πρόσβαση ενός ή περισσοτέρων, σε περίπτωση διαρροής των κωδικών, μεταβάλλοντας την τιμή στο πεδίο “Ενεργοποιημένος”",
            superUser: 'ΥΠΕΥΘΥΝΟΣ ΧΡΗΣΤΗΣ',
            legalRepresentative: 'ΝΟΜΙΜΟΣ ΕΚΠΡΟΣΩΠΟΣ',
            simpleUser: 'ΧΡΗΣΤΗΣ',
            userAdded: 'Προσθήκη χρήστη',
            lastConnection: 'Τελευταία σύνδεση',
            enabled: 'Ενεργοποιημένος',
            disabled: 'Απενεργοποιημένος',
            notConfirmed: 'Πρόσκληση σε εκκρεμότητα',
            lockedToggle: 'Κλειδωμένη ρύθμιση. Η απενεργοποίηση μπορεί να γίνει μόνο απο την Cardlink',
            userToggleOn: 'Ο χρήστης είναι ενεργός. Απενεργοποιώντας τον λογαριασμό του αποκλείεις την είσοδό του στο Portal.',
            userToggleOff: 'Ο λογαριασμός είναι απενεργοποιημένος. Μόνο οι ενεργοποιημένοι χρήστες έχουν πρόσβαση στο Portal.'
        },
        termsAndConditions: {
            title: 'Όροι και Προϋποθέσεις χρήσης του Cardlink apollo',
            attention: '<b>ΠΡΟΣΟΧΗ!</b> Για να συνεχίσετε να χρησιμοποιείτε το Cardlink apollo πρέπει να' +
                ' αποδεχθείτε τους νέους όρους και προϋποθέσεις.',
            acceptButton: 'Αποδέχομαι'
        },
        support: {
            category: {
                title: "Κατηγορία",
                dropdown: {
                    request: "Αίτημα",
                    failure: "Βλάβη",
                    complain: "Παράπονο",
                    selection: "Επίλεξε κατηγορία",
                }
            },
            company: {
                title: "Επιχείρηση",
                dropdown: {
                    selection: "Επίλεξε επιχείρηση",
                    allCompanies: "Όλες οι επιχειρήσεις"
                }
            },
            mid: {
                title: "Κατάστημα (MID)",
                dropdown: {
                    selection: 'Επίλεξε MID',
                    allMids: 'Όλα τα MIDS'
                }
            },
            tid: {
                title: "POS (TID)",
                dropdown: {
                    selection: 'Επίλεξε TID',
                    allTids: 'Όλα τα TIDS'
                }
            },
            textArea: {
                title: "Μήνυμα",
                placeholder: "Γράψε το μήνυμά σου",
                placeholderB: "Επίλεξε κατηγορία για να μπορέσεις να γράψεις το μήνυμά σου",
                contentError: "Το περιεχόμενο του μυνήματος είναι ακατάλληλο. Παρακαλούμε να δώσετε νέο."
            },
            fileUpload: {
                dragAndDrop: "Επισύναψε τα επιθυμητά αρχεία <br/>(jpeg, png, gif ή pdf < 4MB)",
                attachedFiles: "Επισυναπτόμενα αρχεία",
                title: "Επίσύναψη αρχείου",
                error: "Το αρχείο δεν είναι κατάλληλο για επισύναψη. Παρακαλούμε έλεγξε τις παραμέτρους."
            },
            buttonSubmit: "Υποβολή",
            emailFetching: "Αποστολή email...",
            business: {
                message: "Χρειάζεσαι βοήθεια;",
                button: "Ενημέρωσέ μας"
            },
            response: {
                positive: 'Το μήνυμά σου στάλθηκε επιτυχώς!',
                negative: 'Παρουσιάστηκε πρόβλημα, παρακαλούμε προσπάθησε ξανά.',
                close: 'Κλείσιμο'
            },
            email: {
                labels: {
                    timeStamp: "Καταχωρήθηκε: ",
                    cause: "Αιτιολογία: ",
                    taxId: "ΑΦΜ: ",
                    mid: "MID: ",
                    tid: "TID: ",
                    email: "Email: ",
                    fullName: "Ονοματεπώνυμο: ",
                    mobile: "Κινητό: ",
                    corpTitle: "Διακριτικός/οι τίτλος/οι: ",
                    message: "Μήνυμα: "
                },
                footer: {
                    subject: "Cardlink: Πες μας τη γνώμη σου!",
                    portalLabel: "Αποστολή από Cardlink apollo - Πες μας τη γνώμη σου!",
                },
                aside: {
                    subject: "Cardlink: Χρειάζεσαι βοήθεια;",
                    portalLabel: "Αποστολή από Cardlink apollo - Χρειάζεσαι βοήθεια; Υποστήριξη",
                },
                business: {
                    subject: "Cardlink: Χρειάζεσαι βοήθεια;",
                    portalLabel: "Αποστολή από Cardlink apollo - Χρειάζεσαι βοήθεια;"
                }
            }
        }
    },
    common: {
        forms: {
            help: 'Επικοινώνησε μαζί μας στο support@cardlink.gr ή στο 2106303000',
            needHelp: 'Χρειάζεσαι βοήθεια;'
        },
        fastSearch: {
            label: 'Γρήγορη Αναζήτηση',
            placeholder: 'MID, TID, ALIAS'
        },
        answers: {
            yes: 'ΝΑΙ',
            no: 'ΟΧΙ'
        },
        noPosInAccount: 'Δεν έχεις συνδεδεμένα POS στο λογαριασμό σου, παρακαλώ επικοινώνησε με την Cardlink.'
    },
    navMenu: {
        links: {
            overview: 'ΕΠΙΣΚΟΠΗΣΗ',
            business: 'ΕΠΙΧΕΙΡΗΣΗ',
            reports: 'REPORTS',
            transactions: 'ΣΥΝΑΛΛΑΓΕΣ',
            bankTotals: 'ΣΥΝΟΛΑ ΣΥΝΕΡΓΑΣΙΩΝ',
        },
        bubble: {
            title: 'Μάθε γρήγορα το apollo',
            text: 'Η ΕΠΕΞΗΓΗΣΗ είναι διαθέσιμη σε κάθε σελίδα. Μπορείς να την ενεργοποιείς κάθε φορά που θέλεις πληροφορίες για τις λειτουργίες του Cardlink apollo.'

        }
    },
    orderStatus: {
        loading: 'Έλεγχος κατάστασης αίτησης...',
        none: {
            button: "Εκκρεμεί πληρωμή",
            modal: {
                title: "Εκκρεμεί πληρωμή",
                text: "Η αίτησή σου για εγγραφή στο <b>Cardlink one</b> καταχωρήθηκε. Εκκρεμεί η πληρωμή για να προχωρήσουμε στην επεξεργασία της.\n" +
                    "Αν εκκρεμούν έγγραφα σχετικά με την αίτησή σου μπορείς να τα επισυνάψεις πατώντας <a href=%{mbsLink}>εδώ</a>.",
                bottomBtn: "Κλείσιμο"
            }
        },
        pending: {
            button: "Εκκρεμεί",
            modal: {
                title: "Αίτηση υπό επεξεργασία",
                text: "Η αίτησή σου για εγγραφή στο <b>Cardlink one</b> καταχωρήθηκε με επιτυχία και σύντομα θα σε ενημερώσουμε για την εξέλιξή της μέσω e-mail.\n" +
                    "Αν εκκρεμούν έγγραφα σχετικά με την αίτησή σου μπορείς να τα επισυνάψεις πατώντας <a href=%{mbsLink}>εδώ</a>.",
                bottomBtn: "Κλείσιμο"
            }
        },
        partiallyActivated: {
            button: "Μερικώς ενεργή",
            modal: {
                title: "Σε μερική λειτουργία",
                text: "Η αίτησή σου για εγγραφή στο <b>Cardlink one</b> είναι σε διαδικασία ελέγχου.\n" +
                    "Σύντομα θα σε ενημερώσουμε για την έγκρισή της μέσω e-mail. Μέχρι να ολοκληρωθεί, μπορείς να πραγματοποιήσεις συναλλαγές αξίας €500 συνολικά.\n" +
                    "Αν εκκρεμούν έγγραφα σχετικά με την αίτησή σου μπορείς να τα επισυνάψεις πατώντας <a href=%{mbsLink}>εδώ</a>.",
                bottomBtn: "Κλείσιμο"
            }
        },
        blocked: {
            button: "Υπέρβαση ορίου",
            modal: {
                title: "Υπέρβαση ορίου",
                text: "Πραγματοποίησες συναλλαγές συνολικής αξίας €500, το όριο μέχρι να ολοκληρωθεί η διαδικασία ελέγχου της αίτησής σου στο <b>Cardlink one</b>. Θα σε ενημερώσουμε για την πορεία της αίτησής σου.\n" +
                    "Αν εκκρεμούν έγγραφα σχετικά με την αίτησή σου μπορείς να τα επισυνάψεις πατώντας <a href=%{mbsLink}>εδώ</a>.\n" +
                    "Αν χρειάζεσαι περισσότερες πληροφορίες μπορείς να καλέσεις στο 2106306050 ή να στείλεις email στο <a href=\"mailto:one@cardlink.gr\">one@cardlink.gr</a>.",
                bottomBtn: "Κλείσιμο"
            }
        },
        activated: {
            button: "Εγκρίθηκε",
            modal: {
                title: "Έγκριση αίτησης",
                text: "Η αίτησή σου έχει εγκριθεί. Σε ευχαριστούμε για την προτίμησή σου στο <b>Cardlink one</b>.",
                bottomBtn: "Κλείσιμο"
            }
        },
        declined: {
            button: "Απορρίφθηκε",
            modal: {
                title: "Απόρριψη αίτησης",
                text: "Θα θέλαμε να σε ενημερώσουμε πως η αίτησή σου δεν εγκρίθηκε.\n" +
                    "Για οποιαδήποτε πληροφορία χρειαστείς μπορείς να καλέσεις στο 2106306050 ή να στείλεις e-mail στο <a href=\"mailto:one@cardlink.gr\">one@cardlink.gr</a>.",
                bottomBtn: "Κλείσιμο"
            }
        },
        cancelled: {
            button: "Ακυρώθηκε",
            modal: {
                title: "Ακύρωση αίτησης",
                text: "Η αίτησή σου έχει ακυρωθεί. Το <b>Cardlink one</b> είναι στη διάθεσή σου αν θέλεις να ξεκινήσεις νέα αίτηση.",
                bottomBtn: "Έναρξη αίτησης"
            }
        }
    },
    modalList: {
        options: {
            profile: 'ΠΡΟΦΙΛ',
            notifications: 'ΕΙΔΟΠΟΙΗΣΕΙΣ',
            userManagement: 'ΔΙΑΧΕΙΡΙΣΗ ΧΡΗΣΤΩΝ',
            smartSettings: "ΕΞΥΠΝΕΣ ΡΥΘΜΙΣΕΙΣ"
        }
    },
    externalList: {
        links: {
            support: 'ΥΠΟΣΤΗΡΙΞΗ',
            help: 'ΕΠΕΞΗΓΗΣΗ',
            terms: 'ΟΡΟΙ & ΠΡΟΫΠΟΘΕΣΕΙΣ',
            privacyPolicy: 'ΔΗΛΩΣΗ ΑΠΟΡΡΗΤΟΥ',
            cookies: "COOKIES",
            cookiePreferences: "ΠΡΟΤΙΜΗΣΕΙΣ",
            cookiesPolicy: "ΠΟΛΙΤΙΚΗ"
        }
    },
    header: {
        cookiesBanner: {
            text: 'Χρησιμοποιώντας τον παρών ιστότοπο, αποδέχεσαι τη χρήση cookies',
            more: 'Περισσότερα',
            accept: 'Αποδέχομαι'
        },
    },
    footer: {
        support: 'Πες μας τη γνώμη σου! Είναι πολύ σημαντική για εμάς!',
        modal: {
            header: "Πες μας τη γνώμη σου!"
        }
    },
    loader: {
        dashboard: "Φόρτωση στατιστικών των συναλλαγών...",
        generic: "Φόρτωση δεδομένων",
        trxEmail: "Αποστολή email",
        trxSubMessage: "Δες σε λίγο το inbox σου",
        receiptPdf: "Δημιουργία ηλεκτρονικής απόδειξης"
    },
    errorBoundary: {
        title1: ':( ',
        title2: 'Παρουσιάστηκε κάποιο πρόβλημα'
    },
    hotSpots: {
        companiesDropdown: "Επίλεξε την επιχείρηση που επιθυμείς για να δεις τις σχετικές πληροφορίες.",
        storesMultiselect: "Επίλεξε ένα ή περισσότερα καταστήματα για να δεις τις σχετικές πληροφορίες.",
        reportsTransactions: "Αλλάζοντας τις ημερομηνίες ή μετακινώντας τις μπάρες, φιλτράρεις τα αποτελέσματα του γραφήματος.",
        quickSearch: "Συμπλήρωσε κάποιο από τα στοιχεία επιχείρησης για να δεις τις λεπτομέρειές του.",
        reportsBanks: "Από εδώ μπορείς: " + "<ul>" +
            "<li> Να κατεβάσεις το γράφημα σαν εικόνα </li>" +
            "<li> Να σώσεις τα στοιχεία του σαν CSV, XLSX ή JSON </li>" +
            "<li> Να βάλεις κείμενο ή σχήματα πάνω στο γράφημα </li>" +
            "<li> Να εκτυπώσεις το γράφημα</li>" + "</ul>",
        downloadReports: "Κατέβασε reports διαφορετικών ειδών επιλέγοντας χρονικές περιόδους, επιχείρηση ή κατάστημα.",
        cardScheme: "Εδώ μπορείς να εναλλάσσεις το είδος των δεδομένων του γραφήματος (τύπος συναλλαγών και τύπος κάρτας).",
        cardsDates: "Επίλεξε χρονική περίοδο για να φιλτράρεις τα αποτελέσματα.",
        transactionTypes: "Μπορείς να δεις αναλυτικά τις υπόλοιπες συναλλαγές αν αποεπιλέξεις την κατηγορία Αγορά.",
        exportChartHints: "Από εδώ μπορείς: " + "<ul>" +
            "<li> Να κατεβάσεις το γράφημα σαν εικόνα </li>" +
            "<li> Να σώσεις τα στοιχεία του σαν CSV, XLSX ή JSON </li>" +
            "<li> Να βάλεις κείμενο ή σχήματα πάνω στο γράφημα </li>" +
            "<li> Να εκτυπώσεις το γράφημα</li>" + "</ul>",
        downloadBusinessExcel: "Κατέβασε το αρχείο με τα στοιχεία της επιχείρησής σου (MID & TID).",
        transactionDetailsInfo: "Επίλεξε μία συναλλαγή για να δεις περισσότερες πληροφορίες και να τις εκτυπώσεις.",
        transactionSettlingInfo: "Οι συναλλαγές κάθε μέρας περιλαμβάνονται αυτόματα σε αρχείο προς εκκαθάριση στις 00:00 κάθε βράδυ.",
        settledTransactions: "Συναλλαγές που έχουν συμπεριληφθεί σε αρχείο προς τον/τους Πάροχο/ους Πληρωμών σας.",
        pendingTransactions: "Εγκεκριμένες συναλλαγές που δεν έχουν συμπεριληφθει ακόμη σε αρχείο προς τον/τους Πάροχο/ους Πληρωμών σας.",
        threeDSecureHeader: "Δες αν συναλλαγή πραγματοποιήθηκε με πιστοποίηση 3D Secure.",
        eCommerceBusiness: "Εδώ μπορείς να βρεις πληροφορίες για το ηλεκτρονικό σου κατάστημα.",
        transactionExcelButton: "Το αρχείο των συναλλαγών που θα λάβεις περιλαμβάνει όλες τις πληροφορίες για τις συναλλαγές σου.",
        responseCodeHeader: "Με <b>x</b> εμφανίζονται οι συναλλαγές που δεν έχουν εγκριθεί.",
        isSettledTransactionHeader: "<ul><li>Με πορτοκαλί χρώμα εμφανίζονται οι συναλλαγές που έχουν πραγματοποιηθεί στα POS σας και δεν έχουν συμπεριληφθεί ακόμη σε αρχείο προς εκκαθάριση προς τον Πάροχο Πληρωμών.</li> <li>Με μωβ χρώμα εμφανίζονται οι συναλλαγές που έχουν συμπεριληφθεί σε αρχείο προς εκκαθάριση προς τη Συνεργασία.</li></ul>"

    },
    tooltips: {
        hover: {
            timeoutSession: "Αντίστροφη μέτρηση αυτόματης αποσύνδεσης απο το Cardlink apollo",
            seeTRXbtn: "Δες αναλυτικά τις συναλλαγές αυτής της περιόδου",
            tooltip: "Μέσος όρος αξίας συναλλαγής της επιλεγμένης περιόδου και μικρότερη/μεγαλύτερη συναλλαγή.",
            supportFormTooltip: "Φόρμα Επικοινωνίας με την Cardlink",
            logOut: "Αποσύνδεση",
            profileAvatar: "Το προφίλ σου",
            timeDropdownKPI: "Επίλεξε χρονική περίοδο",
            ssoLink: "Μάθε ό,τι χρειάζεσαι μέσα απο το Cardlink Support",
            trxDownloadBTN: "Λάβε με email τον σύνδεσμο για να κατεβάσεις τις συναλλαγές (μέχρι 100.000 συναλλαγές κάθε φορά)",
            trxLeftPaperclip: "Συναλλαγή που έχει συμπεριληφθεί σε αρχείο προς εκκαθάριση προς τον Πάροχο Πληρωμών.",
            trxLeftClock: "Εγκεκριμένη συναλλαγή που δεν έχει συμπεριληφθεί ακόμη σε πακέτο ή απορριφθείσα συναλλαγή.",
            trxLeftInBatch: "Συναλλαγή που έχει συμπεριληφθεί σε πακέτο.",
            trxLeftCheck: "Επιτυχής πίστωση σε λογαριασμό.",
            trxLeftFail: "Ανεπιτυχής πίστωση σε λογαριασμό.",
            trxStatusOk: "Επιτυχής συναλλαγή",
            trxStatusRejected: "Απορρίφθηκε",
            trxStatusCardlinkOneRejected: "Απορρίφθηκε - Όριο συναλλαγών επιχείρησης",
            reportDownloadPanel: "Επίλεξε και κατέβασε τα reports που θέλεις.",
            timeDropdown01: "Επίλεξε χρονική περίοδο",
            timeDropdown02: "Επίλεξε χρονική περίοδο",
            timeDropdown03: "Επίλεξε χρονική περίοδο",
            timeDropdown04: "Επίλεξε χρονική περίοδο",
            timeDropdown05: "Επίλεξε χρονική περίοδο",
            addAvatar: "Πρόσθεσε φωτογραφία στο προφίλ σου",
            editName: "Επεξεργασία στοιχείων",
            editEmail: "Επεξεργασία στοιχείων",
            editMobile: "Επεξεργασία στοιχείων",
            editPassword: "Επεξεργασία στοιχείων",
            lockedToggle: "Κλειδωμένη ρύθμιση. Η απενεργοποίηση μπορεί να γίνει μόνο απο την Cardlink",
            userToggleOn: "Ο χρήστης είναι ενεργός. Απενεργοποιώντας το λογαριασμό του, αποκλείεις την είσοδό του στο Cardlink apollo.",
            userToggleOff: "Ο λογαριασμός του χρήστη είναι απενεργοποιημένος. Μόνο οι ενεργοποιημένοι χρήστες έχουν πρόσβαση στο Cardlink apollo."
        },
        click: {
            businessTidAlias: "Δώσε εναλλακτική όνομασία (Alias) στο POS για να το βρίσκεις πιο εύκολα στη σελίδα της Επιχείρησης αλλά και στη Γρήγορη Αναζήτηση",
            companyDlMultipleMid: "Πρόσθεσε τους κωδικούς MID για να κατεβάσεις στοιχεία που αφορούν μόνο αυτά.",
            companyDlMultipleTid: "Πρόσθεσε τους κωδικούς TID για να κατεβάσεις στοιχεία που αφορούν μόνο αυτά.",
            mainMultipleMID: "Πρόσθεσε κωδικούς MID για να εμφανιστούν αποτελέσματα που αφορούν αποκλειστικά αυτά τα καταστήματα (MID).",
            companyTKInfo: "Τα καταστήματα (MID) καταχωρούνται στις περιφέρειες σύμφωνα με τον Τ.Κ. Αν υπάρχει λάθος αντιστοίχιση μπορείς να διορθώσεις τον Τ.Κ. στη σελίδα του καταστήματος (MID)",
            tooltipMid: "Δώσε εναλλακτική όνομασία (Alias) στο MID για να το βρίσκεις πιο εύκολα στη σελίδα της Επιχείρησης αλλά και στη Γρήγορη Αναζήτηση",
            reportDownloadPanelMultipleMID: "Πρόσθεσε MID αν θέλεις να κατεβάσεις reports για συγκεκριμένα καταστήματα",
            reportsRightColumnTitleTltp: "Τα γραφήματα και τα reports περιλαμβάνουν δεδομένα μέχρι και την προηγούμενη ημέρα, με εξαίρεση το Σύνολα Συναλλαγών (live).",
            quickFind: "Γράψε τον κωδικό MID, TID ή την Εναλλακτική Ονομασία τους για να μεταφερθείς στο σημείο εμφάνισής τους",
            trxSearchMultipleMID: "Πρόσθεσε τους κωδικούς MID που θέλεις, για να κάνεις πιο συγκεκριμένη την αναζήτησή σου",
            downloadExcelTooltip: "Μπορείς να λάβεις μέσω email μέχρι 100.000 συναλλαγές κάθε φορά",
            refund: "Η επιστροφή χρημάτων δεν είναι διαθέσιμη εάν: <br/> 1. Έχει περάσει 1 χρόνος απο την εκκαθάρισή της συναλλαγής. <br/> 2. Η συναλλαγή έγινε χωρίς παρουσία κάρτας. <br/>",
            tooltipTid: 'Δώσε εναλλακτική όνομασία (Alias) στο Pos για να το βρίσκεις πιό εύκολα στην σελίδα της Επιχείρησης αλλά και στη Γρήγορη Αναζήτηση',
            tooltipTrancactionsChart: "Τα γραφήματα περιλαμβάνουν δεδομένα μέχρι και την προηγούμενη ημέρα.",
            overviewKpiTitleTltp: "Τα γραφήματα περιλαμβάνουν δεδομένα μέχρι και την προηγούμενη ημέρα.",
            duplicateTransactionsTitleTltp: "Εμφανίζονται συναλλαγές με φίλτρα όπως: ίδιο TID, ίδια ημερομηνία, ίδιο ποσό κ.ά."
        }
    },
}
