export default function sideModalReducer(state = {
    isProfile: false,
    isUserManagement: false,
}, action) {
    switch (action.type) {
        case "SET_MODAL_ACTION": {
            return {
                ...state,
                [action.typeOfModal]: action.isModalOpen
            }
        }
        case "NULLIFY_MODAL_ACTION": {
            return {
                ...state,
                isProfile: false,
                isUserManagement: false,
            }
        }
        default : {
            return state

        }
    }
}