import AmCharts from "@amcharts/amcharts3-react";
require("amstock3/amcharts/amstock")


export default function Options(dataProvider, column, panelType, id, showAllTxt, filename,  locale, dates) {
    let graphLineColor = "";
    let mainColor = "";
    let precision = 0;
    if(id === "TotalsTransactions") {
        if (panelType === "step") {
            graphLineColor = "#a0afba";
            mainColor = "#005596";
            precision = 2;
        } else {
            graphLineColor = "#ada2b1";
            mainColor = "#7D4199";
            precision = -1;
        }
    } else {
        if (panelType === "step") {
            graphLineColor = "#D9B296";
            mainColor = "#F47B20";
            precision = 2;
        } else {
            graphLineColor = "#a0afba";
            mainColor = "#005596";
            precision = -1;
        }
    }
    AmCharts.monthNames = locale === "el" ?
        ["Ιανουάριος", "Φεβρουάριος", "Μάρτιος", "Απρίλιος", "Μάιος", "Ιούνιος", "Ιούλιος", "Αύγουστος", "Σεπτέμβριος", "Οκτώβριος", "Νοέμβριος", "Δεκέμβριος"]
        :['January', 'February', 'March', 'April', 'May', 'June', 'July', 'August', 'September', 'October', 'November', 'December'];
    AmCharts.shortMonthNames = locale === "el" ?
        ["Ιαν", "Φεβ", "Μαρ", "Απρ", "Μάι", "Ιουν", "Ιουλ", "Αυγ", "Σεπτ", "Οκτ", "Νοε", "Δεκ"]
        :['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'];
    AmCharts.dayNames = locale === "el" ?
        ["Κυριακή", "Δευτέρα", "Τρίτη", "Τετάρτη", "Πέμπτη", "Παρασκευή", "Σάββατο"]
        :['Sunday', 'Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday'];
    AmCharts.shortDayNames =  locale === "el" ?
        ["Κυρ", "Δευ", "Τρι", "Τετ", "Πεμ", "Παρ", "Σαβ"]
        :['Sun', 'Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat'];

    const installmentsStrGr = id === "TransactionsWithInstallments"  ? " με δόσεις": "";
    const installmentsStrEn = id === "TransactionsWithInstallments"  ? " with installments": "";

	const decimalSeparator = locale === 'el' ? "," : ".";
	const thousandSeparator = locale === 'el' ? "." : ",";

    const fillAphas = column === 'count' ? {
        "fillAlphas": 0,
        // "lineThickness": 2,
        // "bullet": "round",
        // "bulletBorderAlpha": 1,
        // "bulletBorderColor": mainColor,
        // "bulletBorderThickness": 1.7,
        // "bulletColor": "#ffffff",
        // "bulletAlpha": 0,
        // "bulletSize": 7,
        // "bulletOffset": 3
    } : {
        "fillAlphas": 1,
    };
    return {
        "type": "stock",
        "hideCredits": true,
        "theme": "none",
        "mouseWheelZoomEnabled": true,
        "dataDateFormat": "YYYY-MM-DD",
        "color": "#009F25",
        "dataSets": [{
            "color": mainColor,
            "fieldMappings": [{
                "fromField": column,
                "toField": column
            }],
            "dataProvider": dataProvider,
            "categoryField": "date"
        }],
        "categoryAxesSettings": {
            "gridThickness": 0,
            "axisAlpha": 1,
            "groupToPeriods": ['DD'],
            "minPeriod": 'DD',
            "alwaysGroup": true
        },
        "valueAxesSettings": {
            "position": "left",
            "stackType": "regular",
            "gridThickness": 0,
            "fillAlpha": 0,
            "unit": column === 'amount' ? "€ " : null,
            "unitPosition": "left",
            "axisAlpha": 0,
            "axisThickness": 0,
            "gridAlpha": 0,
            "offset": 45
        },
        "panelsSettings": {
            "marginRight": 40,
            "marginLeft": 40,
            "usePrefixes": true,
			"creditsPosition": "top-left",
            "precision": precision,
			"decimalSeparator": decimalSeparator,
			"thousandSeparator": thousandSeparator
        },
        "panels": [{
            "title": column,
            "stockGraphs": [{
                "id": "g1",
                "type": panelType,
                "valueField": column,
                "comparable": true,
                "compareField": column,
                "balloonText": column === "amount" ? "€ [[value]]" : "[[value]]",
                ...fillAphas
            }],
        }],
        "chartScrollbarSettings": {
            "graph": "g1",
            "color": "#595A5C",
            "graphType": "line",
            "updateOnReleaseOnly": false,
            "graphLineAlpha": 1,
            "selectedGraphFillAlpha": 0,
            "selectedGraphLineAlpha": 1,
            "gridAlpha": 0,
            "dragIcon": "dragIconRectSmall",
            "dragIconWidth" : 25,
            "dragIconHeight" : 25,
            "height": 50,
            "backgroundColor": "#f8f8f8",
            "selectedBackgroundColor": mainColor,
            "selectedBackgroundAlpha": "0.17",
            "graphLineColor": graphLineColor,
            "graphFillAlpha": "0"

        },
        "chartCursorSettings": {
            "valueBalloonsEnabled": true,
            "categoryBalloonColor": mainColor,
            "cursorColor": mainColor
        },
        "fontFamily": "Open Sans",
        "export": {
            "enabled": true,
            "columnNames": {
                "date": "Ημερομηνία/Date",
                "amount": "Ποσό (€)/Amount (€)",
                "count": "Αριθμός Συναλλαγών" + installmentsStrGr +"/Transactions' count" + installmentsStrEn
            },
            "position": "top-left",
            "divId": "chart-export-btn__" + id,
            "fileName": filename + "_" + dates.dateFrom + "_" +
                dates.dateTo,
            "exportSelection": true
        },
        "numberFormatter": {
            "precision": precision,
			"decimalSeparator": decimalSeparator,
			"thousandsSeparator": thousandSeparator
        },
        "periodSelector": {
            "hideCredits": true,
            "autoZoom": true,
            "hideOutOfScopePeriods": true,
            "inputFieldsEnabled": true,
            "position": "top",
            "fromText": "",
            "toText": "",
            "width": 180,
            "periodsText": "",
            "periods": [
                {
                    "period": "MAX",
                    "label": showAllTxt
                }
            ]
        },
        "extendToFullPeriod": false
    }
}