import React from 'react';
import moment from "moment";
import StockChart from "./stockChart/StockChart";
import store from "../../store";
import {countsTotals} from "../../providers/charts";
import {arrangeCompanyName} from "../../lib/utils"
import {withRouter} from "react-router-dom";
import connect from "react-redux/es/connect/connect";
import NoChartData from "../common/NoChartData";
import Loader from "../common/Loader";
import HotSpot from "../common/HotSpot";
import Tooltip from "../common/Tooltip";

const Translate = require('react-redux-i18n').Translate;
const I18n = require('react-redux-i18n').I18n


const stateMap = (store) => {
    return {
        i18n: store.i18n,
        chartReducer: store.chartReducer,
        userReducer: store.userReducer
    };
};

class TotalTransactionsChart extends React.Component {
    constructor(props) {
        super(props)
        this.state = {
            afm: props.afm,
            mid: props.mid,
            countsTotalsData: null,
            transactionsMode: false,
            dateFrom: moment(moment().subtract(1, 'day')).subtract(12, 'months').format('YYYY-MM-DD'),
            dateTo: moment().subtract(1, 'days').format('YYYY-MM-DD'),
        }
        this.countsTotals = this.countsTotals.bind(this)
        this.reRenderChart = this.reRenderChart.bind(this)
        this.transactionsModeCheck = this.transactionsModeCheck.bind(this)
        this.handleCheck = this.handleCheck.bind(this)
        this.arrangeCheckedAfms = this.arrangeCheckedAfms.bind(this)
        this.arrangeAfmsArr = this.arrangeAfmsArr.bind(this)
    }

    componentWillMount() {
        this.countsTotals({
            afm: this.props.afm,
            mid: this.props.mid,
            dateFrom: this.state.dateFrom,
            dateTo: this.state.dateTo,
        });
        this.arrangeCheckedAfms(this.props.afm)
    }

    componentWillReceiveProps(nextProps) {
        if (this.props.afm !== nextProps.afm || this.props.mid !== nextProps.mid ) {
            this.setState({
                    afm: nextProps.afm,
                    mid: nextProps.mid
                }, () => {
                    this.reRenderChart()
                    this.arrangeCheckedAfms(this.state.afm)
                }
            )
        }

        if (this.props.i18n.locale !== nextProps.i18n.locale) {
            this.reRenderChart()
        }
    }

    reRenderChart(){
        this.countsTotals({
            afm: this.state.afm,
            mid: this.state.mid,
            dateFrom: this.state.dateFrom,
            dateTo: this.state.dateTo
        })
    }

    countsTotals(data) {
        store.dispatch(countsTotals(data)).then((response) => {
            this.setState({
                countsTotalsData: response.value.data
            })
        })
    }

    transactionsModeCheck(event) {
        this.setState({
            transactionsMode: !this.state.transactionsMode
        })
    }

    handleCheck(event) {
        let afm = event.target.id;
        let checked = event.target.checked;
        this.setState(prevState => ({
            [afm]: checked,
            afm: this.arrangeAfmsArr(afm, checked, prevState.afm)
        }), () => {
            this.countsTotals({
                afm: this.state.afm,
                mid: this.state.mid,
                dateFrom: this.state.dateFrom,
                dateTo: this.state.dateTo
            })
        });
    }

    arrangeCheckedAfms(arr) {
        let allAfms = this.props.afms;
        let afmsArr = arr.includes(',') ? arr.split(',') : [arr];
        allAfms.forEach(afmObj => {
            this.setState({
                [afmObj.afm]: afmsArr.includes(afmObj.afm)
            })
        });
    }

    arrangeAfmsArr(afm, checked, prevArr) {
        let afmsArr = prevArr.includes(',') ? prevArr.split(',') : [prevArr];
        if (checked && !afmsArr.includes(afm)) {
            afmsArr.push(afm)
        } else if (!checked && afmsArr.includes(afm)) {
            let idx = afmsArr.indexOf(afm);
            afmsArr.splice(idx, 1);
        }
        return afmsArr.toString()
    }

    render() {
        let afms = this.props.afms;
        let isSingleAfm = this.props.userReducer.getAssociationsResponse.merchantType !== "multiAfm";
        return (
            <React.Fragment>
                <h4 key="reports__transactions--section_title"
                    className="reports__transactions--section_title">
                    <Translate value="pages.reports.titles.transactions"/>
                    <span>
                        <Tooltip placement="right"
                                 tooltipButtonId="reportsTransactionsChart"
                                 tooltipLabelClass="reports-transactions-chart-label"
                                 popoverId="reports-transactions-chart-tltp"
                                 tooltipText='tooltipTrancactionsChart'
                        />
                    </span>
                </h4>
                {this.props.userReducer.showHotSpots ?
                    <div className="hotSpot-element__wrapper">
                        <HotSpot
                            hotProp="exportChartHints"
                            placement="bottom"
                        />
                    </div>
                    : null}
                <div key="reports__transactions--chart reports__total__transactions--chart"
                     className={"reports__transactions--chart col-sm-12 col-xs-12 " + (isSingleAfm ? "col-md-12" : "col-md-9")}>
                    {this.state.countsTotalsData !== null && this.state.countsTotalsData.length > 0
                    && this.props.chartReducer.totalTransactions.fetched ?
                        [<StockChart
                            key="overview-stock-chart"
                            data={this.state.countsTotalsData}
                            column={this.state.transactionsMode ? 'count' : 'amount'}
                            panelType={this.state.transactionsMode ? 'line' : 'step'}
                            id={'TotalsTransactions'}
                            filename={I18n.t("pages.reports.titles.transactions")}
                            dates={{dateFrom: this.state.dateFrom, dateTo: this.state.dateTo}}
                        />,

                    <div className="reports__chart-switch chart-left reports__chart-switch--blue-purple"
                            key="reports-chart-switch">
                        <div
                            className={"amount-label report-translate" + (!this.state.transactionsMode ? "_active" : "")}>
                            <Translate value="pages.reports.amountTitle"/>
                        </div>
                        <input type="checkbox"
                               name="select-transaction-type-mode-checkbox"
                               className="cbx"
                               id='transactionsMode'
                               checked={this.state.transactionsMode}
                               onChange={this.transactionsModeCheck}
                        />
                        <label htmlFor="transactionsMode" className="toggle">
                            <span></span>
                        </label>
                        <div
                            className={"count-label report-translate" + (this.state.transactionsMode ? "_active" : "")}>
                            <Translate value="pages.reports.countTitle"/>
                        </div>
                    </div>]
                            : this.props.chartReducer.totalTransactions
                            && !this.props.chartReducer.totalTransactions.fetching ?
                            <NoChartData
                                hasAfms={!this.state.afm}
                            /> : null
                        }
                    {this.props.chartReducer.totalTransactions && this.props.chartReducer.totalTransactions.fetching ?
                        <Loader
                            bouncing={false}
                            color={'midnight-blue'}
                            loaderStyles={{minHeight: "112.5px", margin: "40px 0"}}
                            message="loader.generic"/> : null}
                    {this.props.userReducer.showHotSpots ?
                        <div className="hotSpot-element__wrapper">
                            <HotSpot
                                hotProp="reportsTransactions"
                                image="reportsTransactions"
                                placement="right"
                            />
                        </div>
                        :null}
                </div>
                <div key="reports__transactions--companies_selection"
                     className={"reports__transactions--companies_selection col-md-3 col-sm-12 col-xs-12 "}
                     style={{display: isSingleAfm ? "none" : ""}}>
                    <div className="reports__transactions--company_cbox--header">
                        <Translate value="pages.reports.checkboxesHeader"/>
                    </div>
                    {afms.map(afmEl => {
                        return (
                            <div key={"company_cbox--" + afmEl.afm}
                                 className="reports__transactions--company_cbox">
                                <input type="checkbox"
                                       name={"select-afm-checkbox--" + afmEl.afm}
                                       value={this.state[afmEl.afm]}
                                       checked={this.state[afmEl.afm]}
                                       id={afmEl.afm}
                                       onChange={this.handleCheck}/>
                                <label htmlFor={afmEl.afm}>
                                    <span>{arrangeCompanyName(afmEl, this.props.i18n.locale)}</span>
                                    <span className="checkmark"></span>
                                </label>
                            </div>)
                    })}
                </div>
            </React.Fragment>

        )
    }
}

export default withRouter(connect(stateMap)(TotalTransactionsChart));