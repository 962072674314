import React from 'react';
import {Panel} from "react-bootstrap";

const Translate = require('react-redux-i18n').Translate

export default class BillingMethod extends React.Component {
    constructor(props) {
        super(props)
        this.state = {
            data: props.data
        }
    }

    componentWillReceiveProps(nextProps) {
        if (this.props.data !== nextProps.data) {
            this.setState({
                data: nextProps.data
            })
        }
    }

    render() {
        const data = this.state.data
        let billing = [
            {label: "posAcquisition", value: this.props.locale === 'en' ? data.uiPosAcquisition : data.uiPosAcquisitionGr},
            {label: "monthlyServiceFee", value: this.props.locale === 'en' ? data.uiMonthlyServiceFee : data.uiMonthlyServiceFeeGr},
            {label: "rentalFee", value: this.props.locale === 'en' ? data.uiRentalFee : data.uiRentalFeeGr},
            {label: "initialBillingPeriod", value: this.props.locale === 'en' ? data.uiInitialBillingPeriod : data.uiInitialBillingPeriodGr},
            {label: "futureBilling", value: this.props.locale === 'en' ? data.uiFutureBilling : data.uiFutureBillingGr},
            {label: "billingPeriod", value: this.props.locale === 'en' ? data.uiBillingPeriod : data.uiBillingPeriodGr},

        ]
        let prefix = "pages.business.billing.";
        return (
        <Panel id="collapsible-panel-billing-type">
            <Panel.Heading>
                <Panel.Toggle componentClass="h4">
                    <Translate value="pages.business.billing.billingTitle"/>
                    <i className="icon-plus-accordion"></i>
                    <i className="icon-minus-accordion"></i>
                </Panel.Toggle>
            </Panel.Heading>
            <Panel.Collapse>
                <Panel.Body>
                    <div className="business-row__details business-row__details--billing">
                        {
                            billing.map((item) => {
                                return [
                                    <div className="business-details__column"
                                         key="business-billing-method">
                                        <Translate value={prefix + item.label}/>
                                        <div className="business-details__value">{item.value ? item.value : "-"}</div>
                                    </div>
                                ]
                            })
                        }
                    </div>
                </Panel.Body>
            </Panel.Collapse>
        </Panel>
        )
    }
}
