import React from "react";
import NumberFormat from "react-number-format";
import {phoneNumberFormat} from "../../../lib/utils";
import {withRouter} from "react-router-dom";
import connect from "react-redux/es/connect/connect";
import {setRegistrationStep1} from "../../../actions/registerAction";
import store from "../../../store";
import {merchantRegistrationValidate} from "../../../providers/rest";
import Loader from "../../common/Loader";
import {ShowHelp} from "../../common/ShowHelp";
import {Button} from "react-bootstrap";
import Logo from "../../common/Logo";

const stateMap = (store) => {
    return {
        i18n: store.i18n,
        registrationReducer: store.registrationReducer
    };
};

const Translate = require('react-redux-i18n').Translate;
const I18n = require('react-redux-i18n').I18n

class MerchantRegistrationStep1 extends React.Component {
    constructor(props) {
        super(props)
        this.state = {
            isLegal: props.legal,
            errorMessage: '',
            emailPlaceholder: I18n.t('pages.register.emailPlaceholder'),
            lastNamePlaceholder: I18n.t('pages.register.lastNamePlaceholder'),
            firstNamePlaceholder: I18n.t('pages.register.firstNamePlaceholder'),
        }
        this.handleChange = this.handleChange.bind(this)
        this.handleButtonChange = this.handleButtonChange.bind(this)
        this.handleFormattedChange = this.handleFormattedChange.bind(this)
        this.alertBody = this.alertBody.bind(this)
        this.validate = this.validate.bind(this)
    }

    componentWillReceiveProps(nextProps) {
        if (nextProps.i18n !== this.props.i18n) {
            this.setState({
                emailPlaceholder: I18n.t('pages.register.emailPlaceholder'),
                lastNamePlaceholder: I18n.t('pages.register.lastNamePlaceholder'),
                firstNamePlaceholder: I18n.t('pages.register.firstNamePlaceholder')
            })
        }
    }

    handleChange(event) {
        this.props.handleChange(event)
    }

    handleButtonChange(val) {
        let event = {
            target: {
                id: "legal",
                value: val
            }
        }
        this.props.handleChange(event)
    }

    handleFormattedChange(obj, ev) {
        this.props.handleFormattedChange(obj, ev)
    }

    alertBody() {
        return (
            <div>
                <h4><Translate value='pages.register.legalAlert'/></h4>
                <h4><Translate value='pages.register.thankYou'/></h4>
                <button onClick={(e) => {
                    this.props.history.push({
                        pathname: '/login',
                    })
                }}><Translate value='pages.register.οκ'/></button>
            </div>
        )
    }

    validate(data) {
        store.dispatch(merchantRegistrationValidate(data)).then((response) => {
                data.step++
                store.dispatch(setRegistrationStep1(data))
            }, () => {
                this.setState({
                    errorMessage: I18n.t('pages.register.inputError')
                })
            }
        )
    }

    render() {
        const validationInProgress = this.props.registrationReducer &&
            this.props.registrationReducer.validateMerchantRegistration &&
            this.props.registrationReducer.validateMerchantRegistration.fetching
        return (
            <div className="register-merchant-step1__content">
                <div className="register-merchant-step1__left">
                    <p className="register-merchant-step1__sub-header">
                        <Translate value="pages.register.registerMerchant"/>
                    </p>
                    <p className="register-merchant-step1__sub-header">
                        <b> <Translate value="pages.register.step1"/> </b>
                    </p>
                    <p className="register-merchant-step1__text register-merchant-step1__text_intro">
                        <Translate value="pages.register.subTitle1"/>
                    </p>
                    <ul className="login-area__text login-area__text_list  login-area__text_list--bold">
                        <li>
                            <Translate value="pages.register.listItem1"/>
                        </li>
                        <li>
                            <Translate value="pages.register.listItem2"/>
                        </li>
                        <li>
                            <Translate value="pages.register.listItem3"/>
                        </li>
                    </ul>
                </div>
                <div className="register-merchant-step1__login-with-forgot">
                    <div className="register-merchant-step1__login-box">
                        <Logo isMain={false} isHeader={false} imgClassName="login"/>
                        <form onSubmit={(e) => {
                            e.preventDefault();
                            let data = {
                                lastName: this.props.lastName,
                                firstName: this.props.firstName,
                                email: this.props.email,
                                phone1: this.props.phone1,
                                step: this.props.step
                            }

                            this.validate(data)
                        }}
                            className={!this.state.isLegal ? "registration-disabled" : ""}
                        >
                            <h3 className="register-merchant-title">
                                <Translate value="pages.register.title1"/>
                            </h3>
                            <Translate value="pages.register.legalRepCaption" className="legal-rep--txt"/>
                            <section className="legal-rep__btn--container">
                                {!this.state.isLegal ?
                                <Button className="legal-rep__btn"
                                        value={this.state.isLegal}
                                    onClick={(e) => {
                                    this.setState(prevState => ({
                                        isLegal: !prevState.isLegal
                                    }), ()=> {
                                        this.handleButtonChange(this.state.isLegal)
                                    });

                                }}>
                                    <Translate value={"pages.register.legalRepPositive"} />
                                </Button>
                                    : <div className="legal-rep__confirmation">
                                        <div className={"legal-rep__icon-check icon-circle-check"} />
                                        <Translate className="legal-rep__text-check" value={"pages.register.legalRepStatement"} />
                                    </div> }
                            </section>
                            <input type="text" required={true}
                                   disabled={!this.state.isLegal}
                                   onChange={this.handleChange}
                                   placeholder={this.state.lastNamePlaceholder} size="40"
                                   id="lastName" name="lastName" value={this.props.lastName}/>
                            <input type="text" required={true}
                                   disabled={!this.state.isLegal}
                                   onChange={this.handleChange}
                                   placeholder={this.state.firstNamePlaceholder} size="40"
                                   id="firstName" name="firstName" value={this.props.firstName}/>
                            <input type="email" required={true}
                                   disabled={!this.state.isLegal}
                                   onChange={this.handleChange}
                                   placeholder={this.state.emailPlaceholder} size="40"
                                   id="email" name="email" value={this.props.email}/>
                            <NumberFormat
                                type="tel"
                                id="phone1"
                                disabled={!this.state.isLegal}
                                format={phoneNumberFormat}
                                className="simple-input"
                                value={this.props.phone1}
                                placeholder="69"
                                onValueChange={this.handleFormattedChange}
                                decimalScale={0} required={true}/>
                            {this.state.errorMessage !== '' ?
                                <div className="login-area__login-box__wrong-creds">
                                    <div className="generic-error-msg">
                                        <span className="icon-icon-attention"/>
                                        <div>{this.state.errorMessage}</div>
                                    </div>
                                </div> : null
                            }
                            <div className="register-merchant-step1__login-button-area">
                                <button type="submit" className="register-merchant-step1__login-button register__button"
                                        disabled={validationInProgress || !this.state.isLegal}>
                                    {validationInProgress ?
                                        <Loader bouncing={false} color={'white'} message=''
                                                style={{marginTop: 0}} bounceClass='smaller-bounces'/> :
                                        <Translate value="pages.register.submitButton"/>
                                    }

                                </button>
                            </div>
                            <ShowHelp/>
                        </form>
                    </div>
                </div>
            </div>
        )
    }
}

export default withRouter(connect(stateMap)(MerchantRegistrationStep1));