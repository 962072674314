import React from 'react';
import {Button, Modal} from "react-bootstrap";
import MidDetails from "./MidDetails";
import {toUppercaseNoAccents} from "../../lib/utils";
import connect from "react-redux/es/connect/connect";
import store from "../../store";
import {setRightColumnAfmAndMidSearched} from "../../actions/rightColumnActions";
import BusinessSupportForm from "../supportForms/BusinessSupportForm";

const Translate = require('react-redux-i18n').Translate;

const I18n = require('react-redux-i18n').I18n;


const stateMap = (store) => {
    return {
        rightColumnReducer: store.rightColumnReducer
    };
};

class MidData extends React.Component {
    constructor(props) {
        super(props)
        this.state = {
            data: props.data,
            showMidDetails: false,
            selectedTid: null,
            supportTid: null,
            showSupportModal: false,
        }
        this.locationReducer = this.locationReducer.bind(this)
        this.renderMidData = this.renderMidData.bind(this)
        this.loopCities = this.loopCities.bind(this)
        this.handleShowModal = this.handleShowModal.bind(this)
        this.handleCloseModal = this.handleCloseModal.bind(this)
        this.handleAliasChange = this.handleAliasChange.bind(this)
        this.openModalAndNullifyAfmMidSearch = this.openModalAndNullifyAfmMidSearch.bind(this)
        this.setTidForSupport = this.setTidForSupport.bind(this);
        this.closeDetailsModal = this.closeDetailsModal.bind(this);
        this.openSupportForm = this.openSupportForm.bind(this);
        this.closeSupportForm = this.closeSupportForm.bind(this);
    }

    componentDidMount() {
        if (this.props.rightColumnReducer.midSearched !== null) {
            this.openModalAndNullifyAfmMidSearch(this.props.rightColumnReducer.midSearched,
                this.props.rightColumnReducer.tidSearched)
        }
    }

    openModalAndNullifyAfmMidSearch(midSearched, tidSearched) {
        this.handleShowModal(midSearched, tidSearched)
        store.dispatch(setRightColumnAfmAndMidSearched(null, null, null))
    }

    componentWillReceiveProps(nextProps) {
        if (nextProps.data !== this.props.data) {
            this.setState({
                data: nextProps.data,
            })
        }

        if (nextProps.rightColumnReducer.midSearched !== this.props.rightColumnReducer.midSearched &&
            nextProps.rightColumnReducer.midSearched !== null) {
            this.openModalAndNullifyAfmMidSearch(nextProps.rightColumnReducer.midSearched,
                nextProps.rightColumnReducer.tidSearched)
        }
    }

    locationReducer(data) {
        return data.reduce((categoryOfCities, {locationCity, mid, locationAddress1, locationAddress2, merchantAlias, hideEcom}) => {
            let cityData = {
                mid: mid,
                locationCity: locationCity,
                locationAddress1: locationAddress1,
                locationAddress2: locationAddress2,
                merchantAlias: merchantAlias,
                hideEcom: hideEcom
            };
            if (!categoryOfCities[locationCity] && !cityData.hideEcom){
                categoryOfCities[locationCity] = [];
            }
            if (!cityData.hideEcom) {
                categoryOfCities[locationCity].push(cityData);
            }
            return categoryOfCities;
        }, {});
    }

    loopCities(citiesData, mid) {
        return (
            <React.Fragment>
                {Object.keys(citiesData).map((city) => (
                    !(citiesData[city].length === 1 && citiesData[city].find((e) => e.hideEcom === true) !== undefined) ?
                        <div className="area__details" key={"business-area__" + city + "-" + mid}>
                            <div className="business-column area" key={"business-column__" + city + "-" + mid}>
                                <h5 className="area__name">{toUppercaseNoAccents(city && city !== "null" && city !== "NULL" ? city : I18n.t('pages.business.midDetails.emptyCity'))}</h5>
                                <div className="business-row area__row">
                                    {citiesData[city].map(midStore => (
                                        !midStore.hideEcom ?
                                        this.renderMidData(midStore):null
                                    ))}
                                </div>
                            </div>
                        </div>:null
                ))}
            </React.Fragment>
        )
    }

    renderMidData(midStore) {
        return (
            <div className="business-column business-clickable" key={midStore.mid} onClick={() => {
                this.handleShowModal(midStore.mid, null)
            }}>
                <div className="area__address">
                    {midStore.locationAddress1}
                    {midStore.locationAddress2 ? ' & ' : null}
                    {midStore.locationAddress2}
                </div>
                <div className="area__mid">
                    {'MID ' + midStore.mid}
                </div>
                {midStore.merchantAlias ?
                    <div className="area__alias">
                        <span>
                            {midStore.merchantAlias}
                        </span>
                    </div> : null
                }
            </div>
        )
    }

    handleShowModal(mid, tid) {
        this.setState({
            showMidDetails: true,
            selectedMid: mid,
            selectedTid: tid
        })
    }

    handleCloseModal() {
        this.setState({
            showMidDetails: false
        })
    }

    handleAliasChange(mid, alias) {
        const data = this.state.data;
        if (data.find((e) => e.mid === mid)) {
            data.find((e) => e.mid === mid).merchantAlias = alias;
            this.setState({
                data: data
            })
        }
    }

    setTidForSupport(tid) {
        this.setState({
            supportTid: tid,
        })
    }

    closeDetailsModal() {
        this.setState({
            showMidDetails: false
        })
    }


    openSupportForm() {
        this.setState({
            showSupportModal: true,
            showMidDetails: false
        })
    }

    closeSupportForm() {
        this.setState({
            showSupportModal: false
        })
    }


    render() {
        let midData = this.state.data != null ? this.state.data : null;
        let allAreasMidData = this.props.allAreasMidData;
        return (
            <div>
                {midData != null ?
                    <div>
                        <Modal show={this.state.showMidDetails}
                               onHide={this.handleCloseModal}
                               bsSize="large">
                            <Modal.Header closeButton bsClass="modal-header business-modal__header">
                            </Modal.Header>
                            <Modal.Body bsClass="business-modal">
                                <MidDetails
                                    data={allAreasMidData.find((e) => (e !== undefined ? e.mid === this.state.selectedMid: false))}
                                    iban={this.props.iban}
                                    setAliasesArr={this.props.setAliasesArr}
                                    updateTerminalAllias={this.props.updateTerminalAllias}
                                    selectedTid = {this.state.selectedTid}
                                    setTidForSupport = {this.setTidForSupport}
                                    ecrMap={this.props.ecrMap}
                                    locale={this.props.locale}
                                    handleAliasChange={this.handleAliasChange}
                                    handlePostCodeChange = {this.props.handlePostCodeChange}
                                />
                                <div className="support-business__wrapper">
                                    <h5>
                                        <Translate value="modals.support.business.message"/>
                                    </h5>
                                    <Button className="support-form__btn btn-default"
                                            onClick={() => {
                                                this.openSupportForm();
                                            }}>
                                        <Translate value="modals.support.business.button"/>
                                    </Button>
                                </div>
                            </Modal.Body>
                        </Modal>
                        <div className="support-business__wrapper">
                            <Modal  bsClass="modal"
                                    onHide={this.closeSupportForm}
                                    dialogClassName="modal-dialog support-modal__dialog"
                                    show={this.state.showSupportModal}>
                                <Modal.Header closeButton bsClass="modal-header support-modal_header">
                                </Modal.Header>
                                <Modal.Body bsClass="modal-body support-modal_body">
                                    <BusinessSupportForm
                                        selectedMid = {this.state.selectedMid}
                                        selectedTid = {this.state.supportTid ? this.state.supportTid : this.state.selectedTid}
                                        closeModal = {this.closeSupportForm}/>
                                </Modal.Body>
                            </Modal>
                        </div>
                        {this.loopCities(this.locationReducer(midData, this.state.selectedMid))}
                    </div>
                    : null}
            </div>
        )
    }
}

export default connect(stateMap)(MidData);