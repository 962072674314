import {combineReducers} from "redux";
import {i18nReducer} from "react-redux-i18n";
import localeReducer from "./localeReducer";
import sideModalReducer from "./sideModalReducer";
import userReducer from "./userReducer";
import merchantReducer from "./merchantReducer";
import rightColumnReducer from "./rightColumnReducer";
import registrationReducer from "./registrationReducer";
import termsAndConditionsReducer from "./termsAndConditionsReducer";
import emailReducer from "./emailReducer";
import chartReducer from "./chartReducer";
import debitNotesReducer from "./debitNotesReducer";

const appReducer = combineReducers({
	locale: localeReducer,
	sideModalReducer: sideModalReducer,
	userReducer: userReducer,
	merchantReducer: merchantReducer,
	rightColumnReducer: rightColumnReducer,
	registrationReducer: registrationReducer,
	termsAndConditionsReducer: termsAndConditionsReducer,
	emailReducer: emailReducer,
	chartReducer: chartReducer,
	debitNotesReducer: debitNotesReducer,
	i18n: i18nReducer
});

const rootReducer = (state, action) => {
	if (action.type === 'LOGOUT_FULFILLED' || (action.type === 'GET_TOKEN_REJECTED' && state.userReducer.phone === '') || action.type === 'STOP_IMPERSONATION_FULFILLED') {
        const {i18n} = state
        state = {i18n}
    }
	return appReducer(state, action)
}

export default rootReducer;