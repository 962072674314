import React from 'react';
import store from "../../store";
import {getInstallmentDetails} from "../../providers/authorizations";
import moment from "moment";
import connect from "react-redux/es/connect/connect";

import {formatAmount} from "../../lib/utils";

const Translate = require('react-redux-i18n').Translate;

const I18n = require('react-redux-i18n').I18n

const stateMap = (store) => {
    return {
        userReducer: store.userReducer
    };
};

const euro = " €";

export class InstallmentDetails extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            id: '',
            authorizationDate: '',
            authorizationTime: '',
            cardNumber: '',
            cardTypeBrand: '',
            authorizationAmount: '',
            response: '',
            responseCode: '',
            merchantId: '',
            terminalId: '',
            merchantAlias: '',
            terminalAlias: '',
            vMerchantId: '',
            vTerminalId: '',
            totalInstalments: '',
            bank: '',
            acquirerBankId: '',
            redemptionAmount: '',
            tillNumber: '',
            cashierNumber: '',
            paymentInfo: '',
            transactionType: '',
            transactionAttribute: '',
            cardholderVerMode: '',
            cardEntryMode: '',
            contactlessFlag: '',
            authorizationCode: '',
            retrievalReferenceNumber: '',
            closeBatch: '',
            settlementDate: '',
            status3D: '',
            infoField1: '',
            infoField2: '',
            infoField3: '',
            dccFlag: '',
            dccAmount: '',
            dccCurrency: '',
            dccRate: '',
            cardlessType: '',
            statusId: '',
            originalTerminalId: '',
            paymentCode: '',
            walletId: '',
            remainingAmount: '',
            refundSum: '',
            refundedOn: '',
            refundedBy: '',
            tipsAmount: '',
            ecommerceOrderId: '',
            amountToRefund: null,
            showRefundModal: false,
            isCardlinkOne: false,
            merchantFee: '',
            commissionAmountMerchant: '',
            commissionAmountAcquirer: '',
            commissionAmountAcquirerOthers: '',
            paymentReqDate: '',
            paymentRespDate: '',
            paymentRespMessage: '',
            total: 0,
            tlgsutan: 0
        }
        this.getInstallmentDetails = this.getInstallmentDetails.bind(this);
    }

    componentWillMount() {
        this.getInstallmentDetails()
    }

    getInstallmentDetails() {
        store.dispatch(getInstallmentDetails(this.props.id)).then(
            (response) => {
                const data = response.value.data.payload;
                this.setState({
                    merchantId: data.merchantId,
                    terminalId: data.terminalId,
                    id: data.id,
                    paymentDate: data.paymentDate !== null ? moment(data.paymentDate).format('DD/MM/YYYY') : '-',
                    trxId: data.trxId != null ? data.trxId : '-',
                    amount: data.amount != null ? formatAmount(I18n._locale, data.amount, 2) + euro : '-',
                    merchantFee: data.merchantFee != null ? formatAmount(I18n._locale, data.merchantFee, 2) + euro : '-',
                    commissionAmountMerchant: data.commissionAmountMerchant != null ? formatAmount(I18n._locale, data.commissionAmountMerchant, 2) + euro : '-',
                    number: data.number != null ? data.number : '-',
                    statusId: data.statusId != null ? I18n.t('pages.installments.details.statusDescription.' + data.statusId) : '-',
                    paymentReqDate: data.paymentReqDate != null ? moment(data.paymentReqDate).format('DD/MM/YYYY HH:mm:ss') : '-',
                    paymentRespDate: data.paymentRespDate != null ? moment(data.paymentRespDate).format('DD/MM/YYYY HH:mm:ss') : '-',
                    paymentRespMessage: data.paymentRespMessage != null ? data.paymentRespMessage : '-',
                    debitNoteNumber: data.debitNoteNumber != null ? data.debitNoteNumber : '-',
                    debitNoteGenerationDate: data.debitNoteGenerationDate != null ? moment(data.debitNoteGenerationDate).format('DD/MM/YYYY HH:mm:ss') : '-',
                    debitNoteScheme: data.debitNoteScheme != null ? data.debitNoteScheme : '-',
                    debitNoteProduct: data.debitNoteProduct != null ? data.debitNoteProduct : '-',
                    debitNoteMonth: data.debitNoteMonth != null ? data.debitNoteMonth : '-',
                    debitNoteYear: data.debitNoteYear != null ? data.debitNoteYear : '-',
                    createDatetime: data.createDatetime != null ? moment(data.createDatetime).format('DD/MM/YYYY HH:mm:ss') : '-',
                    updateDatetime: data.updateDatetime != null ? moment(data.updateDatetime).format('DD/MM/YYYY HH:mm:ss') : '-',
                    authorizationDate: data.authorizationDate != null ? moment(data.authorizationDate).format('DD/MM/YYYY') : '-',
                    tlgsutan: data.tlgsutan != null ? data.tlgsutan : 0,
                    total: data.total != null ? data.total : 0
                })
            },
            (error) => {
            })
    }

    render() {
        return (
            <React.Fragment>
                <div className="all-details__wrapper">
                    <div className="transaction__details_modal-header--print transaction__details_modal-header">
                        <h4 className="modal-title">
                            <Translate value="pages.transactions.details.title"/>
                        </h4>
                    </div>
                    <div className="all-details__detail-row">
                        <div className="all-details__detailColumn">
                            <Translate value="pages.installments.details.merchantId"/>
                            <span>{this.state.merchantId}</span>
                        </div>
                        <div className="all-details__detailColumn">
                            <Translate value="pages.installments.details.terminalId"/>
                            <span>{this.state.terminalId}</span>
                        </div>
                        <div className="all-details__detailColumn">
                        </div>
                        <div className="all-details__detailColumn">
                        </div>
                        <div className="all-details__detailColumn">
                        </div>
                    </div>

                    <div className="all-details__detail-row">
                        <div className="all-details__detailColumn">
                            <Translate value="pages.installments.details.id"/>
                            <span>{this.state.id}</span>
                        </div>
                        <div className="all-details__detailColumn">
                            <Translate value="pages.installments.details.trxId"/>
                            <span>{this.state.trxId}</span>
                        </div>
                        <div className="all-details__detailColumn">
                            <Translate value="pages.installments.details.amount"/>
                            <span>{this.state.amount}</span>
                        </div>
                        <div className="all-details__detailColumn">
                            <Translate value="pages.installments.details.merchantFee"/>
                            <span>{this.state.merchantFee}</span>
                        </div>
                        <div className="all-details__detailColumn">
                            <Translate value="pages.installments.details.commissionAmountMerchant"/>
                            <span>{this.state.commissionAmountMerchant}</span>
                        </div>
                    </div>

                    <div className="all-details__detail-row">
                        <div className="all-details__detailColumn">
                            <Translate value="pages.installments.details.number"/>
                            <span>{this.state.number} of {this.state.total}</span>
                        </div>
                        <div className="all-details__detailColumn">
                            <Translate value="pages.installments.details.paymentDate"/>
                            <span>{this.state.paymentDate}</span>
                        </div>
                        <div className="all-details__detailColumn vMerchant-col">
                            <Translate value="pages.installments.details.status"/>
                            <span>{this.state.statusId}</span>
                        </div>
                        <div className="all-details__detailColumn">
                            <Translate value="pages.installments.details.paymentReqDate"/>
                            <span>{this.state.paymentReqDate}</span>
                        </div>
                        <div className="all-details__detailColumn">
                            <Translate value="pages.installments.details.paymentRespDate"/>
                            {this.state.paymentRespDate}
                        </div>
                    </div>
                    <div className="all-details__detail-row">
                        <div className="all-details__detailColumn">
                            <Translate value="pages.installments.details.paymentRespMessage"/>
                            <span>{this.state.paymentRespMessage}</span>
                        </div>
                        <div className="all-details__detailColumn">
                            <Translate value="pages.installments.details.debitNoteNumber"/>
                            <span>{this.state.debitNoteNumber}</span>
                        </div>
                        <div className="all-details__detailColumn">
                            <Translate value="pages.installments.details.debitNoteGenerationDate"/>
                            <span>{this.state.debitNoteGenerationDate}</span>
                        </div>
                        <div className="all-details__detailColumn">
                            <Translate value="pages.installments.details.debitNoteScheme"/>
                            <span>{this.state.debitNoteScheme}</span>
                        </div>
                        <div className="all-details__detailColumn">
                            <Translate value="pages.installments.details.debitNoteProduct"/>
                            <span>{this.state.debitNoteProduct}</span>
                        </div>
                    </div>
                    <div className="all-details__detail-row">
                        <div className="all-details__detailColumn">
                            <Translate value="pages.installments.details.debitNoteMonth"/>
                            <span>{this.state.debitNoteMonth}</span>
                        </div>
                        <div className="all-details__detailColumn">
                            <Translate value="pages.installments.details.debitNoteYear"/>
                            <span>{this.state.debitNoteYear}</span>
                        </div>
                        <div className="all-details__detailColumn">
                            <Translate value="pages.installments.details.createDatetime"/>
                            <span>{this.state.createDatetime}</span>
                        </div>
                        <div className="all-details__detailColumn">
                            <Translate value="pages.installments.details.updateDatetime"/>
                            <span>{this.state.updateDatetime}</span>
                        </div>
                        <div className="all-details__detailColumn">
                        </div>
                    </div>
                </div>
            </React.Fragment>
        )
    }
}


export default connect(stateMap)(InstallmentDetails);
