import React from "react";
import {withRouter} from "react-router-dom";
import connect from "react-redux/es/connect/connect";
import {setRightColumnAfmAndMidSearched} from "../../actions/rightColumnActions";
import store from "../../store";
import SearchBoxRenderer from "../common/SearchBoxRenderer";
import HotSpot from "../common/HotSpot";
import {
    getMerchantTreeSearchValues,
    getMidsAndTidsByAfm,
} from "../../providers/merchants";
import Loader from "../common/Loader";
import localforage  from "localforage";

const stateMap = (store) => {
    return {
        merchantReducer: store.merchantReducer,
        userReducer: store.userReducer
    };
};


class QuickFindSearch extends React.Component {
    _isMounted = false;

    constructor(props) {
        super(props)

        this.state = {
            searchedItem: '',
            searchDropdownData: null,
            selectedAfm: undefined,
            merchantTreeValues: []
        };

        this.handleSearchDropdownValues = this.handleSearchDropdownValues.bind(this);
        this.handleMerchantTreeValues = this.handleMerchantTreeValues.bind(this);
        this.handleChange = this.handleChange.bind(this)
        this.findAfmAndMidToSearchFor = this.findAfmAndMidToSearchFor.bind(this)
        this.openMidModalAndDispatchUser = this.openMidModalAndDispatchUser.bind(this)
    }

    componentDidMount() {
        this._isMounted = true;
        this.setState({
            selectedAfm: this.props.merchantReducer.afm ? this.props.merchantReducer.afm : undefined
        }, ()=> {
            this.handleSearchDropdownValues(this.state.selectedAfm);
            this.handleMerchantTreeValues(this.state.selectedAfm);
        });
    }

    componentWillUnmount() {
        this._isMounted = false;
    }

    componentWillReceiveProps(nextProps) {
        if (this.props.merchantReducer.afm !== nextProps.merchantReducer.afm && this._isMounted) {
            this.setState({
                selectedAfm: nextProps.merchantReducer.afm
            }, ()=> {
                this.handleSearchDropdownValues(this.state.selectedAfm);
                this.handleMerchantTreeValues(this.state.selectedAfm);
            })
        }
    }

    handleSearchDropdownValues(afm) {
        let key = afm ? afm + '-quick_search' : 'all-quick_search';
        let _this = this;
        localforage.getItem(key).then(function(value) {
            if (value && value.length > 0) {
                _this.setState({
                    searchDropdownData: value
                });
            } else {
                store.dispatch(getMerchantTreeSearchValues(afm)).then((resp)=> {
                    localforage.setItem(key, resp.value.data).then(function(value) {
                        _this.setState({
                            searchDropdownData: value
                        });
                    });
                }, (err)=> {
                    console.log(err);
                    _this.setState({
                        searchDropdownData: []
                    })
                });
            }
        });
    }

    handleMerchantTreeValues(afm) {
        let key = afm ? afm + '-merchant_tree_values' : 'all-merchant_tree_values';
        let _this = this;
        localforage.getItem(key).then(function(value) {
            if (value && value.length > 0) {
                _this.setState({
                    merchantTreeValues: value
                });
            } else {
                store.dispatch(getMidsAndTidsByAfm(1, -1, afm, true)).then((response) => {
                    localforage.setItem(key, response.value.data).then(function(value) {
                        _this.setState({
                            merchantTreeValues: response.value.data
                        })
                    });
                }, (err) => {
                    console.log(err)
                    _this.setState({
                        merchantTreeValues: []
                    })
                });
            }
        });
    }

    findAfmAndMidToSearchFor(searchedItem) {
        let afmMidBelongsTo = null
        let midToSearchFor = null
        let tidToSearchFor = null
        let merchantTree = this.state.merchantTreeValues;
        merchantTree.forEach(merchant => {
            merchant.mids.forEach(midEntry => {
                if (midEntry !== null) {
                    if (searchedItem.value === midEntry.mid) {
                        if (midToSearchFor === null) {
                            afmMidBelongsTo = merchant.afm;
                            midToSearchFor = midEntry.mid;
                        }
                    }
                    midEntry.tids.forEach(tid => {
                        if (tid === searchedItem.value) {
                            if (midToSearchFor === null) {
                                afmMidBelongsTo = merchant.afm;
                                midToSearchFor = midEntry.mid;
                                tidToSearchFor = tid;
                            }
                        }
                    })
                }
            })
        });
        return {
            afmMidBelongsTo: afmMidBelongsTo,
            midToSearchFor: midToSearchFor,
            tidToSearchFor: tidToSearchFor
        }
    }

    handleChange(searchedItem) {
        let afmMidBelongsTo, midToSearchFor, tidToSearchFor
        ({afmMidBelongsTo, midToSearchFor, tidToSearchFor} = this.findAfmAndMidToSearchFor(searchedItem))
        this.openMidModalAndDispatchUser(afmMidBelongsTo, midToSearchFor, tidToSearchFor)
    }

    openMidModalAndDispatchUser(afmMidBelongsTo, midSearched, tidSearched) {
        store.dispatch(setRightColumnAfmAndMidSearched(afmMidBelongsTo, midSearched, tidSearched))
    }

    render() {
        return (
        <div className="hotSpot-element__wrapper hotSpot-element__wrapper--quick-find">
            {this.props.userReducer.showHotSpots ?
                <HotSpot
                    hotProp="quickSearch"
                    placement="bottom"
                />
                : null}
            {this.state.searchDropdownData && this.state.searchDropdownData.length > 0 ?
            <SearchBoxRenderer searchDropdownData={this.state.searchDropdownData}
                               searchedItem={this.state.searchedItem}
                               handleChange={this.handleChange}/>:
                <Loader bounceClass="smaller-bounces"
                        bouncing={false} color={'bahama-blue'} message=''/>}
        </div>

        )
    }
}

export default withRouter(connect(stateMap)(QuickFindSearch));