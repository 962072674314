import React from "react";
import {connect} from "react-redux";
import store from "../../store";
import {getGroups, getProfile, login} from "../../providers/users";
import {NavLink, withRouter} from "react-router-dom";
import LocaleSwitch from "../common/LocaleSwitch";
import LoginErrorMessages from "./login/LoginErrorMessages";
import Logo from "../common/Logo";
import browser from 'browser-detect';
import {Modal} from "react-bootstrap";
import {getAssociations} from "../../providers/authorizations";
import {redirectToCardlink, setUserLoggedInStatus, toggleHelpBubble} from "../../actions/userActions";
import {findUserAssociations, getEcomAvailabilityByAfm, getMerchantOrderStatus} from "../../providers/merchants";
import Wave from "../common/Wave";
import Loader from "../common/Loader";
import {getLatestTermsAndConditions} from "../../providers/termsAndConditions";
import Button from "react-bootstrap/es/Button";
import localforage from "localforage";

const Translate = require('react-redux-i18n').Translate;

const I18n = require('react-redux-i18n').I18n

const stateMap = (store) => {
    return {
        i18n: store.i18n,
        userReducer: store.userReducer,
        merchantReducer: store.merchantReducer
    };
};

class Login extends React.Component {
    constructor(props) {
        super(props)
        this.state = {
            emailPlaceholder: I18n.t('pages.login.emailPlaceholder'),
            passwordPlaceholder: I18n.t('pages.login.passwordPlaceholder'),
            wrongCreds: false,
            roleNotMerchant: false,
            nonConfirmedAccount: false,
            disabledAccount: false,
            username: "",
            password: "",
            successfulPassReset: false,
            loginButtonLoaderEnabled: false,
            showPassword: false,
            notAllowedIp: false
        };

        this.login = this.login.bind(this)
        this.checkIfTermsAreNotAccepted = this.checkIfTermsAreNotAccepted.bind(this)
        this.checkIfPasswordHasExpired = this.checkIfPasswordHasExpired.bind(this)
        this.checkIfTooManyLoginAttampts = this.checkIfTooManyLoginAttampts.bind(this)
        this.handleChange = this.handleChange.bind(this)
        this.capsLockDetect = this.capsLockDetect.bind(this)
        this.removeCapsWarning = this.removeCapsWarning.bind(this)
        this.focusedInPassword = this.focusedInPassword.bind(this)
        this.checkUserIsValid = this.checkUserIsValid.bind(this)
        this.checkRoleIsMerchant = this.checkRoleIsMerchant.bind(this)
        this.invalidateAllErrors = this.invalidateAllErrors.bind(this)
        this.handleCloseModal = this.handleCloseModal.bind(this)
        this.getMidsEtc = this.getMidsEtc.bind(this)
        this.getProfileActions = this.getProfileActions.bind(this)
        this.toggleShowPassword = this.toggleShowPassword.bind(this)
        this.impersonatorLogin = this.impersonatorLogin.bind(this)
        this.checkUserDisabled = this.checkUserDisabled.bind(this)
        this.validUserActions = this.validUserActions.bind(this)

    }

    componentWillMount() {
        this.impersonatorLogin()
    }

    componentDidMount() {
        if (this.props.userReducer.userLoggedIn) {
            this.props.history.push("/overview")
        }
        document.addEventListener("keydown", this.capsLockDetect, false);
        browser();
        this.setState({
            successfulPassReset: this.props.location.state && this.props.location.state.successfulPassReset ?
                this.props.location.state.successfulPassReset : false
        })
        this.props.history.push({hash: this.props.i18n.locale})
    }

    componentWillUnmount() {
        document.removeEventListener("keydown", this.capsLockDetect, false);
    }


    focusedInPassword(event) {
        this.setState({
            focusedInPassword: true
        })
    }


    capsLockDetect(e) {
        if (e.keyCode === 20 && this.state.capsLockEnabled !== null) {
            this.setState({
                capsLockEnabled: !this.state.capsLockEnabled
            })
        }
    }

    removeCapsWarning() {
        this.setState({
            focusedInPassword: false
        })
    }

    componentWillReceiveProps(nextProps) {
        if (nextProps.i18n !== this.props.i18n) {
            this.setState({
                emailPlaceholder: I18n.t('pages.login.emailPlaceholder'),
                passwordPlaceholder: I18n.t('pages.login.passwordPlaceholder')
            })
            this.props.history.push({hash: nextProps.i18n.locale})
        }
    }

    invalidateAllErrors() {
        this.setState({
            wrongCreds: false,
            nonConfirmedAccount: false,
            disabledAccount: false,
            roleNotMerchant: false,
            notAllowedIp: false
        })
    }

    checkRoleIsMerchant(profile) {
        if (profile.role !== 'MERCHANT') {
            this.invalidateAllErrors();
            this.setState({
                roleNotMerchant: true
            });
            return 1
        }
        return 0
    }

    checkUserDisabledFlag(profile) {
        if (profile.disabled === true) {
            this.invalidateAllErrors();
            this.setState({
                disabledAccount: true
            });
            return 1
        }
        return 0
    }

    checkUserNotConfirmed(profile) {
        // when the user is not confirmed the whole payload returned is null
        if (profile === null) {
            this.invalidateAllErrors();
            this.setState({
                nonConfirmedAccount: true
            });
            return 1
        }
        return 0
    }


    checkUserIsValid(profile) {
        if (this.checkUserNotConfirmed(profile) !== 1)
            if (this.checkUserDisabledFlag(profile) !== 1)
                if (this.checkRoleIsMerchant(profile) !== 1)
                    return 0

        return 1
    }

    validUserActions(profile, showTermsAndConditions) {
        let defaultPageOverview = profile.value.data.payload.defaultPageOverview
        if (this.checkUserIsValid(profile.value.data.payload) === 0) {
            this.invalidateAllErrors()
            store.dispatch(getMerchantOrderStatus(undefined, undefined)).then(
                () => {
                    if (showTermsAndConditions) {
                        this.props.showTermsAndConditions(false)
                    }
                    this.getMidsEtc(defaultPageOverview)
                    store.dispatch(getEcomAvailabilityByAfm())
                    store.dispatch(toggleHelpBubble(true));

                });
        } else {
            store.dispatch(setUserLoggedInStatus(false))
            this.setState({
                loginButtonLoaderEnabled: false
            })
        }
    }

    login() {
        const username = this.state.username
        const password = this.state.password
        const currentUrl = window.location.origin
        store.dispatch(getLatestTermsAndConditions(this.props.i18n.locale))
        this.setState({
            loginButtonLoaderEnabled: true
        })
        store.dispatch(login(username, password, currentUrl)).then(
            (response) => {
                if (response.value && response.value.data && response.value.data.payload && response.value.data.payload.errorMessage) {
                    this.setState({
                        loginButtonLoaderEnabled: false
                    })
                    let errorMessage = response.value.data.payload.errorMessage
                    if (this.checkIfPasswordHasExpired(errorMessage)) {
                        this.props.history.push({
                            pathname: '/expired/password',
                        })
                    } else if (this.checkIfTermsAreNotAccepted(errorMessage)) {
                        this.getProfileActions(true)
                    } else {
                        store.dispatch(setUserLoggedInStatus(false))
                        this.invalidateAllErrors()
                        this.setState({
                            wrongCreds: true
                        })
                    }
                } else {
                    this.getProfileActions()
                }
            },
            (error) => {
                this.setState({
                    loginButtonLoaderEnabled: false
                })
                if (this.checkIfIpNotAllowed(error.response)) {
                    store.dispatch(setUserLoggedInStatus(false))
                    this.invalidateAllErrors()
                    this.setState({
                        notAllowedIp: true
                    })
                } else if (this.checkUserDisabled(error.response)) {
                    store.dispatch(setUserLoggedInStatus(false))
                    this.invalidateAllErrors()
                    this.setState({
                        disabledAccount: true
                    })
                } else if (this.checkIfTooManyLoginAttampts(error.response.data.description)) {
                    store.dispatch(setUserLoggedInStatus(false))
                    localforage.setItem('userResetPwPhone', true)
                    this.invalidateAllErrors()
                    this.setState({
                        wrongCreds: true
                    })
                } else {
                    store.dispatch(setUserLoggedInStatus(false))
                    this.invalidateAllErrors()
                    this.setState({
                        wrongCreds: true
                    })
                }
            }).then(() => {
            store.dispatch(getGroups())
        })
    }

    getProfileActions(showTermsAndConditions) {
        store.dispatch(getProfile()).then(
            (response) => {
                if (response.value.data.payload.source === 'cardlink-one') {
                    store.dispatch(findUserAssociations()).then(
                        (res) => {
                            if (!res.value.data.payload || res.value.data.payload.length === 0) {
                                store.dispatch(redirectToCardlink(true))
                            } else {
                                this.validUserActions(response, showTermsAndConditions)
                            }
                        }
                    )
                } else {
                    this.validUserActions(response, showTermsAndConditions)
                }
            },
            (error) => {

            })
    }


    checkIfTermsAreNotAccepted(errorResponse) {
        return errorResponse === 'TERMS_AND_CONDITIONS_LATEST_VERSION_NOT_ACCEPTED'
    }

    checkIfPasswordHasExpired(errorResponse) {
        return errorResponse === 'PASSWORD_EXPIRED'
    }

    checkIfTooManyLoginAttampts(errorResponse) {
        return errorResponse === 'TOO_MANY_LOGIN_ATTEMPTS'
    }

    checkIfIpNotAllowed(errorResponse) {
        return errorResponse.status && errorResponse.status === 403 && errorResponse.data && errorResponse.data.errors
            && errorResponse.data.errors.length > 0 && errorResponse.data.errors[0] === 'NOT_ALLOWED_IP'
    }

    checkUserDisabled(errorResponse) {
        return errorResponse.status && errorResponse.status === 403 && errorResponse.data && errorResponse.data.errors
            && errorResponse.data.errors.length > 0 && errorResponse.data.errors[0] === 'USER_IS_DISABLED'
    }

    getMidsEtc(hideIntroScreen) {
        store.dispatch(getAssociations(-1)).then(
            () => {
                store.dispatch(setUserLoggedInStatus(true))
                if (hideIntroScreen) {
                    this.props.showIntroScreen(false)
                    this.props.history.push({
                        pathname: '/overview',
                        search: this.props.location.state ? (this.props.location.state.from ? this.props.location.state.from.search : null) : null
                    })
                } else {
                    this.props.showIntroScreen(true)
                    this.props.history.push({
                        pathname: '/introScreen',
                    })
                }
            });
    }

    handleChange(event) {
        this.setState({
            [event.target.id]: event.target.value
        })
    }

    toggleShowPassword() {
        this.setState(prevState => ({
            showPassword: !prevState.showPassword
        }));
    }

    handleCloseModal() {
        this.setState({
            successfulPassReset: false
        });
        this.props.history.replace({
            pathname: '/login',
            state: {}
        })
    }

    impersonatorLogin() {
        if (this.props.userReducer.impersonator !== null) {
            store.dispatch(getLatestTermsAndConditions(this.props.i18n.locale))
            store.dispatch(getGroups())
            this.setState({
                loginButtonLoaderEnabled: true
            });
            this.getProfileActions()
        }
    }

    render() {
        if (this.props.userReducer.redirectToCardlink) {
            this.props.history.push("/cardlink")
        }
        let currentYear = new Date().getFullYear();
        const loggedIn = this.props.userReducer.userLoggedIn;
        const loginInProgress = (this.props.userReducer && this.props.userReducer.loginProcedure &&
            this.props.userReducer.loginProcedure.fetching) || this.state.loginButtonLoaderEnabled;
        return (
            loggedIn === false ?
                <div className="login-area__wrapper">
                    <Modal
                        show={this.state.roleNotMerchant}
                        backdrop="static"
                        container={this}
                        dialogClassName="intro-dialog intro-dialog__login-screen">
                        <Modal.Body>
                            <article className="article__login-screen">
                                <Translate value="pages.login.nonMerchantModalMsgA"/>
                                <br/>
                                <a href="/mrchnt">mrchnt.cardlink.gr</a>
                                <br/>
                                <Translate value="pages.login.nonMerchantModalMsgB"/>
                            </article>
                        </Modal.Body>
                    </Modal>
                    <Modal show={this.state.successfulPassReset} onHide={this.handleCloseModal}
                           dialogClassName="reset-area-step1__custom-modal">
                        <Modal.Header closeButton>
                            <Modal.Title>
                                <Translate className="generic-modal-title" value="pages.reset.successfulPassChange"/>
                            </Modal.Title>
                        </Modal.Header>
                        <Modal.Body>
                            <div className="generic-success-modal-msg">
                                <span className="icon-success"/>
                                <Translate className="text-success" value="pages.reset.youcanNowLogin"/>
                            </div>
                        </Modal.Body>
                    </Modal>
                    <main className="login-area">
                        <div className="login-area__top">
                            <LocaleSwitch type={"login-area"}></LocaleSwitch>
                        </div>
                        <div className="login-area__content">
                            <div className="login-area__left">
                                <p className="login-area__sub-header">
                                    <Translate value="pages.login.tool"/>
                                </p>
                                <h3 className="login-area__text login-area__text_intro">
                                    <Translate value="pages.login.everyDay"/>
                                </h3>
                                <ul className="login-area__text login-area__text_list">
                                    <li>
                                        <Translate value="pages.login.listItem1"/>
                                    </li>
                                    <li>
                                        <Translate value="pages.login.listItem2"/>
                                    </li>
                                    <li>
                                        <Translate value="pages.login.listItem3"/>
                                    </li>
                                </ul>
                                <div className="login-area__copyright">{'©Cardlink ' + currentYear}</div>
                            </div>
                            <div className="login-area__login-with-forgot">
                                <div className="login-area__login-box">
                                    <Logo isMain={false} isHeader={false} imgClassName="login" isNotLink={true}/>
                                    <form onSubmit={(e) => {
                                        e.preventDefault();
                                        this.login();
                                    }}>
                                        <Translate value="pages.login.email"/>
                                        <input type="email" required={true}
                                               onChange={this.handleChange}
                                               placeholder={this.state.emailPlaceholder} size="40"
                                               id="username" name="username" value={this.state.username}/>
                                        <Translate value="pages.login.password"/>
                                        <div className="password-container">
                                            <div className="login-area__login-box__password">
                                                <input type={this.state.showPassword ? "text" : "password"}
                                                       onChange={this.handleChange}
                                                       onBlur={this.removeCapsWarning}
                                                       onFocus={this.focusedInPassword}
                                                       placeholder={this.state.passwordPlaceholder} size="40"
                                                       required={true}
                                                       id="password"
                                                       name="password"
                                                       value={this.state.password}/>
                                            </div>
                                            <Button onClick={this.toggleShowPassword}
                                                    className={"icon-password-eye" + (this.state.showPassword ? " icon-show-password-eye" : " icon-hide-password-eye")}></Button>
                                        </div>
                                        <div className="login-area__login-box__wrong-creds">
                                            <LoginErrorMessages info={this.state}/>
                                        </div>

                                        <div className="login-area__login-button-area">
                                            <button type="submit" className="login-area__login-button"
                                                    disabled={loginInProgress}>
                                                {loginInProgress ?
                                                    <Loader bouncing={false} color={'white'} message=''
                                                            style={{marginTop: 0}} bounceClass='smaller-bounces'/> :
                                                    <Translate value="pages.login.buttonText"/>
                                                }
                                            </button>
                                        </div>
                                        <div className="login-area__forgot-password">
                                            <NavLink to={"/reset"} activeClassName="_active">
                                                <Translate value="pages.login.forgotPassword"/>
                                            </NavLink>
                                        </div>
                                    </form>
                                </div>
                                <div className="login-area__register-area">
                                    <Translate value="pages.login.newUser"/>
                                    <NavLink to={"/register"} activeClassName="_active">
                                        <button type="button" className="login-area__register-button">
                                            <Translate value="pages.login.registerIn5Steps"/>
                                        </button>
                                    </NavLink>
                                </div>
                            </div>
                        </div>
                        <Wave/>
                    </main>
                </div> : null
        )
    }
}

export default withRouter(connect(stateMap)(Login));