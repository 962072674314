export function switchCompany(afm, name, isCardlinkOne) {
    return {
        type: "SET_CURRENT_COMPANY",
        afm: afm,
        name: name,
        isCardlinkOne: isCardlinkOne
    }
}

export function switchStore(midsCommaSeparated, midsSearchBoxFormat) {
    return {
        type: "SET_CURRENT_STORE",
        midsCommaSeparated: midsCommaSeparated,
        midsSearchBoxFormat: midsSearchBoxFormat
    }
}

export function setOrderStatus(orderStatus) {
    return {
        type: "SET_ORDER_STATUS",
        orderStatus: orderStatus
    }
}

export function setAuthorizationsReportStatus() {
    return {
        type: "INIT_AUTHORIZATION_REPORT"
    }
}
