import {makeRequest} from "../api-client";

const middlewareUrl = process.env.MIDDLEWARE_URL

export const getMidsByAfm = (page, rowsPerPage, afm) => {
    let headers = {'x-afm': afm};
    return makeRequest(middlewareUrl + "/merchants/get/mids/by/afm", "GET", {
        "page": page,
        "rowsPerPage": rowsPerPage,
    }, headers, "GET_MIDS_BY_AFM")
};

export const getMidsNumByAfm = (page, rowsPerPage, afm) => {
    let headers = {'x-afm': afm};
    return makeRequest(middlewareUrl + "/merchants/get/mids/num/by/afm", "GET", {
        "page": page,
        "rowsPerPage": rowsPerPage,
    }, headers, "GET_MIDS_NUM_BY_AFM")
};

export const getMidsAndTidsByAfm = (page, rowsPerPage, afm, removeLocation) => {
    let headers = {'x-afm': afm};
    return makeRequest(middlewareUrl + "/merchants/get/mids/tids/by/afm", "GET", {
        "page": page,
        "rowsPerPage": rowsPerPage,
        "removeLocation": removeLocation
    }, headers, "GET_MIDS_TIDS_LOCATION_BY_AFM")
};

export const getMidsAndTidsFilteredByAfmAndMidAndEcom = (page, rowsPerPage, afm, mid, ecom) => {
    let headers = {'x-afm': afm};
    return makeRequest(middlewareUrl + "/merchants/get/grouped/tids/by/mid/ecom", "GET", {
        "page": page,
        "rowsPerPage": rowsPerPage,
        "mid": mid,
        "ecom": ecom
    }, headers, "GET_TIDS_GROUPED_BY_MID")
};


export const getMerchantTree = (afm, mid) => {
    let headers = {'x-afm': afm};
    return makeRequest(middlewareUrl + "/merchants/merchant/tree", "GET", {
        "mid": mid
    }, headers,  "GET_MERCHANT_TREE")
};

export const getMerchantOrderStatus = (afm, mid) => {
    let headers = {'x-afm': afm};
    return makeRequest(middlewareUrl + "/merchants/check/order/status", "GET", {
        "mid": mid
    }, headers,  "GET_MERCHANT_ORDER_STATUS")
};

export const getMerchantTreeSearchValues = (afm, mid) => {
    let headers = {'x-afm': afm};
    return makeRequest(middlewareUrl + "/merchants/merchant/tree/search", "POST", {
        "mid": mid,
    }, headers, "GET_MERCHANT_TREE_SEARCH")
};

export const getMidsTidsWithAliases = (afm, mid) => {
    let headers = {'x-afm': afm};
    return makeRequest(middlewareUrl + "/merchants/get/mids/tids/alias", "POST", {
        "mid": mid
    }, headers, "GET_MID_TID_ALIASES")
};


export const updateMerchantAlias = (mid, merchantAlias) => {

    return makeRequest(middlewareUrl + "/merchants/update/mid/alias", "POST", {
        mid: mid,
        merchantAlias: merchantAlias
    }, {}, "UPDATE_MERCHANT_MID_ALIAS")
};

export const updateTerminalAlias = (mid, tid, terminalAlias) => {
    return makeRequest(middlewareUrl + "/merchants/update/tid/alias", "POST", {
        mid: mid,
        tid: tid,
        terminalAlias: terminalAlias
    }, {}, "UPDATE_MERCHANT_TID_ALIAS")
};

export const updatePostCode = (mid, postCode) => {
    return makeRequest(middlewareUrl + "/merchants/update/portal/post/code", "POST", {
        mid: mid,
        portalPostCode: postCode
    }, {}, "UPDATE_MERCHANT_POST_CODE")
};

export const getPosStatusCodes = (rowsPerPage, afm, mid) => {
    let headers = {'x-afm': afm};
    return makeRequest(middlewareUrl + "/merchants/get/pos/status/codes", "GET", {
        rowsPerPage: rowsPerPage,
        mid: mid
    }, headers, "GET_POS_STATUS_CODES")
};

export const getBusinessExcel = (rowsPerPage, afm, mid, tid, onlyActive, locale) => {
    let headers = {'x-afm': afm};
    return makeRequest(middlewareUrl + "/business/excel", "GET", {
        rowsPerPage: rowsPerPage,
        mid: mid,
        tid: tid,
        onlyActive: onlyActive,
        page: 1,
        by: null,
        asc: null,
        locale: locale
    }, headers, "GET_BUSINESS_EXCEL", "blob")
}

export const getKpiExcel = (kpiContent, locale) => {
    return makeRequest(middlewareUrl + "/dashboard/kpi/excel", "GET", {
        company: kpiContent.company,
        mid: kpiContent.mid,
        date: kpiContent.date,
        amount: kpiContent.amount,
        count: kpiContent.count,
        min: kpiContent.min,
        max: kpiContent.max,
        average: kpiContent.average,
        locale: locale
    }, {}, "GET_KPI_EXCEL", "blob")
}

export const getPosStatusCodesExcel = (rowsPerPage, afm, mid, locale) => {
    let headers = {'x-afm': afm};
    return makeRequest(middlewareUrl + "/merchants/get/pos/status/codes/excel", "GET", {
        rowsPerPage: rowsPerPage,
        mid: mid,
        locale: locale
    }, headers, "GET_POS_STATUS_CODES_EXCEL", "blob")
}

export const getEcomAvailabilityByAfm = (afm) => {
    let headers = {'x-afm': afm};
    return makeRequest(middlewareUrl + "/merchants/ecom/by/afm", "GET",{},
        headers, "GET_ECOM_AVAILABILITY_BY_AFM")
}

export const getTransactionTypesByAfm = (afm) => {
    let headers = {'x-afm': afm};
    return makeRequest(middlewareUrl + "/transaction/types/by/afm", "GET",{},
        headers, "GET_TRANSACTION_TYPES_BY_AFM")
}

export const getCardlinkOneCardsCommissionsByMid = (mid) => {
    return makeRequest(middlewareUrl + "/merchants/cardlink/one/card/commissions", "GET", {"mid": mid}, {},
        "GET_MERCHANTS_CARDS_COMMISSIONS")
}

export const findUserAssociations = () => {
    return makeRequest(middlewareUrl + "/merchants/user/associations", "GET", {
    }, {}, "VALIDATE_CARDLINK_ONE_MERCHANT")
};