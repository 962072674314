import React from "react";
import store from "../../store";
import {updateTerminalAlias} from "../../providers/merchants";

const Translate = require('react-redux-i18n').Translate

export default class PopoverTerminalAlias extends React.Component {
    constructor(props) {
        super(props)
        this.state = {
            mid: props.mid,
            tid: props.tid,
            terminalAlias: props.terminalAlias
        }
        this.updateTerminalAlias = this.updateTerminalAlias.bind(this)
    }

    updateTerminalAlias(mid, tid, terminalAlias) {
        store.dispatch(updateTerminalAlias(mid, tid, terminalAlias)).then((response) => {
                this.props.handleTerminalAliasChange(tid, response.value.data.terminalAlias)
                this.props.updateTerminalAllias(this.state.mid, this.state.tid, response.value.data.terminalAlias)
            },
            (error) => {
            }
        )
    }

    render() {
        return (
            <div className="popover__business-modal">
                <form onSubmit={(e) => {
                    e.preventDefault();
                    this.updateTerminalAlias(this.state.mid, this.state.tid, this.state.terminalAlias)
                    this.props.overlayRef.hide();
                }}>
                    <div className="business-column">
                        <Translate value="pages.business.popover.terminalAlias" className="editable-popover-label"/>
                        <div className="business-row">
                            <input type="text" id="tidAlias" name="tidAlias"
                                   className="simple-input"
                                   onChange={(event) => {
                                       this.setState({
                                           terminalAlias: event.target.value
                                       })
                                   }}
                                   value={this.state.terminalAlias}
                                   placeholder={this.state.terminalAlias}
                                   title={this.state.terminalAlias}/>
                            <button className="icon-icon-check" type="submit"></button>
                            <button className="icon-x-icon" type="button" onClick={() => this.props.overlayRef.hide()}></button>
                        </div>
                    </div>
                </form>
            </div>
        )
    }
}