import React from 'react';
import {DebitNotesOptions, Options} from "./DonutChartOptions";
import AmCharts from "@amcharts/amcharts3-react";
import bank_donut from "../../../../assets/img/bank_donut.png";
import card_donut from "../../../../assets/img/card_donut.png";
import connect from "react-redux/es/connect/connect";
import {withRouter} from "react-router-dom";


const stateMap = (store) => {
    return {
        i18n: store.i18n
    };
};

class DonutChart extends React.Component {
    constructor(props) {
        super(props)

        this.state = {
            locale: this.props.i18n.locale
        }
    }


    componentWillMount() {
        this.setState({
            locale: this.props.i18n.locale
        })

    }

    componentWillReceiveProps(nextProps) {
        if (this.props.i18n.locale !== nextProps.i18n.locale) {
            this.setState({
                locale: nextProps.i18n.locale
            })
        }
    }

    render() {
        let isBank = this.props.type === 'bank';
        let isDebitNotes = this.props.isDebitNotes;
        let imgUrl = !isDebitNotes ? (isBank ? bank_donut : card_donut) : null;
        let styles = {
            width: "220px",
            height: "160px",
            backgroundImage: !isDebitNotes ? "url(" + imgUrl + ")": null,
            marginTop:  !isDebitNotes ? "30px" : "-5px",
            backgroundRepeat: 'no-repeat',
            backgroundSize: this.props.type === 'bank' ? "42px 39px" : "48px 31px",
            backgroundPosition: 'center ' + (this.props.type === 'bank' ? 'center' : '53%'),
            position: 'relative',
            left: isBank ? "50%" : "initial",
            marginLeft: isBank ? "-110px" : "initial",
            locale: this.props.i18n.locale
        };

        return (
            <div>
                {!isDebitNotes ?
                    <div id={"chart-export-btn__" + this.props.type}>
                    </div>
                :null}
                <AmCharts.React
                    key={this.props.type + 'TotalsKey'}
                    style={styles}
                    options={this.props.isDebitNotes ?
                        DebitNotesOptions(this.props.data, this.props.type, this.props.chartTitle, this.props.filename,
                            this.state.locale)
                        : Options(this.props.data, this.props.type, this.props.chartTitle, this.props.filename,
                        this.state.locale)}/>
            </div>
        )
    }
}

export default withRouter(connect(stateMap)(DonutChart));
