import React from "react";
import store from "../../../store";
import {setRegistrationStep} from "../../../actions/registerAction";
import {withRouter} from "react-router-dom";
import {connect} from "react-redux";
import {merchantRegistrationSave} from "../../../providers/rest";
import Loader from "../../common/Loader";
import {ShowHelp} from "../../common/ShowHelp";
import {getLatestTermsAndConditions} from "../../../providers/termsAndConditions";
import Logo from "../../common/Logo";

const stateMap = (store) => {
    return {
        i18n: store.i18n,
        registrationReducer: store.registrationReducer
    };
};

const Translate = require('react-redux-i18n').Translate;
const I18n = require('react-redux-i18n').I18n;

class MerchantRegistrationStep5 extends React.Component {
    constructor(props) {
        super(props)
        this.state = {
            disabled: true,
            errorMessage: '',
            acceptCheckBox1: props.acceptCheckbox1,
            acceptCheckBox2: props.acceptCheckbox2,
            emailPlaceholder: I18n.t('pages.register.emailPlaceholder'),
            lastNamePlaceholder: I18n.t('pages.register.lastNamePlaceholder'),
            firstNamePlaceholder: I18n.t('pages.register.firstNamePlaceholder'),
        }
        this.handleChange = this.handleChange.bind(this)
        this.saveRegistration = this.saveRegistration.bind(this)
    }

    handleChange(event, cbox) {
        this.props.handleChange(event, cbox)
    }

    componentWillReceiveProps(nextProps) {
        if (this.props.acceptCheckbox1 !== nextProps.acceptCheckbox1) {
            this.setState({
                acceptCheckbox1: nextProps.acceptCheckbox1
            })
        }
        if (this.props.acceptCheckbox2 !== nextProps.acceptCheckbox2) {
            this.setState({
                acceptCheckbox2: nextProps.acceptCheckbox2
            })
        }
    }

    saveRegistration() {
        let data = {
            ...this.props.registrationReducer,
            mid: this.props.registrationReducer.mid.filter(Boolean).join()
        }
        store.dispatch(merchantRegistrationSave(data, this.props.i18n.locale)).then((response) => {
                this.props.history.push({
                    pathname: '/submitted',
                    state: {successfulMerchantRegistration: true}
                })
            }, () => {
                this.setState({
                    errorMessage: I18n.t('pages.register.inputError')
                })
            }
        )
    }

    render() {
        const savingMerchantInProgress = this.props.registrationReducer &&
            this.props.registrationReducer.saveMerchantRegistration &&
            this.props.registrationReducer.saveMerchantRegistration.fetching
        return (
            <div className="register-merchant-step1__content">
                <div className="register-merchant-step1__left">
                    <p className="register-merchant-step1__sub-header">
                        <Translate value="pages.register.registerMerchant"/>
                    </p>
                </div>
                <div className="register-merchant-step1__login-with-forgot">
                    <div className="register-merchant-step1__login-box">
                        <Logo isMain={false} isHeader={false} imgClassName="login"/>
                        <form onSubmit={(e) => {
                            e.preventDefault();
                            if (this.props.acceptCheckbox1 && this.props.acceptCheckbox2) {
                                this.saveRegistration()
                            } else {
                                this.setState({
                                    errorMessage: I18n.t('pages.register.acceptTerms')
                                })
                            }
                        }}>
                            <h2 className="register-merchant-title">
                                <Translate value="pages.register.title5"/>
                            </h2>
                            <div className="cbox-registration__container">
                            <a href='javascript:void(0)' onClick={(e) => {
                                    if (e.target && (e.target.type !== 'checkbox')) {
                                        e.preventDefault();
                                        store.dispatch(getLatestTermsAndConditions(this.props.i18n.locale)).then(
                                            () => {
                                                this.props.showTermsAndConditions(true);
                                            },
                                            (error) => {
                                            });
                                        this.setState({
                                            disabled: false
                                        })
                                    }
                                }}>
                            <span className="cbox-registration__accept-terms">
                                <input disabled={this.state.disabled}
                                       type="checkbox"
                                       id="acceptCheckbox1"
                                       checked={this.props.acceptCheckbox1}
                                       onChange={
                                           (e) => {
                                                this.handleChange(e, "acceptCheckbox1")
                                            }
                                       }/>
                                <label htmlFor="acceptCheckbox1">
                                </label>
                                <Translate className="cbox-registration__text" value={"pages.register.approveTermsPart1"}/>

                            <Translate className="cbox-registration__text cbox-registration__text--blue" value={"pages.register.approveTermsPart2"} dangerousHTML/>
                            <Translate className="cbox-registration__text" value={"pages.register.approveTermsPart3"}/>
                            </span>
                            <span className="cbox-registration__confirm-personal-info">
                                <input disabled={this.state.disabled}
                                       type="checkbox"
                                       id="acceptCheckbox2"
                                       checked={this.props.acceptCheckbox2}
                                       onChange={
                                           (e) => {
                                               this.handleChange(e, "acceptCheckbox2")
                                           }
                                       }/>
                                <label htmlFor="acceptCheckbox2">
                                </label>
                                 <Translate className="cbox-registration__text" value={"pages.register.approveSecondCheck"} />
                            </span>
                            </a>
                            </div>
                            {this.state.errorMessage !== '' ?
                                <div className="login-area__login-box__wrong-creds">

                                    <div className="generic-error-msg">
                                        <span className="icon-icon-attention"/>
                                        <div>{this.state.errorMessage}</div>
                                    </div>
                                </div>: null
                            }
                            <div className="register-merchant-step1__login-button-area__wrapper">
                                <button className="register-merchant-step1__login-button register__button"
                                    onClick={(e) => {
                                    store.dispatch(setRegistrationStep({
                                        step: this.props.step - 1
                                    }))
                                }}>
                                    <Translate value="pages.register.backButton"/>
                                </button>
                                <button type="submit"
                                        className="register-merchant-step1__login-button register__button"
                                        disabled={savingMerchantInProgress || !(this.state.acceptCheckbox1 && this.state.acceptCheckbox2)}>
                                    {savingMerchantInProgress ?
                                        <Loader bouncing={false} color={'white'} message=''
                                                style={{marginTop: 0}} bounceClass='smaller-bounces'/> :
                                        <Translate value="pages.register.registerButton"/>
                                    }
                                </button>
                            </div>
                            <ShowHelp/>
                        </form>
                    </div>
                </div>
            </div>
        )
    }
}

export default withRouter(connect(stateMap)(MerchantRegistrationStep5));