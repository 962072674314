import React from 'react';
import connect from "react-redux/es/connect/connect";
import {withRouter} from "react-router-dom";
import {formatAmount} from "../../lib/utils";

const stateMap = (store) => {
    return {
        i18n: store.i18n
    };
};

class NumberFormatter extends React.Component {
    constructor(props) {
        super(props)

        this.state = {
            number: this.props.number ? this.props.number: 0,
            digits: this.props.digits !== undefined ? this.props.digits: 2,
            locale: this.props.i18n.locale,
            symbol: this.props.symbol ? this.props.symbol : "",
            symbolAsSuffix: this.props.symbolAsSuffix ? this.props.symbolAsSuffix : false
        }
    }

    componentWillReceiveProps(nextProps) {
        if (this.props.i18n.locale !== nextProps.i18n.locale) {
            this.setState({
                locale: nextProps.i18n.locale
            })
        }
        if (this.props.number !== nextProps.number) {
            this.setState({
                number: nextProps.number ? nextProps.number: 0
            })
        }
    }


    render() {
        return(
            <span>
                {(!this.state.symbolAsSuffix ? (this.state.symbol + " "): "")  +
                formatAmount(this.state.locale, this.state.number, this.state.digits) +
                (this.state.symbolAsSuffix ? (" " + this.state.symbol): "")
                }
            </span>
        )
    }
}

export default withRouter(connect(stateMap)(NumberFormatter));