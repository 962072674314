export default function termsAndConditionsReducer(state = {
    termsAndConditionsAccepted: null,
    showCloseButton: null,
    latestTermsAndConditions: null
}, action) {
    switch (action.type) {
        case "TERMS_AND_CONDITIONS": {
            return {
                ...state,
                termsAndConditionsAccepted: action.termsAndConditionsAccepted,
                showCloseButton: action.showCloseButton
            }
        }
        case "GET_LATEST_TERMS_AND_CONDITIONS_FULFILLED": {
            return {
                ...state,
                latestTermsAndConditions: action.payload.data.payload
            }
        }
        default : {
            return state
        }
    }
}