import React from "react";
import {dangerousHtmlDiv} from "../../lib/utils";
import connect from "react-redux/es/connect/connect";
import {Panel} from "react-bootstrap";

const allHtmlEntities = require('html-entities').AllHtmlEntities;
const entities = new allHtmlEntities();

const stateMap = (store) => {
    return {
        i18n: store.i18n
    };
};

class Faq extends React.Component {

    constructor(props) {
        super(props)

        this.state = {
            openPanel: false,
            topFaqAnswer: '',
            topFaqQuestion: ''
        }

        this.handleButtonClick = this.handleButtonClick.bind(this);
        this.checkIfExpanded = this.checkIfExpanded.bind(this);
        this.faqLocalizedResponse = this.faqLocalizedResponse.bind(this);
    }

    componentDidMount() {
        this.faqLocalizedResponse()
    }

    componentWillReceiveProps(nextProps) {
        if (nextProps.i18n.locale !== this.props.i18n.locale) {
            this.setState({
                locale: nextProps.i18n.locale
            }, () => {
                this.faqLocalizedResponse()
            })
        }
    }

    handleButtonClick() {
        this.setState({
            openPanel: !this.state.openPanel
        })
        this.props.handlePickedPanel(this.props.topFaq.faqId)
    }

    checkIfExpanded() {
        if (this.props.pickedFaqId === this.props.topFaq.faqId && this.state.openPanel) {
            return true
        } else if (this.props.pickedFaqId !== this.props.topFaq.faqId && this.state.openPanel)
            this.setState({
                openPanel: false
            })
        return false
    }

    faqLocalizedResponse() {
        const isLocaleEl = this.props.i18n.locale === 'el'
        this.setState({
            topFaqAnswer: entities.decode(isLocaleEl ? this.props.topFaq.answer : this.props.topFaq.answerEn),
            topFaqQuestion: entities.decode(isLocaleEl ? this.props.topFaq.question : this.props.topFaq.questionEn)
        })
    }

    render() {
        return (
            <div className="faq__element">
                <Panel id={"collapsible-panel-faq-" + this.props.topFaq.faqId}
                       expanded={this.checkIfExpanded()}
                       onToggle={() => {
                       }}>
                    <Panel.Heading>
                        <Panel.Toggle componentClass="h6" onClick={this.handleButtonClick}>
                            <div className="faq__title">
                                {this.state.topFaqQuestion}
                            </div>
                            <i className="icon-plus-accordion"></i>
                            <i className="icon-minus-accordion"></i>
                        </Panel.Toggle>
                    </Panel.Heading>
                    <Panel.Collapse>
                        <Panel.Body>
                            {dangerousHtmlDiv(this.state.topFaqAnswer)}
                        </Panel.Body>
                    </Panel.Collapse>
                </Panel>
            </div>
        )

    }
}

export default connect(stateMap)(Faq);