import React from 'react';
import moment from "moment";
import DonutChart from "./donutChart/DonutChart";
import BootstrapTable from "react-bootstrap-table-next";
import store from "../../store";
import {bankTotals} from "../../providers/charts";
import ChartDropdown from "./ChartDropdown";
import {setChartDates} from "../../lib/utils";
import NoChartData from "../common/NoChartData";
import Loader from "../common/Loader";
import {withRouter} from "react-router-dom";
import connect from "react-redux/es/connect/connect";
import HotSpot from "../common/HotSpot";
import NumberFormatter from "../common/NumberFormatter";

const I18n = require('react-redux-i18n').I18n
const Translate = require('react-redux-i18n').Translate;

const stateMap = (store) => {
    return {
        chartReducer: store.chartReducer,
        userReducer: store.userReducer,
        i18n: store.i18n,
    };
};


const donutChart = {
    headerFormatter: (column, index, {sortElement, filterElement}) => {
        let str = column.text;
        let firstStr = str.substr(0, str.indexOf(' '));
        let scndStr = str.substr(str.indexOf(' ') + 1);
        return (
            <div className="donut-chart-tbl-hdr">
                <div className="donut-chart-tbl-hdr--item">{firstStr + " "}</div>
                <div className="donut-chart-tbl-hdr--item">{scndStr}</div>
            </div>

        )
    }
};

class BankTotalsChart extends React.Component {
    constructor(props) {
        super(props)
        this.state = {
            afm: this.props.afm,
            mid: this.props.mid,
            bankTotalsData: null,
            dateFrom: moment(moment().subtract(1, 'days')).subtract(1, 'week').format('YYYY-MM-DD'),
            dateTo: moment().subtract(1, 'days').format('YYYY-MM-DD'),
            cardDropdownTooltip: I18n.t('tooltips.hover.timeDropdown01'),
	        locale: this.props.locale
        }
        this.bankTotals = this.bankTotals.bind(this)
        this.reRenderChart = this.reRenderChart.bind(this)
        this.setNewDates = this.setNewDates.bind(this)
        this.defineBanksColor = this.defineBanksColor.bind(this);
        this.valueFormatter = this.valueFormatter.bind(this);
    }

    componentWillMount() {
        this.bankTotals({
            afm: this.props.afm,
            mid: this.props.mid,
            dateFrom: this.state.dateFrom,
            dateTo: this.state.dateTo,
        })
    }

    componentWillReceiveProps(nextProps) {
        if (this.props.afm !== nextProps.afm || this.props.mid !== nextProps.mid) {
            this.setState({
                afm: nextProps.afm,
                mid: nextProps.mid
            }, () => {
                this.reRenderChart()
            })
        }

        if (nextProps.i18n.locale !== this.props.i18n.locale) {
          this.reRenderChart()
        }
    }

    reRenderChart(){
        this.bankTotals({
            afm: this.state.afm,
            mid: this.state.mid,
            dateFrom: this.state.dateFrom,
            dateTo: this.state.dateTo
        })
    }


    bankTotals(data) {
        store.dispatch(bankTotals(data)).then((response) => {
            let bankTotalsData = []
            response.value.data.forEach((bank, index) => {
                let color = this.defineBanksColor(bank.bank);
                if (bank.bank !== 'SKLAVENITIS') {
                    bankTotalsData.push({
                        title: bank.bank, value: bank.amount, count: bank.count, dotColor: color,
                        uniqueKey: index
                    })
                }
            })
            this.setState({
                bankTotalsData: bankTotalsData
            })
        }, (error) => {
        })
    }

    setNewDates(dateFrom, dateTo) {
        this.setState({
            dateFrom: dateFrom,
            dateTo: dateTo
        })
    }

    defineBanksColor(title) {
        switch (title) {
            case 'NEXI':
                return 'jacksons-purple';
            case 'WORLDLINE':
                return 'grenadier';
            case 'PIRAEUS BANK':
                return 'sunglow';
            case 'NBG':
                return 'pelorous';
            case 'ATTICA BANK':
                return 'blumine';
            case 'SKLAVENITIS':
                return 'carrot-orange';
            default:
                return 'jumbo';
        }
    }

    valueFormatter(cell, row, index) {
        return (
            <NumberFormatter number={cell} digits={2} symbol={"€"} />
        )
    }

    countFormatter(cell, row, index) {
        return (
            <NumberFormatter number={cell} digits={0}/>
        )
    }

    render() {
        const columns = [{
            dataField: 'title',
            text: " ",
        }, {
            dataField: 'count',
            text: I18n.t('pages.reports.tableTitles.number'),
            headerFormatter: donutChart.headerFormatter,
            formatter: this.countFormatter
        }, {
            dataField: 'value',
            text: I18n.t('pages.reports.tableTitles.count'),
            headerFormatter: donutChart.headerFormatter,
            formatter: this.valueFormatter,
            formatExtraData: 1
        }];

        const rowClasses = (row, rowIndex) => {
            let classes = row.dotColor;
            return classes;
        };

        return (
            <div className="reports__bank--chart">
                <div className="date-align-right__wrapper">
                    <h5 className="reports__bank--section_pre-title">
                        <Translate value="pages.reports.titles.components"/>
                    </h5>
                    {this.props.renderDates(this.state.dateFrom, this.state.dateTo)}
                </div>
                <div className="date-align-right__wrapper">
                    <h4 className="reports__bank--section_title">
                        <Translate value="pages.reports.titles.perAcquirer"/>
                    </h4>
                    <div title={this.state.cardDropdownTooltip}>
                        <ChartDropdown
                            id="bank-total-chart-timespan"
                            chartMaps={this.props.chartMaps} data={{
                            afm: this.props.afm,
                            mid: this.state.mid
                        }} reRenderChart={this.bankTotals} setDates={setChartDates}
                            setDatesInParent={this.setNewDates}
                            dropdownBaseValue="pages.reports.chart.dropdown.baseValueWeek"
                        />
                    </div>
                </div>
                {this.props.chartReducer && this.props.chartReducer.bankTotals &&
                this.props.chartReducer.bankTotals.fetched ?
                    <React.Fragment>
                        {this.state.bankTotalsData && this.state.bankTotalsData.length > 0 ?
                            <div>
                                {this.props.userReducer.showHotSpots ?
                                    <div className="hotSpot-element__wrapper">
                                        <HotSpot
                                            hotProp="reportsBanks"
                                            placement="bottom"
                                        />
                                    </div>
                                    : null}
                                <div className="donut-chart donut-chart--banks">
                                    <DonutChart
                                        type="bank"
                                        data={this.state.bankTotalsData}
                                        chartTitle={I18n.t("pages.reports.pieChart.title")}
                                        filename={I18n.t("pages.reports.titles.components") + " " +
                                        I18n.t("pages.reports.titles.perAcquirer") +
                                        "_" + this.state.dateFrom + "_" + this.state.dateTo} />
                                    <BootstrapTable classes="donut-chart-table"
                                                    keyField='uniqueKey'
                                                    data={this.state.bankTotalsData}
                                                    columns={columns}
                                                    rowClasses={rowClasses}/>
                                </div>
                            </div>
                            :
                            <NoChartData/>}
                    </React.Fragment> : null}
                {this.props.chartReducer && this.props.chartReducer.bankTotals &&
                this.props.chartReducer.bankTotals.fetching ?
                    <Loader bouncing={false} color={'bahama-blue'} message=''/> : null
                }
            </div>
        )
    }
}

export default withRouter(connect(stateMap)(BankTotalsChart));