import React from "react";
import {withRouter} from "react-router-dom";
import connect from "react-redux/es/connect/connect";
import store from "../../../store";
import {setRegistrationStep, setRegistrationStep4} from "../../../actions/registerAction";
import NumberFormat from "react-number-format";
import {phoneNumberFormat, toCamelCase} from "../../../lib/utils";
import {merchantRegistrationValidate} from "../../../providers/rest";
import Loader from "../../common/Loader";
import {ShowHelp} from "../../common/ShowHelp";
import Logo from "../../common/Logo";

const stateMap = (store) => {
    return {
        i18n: store.i18n,
        registrationReducer: store.registrationReducer
    };
};

const Translate = require('react-redux-i18n').Translate;
const I18n = require('react-redux-i18n').I18n

class MerchantRegistrationStep4 extends React.Component {
    constructor(props) {
        super(props)
        this.state = {
            numberOfUsers: 2,
            errorMessage: '',
            emailPlaceholder: I18n.t('pages.register.emailPlaceholder'),
            lastNamePlaceholder: I18n.t('pages.register.lastNamePlaceholder'),
            firstNamePlaceholder: I18n.t('pages.register.firstNamePlaceholder'),
            responsibleUser: props.responsibleUser,
            isLegalRepRespUser: props.responsibleUser === 'legalRep',
            superuserLastName: props.superuserLastName ? props.superuserLastName : undefined,
            superuserFirstName: props.superuserFirstName ? props.superuserFirstName : undefined,
            superuserEmail: props.superuserEmail ? props.superuserEmail : undefined,
            superuserPhone: props.superuserPhone ? props.superuserPhone : undefined,
            otherLastName: undefined,
            otherFirstName: undefined,
            otherEmail: undefined,
            otherPhone: undefined

        }
        this.handleChange = this.handleChange.bind(this)
        this.handleRadioChange = this.handleRadioChange.bind(this)
        this.handleFormattedChange = this.handleFormattedChange.bind(this)
        this.defineInputValues = this.defineInputValues.bind(this)
        this.validate = this.validate.bind(this)
    }

    componentDidMount() {
        let props = this.props;
        let isLegalRepRespUser = props.responsibleUser === 'legalRep'
        this.setState({
            superuserLastName: props.superuserLastName && !isLegalRepRespUser ? props.superuserLastName : props.lastName,
            superuserFirstName: props.superuserFirstName && !isLegalRepRespUser ? props.superuserFirstName : props.firstName,
            superuserEmail: props.superuserEmail && !isLegalRepRespUser ? props.superuserEmail : props.email,
            superuserPhone: props.superuserPhone && !isLegalRepRespUser ? props.superuserPhone : props.phone1,
        })
    }

    componentDidUpdate(prevProps: Readonly<P>, prevState: Readonly<S>, snapshot: SS) {
        if (this.props.responsibleUser !== prevProps.responsibleUser) {
            this.setState({
                responsibleUser: this.props.responsibleUser,
                isLegalRepRespUser: this.props.responsibleUser === 'legalRep'
            }, ()=> {
                this.defineInputValues(this.state.responsibleUser);
            });
        }

        if (this.props.superuserLastName !== prevProps.superuserLastName) {
            this.setState({
                superuserLastName: this.props.superuserLastName
            })
        }
        if (this.props.superuserFirstName !== prevProps.superuserFirstName) {
            this.setState({
                superuserFirstName: this.props.superuserFirstName
            })
        }
        if (this.props.superuserEmail !== prevProps.superuserEmail) {
            this.setState({
                superuserEmail: this.props.superuserEmail
            })
        }
        if (this.props.superuserPhone!== prevProps.superuserPhone) {
            this.setState({
                superuserPhone: this.props.superuserPhone
            })
        }
    }

    handleChange(event) {
        this.props.handleChange(event);
        if (!this.state.isLegalRepRespUser) {
            let inputId = "other" + event.target.id.replace("superuser", "");
            this.setState({
                [inputId]: event.target.value
            })
        }
    }

    handleRadioChange(event) {
        this.props.handleRadioChange(event)
    }

    handleFormattedChange(obj, ev) {
        this.props.handleFormattedChange(obj, ev);
        if (!this.state.isLegalRepRespUser) {
            let inputId = "other" + ev.target.id.replace("superuser", "");
            this.setState({
                [inputId]: obj.value
            })
        }
    }

    defineInputValues(type) {
        let arr = ["lastName", "firstName", "email", "phone"];
        let isLegalRep = type === "legalRep";
        arr.forEach((el, idx)=> {
            let elCamel = toCamelCase(el);
            let evt = {};
            let propEl = el === 'phone' ? 'phone1': el;
            let otherStr = 'other' + elCamel;
            evt.target = {};
            evt.target.id = 'superuser' + elCamel;
            evt.target.value = isLegalRep ? this.props[propEl] : (this.state[otherStr] === this.props[propEl] || !this.state[otherStr] ? undefined : this.state[otherStr]);
            this.props.handleChange(evt);
        });
    }

    validate(data) {
        store.dispatch(merchantRegistrationValidate(data)).then((response) => {
                data.step++
                store.dispatch(setRegistrationStep4(data))
            }, () => {
                this.setState({
                    errorMessage: I18n.t('pages.register.inputError')
                })
            }
        )
    }

    render() {
        const validationInProgress = this.props.registrationReducer &&
            this.props.registrationReducer.validateMerchantRegistration &&
            this.props.registrationReducer.validateMerchantRegistration.fetching;
        return (
            <div className="register-merchant-step1__content">
                <div className="register-merchant-step1__left">
                    <p className="register-merchant-step1__sub-header">
                        <Translate value="pages.register.registerMerchant"/>
                    </p>
                    <p className="register-merchant-step1__sub-header">
                        <b> <Translate value="pages.register.step4"/> </b>
                    </p>
                    <p className="register-merchant-step1__text register-merchant-step1__text_intro">
                        <Translate value="pages.register.subTitle4"/>
                    </p>
                </div>
                <div className="register-merchant-step1__login-with-forgot">
                    <div className="register-merchant-step1__login-box">
                        <Logo isMain={false} isHeader={false} imgClassName="login"/>
                        <h2 className="register-merchant-title">
                            <Translate value="pages.register.title4"/>
                        </h2>
                        <section className="register-merchant-radius-section">
                            <div className="register-merchant-radius--corp">
                                <input
                                    id="radio1RespUser"
                                    name="responsibleUser"
                                    type="radio"
                                    onChange={this.handleRadioChange}
                                    className="register-radio-btn"
                                    value="other"
                                    checked={this.state.responsibleUser === 'other'} required={true} onClick={(e) => {
                                    this.setState({
                                        responsibleUser: 'other'
                                    })
                                }}/>
                                <label htmlFor="radio1RespUser">
                                    <Translate value="pages.register.responsibleUserOther"/>
                                </label>
                            </div>
                            <div className="register-merchant-radius--corp">
                                <input
                                    id="radio2RespUser"
                                    name="responsibleUser"
                                    type="radio"
                                    onChange={this.handleRadioChange}
                                    className="register-radio-btn"
                                    value="legalRep"
                                    checked={this.state.responsibleUser === 'legalRep'} required={true} onClick={(e) => {
                                    this.setState({
                                        responsibleUser: 'legalRep'
                                    })
                                }}/>
                                <label htmlFor="radio2RespUser">
                                    <Translate value="pages.register.responsibleUserLegalRep"/>
                                </label>
                            </div>
                        </section>
                        <form onSubmit={(e) => {
                            e.preventDefault();
                            let data = {
                                ...this.props.registrationReducer,
                                superuserLastName: this.props.superuserLastName,
                                superuserFirstName: this.props.superuserFirstName,
                                superuserEmail: this.props.superuserEmail,
                                superuserPhone: this.props.superuserPhone,
                                mid: this.props.registrationReducer.mid.filter(Boolean).join()
                            }
                            this.validate(data)
                        }}
                              className={this.state.responsibleUser === 'legalRep' || !this.state.responsibleUser ? "registration-disabled" : ""}
                        >
                            <input type="text" required={true}
                                   disabled={this.state.isLegalRepRespUser || !this.state.responsibleUser }
                                   onChange={this.handleChange}
                                   placeholder={this.state.lastNamePlaceholder} size="40"
                                   id="superuserLastName" name="superuserLastName"
                                   value={this.state.superuserLastName && this.state.responsibleUser ? this.state.superuserLastName : ''}/>
                            <input type="text" required={true}
                                   disabled={this.state.isLegalRepRespUser || !this.state.responsibleUser }
                                   onChange={this.handleChange}
                                   placeholder={this.state.firstNamePlaceholder} size="40"
                                   id="superuserFirstName" name="superuserFirstName"
                                   value={this.state.superuserFirstName && this.state.responsibleUser ? this.state.superuserFirstName : ''}/>
                            <input type="email" required={true}
                                   disabled={this.state.isLegalRepRespUser || !this.state.responsibleUser}
                                   onChange={this.handleChange}
                                   placeholder={this.state.emailPlaceholder} size="40"
                                   id="superuserEmail" name="superuserEmail"
                                   value={this.state.superuserEmail && this.state.responsibleUser ? this.state.superuserEmail : ''}/>
                            <NumberFormat
                                disabled={this.state.isLegalRepRespUser || !this.state.responsibleUser}
                                type="tel"
                                id="superuserPhone"
                                format={phoneNumberFormat}
                                className="simple-input"
                                value={this.state.superuserPhone && this.state.responsibleUser ? this.state.superuserPhone : ''}
                                placeholder="69"
                                onValueChange={this.handleFormattedChange}
                                decimalScale={0} required={true}/>
                            {this.state.errorMessage !== '' ?
                            <div className="login-area__login-box__wrong-creds">
                                    <div className="generic-error-msg">
                                        <span className="icon-icon-attention"/>
                                        <div>{this.state.errorMessage}</div>
                                    </div>
                            </div>: null
                            }
                            <div className="register-merchant-step1__login-button-area__wrapper">
                                <button className="register-merchant-step1__login-button register__button"
                                        onClick={(e) => {
                                            store.dispatch(setRegistrationStep({
                                                step: this.props.step - 1
                                            }))
                                }}>
                                    <Translate value="pages.register.backButton"/>
                                </button>
                                <button type="submit" className="register-merchant-step1__login-button register__button"
                                        disabled={validationInProgress || !this.state.responsibleUser}>
                                    {validationInProgress ?
                                        <Loader bouncing={false} color={'white'} message=''
                                                style={{marginTop: 0}} bounceClass='smaller-bounces'/> :
                                        <Translate value="pages.register.submitButton"/>
                                    }
                                </button>
                            </div>
                            <ShowHelp/>
                        </form>
                    </div>
                </div>
            </div>
        )
    }
}

export default withRouter(connect(stateMap)(MerchantRegistrationStep4));