import React from 'react';
import store from "../../store";
import {getMerchantTree, getMidsTidsWithAliases} from "../../providers/merchants";
import {getMap} from "../../providers/rest";
import connect from "react-redux/es/connect/connect";
import AfmData from "../company/AfmData";
import Areas from "../company/Areas";
import RightColumn from "../mainFrame/RightColumn";
import ExportCompany from "../company/ExportCompany";
import Loader from "../common/Loader";
import {toggleSearchTransactionsPopover} from "../../actions/userActions";
import EmptyStateShape from "../common/EmptyStateShape";
import localforage from "localforage";


const stateMap = (store) => {
    return {
        i18n: store.i18n,
        userReducer: store.userReducer,
        merchantReducer: store.merchantReducer,
    };
};

class Business extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            afm: props.merchantReducer.afm,
            exportAfm: null,
            merchantTree: null,
            businessMaps: null,
            isToggleOn: false
        };
        this.getMerchantTree = this.getMerchantTree.bind(this);
        this.getMap = this.getMap.bind(this)
        this.togglePopover = this.togglePopover.bind(this)
        this.setAliasesArr = this.setAliasesArr.bind(this)
        this.updateTerminalAllias = this.updateTerminalAllias.bind(this)
    }

    componentWillMount() {
        this.getMerchantTree(this.state.afm);
        this.setAliasesArr(this.state.afm);
        this.getMap('businessMaps');
        store.dispatch(toggleSearchTransactionsPopover(false));
    }

    componentWillReceiveProps(nextProps) {
        if (nextProps.merchantReducer.afm !== this.props.merchantReducer.afm) {
            this.getMerchantTree(nextProps.merchantReducer.afm, this.state.mid);
            this.setAliasesArr(nextProps.merchantReducer.afm)
        }
    }

    componentDidMount() {
    }

    getMap(mapName) {
        store.dispatch(getMap(mapName)).then((response) => {
                this.setState({
                    businessMaps: response.value.data
                })
            },
            (error) => {
            }
        )
    }

    getMerchantTree(afm) {
        this.setState({
            afm: afm
        }, ()=> {
            let key = afm ? afm + '-merchant_tree' : 'all-merchant_tree';
            let _this = this;
            localforage.getItem(key).then(function(value) {
                if (value && value.length > 0) {
                    _this.setState({
                        merchantTree: value
                    });
                } else {
                    store.dispatch(getMerchantTree(afm, null)).then((response) => {
                        localforage.setItem(key, response.value.data.payload).then(function(value) {
                            _this.setState({
                                merchantTree: value
                            });
                        });
                    }, (err)=> {
                        console.log(err);
                        _this.setState({
                            merchantTree: []
                        })
                    });
                }
            });
        });
    }

    setAliasesArr(afmParam, updateAlias = false) {
        let afm = afmParam ? afmParam : this.state.afm;
        store.dispatch(getMidsTidsWithAliases(afm, undefined)).then((response) => {
            if (updateAlias) {
                let _this = this;
                localforage.getItem(afm + '-merchant_tree').then(function (value) {
                    if (value && value.length > 0) {
                        let merchantTree = value[0]
                        response.value.data.forEach(mid => {
                            for (const [k, v] of Object.entries(mid)) {
                                if (merchantTree && merchantTree.midData && merchantTree.midData.length > 0) {
                                    let currentMid = merchantTree.midData.find(mid => mid.mid === k)
                                    if (currentMid) {
                                        currentMid.merchantAlias = v
                                        localforage.setItem(afm + '-merchant_tree', value).then(function (value) {
                                            _this.setState({
                                                merchantTree: value
                                            })
                                        })
                                    }
                                }
                            }
                        })
                    }
                })
            }
            this.setState({
                aliasesArr: response.value.data
            })
            }
        )
    }

    updateTerminalAllias(merchantId, terminalId, alias) {
        let afm = this.state.afm
        let _this = this;
        localforage.getItem(afm + '-merchant_tree').then(function (value) {
            if (value && value.length > 0) {
                if (value[0] && value[0].midData && value[0].midData.length > 0) {
                    let currentMid = value[0].midData.find(mid => mid.mid === merchantId)
                    if (currentMid && currentMid.tidData && currentMid.tidData.length > 0) {
                        let currentTid = currentMid.tidData.find(tid => tid.tid === terminalId)
                        if (currentTid) {
                            currentTid.terminalAlias = alias
                            localforage.setItem(afm + '-merchant_tree', value).then(function (value) {
                                _this.setState({
                                    merchantTree: value
                                })
                            })
                        }
                    }
                }
            }
        })
    }

    togglePopover() {
        this.setState({
            isToggleOn: !this.state.isToggleOn
        });
    }

    render() {
        let data = this.state.merchantTree ? this.state.merchantTree[0] : null;
        const afmOptions = [];
        this.props.userReducer.getAssociationsResponse.uniqueAfms.map(afm => {
            afmOptions.push({value: afm, label: afm})
        });
        return (
            this.props.userReducer.getAssociationsResponse.uniqueAfmsWithTitles.length > 0 ?
                <main className="main-page business-page">
                    <div className="main-content">
                        <ExportCompany
                            isToggleOn={this.state.isToggleOn}
                            togglePopover={this.togglePopover}
                            locale={this.props.i18n.locale}
                        />
                        {this.state.merchantTree !== null && this.props.merchantReducer &&
                        this.props.merchantReducer.merchantTree.fetched ?
                            <div>
                                <AfmData
                                    data={data}
                                    locale={this.props.i18n.locale}
                                />
                                {this.state.businessMaps !== null && this.state.businessMaps !== '' && data.midData != null ?
                                    <Areas
                                        data={data.midData}
                                        iban={data.iban}
                                        aliasesArr={this.state.aliasesArr}
                                        setAliasesArr={this.setAliasesArr}
                                        updateTerminalAllias={this.updateTerminalAllias}
                                        areasMap={this.state.businessMaps.areas}
                                        ecrMap={this.state.businessMaps.ecrFunctionality}
                                        locale={this.props.i18n.locale}
                                    /> : null
                                }
                            </div>: null}
                        {this.props.merchantReducer &&
                        this.props.merchantReducer.merchantTree.fetching ?
                            <Loader
                                bouncing={true}
                                color={'cerulean'}
                                loaderStyles={{minHeight: "150px", margin: "60px 0", height: "calc(50% - 140px)"}}
                                message="loader.generic"/> : null}
                    </div>
                    <RightColumn type="promo" />
                </main> :
                <div className="no-pos__wrapper">
                    <EmptyStateShape title="common.noPosInAccount"/>
                </div>
        );
    }
}

export default connect(stateMap)(Business);