import React from 'react';
import 'react/umd/react.development';
import 'amcharts3';
import 'amcharts3/amcharts/plugins/export/export.css';
import 'amcharts3/amcharts/plugins/export/export.min';
import 'amcharts3/amcharts/serial';
import RightColumn from "../mainFrame/RightColumn";
import Dashboard from "../overview/Dashboard";
import connect from "react-redux/es/connect/connect";
import {withRouter} from "react-router-dom";
import MyPos from "../overview/MyPos";
import TotalTransactionsChart from "../charts/TotalTransactionsChart";
import moment from "moment";
import store from "../../store";
import {toggleSearchTransactionsPopover} from "../../actions/userActions";
import {downloadAuthReportFile} from "../../providers/authorizations";
import EmptyStateShape from "../common/EmptyStateShape";
import {downloadReportFile} from "../../providers/reports";

const qs = require('qs')
const fileDownload = require('js-file-download')
const _ = require("underscore");

const stateMap = (store) => {
    return {
        i18n: store.i18n,
        userReducer: store.userReducer,
        merchantReducer: store.merchantReducer
    };
};

class Overview extends React.Component {

    constructor(props) {
        super(props)

        this.state = {
            afm: props.merchantReducer.afm ? props.merchantReducer.afm :
                props.userReducer.getAssociationsResponse.uniqueAfms.toString(),
            mid: props.merchantReducer.midsCommaSeparated ? props.merchantReducer.midsCommaSeparated : undefined,
            afms: props.userReducer.getAssociationsResponse.uniqueAfmsWithTitles,
        }

        this.renderDates = this.renderDates.bind(this)
    }

    renderDates(dateFrom, dateTo, className) {
        return (
            <div className={className ? className : ''}>
                <span>{moment(dateFrom).format('DD/MM/YYYY')}</span>
                {dateTo ?
                    <React.Fragment>
                        <span> - </span>
                        <span>{moment(dateTo).format('DD/MM/YYYY')}</span>
                    </React.Fragment> : null}
            </div>
        )
    }

    componentWillMount() {
        store.dispatch(toggleSearchTransactionsPopover(false));
        let parameters = qs.parse(this.props.location.search, {ignoreQueryPrefix: true})
        parameters = _.isEmpty(parameters) ? qs.parse(this.props.history.location.search, {ignoreQueryPrefix: true}) : parameters
        if (parameters.filename !== undefined && (parameters.email === this.props.userReducer.profile.email
	        || (this.props.userReducer.impersonator !== null && parameters.email === this.props.userReducer.impersonator.email))) {
            if (parameters.originalFilename !== undefined) {
                store.dispatch(downloadReportFile(parameters.filename, parameters.email, parameters.originalFilename, this.props.userReducer.impersonator !== null, parameters.source)).then(response => {
                    fileDownload(response.value.data, response.value.headers.filename)
                })
            } else {
                store.dispatch(downloadAuthReportFile(parameters.filename, parameters.email, this.props.userReducer.impersonator !== null, parameters.source)).then(response => {
                    fileDownload(response.value.data, response.value.headers.filename)
                })
            }
        }
        this.props.history.push('/overview')
    }


    componentWillReceiveProps(nextProps) {
        if (this.props.merchantReducer !== nextProps.merchantReducer) {
            this.setState({
                afm: nextProps.merchantReducer.afm ? nextProps.merchantReducer.afm :
                    nextProps.userReducer.getAssociationsResponse.uniqueAfms.toString(),
                mid: nextProps.merchantReducer.midsCommaSeparated ? nextProps.merchantReducer.midsCommaSeparated : undefined
            })
        }
    }

    componentDidMount() {
    }

    render() {
        return (
            this.props.userReducer.getAssociationsResponse.uniqueAfmsWithTitles.length > 0 ?
                <main className="main-page overview-page" key={"overview"}>
                    <div className="main-content">
                        <Dashboard afm={this.state.afm} mid={this.state.mid}
                                   renderDates={this.renderDates}/>
                        <section className='reports__transactions row'>
                            <TotalTransactionsChart
                                afm={this.state.afm}
                                mid={this.state.mid}
                                afms={this.state.afms}
                                renderDates={this.renderDates}
                            />
                        </section>
                        <MyPos afm={this.state.afm}
                               mid={this.state.mid}/>
                    </div>
                    <RightColumn type="promo" />
                </main> :
                <div className="no-pos__wrapper">
                    <EmptyStateShape title="common.noPosInAccount"/>
                </div>
        )
    }
}


export default withRouter(connect(stateMap)(Overview));