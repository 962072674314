import React from "react";
import store from "../../store";
import {getTopFaqs} from "../../providers/rest";
import Faq from "./Faq";

export default class AllFaq extends React.Component {

    constructor(props) {
        super(props)

        this.state = {
            topFaqs: null,
            pickedFaqId: null
        }

        this.handlePickedPanel = this.handlePickedPanel.bind(this);
    }

    componentDidMount() {
        store.dispatch(getTopFaqs(6)).then(
            (response) => {
                this.setState({
                    topFaqs: response.value.data.payload
                })
            },
            (error) => {
                console.log('error is ', error)
            })
    }

    handlePickedPanel(pickedFaqId) {
        this.setState({
            pickedFaqId: pickedFaqId
        })
    }

    render() {
        return <div>
            {this.state.topFaqs !== null ?
                <div className="faq__element_wrapper">
                    {
                        this.state.topFaqs.map(topFaq => {
                            return <Faq key={topFaq.faqId}
                                        topFaq={topFaq}
                                        handlePickedPanel={this.handlePickedPanel}
                                        pickedFaqId={this.state.pickedFaqId}/>
                        })
                    }
                </div>
                : null}
        </div>
    }
}
