import React from 'react';
import Options from "./StockChartOptions";
import connect from "react-redux/es/connect/connect";
import AmCharts from "@amcharts/amcharts3-react";


const stateMap = (store) => {
    return {
        i18n: store.i18n
    };
};

class StockChart extends React.Component {
    constructor(props) {
        super(props)

        this.state = {
            locale: this.props.i18n.locale
        }
    }

    componentWillMount() {
        this.setState({
            locale: this.props.i18n.locale
        })

    }

    componentWillReceiveProps(nextProps) {
        if (this.props.i18n.locale !== nextProps.i18n.locale) {
            this.setState({
                locale: nextProps.i18n.locale
            })
        }
    }

    render() {
        let showAllTxt = this.props.i18n.locale === 'en' ? "Show all" : "Εμφάνιση όλων";
        let styles = {
            width: "100%",
            height: "300px"
        };
        return (
            <React.Fragment>
                <div id={"chart-export-btn__" + this.props.id}>
                </div>
                <AmCharts.React
                    key={"_stockChart"}
                    style={styles}
                    options={Options(this.props.data, this.props.column, this.props.panelType, this.props.id, showAllTxt, this.props.filename, this.state.locale, this.props.dates)}/>
            </React.Fragment>
        )
    }
}

export default connect(stateMap)(StockChart);
