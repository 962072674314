import React from "react";
import moment from "moment";
import store from "../../store";
import {withRouter} from "react-router-dom";
import connect from "react-redux/es/connect/connect";
import {downloadBankTotalsReport, getBankTotals} from "../../providers/bankTotals";
import Loader from "../common/Loader";
import SearchBankTotals from "../bankTotals/SearchBankTotals";
import BankTotalsTable from "../bankTotals/BankTotalsTable";
import {getMidsAndTidsFilteredByAfmAndMidAndEcom} from "../../providers/merchants";
import EmptyStateShape from "../common/EmptyStateShape";

const I18n = require('react-redux-i18n').I18n
const _ = require("underscore");
const fileDownload = require('js-file-download');

const stateMap = (store) => {
    return {
        merchantReducer: store.merchantReducer,
        userReducer: store.userReducer
    };
};

class BankTotals extends React.Component {
    constructor(props) {
        super(props)
        this.state = {
            locale: I18n._locale,
            merchantId: props.merchantReducer.afm !== '' ? (props.merchantReducer.midsCommaSeparated ?
                props.merchantReducer.midsCommaSeparated : props.userReducer.getAssociationsResponse.uniqueMids.join()) :
                props.userReducer.getAssociationsResponse.uniqueMids.join(),
            selectedMids: null,
            midsOption: props.userReducer.getAssociationsResponse.uniqueMidsWithLocation ? props.userReducer.getAssociationsResponse.uniqueMidsWithLocation : [],
            acquirerId: null,
            dateFrom: moment().subtract(1, 'month').format('DD/MM/YYYY'),
            dateTo: moment().format('DD/MM/YYYY'),
            bankTotalsData: null,
            isAllMids: true,
            isPanelOpen: props.isPanelOpen
        }
        this.getBankTotals = this.getBankTotals.bind(this)
        this.downloadBankTotals = this.downloadBankTotals.bind(this)
        this.setMidsOnDropdownChange = this.setMidsOnDropdownChange.bind(this)
        this.createTables = this.createTables.bind(this)
        this.changeDate = this.changeDate.bind(this)
        this.changeMerchantId = this.changeMerchantId.bind(this)
        this.handleInputChange = this.handleInputChange.bind(this)
        this.resetSelectedMids = this.resetSelectedMids.bind(this)
        this.triggerLoader = this.triggerLoader.bind(this)
    }

    async componentWillMount() {

    }

    componentDidMount() {

    }

    async componentWillReceiveProps(nextProps) {
        if ((this.props.merchantReducer.afm !== nextProps.merchantReducer.afm) && this.state.isPanelOpen) {
            await this.setMidsOnDropdownChange(nextProps)
        }

        if (this.props.isPanelOpen !== nextProps.isPanelOpen) {
            this.setState({
                isPanelOpen: nextProps.isPanelOpen
            }, async () => {
                if (this.state.isPanelOpen) {
                    await this.setMidsOnDropdownChange(nextProps)
                    this.bankTotalsInterval = setInterval(() => {
                        if (this.state.date === moment().format('DD/MM/YYYY')) {
                            this.getBankTotals(this.state.dateFrom, this.state.dateTo, this.state.merchantId, this.state.acquirerId, this.state.locale)
                        }
                    }, 60000)
                } else {
                    clearInterval(this.bankTotalsInterval);
                }
            })
        }
        if (this.state.locale !== I18n._locale) {
            this.setState({locale: I18n._locale})
        }
    }

    componentWillUnmount() {
        clearInterval(this.bankTotalsInterval);
    }

    getBankTotals(dateFrom, dateTo, merchantId, acquirerId, locale) {
        store.dispatch(getBankTotals(dateFrom, dateTo, merchantId, acquirerId, locale)).then((response) => {
            this.setState({
                bankTotalsData: response.value.data
            })
        })
    }

    async setMidsOnDropdownChange(props) {
        let mids = []
        let midsOption = []
        if (props.merchantReducer.afm !== '' && props.merchantReducer.afm !== undefined) {
            await store.dispatch(getMidsAndTidsFilteredByAfmAndMidAndEcom(1, -1, props.merchantReducer.afm, undefined)).then(response => {
                response.value.data.midsWithLocation.forEach(mid => {
                    if (!mids.includes(mid)) {
                        mids.push(mid)
                    }
                })
            })
        } else {
            mids = props.userReducer.getAssociationsResponse.uniqueMidsWithLocation
        }
        mids.forEach(element => {
            midsOption.push({
                value: element.mid,
                label: element.mid + (element.location ? ' - ' + element.location : '')
            })
        })
        this.setState({
            merchantId: mids.map(e => e.mid).join(),
            midsOption: midsOption
        }, () => this.getBankTotals(this.state.dateFrom, this.state.dateTo, this.state.merchantId, this.state.acquirerId, this.state.locale))
    }

    createTables(data) {
        return (
            Object.keys(data).map((key, index) => {
                if (key !== 'allMidSums' || Object.keys(data).length > 2) {
                    let location = data[key][0].location
                    let label = key === 'allMidSums' ? I18n.t('pages.bankTotals.table.titles.allMids') : (I18n.t('pages.bankTotals.table.titles.mid') + key)
                    location = key !== 'allMidSums' ? location : ''
                    return (
                        <div key={key} className={'bank-totals-table-wrapper row'}>
                            <BankTotalsTable
                                data={data[key]}
                                title={label}
                                locationTitle={location}
                            />
                        </div>
                    )
                }
            })
        )
    }

    changeDate(dateFrom, dateTo) {
        this.setState({
            dateFrom: moment(dateFrom).format('DD/MM/YYYY'),
            dateTo: moment(dateTo).format('DD/MM/YYYY')
        }, () => this.getBankTotals(this.state.dateFrom, this.state.dateTo, this.state.merchantId, this.state.acquirerId, this.state.locale))
    }

    changeMerchantId(event) {
        let list = []
        event.map(e => {
            list.push(e.value)
        })
        this.setState({
            merchantId: list.length <= 0 ?
                (this.props.merchantReducer.afm !== '' && this.props.merchantReducer.afm !== undefined ? (this.props.merchantReducer.midsCommaSeparated ?
                    this.props.merchantReducer.midsCommaSeparated : this.props.userReducer.getAssociationsResponse.uniqueMids.join())
                    : this.props.userReducer.getAssociationsResponse.uniqueMids.join()) : list.join(),
            selectedMids: event
        }, () => {
            this.getBankTotals(this.state.dateFrom, this.state.dateTo, this.state.merchantId, this.state.acquirerId, this.state.locale)
     })
    }

    handleInputChange() {
        this.setState({
            isAllMids: !this.state.isAllMids,
        }, () => {
            this.resetSelectedMids()
        })
    }

    resetSelectedMids() {
        this.setState({
            merchantId: this.props.merchantReducer.afm !== '' && this.props.merchantReducer.afm !== undefined ? (this.props.merchantReducer.midsCommaSeparated ?
                this.props.merchantReducer.midsCommaSeparated : this.props.userReducer.getAssociationsResponse.uniqueMids.join())
                : this.props.userReducer.getAssociationsResponse.uniqueMids.join(),
            selectedMids: null
        }, () => this.getBankTotals(this.state.dateFrom, this.state.dateTo, this.state.merchantId, this.state.acquirerId, this.state.locale))
    }

    downloadBankTotals(dateFrom, dateTo, merchantId, acquirerId, email, currentUrl, locale) {
        store.dispatch(downloadBankTotalsReport(dateFrom, dateTo, merchantId, acquirerId, email, currentUrl, locale)).then((response) => {
                this.setState({
                    errorMessage: ''
                })
                if (response.value.status === 204) {
                    this.triggerLoader()
                } else {
                    fileDownload(response.value.data, response.value.headers.filename)
                }
            },
            (error) => {
                this.setState({
                    errorMessage: I18n.t("pages.reports.download.errors.downloadFailed"),
                })
            })
    }

    triggerLoader() {
        this.setState({
            loaderSquircleIsActive: true
        }, () => {
            setTimeout(() => {
                this.setState({
                    loaderSquircleIsActive: false
                });
            }, 5000)
        });
    }

    render() {
        return (
            <div className="bank-totals-spacer">
                <div className="bank-totals-popover-wrapper">
                    <button type="button" className="bank-totals-popover-wrapper__excel-button"
                            title={I18n.t("pages.bankTotals.searchModal.labels.report")}
                            onClick={() => this.downloadBankTotals(
                                this.state.dateFrom, this.state.dateTo,
                                this.state.merchantId, this.state.acquirerId,
                                (this.props.userReducer.impersonator !== null ? this.props.userReducer.impersonator.email : this.props.userReducer.profile.email),
                                window.location.origin,
                                this.state.locale)}
                            disabled={((this.props.merchantReducer.bankTotalsReport.fetching === true ||
                                this.props.merchantReducer.bankTotalsTable.fetching === true) &&
                                this.props.merchantReducer.bankTotalsTable.fetched === false) ||
                            (!this.state.isAllMids && _.isEmpty(this.state.selectedMids)) ||
                            (!this.state.bankTotalsData|| _.isEmpty(this.state.bankTotalsData) || this.state.bankTotalsData === null)}/>
                    <SearchBankTotals
                        merchantId={this.state.merchantId}
                        selectedMids={this.state.selectedMids}
                        midsOption={this.state.midsOption}
                        locale={this.state.locale}
                        isAllMids={this.state.isAllMids}
                        data={this.state.bankTotalsData}
                        downloadBankTotals={this.downloadBankTotals}
                        changeDate={this.changeDate}
                        changeMerchantId={this.changeMerchantId}
                        handleInputChange={this.handleInputChange}
                    />
                </div>
                {this.props.merchantReducer.bankTotalsTable.fetching ?
                    <Loader bouncing={false} color={'bahama-blue'} message=''/> :
                    <div className="bank-totals-wrapper">
                        {
                            this.props.merchantReducer.bankTotalsTable.fetched && this.state.bankTotalsData !== null
                            && !_.isEmpty(this.state.bankTotalsData) ?
                                this.createTables(this.state.bankTotalsData)
                                :
                                <div className="bank-totals-table__empty-state">
                                    <EmptyStateShape title="pages.bankTotals.table.emptyTableMessageTitle" />
                                </div>
                        }
                    </div>
                }
                {this.state.loaderSquircleIsActive ?
                    <Loader bouncing={true}
                            color={'cerulean'}
                            loaderStyles={{minHeight: "100vh", margin: "0"}}
                            message='loader.trxEmail'
                            subMessage='loader.trxSubMessage'
                            style={{top: "115px"}}
                            overlayClass="is-overlay"/> : null
                }
            </div>
        )
    }
}

export default withRouter(connect(stateMap)(BankTotals));