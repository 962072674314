export function setRegistrationData(data) {
    return {
        type: "SET_REGISTRATION_DATA",
        step: data.step,
        lastName: data.lastName,
        firstName: data.firstName,
        email: data.email,
        phone1: data.phone1,
        afm: data.afm,
        merchantNameLatin: data.merchantNameLatin,
        merchantTitleLatin: data.merchantTitleLatin,
        mid: data.mid,
        logoImageBase64: data.logoImageBase64,
        logoFileName: data.logoFileName,
        lastName2: data.lastName2,
        firstName2: data.firstName2,
        email2: data.email2,
        lastName3: data.lastName3,
        firstName3: data.firstName3,
        email3: data.email3,
        lastName4: data.lastName4,
        firstName4: data.firstName4,
        email4: data.email4,
        lastName5: data.lastName5,
        firstName5: data.firstName5,
        email5: data.email5,
        superuserLastName: data.superuserLastName,
        superuserFirstName: data.superuserFirstName,
        superuserEmail: data.superuserEmail,
        superuserPhone: data.superuserPhone,
        acceptCheckbox1: data.acceptCheckbox1,
        acceptCheckbox2: data.acceptCheckbox2,
    }
}

export function setRegistrationStep(data) {
    return {
        type: "SET_REGISTRATION_STEP",
        step: data.step,
    }
}

export function setRegistrationStep1(data) {
    return {
        type: "SET_REGISTRATION_STEP_1",
        step: data.step,
        lastName: data.lastName,
        firstName: data.firstName,
        email: data.email,
        phone1: data.phone1,
    }
}

export function setRegistrationStep2(data) {
    return {
        type: "SET_REGISTRATION_STEP_2",
        step: data.step,
        afm: data.afm,
        merchantNameLatin: data.merchantNameLatin,
        merchantTitleLatin: data.merchantTitleLatin,
        mid: data.mid.split(","),
        logoImageBase64: data.logoImageBase64,
        logoFileName: data.logoFileName,
        corpType: data.corpType
    }
}

export function setRegistrationStep3(data) {
    return {
        type: "SET_REGISTRATION_STEP_3",
        step: data.step,
        lastName2: data.lastName2,
        firstName2: data.firstName2,
        email2: data.email2,
        lastName3: data.lastName3,
        firstName3: data.firstName3,
        email3: data.email3,
        lastName4: data.lastName4,
        firstName4: data.firstName4,
        email4: data.email4,
        lastName5: data.lastName5,
        firstName5: data.firstName5,
        email5: data.email5,
    }
}

export function setRegistrationStep4(data) {
    return {
        type: "SET_REGISTRATION_STEP_4",
        step: data.step,
        superuserLastName: data.superuserLastName,
        superuserFirstName: data.superuserFirstName,
        superuserEmail: data.superuserEmail,
        superuserPhone: data.superuserPhone
    }
}

export function setRegistrationStep5(data) {
    return {
        type: "SET_REGISTRATION_STEP_5",
        step: data.step,
        acceptCheckbox1: data.acceptCheckbox1,
        acceptCheckbox2: data.acceptCheckbox2,
    }
}
