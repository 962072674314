import React from "react";
import LocaleSwitch from "../common/LocaleSwitch";
import LogOut from "../userProcedures/LogOut";
import Logo from "../common/Logo";
import Link from "react-router-dom/es/Link";
import connect from "react-redux/es/connect/connect";
import {loadUserAvatar} from "../../lib/utils";
import store from "../../store";
import {nullifyModalAction, setModalAction} from "../../actions/SideModalActions";
import {withRouter} from "react-router-dom";

const stateMap = (store) => {
    return {
        i18n: store.i18n,
        userReducer: store.userReducer
    };
};

const I18n = require('react-redux-i18n').I18n;

class Header extends React.Component {
    constructor(props) {
        super(props)

        this.state = {
            userAvatar: null,
            avatarTooltip: I18n.t('tooltips.hover.profileAvatar')
        }
    }

    componentDidMount() {
        loadUserAvatar()
        this.props.history.push({hash: this.props.i18n.locale})
    }

    componentWillReceiveProps(nextProps) {
        if (nextProps.i18n !== this.props.i18n) {
            this.setState({
                avatarTooltip: I18n.t('tooltips.hover.profileAvatar')
            })
            this.props.history.push({hash: nextProps.i18n.locale})
        }

        if (nextProps.location.pathname !== this.props.location.pathname) {
            this.props.history.push({hash: this.props.i18n.locale})
        }
    }

    toggleSideModal() {
        store.dispatch(nullifyModalAction())
        store.dispatch(setModalAction(true, 'isProfile'))
    }

    render() {
        let profile = this.props.userReducer.profile
        const userAvatar = this.props.userReducer.userAvatar
        return (
            <header>
                <Logo isMain={true} isHeader={true}/>
                <div className="user">
                    <Link to={this.props.location} className="profile-link" onClick={this.toggleSideModal}>
                        <span>{profile.firstName}</span>
                        <span>{profile.lastName}</span>
                        {!userAvatar ?
                            <div className="user__initials" title={this.state.avatarTooltip}>
                                {profile.firstName.charAt(0) + profile.lastName.charAt(0)}
                            </div> :
                            <span className="user__image" title={this.state.avatarTooltip}>
                                <img src={'data:image/png;base64,' + userAvatar} alt="user avatar"/>
                            </span>
                        }
                    </Link>
                </div>
                <div className="header__right-part">
                    <LocaleSwitch type={"header"}></LocaleSwitch>
                    <LogOut remainingTime={this.props.remainingTime} pending={this.props.userReducer.logoutProcedure.fetching}
                            isImpersonator={this.props.userReducer.impersonator !== null}/>
                </div>
            </header>
        )
    }
}

export default withRouter(connect(stateMap)(Header));