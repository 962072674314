import {makeRequest} from "../api-client";

const middlewareUrl = process.env.MIDDLEWARE_URL

export const login = (username, password, urlBase) => {

    let data = {}
    data['username'] = username
    data['password'] = password
    data['urlBase'] = urlBase
    let headers = {}
    return makeRequest(middlewareUrl + "/login", "POST", data, headers, "LOGIN")
};

export const checkMerchantStatus = (orderNumber, vatnumber) => {
    let data = {}
    data['orderNumber'] = orderNumber
    data['vatnumber'] = vatnumber
    return makeRequest(middlewareUrl + "/check/status", "POST", data, {}, "CHECK_MERCHANT_STATUS")
};

export const getGroups = () => {
    return makeRequest(middlewareUrl + "/groups", "GET", {}, {},
        "GET_USER_GROUPS")
};

export const getToken = () => {
    return makeRequest(middlewareUrl + "/identity", "GET", {}, {},
        "GET_TOKEN")
};

export const startImpersonation = (email, tempToken, impersonatorEmail, groups) => {
    let data = {email: email, tempToken: tempToken, impersonatorEmail: impersonatorEmail, groups: groups}
    return makeRequest(middlewareUrl + "/impersonation/start", "GET", data, {},
        "START_IMPERSONATION")
}

export const getImpersonator = () => {
    return makeRequest(middlewareUrl + "/impersonation/get", "GET", {}, {},
        "GET_IMPERSONATOR")
};

export const stopImpersonation = () => {
    return makeRequest(middlewareUrl + "/impersonation/stop", "POST", {}, {},
        "STOP_IMPERSONATION")
};

export const getProfile = () => {
    return makeRequest(middlewareUrl + "/profile", "GET", null, {}, "GET_PROFILE")
};

export const getFreshDeskUrl = () => {
    return makeRequest(middlewareUrl + "/freshdesk", "GET", {}, {}, "GET_FRESHDESK_URL")
};

export const logout = () => {
    return makeRequest(middlewareUrl + "/logout", "POST", null, {}, "LOGOUT")
};

export const passwordResetInit = (email, urlBase) => {
    let data = {email: email, urlBase: urlBase}
    return makeRequest(middlewareUrl + "/password/reset/init", "POST", data, {},
        "PASSWORD_RESET_INIT")
};

export const checkConfirmationCode = (email, confirmationCode) => {
    let data = {email: email, confirmationCode: confirmationCode}
    return makeRequest(middlewareUrl + "/user/invitation/check/code", "GET", data, {},
        "CHECK_CONFIRMATION_CODE")
};

export const passwordReset = (email, otp, resetPasswordCode, password, phone) => {
    let data = {email: email, otp: otp, resetPasswordCode: resetPasswordCode, password: password}
    if (phone) {
        data['phone'] = phone;
    }
    return makeRequest(middlewareUrl + "/password/reset", "POST", data, {},
        "PASSWORD_RESET")
};

export const setPassword = (confirmationCode, email, password) => {
    let data = {confirmationCode: confirmationCode, email: email, password: password}
    return makeRequest(middlewareUrl + "/set/password", "POST", data, {},
        "SET_PASSWORD")
};

export const setPhone = (confirmationCode, email, phone) => {
    let data = {confirmationCode: confirmationCode, email: email, phone: phone}
    return makeRequest(middlewareUrl + "/set/phone", "POST", data, {},
        "SET_PHONE")
};

export const validateOtpAndCompleteProfile = (confirmationCode, email, otp) => {
    let data = {confirmationCode: confirmationCode, email: email, otp: otp}
    return makeRequest(middlewareUrl + "/rest/user/invitation/confirm/profile", "POST", data, {},
        "VALIDATE_OTP_AND_COMPLETE_PROFILE")
};

export const resendCode = (email, phone) => {
    let data = {email: email, phoneNumber: phone}
    return makeRequest(middlewareUrl + "/otp/request/for/email/to/phone", "POST", data, {},
        "RESEND_CODE")
};

export const getEmailById = (userId) => {
    let data = {userId: userId}
    return makeRequest(middlewareUrl + "/get/email", "GET", data, {},
        "GET_EMAIL_BY_ID")
};

export const getAvatar = () => {
    return makeRequest(middlewareUrl + "/get/avatar", "GET", {}, {},
        "GET_AVATAR")
};

export const updateAvatar = (avatarFileName, avatarImageBase64) => {
    let avatarWithoutBase64 = avatarImageBase64.split(",").pop()
    let data = {avatarFileName: avatarFileName, avatarImageBase64: avatarWithoutBase64}
    return makeRequest(middlewareUrl + "/update/avatar", "POST", data, {},
        "UPDATE_AVATAR")
};

export const deleteAvatar = () => {
    return makeRequest(middlewareUrl + "/delete/avatar", "POST", {}, {},
        "DELETE_AVATAR")
};

export const nameChange = (firstName, lastName) => {
    let data = {firstName: firstName, lastName: lastName}
    return makeRequest(middlewareUrl + "/name/change", "POST", data, {},
        "NAME_CHANGE")
};

export const emailChange = (newEmail, password, urlBase) => {
    let data = {newEmail: newEmail, password: password, urlBase: urlBase}
    return makeRequest(middlewareUrl + "/email/change", "POST", data, {},
        "EMAIL_CHANGE")
};

export const confirmEmailChange = (confirmationCode) => {
    let data = {confirmationCode: confirmationCode}
    return makeRequest(middlewareUrl + "/email/change/confirm", "GET", data, {},
        "EMAIL_CHANGE_CONFIRM")
};

export const passwordChange = (oldPassword, password) => {
    let data = {oldPassword: oldPassword, password: password}
    return makeRequest(middlewareUrl + "/password/change", "POST", data, {},
        "PASSWORD_CHANGE")
};

export const phoneChange = (phone, password) => {
    let data = {phone: phone, password: password}
    return makeRequest(middlewareUrl + "/phone/change", "POST", data, {},
        "PHONE_CHANGE")
};

export const getAssociatedUsers = () => {
    return makeRequest(middlewareUrl + "/users/associated", "GET", {}, {},
        "GET_ASSOCIATED_USERS")
};

export const updateAssociatedStatus = (userId, status) => {
    let data = {userId: userId, status: status}
    return makeRequest(middlewareUrl + "/users/associated/status", "POST", data, {},
        "UPDATE_ASSOCIATED_USERS")
};

export const changeDefaultPageOverview = (defaultPageOverview) => {
    let data = {defaultPageOverview: defaultPageOverview}
    return makeRequest(middlewareUrl + "/user/default/page/overview/change", "POST", data, {},
        "CHANGE_DEFAULT_PAGE_OVERVIEW")
};

export const changeInitialLogin = (initialLogin) => {
    let data = {initialLogin: initialLogin}
    return makeRequest(middlewareUrl + "/user/initial/login/change", "POST", data, {},
        "CHANGE_INITIAL_LOGIN")
};

export const emailSupport = (subject, mainContent) => {
    let data = {subject: subject, mainContent: mainContent};
    return makeRequest(middlewareUrl + "/email/support", "POST", data, {},
        "POST_SUPPORT_EMAIL")
};

export const downloadUserManagementReport = (locale) => {
    return makeRequest(middlewareUrl + "/users/excel", "GET", {
            locale: locale
        }, {},
        "DOWNLOAD_USER_MANAGEMENT_REPORT", 'blob')
};