import React from "react";
import store from "../../store";
import {getPosStatusCodes, getPosStatusCodesExcel} from "../../providers/merchants";
import connect from "react-redux/es/connect/connect";
import Loader from "../common/Loader";
import {formatAmount} from "../../lib/utils";

const Translate = require('react-redux-i18n').Translate;
const fileDownload = require('js-file-download');

const stateMap = (store) => {
    return {
        i18n: store.i18n,
        merchantReducer: store.merchantReducer,
        chartReducer: store.chartReducer
    };
};

class MyPos extends React.Component {
    constructor(props) {
        super(props)
        this.state = {
            afm: props.afm.includes(',') ? undefined : props.afm,
            mid: props.mid,
            posStatusCodes: this.getPosStatusCodes((props.afm.includes(',') ? undefined : props.afm), props.mid),
            disableExcel: false
        }

        this.getPosStatusCodes = this.getPosStatusCodes.bind(this);
        this.getPosStatusCodesExcel = this.getPosStatusCodesExcel.bind(this);
    }

    componentWillMount() {
        this.getPosStatusCodes(this.state.afm, this.state.mid)
    }

    componentWillReceiveProps(nextProps) {
        if (this.props.afm !== nextProps.afm || this.props.mid !== nextProps.mid) {
            this.setState({
                afm: nextProps.afm.includes(',') ? undefined : nextProps.afm,
                mid: nextProps.mid,
            }, () => {
                this.getPosStatusCodes(this.state.afm, this.state.mid)
            })
        }
    }

    getPosStatusCodes(afm, mid) {
        store.dispatch(getPosStatusCodes(-1, afm, mid)).then((response) => {
                this.setState({
                    posStatusCodes: response.value.data
                })
            },
            (error) => {
            }
        )
    }

    getPosStatusCodesExcel() {
        this.setState({
            disableExcel: true
        })
        store.dispatch(getPosStatusCodesExcel(-1, this.state.afm, this.state.mid, this.props.i18n.locale)).then((response) => {
            fileDownload(response.value.data, response.value.headers.filename);
            this.setState({
                disableExcel: false
            })
        })
    }

    render() {
        let posStatusCodesFormatted = null;
        this.state.posStatusCodes && Object.keys(this.state.posStatusCodes).forEach(status => {
            let total = this.state.posStatusCodes[status]['total'];
            let formatedTotal =  total ? formatAmount(this.props.i18n.locale, total, 0) : 0;
            posStatusCodesFormatted = posStatusCodesFormatted ? {...posStatusCodesFormatted, [status]: formatedTotal} : {[status]: formatedTotal}
        });
        return (
            <React.Fragment>
                {!this.props.chartReducer.posStatusCodes.fetching ?
                    <div className="my-pos">
                        {this.props.chartReducer.posStatusCodes && this.props.chartReducer.posStatusCodes.fetched ?
                            <React.Fragment>
                                <h4 className="reports__transactions--section_title my-pos__title">
                                    <Translate value="pages.overview.pos.title"/>
                                </h4>
                                <button
                                    className={"my-pos-excel-btn " + (this.state.disableExcel === true ? "disabled" : "")}
                                    onClick={this.getPosStatusCodesExcel}
                                    disabled={this.state.disableExcel === true}>
                                </button>
                                <div className="my-pos__content">
                                    {posStatusCodesFormatted ?
                                        Object.keys(posStatusCodesFormatted).map(status => {
                                            return [
                                                <div key={status + "_key"} className="my-pos__text">
                                                    {posStatusCodesFormatted[status]}
                                                    <div className="my-pos__under-text">
                                                        <Translate value={"pages.overview.pos.functions." + status}/>
                                                    </div>
                                                </div>
                                            ]
                                        }) : null}
                                </div>
                            </React.Fragment> : null}
                        {this.props.chartReducer.posStatusCodes && this.props.chartReducer.posStatusCodes.fetching ?
                            <Loader
                                bouncing={false}
                                color={'midnight-blue'}
                                loaderStyles={{minHeight: "112.5px", margin: "15px 0"}}
                                message=""/> : null}
                    </div>
                    : null}
            </React.Fragment>
        )
    }
}

export default connect(stateMap)(MyPos);