import React from "react";
import {Button, OverlayTrigger, Popover} from "react-bootstrap";
import DataTable from "./DataTable";
import PopoverSearch from "./PopoverSearch";
import GenericModal from "../common/GenericModal";
import Loader from "../common/Loader";
import Tooltip from "../common/Tooltip";
import connect from "react-redux/es/connect/connect";
import HotSpot from "../common/HotSpot";
import EmptyStateShape from "../common/EmptyStateShape";
import store from "../../store";
import {setAuthorizationsReportStatus} from "../../actions/merchantAction";

const Translate = require('react-redux-i18n').Translate
const I18n = require('react-redux-i18n').I18n

const stateMap = (store) => {
    return {
        merchantReducer: store.merchantReducer,
        userReducer: store.userReducer
    };
};

class TransactionPopoverAndTable extends React.Component {
    constructor(props) {
        super(props)
        this.state = {
            excelButtonTooltip: I18n.t("tooltips.hover.trxDownloadBTN"),
        }
    }

    componentDidMount() {
        store.dispatch(setAuthorizationsReportStatus())
    }

    componentWillReceiveProps(nextProps) {
        if (nextProps.i18n !== this.props.i18n) {
            this.setState({
                excelButtonTooltip: I18n.t("tooltips.hover.trxDownloadBTN")
            })
        }
    }


    render() {
        const popoverBottom = (
            <Popover id="transactions-popover-positioned-bottom">
                {this.props.data.nonMapData !== null && this.props.data.allMaps !== null ?
                    <PopoverSearch
                        data={this.props.data}
                        nonMapData={this.props.data.nonMapData}
                        allMaps={this.props.data.allMaps}
                        overlayRef={this.refs.overlayRef}
                        isToggleOn={this.props.isToggleOn}
                        getTransactions={this.props.getTransactions}
                        currentPage={this.props.data.currentPage}
                        rowsPerPage={this.props.data.rowsPerPage}
                        resetPopover={this.props.resetPopover}
                        paymentTypeValue={this.props.data.paymentTypeValue}
                        toggleMoreCriteria={this.props.toggleMoreCriteria}
                        setSelectedMid={this.props.setSelectedMid}
                        transactionTypesByAfm={this.props.transactionTypesByAfm}
                        showDuplicateTransactions={this.props.showDuplicateTransactions}
                    /> : null}
            </Popover>
        );
        const disabledButton = (this.props.authorizationReport.fetching === true &&
            this.props.authorizationReport.fetched === false) ||
			this.props.data.total > 100000 ||
            this.props.data.total === 0 ||
            this.props.merchantReducer.authorizationList.fetching
        return (
            <div>
                <GenericModal
                    showModal={this.props.transactionReportError}
                    showCloseButton={true}
                    onHide={this.props.hideTransactionReportError}
                    modalTitle={<Translate value={"pages.transactions.excelReport.AnErrorOccurred"}/>}
                    modalBody={this.props.transactionReportError ?
                        <Translate value={this.props.transactionReportError}/> : ''}
                    bsSize="small"/>
                <div className="hotSpot-element__wrapper hotSpot-element__wrapper--trans-settling-info">
                    {this.props.userReducer.showHotSpots && this.props.isEcom ?
                        <HotSpot
                            hotProp="transactionSettlingInfo"
                            placement="top"
                        />
                        : null}
                </div>
                <div className="transactions-popover-wrapper">
                    {this.props.userReducer.showHotSpots ?

                            <HotSpot
                                hotProp="transactionExcelButton"
                                placement="bottom"
                            />

                        : null}
                    <div className="transaction-excel-wrapper">
                        <div className="transactions-popover-wrapper__excel-button-tooltip-wrapper">
                            <Translate value={"pages.transactions.tooltips.excelButtonTooltip"}/>
                        </div>
                        <button type="button" className={"transactions-popover-wrapper__excel-button"}
                                title={this.state.excelButtonTooltip}
                                onClick={this.props.downloadTransactionsReport}
                                disabled={disabledButton}>
                            <i className={"icon-saita "}></i>
                        </button>
                        {disabledButton ?
                            <Tooltip placement="left"
                                     tooltipButtonId="download-excel-tltp"
                                     popoverId="download-trans-tltp"
                                     tooltipLabelClass="download-trans-label"
                                     tooltipText="downloadExcelTooltip"
                            /> : null}
                    </div>
                    {(this.props.merchantReducer.getTidsGroupedByMid.fetched
                        && this.props.transactionTypesByAfm !== null)
                    || this.props.searchStartedFromPopoverSearch ?
                        <OverlayTrigger trigger="click"
                                        placement="bottom"
                                        overlay={popoverBottom}
                                        ref="overlayRef"
                                        container={this}>
                            <Button className={"transactions-popover-toggle " +
                            (this.props.isToggleOn ? "triggered " : "not-triggered") +
                            (this.props.data.paymentTypeValue === "installments" ? " move-to-right"
                              : this.props.data.paymentTypeValue === "ecom" && this.props.hasNotEcomTransactions ?
                                " move-to-middle" : "")}
                                    onClick={this.props.togglePopover}>
                                <Translate value={"pages.transactions.searchModal.labels.opened"}/>
                                <Translate value={"pages.transactions.searchModal.labels.closed"}/>
                                <span className="icons">
                                <i className={"icon-ico-magnifier"}> </i>
                                <i className={"icon-x-icon"}> </i>
                            </span>
                            </Button>
                        </OverlayTrigger> : <Loader loaderClass="transactions-popover-toggle__loader"
                                                    bouncing={false} color={'bahama-blue'} message=''/>}
                </div>
                {this.props.tableData !== null && this.props.allMaps !== null &&
                this.props.merchantReducer && this.props.merchantReducer.authorizationList &&
                this.props.merchantReducer.authorizationList.fetched ?
                    <div className="transactions-table-wrapper">
                        {this.props.userReducer.showHotSpots ?
                            <HotSpot
                                hotProp="transactionDetailsInfo"
                                placement="top"
                            />
                            : null}
                        {this.props.data.total > 20 ?
                            <div className="transactions-table-wrapper__trx-tooltip-wrapper">
                                <Translate value={"pages.transactions.tooltips.trxItemDropdown"}/>
                            </div> : null}
                        <DataTable
                            data={this.props.data}
                            handleTblChange={this.props.adjustTable}
                            locale={this.props.locale}
                            isEcom={this.props.isEcom}
                        />
                    </div> :
                    !this.props.merchantReducer.authorizationList.fetching ?
                        <div className="transactions-table__empty-state">
                            <EmptyStateShape title="pages.transactions.table.errorTableMessageTitle" />
                        </div> : null
                }
                {this.props.merchantReducer && this.props.merchantReducer.authorizationList &&
                this.props.merchantReducer.authorizationList.fetching ?
                    <Loader
                        bouncing={true}
                        color={'cerulean'}
                        loaderStyles={{
                            minHeight: "150px",
                            width: "calc(100% - 145px)",
                            margin: "35px auto"
                        }}
                        style={{top: "70px"}}
                        message="loader.generic"/>
                    : null}
            </div>
        )
    }
}

export default connect(stateMap)(TransactionPopoverAndTable);
