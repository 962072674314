import React, {Component} from 'react';
import SideBar from './Sidebar';
import {BrowserRouter, Redirect, Route, Switch} from "react-router-dom";
import Overview from "../mainPages/Overview";
import Business from '../mainPages/Business';
import Reports from '../mainPages/Reports';
import Transactions from '../mainPages/Transactions';
import Login from "../userProcedures/Login";
import Header from "./Header";
import store from "../../store";
import TopMainRenderer from "./TopMainRenderer"
import {nullifyModalAction} from "../../actions/SideModalActions";
import PrivateRoute from "./PrivateRoute"
import ResetPassword from "../userProcedures/ResetPassword";
import RegisterMerchant from "../userProcedures/RegisterMerchant";
import GlobalActions from "./GlobalActions";
import {getImpersonator, getToken, logout, stopImpersonation} from "../../providers/users";
import connect from "react-redux/es/connect/connect";
import {loadPromoScript, switchLocale} from "../../lib/utils";
import {changeFirstTimeOverlay, setUserLoggedInStatus} from "../../actions/userActions";
import {termsAndConditionsActions} from "../../actions/termsAndConditionsActions";
import TermsAndConditions from "../common/TermsAndConditions";
import Footer from "./Footer";
import UserDetails from "../userProcedures/userDetails/UserDetails";
import IntroScreen from "../common/IntroScreen";
import PoliciesModal from "../common/PoliciesModal"
import ErrorBoundary from "../mainPages/ErrorBoundary";
import ExpiredPassword from "../userProcedures/ExpiredPassword";
import InvalidInvitation from "../userProcedures/InvalidInvitation";
import {Modal} from "react-bootstrap";
import Logout from "../userProcedures/LogOut";
import {getLatestTermsAndConditions} from "../../providers/termsAndConditions";
import localforage from "localforage";
import SuccessfulRegistration from "../userProcedures/SuccessfulRegistration";
import StartImpersonation from "../userProcedures/StartImpersonation";

const Translate = require('react-redux-i18n').Translate;
const cardlinkOneUrl = process.env.CARDLINK_ONE_URL;

const stateMap = (store) => {
    return {
        i18n: store.i18n,
        userReducer: store.userReducer,
        termsAndConditionsReducer: store.termsAndConditionsReducer
    };
};

class App extends Component {
    constructor(props) {
        super(props)

        this.state = {
            checkedLoggedInStatus: false,
            latestTermsAndConditions: null,
            termsAndConditionsAccepted: true,
            showCloseButton: false.valueOf(),
            showIntroScreen: undefined,
            blockImpersonatorModal: false,
            blockImpersonatorMsg: ''
        }

        this.idleTimer = null
        this.checkIfUserLoggedIn = this.checkIfUserLoggedIn.bind(this)
        this.showTermsAndConditions = this.showTermsAndConditions.bind(this)
        this.showIntroScreen = this.showIntroScreen.bind(this)
        this.togglePoliciesModal = this.togglePoliciesModal.bind(this)
        this.notLoggedInUserActions = this.notLoggedInUserActions.bind(this)
        this.showBlockImpersonatorModal = this.showBlockImpersonatorModal.bind(this)
    }
    componentDidMount(){
        localforage.config({
            name: 'apolloIndexedDB',
            storeName: 'keyvaluepairs',
            driver: localforage.INDEXEDDB
        });
        loadPromoScript();
    }

    componentWillMount() {
        this.checkIfUserLoggedIn();
        switchLocale(this.props.i18n.locale ? this.props.i18n.locale : 'el');
    }

    componentWillReceiveProps(nextProps) {
        if (nextProps.i18n.locale !== this.props.i18n.locale) {
            store.dispatch(getLatestTermsAndConditions(nextProps.i18n.locale))
        }
    }

    checkIfUserLoggedIn(startImpersonation, history) {
        store.dispatch(getToken()).then(() => {
                if (this.props.userReducer.redirectToCardlink) {
                    store.dispatch(nullifyModalAction())
                    this.setState({
                        checkedLoggedInStatus: true
                    });
                    store.dispatch(setUserLoggedInStatus(false))
                } else {
                    let isImpersonator = false;
                    store.dispatch(getImpersonator()).then(() => {
                            isImpersonator = this.props.userReducer.impersonator !== null;
                            if (isImpersonator && !this.props.userReducer.userLoggedIn) {
                                this.notLoggedInUserActions()
                                if (startImpersonation) {
                                    history.push("/login")
                                }
                            } else {
                                store.dispatch(nullifyModalAction())
                                this.setState({
                                    checkedLoggedInStatus: true
                                });
                                store.dispatch(setUserLoggedInStatus(true))
                            }
                        },
                        () => {
                            if (isImpersonator || this.props.userReducer.impersonator !== null) {
                                store.dispatch(stopImpersonation()).then(() => {
                                    Logout.logoutActions()
                                })
                            } else {
                                store.dispatch(logout()).then(() => {
                                    Logout.logoutActions()
                                })
                            }
                            setTimeout(() => {
                                window.location.reload();
                            }, 3000)
                        })
                }
        },
        () => {
            this.notLoggedInUserActions()
        })
    }

    showTermsAndConditions(showCloseButton) {
        store.dispatch(termsAndConditionsActions(false, showCloseButton))
    }

    showBlockImpersonatorModal(block, msg) {
        this.setState({
            blockImpersonatorModal: block,
            blockImpersonatorMsg: msg,
            checkedLoggedInStatus: true
        })
    }

    showIntroScreen(showIntroScreen) {
        this.setState({
            showIntroScreen: showIntroScreen ? "show" : "hide"
        })
    }

    togglePoliciesModal(policyType, show) {
        this.setState({
            [policyType]: show
        })
    }


    notLoggedInUserActions () {
        store.dispatch(setUserLoggedInStatus(false));
        store.dispatch(changeFirstTimeOverlay(true));
        store.dispatch(nullifyModalAction());
        let params =  new URLSearchParams(window.location.search);
        if (!(window.location.pathname.includes('reset') && params.get("resetPasswordCode") !== null)) {
            localforage.dropInstance({
                name: "apolloIndexedDB",
                storeName: "keyvaluepairs"
            }).then(function() {
                console.log('Dropped apolloIndexedDB')
            });
        }

        this.setState({
            checkedLoggedInStatus: true
        });
        switchLocale(this.props.i18n.locale ? this.props.i18n.locale : 'el');

    }

    render() {
        const loggedIn = this.props.userReducer.userLoggedIn;
        return (
            this.state.checkedLoggedInStatus === true ?
                <div>
                    <BrowserRouter>
                        <div>
                            {loggedIn && !window.location.pathname.includes("introScreen")
                            && !window.location.pathname.includes("login")
                            && !window.location.pathname.includes("/invite/accept/register")
                                ? [
                                <Header key={"header"}/>,
                                <SideBar key={"sidebar"} location={window.location}
                                         showTermsAndConditions={this.showTermsAndConditions}
                                         togglePoliciesModal={this.togglePoliciesModal}
                                />,
                                <TopMainRenderer key={"top-main-renderer"}/>,
                                <GlobalActions key={"global-actions"}/>] : null
                            }
                            <ErrorBoundary>
                                {this.props.termsAndConditionsReducer.termsAndConditionsAccepted === false ?
                                    <TermsAndConditions bsSize="large"/> : null}
                                    <Modal
                                        bsClass="block-modal modal"
                                        show = {this.state.blockImpersonatorModal}
                                        backdrop="static"
                                        dialogClassName="intro-dialog intro-dialog__login-screen">
                                        <Modal.Body>
                                            <article className="article__login-screen">
                                                <Translate value={"pages.login.impersonator." + this.state.blockImpersonatorMsg}/>
                                            </article>
                                        </Modal.Body>
                                    </Modal>
                                    <PoliciesModal
                                        privacyPolicy={this.state.privacyPolicy}
                                        cookiesPolicy={this.state.cookiesPolicy}
                                        togglePoliciesModal={this.togglePoliciesModal}
                                        locale={this.props.i18n.locale ? this.props.i18n.locale : 'el'}
                                    />
                                <Switch>
                                    <Route exact path="/" render={props => <Redirect to="/overview"/>}/>
                                    <Route exact path="/login" render={props =>
                                        <Login
                                            showTermsAndConditions={this.showTermsAndConditions}
                                            showIntroScreen={this.showIntroScreen}
                                            showBlockImpersonatorModal={this.showBlockImpersonatorModal}
                                            {...props}/>}>
                                    </Route>
                                    <Route exact path="/impersonation/start" render={props =>
                                        <StartImpersonation
                                            {...props}
                                            checkIfUserLoggedIn={this.checkIfUserLoggedIn}/>}/>
                                    <Route exact path="/reset" render={props => <ResetPassword {...props}/>}/>
                                    <Route exact path="/expired/password" render={props => <ExpiredPassword {...props}/>}/>
                                    <Route exact path="/register" render={props => <RegisterMerchant
                                        showTermsAndConditions={this.showTermsAndConditions}{...props}/>}/>
                                    <Route exact path="/invite/accept/register" render={props => <UserDetails
                                        showTermsAndConditions={this.showTermsAndConditions}{...props}/>}/>
                                    <Route exact path="/invalid/invitation" render={props => <InvalidInvitation {...props}/>}/>
                                    <Route exact path="/submitted" render={props => <SuccessfulRegistration{...props}/>}/>
                                    <Route path='/mrchnt' component={() => window.location = 'https://mrchnt.cardlink.gr'}/>
                                    <Route path='/cardlink' component={() => window.location = cardlinkOneUrl}/>
                                    <PrivateRoute authed={loggedIn} path='/introScreen' component={IntroScreen}  checkIfUserLoggedIn={this.checkIfUserLoggedIn} />
                                    <PrivateRoute authed={loggedIn} path='/overview' component={Overview}   checkIfUserLoggedIn={this.checkIfUserLoggedIn} />
                                    <PrivateRoute authed={loggedIn} path='/transactions' component={Transactions}  checkIfUserLoggedIn={this.checkIfUserLoggedIn} />
                                    <PrivateRoute authed={loggedIn} path='/business' component={Business} checkIfUserLoggedIn={this.checkIfUserLoggedIn} />
                                    <PrivateRoute authed={loggedIn} path='/reports' component={Reports}  checkIfUserLoggedIn={this.checkIfUserLoggedIn} />
                                    <PrivateRoute authed={loggedIn} path='/interactiveTour' component={Overview}  checkIfUserLoggedIn={this.checkIfUserLoggedIn} />
                                    {/*Route to '/' path must be put last for proper rendering*/}
                                    <PrivateRoute authed={loggedIn} path='/' component={Overview}  checkIfUserLoggedIn={this.checkIfUserLoggedIn} />
                                </Switch>
                            </ErrorBoundary>
                            {loggedIn && !window.location.pathname.includes("introScreen") ?
                                <Footer key={"footer"}/> : null}
                        </div>
                    </BrowserRouter>
                </div> : null
        )
    }
}

export default connect(stateMap)(App);
