import React from "react";
import Select, {components} from 'react-select';

const Translate = require('react-redux-i18n').Translate;

const DropdownIndicator = (props) => {
    return components.DropdownIndicator && (
        <components.DropdownIndicator {...props}>
            <span className={"multi-select-caret" + (props.selectProps.menuIsOpen ? " arrow-up" : "")}></span>
        </components.DropdownIndicator>
    );
};

export default class SearchBox extends React.Component {

    constructor(props) {
        super(props)
        this.selectBox = React.createRef();
    }

    componentWillReceiveProps(nextProps) {
        if (this.props.blurBox !== nextProps.blurBox && nextProps.blurBox) {
            this.props.blurInput(this.selectBox.current);
        }
    }
    
    render() {

        const customStyles = {
            indicatorSeparator: null
        }


        return (
            <div className={this.props.clsName}>
                <Select
                    ref={this.selectBox}
                    blurInputOnSelect={true}
                    components={{DropdownIndicator}}
                    styles={customStyles}
                    value={this.props.value}
                    onChange={this.props.onChange}
                    options={this.props.options}
                    // defaultMenuIsOpen={true}
                    isSearchable={true}
                    isDisabled={this.props.isDisabled}
                    placeholder={<Translate value={this.props.placeholder}/>}
                    isMulti={this.props.isMulti ? this.props.isMulti : false}
                    classNamePrefix={this.props.classNamePrefix ? this.props.classNamePrefix: 'search-box'}
                    className={this.props.className ? this.props.className: 'search-box__element'}
                    onInputChange={inputValue =>
                    (inputValue.length <= 30 ? inputValue : inputValue.substr(0, 30))
                    }
                />
            </div>
        )
    }
}
