export default function registerReducer(state = {
    step: 1,
    lastName: null,
    firstName: null,
    email: null,
    phone1: null,
    afm: null,
    merchantNameLatin: null,
    merchantTitleLatin: null,
    mid: [],
    logoImageBase64: null,
    logoFileName: null,
    lastName2: null,
    firstName2: null,
    email2: null,
    lastName3: null,
    firstName3: null,
    email3: null,
    lastName4: null,
    firstName4: null,
    email4: null,
    lastName5: null,
    firstName5: null,
    email5: null,
    superuserLastName: null,
    superuserFirstName: null,
    superuserEmail: null,
    superuserPhone: null,
    corpType: null,
    validateMerchantRegistration: {
        fetching: false,
        fetched: false
    },
    saveMerchantRegistration: {
        fetching: false,
        fetched: false
    }
}, action) {
    switch (action.type) {
        case "SET_REGISTRATION_DATA": {
            return {
                ...state,
                step: action.step,
                lastName: action.lastName,
                firstName: action.firstName,
                email: action.email,
                phone1: action.phone1,
                afm: action.afm,
                merchantNameLatin: action.merchantNameLatin,
                merchantTitleLatin: action.merchantTitleLatin,
                mid: action.mid,
                logoImageBase64: action.logoImageBase64,
                logoFileName: action.logoFileName,
                lastName2: action.lastName2,
                firstName2: action.firstName2,
                email2: action.email2,
                lastName3: action.lastName3,
                firstName3: action.firstName3,
                email3: action.email3,
                lastName4: action.lastName4,
                firstName4: action.firstName4,
                email4: action.email4,
                lastName5: action.lastName5,
                firstName5: action.firstName5,
                email5: action.email5,
                superuserLastName: action.superuserLastName,
                superuserFirstName: action.superuserFirstName,
                superuserEmail: action.superuserEmail,
                superuserPhone: action.superuserPhone,
                corpType: action.corpType,
            }
        }
        case "SET_REGISTRATION_STEP": {
            return {
                ...state,
                step: action.step
            }
        }
        case "SET_REGISTRATION_STEP_1": {
            return {
                ...state,
                step: action.step,
                lastName: action.lastName,
                firstName: action.firstName,
                email: action.email,
                phone1: action.phone1,
            }
        }
        case "SET_REGISTRATION_STEP_2": {
            return {
                ...state,
                step: action.step,
                afm: action.afm,
                merchantNameLatin: action.merchantNameLatin,
                merchantTitleLatin: action.merchantTitleLatin,
                mid: action.mid,
                logoImageBase64: action.logoImageBase64,
                logoFileName: action.logoFileName,
                corpType: action.corpType,
            }
        }
        case "SET_REGISTRATION_STEP_3": {
            return {
                ...state,
                step: action.step,
                lastName2: action.lastName2,
                firstName2: action.firstName2,
                email2: action.email2,
                lastName3: action.lastName3,
                firstName3: action.firstName3,
                email3: action.email3,
                lastName4: action.lastName4,
                firstName4: action.firstName4,
                email4: action.email4,
                lastName5: action.lastName5,
                firstName5: action.firstName5,
                email5: action.email5,
            }
        }
        case "SET_REGISTRATION_STEP_4": {
            return {
                ...state,
                step: action.step,
                superuserLastName: action.superuserLastName,
                superuserFirstName: action.superuserFirstName,
                superuserEmail: action.superuserEmail,
                superuserPhone: action.superuserPhone
            }
        }
        case "SET_REGISTRATION_STEP_5": {
            return {
                ...state,
                step: action.step,
            }
        }
        case "VALIDATE_MERCHANT_REGISTRATION_PENDING": {
            return {
                ...state,
                validateMerchantRegistration: {
                    fetching: true,
                    fetched: false
                }
            }
        }
        case "VALIDATE_MERCHANT_REGISTRATION_REJECTED": {
            return {
                ...state,
                validateMerchantRegistration: {
                    fetching: false,
                    fetched: false
                }
            }
        }
        case "VALIDATE_MERCHANT_REGISTRATION_FULFILLED": {
            return {
                ...state,
                validateMerchantRegistration: {
                    fetching: false,
                    fetched: true
                }
            }
        }
        case "SAVE_MERCHANT_REGISTRATION_PENDING": {
            return {
                ...state,
                saveMerchantRegistration: {
                    fetching: true,
                    fetched: false
                }
            }
        }
        case "SAVE_MERCHANT_REGISTRATION_REJECTED": {
            return {
                ...state,
                saveMerchantRegistration: {
                    fetching: false,
                    fetched: false
                }
            }
        }
        case "SAVE_MERCHANT_REGISTRATION_FULFILLED": {
            return {
                ...state,
                saveMerchantRegistration: {
                    fetching: false,
                    fetched: true
                }
            }
        }
        default : {
            return state
        }
    }
}