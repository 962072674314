const scaleDataURL = (url, x, y, width, height, maxWidth, maxHeight) => {
    return new Promise((resolve, reject)=>{
        const img = new Image();
        img.addEventListener('error', error => reject(error))
        img.setAttribute('crossOrigin', 'anonymous') // needed to avoid cross-origin issues on CodeSandbox
        img.addEventListener('load', () => {
            let scale, newWidth, newHeight, canvas, ctx;
            if (width < maxWidth) {
                scale = maxWidth / width;
            } else {
                scale = maxHeight / height;
            }
            newWidth = width * scale;
            newHeight = height * scale;
            canvas = document.createElement('canvas');
            canvas.height = newHeight;
            canvas.width = newWidth;
            ctx = canvas.getContext('2d');
            ctx.fillStyle = '#ffffff'
            ctx.fillRect(x, y, canvas.width, canvas.height)
            ctx.drawImage(img, x, y, img.width, img.height, 0, 0, newWidth, newHeight);
            resolve(canvas.toDataURL());
        });
        img.src = url;
    });
}

export default async function getScaledImg(imageSrc, pixelCrop) {
    return await scaleDataURL(imageSrc, 0, 0, pixelCrop.width, pixelCrop.height, 1280, 720);
}
