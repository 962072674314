import React from 'react';
import moment from "moment";
import store from "../../store";
import {installments, installmentsAverage, installmentsPerPeriod} from "../../providers/charts";
import StockChart from "./stockChart/StockChart";
import Gauge from "./gaugeChart/Gauge";
import {formatAmount, setChartDates} from "../../lib/utils";
import ChartDropdown from "./ChartDropdown";
import NoChartData from "../common/NoChartData";
import Loader from "../common/Loader";
import {withRouter} from "react-router-dom";
import connect from "react-redux/es/connect/connect";

const I18n = require('react-redux-i18n').I18n
const Translate = require('react-redux-i18n').Translate;

const stateMap = (store) => {
    return {
        i18n: store.i18n,
        chartReducer: store.chartReducer
    };
};

class TransactionsWithInstallmentsChart extends React.Component {
    constructor(props) {
        super(props)
        this.state = {
            afm: props.afm,
            mid: props.mid,
            installmentsData: null,
            installmentsMode: false,
            dateFrom: moment(moment().subtract(1, 'days')).subtract(1, 'week').format('YYYY-MM-DD'),
            dateTo: moment().subtract(1, 'days').format('YYYY-MM-DD'),
            installmentsDropdownTooltip: I18n.t('tooltips.hover.timeDropdown04')

        }
        this.installmentsTotals = this.installmentsTotals.bind(this)
        this.installmentsModeCheck = this.installmentsModeCheck.bind(this)
        this.transactionsTotals = this.transactionsTotals.bind(this)
        this.setNewDates = this.setNewDates.bind(this)
    }

    componentWillMount() {
        this.installmentsTotals({
            afm: this.props.afm,
            mid: this.props.mid,
            dateFrom: this.state.dateFrom,
            dateTo: this.state.dateTo,
        })
    }

    componentWillReceiveProps(nextProps) {
        if (this.props.afm !== nextProps.afm || this.props.mid !== nextProps.mid || this.props.i18n.locale !== nextProps.i18n.locale) {
            this.setState({
                    afm: nextProps.afm,
                    mid: nextProps.mid
                }, () => {
                    this.installmentsTotals({
                        afm: this.state.afm,
                        mid: this.state.mid,
                        dateFrom: this.state.dateFrom,
                        dateTo: this.state.dateTo
                    });
                }
            )
        }
    }

    installmentsTotals(data) {
        store.dispatch(installments(data)).then((response) => {
            this.setState({
                installmentsData: response.value.data
            })
        })
        store.dispatch(installmentsAverage(data)).then(
            (response) => {
                this.setState({
                    // totalAmount: response.value.data.totalAmount ? response.value.data.totalAmount : null,
                    minAmount: response.value.data.payload[0].minAmount,
                    maxAmount: response.value.data.payload[0].maxAmount,
                    averageAmount: response.value.data.payload[0].averageAmount
                })
            })
        store.dispatch(installmentsPerPeriod(data)).then(
            (response) => {
                this.setState({
                    periodType: response.value.data.periodType,
                    periodDiff: response.value.data.periodDiff,
                    perPeriod: response.value.data.perPeriod
                })
            })
    }

    transactionsTotals(data) {
        store.dispatch(installments(data)).then((response) => {
                this.setState({
                    installmentsData: response.value.data
                })
            }
        )
    }

    installmentsModeCheck(event) {
        this.setState({
            installmentsMode: !this.state.installmentsMode
        })
    }

    setNewDates(dateFrom, dateTo) {
        this.setState({
            dateFrom: dateFrom,
            dateTo: dateTo
        }, () => {
        })
    }

    render() {
        let currency = "€";
        let perPeriod = this.state.perPeriod ? formatAmount(I18n._locale, this.state.perPeriod, 0) : 0;

        const installmentsReady = this.props.chartReducer && this.props.chartReducer.installments
        const installmentsAverageReady = this.props.chartReducer && this.props.chartReducer.installmentsAverage
        const installmentsPerPeriodReady = this.props.chartReducer && this.props.chartReducer.installmentsPerPeriod
        const installmentsFetching = installmentsReady && this.props.chartReducer.installments.fetching
        const installmentsFetched = installmentsReady && this.props.chartReducer.installments.fetched
        const installmentsAverageFetching = installmentsAverageReady && this.props.chartReducer.installmentsAverage.fetching
        const installmentsAverageFetched = installmentsAverageReady && this.props.chartReducer.installmentsAverage.fetched
        const installmentsPerPeriodFetching = installmentsPerPeriodReady && this.props.chartReducer.installmentsPerPeriod.fetching
        const installmentsPerPeriodFetched = installmentsPerPeriodReady && this.props.chartReducer.installmentsPerPeriod.fetched

        return (
            <div className="reports__installments--chart">
                <div className="date-align-right__wrapper">
                    {this.props.renderDates(this.state.dateFrom, this.state.dateTo)}
                </div>
                <div className="dates__chart-dropdown__wrapper"
                     title={this.state.installmentsDropdownTooltip}>
                    <ChartDropdown
                        id="installments-total-chart-timespan"
                        chartMaps={this.props.chartMaps}
                        data={{
                            afm: this.state.afm,
                            mid: this.state.mid
                        }} reRenderChart={this.installmentsTotals}
                        setDates={setChartDates}
                        setDatesInParent={this.setNewDates}
                        dropdownBaseValue="pages.reports.chart.dropdown.baseValueWeek"
                    />
                </div>
                {installmentsFetched && installmentsAverageFetched && installmentsPerPeriodFetched ?
                    <React.Fragment>
                        {this.state.installmentsData !== null && this.state.installmentsData.length > 0 ?
                            <div className="installments__outter__wrapper">
                                <div
                                    className="reports__transactions--chart installments--chart col-md-9 col-sm-12 col-xs-12">
                                    <StockChart
                                        key="installments-stock-chart"
                                        data={this.state.installmentsData}
                                        column={this.state.installmentsMode ? 'count' : 'amount'}
                                        panelType={this.state.installmentsMode ? 'line' : 'step'}
                                        id={'TransactionsWithInstallments'}
                                        filename={I18n.t("pages.reports.titles.installmentsTotals")}
                                        dates={{dateFrom: this.state.dateFrom, dateTo: this.state.dateTo}}
                                    />
                                    <div
                                        className="reports__chart-switch chart-left reports__chart-switch--blue-orange-reversed">
                                        <div
                                            className={"orange__switch report-translate" + (!this.state.installmentsMode ? "_active" : "")}>
                                            <Translate value="pages.reports.amountTitle"/>
                                        </div>
                                        <input type="checkbox"
                                               name="select-transaction-type-mode-checkbox"
                                               className="cbx"
                                               id='installmentsMode'
                                               checked={this.state.installmentsMode}
                                               onChange={this.installmentsModeCheck}
                                        />
                                        <label htmlFor="installmentsMode" className="toggle">
                                            <span></span>
                                        </label>
                                        <div
                                            className={"blue__switch report-translate" + (this.state.installmentsMode ? "_active" : "")}>
                                            <Translate value="pages.reports.countTitle"/>
                                        </div>
                                    </div>
                                </div>
                                <div
                                    className="reports__transactions--chart installments__gauge col-md-3 col-sm-12 col-xs-12">
                                    {this.state.periodType !== null ?
                                        <div className="per-period__wrapper">
                                            <h6>
                                <span className="per-period__value">
                                    {perPeriod}
                                </span>
                                                <Translate className="per-period__type"
                                                           value={"pages.reports.installments." + this.state.periodType}/>
                                            </h6>
                                            <Translate className="per-period__caption"
                                                       value="pages.reports.installments.perPeriodCaption"/>
                                        </div> : null}
                                    <Gauge currency={currency} min={this.state.minAmount} max={this.state.maxAmount} average={this.state.averageAmount}
                                           label={"installmentsLabel"}/>
                                </div>
                            </div> :
                            <NoChartData/>}
                    </React.Fragment> : null}
                {installmentsFetching || installmentsAverageFetching || installmentsPerPeriodFetching ?
                    <Loader bouncing={false} color={'bahama-blue'} message=''/> : null
                }
            </div>
        )
    }
}

export default withRouter(connect(stateMap)(TransactionsWithInstallmentsChart));