import React from 'react';
import ManagedUser from "./ManagedUser";
import store from "../../store";
import {downloadUserManagementReport, getAssociatedUsers} from "../../providers/users";
import connect from "react-redux/es/connect/connect";

const Translate = require('react-redux-i18n').Translate;
const fileDownload = require('js-file-download');

const stateMap = (store) => {
    return {
        userReducer: store.userReducer
    };
};

class UserManagement extends React.Component {

    constructor(props) {
        super(props)
        this.state = {
            associatedUsers: null
        }

        this.getAssociatedUsers = this.getAssociatedUsers.bind(this)
        this.sortAssociatedUsers = this.sortAssociatedUsers.bind(this)
        this.downloadReport = this.downloadReport.bind(this)
    }

    componentDidMount() {
        this.getAssociatedUsers()
    }

    getAssociatedUsers() {
        store.dispatch(getAssociatedUsers()).then(
            (response) => {
                this.setState({
                    associatedUsers: response.value.data.payload
                })
            },
            (error) => {
            })
    }

    sortAssociatedUsers(user1, user2) {
        if (user2.superUser === true && user1.superUser === false) {
            return 1
        } else if (user2.superUser === false && user1.superUser === true) {
            return -1
        } else if (user2.legalRepresentative === true && user1.legalRepresentative === false) {
            return 1
        } else if (user2.legalRepresentative === false && user1.legalRepresentative === true) {
            return -1
        } else {
            if (user1.userId === this.props.currentUser) {
                return -1;
            } else if (user2.userId === this.props.currentUser) {
                return 1
            } else {
                return 0
            }

        }
    }

    downloadReport() {
        store.dispatch(downloadUserManagementReport(this.props.locale)).then((response) => {
                fileDownload(response.value.data, response.value.headers.filename)
            },
            () => {
            })
    }

    render() {

        return (
            <div>
                {this.props.userReducer.showHotSpots ?
                    <div className="blocking-overlay__wrapper">
                        <div className="blocking-overlay blocking-overlay__modal" onClick={this.closeHotPopovers}></div>
                    </div>:null}
                <div className="user-management__title-and-download-button-wrapper">
                    <h3 className="user-management__title">
                        <Translate value="modals.userManagement.title"/>
                    </h3>
                    <button type="button" className="user-management__excel-button"
                            onClick={this.downloadReport}>
                    </button>
                </div>
                <div className="user-management__caption">
                    <Translate value="modals.userManagement.caption"/>
                </div>
                {this.state.associatedUsers !== null ?
                    <div>
                        {
                            this.state.associatedUsers.sort(this.sortAssociatedUsers).map(associatedUser => {
                                return <ManagedUser key={associatedUser.userId} associatedUser={associatedUser}
                                                    currentUser={this.props.currentUser}
                                                    getAssociatedUsers={this.getAssociatedUsers}
                                                    impersonator = {this.props.userReducer.impersonator}/>
                            })
                        }
                    </div>
                    : null}
            </div>

        )

    }
}

export default connect(stateMap)(UserManagement);