import React from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import LocaleSwitch from '../../common/LocaleSwitch';
import Logo from '../../common/Logo';
import store from '../../../store';
import { passwordReset, resendCode } from '../../../providers/users';
import NumberFormat from 'react-number-format';
import Button from 'react-bootstrap/es/Button';
import Popover from 'react-bootstrap/es/Popover';
import Overlay from 'react-bootstrap/es/Overlay';
import Wave from '../../common/Wave';
import GenericModal from '../../common/GenericModal';
import Loader from '../../common/Loader';
import { Helmet } from 'react-helmet';
import { phoneNumberFormat } from '../../../lib/utils';
import localforage from 'localforage';

const Translate = require('react-redux-i18n').Translate;
const I18n = require('react-redux-i18n').I18n

const stateMap = (store) => {
    return {
        i18n: store.i18n,
        userReducer: store.userReducer
    };
};

class ResetPasswordStep2 extends React.Component {
    constructor(props) {
        super(props)

        this.state = {
            passwordPattern: '(?=^.{12,255}$)((?=.*\\d)(?=.*[A-Z])(?=.*[a-z])(?=.*[^A-Za-z0-9]))^.*',
            passwordRequirements: I18n.t('pages.reset.passwordRequirements'),
            matchRequestedFormat: I18n.t('pages.reset.matchRequestedFormat'),
            showHelp: false,
            showBottomHelp: false,
            showCodeHelp: false,
            password: '',
            repeatPassword: '',
            errorMessage: '',
            fourDigitCode: '',
            showNewPassword: false,
            showNewPasswordConfirm: false,
            otpExpired: false,
            askPhone: null,
            phoneSubmitted: false,
            hasSavedPhone: false

        }

        this.handleChange = this.handleChange.bind(this)
        this.reset = this.reset.bind(this)
        this.handleFormattedChange = this.handleFormattedChange.bind(this)
        this.toggleShowPassword = this.toggleShowPassword.bind(this)
        this.handleCloseModal = this.handleCloseModal.bind(this)
    }

    componentWillReceiveProps(nextProps) {
        if (nextProps.i18n !== this.props.i18n) {
            this.setState({
                passwordRequirements: I18n.t('pages.reset.passwordRequirements'),
                matchRequestedFormat: I18n.t('pages.reset.matchRequestedFormat')
            })
        }
    }

    componentDidMount() {
        let _this = this;
        localforage.getItem("userResetPwPhone").then(function(value) {
            _this.setState({
                askPhone: !value,
                hasSavedPhone: value
            });
        });
    }

    toggleShowPassword(target) {
        this.setState(prevState => ({
            [target]: !prevState[target]
        }));
    }

    reset() {
        if (this.state.password !== this.state.repeatPassword) {
            this.setState({
                errorMessage: 'pages.reset.passwordsNotMatch'
            })
        } else {
            this.setState({
                errorMessage: ''
            })
            store.dispatch(passwordReset(this.props.email, this.state.fourDigitCode, this.props.resetPasswordCode,
                this.state.password, this.state.phone)).then(
                (response) => {
                    this.props.history.push({
                        pathname: '/login',
                        state: {successfulPassReset: true}
                    })
                },
                (error) => {
                    if (error.response.data.errors) {
                        if (error.response.data.errors[0] === "EMAIL_NOT_FOUND") {
                            this.setState({
                                errorMessage: 'pages.reset.errors.invalidNotification'
                            })
                        } else if (error.response.data.errors[0] === "EMAIL_NOT_CONFIRMED") {
                            this.setState({
                                errorMessage: 'pages.reset.errors.invalidNotification'
                            })
                        } else if (error.response.data.errors[0] === "CODE_NOT_FOUND_OR_EXPIRED") {
                            this.setState({
                                errorMessage: 'pages.reset.errors.codeNotFoundOrExpired'
                            })
                        } else if (error.response.data.errors[0] === "INVALID_OTP") {
                            this.setState({
                                errorMessage: 'pages.reset.errors.otpInvalid'
                            })
                        } else if (error.response.data.errors[0] === "OTP_ATTEMPTS_LIMIT_REACHED") {
                            this.resendCode(true);
                        } else if (typeof error.response.data === 'string' &&
                            (error.response.data.includes('id is null.') ||
                                error.response.data.includes('id is already used'))) {
                            this.setState({
                                errorMessage: 'pages.reset.errors.anErrorOccured'
                            })
                        }
                    }
                })
        }
    }

    resendCode(otpExpired) {
        let phone = this.props.phone ? this.props.phone : this.state.phone
        store.dispatch(resendCode(this.props.email, phone)).then(
            (response) => {
                this.setState({
                    errorMessage: '',
                    otpExpired: otpExpired,
                    phoneSubmitted: true,
                    askPhone: false
                })
            },
            (error) => {
                if ((error.response.status === 503)) {
                    this.setState({
                        errorMessage: 'pages.reset.errors.anErrorOccured'
                    })
                }
            })
    }

    handleChange(event) {
        this.setState({
            [event.target.id]: event.target.value
        })
    }

    handleFormattedChange(obj, ev) {
        this.setState({
            [ev.target.id]: obj.value
        })
    }

    handleCloseModal() {
        this.setState({otpExpired: false})
    }

    render() {
        const passwordResetInProgress = this.props.userReducer &&
            this.props.userReducer.passwordReset &&
            this.props.userReducer.passwordReset.fetching
        const resendingOtpCode = this.props.userReducer &&
            this.props.userReducer.resendCode &&
            this.props.userReducer.resendCode.fetching
        return (
            <main className="reset-area-step2">
                <Helmet>
                    <meta name="referrer" content="no-referrer"/>
                </Helmet>
                <GenericModal
                    showModal={this.state.otpExpired}
                    onHide={this.handleCloseModal}
                    dialogClassName="reset-area-step2__custom-modal"
                    showCloseButton={true}
                    modalTitle={<Translate className="generic-modal-title"
                                           value='pages.reset.errors.unsuccessfulReset'/>}
                    modalBody={
                        <div className="generic-warning-modal-msg">
                            <span className="icon-failed"></span>
                            <div>
                                <Translate className="text-warning" value="pages.reset.errors.otpLimitReached"/>
                            </div>
                        </div>
                    }
                />
                <div className="reset-area-step2__top">
                    <GenericModal
                        showModal={this.state.errorMessage === 'pages.reset.errors.anErrorOccured'}
                        showCloseButton={true}
                        onHide={() => {
                            this.setState({
                                errorMessage: ''
                            })
                        }}
                        modalTitle={<Translate value='pages.userDetails.errors.errorOccurredTitle'/>}
                        modalBody={<Translate value={this.state.errorMessage}/>}
                        dialogClassName="reset-area-step1__custom-modal"
                        bsSize="small"/>
                    <LocaleSwitch type={"login-area"}></LocaleSwitch>
                </div>
                <div className="reset-area-step2__content">
                    <div className="reset-area-step2__left">
                        <p className="reset-area-step2__sub-header">
                            <Translate value="pages.reset.resetYourPass"/>
                        </p>
                        <p className="reset-area-step2__sub-header">
                            <b> <Translate value="pages.reset.step2"/> </b>
                        </p>
                        <p className="reset-area-step2__text reset-area-step2__text__intro">
                            <Translate value="pages.reset.enter4DigitCode"/>
                        </p>
                        <div className="reset-area-step2__resend-area">
                            <form onSubmit={(e) => {
                                e.preventDefault();
                                this.resendCode(false);
                            }}>
                                <div className="reset-area-step2__show-digit-code-help">
                                    <a href="javascript:void(0);"
                                       onClick={() => {
                                           this.setState({
                                               showCodeHelp: !this.state.showCodeHelp
                                           })
                                       }} className="_active">
                                        <Translate value={"pages.reset.codeNotReceived"}/>
                                    </a>
                                </div>
                                {this.state.showCodeHelp ?
                                    <React.Fragment>
                                        <ul className="reset-area-step2__text reset-area-step2__text__intro__few">
                                            <li>
                                                <Translate value="pages.reset.allowFewSeconds1" dangerousHTML/>
                                            </li>
                                            <li>
                                                <Translate value="pages.reset.allowFewSeconds2" dangerousHTML/>
                                            </li>
                                            <li>
                                                <Translate value="pages.reset.allowFewSeconds3" dangerousHTML/>
                                            </li>

                                        </ul>
                                        <button type="submit" className="reset-area-step2__resend-button"
                                                disabled={resendingOtpCode}>
                                            {resendingOtpCode ?
                                                <Loader bouncing={false} color={'bahama-blue'} message=''
                                                        style={{marginTop: 0}} bounceClass='smaller-bounces'/> :
                                                <Translate value="pages.reset.resendButton"/>
                                            }
                                        </button>
                                    </React.Fragment>
                                    : null}
                            </form>
                        </div>
                    </div>
                    <div className="reset-area-step2__right">
                        <div className="reset-area-step2__login-with-forgot">
                            <div className="reset-area-step2__four-digit-box">
                                    <span className={"icon-icon-mob"}>
                                    </span>
                                {!this.state.askPhone || this.state.phoneSubmitted ?
                                    <div className="reset-area-step2__four-digit-box__digit-column">
                                        <Translate value="pages.reset.fourDigitCode"/>
                                        <NumberFormat
                                            type="text"
                                            id="fourDigitCode"
                                            format="####"
                                            className="reset-area-step2__four-digit-box__code"
                                            value={this.state.fourDigitCode}
                                            placeholder=""
                                            decimalScale={0}
                                            onValueChange={this.handleFormattedChange}/>
                                    </div> :
                                    <div className="reset-area-step2__four-digit-box__digit-column">
                                        <Translate value="pages.reset.mobilePhone"/>
                                        <div className="phone-input-wrapper">
                                            <NumberFormat
                                                type="tel"
                                                id="phone"
                                                format={phoneNumberFormat}
                                                className="simple-input"
                                                value={this.state.phone}
                                                placeholder="69"
                                                decimalScale={0} required={true}
                                                onValueChange={this.handleFormattedChange}/>
                                                <Button disabled={!this.state.phone}
                                                     onClick={()=> {this.resendCode(false)}}>
                                                    <Translate value="pages.reset.sendFourDigitCode"/>
                                                </Button>
                                        </div>
                                    </div>
                                }
                            </div>
                        </div>
                        <div className="reset-area-step2__login-with-forgot">
                            <div className="reset-area-step2__login-box">
                                <Logo isMain={false} isHeader={false} imgClassName="login"/>
                                <form onSubmit={(e) => {
                                    e.preventDefault();
                                    this.reset();
                                }}>
                                    <Translate value="pages.reset.newPassword"/>
                                    <label htmlFor="moreCriteria"
                                           className="reset-area-step2__login-with-forgot__info-button-label">
                                        <Button id="moreCriteria"
                                                className="reset-area-step2__login-with-forgot__info-button"
                                                onClick={e => {
                                                    this.setState({target: e.target, showHelp: !this.state.showHelp});
                                                }}>
                                        </Button>
                                        <span className="icon-icon-info"/>
                                        <Overlay
                                            show={this.state.showHelp}
                                            target={this.state.target}
                                            placement="top"
                                            container={this}
                                            containerPadding={20}>
                                            <Popover id="popover-positioned-top"
                                                     title=''>
                                                {this.state.passwordRequirements}
                                            </Popover>
                                        </Overlay>
                                    </label>
                                    <div className="reset-area__password-container">
                                        <input id="password" name="password"
                                               type={this.state.showNewPassword ? "text" : "password"}
                                               placeholder={this.state.passwordPlaceholder}
                                               onChange={this.handleChange}
                                               pattern={this.state.passwordPattern}
                                               title={this.state.passwordRequirements}
                                               required={true} value={this.state.password} size={20}/>
                                        <Button onClick={() => {
                                            this.toggleShowPassword("showNewPassword")
                                        }}
                                                className={"reset-area__icon-password-eye" +
                                                    (this.state.showNewPassword ? " icon-show-password-eye" : " icon-hide-password-eye")}></Button>
                                    </div>
                                    <Translate value="pages.reset.retypeNewPassword"/>
                                    <div className="reset-area__password-container">
                                        <input id="repeatPassword" name="repeatPassword"
                                               type={this.state.showNewPasswordConfirm ? "text" : "password"}
                                               placeholder={this.state.passwordPlaceholder}
                                               onChange={this.handleChange}
                                               title={this.state.passwordRequirements}
                                               pattern={this.state.passwordPattern}
                                               required={true} value={this.state.repeatPassword} size={20}/>
                                        <Button onClick={() => {
                                            this.toggleShowPassword("showNewPasswordConfirm")
                                        }}
                                                className={"reset-area__icon-password-eye" +
                                                    (this.state.showNewPasswordConfirm ? " icon-show-password-eye" : " icon-hide-password-eye")}></Button>
                                    </div>
                                    {/*Error messages*/}
                                    {this.state.errorMessage !== '' ?
                                        <div className="login-area__login-box__wrong-creds">
                                            <div className="generic-error-msg">
                                                <span className="icon-icon-attention"/>
                                                <div>
                                                    <Translate value={this.state.errorMessage} dangerousHTML/>
                                                </div>
                                            </div>
                                        </div> : null
                                    }
                                    <div className="reset-area-step2__login-button-area">
                                        <button type="submit" className="reset-area-step2__login-button"
                                                disabled={passwordResetInProgress || (this.state.askPhone && !this.state.phoneSubmitted)}>
                                            {passwordResetInProgress ?
                                                <Loader bouncing={false} color={'white'} message=''
                                                        style={{marginTop: 0}} bounceClass='smaller-bounces'/> :
                                                <Translate value="pages.reset.buttonText"/>
                                            }
                                        </button>
                                    </div>
                                    <div className="reset-area-step1__forgot-password">
                                        <a href="javascript:void(0);"
                                           onClick={() => {
                                               this.setState({
                                                   showBottomHelp: !this.state.showBottomHelp
                                               })
                                           }} className="_active">
                                            <Translate value={"pages.reset.needHelp"}/>
                                        </a>
                                    </div>
                                    {this.state.showBottomHelp ?
                                        <div className="reset-area-step1__forgot-password">
                                            <Translate value={"pages.reset.help"}/>
                                        </div>
                                        : null}
                                </form>
                            </div>
                        </div>
                    </div>
                </div>
                <Wave/>
            </main>
        )
    }
}

export default withRouter(connect(stateMap)(ResetPasswordStep2));
