import React from 'react';
import NumberFormat from "react-number-format";

const Translate = require('react-redux-i18n').Translate;


export default class AmountRange extends React.Component {
    constructor(props) {
        super(props)

        this.checkAmountsAreInRange = this.checkAmountsAreInRange.bind(this)
    }

    checkAmountsAreInRange(values) {
        const {formattedValue, floatValue} = values;
        return formattedValue === "" || (floatValue >= 0 && floatValue <= 9999999.99 && formattedValue.length < '13' &&
            formattedValue[11] !== ',')
    }

    render() {
        return (
            <React.Fragment>
                <div className={"search-modal__" + this.props.clsType + "-column-upper__from-to-placeholder__label"}>
                    <Translate value={this.props.baseTranslate + ".midColumn.range"}/>
                </div>
                <div className={"search-modal__" + this.props.clsType + "-column-upper__from-to-placeholder"}>
                    <div className={"search-modal__" + this.props.clsType + "-column-upper__from-placeholder"}>
                        <NumberFormat
                            required={false}
                            thousandSeparator="."
                            decimalSeparator=","
                            decimalScale={2}
                            isAllowed={(values) => {
                                return this.checkAmountsAreInRange(values)
                            }}
                            onValueChange={this.props.handleFormattedChange}
                            className={"search-modal__" + this.props.clsType + "-column-upper__from-placeholder__input-text"
                            + " search-modal__" + this.props.clsType + "-column-upper__input-text"}
                            placeholder={this.props.fromPlaceholder}
                            id="amountFrom" name="from"
                            value={this.props.amountFrom}/>
                        <span className={"search-modal__" + this.props.clsType
                        + "-column-upper__currencyInput"}>{this.props.currency}
                        </span>
                    </div>
                    <div className={"search-modal__" + this.props.clsType + "-column-upper__to-placeholder"}>
                        <NumberFormat
                            required={false}
                            thousandSeparator="."
                            decimalSeparator=","
                            decimalScale={2}
                            isAllowed={(values) => {
                                return this.checkAmountsAreInRange(values)
                            }}
                            onValueChange={this.props.handleFormattedChange}
                            className={"search-modal__" + this.props.clsType + "-column-upper__from-placeholder__input-text"
                            + " search-modal__" + this.props.clsType + "-column-upper__input-text"}
                            placeholder={this.props.toPlaceholder}
                            id="amountTo" name="from" value={this.props.amountTo}/>
                        <span
                            className={"search-modal__" + this.props.clsType + "-column-upper__currencyInput"}>{this.props.currency}</span>
                    </div>
                </div>
            </React.Fragment>
        )
    }
}