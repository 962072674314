import React from "react";
import SearchBox from "./SearchBox";

export default class SearchBoxRenderer extends React.Component {


    render() {
        return (
            this.props.searchDropdownData !== null ?
                <SearchBox options={this.props.searchDropdownData}
                           clsName="search-fast"
                           value={this.props.searchedItem} onChange={(searchedItem) => {
                    this.props.handleChange(searchedItem)
                }}
                           placeholder={"common.fastSearch.placeholder"}/> : null
        )
    }
}