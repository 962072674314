import React from "react";
import logo from "../../../assets/img/logo.svg";
import store from "../../store";
import {setRegistrationData} from "../../actions/registerAction";

export default class Logo extends React.Component {

    render() {
        return (
            <div className={(this.props.isHeader ? 'header-logo-wrapper' : '') + (this.props.isNotLink ? 'header-logo-no-cursor' : '') }>
                <a className="logo" href={!this.props.isNotLink ? (this.props.isMain ? "/overview" : "/login"): 'javascript:void(0)'} onClick={() => {
                    store.dispatch(setRegistrationData({step: 1}))
                }}>
                    <img className={this.props.imgClassName ? this.props.imgClassName : ''
                    } alt="cardlink's logo" src={logo}/>
                </a>
            </div>
        )
    }
}