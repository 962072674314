import React from 'react'
import Avatar from "react-avatar-edit";

const Translate = require('react-redux-i18n').Translate;

export default class AvatarEditorWithButton extends React.Component {
    constructor(props) {
        super(props)

        this.state = {
            imgSrc: this.props.imgUrl,
            croppedSrc: null
        }
        this.onClose = this.onClose.bind(this)
        this.onCrop = this.onCrop.bind(this)
    }

    onClose() {
        this.props.nullifyImgUrlValue()
        this.props.onToggleImageLoad(false)
    }

    onCrop(croppedSrc) {
        this.setState({croppedSrc})
    }


    render() {
        return (
            <form className={this.props.containerClass} onSubmit={(e) => {
                e.preventDefault();
                this.props.imageUploadHandler(this.props.imgName, this.state.croppedSrc);
            }}>
                <div className={this.props.class}>
                    <div className="btn-close-edit-avatar-tooltip"
                         title={this.props.closeEditAvatarTooltip}
                         onClick={this.onClose}

                    >
                    </div>
                    <Avatar
                        width={this.props.width}
                        height={this.props.height}
                        onCrop={this.onCrop}
                        onClose={this.onClose}
                        imageWidth={this.props.imageWidth}
                        cropColor={this.props.cropColor}
                        backgroundColor={this.props.backgroundColor}
                        cropRadius={this.props.cropRadius}
                        minCropRadius={this.props.minCropRadius}
                        closeIconColor={this.props.closeIconColor}
                        shadingColor={this.props.shadingColor}
                        shadingOpacity={this.props.shadingOpacity}
                        src={this.state.imgSrc}
                    />

                    <button type="submit"
                            title={this.props.saveAvatarTooltip}
                            className="user-avatar-submit-btn">
                        <Translate value="modals.profile.submitImageButton"/>
                    </button>
                </div>
            </form>
        )
    }
}