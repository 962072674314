import React from "react";

const Translate = require('react-redux-i18n').Translate;

export default class SocialButtons extends React.Component {
    render() {
        return (
            <div className="social-btns">
                <h6 className="social-btns__header">
                    <Translate value="socialMedia"/>
                </h6>
                <div className="social-btns__row">
                    <div className="social-btns__wrapper">
                        <a href="https://www.facebook.com/cardlinkgreece/" className="icon-social-fb" target="_blank"
                           rel="noopener noreferrer">
                        </a>
                        <a href="https://www.instagram.com/cardlink.gr/ " className="icon-social-instagram"
                           target="_blank"
                           rel="noopener noreferrer">
                        </a>
                        <a href="https://www.linkedin.com/company/cardlink-sa/" className="icon-social-linkedin"
                           target="_blank"
                           rel="noopener noreferrer">
                        </a>
                        <div className="youtube-align">
                            <a href="https://www.youtube.com/channel/UCCd-DAAMb9Gv6Wpdmeh2biQ/videos"
                               className="icon-youtube" target="_blank"
                               rel="noopener noreferrer">
                            </a>
                        </div>
                    </div>
                    <a href="http://www.cardlink.gr/" className="social-btns__link"
                       target="_blank" rel="noopener noreferrer">{"www.cardlink.gr"}</a>
                </div>
            </div>
        )
    }
}

