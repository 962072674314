import {makeRequest} from "../api-client";

const middlewareUrl = process.env.MIDDLEWARE_URL
const blobResponseType = 'blob'

export const getTransactionList = (userAfm, transactionDateFrom, transactionDateTo, transactionTimeFrom, transactionTimeTo,
                                   page, rowsPerPage, cardNumber, rrn, authorizationCode,
                                   merchantId, terminalId, amountFrom, amountTo, acquirerId,
                                   vMerchantId, vTerminalId, transactionType, responseCode,
                                   packageStatus, paymentTypeValue, by, asc, showDuplicateTransactions) => {

    let data = createTransactionObject(transactionDateFrom, transactionDateTo, transactionTimeFrom, transactionTimeTo,
        page, rowsPerPage, cardNumber, rrn, authorizationCode,
        merchantId, terminalId, amountFrom, amountTo, acquirerId,
        vMerchantId, vTerminalId, transactionType, responseCode,
        packageStatus, paymentTypeValue, by, asc, showDuplicateTransactions);
    let headers = {'x-afm': userAfm};
    return makeRequest(middlewareUrl + "/authorizations/list", "GET", data, headers,
        "AUTHORIZATIONS_LIST")
};

export const downloadTransactionReport = (userAfm, transactionDateFrom, transactionDateTo, transactionTimeFrom, transactionTimeTo,
                                          page, rowsPerPage, locale, cardNumber, rrn, authorizationCode,
                                          merchantId, terminalId, amountFrom, amountTo, acquirerId,
                                          vMerchantId, vTerminalId, transactionType, responseCode,
                                          packageStatus, paymentTypeValue, by, asc, showDuplicateTransactions, email, currentUrl, isImpersonator) => {

    let data = createTransactionObject(transactionDateFrom, transactionDateTo, transactionTimeFrom, transactionTimeTo,
        page, rowsPerPage, cardNumber, rrn, authorizationCode,
        merchantId, terminalId, amountFrom, amountTo, acquirerId,
        vMerchantId, vTerminalId, transactionType, responseCode,
        packageStatus, paymentTypeValue, by, asc, showDuplicateTransactions, email, currentUrl, isImpersonator)
    data.locale = locale
    let headers = {'x-afm': userAfm}
    return makeRequest(middlewareUrl + "/authorizations/excel", "GET", data, headers,
        "DOWNLOAD_AUTHORIZATION_REPORT")
};

export const getTransactionDetails = (authorizationId, authorizationDate) => {
    return makeRequest(middlewareUrl + "/authorizations/find", "GET",
        {authorizationId: authorizationId, authorizationDate: authorizationDate}, {},
        "GET_TRANSACTION_DETAILS")
};

export const getAssociations = (rowsPerPage) => {
    let data = {}
    data['rowsPerPage'] = rowsPerPage
    return makeRequest(middlewareUrl + "/merchants/get/associations", "GET", data, {},
        "GET_ASSOCIATIONS")
};

function createTransactionObject(transactionDateFrom, transactionDateTo, transactionTimeFrom, transactionTimeTo,
                                 page, rowsPerPage, cardNumber, rrn, authorizationCode,
                                 merchantId, terminalId, amountFrom, amountTo, acquirerId,
                                 vMerchantId, vTerminalId, transactionType, responseCode,
                                 packageStatus, paymentTypeValue, by, asc, showDuplicateTransactions, email, currentUrl, isImpersonator) {
    let data = {};

    data['transactionDateFrom'] = transactionDateFrom;
    data['transactionDateTo'] = transactionDateTo;
    data['transactionTimeFrom'] = transactionTimeFrom;
    data['transactionTimeTo'] = transactionTimeTo;
    data['page'] = page;
    data['rowsPerPage'] = rowsPerPage;
    data['cardNumber'] = cardNumber !== "" ? cardNumber : undefined;
    data['rrn'] = rrn !== "" ? rrn : undefined;
    data['authorizationCode'] = authorizationCode !== "" ? authorizationCode : undefined;
    data['merchantId'] = merchantId !== null ? merchantId : undefined;
    data['terminalId'] = terminalId !== null && terminalId !== "" ? terminalId : undefined;
    data['amountFrom'] = amountFrom !== "" ? amountFrom : undefined;
    data['amountTo'] = amountTo !== "" ? amountTo : undefined;
    data['acquirerId'] = acquirerId !== null && acquirerId !== 'L' ? acquirerId : undefined;
    data['vMerchantId'] = vMerchantId !== null ? vMerchantId : undefined;
    data['vTerminalId'] = vTerminalId !== null ? vTerminalId : undefined;
    data['transactionType'] = acquirerId === 'L' ? 'L' : transactionType !== null ? transactionType : undefined;
    data['responseCode'] = responseCode;
    // mposEcom is about the payment type
    data['mposEcom'] = paymentTypeValue !== null ? paymentTypeValue : undefined;
    data['packageStatus'] = packageStatus;
    data['by'] = by;
    data['asc'] = asc;
    data['email'] = email;
    if (isImpersonator !== null && isImpersonator !== undefined) {
        data['isImpersonator'] = isImpersonator;
    }
    data['currentUrl'] = currentUrl;
    data['showDuplicateTransactions'] = showDuplicateTransactions;

    return data;
}

export const refund = (transactionId, amount, requestId, authorizationDate) => {
    let data = {};
    data['transactionId'] = transactionId;
    data['amount'] = amount;
    data['authorizationDate'] = authorizationDate;

    if (typeof amount === 'string') {
        data['amount'] = amount.replace(',', '.');
        data['amount'] = parseFloat(data['amount'])
    }
    data['requestId'] = requestId;
    return makeRequest(middlewareUrl + "/add/refund", "POST", data, {},
        "REFUND")
};

export const downloadAuthReportFile = (filename, email, isImpersonator) => {
    return makeRequest(middlewareUrl + "/authorizations/download/excel/file", "GET", {
            filename: filename,
            email: email,
            isImpersonator: isImpersonator
        }, {},
        "DOWNLOAD_AUTH_REPORT_FILE", blobResponseType)
}

export const getReceiptPdfFile = (authorizationId, authorizationDate) => {
    return makeRequest(middlewareUrl + "/receipt/pdf", "GET",
        {authorizationId: authorizationId, authorizationDate: authorizationDate}, {},
        "GET_TRANSACTION_RECEIPT_PDF", blobResponseType)
};

export const sendReceiptPdfFileEmail = (authorizationId, authorizationDate, receiptEmail) => {
    return makeRequest(middlewareUrl + "/receipt/pdf/email", "GET",
        {authorizationId: authorizationId, authorizationDate: authorizationDate, receiptEmail: receiptEmail}, {},
        "GET_TRANSACTION_RECEIPT_PDF_EMAIL")
};

export const getDebitNotesList = (data) => {
    return makeRequest(middlewareUrl + "/one/debit/notes", "GET", data, {},
        "DEBIT_NOTES_LIST")
}

export const getDebitNotesTotalData = (data) => {
    return makeRequest(middlewareUrl + "/one/debit/notes/total/data", "GET", data, {},
        "DEBIT_NOTES_TOTAL_DATA")
}

export const getOneDebitNotificationList = (afm, mid, email, status) => {
    return makeRequest(middlewareUrl + "/one/debit/notifications", "GET", {email: email, mid: mid, status: status}, {'x-afm': afm},
        "ONE_DEBIT_NOTIFICATION_LIST")
};

export const downloadOneDebitNotesFile = (data) => {
    return makeRequest(middlewareUrl + "/one/debit/notes/download/file", "GET", data, {},
        "DOWNLOAD_ONE_DEBIT_NOTE_FILE", blobResponseType)
}

export const downloadOneDebitNotesPdfFile = (data) => {
    let headers = {'x-afm': data.afm}
    let dataCopy = Object.assign({}, data)
    delete dataCopy.afm;
    return makeRequest(middlewareUrl + "/one/debit/notes/download/pdf/file", "GET", dataCopy, headers,
        "DOWNLOAD_ONE_DEBIT_NOTE_PDF_FILE", blobResponseType)
}

export const getInstallmentsList = (userAfm, trxId, dateFrom, dateTo, amountFrom, amountTo, page, rowsPerPage, by, asc) => {
    let data = createInstallmentObject(trxId, dateFrom, dateTo, amountFrom, amountTo, page, rowsPerPage, by, asc);
    let headers = {'x-afm': userAfm}
    return makeRequest(middlewareUrl + "/installments/list", "GET", data, headers,
      "INSTALLMENTS_LIST")
};

export const getInstallmentDetails = (id) => {
    return makeRequest(middlewareUrl + "/installments/find", "GET",
      {id: id}, {},
      "GET_INSTALLMENT_DETAILS")
};

export const downloadInstallmentsReport = (userAfm, trxId, dateFrom, dateTo, amountFrom, amountTo, page, rowsPerPage, by, asc, email, currentUrl) => {
    let data = createInstallmentObject(trxId, dateFrom, dateTo, amountFrom, amountTo, page, rowsPerPage, by, asc)
    data['email'] = email
    data['currentUrl'] = currentUrl
    let headers = {'x-afm': userAfm}
    return makeRequest(middlewareUrl + "/installments/excel", "GET", data, headers,
      "DOWNLOAD_AUTHORIZATION_REPORT")
};

function createInstallmentObject(trxId, dateFrom, dateTo, amountFrom, amountTo, page, rowsPerPage, by, asc) {
    let data = {};
    data['trxId'] = trxId !== "" ? trxId : undefined;
    data['dateFrom'] = dateFrom;
    data['dateTo'] = dateTo;
    data['amountFrom'] = amountFrom !== "" ? amountFrom : undefined;
    data['amountTo'] = amountTo !== "" ? amountTo : undefined;
    data['page'] = page;
    data['rowsPerPage'] = rowsPerPage;
    data['by'] = by;
    data['asc'] = asc;

    return data;
}
