import React from "react";
import {connect} from "react-redux";
import {Modal} from "react-bootstrap";


const stateMap = (store) => {
    return {
        i18n: store.i18n,
        userReducer: store.userReducer
    };
};

class GenericModal extends React.Component {
    render() {
        return (
            <Modal show={this.props.showModal} onHide={this.props.onHide} bsSize={this.props.bsSize}
                   bsClass={this.props.modalClass ? this.props.modalClass : 'modal'}
                   dialogClassName={this.props.dialogClassName ? this.props.dialogClassName : ''}>
                <Modal.Header bsClass={this.props.modalHeaderClass ? this.props.modalHeaderClass : 'modal-header'}
                              closeButton={this.props.showCloseButton}>
                    {this.props.modalTitle ?
                        <Modal.Title>
                            {this.props.modalTitle}
                        </Modal.Title> : null}
                </Modal.Header>
                <Modal.Body bsClass={this.props.modalBodyClass ? this.props.modalBodyClass : 'modal-body'}>
                    {this.props.modalBody}
                </Modal.Body>
                {this.props.modalFooter ?
                    <Modal.Footer bsClass={this.props.modalFooterClass ? this.props.modalFooterClass : 'modal-footer'}>
                        {this.props.modalFooter}
                    </Modal.Footer> : null}
            </Modal>
        )
    }
}

export default connect(stateMap)(GenericModal);