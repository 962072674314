import {makeRequest} from "../api-client";

const middlewareUrl = process.env.MIDDLEWARE_URL

export const getBankTotals = (dateFrom, dateTo, merchantId, acquirerId) => {
    let data = {
        dateFrom: dateFrom,
        dateTo: dateTo,
        merchantId: merchantId === null ? '' : merchantId,
        acquirerId: acquirerId
    }
    return makeRequest(middlewareUrl + "/bank/totals/list", "GET", data, {}, "GET_BANK_TOTALS_LIST")
};

export const downloadBankTotalsReport = (dateFrom, dateTo, merchantId, acquirerId, email, currentUrl, locale) => {
    let data = {
        dateFrom: dateFrom,
        dateTo: dateTo,
        merchantId: merchantId === null ? '' : merchantId,
        acquirerId: acquirerId,
        email: email,
        currentUrl: currentUrl,
        locale: locale
    }
    return makeRequest(middlewareUrl + "/bank/totals/excel", "GET", data, {},
        "DOWNLOAD_BANK_TOTALS_REPORT", 'blob')
};