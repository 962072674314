import React from "react";
import connect from "react-redux/es/connect/connect";
const Translate = require('react-redux-i18n').Translate;

const stateMap = (store) => {
    return {
        userReducer: store.userReducer
    };
};

class ClosedDetail extends React.Component {

    render() {

        return (
            <div className="user-profile__content" key={"user-profile-content-closed-" + this.props.type }>
                <div className="user-profile__label__title_closed" key={"title-closed-" + this.props.type }>
                    <Translate value={"modals.profile." + this.props.type + ".title"} />
                </div>
                <div className="user-profile__label__value_closed" key={"value-closed-" + this.props.type }>
                    <span>{this.props.labelValue}</span>
                </div>
            </div>
        )
    }
}

export default connect(stateMap)(ClosedDetail);