
export const Locales = {
    en: "en",
    el: "el"
}

export const phoneNumberRegex = "[6|9]{2}[0-9]{8}"

export const passwordRequirementsPattern = '(?=^.{12,255}$)((?=.*\\d)(?=.*[A-Z])(?=.*[a-z])(?=.*[^A-Za-z0-9]))^.*'

export const merchantsAdmin = "MERCHANTS_ADMIN"

export const merchantsBankTotals = "MERCHANT-BANK-TOTALS"

export const impersonatorFullAccess = "IMPERSONATOR_FULL_ACCESS"

export const impersonatorLimitedAccess = "IMPERSONATOR_LIMITED_ACCESS"

