import React from 'react';
import BootstrapTable from 'react-bootstrap-table-next';
import {Modal} from 'react-bootstrap';
import paginationFactory from 'react-bootstrap-table2-paginator';
import moment from 'moment';
import NumberFormat from 'react-number-format';
import connect from 'react-redux/es/connect/connect';
import NumberFormatter from '../common/NumberFormatter';
import EmptyStateShape from '../common/EmptyStateShape';
import ReactToPrint from 'react-to-print';
import {InstallmentDetails} from './InstallmentDetails';

const Translate = require('react-redux-i18n').Translate;
const I18n = require('react-redux-i18n').I18n;

const sortProps = {
    headerFormatter: (column, index, {sortElement, filterElement}) => {
        const order = column.sort ? sortElement.props : null;
        return (
            <div className="installments-table-header">
                <Translate value={column.text} dangerousHTML/>
                <span>{order != null ? order.children : null}</span>
            </div>
        )
    }
};

const stateMap = (store) => {
    return {
        userReducer: store.userReducer,
        merchantReducer: store.merchantReducer
    };
};

class InstallmentsDataTable extends React.Component {
    constructor(props) {
        super(props)

        this.dateTimeFormatter = this.dateTimeFormatter.bind(this);
        this.amountsFormatter = this.amountsFormatter.bind(this);
        this.sortCaret = this.sortCaret.bind(this);
        this.handleCloseModal = this.handleCloseModal.bind(this);
        this.handleShowModal = this.handleShowModal.bind(this);
        this.getJumpToPageValue = this.getJumpToPageValue.bind(this);
        this.jumpToPage = this.jumpToPage.bind(this);
        this.adjustPaginatorSize = this.adjustPaginatorSize.bind(this);
        this.jumpToPageKeyEvent = this.jumpToPageKeyEvent.bind(this);
        this.handleTableChange = this.handleTableChange.bind(this);
        this.getSortField = this.getSortField.bind(this);

        this.state = {
            nextPageTitle: I18n.t('pages.transactions.table.pagination.nextPageTitle'),
            prevPageTitle: I18n.t('pages.transactions.table.pagination.prevPageTitle'),
            firstPageTitle: I18n.t('pages.transactions.table.pagination.firstPageTitle'),
            lastPageTitle: I18n.t('pages.transactions.table.pagination.lastPageTitle'),
            showInstallmentDetails: false,
            selectedRowId: null,
            pageInput: null,
            emptyTable: (this.props.data.installmentsTableData ? (this.props.data.installmentsTableData.length === 0) : true),
            rowsPerPage: this.props.data.rowsPerPage ? this.props.data.rowsPerPage : 20,
            showJumpToPage: this.props.data.total > 20,
            leftPaperclipTooltip: I18n.t('tooltips.hover.trxLeftPaperclip'),
            leftZedTooltipInBatch: I18n.t('tooltips.hover.trxLeftInBatch'),
            leftClockTooltip: I18n.t('tooltips.hover.trxLeftClock'),
            leftCheckTooltip: I18n.t('tooltips.hover.trxLeftCheck'),
            leftFailTooltip: I18n.t('tooltips.hover.trxLeftFail'),
            iconCheckTooltip: I18n.t('tooltips.hover.trxStatusOk'),
            iconXTooltip: I18n.t('tooltips.hover.trxStatusRejected'),
            iconXCardlinkOneTooltip: I18n.t('tooltips.hover.trxStatusCardlinkOneRejected'),
        }
    }

    componentWillReceiveProps(nextProps) {
        if (nextProps.locale !== this.props.locale) {
            this.setState({
                nextPageTitle: I18n.t('pages.transactions.table.pagination.nextPageTitle'),
                prevPageTitle: I18n.t('pages.transactions.table.pagination.prevPageTitle'),
                firstPageTitle: I18n.t('pages.transactions.table.pagination.firstPageTitle'),
                lastPageTitle: I18n.t('pages.transactions.table.pagination.lastPageTitle'),
                leftPaperclipTooltip: I18n.t('tooltips.hover.trxLeftPaperclip'),
                leftClockTooltip: I18n.t('tooltips.hover.trxLeftClock'),
                leftZedTooltipInBatch: I18n.t('tooltips.hover.trxLeftInBatch'),
                iconCheckTooltip: I18n.t('tooltips.hover.trxStatusOk'),
                iconXTooltip: I18n.t('tooltips.hover.trxStatusRejected')
            })
        }

        if (nextProps.data.installmentsTableData !== this.props.data.installmentsTableData) {
            this.setState({
                emptyTable: (nextProps.data.installmentsTableData ? (nextProps.data.installmentsTableData.length === 0) : true),
                showJumpToPage: nextProps.data.total > 20
            })
        }

    }

    handleTableChange = (type, {page, sizePerPage, sortField: dataField, sortOrder}) => {
        this.setState({
            currentPage: page,
            rowsPerPage: sizePerPage,
        }, () => {
            if (type === "sort") {
                const sortField = this.getSortField(dataField);
                this.props.handleTblChange(page, sizePerPage, sortField, (!(this.props.data.asc && sortField === this.props.data.by)));
            } else {
                this.props.handleTblChange(page, sizePerPage, this.props.data.by, this.props.data.asc)
            }
        })
    };

    handleCloseModal() {
        this.setState({showInstallmentDetails: false});
    }

    handleShowModal() {
        this.setState({showInstallmentDetails: true});
    }

    arrangeSizePerPageList(total) {
        switch (true) {
            case (total > 20 && total < 100):
                return [
                    {
                        text: '20', value: 20
                    },
                    {
                        text: '50', value: 50
                    }
                ];
            case (total >= 100):
                return [{
                    text: '20', value: 20
                },
                    {
                        text: '50', value: 50
                    },
                    {
                        text: '100', value: 100
                    }];
            default:
                return [{
                    text: '20', value: 20
                },
                    {
                        text: '50', value: 50
                    },
                    {
                        text: '100', value: 100
                    }];
        }
    }

    dateTimeFormatter(cell, row) {
        return (
            <div className="datetime-tbl">
                <span className="date-tbl">
                    {moment(cell).format('DD/MM/YY HH:mm:ss')}
                </span>
            </div>
        );
    }

    amountsFormatter(cell, row) {
        return (
            <NumberFormatter number={cell} digits={2} symbol={"€"}/>
        )
    }

    sortCaret(order, column) {
        let isByDateTime = this.props.data.by === 'payment_date' && column.sortField === 'payment_date';
        return (
            <div className="order">
                {(!this.props.data.asc ||
                    this.props.data.asc === false) ||
                (this.props.data.asc && column.sortField !== this.props.data.by && !isByDateTime) ?
                    <span className="dropdown">
                <span className="caret">
                </span>
            </span> : null}
                {this.props.data.asc === true &&
                (column.sortField === this.props.data.by || isByDateTime) ?
                    <span className="dropup">
                    <span className="caret">
                    </span>
                </span> : null}
            </div>
        )
    }

    getJumpToPageValue(obj) {
        this.setState({
            pageInput: obj.floatValue
        });
    }

    jumpToPage() {
        let quotient = Math.floor(parseInt(this.props.data.total, 10) / parseInt(this.state.rowsPerPage, 10));
        let remain = parseInt(this.props.data.total, 10) % parseInt(this.state.rowsPerPage, 10);
        let toPage = (quotient + (remain > 0 ? 1 : 0));

        if (this.state.pageInput <= toPage) {
            this.props.handleTblChange(this.state.pageInput, this.state.rowsPerPage);
        }
    }

    jumpToPageKeyEvent(e) {
        if (e.key === 'Enter' && this.state.pageInput !== null) {
            this.jumpToPage();
        }
    }

    adjustPaginatorSize(pageNumDigits) {
        switch (true) {
            case (pageNumDigits >= 5):
                return 3;
            case (pageNumDigits === 4):
                return 4;
            case (pageNumDigits === 3):
                return 5;
            case (pageNumDigits === 2 || pageNumDigits === 1):
                return 6;
            default:
                return 3;
        }
    }

    getSortField(dataField) {
        switch (dataField) {
            case 'paymentRespDate':
                return 'payment_resp_date';
            case 'trxId':
                return 'trx_id';
        case 'merchantId':
            return null;
        case 'terminalId':
            return null;
            default:
                return dataField;
        }
    }

    render() {
        const rowEvents = {
            onClick: (e, row, rowIndex) => {
                this.setState({
                    selectedRowId: row.id,
                }, () => {
                    this.handleShowModal();
                })

            }
        };

        const rowClasses = (row, rowIndex) => {
            let classes = row.color;
            return classes;
        };

        const customTotal = (from, to, size) => (
            <div className="react-bootstrap-transactions-table-pagination-total">
                <div className="results-total">
                    <span> {this.props.data.total} </span>
                    <Translate value="pages.transactions.table.pagination.results"/>
                </div>
                <div className="dates-summary">
                    <span>{this.props.data.dateFrom}</span>
                    <Translate value="pages.transactions.table.pagination.toLabel"/>
                    <span>{this.props.data.dateTo}</span>
                </div>
            </div>
        );


        let options = {
            hideSizePerPage: this.props.data.total <= 20,
            paginationSize: this.adjustPaginatorSize(this.props.data.currentPage.toString().length),
            pageStartIndex: 1,
            alwaysShowAllBtns: true,
            withFirstAndLast: true,
            hidePageListOnlyOnePage: true,
            prePageText: 'p',
            nextPageText: 'n',
            firstPageText: 'f',
            lastPageText: 'l',
            prePageTitle: this.state.prevPageTitle,
            nextPageTitle: this.state.nextPageTitle,
            firstPageTitle: this.state.firstPageTitle,
            lastPageTitle: this.state.lastPageTitle,
            showTotal: true,
            paginationTotalRenderer: customTotal,
            onTableChange: this.handleTableChange,
            page: this.props.data.currentPage,
            sizePerPage: this.props.data.rowsPerPage,
            totalSize: this.props.data.total,
            sizePerPageList: this.arrangeSizePerPageList(this.props.data.total)
        };

        const columns = [
            {
                dataField: 'merchantId',
                headerFormatter: sortProps.headerFormatter,
                text: "pages.installments.table.headings.merchantId",
                sort: false
            },
            {
                dataField: 'terminalId',
                headerFormatter: sortProps.headerFormatter,
                text: "pages.installments.table.headings.terminalId",
                class: "sortable",
                sort: false
            },
            {
                dataField: 'paymentRespDate',
                sortField: 'payment_resp_date',
                text: "pages.installments.table.headings.paymentDate",
                formatter: this.dateTimeFormatter,
                headerFormatter: sortProps.headerFormatter,
                sortCaret: this.sortCaret,
                sort: true,
            },
            {
                dataField: 'number',
                sortField: 'number',
                text: "pages.installments.table.headings.number",
                headerFormatter: sortProps.headerFormatter,
                sortCaret: this.sortCaret,
                sort: true
            },
            {
                dataField: 'total',
                text: "pages.installments.table.headings.total",
                headerFormatter: sortProps.headerFormatter,
            },
            {
                dataField: 'statusId',
                text: "pages.installments.table.headings.status",
                headerFormatter: sortProps.headerFormatter,
                sortCaret: this.sortCaret,
                sort: true
            },
            {
                dataField: 'amount',
                text: "pages.installments.table.headings.amount",
                formatter: this.amountsFormatter,
                headerFormatter: sortProps.headerFormatter,
                sortCaret: this.sortCaret,
                sort: true
            }
        ];

        return (
            <React.Fragment>
                <Modal show={this.state.showInstallmentDetails} onHide={this.handleCloseModal}
                       bsClass="transaction__details_modal modal">
                    <Modal.Header closeButton bsClass="transaction__details_modal-header modal-header">
                        <Modal.Title>
                            <Translate value="pages.installments.details.title"/>
                            <div className="buttons-wrapper">
                                <ReactToPrint
                                    trigger={() =>
                                        <button title={I18n.t("pages.transactions.details.printDetails")}
                                                className="icon-printer transaction__details__print--btn"/>}
                                    bodyClass={"transaction__details--print"}
                                    content={() => this.componentRef}
                                />
                            </div>
                        </Modal.Title>
                    </Modal.Header>
                    <Modal.Body bsClass="transaction__details_modal-body modal-body"
                                ref={el => (this.componentRef = el)}
                    >
                        <InstallmentDetails
                                 id={this.state.selectedRowId}/>
                    </Modal.Body>
                </Modal>
                <BootstrapTable classes={"installments-table"}
                                remote
                                keyField='id'
                                data={this.props.data.installmentsTableData}
                                columns={columns}
                                rowEvents={rowEvents}
                                pagination={paginationFactory(options)}
                                onTableChange={this.handleTableChange}
                                rowStyle={rowClasses}
                />
                {this.state.emptyTable ?
                    <div className="installments-table__empty-state">
                        <EmptyStateShape
                            title={"pages.transactions.table." + (this.props.data.fromSearch ? "emptySearchMessageTitle" : "emptyTableMessageTitle")}
                            text={this.props.data.fromSearch ? "pages.transactions.table.emptySearchMessageText" : null}
                        />
                    </div> : null
                }
                {!this.state.emptyTable && this.state.showJumpToPage ?
                    <div className="jump-to-page">
                        <div className="jump-to-page__wrapper">
                            <div className="jump-to-page__label">
                                <Translate value="pages.transactions.table.pagination.jumpToPage"/>
                            </div>
                            <NumberFormat
                                allowNegative={false}
                                format="########"
                                onValueChange={this.getJumpToPageValue}
                                onKeyPress={this.jumpToPageKeyEvent}
                            />
                            <button type="button" onClick={this.jumpToPage}> {"OK"} </button>
                        </div>
                    </div> : null}
            </React.Fragment>
        )
    }
}

export default connect(stateMap)(InstallmentsDataTable);
