import React from "react";

const Translate = require('react-redux-i18n').Translate;

export default class TransactionsInfo extends React.Component {

    render() {
        let isUp = this.props.isUp;
        let isAmount = this.props.isAmount;
        let value = this.props.value;
        let diff = this.props.diff;
        let currency = this.props.currency;
        let timespan = this.props.previousTimespanMessage;
        let zeroDiff = diff === '-';
        const timespanLabel1 = "pages.overview.dashboard.transactionsInfo.timespanLabels." + timespan + "1";
        const timespanLabel2 = "pages.overview.dashboard.transactionsInfo.timespanLabels." + timespan + "2";
        return (
            <div
                className={"transactions-info" + (isAmount ? " transactions-info_amount-type" : " transactions-info_count-type")}>
                <div className={"transactions-info__label"}>
                    <Translate value={"pages.overview.dashboard.transactionsInfo." + (isAmount ? "amount" : "count")}/>
                </div>
                <div className="transactions-info__value">
                    {isAmount ? <span className="transactions-info__value__currency"> {currency} </span> : null}
                    <span className={"transactions-info__value" + (isAmount ? "__amount" : "__count")}>
                        {value}
                    </span>
                </div>
                <div className="transactions-info__percentage">
                    <div className="timespan-label">
                            <Translate value={timespanLabel1}/>
                            <Translate value={timespanLabel2}/>
                    </div>
                    {zeroDiff ?
                        <div className="timespan-value">
                            <span>-</span>
                        </div>
                        :<div className="timespan-value">
                            <span>{(this.props.outOffRange ? this.props.outOffRange : '') + diff}</span>
                            <span>%</span>
                            <span>
                                <i className={"icon-kpi-arrow kpi-arrow-" + (isUp ? "up" : "down") + (isAmount ? " amount" : " count")}></i>
                            </span>
                        </div>}
                </div>
            </div>
        )
    }
}

