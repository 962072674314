import React from "react";
import {withRouter} from "react-router-dom";
import TransactionsInfo from "./TransactionsInfo";
import Gauge from "../charts/gaugeChart/Gauge";
import {
    calculateCountAmountDiff,
    date1NotOlderForMoreThanXDays,
    formatAmount,
    pickedDateIs1DayAgo,
    setChartDates,
} from "../../lib/utils";
import {countsAverage} from "../../providers/charts";
import moment from "moment";
import {connect} from "react-redux";
import store from "../../store";
import Loader from "../common/Loader";
import {getMap} from "../../providers/rest";
import ChartDropdown from "../charts/ChartDropdown";
import {getKpiExcel, getMidsNumByAfm} from "../../providers/merchants";
import Tooltip from "../common/Tooltip";

const stateMap = (store) => {
    return {
        i18n: store.i18n,
        userReducer: store.userReducer,
        merchantReducer: store.merchantReducer,
        chartReducer: store.chartReducer
    };
};

const Translate = require('react-redux-i18n').Translate;
const fileDownload = require('js-file-download');
const I18n = require('react-redux-i18n').I18n;

class Dashboard extends React.Component {
    constructor(props) {
        super(props)
        this.state = {
            afm: this.props.afm,
            mid: this.props.mid,
            transactionsMode: false,
            previousDateFrom: moment().subtract(2, 'day').format('YYYY-MM-DD'),
            previousDateTo: moment().subtract(2, 'day').format('YYYY-MM-DD'),
            dateFrom: moment().subtract(1, 'day').format('YYYY-MM-DD'),
            dateTo: moment().subtract(1, 'day').format('YYYY-MM-DD'),
            showTransactionsLink: false,
            allAfms: props.userReducer.getAssociationsResponse.uniqueAfms,
            totalCount: null,
            previousTotalAmount: null,
            formattedTotalAmount: null,
            totalAmount: null,
            percentageOfPreviousToCurrentAmount: null,
            signOfPreviousToCurrentAmount: null,
            percentageOfPreviousToCurrentCount: null,
            signOfPreviousToCurrentCount: null,
            previousTimespanMessage: 'prePrevDay',
            keyPerformanceIndicatorMaps: null,
            disableExcel: false,
            kpiObj: {},
            goToTransactionsTooltip: I18n.t('tooltips.hover.seeTRXbtn'),
            timeDropdownTooltip: I18n.t('tooltips.hover.timeDropdownKPI'),
            locale: props.i18n.locale
        }

        this.getCurrentAndPreviousCountsAverage = this.getCurrentAndPreviousCountsAverage.bind(this);
        this.setPreviousTimespanMessage = this.setPreviousTimespanMessage.bind(this);
        this.previousCountsAverage = this.previousCountsAverage.bind(this);
        this.getPercentageOfPreviousToCurrentCount = this.getPercentageOfPreviousToCurrentCount.bind(this);
        this.getPercentageOfPreviousToCurrentAmount = this.getPercentageOfPreviousToCurrentAmount.bind(this);
        this.nullifyPercentages = this.nullifyPercentages.bind(this);
        this.countsAverage = this.countsAverage.bind(this);
        this.getKeyPerformanceIndicatorMaps = this.getKeyPerformanceIndicatorMaps.bind(this);
        this.arrangeAfm = this.arrangeAfm.bind(this);
        this.setNewDates = this.setNewDates.bind(this);
        this.getKpiExcel = this.getKpiExcel.bind(this);
        this.calculateDiff = this.calculateDiff.bind(this);
        this.checkAllowedDateRanges = this.checkAllowedDateRanges.bind(this)
        this.getMaxDateRange = this.getMaxDateRange.bind(this)
    }

    componentWillMount() {
        this.getCurrentAndPreviousCountsAverage({
            afm: this.arrangeAfm(this.props.merchantReducer.afm),
            mid: this.state.mid,
            dateFrom: this.state.dateFrom,
            dateTo: this.state.dateTo,
            previousDateFrom: this.state.previousDateFrom,
            previousDateTo: this.state.previousDateTo,
            locale: this.props.i18n.locale
        })
        this.getKeyPerformanceIndicatorMaps()
        this.checkAllowedDateRanges(this.props.merchantReducer.afm)

    }

    checkAllowedDateRanges(afm){
        store.dispatch(getMidsNumByAfm(1, -1, afm)).then((res)=> {
            this.setState({
                midsNum: res.value.data
            }, ()=> {
                this.getMaxDateRange()
            })
        })
    }

    getMaxDateRange() {
        let maxDateRange = this.state.midsNum > parseInt(process.env.SEARCH_LIMIT_MIDS) ?
            parseInt(process.env.SEARCH_LIMIT_DAYS_SMALL) :
            parseInt(process.env.SEARCH_LIMIT_DAYS_BIG);
        let selectedDateIsWithinAllowedRange =   date1NotOlderForMoreThanXDays(this.state.dateFrom, this.state.dateTo, maxDateRange);
        this.setState({
            showTransactionsLink: selectedDateIsWithinAllowedRange
        })
    }

    componentWillReceiveProps(nextProps) {
        if (this.props.afm !== nextProps.afm || this.props.mid !== nextProps.mid) {
            this.getCurrentAndPreviousCountsAverage({
                afm: this.arrangeAfm(nextProps.afm),
                mid: nextProps.mid,
                dateFrom: this.state.dateFrom,
                dateTo: this.state.dateTo,
                previousDateFrom: this.state.previousDateFrom,
                previousDateTo: this.state.previousDateTo
            })
            this.setState({
                afm: nextProps.afm,
                mid: nextProps.mid
            })
        }

        if (this.props.merchantReducer.afm !== nextProps.merchantReducer.afm) {
            this.checkAllowedDateRanges(nextProps.merchantReducer.afm)
        }

        if (nextProps.i18n.locale !== this.props.i18n.locale) {
            this.setState({
                goToTransactionsTooltip: I18n.t("tooltips.hover.seeTRXbtn"),
                timeDropdownTooltip: I18n.t("tooltips.hover.timeDropdownKPI"),
                locale: nextProps.i18n.locale
            })
        }
    }

    countsAverage(data) {
        store.dispatch(countsAverage(data)).then(
            (response) => {
                this.setState({
                    totalAmount: response.value.data.totalAmount ? response.value.data.totalAmount : 0,
                    formattedTotalAmount: response.value.data.totalAmount ? formatAmount(this.state.locale, response.value.data.totalAmount, 2) : 0,
                    totalCount: response.value.data.totalCount ? response.value.data.totalCount : 0,
                    formattedTotalCount: response.value.data.totalCount ? formatAmount(this.state.locale, response.value.data.totalCount, 0) : 0,
                    minAmount: response.value.data.minAmount,
                    maxAmount: response.value.data.maxAmount,
                    averageAmount: response.value.data.averageAmount
                }, () => {
                    this.previousCountsAverage(data);
                    this.setState({
                        kpiContent: {
                            company: this.state.afm,
                            mid: this.state.mid ? this.state.mid : '-',
                            date: this.state.dateFrom.toString() + "-" + this.state.dateTo.toString(),
                            amount: this.state.totalAmount ? this.state.totalAmount.toString() : "0",
                            count: this.state.totalCount ? this.state.totalCount.toString() : "0",
                            min: this.state.minAmount ? this.state.minAmount.toString() : "0",
                            max: this.state.maxAmount ? this.state.maxAmount.toString() : "0",
                            average: this.state.averageAmount ? this.state.averageAmount.toString() : "0"
                        }
                    })
                })
            })
    }

    previousCountsAverage(data) {
        store.dispatch(countsAverage(data)).then(
            (response) => {
                this.setState({
                    previousTotalAmount: response.value.data.totalAmount ? response.value.data.totalAmount : 0,
                    previousTotalCount: response.value.data.totalCount ? response.value.data.totalCount : 0
                }, () => {
                    this.getPercentageOfPreviousToCurrentAmount()
                })
            })
    }

    calculateDiff(prev, curr) {
        if (prev === 0 || curr === 0) {
            return '-';
        } else {
            return ((curr / prev) - 1) * 100
        }
    }


    getPercentageOfPreviousToCurrentCount() {
        let totalCount = this.state.totalCount ? this.state.totalCount : 0;
        let prevTotalCount = this.state.previousTotalCount ? this.state.previousTotalCount : 0;
        const previousToCurrentCount = this.calculateDiff(parseInt(prevTotalCount, 10), parseInt(totalCount, 10));
        this.setState({
            percentageOfPreviousToCurrentCount: previousToCurrentCount,
            signOfPreviousToCurrentCount: previousToCurrentCount > 0
        }, () => {
            let kpiObj = Object.assign({}, this.state.kpiContent);
            const sign = this.state.signOfPreviousToCurrentCount ? "+" : "-";
            kpiObj.prevCount = (sign + this.state.percentageOfPreviousToCurrentCount + "% ("
                + I18n.t("pages.overview.dashboard.transactionsInfo.timespanLabels."
                    + this.state.previousTimespanMessage + "1") + " "
                + I18n.t("pages.overview.dashboard.transactionsInfo.timespanLabels."
                    + this.state.previousTimespanMessage + "2"
                ) + ")").toString();
            this.setState({kpiContent: kpiObj});
        })
    }

    getPercentageOfPreviousToCurrentAmount() {
        let totalAmount = this.state.totalAmount ? this.state.totalAmount : 0;
        let prevTotalAmount = this.state.previousTotalAmount ? this.state.previousTotalAmount : 0;
        const previousToCurrentAmount = this.calculateDiff(parseInt(prevTotalAmount, 10), parseInt(totalAmount, 10));
        this.setState({
            percentageOfPreviousToCurrentAmount: previousToCurrentAmount,
            signOfPreviousToCurrentAmount: previousToCurrentAmount > 0
        }, () => {
            let kpiObj = Object.assign({}, this.state.kpiContent);
            const sign = this.state.signOfPreviousToCurrentAmount ? "+" : "-"
            kpiObj.prevAmount = (sign + this.state.percentageOfPreviousToCurrentAmount + "% ("
                + I18n.t("pages.overview.dashboard.transactionsInfo.timespanLabels."
                    + this.state.previousTimespanMessage + "1") + " "
                + I18n.t("pages.overview.dashboard.transactionsInfo.timespanLabels."
                    + this.state.previousTimespanMessage + "2"
                ) + ")").toString()
            this.setState({kpiContent: kpiObj}, () => {
                this.getPercentageOfPreviousToCurrentCount()
            });
        })
    }

    nullifyPercentages() {
        this.setState({
            percentageOfPreviousToCurrentAmount: null,
            percentageOfPreviousToCurrentCount: null
        })
    }

    getKeyPerformanceIndicatorMaps() {
        store.dispatch(getMap('keyPerformanceIndicators')).then((response) => {
            this.setState({
                keyPerformanceIndicatorMaps: response.value.data
            })
        })
    }

    arrangeAfm(afm) {
        if (afm && afm !== '') {
            return afm;
        } else {
            return this.props.userReducer.getAssociationsResponse.uniqueAfms.toString()
        }
    }

    setNewDates(dateFrom, dateTo) {
        this.setState({
            dateFrom: dateFrom,
            dateTo: dateTo
        }, ()=> {
            this.checkAllowedDateRanges(this.props.merchantReducer.afm)
        })
    }

    setPreviousTimespanMessage(previousTimespanMessage) {
        this.setState({
            previousTimespanMessage: previousTimespanMessage
        })
    }

    getCurrentAndPreviousCountsAverage(data) {
        this.countsAverage(data)
        data.dateFrom = data.previousDateFrom
        data.dateTo = data.previousDateTo
    }

    getKpiExcel() {
        this.setState({
            disableExcel: true
        })
        store.dispatch(getKpiExcel(this.state.kpiContent, this.props.i18n.locale)).then((response) => {
            fileDownload(response.value.data, response.value.headers.filename);
            this.setState({
                disableExcel: false
            })
        })
    }

    render() {
        let currency = "€";
        let formattedTotalAmount = this.state.totalAmount ?
            formatAmount(this.state.locale, this.state.totalAmount, 2) :
            formatAmount(this.state.locale, 0, 2);
        let formattedTotalCount = this.state.totalCount ?
            formatAmount(this.state.locale, this.state.totalCount, 0) : 0;
        let amountDiff = calculateCountAmountDiff(this.state.percentageOfPreviousToCurrentAmount, this.state.locale);
        let countDiff = calculateCountAmountDiff(this.state.percentageOfPreviousToCurrentCount, this.state.locale)

        return (
            <div className={"dashboard" + (!this.state.showTransactionsLink ? " add-padding-bottom": "")}>
                {this.state.keyPerformanceIndicatorMaps !== null ?
                    [<h6 className="overview__section__title" key="overview-title">
                        <Translate value="pages.overview.dashboard.title"/>
                        <span>
                        <Tooltip placement="right"
                                 tooltipButtonId="overviewKpiTitle"
                                 tooltipLabelClass="overview-kpi-title-label"
                                 popoverId="overview-kpi-title-tltp"
                                 tooltipText='overviewKpiTitleTltp'
                        />
                    </span>
                    </h6>,
                        <div className="dates__chart-dropdown__wrapper" key="dashboard-wrapper"
                             title={this.state.timeDropdownTooltip}>
                            <React.Fragment>
                                {pickedDateIs1DayAgo(this.state.dateFrom) ?
                                    this.props.renderDates(this.state.dateTo, undefined, "overview__single-date") :
                                    this.props.renderDates(this.state.dateFrom, this.state.dateTo)}
                            </React.Fragment>
                            <ChartDropdown
                                id="dashboard-chart-timespan"
                                chartMaps={this.state.keyPerformanceIndicatorMaps} data={{
                                afm: this.state.afm,
                                mid: this.state.mid
                            }} reRenderChart={this.getCurrentAndPreviousCountsAverage} setDates={setChartDates}
                                setDatesInParent={this.setNewDates}
                                setPreviousTimespanMessage={this.setPreviousTimespanMessage}
                                dropdownBaseValue={"pages.overview.dashboard.transactionsInfo.dropdownBaseValue"}
                            />
                        </div>,
                        <div key="dashboard-content">
                            {this.props.chartReducer.dashboard.fetched ?
                                <div className="dashboard__tiles">
                                    <button
                                        className={"dashboard-kpi-excel-btn " + (this.state.disableExcel === true ? "disabled" : "")}
                                        onClick={this.getKpiExcel}
                                        disabled={this.state.disableExcel === true}>
                                    </button>
                                    <TransactionsInfo currency={currency} isAmount={true}
                                                      isUp={this.state.signOfPreviousToCurrentAmount}
                                                      value={formattedTotalAmount}
                                                      diff={amountDiff.diff}
                                                      outOffRange={amountDiff.outOfRangeSign}
                                                      previousTimespanMessage={this.state.previousTimespanMessage}/>
                                    <TransactionsInfo currency={currency} isAmount={false}
                                                      isUp={this.state.signOfPreviousToCurrentCount}
                                                      value={formattedTotalCount}
                                                      diff={countDiff.diff}
                                                      outOffRange={countDiff.outOfRangeSign}
                                                      previousTimespanMessage={this.state.previousTimespanMessage}/>
                                    <Gauge currency={currency} min={this.state.minAmount} max={this.state.maxAmount} average={this.state.averageAmount}
                                           label={'kpiLabel'}/>
                                </div> : null}
                        </div>,
                        <div key="dashboard-loader">
                            {this.props.chartReducer.dashboard.fetching ?
                                <Loader
                                    bouncing={false}
                                    color={'midnight-blue'}
                                    loaderStyles={{minHeight: "112.5px", margin: "15px 0"}}
                                    message="loader.dashboard"/> : null}
                        </div>,
                        <div className="goto__transactions" key="go-to-transactions">
                            {this.state.showTransactionsLink ?
                            <a href="" className="goto__transactions__btn btn"
                               title={this.state.goToTransactionsTooltip}
                               onClick={(e) => {
                                   e.preventDefault();
                                   this.props.history.push({
                                       pathname: '/transactions',
                                       state: {dateFrom: this.state.dateFrom, dateTo: this.state.dateTo}
                                   })
                               }}>
                                <Translate value="pages.overview.gotoTransactions"/>
                            </a>: null}
                        </div>] : null}
            </div>
        )
    }
}

export default withRouter(connect(stateMap)(Dashboard));