import React from "react";
import {connect} from "react-redux";
import NewPasswordForm from "./NewPasswordForm";
import NewPhoneForm from "./NewPhoneForm";
import OtpForm from "./OtpForm";
import store from "../../../store";
import {checkConfirmationCode} from "../../../providers/users";

import queryString from 'query-string';

const stateMap = (store) => {
    return {
        i18n: store.i18n,
        userReducer: store.userReducer
    };
};

class UserDetails extends React.Component {
    constructor(props) {
        super(props)

        this.state = {
            confirmationCodeChecked: null,
            confirmationCodeValidationOutput: null
        }
    }

    componentDidMount() {
        const parsedUrl = queryString.parse(this.props.location.search);
        store.dispatch(checkConfirmationCode(parsedUrl.email, parsedUrl.confirmationCode)).then(() => {
            this.setState({
                confirmationCodeChecked: true
            })
        }, (error) => {
            this.setState({
                confirmationCodeChecked: false,
                confirmationCodeValidationOutput: error.response.data.errors[0]
            })
        })
    }



    render() {
        const parsedUrl = queryString.parse(this.props.location.search);
        let formToRender = null;
        if (this.state.confirmationCodeChecked === true) {
            if (this.props.userReducer.userDetails.renderNewPasswordForm) {
                formToRender = <NewPasswordForm confirmationCode={parsedUrl.confirmationCode}
                                                email={parsedUrl.email}
                                                newPasswordFormFinished={this.newPasswordFormFinished}
                                                renderNewPhoneForm={this.renderNewPhoneForm}/>
            } else if (this.props.userReducer.userDetails.renderNewPhoneForm) {
                formToRender = <NewPhoneForm confirmationCode={parsedUrl.confirmationCode}
                                             email={parsedUrl.email}
                                             newPhoneFormFinished={this.newPhoneFormFinished}
                                             setOtp={this.setOtp}/>
            } else if (this.props.userReducer.userDetails.renderOtpForm &&
                this.props.userReducer.userDetails.phone !== '') {
                formToRender = <OtpForm confirmationCode={parsedUrl.confirmationCode}
                                        email={parsedUrl.email}
                                        phone={this.props.userReducer.userDetails.phone}/>
            }
        } else if (this.state.confirmationCodeChecked === false) {
            if (this.state.confirmationCodeValidationOutput === null) {
                //TODO It should be replaced with the 404 page when it is finished
                formToRender = 404
            } else if (this.state.confirmationCodeValidationOutput === 'INVALID_EMAIL_OR_CONFIRMATION_TOKEN') {
                this.props.history.push("/invalid/invitation", { status: 'wrongParam' })
            } else if (this.state.confirmationCodeValidationOutput === 'EXPIRED_CONFIRMATION_TOKEN') {
                this.props.history.push("/invalid/invitation", { status: 'expired' })
            }
        }
        return (
            <div>
                <main className="login-area">
                    {formToRender}
                </main>
            </div>
        )
    }
}

export default connect(stateMap)(UserDetails);